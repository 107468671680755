<template>
  <div class="app-container">
    <statistics-search :search-type="searchType" @statisticsClick="statistics" />
    <div>
      <div style="margin:20px 0 10px;;text-align: center;overflow: hidden;">
        <span class="statistics_title" style="line-height: 30px;">考勤汇总报表</span>
        <span style="float:right">
          <el-button
            class="exportbtn"
            plain
            size="mini"
            @click="exportdata"
          ><svg-icon :icon-class="'export'" /> 导出</el-button>
        </span>
      </div>
      <list-table id="exportTable" :list-tb="listTb" :list-data="listData" />
    </div>
  </div>
</template>

<script>
// import DeptTime from '../components/DeptTime'
import ListTable from './ListTable'
import StatisticsSearch from '@/wapp/common/components/StatisticsSearch'
import qs from 'qs'
const moment = require('moment')

export default {
    components: {
        ListTable, StatisticsSearch
    },
    data: function() {
        return {
            loginUser: this.$store.getters.loginUser,
            selectDept: '',
            chooseDate: [],
            listTb: [
                { 'prop': 'name', 'width': '100px', 'align': 'left', 'title': '姓名', 'sortable': true, 'fixed': 'left' },
                { 'prop': 'deptName', 'width': '', 'align': 'left', 'title': '部门 ', 'sortable': true, 'fixed': 'left' },
                { 'prop': 'mustWorkDays', 'width': '110px', 'align': 'center', 'title': '应出勤天数', 'sortable': true },
                { 'prop': 'workDays', 'width': '120px', 'align': 'center', 'title': '实际出勤天数', 'sortable': true },
                { 'prop': 'workTimes', 'width': '110px', 'align': 'center', 'title': '工作时长 ', 'sortable': true },
                { 'prop': 'lateNums', 'width': '95px', 'align': 'center', 'title': '迟到次数 ', 'sortable': true },
                { 'prop': 'lateTimes', 'width': '110px', 'align': 'center', 'title': '迟到时长 ', 'sortable': true },
                { 'prop': 'earlyNums', 'width': '95px', 'align': 'center', 'title': '早退次数 ', 'sortable': true },
                { 'prop': 'earlyTimes', 'width': '110px', 'align': 'center', 'title': '早退时长 ', 'sortable': true },
                { 'prop': 'onNoCardNums', 'width': '125px', 'align': 'center', 'title': '上班缺卡次数 ', 'sortable': true },
                { 'prop': 'offNoCardNums', 'width': '125px', 'align': 'center', 'title': '下班缺卡次数 ', 'sortable': true },
                { 'prop': 'absenseNums', 'width': '95px', 'align': 'center', 'title': '旷工次数 ', 'sortable': true },
                { 'prop': '', 'width': '210px', 'align': 'center', 'title': '请假', 'merge': true },
                { 'prop': 'overTimes', 'width': '125px', 'align': 'center', 'title': '加班总时长 ', 'sortable': true },
                { 'prop': 'travelNums', 'width': '95px', 'align': 'center', 'title': '出差次数 ', 'sortable': true },
                { 'prop': 'travelDays', 'width': '95px', 'align': 'center', 'title': '出差时长 ', 'sortable': true },
                { 'prop': 'outWorkNums', 'width': '95px', 'align': 'center', 'title': '外勤次数 ', 'sortable': true }
            ],
            listData: [],
            emptyText: '正在加载..',
            loadingMast: null,
            searchType: [
                {
                    type: 'subOrg',
                    title: '统计部门'
                }, {
                    type: 'range',
                    title: '统计周期',
                    rangeType: 'daterange',
                    rangeSeparator: '至',
                    dateFormat: 'yyyy-MM-dd'
                }
            ]
        }
    },
    computed: {
    },
    watch: {
    },
    created() {
        this.$bus.emit('showNavSearch', false) // 取消右上搜索按钮
        // const time = new Date()
        // const year = time.getFullYear()
        // const month = time.getMonth() + 1

        // if (time.getDate() < 15) {
        //     const m = month === 1 ? 12 : month - 1
        //     const y = month === 1 ? year - 1 : year
        //     const ed = new Date(y, m, 0).getDate()

        //     this.chooseDate = [moment(new Date(y, m - 1, 1)).format('YYYY-MM-DD'), moment(new Date(y, m - 1, ed)).format('YYYY-MM-DD')]
        // } else {
        //     this.chooseDate = [moment(new Date(year, month - 1, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')]
        // }
        // this.selectDept = this.loginUser.staffList[0].orgFullId.split('-')[1]
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true) // 还原右上搜索按钮
    },
    mounted() {
        // this.getData()
    },
    methods: {

        statistics(vals) {
            this.chooseDate = [moment(vals.range[0]).format('YYYY-MM-DD'), moment(vals.range[1]).format('YYYY-MM-DD')]
            this.selectDept = vals.subOrg.id
            this.getData()
        },
        // 考勤汇总统计
        getData() {
            if (this.loadingMast !== null) this.loadingMast.close()
            this.emptyText = '正在加载..'
            this.loadingMast = this.$loading({
                target: '.app-container .el-table__body-wrapper'
            })
            this.$http({
                url: 'yykq/homePageIndex/summaryAttendance',
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                params: {
                    startTime: this.chooseDate[0],
                    endTime: this.chooseDate[1],
                    orgCode: this.loginUser.orgCode,
                    deptId: this.selectDept ? this.selectDept : null
                }
            }).then(res => {
                if (res.code === 'success') {
                    const listData = res.body.listdata
                    this.loadingMast.close()
                    if (this.listData.length < 0) {
                        this.emptyText = '暂无数据'
                    }
                    for (let i = 0; i < listData.length; i++) {
                        listData[i].$index = i
                        listData[i].travelDays += '天'
                    }
                    this.listData = listData
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        exportdata() {
            const title1 = [{ 'title': '序号', 'width': 60, 'align': 'center', 'colspan': 1, 'rowspan': 2 }]
            const title2 = [
                { 'title': '事假', 'width': 70, 'align': 'center', 'colspan': 1, 'rowspan': 1 },
                { 'title': '病假', 'width': 70, 'align': 'center', 'colspan': 1, 'rowspan': 1 },
                { 'title': '其它', 'width': 70, 'align': 'center', 'colspan': 1, 'rowspan': 1 }
            ]
            const tbtitle = []
            this.listTb.forEach(tb => {
                title1.push({
                    'title': tb.title,
                    width: parseInt(tb.width) || 255,
                    align: tb.align,
                    colspan: tb.title === '请假' ? 3 : 1,
                    rowspan: tb.title === '请假' ? 1 : 2
                })
            })
            tbtitle.push(title1)
            tbtitle.push(title2)

            const tbdata = []
            this.listData.forEach((list, listIndex) => {
                const row = [{ 'value': listIndex + 1, 'align': 'center', 'colspan': 1, 'rowspan': 1 }]
                this.listTb.forEach(tb => {
                    if (tb.title === '请假') {
                        row.push({ 'value': list.affairTimes, 'align': tb.align, 'colspan': 1, 'rowspan': 1 })
                        row.push({ 'value': list.illTimes, 'align': tb.align, 'colspan': 1, 'rowspan': 1 })
                        row.push({ 'value': list.otherTimes, 'align': tb.align, 'colspan': 1, 'rowspan': 1 })
                    } else {
                        row.push({ 'value': list[tb.prop], 'align': tb.align, 'colspan': 1, 'rowspan': 1 })
                    }
                })
                tbdata.push(row)
            })

            this.$http({
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                data: qs.stringify({ 'rows': JSON.stringify(tbdata), 'titles': JSON.stringify(tbtitle), 'fileName': '考勤汇总报表' }),
                url: 'common/exportXlsFile?httpPort=' + location.port
            }).then(res => {
                if (res.code === 'success') {
                    window.open(encodeURI('/apigw/' + res.body.xlsurl), '_blank')
                } else {
                    this.$alert(res.message, '操作提示', { type: 'error' })
                }
            })
        }
    }
}
</script>

<style scoped>
</style>
