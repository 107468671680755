<template>
  <div id="AreaElements">
    <div class="Element-box">
      <template v-for="(El, index) in Elements">
        <div
          v-if="El.show"
          :key="index"
          class="drag-box"
          :style="{ background: El.config.backgroundColor }"
          draggable="true"
          unselectable="on"
          @drag="drag($event, El)"
          @dragend="dragend($event, El)"
        >
          <img :src="require(`./images/element_icon_${El.name}.png`)">
          {{ El.title }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { Elements } from './AreaConfig'
export default {
    data() {
        return {
            Elements
        }
    },
    methods: {
        drag() {
            this.$bus.emit('handleDragInLayout', ...arguments)
        },
        dragend() {
            this.$bus.emit('handleDragendInLayout', ...arguments)
        }
    }
}
</script>

<style lang="scss" scoped>
#AreaElements{
    padding: 20px;
    user-select: none;
    .Element-box-title{
        color: #1A82E3;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 7px;
    }
    .Element-box{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: column;
    }
    .drag-box{
        cursor: move;
        flex: 1;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        color: #333333;
        text-align: center;
        margin-bottom: 10px;
        border-radius: 3px;
        img {
          width: 14px;
          margin-right: 10px;
        }
    }
}
</style>
