<template>
  <div class="menu-bar">
    <leftbar />
    <rightbar />
  </div>
</template>

<script>
import Leftbar from './Leftbar.vue'
import Rightbar from './Rightbar.vue'
export default {
    components: {
        Leftbar,
        Rightbar
    },
    props: {

    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
    .menu-bar {
        padding-bottom: 10px;
        &:after {
            clear: both;
            display: block;
            content: "";
        }
    }
</style>
