<template>
  <div :class="['apps-popover',popShow?'pop-show':'pop-hide']">
    <div id="popbar">
      <aplication :style1="style" />
    </div>
  </div>
</template>

<script>
import aplication from '@/wapp/portal/application/index.vue'
import { mapGetters } from 'vuex'
// import Recently from './Recently'
import $ from 'jquery'
if (!window.jQuery) {
    window.jQuery = $
}

export default {
    components: {
        // Recently
        aplication
    },
    props: {
        popShow: { type: Boolean, default: false }
    },
    data() {
        return {
            api: process.env.VUE_APP_BASE_API,
            showSetTop: false, // 是否进入设置状态
            isAdmin: false,
            visitLog: [], // 最近应用
            apps: [],
            style: 'padding-bottom:0;margin-bottom:0px;margin-top:10px;'
        }
    },
    computed: {
        ...mapGetters([
            'routes',
            'loginUser'
        ]),
        appMenus() {
            const appMenus = this.routes.filter(item => { // 含apps_ 不在topbar显示，含under_ 在本弹框页显示
                if (item.meta && item.meta.level === 1 && item.meta.icon.indexOf('apps_') > -1) return true
            })
            const obj = {}
            const appMenuList = []
            appMenus.forEach((app) => {
                if (!obj[app.meta.busiType]) {
                    obj[app.meta.busiType] = []
                    obj[app.meta.busiType].push(app)
                } else {
                    for (const data in obj) {
                        if (data === app.meta.busiType) {
                            obj[data].push(app)
                        }
                    }
                }
            })
            for (const item in obj) {
                const appobj = {}
                appobj.title = item
                appobj.menu = obj[item]
                if (appMenuList.length === 0) {
                    appMenuList.push(appobj)
                } else {
                    return appMenuList
                }
                console.log('appMenuList', appMenuList)
            }
            return appMenuList
        }
    },
    watch: {
        popShow: {
            handler(val) {
            },
            immediate: true
        }
    },
    created() {
        this.getAllApp()
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
        this.onResize()
        const roleList = this.$store.getters.roles
        if (roleList && roleList.indexOf('dwAdministrators') !== -1) {
            this.isAdmin = true
        }
    },
    methods: {
        onResize() {
            $('#popbar').css('max-height', $(window).height() - 106)
            $('.vertical-line').css('height', Math.max($('.menu-list').height(), $('.recently').height()))
            $('.pop-hide').css('top', -$(window).height())
        },
        forward(item) {
            if (item.indexUrl) {
                if (item.indexUrl.substring(0, 1) === '#') {
                    var str = encodeURI(item.sysName)
                    this.$router.replace({ path: item.indexUrl.substring(1), query: { sys: str }})
                } else {
                    window.open(item.indexUrl)
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/wapp/common/styles/element-variables.scss";

    ul,li,h3,h4,p,a {
        margin: 0;
        padding: 0;
    }
    ul {
        list-style: none;
    }
    .apps-popover {
        box-shadow: 0 2px 12px 2px rgba(0,0,0,.3);
        // padding: 0 0 0 20px;
        background: #FAFAFA;
        position: absolute;
        left: -216px;
        transition: all;
        overflow-y: auto;
        width: 100vw;
        height: 100vh;
    }
    .pop-show {
        top: 60px!important;
        z-index: 1998;
    }
    .pop-show  /deep/ .system_out{
        margin-top: 15px !important;
    }
    #popbar {
        position: relative;
        // overflow: auto;
    }
    /*修改滚动条样式*/
    #popbar::-webkit-scrollbar{
    width:5px;
    height:5px;
    }
    #popbar::-webkit-scrollbar-track{
    background: rgb(239, 239, 239);
    border-radius:5px;
    }
    #popbar::-webkit-scrollbar-thumb{
    background: #bfbfbf;
    border-radius:10px;
    }
    #popbar::-webkit-scrollbar-thumb:hover{
    background: #999;
    }
    .menu-list {
        width: calc(100% - 350px);
        float:left;
        margin-left: 0;
        margin-top: 0;
        .menu-block {
            padding-top: 10px;
            &:first-child {
                padding-top: 30px;
            }
            .menu-title {
                line-height: 40px;
                margin-left: 10px;
                padding-left: 10px;
                margin-right: 30px;
                position:relative;
                h4 {
                    font-size: 16px;
                    color:#606266;
                    /* font-weight: 400; */
                    display:inline-block;
                    line-height:2;
                    margin-bottom:10px;
                    border-bottom:3px solid $--color-primary;
                }
                button.bare{
                    border: none; background: transparent; position: absolute; left: 78px; top: -3px; display:none;
                    svg { font-size: 24px; position: relative; top: 2px; }
                }
                &:hover button.bare{display:inline-block;}
            }
            .menu-cont {
                padding: 10px 0 10px 10px;
                overflow: hidden;
                .menu-item {
                    float: left;
                    width: 100px;
                    text-align: center;
                    margin-right: 30px;
                    margin-bottom: 20px;
                    /* img {
                        width: 50px;
                        height: 50px;
                        display: block;
                        margin: 0 auto;
                        margin-bottom: 8px;
                    } */
                    svg {font-size:30px;margin-bottom:8px;}
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .vertical-line{position:absolute;right:300px;background:#d8dce5;width:1px;min-height:100%;}
    .recently{
        float:right;width: 300px;
    }
    .zdset{
        position: absolute;
        top: 50px;
        // right: 40px;
        right: 155px;
        color: #333333;
        font-weight: bold;
        line-height: 2;
        width: 70px;
        text-align: center;
        &:hover{
            color: #409EFF;
            cursor: pointer;

        }
    }
    /deep/ .menu-item{
        margin-right: 5px  !important;
    }
    .img-box{
        text-align: center;
        position: relative;
        .radio{
            position: absolute;
            width: 21px;
            height: 21px;
            left: 32px;
            top: -6px;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .link-img{
        width: 36px;
        height: 36px;
        margin-bottom: 11px;
    }
</style>
