<template>
  <div>
    <div
      style="padding: 10px;     font-size: 14px;   display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;"
    >
      <div class="dj">
        登记日期：
        <el-date-picker
          v-model="value"
          class="riqi"
          type="month"
          placeholder="选择月"
          format="yyyy年M月"
          value-format="yyyy-M"
        />

      </div>
      <statistics-search :search-type="searchType" @statisticsClick="statistics" />
    </div>

    <div class="table-box">

      <el-table
        class="custom-table"
        style="width:100%"
        border
        :data="dataList"
        @row-click="rowClick"
      >
        <!-- <el-table-column
          type="selection"
          width="55"
        >
        </el-table-column> -->
        <el-table-column
          label="序号"
          width="60"
          type="index"
        >
          <template slot-scope="scope">
            <span style="margin-left: 10px">
              {{ scope.row.index }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          label="科室"
          disabled
        >
          <template slot-scope="scope">
            <div>{{ scope.row.orgName }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="考勤人数"
          width="80"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.kqNum }}</div>

          </template>
        </el-table-column>
        <el-table-column
          label="实有人数"
          width="80"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.orgPersonNum }}</div>

          </template>
        </el-table-column>
        <el-table-column
          label="科室负责人"
          disabled
          width="100"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.ksFzr }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="考勤员"
          disabled
          width="100"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.kqy }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="登记日期"
          width="150"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.registrationDate }}</div>
          </template>
        </el-table-column>

      </el-table>
    </div>
  </div>
</template>

<script>
import StatisticsSearch from '@/wapp/IndustryVersionCode/kqgl/kqhz/common/StatisticsSearch'
export default {
    components: {
        StatisticsSearch
    },
    props: {

    },
    data() {
        return {
            dataList: [],
            value: '',
            time: '',
            selectDept: '',
            content: '',
            searchType: [
                {
                    type: 'subOrg',
                    title: '科室'
                }
            ]
        }
    },
    computed: {
    },
    watch: {
        value(val) {
            console.log(val)
            const time = val.split('-')
            const y = time[0]
            const m = time[1]
            this.getDays(y, m)
        }
    },
    created() {
        this.$bus.emit('showNavSearch', false)
        const date = new Date()
        this.value = date.getFullYear() + '-' + (date.getMonth() + 1)
        const time = this.value.split('-')
        const y = time[0]
        const m = time[1]
        this.getDays(y, m)

        // this.getshuju()
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true)
    },
    mounted() {

    },
    methods: {
        rowClick(val) {
            console.log('valssss', val)
        },
        statistics(vals) {
            console.log('valssss', vals)
            this.selectDept = vals.subOrg.id
            console.log('this.selectDept', this.selectDept)
            this.$bus.emit('refreshListData')

            this.getshuju(this.time)
        },
        getshuju() {
            const data = {
                hzDate: this.value,
                staffDeptId: this.selectDept

            }
            this.$http({
                method: 'POST',
                url: '/yykq/kqhz/AttendanceReport',
                contentType: 'application/x-www-form-urlencoded',
                params: data
            }).then(res => {
                if (res.code === 'success') {
                    console.log('res', res)
                    this.dataList = res.body.list
                }
                this.dataList.forEach((item, index) => {
                    item.index = index + 1
                })
            })
        },
        tosave() {
            const date = {
                date: this.dataList
            }
            this.$http({
                method: 'PUT',
                contentType: 'application/x-www-form-urlencoded',
                data: date,
                url: '/yykq/kqhz/save'
            }).then(res => {
                if (res.code === 'success') {
                    this.datelist = res.body.listdata
                    this.$message.success('保存成功')
                    this.$bus.emit('refreshListData')
                }
            })
        },
        daoru() {

        },
        daochu() {

        },
        getWeekends(year, month) {
            const weekends = []
            const date = new Date(year, month - 1, 1)
            while (date.getMonth() === month - 1) {
                if (date.getDay() === 0 || date.getDay() === 6) {
                    weekends.push(date.getDate())
                }
                date.setDate(date.getDate() + 1)
            }

            return weekends.join(',')
        },
        getDays(year, month) {
            const days = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
            if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
                days[2] = 29
            }

            console.log(days[month])
            this.time = days[month]
        }
    }
}
</script>

<style scoped lang="scss">
.dj{
  padding: 10px !important;
}
.right {
  margin-right: 10px;
  margin-bottom: 10px;
  float: right;
  display: flex;
  justify-content: flex-end;
}
/deep/.el-table th.el-table__cell.is-leaf {
    border: 1px solid #EBEEF5 !important;
}
.table-box {
  width: 100%;
  padding: 0 10px;
}
/deep/.el-table__empty-block{
   border: 1px solid #EBEEF5 !important;
}
.el-table {
  overflow: hidden;
}
/deep/.el-table--scrollable-x .el-table__body-wrapper{
  overflow-x: auto !important;
}
/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: rgba(248, 247, 247, 0.87) !important;
}

/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
  margin: 0 2px !important;
  border-radius: 6px !important;
  background-color: rgba(199, 199, 199, 0.65) !important;
}

/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: hsla(240, 1%, 35%, 0.418)  !important;
}
</style>
