<template>
    <section class="app-main" :class="[{ 'hideTopMenus-app-main': !showTopMenus, 'hide-tags-view': !tagsView }, addPortalClass]">
        <el-scrollbar :native="false" tag="div" class="main-scrollbar">
            <navbar />
            <transition name="fade-transform" mode="out-in">
                <keep-alive :include="cachedViews">
                    <router-view :key="key" />
                </keep-alive>
            </transition>
        </el-scrollbar>
        <SelectDialog />
    </section>
</template>

<script>
import Navbar from './LocationNav/index'

export default {
    name: 'AppMain',
    components: {
        Navbar
    },
    data() {
        return {
            addPortalClass: ''
        }
    },
    computed: {
        cachedViews() {
            return this.$store.state.tagsView.cachedViews
        },
        key() {
            return this.$route.path
        },
        showTopMenus() {
            return this.$store.state.settings.showTopMenus
        },
        tagsView() {
            return this.$store.state.settings.tagsView
        }
    },
    created() {
        this.$bus.on('addPortalPageClass', this.addPortalPageClass)
    },
    methods: {
        addPortalPageClass(isadd, className) {
            // this.addPortalClass = (isadd ? 'portal-page-main ' : '') + (className || '')
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/wapp/common/styles/variables.module.scss';

.app-main {
    width: 100%;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    min-height: calc(100vh - #{$menuItemHeight} - #{$headerHeight} - 20px);

    margin-top: 10px;
    padding-left: 10px;
    /deep/ .main-scrollbar {
        //主页背景色修改
        //  background-color: #f8f8f8;
        width: 100%;
        height: 100%;
        & > .el-scrollbar__wrap {
            overflow-x: hidden;
            & > .el-scrollbar__view {
                margin-right: 10px;
                min-height: 100%;
                background: #fff;
            }
        }
    }
}
.children-main {
    width: 100%;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    min-height: calc(100vh - #{$menuItemHeight} - #{$headerHeight});
    // margin-top: 10px;
    //padding-left: 10px;
    /deep/ .main-scrollbar {
        //主页背景色修改
        //  background-color: #f8f8f8;
        width: 100%;
        height: 100%;
        & > .el-scrollbar__wrap {
            overflow-x: hidden;
            & > .el-scrollbar__view {
                //  margin-right: 10px;
                min-height: 100%;
                // background: #fff;
            }
        }
    }
}
// 添加外边框
.app-main,
.app-main .main-scrollbar {
    &::before {
        position: absolute;
        content: '';
        width: calc(100% - 20px);
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
        border-top: 1px solid #dee2e6;
        z-index: 1;
    }
    &::after {
        position: absolute;
        content: '';
        height: 100%;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
        border-left: 1px solid #dee2e6;
        z-index: 1;
    }
}
.children-main,
.children-main .main-scrollbar {
    &::before {
        position: absolute;
        content: '';
        width: calc(100% - 20px);
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
        // border-top: 1px solid #DEE2E6;
        z-index: 1;
    }
    &::after {
        position: absolute;
        content: '';
        height: 100%;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
        //border-left: 1px solid #DEE2E6;
        z-index: 1;
    }
}
// 外边框定位
.app-main {
    &::before {
        left: 10px;
        top: 0;
    }
    &::after {
        left: 10px;
        top: 0;
    }
    .main-scrollbar {
        &::before {
            left: 0px;
            bottom: 0;
            width: calc(100% - 10px);
        }
        &::after {
            right: 10px;
            top: 0;
        }
    }
}
.children-main {
    &::before {
        left: 10px;
        top: 0;
    }
    &::after {
        left: 10px;
        top: 0;
    }
    .main-scrollbar {
        &::before {
            left: 0px;
            bottom: 0;
            width: calc(100% - 10px);
        }
        &::after {
            right: 10px;
            top: 0;
        }
    }
}
// 首页样式类
.portal-page-main {
    &::before,
    &::after {
        display: none;
    }
    /deep/ .main-scrollbar {
        &::before,
        &::after {
            display: none;
        }
        & > .el-scrollbar__wrap {
            & > .el-scrollbar__view {
                background: none;
            }
        }
    }
    .navbar {
        background: none;
        margin: 0;
    }
    .app-container {
        background: none;
        padding: 10px 0 0;
    }
}
/*********/
.fixed-header + .app-main {
    margin-top: $menuItemHeight;
    overflow-y: auto;
    /* 110 = navbar + headerHeight = 50 + 60 */
    height: calc(100vh - #{$menuItemHeight} - #{$headerHeight} - 20px);
}
.fixed-header + .main-container {
    overflow: hidden;
}
.hide-tags-view {
    height: calc(100vh - #{$headerHeight} - 20px);
}
.fixed-header + .hideTopMenus-app-main {
    margin-top: 0px;
    /* 60 = headerHeight */
    height: calc(100vh - #{$headerHeight} - 20px);
}

.hasTagsView {
    .app-main {
        min-height: calc(100vh - #{$menuItemHeight} - #{$headerHeight} - 20px);
        height: calc(100vh - #{$menuItemHeight} - #{$headerHeight} - 20px);
        overflow-y: auto;
    }
    .children-main {
        min-height: calc(100vh - #{$menuItemHeight} - #{$headerHeight});
        height: calc(100vh - #{$menuItemHeight} - #{$headerHeight});
        overflow-y: auto;
    }
    .hideTopMenus-app-main {
        min-height: calc(100vh - #{$menuItemHeight} - #{$headerHeight} - 20px);
    }

    .fixed-header + .app-main {
        margin-top: calc(#{$menuItemHeight} + #{$menuItemHeight} + 10px);
        /* 160 = navbar + tagsView + headerHeight = 50 + 50 + 60  */
        height: calc(100vh - #{$menuItemHeight} - #{$menuItemHeight} - #{$headerHeight} - 20px);
    }
    .fixed-header + .hideTopMenus-app-main {
        margin-top: $menuItemHeight;
        /* 110 = headerHeight + tagsView = 60 + 50 */
        height: calc(100vh - #{$menuItemHeight} - #{$headerHeight} - 20px);
    }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
    .fixed-header {
        padding-right: 15px;
    }
}
</style>
