<template>
  <div>
    <span>{{ row.fileName }}</span>
  </div>
</template>

<script>
export default {
    props: {
        column: { type: Object, default: null },
        row: { type: Object, default: null },
        index: { type: Number, default: null }
    },
    created() {
        let names = ''
        for (let i = 0; i < 5; i++) {
            if (i + 1 > 1) {
                if (this.row['fileName' + i]) {
                    if (names !== '')names += '、'
                    names += this.row['fileName' + i]
                }
            } else {
                names += this.row['fileName']
            }
        }
        this.row.fileName = names
    }
}
</script>

<style scoped lang="scss">

</style>
