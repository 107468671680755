<template>
    <div class="wflow-container">
        <!-- 操作按钮 -->
        <CommandBar v-if="!loading" ref="commandBar" :command-bar="commandBar" />
        <div class="mainform-container" :class="{ 'wflow-container-loaded': !loading }">
            <div v-show="!formEvents" class="page-container">
                <div v-loading="loading" element-loading-text="正在加载..." />
            </div>
            <el-scrollbar v-show="formEvents" :native="false" tag="div">
                <div class="page-container">
                    <div class="form-container">
                        <!-- 处理状态 -->
                        <div class="form-header">
                            <div class="form-title">{{ vo.flFormName }}</div>
                            <div class="section-title">
                                <p class="l-text">当前状态：{{ curTN.tnNodeName === '' || !curTN.tnNodeName ? vo.wfNodeNames : curTN.tnNodeName }}</p>
                                <p class="r-text">业务单编号：{{ vo.flFormSerial }}</p>
                            </div>
                        </div>

                        <el-form :model="vo">
                            <div class="el-table el-table--fit el-table--border">
                                <div class="el-table__body-wrapper">
                                    <!-- 业务表单 -->
                                    <BusiForm ref="busiForm" :wf-instance="wfInstance" :vo="vo" :cur-t-n="curTN" />

                                    <!-- 上传正文/显示正文 -->
                                    <BodyFile v-if="(curTN.tnCanEdit == 1 && bodyFileEditor >= 2) || bodyfile != null" :body-file-title="$tx('ecwapp.Wflowform.BodyFile.fileBody', '文件正文')" :vo="vo" :cur-t-n="curTN" :fl-bodyfile="bodyfile" :attachments="attachments" :wf-attach-url="wfAttachUrl" :file-need-sign="fileNeedSign" accept=".doc,.docx,.pdf,.ofd" viewer-no="dcs=http://portal.ecinc.com.cn:18080/dcscloud" />

                                    <!-- 历史意见 -->
                                    <HandleIdea :wf-idea-list="wfIdeaList" :wf-json-data="wfJsonData" />
                                </div>
                            </div>
                        </el-form>

                        <!-- 富文本编辑器 -->
                        <RichtextEditor v-if="bodyFileEditor == 1" v-model="vo.flContent" />
                        <!-- Word编辑器 -->
                        <WebOffice v-if="bodyFileEditor === 2" />
                        <!-- H5在线编辑器 -->
                        <H5Office v-if="bodyFileEditor === 4" />
                        <!-- pdf预览 -->
                        <div v-if="bodyfile !== null && bodyfile.id">
                            <ViewBodyFile :fl-bodyfile="bodyfile" :wf-attach-url="wfAttachUrl" />
                        </div>

                        <!-- 提交办理 -->
                        <!--SubmitHandle v-if="wfJsonData!=undefined&&submitHandle!=null" :cur-t-n="curTN" :wf-instance="wfInstance" :submit-handle="submitHandle" :wf-json-data="wfJsonData" :common-idea-list="commonIdeaList" :submit-template-list="submitTemplateList" /-->
                        <SubmitDialog v-if="wfJsonData != undefined && submitHandle != null" :cur-t-n="curTN" :wf-instance="wfInstance" :submit-handle="submitHandle" :wf-json-data="wfJsonData" :common-idea-list="commonIdeaList" :submit-template-list="submitTemplateList" />

                        <!-- 附件 -->
                        <Attachment ref="attachments" :cur-t-n="curTN" :vo="vo" :fl-bodyfile="bodyfile" :attachments="attachments" :wf-attach-url="wfAttachUrl" />
                    </div>
                    <!-- form-container -->
                </div>
                <!-- page-container -->
            </el-scrollbar>
        </div>
        <!-- mainform-container -->
        <DoingDialog />
        <PrintFile />

        <WfNodelogList />
        <ModifyWfNodelog />
        <SelectOuterDeputy />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import WebOffice from '../../webOffice/WebOffice'
// import H5Office from '../../webOffice/H5Office'

// import PrintFile from './components/PrintFile'

export default {
    components: {

        // H5Office
    },
    props: {},
    data() {
        return {
            loading: true,
            wfParams: {},
            wfInstance: {},
            formEvents: null,
            vo: {},
            commandBar: [],
            wfIdeaList: [],
            wfJsonData: {},
            submitHandle: null,
            commonIdeaList: [],
            submitTemplateList: [],
            curTN: {},
            attachments: [],
            bodyFileEditor: 0,
            bodyfile: null,
            fileNeedSign: 0
        }
    },
    computed: {
        ...mapGetters(['routes', 'loginUser'])
    },
    watch: {
        formEvents() {
            if (this.formEvents !== null) {
                this.formEvents.onWflowFormOpened(this, this.wfParams, this.wfParams, this.wfEngineUrl)
            }
        }
    },
    created() {
        this.wfParams = this.$wfEngine.getWfParams(this)
    },
    beforeDestroy() {},
    mounted() {
        this.loadWfInstance()
        const cookieStyle = this.Cookies.get('uiStyle')
        let style = ''
        if (cookieStyle !== this.loginUser.uiStyle) {
            style = this.loginUser.uiStyle
            this.Cookies.set('uiStyle', style)
        } else {
            style = cookieStyle
        }
        document.querySelector('body').setAttribute('id', style)
    },
    methods: {
        loadWfInstance() {
            new Promise((resolve, reject) => {
                if (this.loginUser.staffList.length === 0 && this.wfParams.wfName !== undefined && this.wfParams.busiCode !== undefined) {
                    this.$alert('您的账号没有机构信息，不能创建、处理文件。', '操作提示', {
                        type: 'error',
                        callback: () => {
                            this.btnCloseClick()
                        }
                    })
                    reject('no staff')
                } else if (this.loginUser.staffList.length > 1 && this.wfParams.wfName !== undefined && this.wfParams.busiCode !== undefined) {
                    this.loginUser.staffList.forEach(function (item) {
                        item.value = item.id
                        item.text = item.orgFullName.substring(item.orgFullName.indexOf('-') + 1)
                    })

                    this.$bus.emit(
                        'openListitemDialog',
                        '选择任职部门',
                        this.loginUser.staffList,
                        (rst) => {
                            if (rst.code === 'success') {
                                this.wfParams.staffId = rst.value
                                resolve(true)
                            } else {
                                this.btnCloseClick()
                                reject('cancel')
                            }
                        },
                        null,
                        null,
                        '您在多个部门任职，请选择：'
                    )
                } else {
                    resolve(true)
                }
            }).then(() => {
                this.$wfEngine.loadWfInstance(this, this.wfParams).then((rst) => {
                    if (rst.code === 'success') {
                        if (this.wfInstance.udmNodes) {
                            document.title = this.vo.flFormName + ' - ' + this.wfInstance.title
                        } else {
                            document.title = this.wfInstance.wfName + ' - ' + this.wfInstance.title
                        }

                        let workform = this.curTN.workform || this.wfInstance.workForm
                        // workform = '@/wapp/ecoa/dzgg/form'
                        if (workform.indexOf('@/') === 0) {
                            workform = workform.replace(/@\/mapp/g, '@/wapp')
                            this.$options.components['BusiForm'] = this.$loadComponent(workform)

                            this.formEvents = {
                                onWflowFormOpened: this.$options.components['BusiForm'].methods.onWflowFormOpened,
                                onWflowFormBeforeSubmit: this.$options.components['BusiForm'].methods.onWflowFormBeforeSubmit,
                                onWflowFormSubmited: this.$options.components['BusiForm'].methods.onWflowFormSubmited
                            }
                            const formCompData = this.$options.components['BusiForm'].data()
                            if (formCompData.bodyFileEditor) {
                                this.bodyFileEditor = formCompData.bodyFileEditor
                                this.fileNeedSign = formCompData.fileNeedSign
                            }
                        } else if (workform.indexOf('/dcontent') !== -1) {
                            workform = workform.split('/dcontent')[1]
                            if (workform.substring(workform.length - 1) === '/') workform = workform.substring(0, workform.length - 1)
                            workform = '#/dcontent/vuebusiform/W/' + workform + '.vue?sysCode=' + this.wfInstance.sysCode
                            this.$options.components['BusiForm'] = this.$loadComponent(workform)
                        }

                        this.loading = false

                        this.$nextTick(() => {
                            const ideaLabel = document.querySelector('.cur-idea-label')
                            if (ideaLabel !== null) ideaLabel.innerHTML = this.curTN.tnIdeaLabelName
                        })

                        if (this.curTN.openedReadItemList && this.curTN.openedReadItemList.length > 0) {
                            const btnRefresh = window.opener.document.querySelector('.el-icon-refresh')
                            if (btnRefresh !== null) btnRefresh.parentElement.click()
                        }
                    } else {
                        this.$alert(rst.message, '操作提示', {
                            type: 'error',
                            callback: () => {
                                this.btnCloseClick()
                            }
                        })
                    }
                })
            })
        },
        btnCloseClick() {
            if (this.$route.query.itemurl) {
                history.back()
                this.$store.dispatch('tagsView/delView', this.$route)
            } else {
                window.close()
            }
        },
        btnSaveAndCloseClick() {
            this.btnSubmitClick('SaveAndClose')
        },
        btnSaveClick() {
            this.btnSubmitClick('Save')
        },
        btnDeleteClick() {
            this.$confirm('您是否确认注销文件？', '确认操作', { type: 'warning' }).then(() => {
                this.$bus.emit('showDoingDialog')
                this.$wfEngine.saveWfInstance(this, 'WriteOff', this.wfParams).then((res) => {
                    if (res.code === 'success') {
                        const btnRefresh = window.opener.document.querySelector('.el-icon-refresh')
                        if (btnRefresh !== null) btnRefresh.parentElement.click()

                        this.btnCloseClick()
                    } else {
                        this.$bus.emit('closeDoingDialog')
                        this.$alert(res.message, '操作提示', { type: 'error' })
                    }
                })
            })
        },
        btnOpenWordClick() {
            this.$bus.emit('btnOpenWordClick', this)
        },
        btnPrintWebFormClick() {
            this.$bus.emit('btnPrintWebFormClick', this)
        },
        btnPrintFileClick() {
            this.$bus.emit('btnPrintFileClick', this)
        },
        btnPrintGzClick() {
            this.$bus.emit('btnPrintGzClick', this)
        },
        btnForwardClick() {
            this.$bus.emit('btnForwardClick', this)
        },
        btnStampClick() {
            this.$bus.emit('btnStampClick', this)
        },
        btnStartDoingClick() {
            this.$bus.emit('btnStartDoingClick')
        },
        btnSubmitClick(actionType) {
            this.formValidate(() => {
                if (this.curTN.tnWaitNode) {
                    this.$alert('您需要等待“' + this.curTN.tnWaitNode + '”处理结束后，才能继续处理!', '操作提示', { type: 'warning' })
                    return
                }
                if (actionType === undefined) actionType = 'Submit'
                this.submitHandle.submitType = actionType

                if (actionType.indexOf('Submit') !== -1 && this.wfInstance.status < 1 && (this.bodyfile === null || this.bodyfile.name === '') && this.bodyFileEditor > 1 && this.fileIsMust !== 0) {
                    let errmsg = ''
                    if (this.bodyFileEditor === 2) {
                        errmsg = '请编辑正文！'
                    } else {
                        errmsg = '请上传正文！'
                    }
                    this.$alert(errmsg, '操作提示', { type: 'warning' })
                    return
                }

                new Promise((resolve, reject) => {
                    this.formEvents.onWflowFormBeforeSubmit(this, { resolve, reject })
                }).then((result) => {
                    if (!result) return

                    const res = this.$wfEngine.checkSubmitHandle(this, actionType)
                    if (res.code !== 'success') {
                        this.$alert(res.message, '操作提示', { type: 'warning' })
                        return
                    }
                    new Promise((resolve, reject) => {
                        if (actionType === 'Submit') {
                            this.$confirm('您是否确认' + this.wfJsonData.routes[this.submitHandle.route.index].name + '？', '确认操作', { type: 'warning' })
                                .then(() => {
                                    resolve(true)
                                })
                                .catch((err) => {
                                    if (err !== 'cancel') {
                                        console.error(err)
                                    }
                                    reject(err)
                                })
                        } else {
                            resolve(true)
                        }
                    }).then((result) => {
                        if (actionType !== 'AutoSave') this.$bus.emit('showDoingDialog')

                        this.$wfEngine.checkOuterDeputy(this, this.wfParams).then((res) => {
                            if (res.code !== 'success') {
                                this.$bus.emit('closeDoingDialog')
                                this.$alert(res.message, '操作提示', { type: 'error' })
                            } else {
                                this.$refs.attachments.uploadWflowAttach(this).then((res) => {
                                    if (res.code === 'success') {
                                        this.$wfEngine.saveWfInstance(this, actionType, this.wfParams).then((res) => {
                                            if (res.code === 'success') {
                                                try {
                                                    const btnRefresh = window.opener.document.querySelector('.el-icon-refresh')
                                                    if (btnRefresh !== null) btnRefresh.parentElement.click()
                                                } catch (e) {
                                                    console.log(e)
                                                }

                                                this.formEvents.onWflowFormSubmited(this)

                                                this.$bus.emit('closeDoingDialog')

                                                if (actionType.indexOf('Save') === -1) {
                                                    // 不等于Save、AutoSave、SaveAndReload
                                                    setTimeout(() => {
                                                        this.btnCloseClick()
                                                    }, 1000)
                                                } else if (actionType === 'Save' || actionType === 'SaveAndReload' || actionType === 'FlowAdminSave') {
                                                    this.$message.success(res.message)

                                                    if (this.$route.query.busiCode !== undefined) {
                                                        const wfParams = { wfEngineUrl: this.wfParams.wfEngineUrl }
                                                        wfParams.wfInstanceId = res.body.wfInstance.id
                                                        wfParams.wfNodelogId = res.body.curTN.tnID

                                                        this.$router.replace({ path: '/wflow', query: wfParams })

                                                        actionType = 'SaveAndReload'
                                                    }

                                                    if (actionType === 'SaveAndReload' || actionType === 'Save') {
                                                        setTimeout(() => {
                                                            location.reload()
                                                        }, 500)
                                                    }
                                                } else if (actionType === 'SaveAndClose') {
                                                    setTimeout(() => {
                                                        this.btnCloseClick()
                                                    }, 500)
                                                }
                                            } else {
                                                this.$bus.emit('closeDoingDialog')
                                                this.$alert(res.message, '操作提示', { type: 'error' })
                                            }
                                        })
                                    } else {
                                        this.$bus.emit('closeDoingDialog')
                                        this.$alert(res.message, '操作提示', { type: 'error' })
                                    }
                                })
                            }
                        })
                    })
                })
            })
        },
        btnViewflowlogClick() {
            this.$bus.emit('showWfNodelogList', this.wfInstance)
        },
        btnModifyFlowLog(modifyType) {
            this.$bus.emit('showModifyWfNodelog', this.wfInstance, this.curTN, modifyType, this.modifyWfNodelogUrl)
        },
        getCookie(cname) {
            var name = cname + '='
            var ca = document.cookie.split(';')
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i].trim()
                if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
            }
            return ''
        }
    }
}
</script>

<style scoped lang="scss">
@import '~@/wapp/common/styles/variables.module.scss';

.wflow-container {
    background-color: #f3f3f3;
    font-weight: 400;
    font-size: $contentFontSize;
    .mainform-container {
        height: calc(100vh - 50px - 60px);
        overflow-y: auto;
        overflow-x: hidden;
        .el-scrollbar {
            width: 100%;
            height: 100%;
            /deep/ .el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    height: calc(100vh - 40px - 50px - 60px) !important;
                }
            }
        }

        .page-container {
            width: 1000px;
            min-height: 100%;
            background: #fff;
            margin: 0 auto;

            .form-container {
                width: 824px;
                margin: 0 auto;
                padding-top: 30px;
                padding-bottom: 30px;

                .form-header {
                    .form-title {
                        font-size: 24px;
                        font-weight: normal;
                        text-align: center;
                        color: #545454;
                    }

                    .section-title {
                        overflow: hidden;
                        // height: 22px;
                        line-height: 22px;

                        .l-text {
                            float: left;
                            margin-bottom: 0px;
                        }
                        .r-text {
                            float: right;
                            margin-bottom: 0px;
                        }
                    }
                }
            }

            /deep/ .el-loading-mask {
                margin-top: 100px;
            }
        }
    }
}
/deep/ .el-form .el-table__body {
    line-height: normal;
}
.wflow-container-loaded {
    height: calc(100vh - 40px - 50px - 60px) !important;
}
</style>
