<template>
  <div style="height: 100%;">
    <excelview v-if="show" v-show="!online" :down-url="url" :is-down-load="isDownLoad" :allow-edit="edit" :online="online" :print="print" :file-name="fileName" :data-type="dataType" :temp-array="tempArray" :temp-object="tempObject" :auto-save="false" :format-array="[]" @close="close" @saveExcel="saveExcel" />
  </div>
</template>

<script>
import excelview from '@/wapp/common/components/ExcelView/index'
export default {
    components: {
        excelview
    },
    props: {

    },
    data() {
        return {
            url: '',
            fileName: '',
            rpId: '',
            voId: '',
            show: false,
            tempObject: {},
            tempArray: [],
            dataType: 1,
            edit: true,
            online: false,
            print: false,
            isDownLoad: 0
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        this.fileName = this.$route.query.attachName
        // this.fileName = this.fileName.substring(0, this.fileName.lastIndexOf('.'))
        this.edit = this.$route.query.canEdit == 1
        this.online = this.$route.query.online == 'true'
        this.print = this.$route.query.print == 'true'
        this.isDownLoad = this.$route.query.isDownLoad || 0
        document.title = this.$route.query.attachName
        this.rpId = this.$route.query.rpId
        this.voId = this.$route.query.voId
        if (this.rpId) {
            this.getRpInfo(this.rpId)
        }
        if (this.voId) {
            this.getRpDate(this.voId)
        }
        console.log('this.$route.query.attachIds', this.$route)
        this.url = this.$route.query.url + '&attachIds=' + this.$route.query.attachIds
    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
        getRpDate(id) {
            this.$http({
                url: '/znbg/dataRp/dataRpInfoCollect',
                method: 'get',
                params: {
                    id: id
                }
            }).then(res => {
                if (res.code === 'success') {
                    this.show = true
                    if (res.body.rpInfo) {
                        this.tempArray = JSON.parse(res.body.rpInfo)
                        this.dataType = 2
                        console.log(63, this.tempArray)
                    }
                }
            })
        },
        getRpInfo(id) {
            this.$http({
                url: '/znbg/dataRpInfo/getById?id=' + id,
                method: 'get'
            }).then(res => {
                console.log(51, JSON.parse(res.body.vo.rpInfo))
                this.show = true
                if (res.body.vo.rpInfo) {
                    if (res.body.vo.rpInfo.indexOf('{') > -1) {
                        this.dataType = 1
                        this.tempObject = JSON.parse(res.body.vo.rpInfo)
                    }
                }
            })
        },
        saveExcel(data, flag) {
            this.$http({
                url: '/znbg/dataRpInfo/updateDataRpInfo?id=' + this.rpId,
                method: 'post',
                data: {
                    rpInfo: data
                }
            }).then(res => {
                if (res.code === 'success') {
                    this.$message.success(res.message)
                    if (flag) {
                        window.close()
                    }
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        close() {
            window.close()
        }

    }
}
</script>

<style scoped lang="scss">
</style>
