module.exports = {
    navbar: {
        logOut: 'Salir',
        dashboard: 'Panel de control',
        github: 'Github',
        theme: 'Tema',
        size: 'Tamaño global',
        profile: 'Profile'
    },
    login: {
        title: 'Formulario de acceso',
        logIn: 'Acceso',
        username: 'Usuario',
        password: 'Contraseña',
        any: 'nada',
        thirdparty: 'Conectar con',
        thirdpartyTips: 'No se puede simular en local, así que combine su propia simulación de negocios. ! !'
    },
    tagsView: {
        refresh: 'Actualizar',
        close: 'Cerrar',
        closeOthers: 'Cerrar otros',
        closeAll: 'Cerrar todos'
    },
    settings: {
        title: 'Page style setting',
        theme: 'Theme Color',
        tagsView: 'Open Tags-View',
        fixedHeader: 'Fixed Header',
        showTopMenus: 'Open TopMenu'
    }
}
