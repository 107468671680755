<template>
  <div class="top-tools">
    <div class="l">

      <statistics-search :search-type="searchType" @statisticsClick="statistics" />

    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatisticsSearch from '@/wapp/common/components/StatisticsSearch'
export default {
    name: 'OperateBtn',
    components: {
        StatisticsSearch
    },
    props: {
        tbData: { type: Array, default() { return [] } },
        tbTitle: { type: Array, default() { return [] } }
    },
    data() {
        return {
            searchType: [
                {
                    type: 'subOrg',
                    title: '部门'
                }

            ]

        }
    },
    computed: {
        ...mapGetters([
            'loginUser'
        ])
    },
    created() {

    },
    methods: {
        statistics(val) {
            const selectDeptId = val.subOrg.id != null ? val.subOrg.id : ''
            this.$emit('statistics-click', { selectDeptId: selectDeptId })
        }

    }
}
</script>

<style scoped lang="scss">
.top-tools, .breadcrumb {
    overflow: hidden;
    margin-top: 10px;
    padding: 0 15px;
    position:relative;
    z-index: 999;
    .l {
      float: left;
      display: flex;
      align-items: center;
      justify-content: space-between;

      // width: 480px;
      .top-text{
         height: 100%;
         margin: 0 auto;
         margin-left: 40px;
      }
      .btn{
        margin-right: 30px;
      }
    }
    .r {
      float: right;
      width: 200px;
      text-align: right
    }
}

</style>

