<template>
  <tr class="td-table">
    <td class="el-table__cell tdcont tdw17" colspan="1">
      <el-input v-if="canEdit" v-model="fieldData.classesNumber" placeholder="请输入" clearable style="width:100%"></el-input>
      <span v-else>{{ fieldData.classesNumber }}</span>
    </td>
    <td class="el-table__cell tdcont tdw33" colspan="1">
      <el-input v-if="canEdit" v-model="fieldData.timeName" placeholder="请输入" clearable style="width:100%"></el-input>
      <span v-else>{{ fieldData.timeName }}</span>
    </td>
    <td class="el-table__cell tdcont" colspan="2">
      <div class="tdcont-flex">
        <template v-if="canEdit">
          <el-time-select
            v-model="fieldData.logonTime"
            :clearable="false"
            style="flex: 1"
            placeholder="请选择时间"
            :picker-options="{
              start: '00:00',
              end: '23:59'
            }"
            @change="calculatingShiftDuration(fieldData)"
          >
          </el-time-select>
          <span style="margin:0 10px;">至</span>
          <el-time-select
            v-model="fieldData.logoffTime"
            placeholder="请选择时间"
            :clearable="false"
            style="flex: 1"
            :picker-options="{
              start: '00:00',
              end: '23:59',
              minTime: fieldData.logonTime
            }"
            @change="calculatingShiftDuration(fieldData)"
          >
          </el-time-select>
        </template>
        <span v-else>{{ fieldData.logonTime }} 至 {{ fieldData.logoffTime }}</span>

      </div>
    </td>
    <td class="el-table__cell tdcont" colspan="1" style="">
      <template v-if="canEdit"><el-input v-model="fieldData.workHours" style="width:40px"></el-input> 小时</template>
      <span v-else>{{ fieldData.workHours }} 小时</span>
    </td>
    <td class="el-table__cell tdcont" colspan="1">
      <span v-if="canEdit" class="row_action" @click="$emit('handleDelete');$emit('handle-delete')">删除</span>
      <span v-else style="color:#666666">删除</span>
    </td>
  </tr>
</template>

<script>
export default {
    props: {
        fieldData: { type: Array, require: true, default: () => {} },
        canEdit: { type: [Boolean, Number], require: true, default: true }
    },
    watch: {
        fieldData: {
            deep: true,
            handler(val) {
                if (val.logonTime && val.logoffTime) {
                    this.fieldData.timeSpan = this.fieldData.logonTime + '-' + this.fieldData.logoffTime
                }
                this.$emit('update:field-data', val)
            }
        }
    },
    methods: {
        // 计算排班时长
        calculatingShiftDuration(item) {
            if (item.logonTime === null || item.logoffTime === null) {
                return
            }
            const params = {
                startTime: item.logonTime,
                endTime: item.logoffTime
            }
            this.$http({
                url: '/yykq/timeInterval/calculatingShiftDuration',
                methods: 'GET',
                params
            }).then(function(res) {
                if (res.code === 'success' && res.body.workTime && res.body.workHours) {
                    item.workHours = res.body.workHours
                    item.workTime = res.body.workTime
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.td-table{
  td{
    text-align: center !important;
    color: #666666 !important;
    font-weight: 600 !important;
  }
}
.row_action:hover{
  color:#409eff;
  text-decoration: underline;
}
.tdcont-flex{
  display: flex;
}
</style>
