<template>
  <div id="help-root">
    <div class="helpHeader">
      <div class="headerLogo">
        <img :src="logo" style="height: 36px;width: 36px;">
        <div style="margin-left: 10px;line-height: 50px;font-size: 16px;">
          {{ logoTitle }}
        </div>
      </div>
      <div v-if="isAdmin" class="headerBtn">
        <el-button v-if="!showEdit" icon="el-icon-edit" @click="editModule">修改目录</el-button>
        <el-button v-if="!showEdit" icon="el-icon-edit" style="margin-right: 10px;" @click="edit">修改内容</el-button>
        <el-button v-if="!showEdit" icon="el-icon-download" style="margin-right: 10px;" @click="open">导入内容</el-button>
        <el-button v-if="showEdit" icon="el-icon-close" style="margin-right: 10px;" @click="cancel(vo)">取消</el-button>
        <el-button v-if="showEdit" icon="el-icon-check" style="margin-right: 10px;" @click="save(vo)">保存</el-button>
      </div>
    </div>
    <div class="help-box">
      <div class="help-sider">
        <helpSider v-if="dataList.length>0" :type-data="dataList" :default-data="typeData" @clickItem="getItem"></helpSider>
        <ArticleCateTree v-if="showTree" />
      </div>
      <div v-if="!showTree&&!showEdit" id="help-con" class="help-content">
        <!-- <template v-if="JSON.stringify(result)!=='{}'">
          <div class="title">{{ result.title }}</div>
          <div id="content-id" class="content-box">
            <div v-html="result.documentInfo"></div>
          </div>
        </template> -->
        <template v-for="item in resultList">
          <div v-if="JSON.stringify(item)!=='{}'" :id="'item_' + item.id" :key="item.id" style="padding-bottom: 80px;">
            <div v-if="item.showTitle===1" class="title">{{ item.title }}</div>
            <div id="content-id" class="content-box">
              <div v-html="item.documentInfo"></div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="showEdit" class="richtext-content">
        <el-input v-model="vo.title" placeholder="请输入标题"></el-input>
        <RichtextEditor
          v-model="vo.documentInfo"
          :toolbar="'full'"
          :height="650"
          :image-upload-url="base_api+'/base/helpDocument/uploadBodyPicture?module=helpDocument&busiDataId='+vo.id+'&attachsType=BODY&orgCode='+loginUser.orgCode"
          :image-browse-url="'plugins/ckeditor/plugins/image/dialogs/browsePicture.html?apiurl='+base_api+'/portal/attachment/browsePictureSet&orgCode='+loginUser.orgCode"
          :video-browse-url="'plugins/ckeditor/plugins/html5video/dialogs/browseVideo.html?apiurl='+base_api+'/portal/attachment/browseVideo&orgCode='+loginUser.orgCode"
        />
      </div>
      <div v-if="headings.length>0" id="help-rig" class="help-right">
        <h4 style="color: #504b4b;">本页目录</h4>
        <div class="right-contet">
          <div v-for="(item,index) in headings" :id="'outline-'+index" :key="index" :class="itemIndex==='outline-'+index?'active':''" :data-target="'#'+item.id" @click="scrollTo('#'+item.id,'outline-'+index)">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <el-dialog
      title="导入模块菜单"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <div>
        <el-form>
          <div class="el-table el-table--fit el-table--border" style="width:824px;margin-top:10px">
            <div class="el-table__body-wrapper">
              <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
                <tbody>
                  <tr>
                    <td class="el-table__cell tdlbl tdw17">产品</td>
                    <td class="el-table__cell tdcont tdw83" colspan="5">
                      <el-input v-model="selectData.sysName" placeholder="选择产品">
                        <el-button slot="append" icon="el-icon-more" @click="selectClo" />
                      </el-input>
                    </td>
                  </tr>
                  <tr>
                    <td class="el-table__cell tdlbl tdw17">上传文件</td>
                    <td class="el-table__cell tdcont tdw83" colspan="5">
                      <div class="row-col">
                        <el-upload
ref="upload"
                                   :on-change="handleUploadChange"
                                   action
                                   :multiple="false"
                                   :limit="1"
                                   :file-list="fileList"
                                   :show-file-list="true"
                                   :auto-upload="false"
                                   :on-remove="handleRemove"
                                   accept="[doc,docx]"
                        >
                          <el-button slot="trigger" type="text">选择文件</el-button>
                        </el-upload>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="el-table__cell tdlbl tdw100" colspan="6">
                      <div style="text-align: start;">
                        <div style="line-height: 26px;">
                          1、请先下载   <a href="/templates/helpDetail.doc" target="_blank"><span style="color:red;width:100%;">DOC导入示例</span></a>
                          <div>2、导入文件应为Doc、DOCX文件；</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="disBtn" @click="importFile">导 入</el-button>
      </span>
    </el-dialog>
    <select-dialog />
    <el-dialog
      title="修改目录"
      :visible.sync="dialogVisibleModule"
      width="60%"
      @close="ok"
    >
      <div style="display:flex">
        <div style="flex:0.3" class="left-tree">
          <ArticleCateTree />
        </div>
        <div style="flex:0.7" class="right-tree">
          <treeForm v-if="showForm" :vo="treeVo" :can-edit="0" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleModule = false">取 消</el-button>
        <el-button type="primary" @click.stop="ok">确 定</el-button>
      </span>
    </el-dialog>
    <Basicform
      :title="formTitle"
      :win-width="moduleInfo.winWidth"
      :win-height="moduleInfo.winHeight"
      :toolbar-btns="busiformBtns"
      :on-basic-form-before-open="onBasicFormBeforeOpen"
      :on-basic-form-opened="onBasicFormOpened"
      :on-basic-form-before-submit="onBasicFormBeforeSubmit"
      :on-basic-form-submited="onBasicFormSubmited"
    >
      <template #default="slotProps">
        <busi-form ref="formItem" :vo="slotProps.vo" :can-edit="slotProps.canEdit" :module-info="moduleInfo" />
      </template>
    </Basicform>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { debounce } from '@/global/utils'
import helpSider from './components/helpSider.vue'
import ArticleCateTree from './components/ArticleCateTree.vue'
import treeForm from './components/treeForm.vue'
export default {
    components: {
        helpSider, ArticleCateTree, treeForm
    },
    props: {

    },
    data() {
        return {
            logo: './images/logo.png',
            formTitle: '帮助中心分类',
            itemIndex: 'outline-0',
            logoTitle: '易臣云办公帮助中心',
            result: '',
            resultList: [], // 内容Array，遍历渲染
            resultHeightList: [], // 内容高度，用于监听滚动后改变左侧菜单
            loading: false,
            typeData: [],
            headings: [],
            showForm: true,
            base_api: '',
            dialogVisible: false,
            isClick: false,
            showEdit: false,
            dataList: [],
            fileList: [],
            dialogVisibleModule: false,
            selectData: {
                sysCode: '',
                sysName: ''
            },
            fd: '',
            isAdmin: false,
            disBtn: false,
            isAdd: false,
            typeId: '',
            vo: {},
            treeVo: {},
            showTree: false,
            loginUser: this.$store.getters.loginUser,
            moduleInfo: {
                'formTemplate': 'dcontent/busiformurl?vwtype=W&url=/base/helpDocumentMenu/form.html',
                'winWidth': 1000,
                'delUrl': 'base/helpDocumentMenu/delete',
                'addNewUrl': 'base/helpDocumentMenu/addNew',
                'insertUrl': 'base/helpDocumentMenu/insert',
                'moduleCode': 'helpDocumentMenu',
                'listAdvSearchExpandPanel': 0,
                'winHeight': 600,
                'updateUrl': 'base/helpDocumentMenu/update',
                'listDataUrl': 'base/helpDocumentMenu/listdata',
                'getByIdUrl': 'base/helpDocumentMenu/getById',
                'listAdvSearchAllFields': 0
            }
        }
    },
    computed: {
        ...mapGetters([
            'loginUser',
            'routes'
        ])
    },
    watch: {
    },
    created() {
        this.getRole()
        this.base_api = process.env.VUE_APP_BASE_API
        this.$bus.on('openCateForm', this.openPrivetForm)
        this.$bus.on('treeClick', this.treeNode)
    },
    beforeDestroy() {
        this.$bus.off('openCateForm', this.openPrivetForm)
        this.$bus.off('treeClick', this.treeNode)
    },
    mounted() {
        this.getData()
        const helpConEl = document.getElementById('help-con')
        helpConEl.addEventListener('scroll', () => {
            // 防抖执行scrollChange方法，方法内有循环，避免高频率触发循环
            this.scrollChange()
            // 加载更多（预留50高度触发阈值）
            if (helpConEl.scrollTop + helpConEl.clientHeight >= helpConEl.scrollHeight - 50) this.loadMore()
            if (helpConEl.scrollTop > 0) {
                helpConEl.style.margin = '0 auto'
            } else {
                helpConEl.style.margin = '20px auto'
            }
            if (!this.isClick) {
                console.log(145, '调用了')
                this.setActiveOutline()
            }
        })
    },
    methods: {
        // 滚动控制
        scrollChange: debounce(function() {
            const scrollTop = document.getElementById('help-con').scrollTop
            const { resultHeightList } = this
            let current = ''
            let countHeight = ''
            for (let index = 0; index < resultHeightList.length; index++) {
                // 高度相加，小于当前高度和，取当前typeId
                countHeight = index === 0
                    ? resultHeightList[index]
                    : countHeight + resultHeightList[index]
                if (scrollTop < countHeight) {
                    current = this.resultList[index].typeId
                    this.vo = this.resultList[index]
                    this.result = this.resultList[index]
                    break
                }
            }
            if (current) this.typeData = current
        }, 20),
        /**
         * @description: 根据菜单id获取下一项的菜单id
         * @param {*} array 菜单tree
         * @param {*} currId 当前菜单id
         * @return {*} Promise 返回值 下一项的菜单object
         */
        async findNextId(array, currId) {
            let resultIndexes = []
            // 递归获取当前id菜单项的下标数组，从外到内层级对应从下标0开始
            // 例： [2,1,3] 第1层第三项的 - children的第二项的 - children的第四项
            const findIndexes = (arr, id, indexes = []) => {
                arr.forEach((item, index) => {
                    const currIndexes = [...indexes]
                    currIndexes.push(index)
                    if (item.id === id) {
                        resultIndexes = currIndexes
                        return
                    } else if (item.children) {
                        findIndexes(item.children, id, currIndexes)
                    }
                })
            }
            // 通过 下标数组 获取下一项
            // 规则：同层不存在下一项，冒泡获取上一层的下一项
            const getNext = (indexes, arr) => {
                const currIndexes = [...indexes]
                const idx = currIndexes.shift()
                if (currIndexes.length) {
                    return getNext(currIndexes, arr[idx].children) || arr[idx + 1]
                } else {
                    return arr[idx + 1]
                }
            }
            // 获取最底层item
            const getFirstChild = (item) => {
                return item.children ? getFirstChild(item.children[0]) : item
            }

            findIndexes(array, currId)
            return getFirstChild(getNext(resultIndexes, array))
        },
        getRole() {
            this.$store.getters.roles.map((item) => {
                if (item === 'dwAdministrators') {
                    this.isAdmin = true
                    return
                }
            })
        },
        ok() {
            this.getData()
            this.dialogVisibleModule = false
            location.reload()
        },
        treeNode(node) {
            this.treeVo = {}
            this.showForm = false
            this.$nextTick(() => {
                this.treeVo = JSON.parse(JSON.stringify(node))
                this.showForm = true
            })
        },
        editModule() {
            this.dialogVisibleModule = true
        },
        openPrivetForm(vo, canEdit) {
            let formurl = this.moduleInfo.formTemplate.split('url=')[1]
            formurl = formurl.replace(/\//g, '.')
            formurl = '#/dcontent/vuebusiform/W/' + formurl.substring(1).replace('.html', '.vue')
            formurl += '?sysCode=' + (this.$route.meta.sysCode || '')
            this.$options.components['BusiForm'] = this.$loadComponent(formurl)
            this.$nextTick(() => {
                this.$bus.emit('openBusiform', this.moduleInfo, vo, canEdit)
            })
        },
        /**
          * 表单打开完成，vo为已加载的
          */
        onBasicFormOpened($scope, vo) {
            console.log('pictureCate form opened')
        },
        /**
          * 表单提交检查，可执行业务数据高级校验，vo为待提交，校验成功：defer.resolve(true)，校验失败：defer.reject(reason)
          */
        onBasicFormBeforeSubmit($scope, vo, defer) {
            if (!vo.parentId) {
                if (localStorage.addVoCate) {
                    const cate = JSON.parse(localStorage.addVoCate)
                    vo.parentId = cate.id
                    vo.menuFullId = cate.id + '-' + vo.id
                    vo.menuFullName = cate.name + '-' + vo.menuName
                    vo.menuLevel = Number(cate.level + 1)
                    if (vo.parentId !== '0') {
                        vo.sysCode = cate.sysCode
                        vo.sysName = cate.sysName
                    }
                } else {
                    vo.parentName = '帮助中心'
                    vo.parentId = '0'
                    vo.fullId = '0-' + vo.id
                    vo.fullName = '帮助中心-' + vo.name
                    vo.fullOrder = vo.orderNo
                }
            }

            if (!vo.menuLevel) {
                vo.menuLevel = 1
            }

            var reg = /^[0-9]\d*$/
            if (!reg.test(vo.orderNo) || vo.orderNo.length < 4) {
                this.$alert('请输入四位整数排序', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                })
                defer.reject()
            } else {
                defer.resolve(true)
            }
            console.log('pictureCate form submit validation success')
        },
        /**
          * 表单提交完成，vo为已保存成功，窗口即将关闭，不需要返回值
          */
        onBasicFormSubmited($scope, vo) {
            console.log('pictureCate form submited')
            this.$bus.emit('setOrgItemTree')
            // this.getData()
        },
        selectClo() {
            var el = {
	       		attrs: {
	           		'listviewurl': 'base.product.list',
	           		'dialog-title': '选择产品',
	           		'exclude-fields': '',
	           		'retain-btns': '',
	           		'multiple': false
	        		 }
	       		}
	 				const vnode = {
	          		context: this,
	        		 'data': { model: { expression: '' }}
	       			}
	      		this.$bus.emit('openListpageDialog', el, null, vnode)
        },
        onSelectListpageRowCompleted(nodes, attr) {
            this.selectData = {
                sysCode: '',
                sysName: ''
            }
            this.selectData.sysCode = nodes[0].code
            this.selectData.sysName = nodes[0].name
        },
        handleRemove(file, fileList) {
            this.fileList = []
        },
        open() {
            this.selectData = {
                sysCode: '',
                sysName: ''
            }
            this.fileList = []
            this.dialogVisible = true
        },
        edit() {
            this.showEdit = true
            if (JSON.stringify(this.vo) === '{}') {
                this.$http({
                    method: 'post',
                    url: '/base/helpDocument/addNew'
                }).then(res => {
                    this.vo = res.body.vo
                    this.isAdd = true
                })
            } else {
                this.isAdd = false
            }
        },
        cancel(vo) {
            this.showEdit = false
            this.showTree = false
            this.getItem(vo.typeId)
        },
        save(vo) {
            if (this.isAdd) {
                vo.status = 1
                vo.typeId = this.typeId
                this.$http({
                    method: 'post',
                    url: '/base/helpDocument/insert',
                    data: vo
                }).then(res => {
                    if (res.code === 'success') {
                        this.$message.success(res.message)
                        this.fileList = []
                        this.getItem(vo.typeId)
                    } else {
                        this.$message.error(res.message)
                    }
                })
            } else {
                this.$http({
                    method: 'put',
                    url: '/base/helpDocument/update',
                    data: vo
                }).then(res => {
                    if (res.code === 'success') {
                        this.$message.success(res.message)
                        this.fileList = []
                        this.getItem(vo.typeId)
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }

            this.showEdit = false
        },
        importFile() {
            if (!this.selectData.sysCode || !this.selectData.sysName) {
                this.$message.error('请选择产品')
                return
            }
            if (this.fileList.length === 0) {
                this.$message.error('请选择文件')
                return
            }
            this.disBtn = true
            this.$http({
                method: 'post',
                url: '/base/helpDocumentMenu/importMenu',
                data: this.fd,
                params: {
                    sysCode: this.selectData.sysCode,
                    sysName: this.selectData.sysName
                }
            }).then(res => {
                if (res.code === 'success') {
                    this.$message.success(res.message)
                    this.disBtn = false
                    this.getData()
                } else {
                    this.$message.error(res.message)
                }
                this.dialogVisible = false
            })
        },
        isDocFile(filename) {
            var pattern = /\.docx?$/i // 匹配以.doc或.docx结尾的字符串，忽略大小写
            return !pattern.test(filename)
        },
        handleUploadChange(file) {
            if (this.isDocFile(file.raw.name)) {
                this.$message.error('请上传doc文件或者docx文件')
                this.fileList = []
                return
            }
            this.fd = ''
            const fd = new FormData()
            fd.append('file', file.raw)
            this.fd = fd
            this.fileList = [file]
        },
        setActiveOutline() {
            const activeNodeId = this.getActiveNodeId()
            const outlineNodes = document.querySelectorAll('[id^="outline"]')
            // 添加样式或其他处理
            if (activeNodeId) {
                for (const node of outlineNodes) {
                    if (node.dataset.target === activeNodeId) {
                        node.classList.add('active')
                    } else {
                        node.classList.remove('active')
                    }
                }
            }
        },
        getActiveNodeId() {
            const outlineNodes = document.querySelectorAll('[id^="outline"]')
            const dom = document.getElementById('help-con')
            const scrollY = dom.scrollTop || dom.pageYOffset // 滚动距离
            const nodeParentTop = document.getElementById('content-id').getBoundingClientRect().top // 内容信息盒子的顶部距离
            const nodeParentHeight = document.getElementById('content-id').clientHeight
            const heightArray = []
            const diffArray = []
            for (const item of outlineNodes) {
                const targetId = item.dataset.target
                const targetNode = dom.querySelector(targetId)
                if (targetNode) {
                    heightArray.push(targetNode.getBoundingClientRect().top - nodeParentTop)
                }
            }
            for (let i = 1; i < heightArray.length; i++) {
                const diff = heightArray[i] - heightArray[i - 1]
                diffArray.push(diff)
            }
            let sum = 0
            diffArray.forEach(item => {
                sum += item
            })
            diffArray.push(nodeParentHeight - sum)
            for (let i = 0; i < outlineNodes.length; i++) {
                const node = outlineNodes[i]
                const targetId = node.dataset.target
                const targetNode = dom.querySelector(targetId)
                if (targetNode) {
                    const { top } = targetNode.getBoundingClientRect()
                    const height = diffArray[i]
                    if (top <= scrollY + (dom.clientHeight / 2) && top + height >= scrollY + (dom.clientHeight / 2)) {
                        return targetId
                    }
                }
            }
            return null
        },
        scrollTo(selector, domId) {
            this.isClick = true
            this.itemIndex = ''
            document.querySelector(selector).scrollIntoView({
                behavior: 'smooth'
            })
            setTimeout(() => {
                this.isClick = false
            }, 1500)
            const allDom = document.querySelectorAll('.right-contet>div')
            allDom.forEach(item => {
                if (domId === item.id) {
                    // this.itemIndex = domId
                    document.getElementById(item.id).classList.add('active')
                } else {
                    document.getElementById(item.id).classList.remove('active')
                }
            })
        },
        // 触底 - 加载更多
        loadMore() {
            this.findNextId(this.dataList, this.resultList[this.resultList.length - 1].typeId)
                .then(res => res && this.getItem(res.id, true))
        },
        getData() {
            this.$http({
                method: 'post',
                url: '/base/helpDocumentMenu/querydata',
                data: [{
                    'compare': '=',
                    'field': 'status',
                    'merge': true,
                    'value': 1
                }],
                params: { 'orderBy': 'orderNo asc' }
            }).then(res => {
                if (res.code === 'success') {
                    if (res.body.listdata.length > 0) {
                        this.dataList = this.buildTree(res.body.listdata)
                        console.log(277, this.dataList)
                        if (this.dataList.length > 0) {
                            if (this.dataList[0].children.length > 0) {
                                this.typeData = this.dataList[0].children[0].id
                            } else {
                                this.typeData = res.body.listdata[0].id
                            }
                        }
                    }
                }
            })
        },
        buildTree(data) {
            const obj = {}
            data.forEach((item) => {
                obj[item.id] = item
            })
            const parentList = []
            data.forEach((item) => {
                const parent = obj[item.parentId]
                if (parent) {
                    // * 当前项有父节点
                    parent.children = parent.children || []
                    parent.children.push(item)
                } else {
                    // * 当前项没有父节点 -> 顶层
                    parentList.push(item)
                }
            })
            return parentList
        },
        getItem(code, more = false) {
            if (this.loading) return
            this.loading = true

            this.showEdit = false
            this.typeId = code
            this.$http({
                method: 'post',
                url: '/base/helpDocument/querydata',
                contentType: 'application/x-www-form-urlencoded',
                data: [{
                    'compare': '=',
                    'field': 'status',
                    'merge': true,
                    'value': 1
                },
                { compare: '=', field: 'typeId', merge: true, value: code }],
                params: { }
            }).then(res => {
                this.loading = false
                if (res.code === 'success') {
                    // 非点击加载项，自动加载点击项的余下4项
                    if (more) {
                        const data = JSON.parse(JSON.stringify(res.body.listdata[0]))
                        this.resultList.push(data)
                        // 容器高度存储，下标一一对应，用于变更左侧菜单(this.scrollChange)
                        this.$nextTick(() => this.resultHeightList.push(document.querySelector('#item_' + data.id).clientHeight))
                        this.resultList.length < 5 && this.findNextId(this.dataList, code).then(res => res && this.getItem(res.id, true))
                        return
                    }
                    this.headings = []
                    this.result = {}
                    this.vo = {}
                    this.result = res.body.listdata.length > 0 ? res.body.listdata[0] : {}
                    // 多视图存储
                    this.resultList = []
                    this.resultList.push(JSON.parse(JSON.stringify(this.result)))
                    this.resultHeightList = []
                    // 容器高度存储，下标一一对应，用于变更左侧菜单(this.scrollChange)
                    this.$nextTick(() => this.resultHeightList.push(document.querySelector('#item_' + this.result.id).clientHeight))
                    // 搜索下一项id并请求
                    this.findNextId(this.dataList, code).then(res => res && this.getItem(res.id, true))

                    this.vo = res.body.listdata.length > 0 ? res.body.listdata[0] : {}
                    if (JSON.stringify(this.result) !== '{}') {
                        let counter = 1
                        this.result.documentInfo = this.result.documentInfo.replace(/<h2>/g, () => `<h2 id="h2-${counter++}">`)
                        const regex = /<h2\s+id="([^"]+)">([^<]+)<\/h2>/g
                        let match
                        while ((match = regex.exec(this.result.documentInfo))) {
                            const id = match[1]
                            const title = match[2].trim()
                            this.headings.push({ id, title })
                        }
                    }
                    console.log(221, this.headings)
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.content-box >>>img{
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
}
.left-tree{
    padding: 10px 10px;
    border: 1px solid #DEE2E6;
    margin-right: 10px;
    height: 300px;
    overflow-y: scroll;
}
.left-tree::-webkit-scrollbar {
  width: 3px;
}
/* 设置滚动条thumb（滑块）的颜色 */
.left-tree::-webkit-scrollbar-thumb {
  background-color: #d2d4da;
  border-radius: 3px;
}

/* 设置滚动条hover时thumb的颜色 */
.left-tree::-webkit-scrollbar-thumb:hover {
  background-color: #d2d4da;
}
/deep/.cke_contents{
    padding: 0 10px !important;
}
 @media (max-width: 1569px) {
    .help-content{
    width: 980px;
    padding:20px 50px;
    }
    .richtext-content{
        width: 980px;
        margin: 20px auto;
    }
 }
 @media (min-width: 1570px) {
    .help-content{
    width: 1200px;
    padding:20px 100px;
    }
    .richtext-content{
        width: 1200px;
        margin: 20px auto;
    }
 }
#help-root{
  height: 100%;
}
.helpHeader{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .headerLogo{
        display: flex;
        align-items: center;
        margin-left: 20px;
    }
    .headerBtn{
        display: flex;
        align-items: center;
    }
}
 /deep/ .sidebar-logo-link{
        background: #fff;
        .sidebar-title{
            color: #000 !important;
        }
      .sidebar-logo{
          margin-left:20px;
          height: 36px;
          width: 36px;
        }
    }
.help-box{
  display: flex;
  background: #f3f3f3;
  border-top: 1px solid #DEE2E6;
  height: calc(100% - 60px);
  .help-sider{
    width: 250px;
    padding-top: 20px;
    padding-left: 20px;
   // border-right: 1px solid #DEE2E6;
    overflow: auto;
      /deep/.el-submenu__title i {
          color: #909399 !important;
      }
  }
  .help-content{
    margin: 20px auto;
    background: white;
    overflow-y: scroll;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 10px 0px, rgba(0, 0, 0, 0.04) 0px 0px 0px;
    .title{
      height: 31px;
      font-size: 24px;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 31px;
    }
    .content-box{
      margin-top: 50px;
    }
  }
  .help-right{
    padding-top: 20px;
    width: 250px;
    .right-contet{
      width: 100%;
      color: #504b4b;
      padding: 0 14px;
      border-left: 2px solid #DEE0E3;
      div{
        font-family: MicrosoftYaHei;
        line-height: 24px;
        margin-bottom: 15px;
        position: relative;
        cursor: pointer;
      }
      .active{
        color: var(--bgColor);
      }
      .active::before{
        content: '';
        display: inline-block;
        position: absolute;
        width: 2px;
        height: 100%;
        background: var(--bgColor);
        left: -16px;
        border-radius: 4px;
      }

    }
  }
}
.help-content::-webkit-scrollbar {
 display: none;
}
.help-sider::-webkit-scrollbar {
  width: 4px;
}
/* 设置滚动条thumb（滑块）的颜色 */
.help-sider::-webkit-scrollbar-thumb {
  background-color: #d2d4da;
  border-radius: 4px;
}

/* 设置滚动条hover时thumb的颜色 */
.help-sider::-webkit-scrollbar-thumb:hover {
  background-color: #d2d4da;
}

</style>
