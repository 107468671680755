
<template>

  <div class="page-content bc page-content-inner container-fluid">
    <!-- PAGE CONTENT BEGINS -->
    <div class="top-tools">
      <div class="l">

        登记日期：
        <el-date-picker
          v-model="value"
          class="riqi"
          type="month"
          placeholder="选择月"
          format="yyyy年MM月"
          value-format="yyyy-M"
        />
        <div>
          <el-button type="primary" style="margin-left: 10px;" @click="getshuju">查询</el-button>
        </div>
        <!-- <statistics-search :is-wdqxj="isWdqxj" :search-type="searchType" @statisticsClick="statistics" /> -->
        <div class="top-text"> <span>{{ countMsg ||0 }}</span></div>
      </div>
    </div>
    <div class="main">
      <template>
        <el-table
          class="custom-table"
          style="width:100%"
          border
          :data="dataList2"
        >
          <el-table-column
            label="序号"
            width="60"
            type="index"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.row.index }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="姓名" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.staffName }}</div>

            </template>
          </el-table-column>
          <el-table-column label="科室" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.staffDept }}</div>

            </template>
          </el-table-column>
          <el-table-column label="年度" width="80" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.hzDate ? scope.row.hzDate.split('-')[0] : '-' }}</div>

            </template>
          </el-table-column>

          <el-table-column
            v-for="(option, optionkey) in dataList.top"
            :key="optionkey"
            align="center"
            header-align="center"
            :label="option+'（天）'"
            width="100"
          >
            <template slot-scope="scope">
              <div>
                {{ dataList2[scope.$index].passenger.list.find(item => item.typeName === option)?.day || 0 }}
              </div>

            </template>
          </el-table-column>

          <el-table-column
            label="进修（天）"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.jxNum ||0 }}</div>
            </template>
          </el-table-column>

          <el-table-column
            label="公差（天）"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.gcNum ||0 }}</div>
            </template>
          </el-table-column>

          <el-table-column
            label="出勤（天）"
            width="100"
            align="center"
          >

            <template slot-scope="scope">
              <div>{{ scope.row.cqNum&lt;0?0:scope.row.cqNum }}</div>
            </template>
          </el-table-column>

        </el-table>
      </template>

      <div class="tableDetail">
        <data-table
          :module-info="moduleInfo"
          :tb-columns="tbColumns"
          :toolbar-btns="listpageBtns"
          :on-list-data-before-load="onListDataBeforeLoad"
          :on-list-data-loaded="onListDataLoaded"
          :on-cell-click="designListPageView"
          :on-selected-rows-change="onSelectedRowsChange"
        /></div>
    </div>
  </div>
</template>

<script>
// import DkDetail from './components/dkDetail'
// import StatisticsSearch from '@/wapp/common/components/StatisticsSearch'
// import Vue from 'vue'
import { mapGetters } from 'vuex'
const moment = require('moment')

export default {
    name: '',
    components: { },
    data() {
        return {
            startDate: this.getDate(-7),
            endDate: this.getDate(-1),
            maxdates: this.getDate(-1),
            personData: {},
            listdata: [],
            curData: {},
            curIndex: 0,
            detailStatus: [4, 5, 6, 8, 9],
            usedDays: 0,
            unusedDays: 0,
            countMsg: '',
            goto: '',
            moduleInfo: {
                delUrl: 'base/codeGenerate/deleteListpage?dwadmin=1',
                listDataUrl: 'yykq/vacateRecord/listdata',
                listDefaultOrderBy: ''// 默认排序
            },
            tbColumns: [{
                field: 'index',
                title: '序号',
                align: 'center',
                width: '50px'
            }, {
                field: 'flLeaveType',
                title: '请假类型',
                align: 'center',
                width: '120px',
                elementFormatter: '1：事假\r\n2：病假\r\n3：婚假\r\n4：丧假\r\n5：产假\r\n6：陪产假\r\n7：年产假\r\n8：调休\r\n9：其他\r\n10：探亲假\r\n'
            }, {
                field: 'startDate',
                title: '休假时间',
                elementFormatter: 'format：yyyy-MM-dd HH:mm\r\n'
            }, {
                field: 'actualDay',
                title: '休假天数',
                align: 'center',
                width: '120px'
            }],
            listpageBtns: [{ 'btnName': '导出', 'btnIcon': 'export.svg', 'btnClick': this.exportWform, 'btnPermission': 'exportWform', 'id': 'btn_export', 'btnType': 'toolbar' }],
            // searchType: [
            //     {
            //         type: 'date',
            //         title: '统计日期',
            //         rangeType: 'daterange',
            //         rangeSeparator: '至',
            //         dateFormat: 'yyyy-MM-dd'
            //     }
            // ],
            isWdqxj: 'isWdqxj',
            countTable: [{
                prop: 'index',
                title: '序号',
                align: 'center',
                width: '50px'
            }, {
                prop: 'userName',
                title: '用户姓名',
                align: 'center',
                width: '120px'
            }, {
                prop: 'orgName',
                title: '科室',
                align: 'center',
                width: '120px'
            }, {
                prop: 'year',
                title: '年度',
                align: 'center',
                width: '100px'
            }, {
                prop: '病假',
                title: '病假（天）',
                align: 'center',
                width: ''
            }, {
                prop: '婚假',
                title: '婚假（天）',
                align: 'center',
                width: ''
            }, {
                prop: '产假',
                title: '产假（天）',
                align: 'center',
                width: ''
            }, {
                prop: '探亲假',
                title: '探亲假（天）',
                align: 'center',
                width: ''
            }, {
                prop: '丧假',
                title: '丧假（天）',
                align: 'center',
                width: ''
            }, {
                prop: '进修',
                title: '进修（天）',
                align: 'center',
                width: ''
            }, {
                prop: '事假',
                title: '事假（天）',
                align: 'center',
                width: ''
            }, {
                prop: '年休假',
                title: '年休假（天）',
                align: 'center',
                width: ''
            }, {
                prop: '公差',
                title: '公差（天）',
                align: 'center',
                width: ''
            }, {
                prop: '出勤',
                title: '出勤（天）',
                align: 'center',
                width: ''
            }],

            countTableDate: [{
                index: 1,
                userName: '',
                orgName: '',
                year: 0,
                '病假': 0,
                '婚假': 0,
                '产假': 0,
                '探亲假': 0,
                '丧假': 0,
                '进修': 0,
                '事假': 0,
                '年休假': 0,
                '公差': 0,
                '出勤': 0

            }],
            clickCount: 0,
            dataList: [],
            value: '',
            time: '',
            selectDept: '',
            selectAccount: '',
            content: '',
            dataList2: [],
            listshuju: []

        }
    },
    computed: {
        ...mapGetters(['loginUser', 'routes'])
    },
    watch: {

    },
    created() {
        this.$bus.emit('showNavSearch', false)
        var now = new Date() // 当前日期
        var nowMonth = now.getMonth() // 当前月
        var nowYear = now.getFullYear() // 当前年
        // 本月的开始时间
        var monthStartDate = new Date(nowYear, nowMonth, 1)
        this.startDate = moment(monthStartDate).format('YYYY-MM-DD')
        this.endDate = moment(new Date()).format('YYYY-MM-DD')
        this.requestData()

        // this.initList()

        const date = new Date()
        this.value = date.getFullYear() + '-' + (date.getMonth() + 1)
        console.log(this.value)
        const time = this.value.split('-')
        const y = time[0]
        const m = time[1]
        this.getDays(y, m)
        console.log('this.$store.getters.loginUser => ', this.$store.getters.loginUser)
        this.selectDept = this.$store.getters.loginUser.staffList[0].orgFullId.split('-')[1]
        this.selectAccount = this.$store.getters.loginUser.personAccount
        console.log(this.$store.getters.loginUser, 'login')

        this.getshuju()
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true)
    },
    methods: {
        statistics(val) {
            console.log('11111111111111', val)
            this.startDate = moment(val.range[0]).format('YYYY-MM-DD')
            this.endDate = moment(val.range[1]).format('YYYY-MM-DD')
            // this.searchData()
            // this.$bus.emit('refreshListData')
            if (this.clickCount !== 0) {
                this.$bus.emit('refreshListData')
            }
            this.clickCount = 1
            this.requestData()
            this.searchData()
        },
        getshuju() {
            this.requestData()
            const time = this.value.split('-')
            const y = time[0]
            const m = time[1]
            this.getDays(y, m)
            this.searchData()
            const data = {
                hzDate: moment(new Date(this.value)).format('YYYY-MM'),
                staffDeptId: this.selectDept,
                staffValue: this.selectAccount
            }
            this.$http({
                method: 'POST',
                url: '/yykq/kqhz/personStatistics',
                contentType: 'application/x-www-form-urlencoded',
                params: data
            }).then(res => {
                if (res.code === 'success') {
                    console.log('res', res)
                    this.dataList = res.body
                    this.dataList2 = this.dataList.list
                }
                if (this.dataList2.length > 0) {
                    this.dataList2.forEach((item, index) => {
                        item.index = index + 1
                    })
                }
            })

            this.$http({
                method: 'post',
                url: '/yykq/vacateRecord/listdata',
                params: {
                    pageSize: 10,
                    pageNum: 1,
                    orderBy: 'startDate desc',
                    urlsearch: `startDate>:${(moment(this.startDate).format('YYYY-MM-DD') + ' 00:00')};startDate<:${(moment(this.endDate).format('YYYY-MM-DD') + ' 23:59')};status:2;createorAccount:${this.loginUser.personAccount};`
                }
            }).then(res => {
                if (res.code === 'success') {
                    this.listshuju = res.body.pageInfo.list
                    this.$bus.emit('refreshListData')
                }
            })
        },
        getDays(year, month) {
            const days = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
            if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
                days[2] = 29
            }
            const thismonth = month.toString().padStart(2, '0')
            this.startDate = year + '-' + month + '-' + 1
            this.endDate = year + '-' + month + '-' + days[month]
            console.log(year, thismonth, days[month])
            this.time = days[month]
        },

        searchData() {
            const searchFields = [
                { 'compare': '=', 'field': 'leaderAccount', 'value': this.loginUser.personAccount },
                { 'compare': '>=', 'field': 'startDate', 'value': (moment(this.startDate).format('YYYY-MM-DD') + ' 00:00') },
                { 'compare': '<=', 'field': 'endDate', 'value': (moment(this.endDate).format('YYYY-MM-DD') + ' 23:59') },
                { 'compare': '=', 'field': 'status', 'value': 2 }
            ]
            this.usedDays = 0
            this.unusedDays = 0
            // 我申请的
            this.$http({
                method: 'POST',
                url: 'yykq/vacateRecord/querydata',
                contentType: 'application/x-www-form-urlencoded',
                data: searchFields
            }).then(res => {
                if (res.code === 'success') {
                    console.log(res, 'res')
                    this.initCountDate(res.body.listdata)
                } else {
                    this.$message.error(res.message)
                }
            })
        },

        requestData() {
            // const searchFields = [
            //     { 'compare': '=', 'field': 'year', 'value': this.startDate.substring(0, 4) },
            //     { 'compare': '=', 'field': 'orgCode', 'value': this.loginUser.orgCode },
            //     { 'compare': '=', 'field': 'typeName', 'value': '年休假' },
            //     { 'compare': '=', 'field': 'account', 'value': this.loginUser.personAccount }
            // ]
            const dateArr = this.startDate.split('-')
            const searchFields = {
                account: this.loginUser.personAccount,
                orgCode: this.loginUser.orgCode,
                startDate: moment(new Date(dateArr[0], dateArr[1], 0)).format('YYYY-MM-DD') + ' 23:59:59'
            }
            this.usedDays = 0
            this.unusedDays = 0
            // 我申请的
            this.$http({
                method: 'GET',
                url: 'yykq/vacate/findHoliday',
                contentType: 'application/x-www-form-urlencoded',
                params: searchFields
            }).then(res => {
                if (res.code === 'success') {
                    const result = res.body
                    this.countMsg = this.startDate.substring(0, 4) + '年休假共' + result.holiday + '天，剩余' + result.surplusDay + '天'
                } else {
                    this.$message.error(res.message)
                }
            })
        },

        /**
        * 查看明细弹出框
        */
        showDetail($d) {
            this.curData = $d
            this.$bus.emit('dkDetailDialogOpen', $d)
        },

        /**
        * 距离当天的天数（wd,0 当天）
        */
        getDate(wd) {
            wd = Number(wd)
            if (isNaN(wd)) { wd = 0 }
            const now = new Date()
            const t = now.getTime() + wd * 24 * 60 * 60 * 1000
            const tod = new Date(t)
            const y = tod.getFullYear()
            const m = tod.getMonth()
            const d = tod.getDate()

            return y + '-' + (`${m + 1}`.padStart(2, '0')) + '-' + (`${d}`.padStart(2, '0'))
        },

        /**
         * 两个日期间的大小
         */
        compareDate(s, e) {
            var oDate1 = new Date(s)
            var oDate2 = new Date(e)
            return oDate1.getTime() - oDate2.getTime()
        },
        // 跳转页面
        gotoPages(code) {
            const toPath = this.getMenuCode(code)
            if (toPath && toPath.path) {
                let path
                switch (toPath.meta.level) {
                    case 3:
                        path = toPath.meta.activeMenu || toPath.path
                        break
                    case 4:
                        path = toPath.meta.activeMenu + '/' + toPath.path
                        break
                    default:
                        path = toPath.path
                }
                this.$router.push({ path: path })
            }
        },
        // 递归查询菜单代码
        getMenuCode(code) {
            let toPath
            function findCode(ary) {
                ary.forEach(val => {
                    if (val.children) {
                        findCode(val.children)
                    }
                    if (!val.children && val.meta && val.meta.code === code) {
                        toPath = val
                        return false
                    }
                })
            }
            findCode(this.routes)
            return toPath
        },
        /**
        * 加载数据准备，qparams为查询参数对象，不需要返回值
        */
        onListDataBeforeLoad: function($scope, qparams) {
            this.loginUser = $scope.loginUser
            qparams.urlsearch = 'startDate>:' + (moment(this.startDate).format('YYYY-MM-DD') + ' 00:00')
            qparams.urlsearch = qparams.urlsearch + ';startDate<:' + (moment(this.endDate).format('YYYY-MM-DD') + ' 23:59')
            qparams.urlsearch = qparams.urlsearch + ';leaderAccount:' + this.loginUser.personAccount
            qparams.urlsearch = qparams.urlsearch + ';status:2'
            qparams.urlsearch = qparams.urlsearch + ';flLeaveType!:公差,进修;actualDay!:0'
            // console.log('leaderGooutReport listdata before load..')
            // qparams.searchMap['leaderAccount'] = this.loginUser.personAccount
            // qparams.searchMap['flCreateDate'] >= (moment(this.startDate).format('YYYY-MM-DD') + ' 00:00')
            // qparams.searchMap['flCreateDate'] <= (moment(this.endDate).format('YYYY-MM-DD') + ' 23:59')
            // qparams.searchMap = { 'leaderAccount': this.loginUser.personAccount }
            // qparams.searchMap = { 'flCreateDate_START': (moment(this.startDate).format('YYYY-MM-DD') + ' 00:00') }
            // qparams.searchMap = { 'flCreateDate_END': (moment(this.endDate).format('YYYY-MM-DD') + ' 23:59') }
        },
        /**
        * 加载数据完成，pageInfo为包含查询结果的分页信息对象，不需要返回值
        */
        onListDataLoaded: function($scope, pageInfo) {
            console.log($scope, pageInfo, '$scope, pageInfo')
            this.listshuju = pageInfo.list

            pageInfo.list.forEach(obj => {
                if (obj.startDate != null && obj.endDate != null) {
                    obj.startDate = obj.actualStartTime.slice(0, -3) + '至' + obj.actualEndTime.slice(0, -3)
                }
            })
            this.listshuju = []
        },
        initCountDate(list) {
            const $scope = this
            console.log('$scope.loginUser.personName', this.loginUser, list)
            $scope.countTableDate[0].userName = $scope.loginUser.personName
            const orgName = $scope.loginUser.staffList[0].orgFullName.split('-')
            $scope.countTableDate[0].orgName = orgName[orgName.length - 1]
            $scope.countTableDate[0].year = $scope.startDate.substring(0, 4)
            const keys = Object.keys(this.countTableDate[0])
            keys.forEach(key => {
                if (key !== 'userName' && key !== 'orgName' && key !== 'year' && key !== 'index') {
                    $scope.countTableDate[0][key] = 0
                }
            })
            console.log(keys, 231)
            list.forEach(obj => {
                keys.forEach(key => {
                    let type = obj.flLeaveType
                    if (type === '1') {
                        type = '事假'
                    }
                    obj.flLeaveType = type
                    if (type === key) {
                        // const allCount = $scope.countTableDate[0][key] + obj.flRealityTime
                        const allCount = $scope.countTableDate[0][key] + 1
                        $scope.countTableDate[0][key] = allCount
                    }
                })
            })
        },
        exportWform() {
            const searchFields = [
                { 'compare': '=', 'field': 'leaderAccount', 'value': this.loginUser.personAccount },
                { 'compare': '>=', 'field': 'startDate', 'value': (moment(this.startDate).format('YYYY-MM-DD') + ' 00:00') },
                { 'compare': '<=', 'field': 'endDate', 'value': (moment(this.endDate).format('YYYY-MM-DD') + ' 23:59') },
                { 'compare': '=', 'field': 'status', 'value': 2 }
            ]
            // 我申请的
            this.$http({
                method: 'POST',
                url: 'yykq/vacateRecord/exportVacateRecordData',
                data: searchFields,
                responseType: 'arraybuffer'
            }).then(res => {
                console.log(res, 'res')
                this.changeXLS(res, '我的请休假.xls')
            })
        },
        // 文件转化
        changeXLS(data, fileName) {
            const blob = new Blob([data], {
                type: 'application/octet-stream'
            })// 转化为blob对象
            const filename = fileName || 'filename.xls'// 判断是否使用默认文件名
            if (typeof window.navigator.msSaveBlob !== 'undefined') {
                window.navigator.msSaveBlob(blob, filename)
            } else {
                var blobURL = window.URL.createObjectURL(blob)// 将blob对象转为一个URL
                var tempLink = document.createElement('a')// 创建一个a标签
                tempLink.style.display = 'none'
                tempLink.href = blobURL
                tempLink.setAttribute('download', filename)// 给a标签添加下载属性
                if (typeof tempLink.download === 'undefined') {
                    tempLink.setAttribute('target', '_blank')
                }
                document.body.appendChild(tempLink)// 将a标签添加到body当中
                tempLink.click()// 启动下载
                document.body.removeChild(tempLink)// 下载完毕删除a标签
                window.URL.revokeObjectURL(blobURL)
            }
        }

    }
}
</script>

<style scoped lang="scss">
.top-tools, .breadcrumb {
    overflow: hidden;
    margin-top: 15px;
    padding: 0 15px;
    .l {
      float: left;
      display: flex;
      align-items: center;

      // width: 4100px;
      .top-text{
         height: 100%;
         margin: 0 auto;
         margin-left: 40px;
      }
    }
    .r {
      float: right;
      width: 200px;
      text-align: right
    }
}

.main {
    padding: 20px 15px;

}

  .tableDetail{
    margin-top:30px;
  }

  .input-group.date input{height:36px;}
  /deep/ .el-range-separator{width: 30px;}
  .el-table, .wflow-container, .el-button {
    font-size: 14px !important;
   }
   /deep/.el-table__header th{
     font-weight: 500;
   }

</style>

