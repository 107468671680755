<template>
    <div class="sidebar-logo-container" :class="{ collapse: isCollapse && !showTopMenus }">
        <transition name="sidebarLogoFade">
            <div class="sidebar-logo-link">
                <img v-if="logo && loginUser.uiStyle !== 'skinOrange' && loginUser.uiStyle !== 'autumn' && loginUser.uiStyle !== 'cloudThem' && loginUser.uiStyle !== 'shangwuGc'" :src="logo" class="sidebar-logo" />
                <svg-icon v-else class-name="logoSvg" icon-class="logoSvg" style="color: var(--textColor)" />
                <h1 class="sidebar-title">{{ title }}</h1>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { title, logo } from '@/wapp/settings'

export default {
    name: 'SidebarLogo',
    ...mapGetters(['sidebar']),
    data() {
        return {
            title: title,
            logo: logo,
            loginUser: this.$store.getters.loginUser
        }
    },
    computed: {
        showTopMenus() {
            return this.$store.state.settings.showTopMenus
        },
        isCollapse() {
            return !this.$store.state.app.sidebar.opened
        }
    },
    created() {
        let productCode = sessionStorage.getItem('productCode')
        if (!productCode) {
            productCode = window.productCode
        }
        console.log(this.loginUser, productCode, 38)
        if (productCode === window.productCode || this.loginUser.orgCode === 'plat') {
            this.logo = process.env.VUE_APP_BASE_API + '/base/sysSetup/downloadLogoBySysCode?sysCode=' + this.loginUser.sysCode
        } else {
            this.logo = './images/w-imgs/logo.png'
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/wapp/common/styles/variables.module.scss';

.sidebarLogoFade-enter-active {
    transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
    opacity: 0;
}

.sidebar-logo-container {
    transition: width 0.28s;
    transition-duration: 0.28s;
    position: relative;
    // width: $sideBarWidth;
    height: $headerHeight;
    line-height: $headerHeight;
    text-align: left;
    overflow: hidden;
    /* background-color: $headerBackground; */
    float: left;
    & .sidebar-logo-link {
        height: 100%;
        width: 100%;
        .logoSvg {
            width: 40px;
            height: 40px;
            color: red;
            vertical-align: middle;
            margin: 0 10px 0 20px;
        }
        & .sidebar-logo {
            // width: 32px;
            height: 32px;
            vertical-align: middle;
            margin-right: 10px;
            margin-left: 20px;
        }

        & .sidebar-title {
            display: inline-block;
            margin: 0;
            color: $headerText;
            font-weight: 400;
            line-height: 50px;
            font-size: 24px;
            font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
            vertical-align: middle;
        }
    }

    &.collapse {
        width: 54px;

        .sidebar-logo {
            margin-left: 10px;
            margin-right: 0px;
        }
        .sidebar-title {
            display: none;
        }
    }
}
#skinWhite .sidebar-logo-container .sidebar-logo-link .sidebar-title {
    color: var(--textColor);
}

.hideTopMenus-header-container .sidebar-logo-container {
    overflow: visible;
    .sidebar-logo-link:after {
        content: '';
        width: 100%;
        position: absolute;
        height: 1px;
        background: #475871;
        bottom: -1px;
        display: block;
    }
}
</style>
