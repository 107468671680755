import LangSelect from '@/wapp/common/components/LangSelect'
import SocialSign from '../../../components/SocialSignin'
import SlideVerify from '../../../components/SlideVerify'
import { resetRouter } from '@/wapp/common/router'
const encrypt = new window.JSEncrypt()
import Cookies from 'js-cookie'
import settings from '@/wapp/settings'
import { title } from '@/wapp/settings'
export const loginMixins = {
    components: {
        SocialSign, SlideVerify, LangSelect
    },
    data() {
        return {
            isShowLogo: true,
            logoInfo: {
                title: '',
                logoUrl: '',
                copyright: ''
            },
            loginForm: {
                orgcode: '',
                account: '',
                phone: '',
                username: '',
                password: '',
                kaptcha: '',
                code: '',
                productCode: window.productCode
            },
            searchKey: {
                orgName: '',
                orgCode: ''
            },
            orgList: [],
            loginUrl: '',
            kaptchaUrl: process.env.VUE_APP_BASE_API + '/login/getKaptchaImage',
            capsTooltip: false,
            loading: false,
            showDialog: false,
            redirect: undefined,
            otherQuery: {},
            waitingCode: false,
            waitingSec: 60,
            title: title,
            error: '',
            remember: false,
            loginType: 'account',
            loginTab: 'phone',
            qrCodeSrc: '',
            loginOrgSelect: window.loginOrgSelect, // //登录页使用下拉 ture/false
            khide: false,
            // 客户端下载浮窗展示
            appDownloadPopoverShow: false,
            // 登录按钮禁用
            loginBtnDisable: window.verifyWay === 'slideVerify',
            // app下载二维码
            appDownloadQrCode: '',
            // 验证方式
            verifyWay: window.verifyWay,
            otherLoginTypes: '', // 其他的登录方式
            qrCodeLoginType: window.qrCodeLoginType, // 支持的扫码登录方式
            qrCodeType: '',
            wwLogin: null, // 企业微信二维码实例
            qrCodeLoginInterval: null, // app检测登录定时
            ddLogin: null, // 钉钉二维码实例
            updateQrCode: true, // 更新二维码
            ddError: '', // 钉钉错误信息
            qrcodeCongig: {},
            fakeQrcode: false,
            isClose: 0,
            fwCode: '',
            doubleAuth: false
        }
    },
    computed: {
        slideVerifyDisabled() {
            const { account, phone, password } = this.loginForm
            return !((account || phone) && password)
        }
    },
    watch: {
        $route: {
            handler: function(route) {
                const query = route.query
                if (query) {
                    this.redirect = query.redirect || query.redirectUrl
                    this.otherQuery = this.getOtherQuery(query)
                }
            },
            immediate: true
        },
        isClose: {
            handler: function(val) {
                console.log(358, val)
            },
            immediate: true
        }
    },
    created() {
        this.isShowLogo = window.isShowLogo
        this.logoInfo = window.logoInfo
        this.getAppDownloadQrCode()
        this.getFWCode()
        document.querySelector('body').removeAttribute('id')
        settings.addStyle = true
        this.checkWaitingSec(this.waitingSec)
    },
    beforeDestroy() {
        if (this.qrCodeLoginInterval !== null)clearInterval(this.qrCodeLoginInterval)
    },
    mounted() {
        this.whoFocus()
        let upw = this.getCookie('upw')
        if (upw !== undefined && upw !== null && upw !== '') {
            upw = this.b64DecodeUnicode(upw)
            this.remember = true
            const acc = upw.split(':')[0]

            // 旧版账号密码格式化方式
            let loginState = null
            if (upw.indexOf('=') > 1) {
                loginState = {}
                upw.split(':').map(v => {
                    const a = v.split('=')
                    loginState[a[0]] = a[1]
                })
                this.loginForm = loginState
                if (loginState.phone && loginState.phone.length === 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/.test(loginState.phone)) {
                    this.loginTab = 'phone'
                } else {
                    this.loginTab = 'acc'
                }
                return
            }
            // 新版账号密码格式化方式
            if (acc.length === 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/.test(acc)) {
                this.loginForm.phone = acc
                this.loginTab = 'phone'
            } else {
                this.loginForm.account = acc
                this.loginTab = 'acc'
            }
            this.loginForm.password = upw.split(':')[1]
            this.loginForm.orgcode = upw.split(':')[2]
            if (window.loginOrgSelect) this.loginForm.orgName = upw.split(':')[3]
            // 获取用户上次登录的orgCode
            if (!this.loginForm.orgcode && this.loginTab === 'phone' && upw) {
                const lastOrgcode = localStorage.getItem('LAST_LOGIN_ORGCODE')
                if (lastOrgcode) {
                    this.loginForm.orgcode = this.b64DecodeUnicode(lastOrgcode)
                }
            }
        }
        if (Cookies.get('uiStyle')) {
            Cookies.remove('uiStyle')
        }
    },
    methods: {
        getFWCode() {
            this.$http({
                url: 'login/createQrCode',
                method: 'post',
                params: {
                    content: `${location.host}/apigw`
                }
            }).then(res => {
                if (res.code === 'success') {
                    this.fwCode = 'data:image/jpeg;base64,' + res.body.image
                }
            })
        },
        checkWaitingSec() {
            var countdown = sessionStorage.getItem('waitingSec')
            if (countdown > 1) {
                this.waitingSec = countdown
                this.waitingCode = true
                this.decrementSecond()
            }
        },
        decrementSecond() {
            this.secInterval = setInterval(() => {
                if (this.waitingSec <= 1) {
                    this.waitingCode = false
                    this.waitingSec = 60
                    clearInterval(this.secInterval)
                    return
                }
                this.waitingSec--
                //  防止刷新页面获取验证码倒计时重置  开始
                sessionStorage.setItem('waitingSec', this.waitingSec)
                // 防止刷新页面获取验证码倒计时重置  结束
            }, 1000)
        },
        handleSendSms() {
            if (!this.loginForm.phone) {
                this.$message.warning('请先输入手机号！')
                return
            } else if (this.loginForm.phone.length === 11 && /^((13|14|15|17|18|19)[0-9]{1}\d{8})$/.test(this.loginForm.phone)) {
                this.$http({
                    method: 'GET',
                    url: 'login/getRsaPublicKey'
                }).then(res => {
                    if (res.code === 'success') {
                        encrypt.setPublicKey(res.body.rsaPublicKey)
                        this.$http({
                            url: 'login/sendSmsDynamicPassword',
                            method: 'GET',
                            params: {
                                mobile: encrypt.encrypt(this.loginForm.phone)
                            }
                        }).then(response => {
                            if (response.code === 'success') {
                                this.$message.success('验证码已发送，如未收到请检查手机号是否正确。')
                                this.waitingCode = true
                                this.decrementSecond()
                            } else {
                                if (response.message !== '处理失败！') {
                                    this.$message.success('验证码已发送，如未收到请检查手机号是否正确。')
                                    this.waitingCode = true
                                    this.decrementSecond()
                                } else {
                                    this.$message.warning(response.message)
                                }
                            }
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                })
            } else {
                this.$message.warning('请检查输入的手机号是否正确！')
                return
            }
        },
        handleLogin() {
            if (this.loading) return
            if (this.loginForm.code !== '') {
                this.loginForm.kaptcha = ''
                this.loginForm.password = this.loginForm.code
                var cookies = document.cookie.split('; ')
                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i].split('=')
                    var cookieName = cookie[0].trim()
                    if (cookieName === 'KAPTCHA_SESSION_KEY') {
                        document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
                    }
                }
            }
            this.formValidate(() => {
                resetRouter()

                this.loading = true
                //
                const expireDate = new Date()
                if (this.remember) {
                    expireDate.setTime(expireDate.getTime() + 7 * 2 * 24 * 60 * 60 * 1000) // cookies 2周内有效期
                    let username = ''
                    if (this.loginTab === 'phone') {
                        username = this.loginForm.phone
                    } else {
                        username = this.loginForm.account
                    }
                    if (window.loginOrgSelect) {
                        document.cookie = 'upw=' + this.b64EncodeUnicode(username + ':' + this.loginForm.password + ':' + this.loginForm.orgcode + ':' + this.loginForm.orgName) + ';expires=' + expireDate + ';path=/'
                    } else {
                        document.cookie = 'upw=' + this.b64EncodeUnicode(username + ':' + this.loginForm.password + ':' + this.loginForm.orgcode) + ';expires=' + expireDate + ';path=/'
                    }
                } else {
                    expireDate.setTime(-1) // cookies 立即过期
                    document.cookie = 'upw=;expires=' + expireDate + ';path=/'
                }
                if (this.loginTab === 'acc') {
                    if (this.loginForm.orgName && this.orgList.length === 0) { this.loginForm.orgcode = this.loginForm.orgName }
                    this.loginForm.username = this.loginForm.orgcode + ':' + this.loginForm.account
                } else {
                    this.loginForm.username = this.loginForm.phone
                    if (settings.loginType === 'PlatPanel') this.loginForm.password = this.loginForm.code
                }
                this.$http({
                    method: 'GET',
                    url: 'login/getRsaPublicKey'
                }).then(res => {
                    if (res.code === 'success') {
                        encrypt.setPublicKey(res.body.rsaPublicKey)
                        const paramsObj = $.extend(true, {}, this.loginForm)
                        paramsObj.username = encrypt.encrypt(paramsObj.username)
                        paramsObj.password = encrypt.encrypt(paramsObj.password)
                        paramsObj.encrypt = true
                        paramsObj.enjwt = true

                        this.$store.dispatch('user/login', paramsObj)
                            .then(res => {
                                // 统一认证登录
                                if (this.redirect && this.redirect.indexOf('http') > -1) {
                                    this.$http({
                                        method: 'get',
                                        contentType: 'application/x-www-form-urlencoded',
                                        url: '/base/user/getSessionId'
                                    }).then(res => {
                                        if (res.code === 'success') {
                                            const tSessionId = res.body.tSessionId
                                            window.location.replace(`${this.redirect}?tSessionId=${tSessionId}`)
                                        } else {
                                            console.log(res.message)
                                        }
                                    })
                                    return
                                }
                                this.$bus.emit('getWaterMarkConfig')
                                if (res['FORCE_MODIFY_PASSWORD'] === true) {
                                    this.$router.push({ path: '/modifyInitPassword', query: this.otherQuery })
                                } else if (res['DOUBLE_SMS_PASSWORD_LOGIN'] === true) {
                                    this.doubleAuth = true
                                    this.loginForm.password = ''
                                    this.loginTab = 'phone'
                                    this.$router.push({ path: '/login', query: this.otherQuery })
                                    this.error = '该账号为新用户登录或已过期，需要短信验证'
                                    this.loginBtnDisable = false
                                    this.loading = false
                                    return
                                } else {
                                    this.saveLoginData(res)
                                    this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
                                }
                                localStorage.setItem('setSysMenuName', '首页')
                            })
                            .catch(error => {
                                this.loginBtnDisable = false
                                if (this.$refs.slideVerify) {
                                    this.$refs.slideVerify.getSlideImg()
                                }
                                if (error.FORCE_MODIFY_PASSWORD) {
                                    this.$alert(error.msg, '操作提示', {
                                        confirmButtonText: '重置密码',
                                        callback: action => {
                                            if (action === 'confirm') this.$router.push('/retrieve')
                                        }
                                    })
                                } else {
                                    this.error = error.msg
                                    this.changekaptcha()
                                    this.loginForm.kaptcha = ''
                                    this.loading = false
                                    if (error.msg.indexOf('验证码') > -1) {
                                        this.whoFocus()
                                    }
                                }
                            })
                    } else {
                        this.loginBtnDisable = false
                        this.error = '服务异常，请稍后重试'
                    }
                })
            }, null, 1)
        },
        checkCapslock({ shiftKey, key } = {}) {
            if (key && key.length === 1) {
                if (shiftKey && (key >= 'a' && key <= 'z') || !shiftKey && (key >= 'A' && key <= 'Z')) {
                    this.capsTooltip = true
                } else {
                    this.capsTooltip = false
                }
            }
            if (key === 'CapsLock' && this.capsTooltip === true) {
                this.capsTooltip = false
            }
        },
        // 搜索机构
        querySearchAsync(queryString, cb) {
            if (queryString.length < 2) {
                cb([])
                return
            }
            this.$http({
                url: 'login/fondMatchOrgNames',
                method: 'GET',
                params: {
                    keyWord: queryString
                }
            }).then(response => {
                if (response.code === 'success') {
                    const arr = response.body.orgNames
                    const orgcodes = response.body.orgCodes
                    if (arr && arr.length > 0) {
                        arr.map((v, i) => {
                            arr[i] = {}
                            arr[i].value = v
                            arr[i].orgCode = orgcodes[i]
                            arr[i].index = i
                            arr[i].orgName = v.replace(queryString, '<span style="color:#3a8ee6;">' + queryString + '</span>')
                        })
                    }
                    this.orgList = arr || []
                    cb(arr)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        loginSelect(item) {
            this.loginForm.orgcode = item.orgCode
            console.log(item, this.loginForm)
        },
        // 选择搜索的机构信息
        async handleSelect(item) {
            this.searchKey.orgCode = item.orgCode
            if (this.qrCodeType === 'dd') {
                await this.getCreateQrcodeParam(this.searchKey, 'dd')
            } else {
                await this.getCreateQrcodeParam(this.searchKey, 'wx')
            }
            this.$nextTick(() => {
                this.getLoginQrCode(this.qrCodeType)
            })
        },
        // 获取生成二维码的企业和应用id
        async getCreateQrcodeParam(item, type) {
            let reqUrl = ''
            if (type === 'wx') {
                reqUrl = 'login/wxqyh/queryWxqyhAppId'
                const resp = await this.$http({
                    method: 'GET',
                    url: reqUrl,
                    params: { orgCode: item.orgCode }
                })
                if (resp.code === 'success') {
                    this.qrcodeCongig[type + 'corpId'] = resp.body.corpId
                    this.qrcodeCongig[type + 'agentId'] = resp.body.appId
                    this.qrcodeCongig[type + 'appkey'] = resp.body.appKey || ''
                    this.qrcodeCongig[type + 'redirectUrl'] = resp.body.redirectUrl.split('/mindex')[0]
                } else {
                    this.$alert(resp.message, '错误', { type: 'error' })
                }
            } else {
                reqUrl = '/login/dingtalk/getDingtalkOAuth2UrlByOrgCode'
                // reqUrl = 'login/dingtalk/queryDingTalkAppId'
                const resp1 = await this.$http({
                    method: 'post',
                    url: reqUrl,
                    params: { orgCode: item.orgCode }
                })
                if (resp1.code === 'success') {
                    this.qrcodeCongig[type + 'redirectUrl'] = resp1.body.redirectUrl
                    this.qrcodeCongig[type + 'state'] = resp1.body.state
                    this.qrcodeCongig[type + 'appkey'] = resp1.body.client_id || ''
                    this.loginUrl = resp1.body.url

                    location.href = resp1.body.url
                }
            }
        },
        // 生成登录二维码
        getLoginQrCode(type) {
            if (this.qrCodeLoginInterval !== null)clearInterval(this.qrCodeLoginInterval)
            this.qrCodeLoginInterval = null
            this.fakeQrcode = false
            this.qrCodeSrc = ''
            if (this.wwLogin !== null) {
                this.wwLogin.destroyed()
                this.wwLogin = null
            }
            this.qrCodeType = type
            this.loginType = 'qrCode'
            this.updateQrCode = false

            this.$nextTick(() => {
                this.updateQrCode = true
                setTimeout(() => {
                    this.$nextTick(() => {
                        if (type === 'app') {
                            this.$http({
                                method: 'GET',
                                url: 'login/getLoginQrCode',
                                params: { 'apigateway': process.env.VUE_APP_BASE_API, deleteWhite: true },
                                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                            }).then(response => {
                                if (response.code === 'success') {
                                    this.error = ''
                                    this.qrCodeSrc = 'data:image/png;base64,' + response.body.image
                                    this.qrCodeLoginInterval = setInterval(() => { this.qrCodeLogin(response.body.loginId) }, 1000)
                                } else {
                                    this.error = response.msg
                                }
                            })
                        } else if (type === 'wx') {
                            if (this.qrcodeCongig.wxcorpId && this.qrcodeCongig.wxagentId) {
                                this.wwLogin = new window.WwLogin({
                                    'id': 'wx_qrcode',
                                    'appid': this.qrcodeCongig.wxcorpId,
                                    'agentid': this.qrcodeCongig.wxagentId,
                                    'redirect_uri': encodeURIComponent(this.qrcodeCongig.wxredirectUrl + '/#/home?agentId=' + this.qrcodeCongig.wxagentId),
                                    'state': '',
                                    'href': 'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLndycF9jb2RlIHt3aWR0aDogMjAwcHg7aGVpZ2h0OjIwMHB4O21hcmdpbjoyMHB4IGF1dG8gMDt9',
                                    'lang': 'zh'
                                })
                            } else {
                                this.fakeQrcode = true
                            }
                        } else if (type === 'dd') {
                            const _this = this
                            this.fakeQrcode = false
                            if (this.qrcodeCongig.ddappkey) {
                                // 创建钉钉二维码实例
                                let errorDialog = false
                                this.ddLogin = null
                                this.ddLogin = window.DTFrameLogin(
                                    {
                                        id: 'dd_qrcode',
                                        width: 290,
                                        height: 275
                                    },
                                    {
                                        redirect_uri: encodeURIComponent(_this.qrcodeCongig.ddredirectUrl),
                                        client_id: _this.qrcodeCongig.ddappkey,
                                        scope: 'openid',
                                        response_type: 'code',
                                        state: _this.qrcodeCongig.ddstate,
                                        prompt: 'consent'
                                    }, (loginResult) => {
                                        this.$http({
                                            url: '/login/dingtalk/dingDingOAuth2Login',
                                            method: 'post',
                                            params: {
                                                authCode: loginResult.authCode,
                                                state: loginResult.state
                                            }
                                        }).then(resp => {
                                            this.$store.dispatch('user/tokenSsoLogin', resp.body)
                                                .then(res => {
                                                    this.$router.replace('/#home')
                                                })
                                        })
                                        // 扫码认证成功后跳转地址进行登录
                                        //  location.href = _this.qrcodeCongig.ddredirectUrl + '/#home?corpId=' + _this.qrcodeCongig.ddcorpId + '&agentId=' + _this.qrcodeCongig.ddagentId + '&authCode=' + authCode
                                        // setTimeout(() => { location.reload() }, 0)
                                    }, (errorMsg) => {
                                        // 这里一般需要展示登录失败的具体原因
                                        _this.ddError = errorMsg
                                        if (!errorDialog) {
                                            errorDialog = true
                                            _this.$alert(errorMsg, '错误', { type: 'error' }).then(() => {
                                                errorDialog = false
                                            }).catch(() => {
                                                errorDialog = false
                                            })
                                        }
                                    })
                            } else {
                                this.fakeQrcode = true
                            }
                        }
                    })
                }, 0)
            })
        },
        // 扫码登录
        qrCodeLogin(loginId) {
            if (this.loginType !== 'qrCode' && this.qrCodeType !== 'app') return
            this.$http({
                method: 'GET',
                url: 'login/qrCodeLogin',
                params: { 'loginId': loginId },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                if (response.code === 'success') {
                    if (response.body.status === 'waiting') {
                        // 手机未扫码 waiting
                    } else if (response.body.status === 'enter') {
                        if (this.qrCodeLoginInterval !== null)clearInterval(this.qrCodeLoginInterval)
                        this.qrCodeLoginInterval = null
                        // 扫码认证成功  enter
                        document.getElementById('wx_reg').innerHTML += "<div style='font-size:14px;color:#67C23A;text-align:center;'>认证已成功，即将进入系统...</div>"
                        // const div = document.createElement('div')
                        // div.innerText = '认证已成功，即将进入系统...'
                        // document.getElementById('wx_reg').appendChild(div)
                        this.$store.dispatch('user/tokenSsoLogin', response.body)
                            .then(res => {
                                this.saveLoginData(response.body)
                                this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
                            })
                            .catch(error => {
                                this.error = error.msg
                            })
                    } else {
                        this.error = response.msg
                        if (this.qrCodeType === 'app') {
                            this.getLoginQrCode('app')
                        } else {
                            if (this.qrCodeLoginInterval !== null)clearInterval(this.qrCodeLoginInterval)
                            this.qrCodeLoginInterval = null
                        }
                    }
                } else {
                    this.error = response.msg
                    if (this.qrCodeType === 'app') {
                        this.getLoginQrCode()
                    } else {
                        if (this.qrCodeLoginInterval !== null)clearInterval(this.qrCodeLoginInterval)
                        this.qrCodeLoginInterval = null
                    }
                }
            })
        },
        getOtherQuery(query) {
            return Object.keys(query).reduce((acc, cur) => {
                if (cur !== 'redirect') {
                    acc[cur] = query[cur]
                }
                return acc
            }, {})
        },
        changekaptcha() {
            this.kaptchaUrl = process.env.VUE_APP_BASE_API + '/login/getKaptchaImage?t=' + Math.random()
        },
        nextFocus(ref) {
            if (ref === 'btnSubmit') {
                document.getElementById('subbtn').focus()
            } else {
                this.$refs[ref].focus()
            }
        },
        whoFocus() {
            setTimeout(() => {
                if (this.loginTab === 'phone') {
                    if (this.loginForm.phone === '') {
                        this.$refs.phone.focus()
                    } else if (this.loginForm.password === '') {
                        this.$refs.password.focus()
                    } else if (this.loginForm.kaptcha === '') {
                        this.$refs.kaptcha.focus()
                    }
                } else {
                    if (this.loginForm.orgcode === '') {
                        this.$refs.orgcode.focus()
                    } else if (this.loginForm.account === '') {
                        this.$refs.account.focus()
                    } else if (this.loginForm.password === '') {
                        this.$refs.password.focus()
                    } else if (this.loginForm.kaptcha === '') {
                        this.$refs.kaptcha.focus()
                    }
                }
            }, 100)
        },
        toggleTab(tab) {
            this.loginTab = tab
            this.error = ''
            this.doubleAuth = false
            this.whoFocus()
        },
        b64EncodeUnicode(str) {
            return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
                return String.fromCharCode('0x' + p1)
            }))
        },
        b64DecodeUnicode(str) {
            return decodeURIComponent(atob(str).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            }).join(''))
        },
        getCookie(cname) {
            var name = cname + '='
            var ca = document.cookie.split(';')
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i].trim()
                if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
            }
            return ''
        },
        // 下载pc客户端
        downloadPc(type) {
            this.$http({
                url: `${window.appVerApiUrl}/mpp/install/queryLastVersion`,
                method: 'GET',
                params: {
                    apigateway: location.host,
                    clientType: type
                }
            }).then(res => {
                if (res.code !== 'success') {
                    this.$message.error(res.message)
                    return
                }

                const downloadUrl = res.body.downloadUrl.replace(/^(\/?apigw)/, '')
                window.open(`${window.appVerApiUrl || process.env.VUE_APP_BASE_API}${downloadUrl}`)
            })
        },
        slideVerifyConfim(xWidth) {
            this.loginForm.kaptcha = xWidth
            this.loginBtnDisable = false
        },
        // 获取app下载二维码
        getAppDownloadQrCode() {
            this.appDownloadQrCode = (window.appVerApiUrl || process.env.VUE_APP_BASE_API) + `/mpp/install/downloadQrCode?apigateway=${window.location.origin}/apigw/&deleteWhite=true` + '&r=' + Math.random() + '&packageName=' + window.appPackageName
        },
        // 保存登录后数据
        async saveLoginData(res) {
            localStorage.setItem('staffList', JSON.stringify(res['STAFF_INFO_LIST']))
            let imapi = ''
            res.PERSON_ORGAN_LIST.some((v) => {
                let flag = false
                if (v.imapi) {
                    imapi = v.imapi
                    flag = true
                }
                return flag
            })
            if (imapi) {
                localStorage.setItem('xmppApi', imapi.replace(/\/$/, ''))
            }
            // Cookies.set('ECWEB-JWTSSO-TOKEN', res['ECWEB-JWTSSO-TOKEN'])
        },
        // 扫码顶部返回事件
        gobackLoginType() {
            this.loginType = 'account'
            if (this.qrCodeLoginInterval !== null)clearInterval(this.qrCodeLoginInterval)
            if (!this.searchKey.orgCode) {
                this.searchKey = {
                    orgName: '',
                    orgCode: ''
                }
                this.fakeQrcode = true
                this.updateQrCode = false
                this.$nextTick(() => {
                    this.updateQrCode = true
                })
                this.qrcodeCongig = {}
            }
        },
        // 搜索机构值改变时
        searchChange() {
            if (!this.searchKey.orgName) {
                this.fakeQrcode = true
                this.searchKey = {
                    orgName: '',
                    orgCode: ''
                }
                this.qrcodeCongig = {}
                this.updateQrCode = false
                this.$nextTick(() => {
                    this.updateQrCode = true
                })
            }
        }
    }
}
