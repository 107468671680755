<template>
  <WflowTester ref="wfTester" />
</template>

<script>
export default {

    data() {
        return {

        }
    },
    mounted() {

    }
}
</script>
