<template>
  <div class="caBox" style="width:100%;height:100%;background:#f2f2f2;border-radius:9px;">
    <!-- 月视图-->
    <template v-if="whichType==='2'&&listDetail.length>0&&listDetail.length<=3">
      <el-popover
        v-for="(item,index) in listDetail"
        :key="index"
        placement="bottom"
        width="280"
        trigger="hover"
        popper-class="proMonth-detail-zbgl"
      >
        <div class="proTime">
          <div class="pro-title">
            <span>{{ item.dutyDate | timeFomat }}应急值班</span>
          </div>
          <div class="pro-info">
            <!-- <div class="pro-name">带队领导：黄多（13700000001）</div> -->
            <!-- <div class="pro-name">值班成员：{{ item.dutyPerson || '-' }}</div>
            <div class="pro-name">值班司机：{{ item.dutyDriver || '-' }}</div> -->
            <div v-if="item.dutyPerson" class="pro-name">
              <span>值班成员：</span> <span v-for="(duty, dIndex) in item.passenger.dutyPersonList.filter(fi =>fi.personType===1)" :key="duty.id" :class="duty.dutyStatus==2?'rc':''">
                {{ duty.name + "（" + duty.mobilePhone +"）" }} <span v-if="item.passenger.dutyPersonList.filter(fi =>fi.personType===1).length-1!==dIndex">、</span> </span>
            </div>
            <div v-if="item.dutyDriver" class="pro-name">
              <span>值班司机：</span> <span v-for="(duty, dIndex) in item.passenger.dutyPersonList.filter(fi =>fi.personType===2)" :key="duty.id" :class="duty.dutyStatus==2?'rc':''">
                {{ duty.name + "（" + duty.mobilePhone +"）" }} <span v-if="item.passenger.dutyPersonList.filter(fi =>fi.personType===2).length-1!==dIndex">、</span> </span>
            </div>
            <div v-else class="pro-name">
              <span>值班司机：<span style="color:#f27707"> {{ setDataVo.carCaptain + "（" + setDataVo.carCaptainPhone +"）" }}</span></span>
            </div>
          </div>

        </div>
        <!-- <div slot="reference" class="four-item" :style="{'height':bigScreen?'28px':'18px','width':'100%'}" @click.stop="getMonthDetail(item)"> -->
        <div slot="reference" class="four-item" style="width:100%;height:100%;" @click.stop="getMonthDetail(item)">
          <!-- <span v-if="item.dutyPerson" class="four-span" :style="{'width':spanHeight}" :class="item.passenger.dutyPersonList.some(list => item.dutyPerson.indexOf(list.personName)&&list.dutyStatus==2)?'rc':''">成员：{{ item.dutyPerson }}</span>
          <span v-if="item.dutyDriver" class="four-span" :style="{'width':spanHeight}" :class="item.passenger.dutyPersonList.some(list => item.dutyDriver.indexOf(list.personName)&&list.dutyStatus==2)?'rc':''">司机：{{ item.dutyDriver }}</span> -->
          <div v-if="item.dutyPerson" class="four-span">
            <span>值班成员：</span> <span v-for="(duty, dIndex) in item.passenger.dutyPersonList.filter(fi =>fi.personType===1)" :key="duty.id" :class="duty.dutyStatus==2?'rc':''">
              {{ duty.name + "（" + duty.mobilePhone +"）" }} <span v-if="item.passenger.dutyPersonList.filter(fi =>fi.personType===1).length-1!==dIndex">、</span> </span>
          </div>
          <div v-if="item.dutyDriver" class="four-span">
            <span>值班司机：</span> <span v-for="(duty, dIndex) in item.passenger.dutyPersonList.filter(fi =>fi.personType===2)" :key="duty.id" :class="duty.dutyStatus==2?'rc':''">
              {{ duty.name + "（" + duty.mobilePhone +"）" }} <span v-if="item.passenger.dutyPersonList.filter(fi =>fi.personType===2).length-1!==dIndex">、</span> </span>
          </div>
          <div v-else class="four-span">
            <span>值班司机：<span style="color:#f27707"> {{ setDataVo.carCaptain + "（" + setDataVo.carCaptainPhone +"）" }}</span></span>
          </div>
        </div>
      </el-popover>
    </template>
  </div>
</template>
<script>
const moment = require('moment')
export default {
    filters: {
        timeFomat(val) {
            if (val) return moment(new Date(val)).format('YYYY年MM月DD日')
            return ''
        }
    },
    props: {
        whichType: {
            type: String,
            default: '1'
        },
        listDetail: {
            type: Array,
            default: function() {
                return []
            }
        },
        weekArray: {
            type: Array,
            default: function() {
                return []
            }
        },
        setDataVo: {
            type: Object,
            default: function() {
                return {}
            }
        }
    },
    data() {
        return {
            monthClick: false,
            bigScreen: false,
            shouOrOPen: false,
            shouOrOPenA: false,
            liHeight: 0,
            spanHeight: '',
            calendarTime: ''
        }
    },
    watch: {
    },
    created() {
    },
    mounted() {
        console.log('this.setDataVo===>', this.setDataVo)
        const _this = this
        this.getWindowInfo()
        window.addEventListener('resize', this.getWindowInfo)
        this.initLine()
        if (this.whichType === '4') {
            setTimeout(() => {
                this.initLine()
            }, 100)
        }
        // 目标元素
        const targetElement = document.querySelector('.month-box')
        if (targetElement) {
            // 创建MutationObserver实例，传入回调函数
            const observer = new ResizeObserver((mutationsList, observer) => {
            // 处理DOM变化的逻辑
                _this.getWindowInfoA()
            })
            // 开始观察目标元素
            observer.observe(targetElement)
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getWindowInfo)
    },
    methods: {
        initLine() {
            this.$nextTick(() => {
                if (document.querySelectorAll('.canOPen') && document.querySelectorAll('.canOPenA')) {
                    document.querySelectorAll('.canOPen').forEach(item => {
                        item.style = 'display:none;'
                        this.shouOrOPen = true
                    })
                    document.querySelectorAll('.canOPenA').forEach(item => {
                        item.style = 'display:none;'
                        this.shouOrOPenA = true
                    })
                }
                if (document.querySelectorAll('.el-scrollbar__view').length === 3) {
                    this.liHeight = document.querySelectorAll('.el-scrollbar__view')[2].clientHeight
                    this.$bus.emit('liH', this.liHeight)
                }
            })
        },
        forMarTime(val) {
            const value = val.split(':')
            return value[0] + ':' + value[1]
        },
        getMonthDetail(vo) {
            const isEnd = false
            this.$bus.emit('editForm', vo, 'edit', isEnd)
            // this.getDataById(vo)
        },
        getDataById(vo) {
            // this.$http({
            //     contentType: 'application/x-www-form-urlencoded',
            //     url: `zbgl/dutyRecord/getById`,
            //     method: 'GET',
            //     params: {
            //         id: vo.id
            //     }
            // }).then(res => {
            //     const isEnd = false
            //     this.$bus.emit('editForm', res.body.vo, 'edit', isEnd)
            // })
        },
        getWindowInfo() {
            clearTimeout(this.calendarTime)
            this.calendarTime = setTimeout(() => {
                if (document.querySelector('.month-box')) {
                    this.spanHeight = Math.floor((document.querySelector('.month-box').clientWidth - 56) / 7) - 20 + 'px'
                }
                if (document.body.clientWidth > 1600) {
                    this.bigScreen = true
                } else {
                    this.bigScreen = false
                }
                var width = $('.month-week div').width()
                $('.four-span').css('width', (width - 15) + 'px')
            }, 500)
        },
        getWindowInfoA() {
            if (document.querySelector('.month-box')) {
                this.spanHeight = Math.floor((document.querySelector('.month-box').clientWidth - 56) / 7) - 20 + 'px'
            }
            if (document.body.clientWidth > 1600) {
                this.bigScreen = true
            } else {
                this.bigScreen = false
            }
            var width = $('.month-week div').width()
            $('.four-span').css('width', (width - 15) + 'px')
        }
    }
}
</script>
<style lang="scss" scoped>
 .proMonth-detail-zbgl{
    border-radius: 8px !important;
    height: unset !important;
   .proTime{
      .pro-title{
        overflow: hidden;
        font-size: 15px;
        line-height: 36px;
        position: relative;
        font-weight: 500;
        color: #222;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .pro-info {
        font-size: 13px;
        color: #666;
        .pro-name {
          margin: 5px 0;
        }
      }
   }
 }
</style>
<style scoped lang="scss">
  .four-pro{
    .pro-title{
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-weight: bold;
    }
    .info-title{
      height: 25px;
      font-size: 14px;
      display: flex;
      align-items: center;
      .span-title{
        margin-left: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 80px;
      }
      .span-time{
        margin-left: 10px;
      }
      .span-color{
          width: 6px;
          height: 6px;
          display: inline-block;
          background:  var(--bgColor);
          transform: rotate(45deg);
      }
    }
  }
  .four-item{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: #f2f2f2 !important;
    width: 100%;
    .four-span{
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      font-size: 14px;
      color: #333;
      line-height: 20px;
    }
  }
  .rc {
    color: red !important;
  }
</style>
