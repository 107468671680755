
<template>
  <div id="qqq" class="lucky-excel">
    <div class="top">
      <div class="left">
        <h2>{{ fileName }}</h2>
      </div>
      <div class="right">
        <el-button v-if="!print" size="mini" @click="save('down')">另存为</el-button>
        <el-button v-if="allowEdit" size="mini" @click="save('save')">保存</el-button>
        <el-button v-if="allowEdit" type="primary" size="mini" @click="save('saveAndClose')">保存关闭</el-button>
        <!-- <el-button v-if="print" type="primary" size="mini" @click="save('print')">打印</el-button> -->
        <img class="poImg" :src="avatar" alt="">
      </div>
    </div>
    <iframe id="excelHtml" ref="myframe" width="100%" frameborder="0" :src="excelUrl" @load="loaded" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'EditExcel',

    props: {
        downUrl: { type: String, default: '' }, // 下载地址
        allowEdit: { type: Boolean, default: true }, // 允许编辑
        online: { type: Boolean, default: false }, // 在线预览
        print: { type: Boolean, default: false }, // 打印
        fileName: { type: String, default: '' }, // 文件名称
        dataType: { type: Number, default: 1 }, // 数据类型  数组/对象
        isDownLoad: { type: Number, default: 0 }, // 是否自动另存为并关闭
        autoSave: { type: Boolean, default: false }, // 允许自动保存
        tempObject: { type: Object, default: function () { return {} } },
        tempArray: { type: Array, default: function () { return [] } },
        formatArray: { type: Array, default: function () { return [] } } // 时间格式化列数组

    },
    data() {
        return {
            excelUrl: 'excelIndex.html',
            rowData: [],
            photoUrl: ''
        }
    },
    computed: {
        ...mapGetters([
            'avatar',
            'loginUser'
        ])
    },
    watch: {
    },
    created() {
        this.photoUrl = this.avatar
        if (!this.downUrl) {
            this.excelUrl = 'excelIndex.html?viewUrl=' + this.$route.query.viewUrl + '&attachIds=' + this.$route.query.attachIds + '&saveAs=' + this.$route.query.saveAs
        }
    },
    mounted() {
        window.addEventListener('message', this.handleMessage)
        if (this.isDownLoad == 1) {
            setTimeout(() => {
                this.save('down')
            }, 2000)
        }
    },
    methods: {
        save(type) {
            console.log('typeWWW', type)

            this.$refs.myframe.contentWindow.postMessage({
                type: type
            }, '*')
        },
        loaded() {
            if (this.downUrl) {
                // if (JSON.stringify(this.tempObject) !== '{}') {
                //     if (this.tempObject.rpInfo.length > 0) {
                //         this.tempObject.rpInfo.forEach(item => {
                //             this.rowData.push(item)
                //         })
                //     }
                // }
                let array = ''
                if (this.dataType === 1) {
                    array = this.tempObject
                } else {
                    array = this.tempArray
                }
                this.$refs.myframe.contentWindow.postMessage({
                    type: 'initExcel',
                    url: this.downUrl,
                    canEdit: this.allowEdit,
                    fileName: this.fileName,
                    autoSave: this.autoSave,
                    formatArray: this.formatArray,
                    tempObject: array,
                    online: this.online,
                    print: this.print
                }, '*')
            }
        },
        handleMessage(event) {
            if (event.origin === location.origin) {
                if (event.data.type === 'save') {
                    if (event.data.celldata.length > 0) {
                        event.data.celldata = event.data.celldata.filter(item => item.v.hasOwnProperty('m'))
                        if (JSON.stringify(event.data.rowLen) !== '{}' && event.data.rowLen) {
                            for (const key in event.data.rowLen) {
                                event.data.celldata.forEach(item => {
                                    if (item.r == key) {
                                        item.rowLen = event.data.rowLen[key]
                                    }
                                })
                            }
                        }
                    }
                    this.$emit('saveExcel', event.data.celldata, event.data.flag)
                } else if (event.data.type === 'close') {
                    this.$emit('close')
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.lucky-excel {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  .top {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .right {
      height: 100%;
      line-height: 60px;
      .poImg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        vertical-align: middle;
        margin-left: 20px;
      }
    }
  }
  #excelHtml {
    height: calc(100% - 60px);
    // padding: 0 10px;
  }
}
</style>
