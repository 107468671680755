<template>
  <div>
    <!-- <el-table class="xzhqTable" :data="curTn.tnNodeName === '发起值班收集'?tableData:wfInstance.passenger.holidayArrangeDetails" border style="width: 100%" @row-click="rowClick"> -->
    <!-- <el-table class="xzhqTable" :data="wfInstance.passenger.holidayArrangeDetails.length>0?wfInstance.passenger.holidayArrangeDetails:tableData" border style="width: 100%" @row-click="rowClick"> -->
    <el-table class="xzhqTable" :data="tableData" border style="width: 100%" @row-click="rowClick">
      <el-table-column prop="arrangeDatetime" label="日期" width="100" align="center">
        <template slot-scope="scope">
          {{ changeTime(scope.row.arrangeDatetime) }}
        </template>
      </el-table-column>
      <el-table-column prop="leaderName" label="带班领导" width="100" align="center" />
      <el-table-column prop="holidayDep" label="值班部门" width="150" align="center">
        <template slot-scope="scope">
          <el-input v-if="curTn.tnNodeName === '发起值班收集' && canEdit" v-model="scope.row.holidayDep" v-select-dialog="'suborg'" org-level="2,3" dialog-title="选择主办部门" :root-org-code="vo.orgCode" name="holidayDep" />
          <span v-else>{{ scope.row.holidayDep }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dayName" label="白班值班人员" width="" align="center">
        <template slot-scope="scope">
          <!-- <el-input v-if="curTn.tnNodeName === '部门填报' && canEdit && scope.row.deptReportId === loginUser.personId" v-model="scope.row.dayName" v-select-dialog="'staff'" :org-id="scope.row.holidayDepId" name="dayName" resize="none" /> -->
          <el-input v-if="curTn.tnNodeName === '部门填报'&& canEdit" v-model="scope.row.dayName" v-select-dialog="'staff'" :org-id="scope.row.holidayDepId" name="dayName" resize="none" />
          <span v-else>{{ scope.row.dayName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="nightName" label="晚班值班人员" width="" align="center">
        <template slot-scope="scope">
          <el-input v-if="curTn.tnNodeName === '部门填报'&& canEdit" v-model="scope.row.nightName" v-select-dialog="'staff'" :org-id="scope.row.holidayDepId" name="nightName" resize="none" />
          <span v-else>{{ scope.row.nightName }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const moment = require('moment')
export default {
    components: {

    },
    props: {
        vo: { type: Object, default: function() { return {} } },
        wfInstance: { type: Object, default: function() { return {} } },
        curTn: { type: Object, default: function() { return {} } },
        canEdit: { type: Number, default: function() { return 1 } }
    },
    data() {
        return {
            tableData: [],
            // tableData: this.wfInstance.passenger.holidayArrangeDetails.length > 0 ? this.wfInstance.passenger.holidayArrangeDetails : [],
            rowIndex: 0,
            leaderList: [],
            leaderIndex: 0
        }
    },
    computed: {
        ...mapGetters(['routes', 'loginUser']),
        changeTime() {
            return (time) => {
                return moment(time).format('YYYY-MM-DD')
            }
        }
    },
    watch: {
        'vo.holidayName': {
            handler(newName, oldName) {
                this.wfInstance.passenger.holiday.map(v => {
                    if (v.name === newName) {
                        this.$http({
                            method: 'GET',
                            contentType: 'application/x-www-form-urlencoded',
                            params: {
                                id: v.id
                            },
                            url: 'xzhq/holiday/getById'
                        }).then((res) => {
                            if (res.code === 'success') {
                                const moment = require('moment')
                                const num = ((moment(res.body.vo.endDay) - moment(res.body.vo.beginDay)) / 24 / 60 / 60 / 1000)
                                this.tableData = []
                                for (let i = 0; i <= num; i++) {
                                    this.tableData.push({
                                        parentId: this.vo.id,
                                        holidayId: res.body.vo.id,
                                        holidayName: res.body.vo.name,
                                        holidayDep: '',
                                        holidayDepId: '',
                                        arrangeDatetime: moment(moment(res.body.vo.beginDay) + i * 24 * 60 * 60 * 1000).format('YYYY-MM-DD HH:mm:ss'),
                                        responseLevel: '',
                                        leaderAccount: '',
                                        leaderName: '',
                                        leaderContact: '',
                                        leaderDays: '',
                                        dayAccount: '',
                                        dayName: '',
                                        dayContact: '',
                                        dayDays: '',
                                        nightAccount: '',
                                        nightName: '',
                                        nightContact: '',
                                        nightDays: ''
                                    })
                                }
                            }
                            this.$nextTick(() => {
                                this.getInnerLeader()
                            })
                        })
                    }
                })
            },
            immediate: true
        },
        'vo.firstLeader': {
            handler(newName, oldName) {
                this.$nextTick(() => {
                    this.getInnerLeader()
                })
            },
            immediate: true
        },
        tableData: {
            handler(newName, oldName) {
                console.log('newName', newName)
                let status = false
                newName.map(item => {
                    if (!item.holidayDepId) {
                        status = true
                    }
                })
                if (!status && this.curTn.tnNodeName === '发起值班收集' && newName.length > 0) {
                    console.log('this', this)
                    this.$nextTick(() => {
                        this.$vnode.context.$vnode.context.btnSubmitClick('AutoSave')
                    })
                }
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        console.log('this.vo', this.vo)
        this.getLeaderList()
    },
    beforeDestroy() {

    },
    mounted() {
        setTimeout(() => {
            this.tableData = this.wfInstance.passenger.holidayArrangeDetails.length > 0 ? this.wfInstance.passenger.holidayArrangeDetails : []
        }, 1000)
    },
    methods: {
    // 选择部门
        onSelectSuborgCompleted(nodes, attr) {
            this.tableData[this.rowIndex].holidayDep = nodes[0].name
            this.tableData[this.rowIndex].holidayDepId = nodes[0].orgFullId.split('-')[1]
        },
        // 选择人员
        onSelectStaffCompleted(nodes, attr) {
            if (attr.name === 'dayName') {
                this.wfInstance.passenger.holidayArrangeDetails[this.rowIndex].dayName = nodes[0].name
                this.wfInstance.passenger.holidayArrangeDetails[this.rowIndex].dayContact = nodes[0].mobile
                this.wfInstance.passenger.holidayArrangeDetails[this.rowIndex].dayAccount = nodes[0].personId
                if (!this.wfInstance.passenger.holidayArrangeDetails[this.rowIndex].nightName) {
                    this.wfInstance.passenger.holidayArrangeDetails[this.rowIndex].nightName = nodes[0].name
                    this.wfInstance.passenger.holidayArrangeDetails[this.rowIndex].nightContact = nodes[0].mobile
                    this.wfInstance.passenger.holidayArrangeDetails[this.rowIndex].nightAccount = nodes[0].personId
                }
            } else if (attr.name === 'nightName') {
                this.wfInstance.passenger.holidayArrangeDetails[this.rowIndex].nightName = nodes[0].name
                this.wfInstance.passenger.holidayArrangeDetails[this.rowIndex].nightContact = nodes[0].mobile
                this.wfInstance.passenger.holidayArrangeDetails[this.rowIndex].nightAccount = nodes[0].personId
                if (!this.wfInstance.passenger.holidayArrangeDetails[this.rowIndex].dayName) {
                    this.wfInstance.passenger.holidayArrangeDetails[this.rowIndex].dayName = nodes[0].name
                    this.wfInstance.passenger.holidayArrangeDetails[this.rowIndex].dayContact = nodes[0].mobile
                    this.wfInstance.passenger.holidayArrangeDetails[this.rowIndex].dayAccount = nodes[0].personId
                }
            }
        },
        rowClick(row, column, event) {
            console.log('row', row)
            // console.log('column', column)
            if (this.curTn.tnNodeName === '发起值班收集') {
                this.tableData.map((item, index) => {
                    if (item.arrangeDatetime === row.arrangeDatetime) {
                        this.rowIndex = index
                    }
                })
            } else {
                this.wfInstance.passenger.holidayArrangeDetails.map((item, index) => {
                    if (item.arrangeDatetime === row.arrangeDatetime) {
                        this.rowIndex = index
                    }
                })
            }
        },
        getLeaderList() {
            console.log(this.loginUser)
            this.$http({
                method: 'GET',
                contentType: 'application/x-www-form-urlencoded',
                params: {
                    orgCode: this.loginUser.orgCode,
                    self: false
                },
                url: 'base/organMgr/findOrgLeaderByOrgCode'
            }).then((res) => {
                if (res.code === 'success') {
                    this.leaderList = res.body.listdata
                }
            })
        },
        getInnerLeader() {
            if (this.tableData.length > 0 && this.leaderList.length > 0 && this.vo.firstLeader) {
                this.leaderList.map((item, index) => {
                    if (item.personId === this.vo.firstLeaderAccount) {
                        this.leaderIndex = index
                    }
                })
                this.tableData.map((item, index) => {
                    item.leaderName = this.leaderList[(index + this.leaderIndex) % this.leaderList.length].name
                    item.leaderAccount = this.leaderList[(index + this.leaderIndex) % this.leaderList.length].personId
                    item.leaderContact = this.leaderList[(index + this.leaderIndex) % this.leaderList.length].mobile
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.xzhqTable {
  /deep/ .el-input input {
    pointer-events: none;
  }
}
</style>
