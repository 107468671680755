<template>
    <div style="height: 100%; background-color: #f4f6f9; overflow: hidden">
        <div class="header-container" :class="{ 'hideTopMenus-header-container': !showTopMenus }">
            <logo />
            <!-- <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" :is-show="isShow" @toggleClick="toggleSideBar" />

    <div class="header-navbar">
      <topMenu v-if="showTopMenus" @showToggle="showToggle" />
      <tags-view v-if="needTagsView && !showTopMenus" />
    </div> -->
            <div class="breadcrumb">
                <span>统一搜索</span>
                <i style="margin: 0 7px" class="el-icon-d-arrow-right"></i>
                <span>{{ searchKey }}</span>
            </div>

            <div class="right-menu" style="display: flex">
                <div ref="searchIcon" style="margin-right: 10px; cursor: pointer">
                    <svg style="width: 22px; height: 22px; margin-top: 19px" ria-hidden="true" class="svg-icon-font">
                        <use :xlink:href="'#icon-souaaaa'" />
                    </svg>
                    <!-- <i class="el-input__icon el-icon-search" @click="searchClick" /> -->
                </div>

                <div class="avatar-container right-menu-item hover-effect">
                    <div class="avatar-wrapper">
                        <img :src="photoUrl" class="user-avatar" />
                        <!-- <i class="el-icon-caret-bottom" /> -->
                        <div class="welcome">
                            <div style="line-height: 18px">welcome</div>
                            <div style="line-height: 18px">{{ nickname }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <elCardShow2 v-if="elCardShow" ref="elCardShow" :search-key="searchKey" :el-card-show="elCardShow" @searchinput="searchinput" />
            <!-- <drawerSearch2 :drawer111="drawer111" :input2="input2" @handleClose="handleClose" @searchinput="searchinput" /> -->
        </div>
        <!-- <div>111222</div> -->
        <div class="fileSearchResults">
            <fileSearchResults :search-key="searchKey" @clearSearchKey="ClearSearch" />
        </div>
    </div>
</template>

<script>
import Logo from './Logo'
import { mapGetters, mapState } from 'vuex'
// import drawerSearch2 from './drawerSearch2'
import fileSearchResults from './fileSearchResults'
import elCardShow2 from './elCardShow2'
let timer = null
export default {
    components: {
        Logo,
        // drawerSearch2,
        fileSearchResults,
        elCardShow2
    },
    data() {
        return {
            isShow: true,
            nickname: '',
            photoUrl: '',
            showCustom: null,
            gxh: false,
            showshow: false,
            showPersonStatus: true,
            drawer111: false,
            searchKey: '',
            input2: '',
            elCardShow: false,
            elCardShowWatch: false
        }
    },
    computed: {
        ...mapGetters(['sidebar', 'avatar', 'device', 'loginUser', 'organList', 'routes']),
        ...mapState({
            needTagsView: (state) => state.settings.tagsView
        }),
        showTopMenus() {
            return this.$store.state.settings.showTopMenus
        },
        isCollapse() {
            return !this.sidebar.opened
        }
    },
    watch: {
        $route: {
            handler(val) {
                this.setShowView()
            },
            deep: true
        },
        // drawer111: {

        // },
        input2: {},
        elCardShowWatch: {
            handler(val) {
                clearTimeout(timer)
                timer = null
                if (val) {
                    this.elCardShow = true
                } else {
                    timer = setTimeout(() => {
                        this.elCardShow = false
                        clearTimeout(timer)
                        timer = null
                    }, 300)
                }
            }
        }
    },
    created() {
        // this.$bus.on('showPerson', this.showPerson)
        document.addEventListener('mouseover', this.searchTimer)
    },
    mounted() {
        this.setShowView()
        const cookieStyle = this.Cookies.get('uiStyle')
        let style = ''
        if (cookieStyle !== this.loginUser.uiStyle) {
            style = this.loginUser.uiStyle
            this.Cookies.set('uiStyle', style)
        } else {
            style = cookieStyle
        }
        document.querySelector('body').setAttribute('id', this.loginUser.orgCode != 'plat' ? style : 'skinScienceBlue')

        this.nickname = this.loginUser.nickname
        this.photoUrl = this.avatar
        if (window.productCode !== '') {
            this.photoUrl += '&productCode=' + window.productCode
        }
    },
    destroyed() {
        // this.$bus.off('showPerson', this.showPerson)
        document.removeEventListener('mouseover', this.searchTimer)
    },
    methods: {
        setShowView() {
            this.searchKey = this.$route.query.searchKey
            this.input2 = this.$route.query.searchKey
        },
        searchTimer(e) {
            var _this = this

            // console.log('mouseOver')
            var aaa = false
            var bbb = false
            // var timer = null
            const elCardShow1 = _this.$refs.elCardShow
            if (elCardShow1) {
                aaa = elCardShow1.$el.contains(e.target)
            }
            // debugger
            // console.log(aaa)

            const searchIcon = _this.$refs.searchIcon
            if (searchIcon) {
                bbb = searchIcon.contains(e.target)
            }

            // clearTimeout(timer)
            // timer = null

            if (aaa || bbb) {
                _this.elCardShowWatch = true
            } else {
                // timer = setTimeout(() => {
                _this.elCardShowWatch = false
                //     clearTimeout(timer)
                //     timer = null
                // }, 500)
            }
            // })
        },
        // 关闭搜索页抽屉
        handleClose() {
            console.log('关闭')
            this.drawer111 = false
        },
        // 输入框输入值搜索
        searchinput(val) {
            // this.drawer111 = false
            this.elCardShow = false
            this.searchKey = val
            this.input2 = val
            // this.setIontoStorage('jobOrderNoArr')
        },
        // // 选择历史记录搜索
        // listdataclick(item) {
        //     this.searchKey = item
        //     this.input2 = item
        // },

        // 页面清除搜索
        ClearSearch() {
            // this.$router.go(-1)
            const { query: oldQuery } = this.$route
            const query = JSON.parse(JSON.stringify(oldQuery))
            // delete query.searchKey
            delete query.searchKey
            delete query.searchTime
            this.$router.go(-1)
        },
        showPerson(show) {
            this.showPersonStatus = show
        },
        showAuto() {
            if (this.gxh === true) {
                this.showshow = false
            } else {
                this.showshow = true
            }
            this.$emit('showCustomBool', this.showshow)
        },
        async logout() {
            await this.$store.dispatch('user/logout')

            await this.$store.dispatch('tagsView/delAllViews')
            this.$router.push(`/login`)
            // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
        },
        toggleSideBar() {
            this.$store.dispatch('app/toggleSideBar')
        },
        getCookie(cname) {
            var name = cname + '='
            var ca = document.cookie.split(';')
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i].trim()
                if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
            }
            return ''
        },
        showToggle(boo) {
            this.isShow = boo
        },
        // 递归查询菜单代码
        getMenuCode(code) {
            let toPath
            function findCode(ary) {
                ary.forEach((val) => {
                    if (val.children) {
                        findCode(val.children)
                    }
                    if (!val.children && val.meta && val.meta.code === code) {
                        toPath = val
                        return false
                    }
                })
            }
            findCode(this.routes)
            return toPath
        },
        // 跳转页面
        gotoPages(code) {
            const toPath = this.getMenuCode(code)
            if (toPath && toPath.path) {
                let path
                switch (toPath.meta.level) {
                    case 3:
                        path = toPath.meta.activeMenu || toPath.path
                        break
                    case 4:
                        path = toPath.meta.activeMenu + '/' + toPath.path
                        break
                    default:
                        path = toPath.path
                }
                this.$router.push({ path: path })
            }
        },
        backToPlatWorkspace() {
            this.$http({
                method: 'GET',
                url: 'base/sysSetup/switchSysLogin',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                if (response.code === 'success') {
                    this.$store
                        .dispatch('user/tokenSsoLogin', response.body)
                        .then(async (res) => {
                            await this.$store.dispatch('route/updateRoutes', [])
                            await this.$store.dispatch('tagsView/delAllViews')

                            this.$router.push({ path: '/', query: this.otherQuery })
                        })
                        .catch((error) => {
                            this.error = error.msg
                        })
                } else {
                    this.error = response.msg
                    this.getLoginQrCode()
                }
            })
        }
    }
}
</script>

<style lang="scss">
@import '~@/wapp/common/styles/variables.module.scss';

.hamburger-container {
    height: $headerHeight;
    line-height: $headerHeight;
    height: 100%;
    float: left;
    cursor: default;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    .hamburger {
        fill: $headerText;
    }
}
.breadcrumb {
    height: $headerHeight;
    line-height: $headerHeight;
    font-size: 18px;
    width: 50vw;
    position: relative;
    left: 15px;
    top: 2px;
}
.header-navbar {
    float: left;
    top: 0px;

    &.collapse {
        left: 54px;
    }

    .el-menu::after,
    .el-menu::before {
        display: none;
    }

    .breadcrumb-container {
        float: left;
    }

    .tags-view-container {
        width: calc(100vw - #{$sideBarWidth} - 50px - 250px);
        height: 60px;
        border-bottom: 0px;

        .scroll-container {
            height: 60px;
            .el-scrollbar__wrap {
                height: 77px;
                .tags-view-item {
                    font-size: 14px;
                    height: 60px;
                    line-height: 60px;
                }
            }
        }
    }
    .el-menu-item {
        border-bottom: none !important;
    }
}
.header-container {
    width: 100%;
    position: fixed;
    background: $headerBackground;
    top: 0px;
    z-index: 1999;
}
.hideTopMenus-header-container {
    border-bottom: $borderSolid;
    background: #fff !important;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    z-index: 1000;

    .hamburger {
        fill: $dark;
    }
    .breadcrumb-container {
        float: left;
        height: $headerHeight;
        line-height: $headerHeight !important;
    }

    .right-menu-item {
        color: $dark !important;
    }

    .sidebar-logo-container {
        background: $dark-menu-bgcolor;
        .sidebar-logo-link .sidebar-title {
            /* color: var(--textColor)!important; */
            color: $headerText !important;
        }
    }

    .tags-view-container .tags-view-wrapper .tags-view-item {
        border-bottom: none;
    }
}
.right-menu {
    // display: flex;
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100%;
    line-height: $headerHeight;

    &:focus {
        outline: none;
    }

    .right-menu-item {
        display: inline-block;
        padding: 0 8px;
        height: 100%;
        font-size: 18px;
        color: $headerText;
        vertical-align: text-bottom;

        &.hover-effect {
            cursor: default;
            transition: background 0.3s;

            &:hover {
                background: rgba(0, 0, 0, 0.025);
            }
        }
    }

    .avatar-container {
        margin-right: 10px;

        .avatar-wrapper {
            /* margin-top: 5px; */
            position: relative;
            float: right;

            .user-avatar {
                cursor: default;
                width: 38px;
                height: 38px;
                border-radius: 50%;
                margin-top: 10px;
                border: #fff solid 2px;
            }

            .el-icon-caret-bottom {
                cursor: default;
                font-size: 12px;
                float: right;
                line-height: $headerHeight;
            }

            .welcome {
                font-size: 12px;
                float: right;
                margin: 0px 5px;
                padding-top: 10px;
                height: $headerHeight;
                word-break: break-word;
                word-wrap: break-word;
                text-align: center;
                max-width: 80px;
            }
        }
    }
}

.header-container {
    background: var(--bgColor);
    color: var(--textColor);
    .el-menu-item {
        background: var(--bgColor);
        color: var(--textColor) !important;
        &.is-active {
            background: var(--bgColorActive);
            color: var(--textColorActive) !important;
        }
        &:hover {
            color: var(--textColorActive) !important;
            background: var(--bgColorActive);
        }
    }
}
.box-fileSearchResults::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
}
.fileSearchResults {
    margin-top: 70px;
    max-height: calc(100vh - 70px);
    overflow-y: auto;
}
</style>
