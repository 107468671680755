<template>
    <div class="skin-selector">
        <div>
            <el-popover v-if="dispose == 0" ref="popoverRef" placement="top-end" width="400" trigger="click" :visible-arrow="true" popper-class="nopad" @show="showPopover">
                <div class="message">
                    <svg-icon icon-class="dingyue" style="cursor: pointer; width: 19px; height: 19px; position: absolute; right: 17px; top: 22px; z-index: 1" @click="opendispose()" />
                    <div style="display: flex; justify-content: space-between">
                        <el-tabs v-model="activeTab" @tab-click="handleClick">
                            <!-- <el-tab-pane name="allMessage">
                <span slot="label">
                  全部消息
                  <el-badge :value="allMessageNum" :max="99" />
                </span>
                <template v-if="listdata.length > 0">
                  <div v-for="(item,index) in listdata" :key="index" class="item" @click="messageClick(index,item.id)">
                    <div class="list">
                      <div :class="item.isRead==0?'noread':'read'" />
                      <div class="img">
                        <el-image style="width: 100%; height: 100%;background:#eee;display: flex !important;justify-content: center !important;align-items: center !important;" :src="urlImage" :fit="fit">
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline" />
                          </div>
                        </el-image>
                      </div>
                      <el-tooltip effect="dark" placement="bottom-start">
                        <div slot="content" style="max-width:300px;">{{ item.msgContent }}</div>
                        <div class="text">
                          {{ item.msgContent }}
                          <div class="typeface" style="color: #2C80FF;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 225px;">{{ item.msgUrl }}</div>
                          <div class="name">{{ item.senderName }}</div>
                        </div>
                      </el-tooltip>
                      <div class="info">
                        <div class="time">{{ cutTime(item.createTime) }}</div>
                      </div>
                    </div>
                  </div>

                </template>
                <EmptyList v-if="listdata.length === 0" />
              </el-tab-pane> -->

                            <el-tab-pane name="systemMessage">
                                <span slot="label">
                                    系统消息
                                    <el-badge :value="systemMessageNum" :hidden="systemMessageNum < 1 ? true : false" :max="99" />
                                </span>
                                <template v-if="listdata.length > 0">
                                    <div v-for="(item, index) in listdata" :key="index" class="item" @click="messageClick(index, item.id)">
                                        <div class="list flex-item">
                                            <!-- <div :class="item.isRead==0?'noread':'read'" /> -->
                                            <div class="noread" />
                                            <div class="img">
                                                <el-image style="width: 100%; height: 100%; background: #eee; display: flex !important; justify-content: center !important; align-items: center !important" :src="urlImage" :fit="fit">
                                                    <div slot="error" class="image-slot">
                                                        <i class="el-icon-picture-outline" />
                                                    </div>
                                                </el-image>
                                            </div>
                                            <el-tooltip transition="" :open-delay="300" popper-class="tooltip-content" effect="dark" placement="top">
                                                <div slot="content" style="max-width: 300px">{{ item.msgContent }}</div>
                                                <div class="text">
                                                    {{ item.msgContent }}
                                                    <!-- <div class="typeface" style="color: #2C80FF;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 225px;">{{ item.msgUrl }}</div> -->
                                                    <div class="name">{{ item.senderName }}</div>
                                                </div>
                                            </el-tooltip>
                                            <div class="info">
                                                <div class="time">{{ cutTime(item.createTime) }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-tab-pane>

                            <!-- <el-tab-pane name="applyMessage">
                <span slot="label">
                  应用消息
                  <el-badge :value="applyMessageNum" :max="99" />
                </span>
                <template v-if="listdata1.length > 0">
                  <div v-for="(item,index) in listdata1" :key="index" class="item" @click="messageClick(index,item.id)">
                    <div class="list">
                      <div :class="item.isRead==0?'noread':'read'" />
                      <div class="img">
                        <el-image style="width: 100%; height: 100%;background:#eee;display: flex !important;justify-content: center !important;align-items: center !important;" :src="urlImage" :fit="fit">
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline" />
                          </div>
                        </el-image>
                      </div>
                      <el-tooltip effect="dark" placement="bottom-start">
                        <div slot="content" style="max-width:300px;">{{ item.msgContent }}</div>
                        <div class="text">
                          {{ item.msgContent }}
                          <div class="typeface" style="color: #2C80FF;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 225px;">{{ item.msgUrl }}</div>
                          <div class="name">{{ item.senderName }}</div>
                        </div>
                      </el-tooltip>
                      <div class="info">
                        <div class="time">{{ cutTime(item.createTime) }}</div>
                      </div>
                    </div>
                  </div>

                </template>
                <EmptyList v-if="listdata1.length === 0" />
              </el-tab-pane> -->
                        </el-tabs>
                        <EmptyList v-if="listdata.length === 0" style="margin-right: 80px; margin-top: 60px" />
                    </div>
                </div>
                <div style="width: 100%; height: 1px; background-image: linear-gradient(270deg, rgba(190, 190, 190, 0) 0%, #ebebeb 49.13%, rgba(190, 190, 190, 0) 100%)" />
                <div style="display: flex; justify-content: space-between; margin-top: 12px; padding: 10px">
                    <div style="font-style: normal; font-weight: 400; font-size: 14px; text-transform: uppercase; color: #8c8c8c; cursor: pointer" @click="allRead()">
                        全部已读
                        <svg-icon icon-class="clean" style="margin-left: 7px" />
                    </div>
                    <div style="font-style: normal; font-weight: 400; font-size: 14px; text-transform: uppercase; color: #8c8c8c; cursor: pointer" @click="onMoreClick()">
                        查看所有消息
                        <i class="el-icon-arrow-right" style="margin-left: 7px" />
                    </div>
                </div>
                <span slot="reference" :class="{ animImage: flicker }" class="animImage-span">
                    <svg-icon v-if="layout === 0 || !layout" style="width: 24px; cursor: pointer; position: relative; top: 1px" class-name="organ-icon" icon-class="xzbgHytz" @click="visible = !visible" />
                    <svg-icon v-else class-name="organ-icon" icon-class="messages" style="font-size: 17px; cursor: pointer; width: 24px; position: relative; top: 0px" @click="visible = !visible" />
                </span>
            </el-popover>

            <el-popover v-if="dispose == 1" placement="top-end" width="400" trigger="hover" popper-class="nopad" @show="showPopover">
                <div class="message">
                    <div style="display: flex; justify-content: space-between; margin: 10px 5px 10px 0; font-size: 14px; text-transform: uppercase; color: #24292b">
                        <div style="font-weight: 600">消息推送配置</div>
                        <div style="display: flex; margin-right: 10px">
                            <div style="margin-right: 15px; cursor: pointer; color: rgba(0, 0, 0, 0.4)" @click="cancel()">取消</div>
                            <div class="submit" style="cursor: pointer" @click="submit()">完成</div>
                        </div>
                    </div>
                    <div style="width: 90%;height: 1px;background-image: linear-gradient(270deg, rgba(190, 190, 190, 0) 0%, #EBEBEB 49.13%, rgba(190, 190, 190, 0) 100%);margin: 10px; 0" />
                    <div style="height: 330px">
                        <div class="scroll">
                            <div v-for="(item, index) in disposeList" :key="index" class="item" style="display: flex; align-items: center; justify-content: space-between; margin-right: 10px">
                                <div class="list">
                                    <div class="img">
                                        <el-image style="width: 100%; height: 100%; background: #eee; display: flex !important; justify-content: center !important; align-items: center !important" :src="item.logoUrl" :fit="fit">
                                            <div slot="error" class="image-slot">
                                                <i class="el-icon-picture-outline" />
                                            </div>
                                        </el-image>
                                    </div>
                                    <div class="text" style="line-height: 40px">
                                        {{ item.cateName }}
                                    </div>
                                </div>
                                <div>
                                    <el-switch v-model="item.isSubscribe" active-color="var(--bgColor)" inactive-color="#E5E5E5" :active-value="1" :inactive-value="0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display: flex; justify-content: center; margin-top: 15px; padding-bottom: 10px">
                    <div class="foot-left" />
                    <span class="typeface" style="color: rgba(0, 0, 0, 0.35)">开启则表示接收通知</span>
                    <div class="foot-right" />
                </div>
                <span slot="reference" :class="{ animImage: flicker }" class="animImage-span">
                    <svg-icon style="width: 24px; cursor: pointer; position: relative; top: 1px" class-name="organ-icon" icon-class="xzbgHytz" @click="visible = !visible" />
                </span>
            </el-popover>
        </div>
        <el-dialog title="消息提醒" :visible.sync="messageDialog" :append-to-body="true" :close-on-click-modal="false" width="30%" center @close="getMessage">
            <div v-if="listdata.length > 0" class="content">
                {{ listdata[messageIndex].msgContent }}
            </div>
            <div v-if="listdata.length > 0" class="infos">
                <div style="float: right">
                    <div class="name">{{ listdata[messageIndex].senderName }}</div>
                    <div class="time">{{ listdata[messageIndex].createTime }}</div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <div class="footer">
                    <div class="prev" :class="messageIndex === 0 ? 'noclick' : ''" @click="changeMessage('prev')">上一条</div>
                    <div class="next" :class="messageIndex === listdata.length - 1 ? 'noclick' : ''" @click="changeMessage('next')">下一条</div>
                    <div class="num">
                        <span class="color">{{ messageIndex + 1 }}</span>
                        /{{ listdata.length }}
                    </div>
                </div>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import EmptyList from '@/wapp/common/components/Empty'

const moment = require('moment')

export default {
    name: 'Notification',
    components: { EmptyList },
    data() {
        return {
            activeName: null,
            pageSize: 5,
            listdata: [],
            listdata1: [],
            base_api: '',
            loginUser: this.$store.getters.loginUser,
            visible: false,
            refreshMessageCount: null,
            flicker: false,
            refreshMessageInterval: null,
            activeTab: 'systemMessage',
            allMessageNum: 0, // 全部信息数量
            systemMessageNum: 0, // 系统信息数量
            applyMessageNum: 0, // 应用信息数量
            dispose: 0, // 0是初始页面 1是推送配置页面
            disposeList: [],
            receiverName: '',
            fit: 'fit',
            messageDialog: false,
            messageIndex: 0,
            urlImage: './images/logo.png',
            vo: '',
            layout: 0
        }
    },
    computed: {
        ...mapGetters(['loginUser', 'routes'])
    },
    watch: {
        // visible(val) {
        //     debugger
        //     if (val) this.refreshMessage()
        // }
    },
    created() {
        this.base_api = process.env.VUE_APP_BASE_API
        this.layout = window.layoutStyle
    },
    beforeDestroy() {
        // 清除消息数量定时
        if (this.refreshMessageInterval === null) clearInterval(this.refreshMessageInterval)
        this.refreshMessageInterval = null
    },
    mounted() {
        // this.getSysTeamList()
    },
    methods: {
        // 全部已读
        allRead() {
            this.$http({
                method: 'GET',
                url: '/message/inbox/allRead'
            }).then((res) => {
                this.getMessage()
                this.$emit('flushTotal')
                this.$refs.popoverRef.doClose()
            })
        },
        // 查看所有消息
        moreMessage() {
            this.$router.push('/morePage/newsMessage')
        },
        // 打开消息推送配置
        opendispose() {
            this.dispose = 1
            this.userSubscription()
        },
        // 取消消息推送配置
        cancel() {
            this.dispose = 0
        },
        // 获取用户订阅配置
        userSubscription() {
            this.$http({
                method: 'POST',
                url: '/message/subscriber/getSubscriber',
                params: {
                    subscribeType: 'PC'
                }
            }).then((res) => {
                this.vo = JSON.parse(res.body.vo)
                this.disposeList = this.vo.subscribe
                this.disposeList.forEach((item) => {
                    if (item.logoUrl) {
                        if (item.logoUrl.includes(location.href.split('#')[0] + 'apigw') == false) {
                            item.logoUrl = location.href.split('#')[0] + 'apigw' + item.logoUrl
                        } else {
                            const url = item.logoUrl
                            item.logoUrl = url
                        }
                    } else {
                        item.logoUrl = this.urlImage
                    }
                })
            })
        },
        // 完成消息推送配置
        submit() {
            for (let i = 0; i < this.disposeList.length; i++) {
                if (this.disposeList[i].isSubscribe == 0) {
                    this.vo.allSubscribe = 0
                    break
                } else {
                    this.vo.allSubscribe = 1
                }
            }
            this.vo.subscribe = this.disposeList
            const vodata = JSON.stringify(this.vo)
            this.$http({
                method: 'PUT',
                url: '/message/subscriber/setSubscriber',
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                data: vodata,
                params: {
                    subscribeType: 'PC'
                }
            }).then((res) => {
                this.dispose = 0
                this.userSubscription()
            })
        },
        handleClick(tab, event) {},
        // async getSysTeamList() {
        //     const data = [
        //         { 'compare': '=', 'field': 'sysType', 'merge': true, 'value': 2 },
        //         { 'compare': '=', 'field': 'status', 'merge': true, 'value': 1 },
        //         { 'compare': '=', 'field': 'orgCode', 'merge': true, 'value': this.loginUser.orgCode }
        //     ]
        //     const rest = await this.$app.querydata('base/clientSystem/querydata', data, 'orderNo asc')
        //     if (rest.code === 'success') {
        //         this.syslist = rest.body.listdata
        //     } else {
        //         this.$alert(rest.message, '错误', { type: 'error' })
        //     }
        // },
        changeMessage(type) {
            if (type === 'prev' && this.messageIndex > 0) {
                // this.messageIndex--
                console.log(this.messageIndex)
                this.$http({
                    method: 'GET',
                    url: '/message/vwInbox/getById',
                    params: { id: this.listdata[this.messageIndex - 1].id }
                }).then((res) => {
                    if (res.code == 'success') {
                        this.messageIndex--
                    }
                })
            }
            if (type === 'next' && this.messageIndex < this.listdata.length - 1) {
                // this.messageIndex++
                console.log(this.messageIndex)
                this.$http({
                    method: 'GET',
                    url: '/message/vwInbox/getById',
                    params: { id: this.listdata[this.messageIndex + 1].id }
                }).then((res) => {
                    if (res.code == 'success') {
                        this.messageIndex++
                    }
                })
            }
        },
        messageClick(index, id) {
            this.$http({
                method: 'GET',
                url: '/message/vwInbox/getById',
                params: { id: id }
            }).then((res) => {
                if (res.code == 'success') {
                    console.log(res)
                    this.messageIndex = index
                    this.messageDialog = true
                    res.body.vo.isRead = 1
                }
            })
        },
        MessageTotal() {
            this.$http({
                method: 'GET',
                url: '/message/vwInbox/getToReadCount',
                params: {
                    orgCode: this.loginUser.orgCode
                }
            }).then((res) => {
                // this.allMessageNum = res.body.toReadCount
                this.systemMessageNum = res.body.toReadCount
            })
        },
        // 刷新消息数量
        async refreshMessage() {
            const params = {
                orderBy: 'createTime desc',
                urlsearch: 'pushStatus!:9;orgCode:' + this.loginUser.orgCode + ';busiCode!:null;msgContent!:null;isRead:0;receiverAccount:' + this.loginUser.personAccount,
                pageNum: 1
            }
            const res = await this.$app.listdata('/message/vwInbox/listdata', params)

            let counts = 0
            if (res.code === 'success') {
                debugger
                console.log(434, this)
                counts += res.body.pageInfo.total
                if (this.refreshMessageCount === null) {
                    this.refreshMessageCount = counts
                } else if (this.refreshMessageCount !== counts) {
                    // 图标闪烁
                    // this.flicker = true
                    this.getMessage()
                }
            } else {
                this.$message.error(res.message)
                // 清除数量刷新
                if (this.refreshMessageInterval === null) clearInterval(this.refreshMessageInterval)
                this.refreshMessageInterval = null
            }
        },
        cutTime(str) {
            let time = ''
            if (str !== '') {
                time = this.isToday(str) ? moment(str).format('MM/DD HH:mm:ss') : moment(str).format('MM/DD HH:mm:ss')
            }
            return time
        },
        isToday(str) {
            const d = new Date(str).setHours(0, 0, 0, 0)
            const today = new Date().setHours(0, 0, 0, 0)
            return d - today === 0
        },
        async showPopover() {
            debugger
            this.activeName = 'system'
            this.flicker = false
            this.dispose = 0
            await this.getMessage()
        },
        getMessage() {
            const params = {
                orderBy: 'createTime desc',
                urlsearch: 'pushStatus!:9;orgCode:' + this.loginUser.orgCode + ';busiCode!:null;msgContent!:null;isRead:0;receiverAccount:' + this.loginUser.personAccount,
                pageNum: 1
            }
            this.$app.listdata('/message/vwInbox/listdata', params).then((res) => {
                if (res.code === 'success') {
                    this.listdata = res.body.pageInfo.list
                    console.log(this.listdata)
                } else {
                    this.$message.error(res.message)
                }
                this.$emit('flushTotal')
                this.MessageTotal()
            })
        },
        // 更多
        onMoreClick() {
            const toPath = this.getMenuCode('allMessage')
            if (toPath && toPath.path) {
                let path
                switch (toPath.meta.level) {
                    case 3:
                        path = toPath.meta.activeMenu + '/' + toPath.path
                        break
                    case 4:
                        path = toPath.meta.activeMenu + '/' + toPath.path
                        break
                    default:
                        path = toPath.path
                }
                this.$refs.popoverRef.doClose()
                this.$router.push({ path: path })
            }
        },
        // 递归查询菜单代码
        getMenuCode(code) {
            let toPath
            function findCode(ary) {
                ary.forEach((val) => {
                    if (val.children) {
                        findCode(val.children)
                    }
                    if (!val.children && val.meta && val.meta.code === code) {
                        toPath = val
                        return false
                    }
                })
            }
            findCode(this.routes)
            return toPath
        }
    }
}
</script>
<style lang="scss" scoped>
@import '~@/wapp/common/styles/variables.module.scss';
.submit {
    cursor: pointer;
    width: 48px;
    height: 24px;
    background: #f9f8f8;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    font-size: 14px;
}
.message {
    width: 100%;
    // height:485px;
    padding: 10px 5px 10px 10px;
}
.item:hover {
    background-color: #f9f8f8;
}
.item {
    height: 40px;
    color: #333333;
    padding: 8px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    // margin-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
    .list {
        display: flex;
        .noread {
            margin-left: -5px;
            width: 6px;
            height: 6px;
            border-radius: 6px;
            background: #f56c6c;
            position: relative;
            left: 34px;
            top: -12px;
        }
    }
    .img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        margin-top: 4px;
    }
    .text {
        flex: 1;
        margin-left: 5px;
        line-height: 40px;
        font-size: 14px;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        position: relative;
        overflow: hidden;
        padding-left: 8px;
        height: 40px;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 10px;
        width: 225px;
    }
    .info {
        // width: 50px;
        line-height: 25px;
        font-size: 14px;
        text-align: right;
        color: #999999;
        font-size: 12px;
    }
    .name {
        font-size: 12px;
        color: #999999;
        line-height: 20px;
    }
}
.all {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50px;
    width: 100%;
    text-align: center;
    line-height: 50px;
    background: #f9f9f9;
    cursor: pointer;
    &:hover {
        color: #409eff;
    }
}
/deep/.el-tabs .el-tabs__header .el-tabs__nav .el-tabs__item.is-active {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    color: #24292b;
}
/deep/.el-tabs .el-tabs__header .el-tabs__nav .el-tabs__active-bar {
    //background: url('~public/images/w-imgs/notactive.png') no-repeat;
    background-size: 100% 100%;
    width: 56px !important;
    height: 2px;
    border-radius: 6px;
    margin-bottom: 3px;
}
/deep/.el-tabs__nav-wrap::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(270deg, rgba(190, 190, 190, 0) 0%, #ebebeb 49.13%, rgba(190, 190, 190, 0) 100%);
    z-index: 1;
}
/deep/.el-tabs__nav-scroll {
    height: 42px;
}
/deep/.el-tabs__item {
    padding: 0;
}
/deep/.el-tabs__content {
    height: 290px;
}
/deep/.el-badge__content {
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    width: 100%;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #fff;
    position: relative;
    top: -11px;
    left: -12px;
}
/deep/.el-badge__content,
.el-progress.is-exception .el-progress-bar__inner {
    background-color: #f56c6c;
}
.footer {
    height: 20px;
    line-height: 20px;
}
.prev {
    float: left;
    font-size: 14px;
    cursor: pointer;
    &.noclick {
        color: #999999;
    }
}
.next {
    float: right;
    font-size: 14px;
    cursor: pointer;
    &.noclick {
        color: #999999;
    }
}
.num {
    float: right;
    margin-right: 20px;
    .color {
        color: #409eff;
    }
}
.content {
    min-height: 200px;
}
.infos {
    height: 40px;
    line-height: 20px;
    text-align: right;
}
.animImage-span:focus {
    outline: none;
}
.animImage {
    animation-name: imageAnim;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease;
    animation-play-state: running;
}
@keyframes imageAnim {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}
.foot-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    height: 1px;
    width: 12px;
    margin-top: 8px;
    margin-right: 10px;
}
.foot-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    height: 1px;
    width: 12px;
    margin-top: 8px;
    margin-left: 10px;
}
// .list-empty{
//    margin-left: 65px;
// }
.scroll {
    overflow-y: scroll;
    height: 100%;
}
.scroll::-webkit-scrollbar {
    width: 5px;
    background-color: none;
}

.scroll::-webkit-scrollbar-track {
    background-color: none;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #eeecec;
}
</style>
<style lang="scss">
.nopad {
    border-radius: 12px;
    .el-tabs__nav-wrap::after {
        background-color: transparent;
    }
}
</style>
