<template>
  <ColumnEdit :row="row" :column="column" :index="index" :page-info="pageInfo" />
</template>

<script>
export default {
    props: {
        row: { type: Object, default: function() { return {} } },
        column: { type: Object, default: function() { return {} } },
        index: { type: Number, default: function() { return 0 } },
        pageInfo: { type: Object, default: function() { return {} } }
    },
    data() {
        return {
        }
    }
}
</script>
