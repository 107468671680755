<template>
  <div>
    <el-dialog v-if="dialogFormVisible" v-el-drag-dialog :visible.sync="dialogFormVisible" :close-on-click-modal="false" :width="'1000px'" :title="'批量导入信息'">
      <el-form :model="vo">
        <div class="el-table el-table--fit el-table--border inner-table">
          <div class="el-table__body-wrapper">
            <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
              <tbody>
                <tr>
                  <td class="el-table__cell tdlbl tdw17"><span class="required">*</span>信息文件</td>
                  <td class="el-table__cell tdcont tdw83">
                    <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="信息文件" rules="required">
                      <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                        <el-upload
                          ref="upload"
                          :on-change="handleUploadChange"
                          action=""
                          :file-list="fileList"
                          :show-file-list="false"
                          :auto-upload="false"
                          :with-credentials="true"
                          :accept="'.xls,.xlsx'"
                        >
                          <el-input v-model="salaryAttachName" name="salaryAttachName" placeholder="请选择信息文件">
                            <el-button slot="append" icon="el-icon-upload2" />
                          </el-input>
                        </el-upload>
                      </el-tooltip>
                    </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td class="el-table__cell tdlbl tdw17">注意事项</td>
                  <td class="el-table__cell tdcont tdw83">
                    <div style="line-height:25px">
                      <span><strong>上传文件内容格式说明：</strong></span><br>
                      <span>1.导入文件应为Office Excel文件;</span><br>
                      <span>2.导入文件暂不支持Office 2007及以后版本的Excel文件，如您使用的是Office 2007及以后版本，请把Excel文件转换为2003或之前的版本格式，具体转换方式为：打开的Excel文件中，选择“另存为”->“Excel 97-2003工作簿”；</span><br>
                      <span>3.进行导入操作时，请确保您导入的文件并未处于使用状态。</span><br>
                      <span>
                        <strong>上传文件内容示例：</strong>
                      </span><br>
                      <span>
                        <a href="/templates/出勤记录导入模板.xls" target="_blank" title="点击下载"><span style="color:red;width:100%;">点击下载Excel导入示例</span></a>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button v-for="(btn,key) in busiformBtns" :key="key" :disabled="doing" :plain="btn.btnType===null || btn.btnType===''" :icon="'el-icon-'+btn.btnIcon" :type="btn.btnType!==null?btn.btnType:''" @click="btn.btnClick">{{ btn.btnName }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="导入结果"
      :visible.sync="tishiShow"
      width="800px"
    >
      <div>
        <div class="title" style="margin-bottom: 15px;">{{ importMsg }}</div>
        <template>
          <el-table
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              label="序号"
              type="index"
              width="50"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="buildName"
              label="楼栋"
              width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="roomNo"
              label="宿舍"
              width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="bedNo"
              label="床位编号"
              width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="studentNumber"
              label="学号"
              width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="msg"
              label="原因"
            >
            </el-table-column>
          </el-table>
        </template>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tishiShow = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            loginUser: this.$store.getters.loginUser,
            dialogFormVisible: false,
            salaryAttachName: '',
            fileList: [],
            doing: false,
            busiformBtns: [
                {
                    id: 'btn_close',
                    btnName: '关 闭',
                    btnIcon: 'close',
                    btnClick: this.closeDialog,
                    btnPermission: '',
                    btnType: ''
                }, {
                    id: 'btn_save',
                    btnName: '导 入',
                    btnIcon: 'check',
                    btnClick: this.submitSalary,
                    btnPermission: 'saveObject',
                    btnType: 'primary'
                }
            ],
            tishiShow: false,
            tableData: [],
            importMsg: ''
        }
    },
    computed: {},
    watch: {},
    created() {
        this.$bus.on('openImportForm', this.openImportForm)
    },
    beforeDestroy() {
        this.$bus.off('openImportForm', this.openImportForm)
    },
    mounted() {},
    methods: {
        openImportForm() {
            this.dialogFormVisible = true
        },
        handleUploadChange(filed, fileList) {
            if (filed.name.indexOf('.xls') === -1 && filed.name.indexOf('.xlsx')) {
                this.$alert('上传文件格式错误', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                })
                this.fileList = []
                this.salaryAttachName = ''
                return
            }
            this.fileList = [filed]
            this.salaryAttachName = filed.name
        },
        submitSalary() {
            this.formValidate(() => {
                this.doing = true
                const file = new FormData()
                file.append('file', this.fileList[0].raw)
                this.$http({
                    url: '/bus/buildingRoomAttendance/importAttendanceScore',
                    method: 'POST',
                    // params: {
                    //     orgCode: this.loginUser.orgCode,
                    //     orgName: this.loginUser.orgName
                    // },
                    data: file
                }).then(res => {
                    if (res.code === 'success') {
                        if (res.body.importData && res.body.importData.length > 0) {
                            this.tableData = res.body.importData
                            this.importMsg = res.message
                            this.tishiShow = true
                        } else {
                            this.$message({
                                message: res.message,
                                type: 'success'
                            })
                        }
                        this.closeDialog()
                        this.$vnode.context.defaultWeekDate()
                        this.$nextTick(() => {
                            this.$bus.emit('refreshListData')
                        })
                        // location.reload()
                    } else {
                        this.$message.error(res.message)
                    }
                    this.$nextTick(() => {
                        this.doing = false
                    })
                })
            })
        },
        closeDialog() {
            this.dialogFormVisible = false
            this.salaryAttachName = ''
            this.fileList = []
        }
    }
}
</script>

<style scoped lang="scss">
.el-tooltip{
  /deep/ .el-upload.el-upload--text{
      width: 100%;
  }
}
</style>
