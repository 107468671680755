<template>
  <el-dialog v-el-drag-dialog :visible.sync="dialogShow" :title="'班次设置'" :close-on-click-modal="false" :destroy-on-close="true" class="add-bc-time ec" append-to-body width="860px">
    <div class="bc-set-box">
      <table class="el-table el-table--border" cellpadding="0" cellspacing="0">
        <tr>
          <td class="el-table__cell tdlbl tdw17">选择类型</td>
          <td class="el-table__cell tdcont tdw83 bcsd">
            <ul>
              <li>
                <el-radio v-model="workOrRest" name="workOrRest" :label="1">
                  <span> 工作日排班  <small>（必须打卡，不打卡计为异常考勤）</small></span>
                </el-radio>
              </li>
              <li>
                <el-radio v-model="workOrRest" name="workOrRest" :label="2">
                  <span> 休息日排班 <small>（打卡计为加班，不打卡不计异常考勤）</small></span>
                </el-radio>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td class="el-table__cell tdlbl tdw17">选择所属班次的时段</td>
          <td class="el-table__cell tdcont tdw83 bcsd bot-line">
            <ul>
              <li v-for="(item, $index) in timeSpanList" :key="item">
                <el-checkbox v-model="item.checked" :disabled="item.disabled" @change="sdCheckedEvent($index, $event)">
                  <span class="class-name li-in">{{ item.name }}</span>
                  <span class="time-span li-in">{{ item.logonTime }}-{{ item.logoffTime }}</span>
                </el-checkbox>
              </li>
            </ul>
          </td>
        </tr>
        <tr v-if="formType == 'bcset'">
          <td class="el-table__cell tdlbl tdw17">选择要添加时段的日期</td>
          <td class="el-table__cell tdcont tdw83">
            <template v-for="(item, inIndex) in resAry">
              <div :key="inIndex" class="rq-list-row">
                <el-checkbox v-model="item.usable" :checked="item.usable"><span>{{ item.cycleTimeShow || item.cycleTime }}</span></el-checkbox>
              </div>
            </template>
          </td>
        </tr>
      </table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button plain icon="el-icon-close" @click="handleClose()">取 消</el-button>
      <el-button type="primary" icon="el-icon-check" @click="sureSelectedBc()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { deepClone } from '@/global/utils'
import { mapGetters } from 'vuex'
export default {
    name: '',
    components: {

    },
    props: {

    },
    data() {
        return {
            dialogShow: false,
            vo: {},
            timeSpanList: null,
            workOrRest: 1,
            resAry: [],
            oldResArry: '',
            formType: ''
        }
    },
    computed: {
        ...mapGetters(['loginUser'])
    },
    watch: {

    },
    async created() {
        this.$bus.on('bcSetDialogShow', this.handleOpen)
        this.timeSpanList = await this.requestSdData()
    },
    mounted() {

    },
    beforeDestroy() {
        this.$bus.off('bcSetDialogShow', this.handleOpen)
    },
    methods: {
        /**
         * 打开弹窗
         */
        async handleOpen(data) {
            this.dialogShow = true
            this.formType = data.formType
            this.vo = deepClone(data.vo)
            this.oldResArry = data.resAry
            this.timeSpanList = await this.requestSdData()
            const resAry = JSON.parse(JSON.stringify(data.resAry))
            if (data.formType === 'bcset') {
                resAry.forEach(item => {
                    item.timeSpanIds = []
                    item.usable = false
                })
            }
            this.resAry = deepClone(resAry)
            if (this.formType === 'editBc') {
                this.edit_i = data.index
            }
        },
        /**
         * 关闭弹窗
         */
        handleClose() {
            this.vo = {}
            this.timeSpanList = null
            this.workOrRest = 1
            this.resAry = []
            this.oldResArry = ''
            this.formType = ''
            this.dialogShow = false
        },
        // 获取考勤时段
        requestSdData() {
            return new Promise((resolve, reject) => {
                this.$http({
                    method: 'POST',
                    contentType: 'application/x-www-form-urlencoded',
                    data: [
                        {
                            'compare': '=',
                            'field': 'orgCode',
                            'merge': true,
                            'value': this.loginUser.orgCode
                        }
                    ],
                    url: 'yykq/timeInterval/querydata'
                }).then((response) => {
                    if (response.code === 'success') {
                        resolve(response.body.listdata)
                    } else {
                        reject(response.$message)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        /**
        * curId 当前选中的时段id
        * bt 当前选中时段的额开始时间
        * et 当前选中时段的额结束时间
        */
        sdCheckedEvent(index, value) {
            const $scope = this
            const arr = [...$scope.timeSpanList]
            const curId = arr[index].id
            const bt = arr[index].logonTime
            const et = arr[index].logoffTime
            const ct2 = this.compareTimes(bt, et)
            if (!value) {
                const sArr = arr.filter((val) => { return val.checked })
                if (sArr.length === 0) {
                    arr.forEach((val, i) => { val.disabled = false })
                } else {
                    sArr.forEach((v1) => {
                        const bt1 = v1.logonTime
                        const et1 = v1.logoffTime
                        const ct3 = this.compareTimes(v1.logonTime, v1.logoffTime)
                        arr.forEach((v2, i) => {
                            if (!v2.checked) {
                                const ct1 = this.compareTimes(v2.logonTime, v2.logoffTime)
                                if (ct1 > ct3) { // 比较时长
                                    if (this.time_range(v2.logonTime, v2.logoffTime, bt1) || this.time_range(v2.logonTime, v2.logoffTime, et1)) { // 判断当前选中的时段 是否 与 已选中的时间段 有重合；
                                        v2.disabled = true
                                    } else {
                                        v2.disabled = false
                                    }
                                } else {
                                    if (this.time_range(bt1, et1, v2.logonTime) || this.time_range(bt1, et1, v2.logoffTime)) { // 判断当前选中的时段 是否 与 已选中的时间段 有重合；
                                        v2.disabled = true
                                    } else {
                                        v2.disabled = false
                                    }
                                }
                            }
                        })
                    })
                }
            } else {
                arr.forEach((val, i) => {
                    if (!(val.checked || curId === val.id)) {
                        const ct1 = this.compareTimes(val.logonTime, val.logoffTime)
                        if (ct1 > ct2) { // 比较时长
                            if (this.time_range(val.logonTime, val.logoffTime, bt) || this.time_range(val.logonTime, val.logoffTime, et)) { // 判断当前选中的时段 是否 与 已选中的时间段 有重合；
                                val.disabled = value
                            }
                        } else {
                            if (this.time_range(bt, et, val.logonTime) || this.time_range(bt, et, val.logoffTime)) { // 判断当前选中的时段 是否 与 已选中的时间段 有重合；
                                val.disabled = value
                            }
                        }
                    }
                })
            }
            // $scope.timeSpanList = arr
        },
        // 时段选择确认
        sureSelectedBc() {
            const $scope = this
            let isSelectTimeSpan = false
            let isSelectDate = false
            if ($scope.formType === 'bcset') {
                $scope.resAry.forEach((v, i) => {
                    if (v.usable) {
                        isSelectDate = true
                        v.timeSpanIds = []
                        $scope.timeSpanList.forEach((v2, i2) => {
                            if (v2.checked) {
                                var lms = this.compareTimes(v2.logonTime, v2.logoffTime)
                                v2.len = lms / (60 * 60 * 1000)
                                var ary = v2.logonTime.split(':')
                                v2.posX = Number(parseInt(ary[0]) + '.' + parseInt(ary[1] * 1 / 6 * 100))
                                v2.workOrRest = $scope.workOrRest
                                v.timeSpanIds.push(v2)
                            }
                        })
                        v.workOrRest = $scope.workOrRest
                    }
                })
                $scope.vo.timeSpan = ''
                $scope.timeSpanList.forEach((v, i) => {
                    if (v.checked) {
                        isSelectTimeSpan = true
                        if (!$scope.vo.timeSpan) {
                            $scope.vo.timeSpan = v.logonTime + '-' + v.logoffTime
                        } else {
                            $scope.vo.timeSpan += ' , ' + v.logonTime + '-' + v.logoffTime
                        }
                    }
                })
            } else if ($scope.formType === 'editBc') {
                const v = $scope.resAry[$scope.edit_i]
                v.timeSpanIds = []
                $scope.timeSpanList.forEach((iv, i2) => {
                    const v2 = JSON.parse(JSON.stringify(iv))
                    if (v2.checked) {
                        isSelectTimeSpan = true
                        const lms = this.compareTimes(v2.logonTime, v2.logoffTime)
                        v2.len = lms / (60 * 60 * 1000)
                        const ary = v2.logonTime.split(':')
                        v2.posX = Number(parseInt(ary[0]) + '.' + parseInt(ary[1] * 1 / 6 * 100))
                        v2.workOrRest = $scope.workOrRest
                        v.timeSpanIds.push(v2)
                    }
                })
                v.usable = true
            }
            if ($scope.formType === 'editBc' && !isSelectTimeSpan) {
                return this.$alert('请选择班次的时段', '提示')
            }
            if ($scope.formType === 'bcset' && (!isSelectTimeSpan || !isSelectDate)) {
                return this.$alert('请选择班次的 时段或日期', '提示')
            }
            if ($scope.formType === 'bcset') {
                const oldSelectTime = $scope.oldResArry.filter(l => l.usable === true)
                const selectTime = $scope.resAry.filter(x => x.usable === true)
                let count = 0

                if (oldSelectTime.length > 0) {
                    for (let a = 0; a < oldSelectTime.length; a++) {
                        for (let b = 0; b < selectTime.length; b++) {
                            if (oldSelectTime[a].cycleTime === selectTime[b].cycleTime && oldSelectTime[a].usable === selectTime[b].usable) {
                                count++
                            }
                        }
                    }
                    if (count > 0) {
                        this.$confirm('当前所选时段日期与已排班的时段日期有冲突，是否更新为当前所选时段日期！', '提示', {
                            confirmButtonText: '是',
                            cancelButtonText: '否',
                            type: 'warning'
                        }).then(() => {
                            $scope.oldResArry.forEach(old => {
                                $scope.resAry.forEach(news => {
                                    if (old.cycleTime === news.cycleTime) {
                                        if (old.usable && !news.usable) {
                                            news.usable = true
                                            news.timeSpanIds = old.timeSpanIds
                                        }
                                    }
                                })
                            })
                            this.$emit('sureBcSet', { resAry: this.resAry })
                            this.handleClose()
                        }).catch(() => {
                            $scope.oldResArry.forEach(old => {
                                $scope.resAry.forEach(news => {
                                    if (old.cycleTime === news.cycleTime) {
                                        if ((old.usable && !news.usable) || (old.usable && news.usable)) {
                                            news.usable = true
                                            news.timeSpanIds = old.timeSpanIds
                                        }
                                    }
                                })
                            })
                            this.$emit('sureBcSet', { resAry: this.resAry })
                            this.handleClose()
                        })
                    } else {
                        $scope.oldResArry.forEach(old => {
                            $scope.resAry.forEach(news => {
                                if (old.cycleTime === news.cycleTime) {
                                    if (old.usable && !news.usable) {
                                        news.usable = true
                                        news.timeSpanIds = old.timeSpanIds
                                    }
                                }
                            })
                        })
                        this.$emit('sureBcSet', { resAry: this.resAry })
                        this.handleClose()
                    }
                } else {
                    this.$emit('sureBcSet', { resAry: this.resAry })
                    this.handleClose()
                }
            } else {
                this.$emit('sureBcSet', { resAry: this.resAry })
                this.handleClose()
            }
        },
        // 清空时段
        async clearSd() {
            this.timeSpanList = await this.requestSdData()
        },
        // 获取两个时间之间差值
        compareTimes(bt, et) {
            const strb = bt.split(':')
            if (strb.length !== 2) return 0
            const stre = et.split(':')
            if (stre.length !== 2) return 0

            const b = new Date()
            const e = new Date()

            b.setHours(strb[0])
            b.setMinutes(strb[1])
            e.setHours(stre[0])
            e.setMinutes(stre[1])
            return e.getTime() - b.getTime()
        },
        // 判断所选时间是否在时间内(HH:MM)
        time_range(beginTime, endTime, nowTime) {
            const strb = beginTime.split(':')
            if (strb.length !== 2) return false

            const stre = endTime.split(':')
            if (stre.length !== 2) return false

            const strn = nowTime.split(':')
            if (stre.length !== 2) return false

            const b = new Date()
            const e = new Date()
            const n = new Date()

            b.setHours(strb[0])
            b.setMinutes(strb[1])
            e.setHours(stre[0])
            e.setMinutes(stre[1])
            n.setHours(strn[0])
            n.setMinutes(strn[1])
            if ((n.getTime() - b.getTime() >= 0) && (n.getTime() - e.getTime() <= 0)) {
                return true
            } else {
                console.warn('当前时间是：' + (n.getHours() > 9 ? n.getHours() : '0' + n.getHours()) + ':' + (n.getMinutes() > 9 ? n.getMinutes() : '0' + n.getMinutes()) + '，不在该时间范围内！')
                return false
            }
        }

    }
}
</script>

<style scoped lang="scss">
.bc-set-box {
  td {
    padding-left: 8px;
    padding-right: 8px;
  }
  .tdw17 {
    width: 19%;
    text-align: right;
    color: #303133;
  }
  .tdw83 {
    width: 81%;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  .bcsd {
    li {
      height: 36px;
      line-height: 24px;
      padding: 6px 20px;
      overflow: hidden;
    }
    &.bot-line{
      padding: 0;
      li {
        height: 44px;
        padding: 10px 20px;
        border-bottom: 1px solid #F2F2F2;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .class-name {display: inline-block; width: 200px;}
	.time-span {display: inline-block; width: 180px;}
  .rq-list-row {height: 32px; line-height: 24px; padding: 4px; width: 92px; float: left;}
}
</style>
