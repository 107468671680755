<template>
    <div id="wapp" :class="listDataBorder ? '' : 'list-border-no'">
        <router-view />
        <Watermark />
        <DefaultDialog />
    </div>
</template>

<script>
import Watermark from './common/components/Watermark'
import DefaultDialog from '@/wapp/common/components/DefaultDialog/index'
import { listDataBorder } from './settings'
export default {
    name: 'Wapp',
    components: {
        Watermark,
        DefaultDialog
    },
    data() {
        return {
            listDataBorder: listDataBorder
        }
    },
    created() {
        // 追悼样式
        //     this.$http({
        //         method: 'GET',
        //         url: '/base/styleConfig/ifOpenMourning'
        //     }).then(res => {
        //         if (res.code === 'success') {
        //             if (res.body.rest) {
        //                 const html = document.getElementsByTagName('html')
        //                 html[0].style.filter = 'gray'
        //                 html[0].style['-webkit-filter'] = 'grayscale(100%)'
        //                 console.log(html, 'html')
        //             }
        //         } else {
        //             this.$message.error(res.message)
        //         }
        //     })
    },
    mounted() {
        window.$wapp = this
    }
}
</script>
<style>
:root {
    --personh: 106px;
    --sidebar-width: auto;
}
.demonstration {
    font-size: 14px;
}
.el-dialog__title {
    padding-left: 40px;
}
.el-dialog__title .el-button {
    display: none;
}
.el-dialog .el-dialog__header {
    background-color: #f2f5fb !important;
}
.el-dialog__wrapper .el-dialog__title::before {
    content: '';
    width: 30px;
    display: inline-block;
    height: 30px;
    background: url('~public/images/systemLogoAll.png') no-repeat;
    background-size: cover;
    position: absolute;
    top: 12.5px;
    left: 20px;
}
.el-dialog__wrapper.select-ztree-dialog .el-dialog__title::before {
    content: '';
    width: 30px;
    display: inline-block;
    height: 30px;
    background: url('~public/images/systemLogoSelect.png') no-repeat;
    background-size: cover;
    position: absolute;
    top: 12.5px;
    left: 20px;
}
</style>
