<template>
  <div id="LayoutRoomConfig">
    <div
      class="RoomContent"
      :style="{ 'grid-template-columns': `repeat(${selected.w}, 1fr)`}"
    >
      <template v-for="block in blocks">
        <div
          :key="block"
          class="RoomContent_item"
          :class="{
            'RoomContent_item--disabled': !disabledBlock(block),
            'RoomContent_item--selected': selectedBlock(block),
            'RoomContent_item--selected_an': isSelectedAn(block)
          }"
          @click="handleClickDoor(block)"
        >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { Elements } from '../AreaConfig'
const door = Elements.find(e => e.name === 'door')
/**
 *  随机key
 */
const uniqueKey = () => {
    const key = Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999)
    return key
}
const deepClone = obj => JSON.parse(JSON.stringify(obj))
const isEmptyObject = obj => JSON.stringify(obj) === '{}'
export default {
    props: {
        selected: { type: Object, default: () => {} }
    },
    data() {
        return {
            cacheData: {}
        }
    },
    computed: {
        blocks() {
            const result = []
            for (let y = 1; y <= this.selected.h; y++) {
                for (let x = 1; x <= this.selected.w; x++) {
                    result.push({ x, y })
                }
            }
            return result
        },
        // 门可选方向
        doorDirection() {
            // 父容器的列宽 w 为x轴最大坐标 h为 y轴最大坐标
            const xDirection = { 1: 'L' }
            const yDirection = { 1: 'T' }
            xDirection[this.selected.w] = 'R'
            yDirection[this.selected.h] = 'B'
            return { xDirection, yDirection }
        }
    },
    watch: {
        selected: {
            deep: true,
            handler(val) {
                this.$emit('update:selected', this.selected)
            }
        }
    },
    methods: {
        // 禁止设置门的块
        disabledBlock(block) {
            const clickableX = [1, this.selected.w]
            const clickableY = [1, this.selected.h]
            return clickableX.includes(block.x) || clickableY.includes(block.y)
        },
        // 已选中门的块
        selectedBlock(block) {
            return this.selected.layout.some(item => item.x === block.x && item.y === block.y)
        },
        // 是否选中的第二方向的门
        isSelectedAn(block) {
            const selectedLayout = this.selected.layout.find(item => item.x === block.x && item.y === block.y)
            if (selectedLayout && selectedLayout.config.direction.indexOf(selectedLayout.config.currDirection) === 1) {
                return true
            }
            return false
        },
        // 处理点击block
        handleClickDoor(block) {
            const selectedLayout = this.selected.layout.find(item => item.x === block.x && item.y === block.y)
            const deleteIndex = this.selected.layout.findIndex(item => item.x === block.x && item.y === block.y)
            if (deleteIndex === -1) {
                const doorConfig = deepClone(door)
                doorConfig.areaId = uniqueKey()
                doorConfig.areaNumber = 'door_' + this.selected.layout.length + 1
                // 通过block的x，y获取能设置的门的方向
                const { xDirection, yDirection } = this.doorDirection
                xDirection[block.x] && doorConfig.config.direction.push(xDirection[block.x])
                yDirection[block.y] && doorConfig.config.direction.push(yDirection[block.y])
                // 并将数组第一个作为门的默认方向
                doorConfig.config.currDirection = doorConfig.config.direction[0]
                this.selected.layout.push({
                    x: block.x,
                    y: block.y,
                    w: 1,
                    h: 1,
                    i: this.selected.layout.length + 1,
                    ...doorConfig
                })
            } else if ( // 当前点击门可选方向，同时只选中了第一个方向，第二次点击换门的方向
                selectedLayout &&
                selectedLayout.config.direction.length === 2 &&
                selectedLayout.config.direction.indexOf(selectedLayout.config.currDirection) === 0
            ) {
                this.$set(selectedLayout.config, 'currDirection', selectedLayout.config.direction[1])
            } else {
                this.selected.layout.splice(deleteIndex, 1)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#LayoutRoomConfig {
    .RoomContent{
        display: grid;
    }
    .RoomContent_item {
        height: 30px;
        flex-grow: 0;
        outline: 1px #e3e3e3 solid;
        cursor: pointer;
    }
    .RoomContent_item:hover {
        background: rgba(61, 146, 224, 0.7);
    }
    .RoomContent_item--disabled{
        pointer-events: none;
        background: #e6e6e6;
        cursor: unset;
    }
    .RoomContent_item--disabled:hover{
        background: #e6e6e6;
    }
    .RoomContent_item--selected{
        background: rgba(61, 146, 224, 1);
    }
    .RoomContent_item--selected:hover{
        background: rgba(61, 146, 224, 1);
    }
    .RoomContent_item--selected_an{
        background: rgba(248, 136, 37, 1);
    }
    .RoomContent_item--selected_an:hover{
        background: rgba(248, 136, 37, 1);
    }
}
</style>
