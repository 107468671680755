<template>
  <div>
    <el-dialog v-if="dialogFormVisible" v-el-drag-dialog :visible.sync="dialogFormVisible" :close-on-click-modal="false" :width="'1000px'" :title="'批量导入周表明细'">
      <el-dialog
        v-el-drag-dialog
        :close-on-click-modal="false"
        title="导入失败"
        :visible.sync="dialogVisible"
        width="30%"
        append-to-body
      >
        <div style="font-size:14px;margin:20px 10px"><span style="color:red;font-size:16px;font-weight:600">{{ failNum }}</span>条数据失败，请检查数据格式。</div>
        <div style="color:#409eff;font-size:14px;margin:20px 10px 50px">导入失败序号：{{ failText }}</div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cliseDia">关 闭</el-button>
        </span>
      </el-dialog>
      <el-form :model="vo">
        <div class="el-table el-table--fit el-table--border inner-table">
          <div class="el-table__body-wrapper">
            <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
              <tbody>
                <tr>
                  <td class="el-table__cell tdlbl tdw17"><span class="required">*</span>周表明细文件</td>
                  <td class="el-table__cell tdcont tdw83">
                    <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="周表明细文件" rules="required">
                      <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                        <el-upload
                          ref="upload"
                          :on-change="handleUploadChange"
                          action=""
                          :file-list="fileList"
                          :show-file-list="false"
                          :auto-upload="false"
                          :with-credentials="true"
                          :accept="'.xls,.xlsx'"
                        >
                          <el-input v-model="salaryAttachName" name="salaryAttachName" placeholder="请选择周表明细文件">
                            <el-button slot="append" icon="el-icon-upload2" />
                          </el-input>
                        </el-upload>
                      </el-tooltip>
                    </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td class="el-table__cell tdlbl tdw17">注意事项</td>
                  <td class="el-table__cell tdcontle__cell tdcont tdw83">
                    <div style="line-height:25px">
                      <span><strong>上传文件内容格式说明：</strong></span><br>
                      <span>1.导入文件应为Office Excel文件;</span><br>
                      <span>2.导入文件暂不支持Office 2007及以后版本的Excel文件，如您使用的是Office 2007及以后版本，请把Excel文件转换为2003或之前的版本格式，具体转换方式为：打开的Excel文件中，选择“另存为”->“Excel 97-2003工作簿”；</span><br>
                      <span>3.进行导入操作时，请确保您导入的文件并未处于使用状态。</span><br>
                      <span>
                        <strong>上传文件内容示例：</strong>
                      </span><br>
                      <span>
                        <a href="/templates/zbhzimport.xls" target="_blank" title="点击下载"><span style="color:red;width:100%;">点击下载Excel导入示例</span></a>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button v-for="(btn,key) in busiformBtns" :key="key" :disabled="doing" :plain="btn.btnType===null || btn.btnType===''" :icon="'el-icon-'+btn.btnIcon" :type="btn.btnType!==null?btn.btnType:''" @click="btn.btnClick">{{ btn.btnName }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    components: {},
    props: {
        id: { type: String, default: '' }
    },
    data() {
        return {
            dialogFormVisible: false,
            salaryAttachName: '',
            fileList: [],
            dialogVisible: false,
            isDisabled: false,
            doing: false,
            busiformBtns: [
                {
                    id: 'btn_close',
                    btnName: '关 闭',
                    btnIcon: 'close',
                    btnClick: this.closeDialog,
                    btnPermission: '',
                    btnType: ''
                }, {
                    id: 'btn_save',
                    btnName: '导 入',
                    btnIcon: 'check',
                    btnClick: this.submitSalary,
                    btnPermission: 'saveObject',
                    btnType: 'primary'
                }
            ],
            successNum: '',
            failNum: '',
            failList: [],
            failText: '',
            second: false
        }
    },
    computed: {},
    watch: {},
    created() {
        this.$bus.on('openImportForm', this.openImportForm)
        console.log(this.vo, 'desd')
    },
    beforeDestroy() {
        this.$bus.off('openImportForm', this.openImportForm)
        this.dialogVisible = false
        this.dialogFormVisible = false
    },
    mounted() {},
    methods: {
        openImportForm() {
            console.log(11111111)
            this.dialogFormVisible = true
        },
        handleUploadChange(filed, fileList) {
            if (filed.name.indexOf('.xls') === -1 && filed.name.indexOf('.xlsx')) {
                this.$alert('上传文件格式错误', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                })
                this.fileList = []
                this.salaryAttachName = ''
                return
            }
            this.fileList = [filed]
            this.salaryAttachName = filed.name
        },
        // 导入
        submitSalary() {
            const id = this.id
            console.log(id, 'sdad')
            this.formValidate(() => {
                this.isDisabled = true
                this.doing = true
                const file = new FormData()
                file.append('file', this.fileList[0].raw)
                this.$http({
                    url: '/zhsp/sxdj/import',
                    method: 'POST',
                    data: file,
                    params: { 'busidataId': id }

                }).then(res => {
                    this.isDisabled = false
                    if (res.code === 'success') {
                        if (res.body.data && (res.body.data.length > 0)) {
                        // 导入失败
                            this.failNum = res.body.data.length
                            this.dialogVisible = true
                            this.failText = res.body.data.join('、')
                            this.doing = false
                        } else {
                            // 导入成功
                            console.log(res, 123)
                            this.$message.success(res.message)
                            this.dialogVisible = false
                            this.dialogFormVisible = false
                            this.doing = false
                            this.salaryAttachName = ''
                            this.fileList = []
                        }

                        this.$nextTick(() => {
                            this.$bus.emit('refreshListData')
                        })
                    } else {
                        this.$message.error(res.message)
                    }
                    this.$nextTick(() => {
                        this.doing = false
                    })
                })
            })
        },
        // 完成
        cliseDia() {
            this.dialogVisible = false
            this.salaryAttachName = ''
            this.fileList = []
        },
        closeDialog() {
            this.dialogVisible = false
            this.dialogFormVisible = false
            this.salaryAttachName = ''
            this.fileList = []
        }
    }
}
</script>

<style scoped lang="scss">
.el-tooltip{
  /deep/ .el-upload.el-upload--text{
      width: 100%;
  }
}

.wflow-container .el-form .el-table.el-table--border td {
    border-bottom-color: #d8d8d8 !important;
}
</style>
