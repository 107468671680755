import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '../layout'
import Blank from '../layout/components/Blank'

// 获取原型对象push函数
const originalPush = Router.prototype.push
// 获取原型对象replace函数
const originalReplace = Router.prototype.replace
// 修改原型对象中的push函数
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
// 修改原型对象中的replace函数
Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
    {
        path: '/',
        component: Layout,
        redirect: '/home',
        children: [
            {
                path: 'home',
                component: resolve => require(['../../home'], resolve),
                meta: { title: 'home', icon: 'home' }
            }
        ]
    },
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path*',
                component: resolve => require(['../redirect'], resolve)
            }
        ]
    },
    {
        path: '/globalsearch',
        component: resolve => require(['../layout/components/Topbar/globalsearch'], resolve),
        hidden: true
    },
    {
        path: '/login',
        component: resolve => require(['../../login'], resolve),
        hidden: true
    },
    {
        path: '/cmccLogin',
        component: resolve => require(['../../login/cmccLogin'], resolve),
        hidden: true
    },
    {
        path: '/ddLogin',
        component: resolve => require(['../../login/ddLogin'], resolve),
        hidden: true
    },
    {
        path: '/modifyInitPassword',
        component: resolve => require(['@/wapp/usersetting/personCenter/modifyPassword'], resolve),
        hidden: true
    },
    // {
    //     path: '/portal',
    //     component: resolve => require(['../../portal'], resolve),
    //     hidden: true
    // },
    {
        path: '/retrieve',
        component: resolve => require(['../../login/retrieve'], resolve),
        hidden: true
    },
    {
        path: '/wflow',
        component: resolve => require(['@/wapp/workflow/wfInstance/mainform'], resolve),
        hidden: true
    },

    {
        path: '/newBusiForm', // 非流程表单新开页
        // component: resolve => require(['@/wapp/workflow/busiFormForNewWindow/newBusiForm'], resolve),
        component: (resolve) => resolve(Vue.options.components['BasicformOpenPage']),
        hidden: true
    },
    {
        path: '/print',
        component: resolve => require(['@/wapp/workflow/wfInstance/mainform/print'], resolve),
        hidden: true
    },
    {
        path: '/imgView',
        component: resolve => require(['@/wapp/common/components/ImgView'], resolve),
        hidden: true
    },
    {
        path: '/excelView',
        component: resolve => require(['@/wapp/common/components/ExcelView/excelView'], resolve),
        hidden: true
    },
    // {
    //     path: '/kflt',
    //     component: resolve => require(['@/wapp/gdgl/components/kflt/index'], resolve),
    //     hidden: true
    // },
    // {
    //     path: '/xmpp',
    //     component: resolve => require(['@/wapp/xmpp/index'], resolve),
    //     hidden: true
    // },
    {
        path: '/viewcontent',
        component: resolve => require(['@/wapp/workflow/wfInstance/viewContent'], resolve),
        hidden: true
    },
    {
        path: '/viewpdffile',
        // component: resolve => require(['@/wapp/workflow/webOffice/ViewPdffile'], resolve),
        component: resolve => resolve(Vue.options.components['viewpdffile']),
        hidden: true
    },
    {
        path: '/commonHeader',
        component: resolve => require(['@/wapp/common/components/commHeader/commonHeader'], resolve),
        hidden: true
    },
    // {
    //     path: '/xmglProjectDetailMen',
    //     component: resolve => require(['@/wapp/xmgl/manage/components/ProjectDetail/index'], resolve),
    //     hidden: true
    // },
    // {
    //     path: '/centerForm',
    //     component: resolve => require(['@/wapp/xxfb/help/components/ArticleForm'], resolve),
    //     hidden: true
    // },
    {
        path: '/helpCenter',
        component: resolve => require(['@/wapp/helpCenter/index'], resolve),
        hidden: true
    },
    // {
    //     path: '/moduleEditorIndex',
    //     component: resolve => require(['@/wapp/htgl/mbgl/components/moduleIndex'], resolve),
    //     hidden: true
    // },
    // {
    //     path: '/designModule',
    //     component: resolve => require(['@/wapp/xmgl/utils/taskTemplate/components/moduleIndex'], resolve),
    //     hidden: true
    // },
    {
        path: '/designer',
        component: Blank,
        hidden: true,
        children: [
            {
                path: 'workflow',
                component: resolve => require(['../../workflow/definition/wfDesigner'], resolve)
            },
            {
                path: 'wfTester',
                component: resolve => require(['../../workflow/definition/wfTester'], resolve)
            },
            {
                path: 'busiform',
                component: resolve => require(['../../base/codeGenerate/components/BusiformDesigner'], resolve)
            },
            {
                path: 'listpage',
                component: resolve => require(['../../base/codeGenerate/components/ListpageDesigner'], resolve)
            },
            {
                path: 'portalpage',
                component: resolve => require(['../../base/codeGenerate/components/PortalpageDesigner'], resolve)
            },
            {
                path: 'stamp',
                component: resolve => resolve(Vue.options.components['SignfileStamp'])
            },
            {
                path: 'voice',
                component: resolve => require(['../../base/voice/VoiceDesigner'], resolve)
            },
            {
                path: 'udmGenerator',
                component: resolve => require(['../../base/codeGenerate/components/UdmGenerator'], resolve)
            },
            {
                path: 'systemSetting',
                component: resolve => require(['../../base/SystemSetting/index'], resolve)
            },
            {
                path: 'conversation',
                component: resolve => require(['../../base/conversation/SimulateTest'], resolve)
            }
            // {
            //     path: 'report',
            //     component: resolve => require(['../../base/codeGenerate/components/ReportDesigner'], resolve)
            // }
        ]
    },

    {
        path: '/listInfo',
        component: resolve => require(['../components/ListInfo/index'], resolve),
        hidden: true
    },
    {
        path: '/auth-redirect',
        component: resolve => require(['../../login/auth-redirect'], resolve),
        hidden: true
    },
    {
        path: '/404',
        component: resolve => require(['../error/404'], resolve),
        hidden: true
    },
    {
        path: '/401',
        component: resolve => require(['../error/401'], resolve),
        hidden: true
    },
    {
        path: '/500',
        component: resolve => require(['../error/500'], resolve),
        hidden: true
    },
    {
        path: '/ssLoginError',
        component: resolve => require(['../error/ssLoginError'], resolve),
        hidden: true
    },
    //运营驾驶舱代码已删除，如项目上需使用可从yoa9.0代码上找 cockpit1 文件夹，
    // {
    //     path: '/yygljsc',
    //     component: resolve => require(['@/wapp/base/cockpit1/index'], resolve),
    //     hidden: true
    // },
    {
        path: '/OCR',
        component: resolve => require(['@/wapp/base/TextRecognition'], resolve),
        hidden: true
    },
    {
        path: '/IR',
        component: resolve => require(['@/wapp/base/InvoiceRecognition'], resolve),
        hidden: true
    },
    {
        path: '/invoice/register',
        component: resolve => require(['@/wapp/base/InvoiceRecognition/register'], resolve),
        hidden: true
    },
    // 默认路由
    {
        path: '/defaultRoute',
        component: resolve => require(['@/wapp/common/components/DefaultDialog/content'], resolve),
        hidden: true
    }
]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
