<template>
  <div class="LayoutHiddenHandler">
    <div v-if="isHidden('T')" class="Hidden_line top"></div>
    <div v-if="isHidden('R')" class="Hidden_line right"></div>
    <div v-if="isHidden('B')" class="Hidden_line bottom"></div>
    <div v-if="isHidden('L')" class="Hidden_line left"></div>
  </div>
</template>

<script>
export default {
    props: {
        layoutItem: { type: Object, require: true, default: () => {} }
    },
    methods: {
        isHidden(direction) {
            if (this.layoutItem && this.layoutItem.config && this.layoutItem.config.hidden.length) {
                if (this.layoutItem.config.hidden.includes(direction)) {
                    return true
                }
            }
            return false
        }
    }
}
</script>

<style lang="scss" scoped>
.LayoutHiddenHandler{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
    .Hidden_line{
        position: absolute;
        z-index: 10;
        background: #ffffff;
    }
    .top {
        top: -1px;
        left: 0px;
        width: 100%;
        height: 1px;
    }
    .right {
        right: -1px;
        top: 0px;
        width: 1px;
        height: 100%
    }
    .left {
        left: -1px;
        top: 0px;
        width: 1px;
        height: 100%
    }
    .bottom {
        bottom: -1px;
        left: 0px;
        width: 100%;
        height: 1px;
    }
}
</style>
