<template>
    <div class="head_topmenu" :style="styleLayout === 1 ? 'position: absolute;width: 63px;top:0px;z-index:999;background: var(--leftToolbarBg);height:calc(100% - 60px)' : 'position:relative;width:100%'">
        <el-menu :default-active="activeIndex" :mode="styleLayout === 1 ? '' : 'horizontal'" class="top-el-menu" text-color="var(--leftToolbarText)" active-text-color="var(--leftToolbarTextActive)" @select="handleSelect">
            <template v-for="(item, index) in topMenus">
                <!-- 首页 -->
                <el-menu-item v-show="portalSubMenu.length > 1" v-if="index === 0 && portalSubMenu.length > 0" :key="index" :index="index + ''" :popper-append-to-body="false" class="el-submenu-top topMenu-show" @click="clickItem(item)">
                    <div v-if="styleLayout === 1" :class="'menuDiv_' + item.name" style="height: 100%" @mouseenter="menuEnter(item, index)">
                        <svg-icon v-if="loginUser.orgCode !== 'plat'" :style="styleLayout === 1 ? 'font-size: 20px;position: absolute;left: 50%;transform: translateX(-50%);top: 20px;' : ''" :icon-class="activeName === item.alias ? 'menhu' : 'menhu_a'"></svg-icon>
                        <span>门户</span>
                    </div>
                    <span v-else>门户</span>
                </el-menu-item>
                <el-menu-item v-if="index === 0 && portalSubMenu.length === 1" :key="index" style="letter-spacing: 0px" :index="0 + ''" @mouseover.native="switchAppsPopover(portalSubMenu[0])" @mouseout.native="clearPopTimer" @click="clickItem(portalSubMenu[0])">
                    <div v-if="styleLayout === 1" :class="'menuDiv_' + portalSubMenu[0].name" style="height: 100%" @mouseenter="menuEnter(portalSubMenu[0], index)">
                        <svg-icon v-if="loginUser.orgCode !== 'plat'" :style="styleLayout === 1 ? 'font-size: 20px;position: absolute;left: 50%;transform: translateX(-50%);top: 20px;' : ''" :icon-class="activeName === '首页' ? 'menhu' : 'menhu_a'"></svg-icon>
                        <span>门户</span>
                    </div>
                    <span v-else>{{ portalSubMenu[0].alias }}</span>
                </el-menu-item>
                <!-- 更多 -->
                <el-submenu v-if="index === topMenus.length - 2" v-show="dropDownmenu.length > 0" :key="index" :index="index + ''" :popper-append-to-body="false" class="el-submenu-top el-menu-item top_sub_menu">
                    <template slot="title">{{ locale === 'zhCN' ? '更多' : 'More' }}</template>
                    <template v-for="drop in dropDownmenu">
                        <el-menu-item :key="drop.item.path" :index="drop.index + ''" @mouseover.native="switchAppsPopover(drop.item, drop.index)" @mouseout.native="clearPopTimer">{{ drop.item.alias }}</el-menu-item>
                    </template>
                </el-submenu>
                <el-menu-item v-if="item.meta.topFlag == 1 && (index !== 0 || portalSubMenu.length === 0)" :key="item.path" :class="{ 'topmenu-allapps': item.meta.code == 'apps' }" class="el-submenu-top topMenu-show" :index="index + ''" @click="clickItem(item)" @mouseover.native="switchAppsPopover(item, index)" @mouseout.native="clearPopTimer">
                    <div v-if="styleLayout === 1" :class="'menuDiv_' + item.name" style="height: 100%" @mouseenter="menuEnter(item, index)">
                        <svg-icon v-if="loginUser.orgCode !== 'plat'" :style="styleLayout === 1 ? 'font-size: 20px;position: absolute;left: 50%;transform: translateX(-50%);top: 20px;' : ''" :icon-class="activeName === item.alias ? item.meta.icon : item.meta.icon + '_a'"></svg-icon>
                        <span :class="{ 'topmenu-item-hover': popShow && item.meta.code == 'apps' }">{{ item.meta.description ? item.meta.description : item.alias === '应用中心' ? '应用' : item.alias }}</span>
                    </div>
                    <span v-else :class="{ 'topmenu-item-hover': popShow && item.meta.code == 'apps' }">{{ item.alias || item.meta ? item.meta.title : '' }}</span>
                </el-menu-item>
            </template>
        </el-menu>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import $ from 'jquery'
if (!window.jQuery) {
    window.jQuery = $
}
export default {
    components: {},
    props: {
        styleLayout: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            activeIndex: '0',
            popShow: false,
            popTimer: -1,
            menuName: '',
            loginUser: this.$store.getters.loginUser,
            appCenterStyle: 'app',
            menuIcon: '',
            menuIndex: 0, // 全部应用菜单打开之前的菜单index
            appsIndex: 0, // 记录apps菜单index
            dropDownmenu: [],
            locale: 'zhCN',
            portalTitle: '首页',
            portalSubMenu: [],
            resizeTopmenu: false,
            allAppsIndex: '0',
            isNbyx: false,
            activeName: '首页',
            hoverTimer: '',
            menuDiv: '',
            hoverTitle: '',
            mouseHoverTime: '',
            leftChooseId: ''
        }
    },
    computed: {
        ...mapGetters(['routes']),
        topMenus() {
            const topMenus = this.routes.filter((item) => {
                if (item.meta && item.meta.level === 1) {
                    return true
                }
            })
            if (topMenus.length === 1) {
                topMenus[0].meta.topFlag = 0
                this.$bus.emit('showPerson', false)
            } else {
                const topFlagNum = topMenus.filter((menu) => menu.meta.topFlag === 1).length

                if (topFlagNum === 0 && topMenus.length > 0) {
                    topMenus[0].meta.topFlag = 1
                }
            }
            return topMenus
        }
    },
    watch: {
        portalTitle() {
            this.$nextTick(() => {
                setTimeout(() => {
                    this.onresize()
                }, 1000)
            })
        }
    },
    created() {
        this.setPortalSubMenu()
        this.locale = this.$i18n.locale
        this.$bus.on('updateTopMenuIndex', this.updateTopMenuIndex)
        this.$bus.on('closePop', this.closePop)
        this.$bus.on('bodyOnClick', this.handleBodyClick)
        this.$bus.on('activeMenu', this.activeMenu)
        const routeLength = this.routes.filter((item) => item.meta && item.meta.level === 1)
        routeLength.forEach((item, index) => {
            if (item.alias && item.alias === '应用中心') {
                this.allAppsIndex = index + ''
            } else if (item.children && item.children.length > 0 && item.children[0].alias === '应用中心') {
                this.allAppsIndex = index + ''
            }
        })
        this.$bus.on('updateleftMenuIndex', this.updateLeftMenuIndex)
    },
    beforeDestroy() {
        this.$bus.off('updateTopMenuIndex', this.updateTopMenuIndex)
        this.$bus.off('closePop', this.closePop)
        this.$bus.off('bodyOnClick', this.handleBodyClick)
        this.$bus.off('activeMenu', this.activeMenu)
        this.$bus.off('updateleftMenuIndex', this.updateLeftMenuIndex)
        window.removeEventListener('resize', this.onresize, false)
        if (this.styleLayout === 1) {
            document.removeEventListener('mousemove', this.leave)
        }
    },
    mounted() {
        if (this.styleLayout === 1) {
            document.addEventListener('mousemove', this.leave)
        }
        this.$bus.on('closeDilog', () => {
            this.popShow = false
        })
        this.appCenterStyle = window.appCenterStyle
        const topMenuIndex = this.Cookies.get('topMenuIndex')
        this.activeIndex = topMenuIndex === null || topMenuIndex === undefined || topMenuIndex === '' ? (this.portalSubMenu.length > 0 ? '0-0' : '0') : topMenuIndex

        for (let i = 0; i < this.topMenus.length; i++) {
            if (this.topMenus[i].meta.icon === 'apps') {
                this.appsIndex = i
                break
            }
            this.topMenus[i].alias = this.$i18n.generateTitle(this.topMenus[i].meta, this)
        }
        let unTopMenuCount = 0
        this.topMenus.forEach((item) => {
            if (item.meta.topFlag === 0) {
                unTopMenuCount++
            }
        })
        if (unTopMenuCount) {
            this.appsIndex = unTopMenuCount + 1
        }

        window.addEventListener('resize', this.onresize, false)
        setTimeout(() => {
            this.onresize()
        }, 1000)
        this.handleSelect(this.activeIndex, null)
        if (this.styleLayout === 1) {
            if (localStorage.getItem('setSysMenuName')) {
                this.activeName = localStorage.getItem('setSysMenuName')
            }
        }
    },
    methods: {
        updateLeftMenuIndex(id) {
            this.leftChooseId = id
        },
        activeMenu() {
            this.activeName = this.hoverTitle
            localStorage.setItem('setSysMenuName', this.activeName)
        },
        leave(event) {
            clearTimeout(this.hoverTimer)
            const _this = this
            this.hoverTimer = setTimeout(() => {
                // 获取鼠标的位置
                var mouseX = event.pageX
                var mouseY = event.pageY
                // 获取div的位置和尺寸
                var box1 = document.querySelector('.hoverBox')
                var div1Rect = ''
                var div2Rect = ''
                if (box1) {
                    div1Rect = box1.getBoundingClientRect()
                }
                if (_this.menuDiv) {
                    var box2 = document.querySelector(_this.menuDiv).offsetParent
                    if (box2) {
                        div2Rect = box2.getBoundingClientRect()
                    }
                }
                if (box1 && box2) {
                    // 判断鼠标是否在div之外
                    if ((mouseX < div1Rect.left || mouseX > div1Rect.right || mouseY < div1Rect.top || mouseY > div1Rect.bottom) && (mouseX < div2Rect.left || mouseX > div2Rect.right || mouseY < div2Rect.top || mouseY > div2Rect.bottom)) {
                        _this.$bus.emit('hideHoverSideBar')
                    }
                }
            }, 100)
        },
        menuEnter(item, index) {
            this.$bus.emit('hideHoverSideBar')
            if (this.portalSubMenu.length === 1) {
                if (item.meta.params && item.meta.params.indexOf('pageCode=') > -1) {
                    this.hoverTitle = '首页'
                    return
                }
            }
            this.menuDiv = '.menuDiv_' + (item.name ? item.name : item.meta.id)

            this.hoverTitle = item.alias
            clearTimeout(this.mouseHoverTime)
            this.mouseHoverTime = setTimeout(() => {
                if (item.alias !== '应用中心') {
                    this.$bus.emit('hoverSideBar', item)
                    this.$store.dispatch('app/openSideBar', { withoutAnimation: false })
                } else {
                    this.$bus.emit('hideHoverSideBar')
                }
            }, 100)
        },
        clickItem(item) {
            if (this.styleLayout === 1) {
                if (this.portalSubMenu.length === 1) {
                    if (item.meta.params && item.meta.params.indexOf('pageCode=') > -1) {
                        this.activeName = '首页'
                        this.$bus.emit('openSideBar', false)
                        return
                    } else {
                        this.activeName = item.alias
                    }
                } else {
                    this.activeName = item.alias
                }
                if (this.activeName !== '应用中心') {
                    this.$bus.emit('openSideBar', true)
                } else {
                    this.$bus.emit('openSideBar', false)
                }
            }
        },
        setPortalSubMenu() {
            const portal = this.topMenus.filter((item) => item.meta && item.meta.code === 'portal')
            const menu = []
            if (portal.length > 0) {
                this.routes.forEach((item, index) => {
                    if (item.meta && item.meta.parentId === portal[0].meta.id) {
                        let submenu = null
                        if (item.children && item.children.length > 0) {
                            item.children[0].meta.parentIconCode = 'portal'
                            submenu = JSON.parse(JSON.stringify(item.children[0]))
                        } else {
                            item.meta.parentIconCode = 'portal'
                            submenu = JSON.parse(JSON.stringify(item))
                        }
                        submenu.path = '/lm/' + item.meta.parentId + '/' + submenu.meta.id

                        item.path = '/lm/' + item.meta.parentId
                        item.children = [submenu]
                        menu.push(submenu)
                    }
                })
            }

            this.portalSubMenu = menu
        },
        // 窗口变化监听
        onresize() {
            if (this.topMenus.length === 1) return
            this.resizeTopmenu = true
            const win = document.body.offsetWidth
            const menuHeader = document.querySelector('.header-navbar')
            let menu = document.querySelectorAll('.topMenu-show')
            const leftSys = document.querySelector('.sidebar-logo-container').offsetWidth + 1
            const leftNav = document.querySelector('.hamburger-container') ? document.querySelector('.hamburger-container').offsetWidth + 1 : 0
            const right = document.querySelector('.right-menu').offsetWidth + 1
            menuHeader.style.width = 'calc(100% - ' + (leftSys + leftNav + right + 'px)')
            let allWid = menu.length > 1 ? leftSys + leftNav + right + 20 : 0
            // let allWid = menu[0].offsetWidth + (menu.length > 1 ? (menu[1].offsetWidth + menu[menu.length - 1].offsetWidth + menu[menu.length - 2].offsetWidth + leftSys + leftNav + right) : 0)
            for (let i = 0; i < menu.length - 1; i++) {
                menu[i].style.display = ''
            }
            this.$nextTick(() => {
                menu = document.querySelectorAll('.topMenu-show')
                const dropDown = []
                for (let i = 0; i < menu.length; i++) {
                    allWid += menu[i].offsetWidth
                    if (allWid > win) {
                        dropDown.push(i)
                        menu[i].style.display = 'none'
                        menu[dropDown[0] - 1].style.display = 'none'
                        dropDown.push(i - 1)
                    } else {
                        menu[i].style.display = ''
                    }
                }

                this.dropDownmenu = []
                const showtopmenu = this.topMenus.filter((item, index) => {
                    if (item.meta.topFlag === 1) {
                        item.keyindex = index
                        return true
                    }
                })
                if (this.portalSubMenu && this.portalSubMenu.length === 1) {
                    showtopmenu.forEach((menu, index) => {
                        if (dropDown.indexOf(index - 1) > -1) {
                            this.dropDownmenu.push({ item: menu, index: menu.keyindex })
                        }
                    })
                } else {
                    showtopmenu.forEach((menu, index) => {
                        if (dropDown.indexOf(index) > -1) {
                            this.dropDownmenu.push({ item: menu, index: menu.keyindex })
                        }
                    })
                }
                this.$nextTick(() => {
                    this.resizeTopmenu = false
                })
            })
        },
        switchAppsPopover(topMenu, index) {
            const _this = this
            if (topMenu.meta && topMenu.meta.code === 'apps') {
                if (!_this.popShow) {
                    _this.popTimer = setTimeout(() => {
                        _this.popShow = true

                        // if (document.querySelector('.portal-left') !== null) {
                        //     this.$nextTick(() => {
                        //         document.querySelector('.apps-popover').style.left = '305px'
                        //         const width = (document.body.clientWidth - 305) + 'px'
                        //         document.querySelector('.apps-popover').style.width = width
                        //     })
                        // }
                    }, 300)
                }
            } else {
                this.closePop(topMenu)
            }
        },
        clearPopTimer() {
            clearTimeout(this.popTimer)
        },
        closePop(menu, item) {
            this.popShow = false
        },
        handleBodyClick(ev) {
            if (ev.target.innerText === '应用中心' || $(ev.target).parents('.apps-popover').length > 0) {
                return
            }
            // else if (ev.target.innerText === '运营驾驶舱') {
            //     console.log('ev.target.innerText === 运营驾驶舱', ev)
            //     const routeUrl = this.$router.resolve('/yygljsc').href
            //     window.open(routeUrl, '_blank')

            //     return
            // }
            this.popShow = false
        },
        updateTopMenuIndex(topMenuId, myId) {
            for (let i = 0; i < this.topMenus.length; i++) {
                if (this.topMenus[i].meta && this.topMenus[i].meta.id === topMenuId) {
                    if (i !== parseInt(this.activeIndex)) {
                        if (i === 0) {
                            let topMenuIndex = this.Cookies.get('topMenuIndex')
                            if (!topMenuIndex) topMenuIndex = '0'
                            this.handleSelect(topMenuIndex, null)
                        } else {
                            // this.handleSelect(i, null)
                            this.activeIndex = i
                            // console.log('updateTopMenuIndex-this.activeIndex', this.activeIndex)
                            this.handleSelect(this.activeIndex, null)
                        }
                    }
                    break
                }
            }
        },
        handleSelect(index, indexPath) {
            this.activeIndex = (index + '').split('-')[0]
            const topMenuA = this.topMenus[index]
            console.log(409, this.portalSubMenu)
            if (this.styleLayout === 0) {
                if (topMenuA.alias === '首页' || topMenuA.alias === '应用中心') {
                    localStorage.setItem('setSysMenuName', '')
                    this.$bus.emit('theSiderHide')
                    if (this.portalSubMenu.length === 1) {
                        this.$bus.emit('hideMainLeft', true)
                    } else {
                        this.$bus.emit('hideMainLeft', false)
                    }
                } else {
                    localStorage.setItem('setSysMenuName', topMenuA.alias)
                    this.$bus.emit('theSiderShow')
                    this.$bus.emit('hideMainLeft', false)
                }
            } else {
                if (topMenuA.alias === '应用中心') {
                    localStorage.setItem('setSysMenuName', '')
                    this.$bus.emit('theSiderHide')
                } else {
                    localStorage.setItem('setSysMenuName', topMenuA.alias)
                    this.$bus.emit('theSiderShow')
                }
                if (topMenuA.alias === '内部邮箱') {
                    this.$bus.emit('enterEmailChangeSide', true)
                    this.$bus.emit('openSideBar', false)
                } else {
                    this.$bus.emit('enterEmailChangeSide', false)
                }
            }
            // 单挑跳转指定菜单
            if (this.$route.query.singleModuleCode) {
                const code = this.$route.query.singleModuleCode
                delete this.$route.query.singleModuleCode
                const toPath = this.getMenuCode(code)
                if (toPath && toPath.path) {
                    toPath.query = this.$route.query
                    this.$router.replace({ ...toPath })
                }
            }

            let cookieTopMenuIndex = null
            // 顶部导航为首页索引为0时
            if (((index && typeof index === 'string' && index.indexOf('-') > -1) || Number(index) === 0) && this.portalSubMenu.length > 0) {
                const menuindex = Number(index) === 0 ? ['0', '0'] : index.split('-')
                let leftIndex = 1
                if (this.$route.meta && this.$route.meta.href !== this.portalSubMenu[menuindex[1]].meta.href) {
                    this.Cookies.set('topMenuIndex', index)
                    if (this.leftChooseId) {
                        leftIndex = this.portalSubMenu.findIndex((item) => {
                            return item.meta.id === this.leftChooseId
                        })
                    }
                    this.$router.push(this.portalSubMenu[menuindex[leftIndex]])
                } else {
                    let pageCode = this.portalSubMenu[menuindex[1]].meta.params
                    pageCode = pageCode.split('pageCode=')[1].split('&')[0]
                    this.$bus.emit('updatePortalApplet', pageCode)
                    index = '0'
                    const topMenuIndex = this.Cookies.get('topMenuIndex')
                    cookieTopMenuIndex = indexPath && indexPath.length > 1 ? indexPath[1] : typeof topMenuIndex === 'string' && topMenuIndex.indexOf('-') > -1 ? topMenuIndex : '0-0'
                    this.onresize()
                }
                this.portalTitle = this.portalSubMenu[menuindex[leftIndex]].alias

                return
            } else {
                cookieTopMenuIndex = index
            }

            if (this.topMenus[index].meta.icon === 'apps' && indexPath !== null) {
                if (!this.popShow) {
                    this.popShow = true
                } else {
                    this.popShow = false
                }
                this.menuIndex = $('.top-el-menu').find('.el-menu-item.is-active').index()
                this.activeIndex = null
                this.$nextTick(() => {
                    this.activeIndex = this.menuIndex
                })

                return
            }

            this.popShow = false

            if (this.topMenus[index].meta.topFlag === 0) {
                this.menuName = this.topMenus[index].meta.title

                this.activeIndex = null
                this.$nextTick(() => {
                    // this.activeIndex = this.appsIndex
                    this.activeIndex = this.allAppsIndex
                    // console.log(' this.activeIndex', this.activeIndex)
                })
            } else {
                this.menuName = ''
            }

            this.Cookies.set('topMenuIndex', cookieTopMenuIndex)
            this.menuIcon = this.topMenus[index].meta.icon
            this.$bus.emit('getMenuName', this.menuName, this.menuIcon)

            const boo = index > 0 || (this.topMenus[0].meta && this.topMenus[0].meta.code !== 'portal')
            this.$emit('showToggle', boo)
            let topMenu = this.topMenus[index]
            if (this.$route.query.alone === '1') {
                this.topMenus.forEach((tm) => {
                    if (this.$route.path.split('/')[2] === tm.path.split('/')[2]) {
                        tm.meta.topFlag = 1
                        topMenu = tm
                    } else {
                        tm.meta.topFlag = 0
                    }
                })
            }

            // 显示本栏目菜单、隐藏其它栏目菜单
            this.routes.forEach((item) => {
                if (item.meta && item.meta.level === 2) {
                    if (item.meta.parentId === topMenu.meta.id) {
                        if (item.meta && item.meta.title) {
                            item.hidden = false
                        } else if (item.children && item.children[0].meta && item.children[0].meta.title) {
                            item.hidden = false
                        }
                    } else {
                        item.hidden = true
                    }
                }
            })

            if (topMenu.meta.target === 'mainpage') {
                if (this.isNbyx || topMenu.children[0].meta.id === this.$route.meta.id || this.$route.fullPath.indexOf(topMenu.children[0].meta.id) > -1) {
                    this.isNbyx = false
                    return
                }
                if (topMenu.alias === '内部邮箱') {
                    this.activeIndex = this.allAppsIndex
                    this.isNbyx = true
                }

                this.$router.push({ ...topMenu.children[0] })
            } else if (topMenu.meta.href && (topMenu.meta.href.substring(0, 2) === '@/' || topMenu.meta.href.substring(0, 10) === '#/dcontent')) {
                if (topMenu.meta.target === '_blank') {
                    window.open(topMenu.path)
                } else {
                    this.$router.replace({ ...topMenu })
                }
            } else if (topMenu.meta.href && topMenu.meta.href.substring(0, 4) === 'http') {
                if (topMenu.meta.target === '_blank') {
                    window.open(topMenu.path)
                } else {
                    location = topMenu.path
                }
            } else {
                if (indexPath !== null) {
                    const defaultPath = this.getDefaultPath(this.routes, topMenu.meta.id)

                    if (defaultPath !== null) {
                        this.$router.replace({ ...defaultPath })
                    }
                }
            }
        },
        getDefaultPath(routes, tmid) {
            let path = null
            for (let i = 0; i < routes.length; i++) {
                if (tmid === null || (routes[i].meta && (routes[i].meta.parentId === tmid || routes[i].meta.level > 2))) {
                    if (typeof routes[i].children !== 'undefined') {
                        path = this.getDefaultPath(routes[i].children)
                    } else if (typeof routes[i].redirect !== 'undefined' || (routes[i].component && routes[i].component.name !== 'Layout')) {
                        path = routes[i]
                    }
                }

                if (path !== null) break
            }

            return path
        },
        // 递归查询菜单代码
        getMenuCode(code) {
            let toPath
            function findCode(ary) {
                ary.forEach((val) => {
                    if (val.children) {
                        findCode(val.children)
                    }
                    if (!val.children && val.meta && val.meta.code === code) {
                        toPath = val
                        return false
                    }
                })
            }
            findCode(this.routes)
            return toPath
        }
    }
}
</script>
<style lang="scss">
#wapp .hideSidebar .head_topmenu .el-submenu {
    overflow: unset;
    z-index: 99;
    & > .el-submenu__title .el-submenu__icon-arrow {
        display: inline-block;
    }
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled, .is-active):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: var(--bgColorActive);
}
</style>
<style lang="scss" scoped>
.topMu {
    /deep/.el-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid var(--leftToolbarBg);
    }
    /deep/.top-el-menu .el-menu-item {
        line-height: 108px;
        width: 100%;
        text-align: center;
        padding: 0;
        height: 80px;
        // margin: 10px 0;
    }
    /deep/.el-menu-item.is-active,
    .el-menu-item:focus {
        // background: var(--leftToolbarActive) !important;
        font-weight: bold;
        background-color: transparent;
    }
    /deep/ .el-menu-item:hover {
        background-color: var(--leftToolbarActive);
        // background-color: transparent;
        color: var(--leftToolbarTextActive);
        font-weight: bold;
    }
}
@import '~@/wapp/common/styles/variables.module.scss';
.top-el-menu {
    background-color: transparent;
    border-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    letter-spacing: -8px;
    z-index: 99;
    // .topmenu-item-hover{
    //     color: #fff !important;
    // }
    .el-menu-item {
        height: $headerHeight;
        line-height: 62px;
        //background-color: transparent;
        // &:hover{
        //     .topmenu-item-hover{
        //         color: var(--bgColor) !important;
        //     }
        // }
    }
    .el-submenu-top {
        display: inline-block;
        float: none;
        letter-spacing: normal;
    }
    /deep/ .el-submenu.top_sub_menu {
        padding-left: 20px;
        &:hover {
            &::before {
                display: none !important;
            }
        }
        .el-submenu__title {
            border-bottom: none;
            padding: 0;
            background: initial;
            height: 60px !important;
            line-height: 60px !important;
            .el-submenu__icon-arrow {
                color: #fff !important;
            }
        }
        // .el-submenu__title:hover{
        //     color: var(--bgColor) !important;
        //     .el-submenu__icon-arrow{
        //        color: var(--bgColor) !important;
        //     }
        // }
        .el-menu--horizontal {
            .el-menu--popup {
                min-width: 100px;
                .el-menu-item {
                    height: 40px;
                    line-height: 40px;
                    position: relative;
                    background: var(--bgColor);
                    min-width: 100px;
                    &:not(:last-child)::after {
                        position: absolute;
                        content: '';
                        width: calc(100% - 10px);
                        border-bottom: 1px solid #fff;
                        bottom: 0;
                        left: 5px;
                    }
                }
            }
        }
    }
    //  /deep/ .is-opened:hover{
    //     .el-submenu__title{
    //         border-bottom: none;
    //         padding: 0;
    //         background: initial;
    //         height: 60px!important;
    //         line-height: 60px!important;
    //         color: var(--bgColor) !important;
    //         .el-submenu__icon-arrow{
    //             color: var(--bgColor) !important;
    //         }
    //     }
    // }
}
</style>
