<template>
  <div class="blank-container">
    <router-view />
  </div>
</template>
<script>
export default {
    name: 'Blank'
}
</script>
