<template>
  <div class="drawer-container">
    <div>
      <h3 class="drawer-title">{{ $t('settings.title') }}</h3>

      <div class="drawer-item">
        <span>{{ $t('settings.theme') }}</span>
        <theme-picker style="float: right;height: 26px;margin: -3px 8px 0 0;" @change="themeChange" />
      </div>

      <div class="drawer-item">
        <span>{{ $t('settings.tagsView') }}</span>
        <el-switch v-model="tagsView" class="drawer-switch" />
      </div>

      <div class="drawer-item">
        <span>{{ $t('settings.fixedHeader') }}</span>
        <el-switch v-model="fixedHeader" class="drawer-switch" />
      </div>

      <div class="drawer-item">
        <span>{{ $t('settings.showTopMenus') }}</span>
        <el-switch v-model="showTopMenus" class="drawer-switch" />
      </div>

    </div>
  </div>
</template>

<script>
import ThemePicker from '@/wapp/common/components/ThemePicker'
import { mapGetters } from 'vuex'

export default {
    components: { ThemePicker },
    data() {
        return {}
    },
    computed: {
        ...mapGetters([
            'routes'
        ]),
        fixedHeader: {
            get() {
                return this.$store.state.settings.fixedHeader
            },
            set(val) {
                this.$store.dispatch('settings/changeSetting', {
                    key: 'fixedHeader',
                    value: val
                })
            }
        },
        tagsView: {
            get() {
                return this.$store.state.settings.tagsView
            },
            set(val) {
                this.$store.dispatch('settings/changeSetting', {
                    key: 'tagsView',
                    value: val
                })
            }
        },
        showTopMenus: {
            get() {
                return this.$store.state.settings.showTopMenus
            },
            set(val) {
                this.$store.dispatch('settings/changeSetting', {
                    key: 'showTopMenus',
                    value: val
                })

                if (!val) {
                    const leftMenus = this.routes.filter(item => {
                        if (item.meta && item.meta.level === 2) return true
                    })

                    leftMenus.forEach(function(item) {
                        if (item.meta && item.meta.title) {
                            item.hidden = false
                        } else if (item.children && item.children[0].meta && item.children[0].meta.title) {
                            item.hidden = false
                        }
                    })
                }
            }
        }
    },
    methods: {
        themeChange(val) {
            this.$store.dispatch('settings/changeSetting', {
                key: 'theme',
                value: val
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.drawer-container {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;

  .drawer-title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 22px;
  }

  .drawer-item {
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    padding: 12px 0;
  }

  .drawer-switch {
    float: right
  }
}
</style>
