<template>
    <div v-loading="loading" class="calendar-detail" onselectstart="return true;">
        <div class="left-content">
            <div v-if="shouyeShow" style="font-size: 15px; color: #000; height: 50px; line-height: 50px; padding: 0 10px; border-bottom: 1px solid #dee2e6; margin-bottom: 10px">周计划表（{{ nowYear }}{{ WeekArray[0].date }} ~ {{ nowYear }}{{ WeekArray[WeekArray.length - 1].date }}）</div>
            <!-- 左边顶部操作按钮 -->
            <div v-else class="left-top" style="margin-top: 10px">
                <div v-loading="loading" class="app-container">
                    <div class="myApp system_out">
                        <el-button-group style="padding-bottom: 10px">
                            <el-button icon="el-icon-film" @click="activeFn(1)"></el-button>
                            <el-button icon="el-icon-menu" @click="activeFn(2)"></el-button>
                        </el-button-group>
                    </div>
                </div>
                <div v-if="!vo.innerList" class="oa-title">
                    <template>
                        <!-- <span class="left-o" @click="preWeek(preOrNextDate)"> <i class="el-icon-arrow-left" /> </span>
            <span class="right-o" @click="nextWeek(preOrNextDate)"><i class="el-icon-arrow-right" /></span>
            <span class="toDay center-o" @click="gotoDay">本周</span> -->
                        <i style="cursor: pointer" class="el-icon-arrow-left" @click="preWeek(preOrNextDate)" />
                        <div style="font-size: 15px">{{ nowYear }}{{ WeekArray[0].date }} 至 {{ nowYear }}{{ WeekArray[WeekArray.length - 1].date }}</div>
                        <i style="cursor: pointer" class="el-icon-arrow-right" @click="nextWeek(preOrNextDate)" />
                        <div style="width: 35px; color: #1a82e3; cursor: pointer" @click="gotoDay">本周</div>
                    </template>
                </div>
            </div>

            <!-- 周视图 -->
            <div v-if="isShow" class="week-box">
                <div style="height: 68px; display: flex; background: #f2f5fb; border-radius: 8px 8px 0 0; border: 1px solid #ebebeb; width: 100%">
                    <div class="week-left" />
                    <div v-if="vo.innerList" class="week-top">
                        <div v-for="(item, index) in WeekArray" :key="index">
                            <span>{{ item.title }}</span>
                            <span style="color: rgba(0, 0, 0, 0.4); font-size: 10px; font-weight: bold; margin-top: 5px; font-style: normal">{{ item.date }}</span>
                        </div>
                    </div>
                    <div v-else class="week-top">
                        <div v-for="(item, index) in WeekArray" :key="index" :style="{ color: item.title == forWeek() ? 'var(--bgColor)' : '' }">
                            <span>{{ item.title }}</span>
                            <span :style="{ color: item.title == forWeek() ? 'var(--bgColor)' : 'rgba(0, 0, 0, 0.4)' }" style="color: rgba(0, 0, 0, 0.4); font-size: 10px; font-weight: bold; margin-top: 5px; font-style: normal">{{ item.date }}</span>
                        </div>
                    </div>
                </div>
                <div class="week-content">
                    <popover :start-timep="startTimep" :end-timep="endTimep" which-type="4" :list-detail="finallyWeekData" :week-array="WeekArray" />
                </div>
            </div>
            <div v-else>
                <zbhzlist :new-vo="newVo"></zbhzlist>
            </div>
        </div>
        <select-dialog />
        <Basicform ref="busiform" :title="'事项登记'" :win-width="moduleInfo.winWidth" :win-height="moduleInfo.winHeight" :append-to-body="true" :toolbar-btns="busiformBtns" :on-basic-form-before-open="onBasicFormBeforeOpened" :on-basic-form-before-submit="onBasicFormBeforeSubmit" :on-basic-form-submited="onBasicFormSubmited">
            <template #default="slotProps">
                <!-- <personOrderForm :vo="slotProps.vo" :can-edit="slotProps.canEdit" :module-info="moduleInfo" /> -->
                <busi-form :vo="slotProps.vo" :can-edit="slotProps.canEdit" :module-info="moduleInfo" />
            </template>
        </Basicform>
    </div>
</template>

<script>
import calendarUtil from '@/wapp/portal/components/calendarUtil/utli.js'
import popover from './components/calendarPopover'
import { calendar } from '@/wapp/portal/components/calendarUtil/calendarUtil.js'
// import personOrderForm from './components/personOrderForm'
import zbhzlist from './components/zbhzlist.vue'
const moment = require('moment')
export default {
    components: {
        popover,
        // personOrderForm,
        zbhzlist
    },
    props: {
        vo: {
            type: Object,
            default: function () {
                return {}
            }
        },
        innerList: {
            type: Array,
            default: function () {
                return []
            }
        },
        appletData: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            newVo: {},
            shouyeShow: false,
            isShow: true,
            loginUser: this.$store.getters.loginUser,
            incidentsList: [],
            wnlData: {},
            weekData: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
            WeekArray: [
                { title: '星期一', date: '', dataValue: '' },
                { title: '星期二', date: '', dataValue: '' },
                { title: '星期三', date: '', dataValue: '' },
                { title: '星期四', date: '', dataValue: '' },
                { title: '星期五', date: '', dataValue: '' },
                { title: '星期六', date: '', dataValue: '' },
                { title: '星期日', date: '', dataValue: '' }
            ],
            monthWeekData: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
            timeData: ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
            currentTime: this.getCurrentDateTime(),
            noChangeNowTime: this.getCurrentDateTime(),
            listData: [],
            loading: true,
            dateListData: [],
            chooseDate: this.getCurrentDateTime(),
            preOrNextDay: '',
            canlendarData: [],
            scheduleData: [],
            scheduleWeek: [],
            finallyData: [],
            incidents: [],
            helloData: [],
            weals: [],
            wealList: [],
            listdataWeek: [],
            preOrNextDate: '',
            nowYear: moment(new Date()).format('YYYY年'),
            dateTile: moment(new Date()).format('YYYY年MM月DD日'),
            weekTitle: '',
            MonthTitle: moment(new Date()).format('YYYY年MM月'),
            spanIndex: 2,
            yearAndMonth: moment(this.currentTime).format('YYYY年MM月'),
            bigScreen: false,
            moduleInfo: {
                winWidth: 1000,
                delUrl: 'zhsp/sxdj/delete',
                addNewUrl: 'zhsp/sxdj/addNew',
                insertUrl: 'zhsp/sxdj/insert',
                winHeight: 550,
                updateUrl: 'zhsp/sxdj/update',
                listDataUrl: 'zhsp/sxdj/listdata',
                queryDataUrl: 'zhsp/sxdj/dayView',
                getByIdUrl: 'zhsp/sxdj/getById',
                queryHolidayUrl: 'base/holiday/querydata'
            },
            busiformBtns: [],
            formStartTime: '',
            formEndTime: '',
            isEnd: '',
            addMonthStartTime: '',
            liHeight: 0,
            startTimep: '',
            endTimep: ''
        }
    },
    watch: {
        currentTime(val) {
            if (val) {
                this.yearAndMonth = val.split('-')[0] + '年' + val.split('-')[1] + '月'
            }
        }
    },
    created() {
        this.newVo = JSON.parse(JSON.stringify(this.vo))

        // console.log('innerList', this.innerList)
        // console.log('appletData', this.appletData)
        this.$bus.emit('showNavSearch', false)
        this.$bus.on('editForm', this.openForm)
        this.$bus.on('liH', this.changeLih)
        // this.initData()
        if (this.appletData && this.appletData.id) {
            this.shouyeShow = true
        }
        if (this.spanIndex === 2) {
            this.initWeek()
        }
        // console.log('vo', this.vo)
        // console.log(' this.newVo', this.newVo)
    },
    mounted() {
        this.getWindowInfo()
        window.addEventListener('resize', this.getWindowInfo)
        if (document.querySelector('.navbar')) {
            document.querySelector('.navbar').style = 'display:block'
        }
        if (this.shouyeShow) {
            document.querySelector('.week-content').style.height = '734px'
        }
        this.moduleInfo.formTemplate = '#/dcontent/vuebusiform/W/zhsp.sxdj.form.vue'
        this.$options.components['BusiForm'] = this.$loadComponent(this.moduleInfo.formTemplate)
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true)
        this.$bus.off('editForm', this.openForm)
        this.$bus.off('liH', this.changeLih)
        if (document.querySelector('.navbar')) {
            document.querySelector('.navbar').style = 'display:block'
        }
        window.removeEventListener('resize', this.getWindowInfo)
    },
    methods: {
        changeLih(val) {
            this.liHeight = val
        },
        activeFn(val) {
            if (val === 1) {
                if (!this.vo.innerList) {
                    // this.initWeek()
                }
                // console.log(1212)
                this.isShow = true
                document.querySelector('.calendar-detail').style.height = '100%'
            } else {
                // console.log(6666)
                this.isShow = false
                this.$nextTick(() => {
                    this.$bus.emit('setlistdata', { startTimep: this.startTimep, endTimep: this.endTimep })
                })
                document.querySelector('.calendar-detail').style.background = '#fff'
                // if (!this.vo.innerList) {
                //     document.querySelector('.calendar-detail').style.height = 'calc(100vh - 130px)'
                // } else {
                //     document.querySelector('.calendar-detail').style.height = '100%'
                // }
            }
        },
        onBasicFormBeforeOpened($scope, vo, defer) {
            // console.log(vo, $scope, this.formStartTime, this.formEndTime)
            $scope.$set($scope.vo, 'firstDupconfig', vo.dupConfig)
            if (vo.isnew) {
                if (this.spanIndex === 1 || this.spanIndex === 2) {
                    vo.startDate = new Date(this.formStartTime)
                    vo.endDate = new Date(this.formEndTime)
                } else {
                    vo.startDate = new Date(this.addMonthStartTime)
                    vo.endDate = new Date(this.addMonthStartTime)
                }
            } else {
                vo.startDate = vo.startTime
                vo.endDate = vo.endTime
            }
            if (this.isEnd) {
                this.busiformBtns = [
                    {
                        id: 'btn_close',
                        btnName: '关 闭',
                        btnIcon: 'close',
                        btnClick: this.$refs['busiform'].closeDialog,
                        btnPermission: '',
                        btnType: ''
                    }
                    // , {
                    //     id: 'btn_save',
                    //     btnName: '保 存',
                    //     btnIcon: 'check',
                    //     btnClick: 'submitBusiform',
                    //     btnPermission: 'saveObject',
                    //     btnType: 'primary'
                    // }
                ]
            } else {
                if (vo.ownerUserId !== this.loginUser.userId) {
                    this.busiformBtns = [
                        {
                            id: 'btn_close',
                            btnName: '关 闭',
                            btnIcon: 'close',
                            btnClick: this.$refs['busiform'].closeDialog,
                            btnPermission: '',
                            btnType: ''
                        }
                        // , {
                        //     id: 'btn_save',
                        //     btnName: '保 存',
                        //     btnIcon: 'check',
                        //     btnClick: 'submitBusiform',
                        //     btnPermission: 'saveObject',
                        //     btnType: 'primary'
                        // }
                    ]
                } else {
                    this.busiformBtns = [
                        {
                            id: 'btn_close',
                            btnName: '关 闭',
                            btnIcon: 'close',
                            btnClick: this.$refs['busiform'].closeDialog,
                            btnPermission: '',
                            btnType: ''
                        }
                        // , {
                        //     id: 'btn_save',
                        //     btnName: '保 存',
                        //     btnIcon: 'check',
                        //     btnClick: 'submitBusiform',
                        //     btnPermission: 'saveObject',
                        //     btnType: 'primary'
                        // }
                    ]
                }
            }
            defer.resolve(true)
        },
        onBasicFormBeforeSubmit($scope, vo, defer) {
            // vo.startTime = vo.startDate
            // vo.endTime = vo.endDate
            // if (new Date(vo.startDate).getTime() > new Date(vo.endDate).getTime()) {
            //     this.$alert('结束时间不能早于开始时间！', '提示', { type: 'warning' })
            //     defer.reject()
            //     return
            // }
            // if (vo.dupConfig > 0 && vo.continueConfig === 3 && vo.startDate > vo.assignDate) {
            //     this.$alert('指定重复时间不能早于结束时间！', '提示', { type: 'warning' })
            //     defer.reject()
            //     return
            // }
            //
            // if (vo.isnew) {
            //     defer.resolve(true)
            // } else {
            //     const diff = new Date(vo.endDate).getDate() - new Date(vo.startDate).getDate()
            //     if ($scope.vo.firstDupconfig !== undefined && $scope.vo.firstDupconfig === 0) {
            //         $scope.moduleInfo.updateUrl = 'ecoa/calendar/update?isCurrent=2&acrossDayNum=' + diff
            //         defer.resolve(true)
            //     } else {
            //         this.$bus.emit('openListitemDialog', '选择修改方式', [{ text: '修改当前日程', value: '1' }, { text: '修改当前以及后续日程', value: '0' }, { text: '修改全部重复日程', value: '2' }], rest => {
            //             if (rest.code === 'success') {
            //                 $scope.moduleInfo.updateUrl = 'ecoa/calendar/update?isCurrent=' + rest.value + '&acrossDayNum=' + diff
            //                 defer.resolve(true)
            //             } else {
            //                 defer.reject()
            //             }
            //         }, null, null, '当前为重复日程，请选择：')
            //     }
            // }
            defer.resolve(true)
        },
        onBasicFormSubmited($scope, vo) {
            if (this.spanIndex === 1) {
                this.initData(this.chooseDate)
                this.dateTile = moment(this.chooseDate).format('YYYY年MM月DD日')
            } else if (this.spanIndex === 2) {
                this.initWeek()
            } else {
                this.initData(this.chooseDate)
                this.yearAndMonth = moment(this.chooseDate).format('YYYY年MM月')
                this.$forceUpdate()
            }
        },
        openForm(vo, type, isEnd) {
            this.isEnd = isEnd
            if (this.vo && this.vo.innerList) return
            if (type === 'edit') {
                this.moduleInfo.formTemplate = '#/dcontent/vuebusiform/W/zhsp.sxdj.form.vue'
                this.$options.components['BusiForm'] = this.$loadComponent(this.moduleInfo.formTemplate)
                this.$nextTick(() => {
                    this.$bus.emit('openBusiform', this.moduleInfo, vo, 0, (res) => {
                        if (res.code === 'success') {
                            console.log(res.code)
                        }
                    })
                })
            } else {
                this.$options.components['BusiForm'] = this.$loadComponent(this.moduleInfo.formTemplate)
                this.moduleInfo.formTemplate = '#/dcontent/vuebusiform/W/zhsp.sxdj.form.vue'
                // setTimeout(() => {
                //     this.$bus.emit('openBusiform', this.moduleInfo, null, 1, (res) => {
                //         if (res.code === 'success') {
                //         }
                //     })
                // }, 300)
            }
        },
        getWindowInfo() {
            if (document.querySelectorAll('.el-scrollbar__view').length === 3) {
                this.liHeight = document.querySelectorAll('.el-scrollbar__view')[2].clientHeight
            }
            if (document.body.clientWidth > 1600) {
                this.bigScreen = true
            } else {
                this.bigScreen = false
            }
        },
        forWeek() {
            const date = new Date().getDay()
            switch (date) {
                case 6:
                    return '星期六'
                case 1:
                    return '星期一'
                case 2:
                    return '星期二'
                case 3:
                    return '星期三'
                case 4:
                    return '星期四'
                case 5:
                    return '星期五'
                default:
                    return '星期日'
            }
        },
        gotoDsj(url) {
            window.open(url)
        },
        wnlFormat(val) {
            const date = val.split('-')
            const year = date[0]
            const month = date[1]
            const day = date[2]
            const Idate = calendar.solar2lunar(year, month, day).IMonthCn + calendar.solar2lunar(year, month, day).IDayCn
            const festival = calendar.solar2lunar(year, month, day).festival
            return {
                Idate,
                festival
            }
        },
        gotoDay() {
            this.nowYear = moment(new Date()).format('YYYY年')
            if (this.spanIndex === 1 || this.spanIndex === 3) {
                const val = this.getCurrentDateTime().split('-')
                this.listData = calendarUtil.createNowMonthDate(val[0], val[1])
                this.preOrNextDay = this.noChangeNowTime
                this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.noChangeNowTime)
                this.dateTile = moment(this.noChangeNowTime).format('YYYY年MM月DD日')
                this.yearAndMonth = moment(this.noChangeNowTime).format('YYYY年MM月')
                this.MonthTitle = moment(this.noChangeNowTime).format('YYYY年MM月')
                this.chooseDate = this.noChangeNowTime
                this.currentTime = this.noChangeNowTime
            } else {
                this.listdataWeek = calendarUtil.getNowWeekDayA(this.noChangeNowTime)
                this.preOrNextDate = this.noChangeNowTime
                this.weekTitle = moment(this.listdataWeek[0].data).format('MM月DD日') + '-' + moment(this.listdataWeek[this.listdataWeek.length - 1].data).format('MM月DD日')
                for (let i = 0; i <= this.listdataWeek.length - 1; i++) {
                    this.WeekArray[i].date = this.listdataWeek[i].month + '月' + this.listdataWeek[i].date + '日'
                    this.WeekArray[i].dataValue = this.listdataWeek[i].data
                }
                this.getWeekListData(this.listdataWeek[0].data, this.listdataWeek[this.listdataWeek.length - 1].data, this.listdataWeek) // 获取本周的日程数据
            }
        },
        nowLine() {
            var d = new Date()
            var hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
            var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
            var startHeight = minutes - 0 + (30 - 0)
            var time = hours + ':' + minutes
            return {
                hours,
                minutes,
                time,
                startHeight
            }
        },
        notCurrentDate(index) {
            return this.listData[index].name === 'nowMonth'
        },
        clickTime(val) {
            this.spanIndex = val
            if (val === 1) {
                this.initData(this.chooseDate)
                this.dateTile = moment(this.chooseDate).format('YYYY年MM月DD日')
            } else if (val === 2) {
                this.initWeek()
            } else {
                this.yearAndMonth = moment(this.chooseDate).format('YYYY年MM月')
                this.$forceUpdate()
            }
            if (document.querySelectorAll('.el-scrollbar__view').length === 3) {
                this.liHeight = document.querySelectorAll('.el-scrollbar__view')[2].clientHeight
            }
        },
        initWeek() {
            const val = this.chooseDate
            if (this.vo && this.vo.innerList) {
                const dateString = this.vo.flSubject
                const formattedDate = this.formatDate(dateString)
                // console.log('formattedDate', formattedDate)
                this.listdataWeek = calendarUtil.getNowWeekDayA(formattedDate)
                // console.log('weekData503', this.listdataWeek)
                this.weekTitle = moment(this.listdataWeek[0].data).format('MM月DD日') + '-' + moment(this.listdataWeek[this.listdataWeek.length - 1].data).format('MM月DD日')
                for (let i = 0; i <= this.listdataWeek.length - 1; i++) {
                    this.WeekArray[i].date = this.listdataWeek[i].month + '月' + this.listdataWeek[i].date + '日'
                    this.WeekArray[i].dataValue = this.listdataWeek[i].data
                }
                this.setWeekListData(this.listdataWeek)
            } else {
                this.listdataWeek = calendarUtil.getNowWeekDayA(val)
                this.weekTitle = moment(this.listdataWeek[0].data).format('MM月DD日') + '-' + moment(this.listdataWeek[this.listdataWeek.length - 1].data).format('MM月DD日')
                for (let i = 0; i <= this.listdataWeek.length - 1; i++) {
                    this.WeekArray[i].date = this.listdataWeek[i].month + '月' + this.listdataWeek[i].date + '日'
                    this.WeekArray[i].dataValue = this.listdataWeek[i].data
                }
                // console.log(this.listdataWeek[0].data, this.listdataWeek[this.listdataWeek.length - 1].data, this.listdataWeek)
                this.getWeekListData(this.listdataWeek[0].data, this.listdataWeek[this.listdataWeek.length - 1].data, this.listdataWeek) // 获取本周的日程数据
            }
        },
        formatDate(dateString) {
            let year = ''
            let str = ''
            if (dateString.indexOf('年')) {
                year = dateString.split('年')[0]
            }
            if (dateString.indexOf('(')) {
                const str1 = dateString.split('(')[1]
                str = str1.split('至')[0]
            }
            const dateStr = year + '年' + str

            const year1 = dateStr.substring(0, 4)
            const month = dateStr.substring(5, 7)
            const day = dateStr.substring(8, 10)

            const date = new Date(`${year1}-${month}-${day}`)
            date.setDate(date.getDate() + 1)

            const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
            return formattedDate
        },

        initData(time) {
            this.currentTime = time || this.currentTime
            const val = time ? this.chooseDate.split('-') : this.getCurrentDateTime().split('-')
            this.listData = calendarUtil.createNowMonthDate(val[0], val[1])
            this.wnlData = this.wnlFormat(this.currentTime)
            this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.currentTime) // 获取一月的日程数据
        },
        getWeekListData(start, end, weekData) {
            // console.log('weekData', weekData)

            const startTime_p = start + ' 00:00:00'
            const endTime_p = end + ' 23:59:59'
            this.startTimep = startTime_p
            this.endTimep = endTime_p
            this.$bus.emit('setlistdata', { startTimep: this.startTimep, endTimep: this.endTimep })
            const arr = []
            this.$http({
                method: 'post',
                url: '/zhsp/sxdj/listdata',
                params: {
                    pageSize: 65000,
                    pageNum: 1,
                    listpageId: 'c0ddf63924a749db9aab7a37541eae5f',
                    urlsearch: `startTime>:${startTime_p};endTime<:${endTime_p};status:1;`
                }
            }).then((res) => {
                // console.log('res', res)
                if (res.code === 'success') {
                    if (res.body.pageInfo.list.length > 0) {
                        res.body.pageInfo.list.forEach((item) => {
                            if (arr.length > 0) {
                                const index = arr.findIndex((val) => val.date === item.startTime.split(' ')[0])
                                if (index === -1) {
                                    arr.push({
                                        date: item.startTime.split(' ')[0],
                                        listDetail: []
                                    })
                                }
                            } else {
                                arr.push({
                                    date: item.startTime.split(' ')[0],
                                    listDetail: []
                                })
                            }
                        })

                        // console.log('1', arr)

                        res.body.pageInfo.list.forEach((item) => {
                            arr.forEach((val) => {
                                if (item.startTime.split(' ')[0] === val.date) {
                                    val.listDetail.push(item)
                                }
                            })
                        })

                        // console.log('2', arr)

                        arr.forEach((item) => {
                            item.listDetail.forEach((val) => {
                                val.title = val.subject
                                val.startDate = val.startTime.split(' ')[0]
                                val.startTime = val.startTime.split(' ')[1]
                                val.endDate = val.endTime.split(' ')[0]
                                val.endTime = val.endTime.split(' ')[1]
                            })
                        })
                    }
                    this.finallyWeekData = this.arrayAdapterWeek(weekData, arr).weeklist
                    // console.log('finallyWeekData', this.finallyWeekData)
                    this.loading = false
                    this.$forceUpdate()
                }
            })
        },
        setWeekListData(weekData) {
            // const weekData = calendarUtil.getNowWeekDayA('2023-07-31')
            // console.log('weekData503', weekData)
            // for (let i = 0; i <= weekData.length - 1; i++) {
            //     this.WeekArray[i].date = weekData.month + '月' + weekData.date + '日'
            //     this.WeekArray[i].dataValue = weekData.data
            // }
            const arr = []
            if (this.vo.innerList.length > 0) {
                this.vo.innerList.forEach((item) => {
                    if (arr.length > 0) {
                        const index = arr.findIndex((val) => val.date === item.startTime.split(' ')[0])
                        if (index === -1) {
                            arr.push({
                                date: item.startTime.split(' ')[0],
                                listDetail: []
                            })
                        }
                    } else {
                        arr.push({
                            date: item.startTime.split(' ')[0],
                            listDetail: []
                        })
                    }
                })

                // console.log('1', arr)

                this.vo.innerList.forEach((item) => {
                    arr.forEach((val) => {
                        if (item.startTime.split(' ')[0] === val.date) {
                            val.listDetail.push(item)
                        }
                    })
                })

                // console.log('2', arr)

                arr.forEach((item) => {
                    item.listDetail.forEach((val) => {
                        val.title = val.subject
                        val.startDate = val.startTime.split(' ')[0]
                        val.startTime = val.startTime.split(' ')[1]
                        val.endDate = val.endTime.split(' ')[0]
                        val.endTime = val.endTime.split(' ')[1]
                    })
                })
            }
            this.finallyWeekData = this.arrayAdapterWeek(weekData, arr).weeklist
            // console.log('finallyWeekData', this.finallyWeekData)
            this.loading = false
            this.$forceUpdate()
        },
        getMonthListData(start, end, listdata, currentTime) {
            // 获取一个月的日程数据
            this.canlendarData = []
            this.weeklist = []
            this.$http({
                method: 'post',
                params: {
                    startdate: moment(start).format('YYYY-MM-DD') + ' ' + '00:00:00',
                    enddate: moment(end).format('YYYY-MM-DD') + ' ' + '23:59:59'
                },
                url: '/ecoa/calendar/dayView'
            }).then((res) => {
                if (res.code === 'success') {
                    this.$http({
                        method: 'POST',
                        data: [{ compare: '=', field: 'type', merge: true, value: '3' }],
                        url: '/portal/cms/article/querydata'
                    }).then((resp) => {
                        if (resp.code === 'success') {
                            this.incidents = resp.body.listdata
                            this.canlendarData = res.body.calendarList
                            this.dateListData = this.arrayAdapter(listdata, this.canlendarData, currentTime, this.incidents).dateList
                            this.finallyData = this.arrayAdapter(listdata, this.canlendarData, currentTime, this.incidents).schedule // 获取日程数据结构

                            if (this.incidents.length > 0) {
                                this.incidentsList = this.incidents.filter((val) => {
                                    return val.publishTime && val.publishTime.split(' ')[0] === currentTime
                                })
                            }
                            // console.log(462, this.finallyData, this.dateListData, this.incidentsList)
                            // if (this.spanIndex === 1) {
                            //     const scrlloBox = document.querySelector('.content-box')
                            //     this.$nextTick(() => {
                            //         if (this.nowLine().hours < 17) {
                            //             scrlloBox.scrollTop = this.nowLine().hours * 60 - 50
                            //         } else {
                            //             scrlloBox.scrollTop = 900
                            //         }
                            //     })
                            // }
                            this.loading = false
                            this.$forceUpdate()
                        }
                    })
                }
            })
        },
        // 组件日程列表数据(周)
        arrayAdapterWeek(weekData, arrayB) {
            // 1周日历数据，2日程数据
            this.scheduleWeek = []
            if (arrayB.length > 0) {
                arrayB.forEach((item) => {
                    if (weekData.length > 0) {
                        weekData.forEach((week) => {
                            if (item.date === week.data) {
                                week.flag = true
                                week.listDetail = item.listDetail
                                item.listDetail.forEach((el) => {
                                    const timeHeight = this.timeDiff(this.timeFor(el.startDate, el.endDate, el.startTime, el.endTime).start, this.timeFor(el.startDate, el.endDate, el.startTime, el.endTime).end)
                                    const time = this.timeFor(el.startDate, el.endDate, el.startTime, el.endTime).time
                                    const startHeight = this.timeFor(el.startDate, el.endDate, el.startTime, el.endTime).startHeight
                                    const endTime = this.timeFor(el.startDate, el.endDate, el.startTime, el.endTime).end
                                    this.scheduleWeek.push({
                                        timeHeight: timeHeight,
                                        time: time,
                                        listDetail: el,
                                        date: item.date,
                                        isEnd: new Date(endTime).getTime() < new Date().getTime(),
                                        startHeight: startHeight
                                    })
                                })
                            }
                        })
                    }
                })
            }

            weekData.forEach((val) => {
                val.allData = []
                this.scheduleWeek.forEach((item) => {
                    if (val.data === item.date) {
                        val.allData.push(item)
                    }
                })
            })
            let allTime = {}
            let num = 1
            const weeklist = []
            this.timeData.forEach((data) => {
                allTime = {
                    timeDate: num < 10 ? '0' + num : num + '',
                    title: data
                }
                num++
                weeklist.push(JSON.parse(JSON.stringify(allTime)))
            })
            const finallyWeekList = []
            weeklist.forEach((item) => {
                const valDataA = []
                weekData.forEach((val) => {
                    const temp = []
                    val.listdata = []
                    if (val.allData.length > 0) {
                        val.allData.sort((a, b) => {
                            if (a.listDetail.startTime < b.listDetail.startTime) {
                                return -1
                            }
                            if (a.listDetail.startTime > b.listDetail.startTime) {
                                return 1
                            }
                            return 0
                        })
                        val.allData.forEach((el) => {
                            if (item.timeDate === el.time) {
                                temp.push(el)
                            }
                        })
                        val.allData = this.getBoxWidth(val.allData)
                    }
                    val.listdata = temp
                    valDataA.push(val)
                })
                finallyWeekList.push({
                    timeDate: item.timeDate,
                    title: item.title,
                    valData: JSON.parse(JSON.stringify(valDataA))
                })
            })
            // console.log(653, finallyWeekList)
            return {
                weeklist: finallyWeekList
            }
        },
        getBoxWidth(el) {
            const k = []
            for (let i = 0; i < el.length; i++) {
                let widthCount = 1
                const jT = []
                for (let j = i + 1; j < el.length; j++) {
                    if (el[i].listDetail.endTime > el[j].listDetail.startTime) {
                        widthCount++
                        jT.push(j)
                    }
                }
                if (k.length === 0) {
                    el[i].width = 98 / widthCount + '%'
                    el[i].left = '1%'
                    if (jT.length > 0) {
                        jT.forEach((item) => {
                            k.push(item)
                            el[item].width = 98 / widthCount + '%'
                            el[item].left = 100 / widthCount + '%'
                        })
                    }
                } else {
                    if (!k.includes(i)) {
                        el[i].width = 98 / widthCount + '%'
                        el[i].left = 100 / widthCount + '%'
                    }
                }
            }
            return el
        },
        getCurrentDateTime() {
            var d = new Date()
            var year = d.getFullYear()
            var month = d.getMonth() + 1
            var date = d.getDate()
            var curDateTime = ''
            curDateTime = year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date)
            return curDateTime
        },
        nowDay(val) {
            const date = this.getCurrentDateTime()
            return val === date
        },
        select(item) {
            return item.data === this.chooseDate
        },
        monthClick(item) {
            this.addMonthStartTime = item.data + ' ' + (new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()) + ':' + (new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes())
            this.openForm(null)
            this.$forceUpdate()
        },
        choose(item) {
            this.chooseDate = item.data
            this.wnlData = this.wnlFormat(item.data)
            this.preOrNextDate = item.data
            this.preOrNextDay = item.data
            if (this.spanIndex === 2) {
                const week = calendarUtil.getNowWeekDayA(item.data)
                this.weekTitle = moment(week[0].data).format('MM月DD日') + '-' + moment(week[week.length - 1].data).format('MM月DD日')
                for (let i = 0; i <= week.length - 1; i++) {
                    this.WeekArray[i].date = week[i].month + '月' + week[i].date + '日'
                    this.WeekArray[i].dataValue = week[i].data
                }
                this.getWeekListData(week[0].data, week[week.length - 1].data, week)
            } else if (this.spanIndex === 1) {
                this.dateTile = moment(item.data).format('YYYY年MM月DD日')
            } else {
                this.MonthTitle = moment(item.data).format('YYYY年MM月')
            }
            this.getMonthListData(item.data, item.data, this.dateListData, item.data)
            this.$forceUpdate()
        },
        timeDiff(startTime, endTime) {
            const start = new Date(startTime).getTime()
            const end = new Date(endTime).getTime()
            const val = Number(end) - Number(start)
            const minutes = val / 1000 / 60
            return minutes
        },
        timeFor(startDate, endDate, startTime, endTime) {
            const time = startTime.split(':')[0]
            const startHeight = startTime.split(':')[1] - 0
            const start = startDate + ' ' + startTime.split(':')[0] + ':' + startTime.split(':')[1]
            const end = endDate + ' ' + endTime.split(':')[0] + ':' + endTime.split(':')[1]
            return {
                time,
                startHeight,
                start,
                end
            }
        },
        preWeek(val) {
            if (this.chooseDate && this.chooseDate.indexOf('-') > -1) {
                this.nowYear = this.chooseDate.split('-')[0] + '年'
            }
            let allData = ''
            if (val) {
                allData = calendarUtil.getLastWeekDayA(val)
            } else {
                allData = calendarUtil.getLastWeekDayA(this.chooseDate)
            }

            this.listdataWeek = allData.lastWeekArray
            this.preOrNextDate = allData.newDay
            this.weekTitle = moment(this.listdataWeek[0].data).format('MM月DD日') + '-' + moment(this.listdataWeek[this.listdataWeek.length - 1].data).format('MM月DD日')
            for (let i = 0; i <= this.listdataWeek.length - 1; i++) {
                this.WeekArray[i].date = this.listdataWeek[i].month + '月' + this.listdataWeek[i].date + '日'
                this.WeekArray[i].dataValue = this.listdataWeek[i].data
            }
            this.getWeekListData(this.listdataWeek[0].data, this.listdataWeek[this.listdataWeek.length - 1].data, this.listdataWeek)
        },
        nextWeek(val) {
            if (this.chooseDate && this.chooseDate.indexOf('-') > -1) {
                this.nowYear = this.chooseDate.split('-')[0] + '年'
            }
            let allData = ''
            if (val) {
                allData = calendarUtil.getNextWeekDayA(val)
            } else {
                allData = calendarUtil.getNextWeekDayA(this.chooseDate)
            }
            this.listdataWeek = allData.nextWeekArray
            this.preOrNextDate = allData.newDay
            this.weekTitle = moment(this.listdataWeek[0].data).format('MM月DD日') + '-' + moment(this.listdataWeek[this.listdataWeek.length - 1].data).format('MM月DD日')
            for (let i = 0; i <= this.listdataWeek.length - 1; i++) {
                this.WeekArray[i].date = this.listdataWeek[i].month + '月' + this.listdataWeek[i].date + '日'
                this.WeekArray[i].dataValue = this.listdataWeek[i].data
            }
            this.getWeekListData(this.listdataWeek[0].data, this.listdataWeek[this.listdataWeek.length - 1].data, this.listdataWeek)
        },
        preDay(val) {
            let year = ''
            let month = ''
            let day = ''
            if (val) {
                year = new Date(val).getFullYear()
                month = new Date(val).getMonth() + 1
                day = new Date(val).getDate()
            } else {
                year = new Date(this.chooseDate).getFullYear()
                month = new Date(this.chooseDate).getMonth() + 1
                day = new Date(this.chooseDate).getDate()
            }
            if (day > 1) {
                day--
            } else if (day == 1) {
                month--
                day = new Date(year, month, 0).getDate()
            }
            if (month <= 1) {
                this.listData = calendarUtil.createNowMonthDate(--year, '12')
                this.preOrNextDay = year + '-' + '12-31'
                this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.preOrNextDay)
                this.currentTime = year + '-' + 12
                this.dateTile = moment(this.preOrNextDay).format('YYYY年MM月DD日')
                this.MonthTitle = moment(this.currentTime).format('YYYY年MM月')
            } else {
                this.listData = calendarUtil.createNowMonthDate(year, month < 10 ? '0' + month : month)
                this.preOrNextDay = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day)
                this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.preOrNextDay)
                this.currentTime = year + '-' + (month < 10 ? '0' + month : month)
                this.dateTile = moment(this.preOrNextDay).format('YYYY年MM月DD日')
                this.MonthTitle = moment(this.currentTime).format('YYYY年MM月')
            }
        },
        nextDay(val) {
            let year = ''
            let month = ''
            let dayNum = ''
            let day = ''
            if (val) {
                year = new Date(val).getFullYear()
                month = new Date(val).getMonth() + 1
                day = new Date(val).getDate()
                dayNum = new Date(year, month, 0).getDate()
            } else {
                year = new Date(this.chooseDate).getFullYear()
                month = new Date(this.chooseDate).getMonth() + 1
                day = new Date(this.chooseDate).getDate()
                dayNum = new Date(year, month, 0).getDate()
            }
            if (day < dayNum) {
                day++
            } else if (day == dayNum) {
                month++
                day = 1
            }
            if (month >= 12) {
                this.listData = calendarUtil.createNowMonthDate(++year, '01')
                this.preOrNextDay = year + '-' + '01' + '-' + (day < 10 ? '0' + day : day)
                this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.preOrNextDay)
                this.currentTime = year + '-' + '01'
                this.MonthTitle = moment(this.currentTime).format('YYYY年MM月')
                this.dateTile = moment(this.preOrNextDay).format('YYYY年MM月DD日')
            } else {
                this.listData = calendarUtil.createNowMonthDate(year, month < 10 ? '0' + month : month)
                this.preOrNextDay = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day)
                this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.preOrNextDay)
                this.currentTime = year + '-' + (month < 10 ? '0' + month : month)
                this.MonthTitle = moment(this.currentTime).format('YYYY年MM月')
                this.dateTile = moment(this.preOrNextDay).format('YYYY年MM月DD日')
            }
        },
        preMonth() {
            let year = new Date(this.currentTime).getFullYear()
            let month = new Date(this.currentTime).getMonth() + 1
            if (month <= 1) {
                this.listData = calendarUtil.createNowMonthDate(--year, '12')
                this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.currentTime)
                this.currentTime = year + '-' + 12
                this.MonthTitle = moment(this.currentTime).format('YYYY年MM月')
            } else {
                this.listData = calendarUtil.createNowMonthDate(year, --month < 10 ? '0' + month : month)
                this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.currentTime)
                this.currentTime = year + '-' + (month < 10 ? '0' + month : month)
                this.MonthTitle = moment(this.currentTime).format('YYYY年MM月')
            }
        },
        nextMonth() {
            let year = new Date(this.currentTime).getFullYear()
            let month = new Date(this.currentTime).getMonth() + 1
            if (month >= 12) {
                this.listData = calendarUtil.createNowMonthDate(++year, '01')
                this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.currentTime)
                this.currentTime = year + '-' + '01'
                this.MonthTitle = moment(this.currentTime).format('YYYY年MM月')
            } else {
                this.listData = calendarUtil.createNowMonthDate(year, ++month < 10 ? '0' + month : month)
                this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.currentTime)
                this.currentTime = year + '-' + (month < 10 ? '0' + month : month)
                this.MonthTitle = moment(this.currentTime).format('YYYY年MM月')
            }
        }
    }
}
</script>
<style lang="scss">
.pro-detail {
    border-radius: 8px !important;
}
</style>
<style scoped lang="scss">
.potop {
    width: 100%;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    .left-radius {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 5px;
    }
}
@import '~@/wapp/common/styles/variables.module.scss';
.calendar-detail {
    background-color: #f0f3f8;
    // height: calc(100vh - 60px);
    overflow-y: auto;
    padding-right: 0;
    margin-bottom: 10px;
    margin-right: 3px;
    .myApp {
        .useapp {
            font-size: 16px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #333333;
        }
        #addApp {
            .custom {
                position: absolute;
                right: 30px;
                width: 79px;
                line-height: 32px;
                text-align: center;
                border-radius: 16px;
                border: 1px solid #0ea04d;
                font-size: 13px;
                font-family: MicrosoftYaHei;
                color: #0ea04d;
                cursor: pointer;
            }
            .savebtn {
                position: absolute;
                right: 30px;
            }
        }
        .text-c {
            text-align: center;
        }
        .mydesc {
            font-size: 14px;
            color: #333;
            margin-top: 9px;
        }
    }
    // background: #f3f6f9;
    width: 100%;
    margin: 0 auto;
    display: flex;
    min-height: 591.6px;
    // height: calc(100vh - 130px);
    .left-content {
        width: 100%;
        height: 100%;
        background: white;
        position: relative;
        padding-bottom: 10px;
        .gray-line {
            position: absolute;
            z-index: 100;
            width: 10px;
            height: 100%;
            background: #f2f6f9;
            top: 0;
            right: -10px;
        }
        .left-top {
            margin: 0 auto;
            width: 98%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .time-title {
                display: flex;
                position: relative;
                span {
                    height: 26px;
                    width: 83px;
                    text-align: center;
                    cursor: pointer;
                    line-height: 26px;
                    background: #f9f8f8;
                    color: #24292b;
                }
                .spanActive {
                    background: var(--bgColor);
                    border-radius: 4px;
                    color: white;
                }
            }
            .oa-title {
                display: flex;
                // width: 143px;
                width: 350px;
                justify-content: space-around;
                span {
                    width: 28px;
                    height: 28px;
                    background: #f9f8f8;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
                .left-o:hover {
                    background: var(--bgColor);
                    color: white;
                }
                .right-o:hover {
                    background: var(--bgColor);
                    color: white;
                }
                .center-o:hover {
                    background: var(--bgColor);
                    color: white;
                }
                .toDay {
                    width: 76px;
                    height: 28px;
                    background: #f9f8f8;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }
        }
        .month-box {
            width: 98%;

            position: relative;
            margin: 0 auto;
            // border: 1px solid #EBEBEB;
            border-radius: 8px;
            .month-week {
                width: 100%;
                height: 68px;
                background: #f2f5fb;
                border: 1px solid #ebebeb;
                border-bottom: none;
                display: flex;
                font-size: 14px;
                font-weight: bold;
                div {
                    height: 100%;
                    cursor: pointer;
                    width: 14.28%;
                    text-align: center;
                    line-height: 68px;
                    border-right: 1px solid #ebebeb;
                }
                // &:last-child(){
                //   border-right: none;
                // }
            }
            .month-week:last-child {
                border-right: none;
            }
            .month-table {
                width: 100%;

                border: 1px solid #ebebeb;
                border-collapse: collapse;
                tr {
                    td {
                        width: 14.28%;
                        height: 88px;
                        position: relative;
                        .month-item {
                            width: 100%;
                            height: 100%;
                            font-size: 14px;
                            cursor: pointer;
                            .item-first {
                                margin-bottom: 6px;
                                display: flex;
                                padding-top: 5px;
                                justify-content: space-between;
                            }
                            .item-second {
                                width: 98px;
                                text-align: center;
                                height: 20px;
                                line-height: 20px;
                                color: var(--bgColor);
                                background: rgba(47, 71, 126, 0.06);
                                margin: 0 auto;
                                margin-top: 10px;
                            }
                        }
                    }
                    .monthChose {
                        background: rgba(47, 71, 126, 0.06);
                        color: var(--bgColor);
                    }
                }
            }
        }
        .week-box {
            width: 98%;
            height: calc(100% - 70px);
            position: relative;
            margin: 0 auto;
            //border: 1px solid #EBEBEB;
            border-radius: 8px;
            .week-content {
                // height: calc(100% - 76px);
                width: 100%;
                border: 1px solid #ebebeb;
                border-right: none;
                border-top: none;
                border-radius: 0 0 8px 8px;
                overflow-y: scroll;
            }
            .week-content::-webkit-scrollbar {
                display: none;
            }
            .week-left {
                height: 70px;
                width: 60px;
                border-radius: 8px 0 0 0;
            }
            .week-top {
                width: calc(100% - 60px);
                height: 68px;
                border-radius: 0 8px 0 0;
                display: flex;
                font-size: 14px;
                font-weight: bold;
                div {
                    height: 100%;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 14.28%;
                    box-sizing: border-box;
                    text-align: center;
                    //line-height: 68px;
                    border-left: 1px solid #ebebeb;
                }
            }
        }
        .content-box {
            width: 100%;
            height: calc(100% - 70px);
            position: relative;
            margin: 0 auto;
            border: 1px solid #ebebeb;
            border-left: none;
            border-right: none;
            overflow-y: scroll;
            .shu {
                position: absolute;
                width: 1px;
                height: 10px;
                left: 59.5px;
                top: 0;
                background: #ebebeb;
            }
        }
        .content-box::-webkit-scrollbar {
            display: none;
        }
    }
    .right-content {
        width: 400px;
        margin-left: 10px;
        padding: 6px 0;
        background: white;
        height: 100%;
        position: relative;
        // .gray-line{
        //   position: absolute;
        //   z-index: 2222;
        //   width: 10px;
        //   height: 760px;
        //   background: #f2f6f9;
        //   top: 0;
        //   left: -10px;
        // }
    }
}
.top {
    margin: 0 auto;
    width: 100%;
    .top-button {
        width: 100%;
        height: 60px;
        padding: 0px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-title {
            font-size: 16px;
            font-weight: bold;
            color: #24292b;
        }
        .right-button {
            height: 100%;
            width: 26%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            span {
                width: 28px;
                height: 28px;
                background: #f9f8f8;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            .left-o:hover {
                background: var(--bgColor);
                color: white;
            }
            .right-o:hover {
                background: var(--bgColor);
                color: white;
            }
        }
    }
}
.table-box {
    padding: 0 15px;
    width: 100%;
    border-spacing: inherit;
    td {
        height: 34px;
        width: 38px;
        border-radius: 8px;
        text-align: center;
        color: black;
        font-weight: bold;
        font-size: 14px;
        position: relative;
        .box-div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;
            height: 100%;
            border-radius: 8px;
            &:hover {
                cursor: pointer;
                background: #dcebfd;
            }
        }
        .nowDay {
            color: white;
            background: var(--bgColor);
        }
        .nowDay .weekData {
            color: white;
            opacity: 1;
        }
        .notCurrentMonth {
            color: rgba(0, 0, 0, 0.4);
            position: relative;
        }
        .select {
            background: var(--bgColor);
            color: white;
        }
        .select .weekData {
            color: white;
            opacity: 1;
        }
    }
}
.weekData {
    color: rgba(0, 0, 0, 0.45);
    opacity: 0.6;
}
.festivalData {
    color: rgba(240, 36, 36, 0.45);
}
.wnl {
    padding: 0 15px;

    .title {
        height: 48px;
        line-height: 48px;
        font-size: 14px;
    }
    .wnl-content {
        height: 34px;
        font-size: 12px;
        background: #f9f8f8;
        border-radius: 4px;
        padding-left: 18px;
        line-height: 34px;
    }
}
.dsj {
    padding: 10px 15px;
    font-size: 12px;
    .title {
        height: 34px;
        line-height: 34px;
    }
    .dsj-content {
        margin-bottom: 10px;
        position: relative;
        height: 50px;
        padding: 4px 20px;
        background: #f9f8f8;
        border-left: 1px solid var(--bgColor);
        display: flex;
        align-items: center;
        span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}
.flhd {
    padding: 0 13px 0 13px;
    font-size: 12px;
    .title {
        height: 34px;
        line-height: 34px;
    }
    .flhd-content {
        background: #f9f8f8;
        position: relative;
        border-radius: 4px;
        margin-bottom: 12px;
        height: 50px;
        display: flex;
        align-items: center;
        position: relative;
        .span-status {
            position: absolute;
            width: 52px;
            right: 0;
            top: -9px;
            font-size: 12px;
            text-align: center;
            line-height: 20px;
            height: 20px;
            border-radius: 4px;
        }
        .color-b {
            color: #12b5a7;
            background: #f0faf9;
        }
        .color-a {
            color: #f76400;
            background: rgba(255, 191, 146, 0.22);
        }
        img {
            width: 31px;
            height: 31px;
            margin-left: 10px;
            border-radius: 4px;
        }
    }
}
@media screen and (max-width: 1600px) {
    .gray-line {
        position: absolute;
        z-index: 100;
        width: 10px;
        height: 100%;
        background: #f2f6f9;
        top: 0;
        right: -10px;
    }
}

// @media screen and (min-width: 1920px) {
//   .gray-line{
//           position: absolute;
//           z-index: 100;
//           width: 10px;
//           height: calc(100vh - 132px);
//           background: #f2f6f9;
//           top: 0;
//           left: -10px;
//         }
// }
@media screen and (min-width: 1600px) {
    .calendar-detail {
        width: 100%;
        //height: calc(100vh - 130px);
        min-height: 591.6px;
        margin: 0 auto;
        display: flex;
        .left-content {
            background: white;
            width: 100%;
            height: 100%;
            position: relative;
            .gray-line {
                position: absolute;
                z-index: 100;
                width: 10px;
                height: 100%;
                background: #f2f6f9;
                top: 0;
                right: -10px;
            }
            .left-top {
                margin: 0 auto;
                width: 98%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .time-title {
                    display: flex;
                    position: relative;
                    span {
                        height: 26px;
                        width: 83px;
                        text-align: center;
                        cursor: pointer;
                        line-height: 26px;
                        background: #f9f8f8;
                        color: #24292b;
                    }
                    .spanActive {
                        background: var(--bgColor);
                        border-radius: 4px;
                        color: white;
                    }
                }
                .oa-title {
                    display: flex;
                    width: 350px;
                    justify-content: space-around;
                    span {
                        width: 28px;
                        height: 28px;
                        background: #f9f8f8;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }
                    .left-o:hover {
                        background: var(--bgColor);
                        color: white;
                    }
                    .right-o:hover {
                        background: var(--bgColor);
                        color: white;
                    }
                    .center-o:hover {
                        background: var(--bgColor);
                        color: white;
                    }
                    .toDay {
                        width: 76px;
                        height: 28px;
                        background: #f9f8f8;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }
                }
            }
            .month-box {
                width: 98%;

                position: relative;
                margin: 0 auto;
                // border: 1px solid #EBEBEB;
                border-radius: 8px;
                .month-week {
                    width: 100%;
                    height: 68px;
                    background: #f2f5fb;

                    border: 1px solid #ebebeb;
                    border-bottom: none;
                    display: flex;
                    font-size: 16px;
                    font-weight: bold;
                    div {
                        height: 100%;
                        cursor: pointer;
                        width: 14.28%;
                        text-align: center;
                        line-height: 68px;
                        border-right: 1px solid #ebebeb;
                    }
                }
                .month-week:last-child {
                    border-right: none;
                }
                .month-table {
                    width: 100%;
                    border: 1px solid #ebebeb;
                    border-collapse: collapse;
                    tr {
                        td {
                            width: 14.28%;
                            height: 118px;
                            position: relative;
                            .month-item {
                                width: 100%;
                                height: 100%;
                                font-size: 16px;
                                cursor: pointer;
                                .item-first {
                                    margin-bottom: 6px;
                                    display: flex;
                                    padding-top: 5px;
                                    justify-content: space-between;
                                }
                                .item-second {
                                    width: 96%;
                                    text-align: center;
                                    height: 26px;
                                    line-height: 26px;
                                    color: var(--bgColor);
                                    background: rgba(47, 71, 126, 0.06);
                                    margin: 0 auto;
                                    margin-top: 10px;
                                }
                            }
                        }
                        .monthChose {
                            background: rgba(47, 71, 126, 0.06);
                            color: var(--bgColor);
                        }
                    }
                }
            }
            .week-box {
                width: 98%;
                height: calc(100% - 100px);
                position: relative;
                margin: 0 auto;
                border-radius: 8px;
                .week-content {
                    // height: calc(100% - 76px);
                    width: 100%;
                    border: 1px solid #ebebeb;
                    border-right: none;
                    border-top: none;
                    border-radius: 0 0 8px 8px;
                    overflow-y: scroll;
                }
                .week-content::-webkit-scrollbar {
                    display: none;
                }
                .week-left {
                    height: 70px;
                    width: 60px;
                    border-radius: 8px 0 0 0;
                }
                .week-top {
                    width: calc(100% - 60px);
                    height: 68px;
                    border-radius: 0 8px 0 0;
                    display: flex;
                    font-size: 16px;
                    font-weight: bold;
                    div {
                        height: 100%;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 14.28%;
                        box-sizing: border-box;
                        text-align: center;
                        //line-height: 68px;
                        border-left: 1px solid #ebebeb;
                    }
                }
            }
            .content-box {
                width: 100%;
                height: calc(100% - 100px);
                position: relative;
                margin: 0 auto;
                border: 1px solid #ebebeb;
                border-left: none;
                border-right: none;
                overflow-y: scroll;
                .shu {
                    position: absolute;
                    width: 1px;
                    height: 10px;
                    left: 59.5px;
                    top: 0;
                    background: #ebebeb;
                }
            }
            .content-box::-webkit-scrollbar {
                display: none;
            }
        }
        .right-content {
            margin-left: 10px;
            width: 400px;
            height: 100%;
            background: white;
            padding: 24px 0px;
            position: relative;
            .gray-line {
                position: absolute;
                z-index: 100;
                width: 10px;
                height: 100%;
                background: #f2f6f9;
                top: 0;
                left: -10px;
            }
        }
    }
    .top {
        margin: 0 auto;
        width: 100%;
        .top-button {
            width: 100%;
            height: 60px;
            padding: 0px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left-title {
                font-size: 16px;
                font-weight: bold;
                color: #24292b;
            }
            .right-button {
                height: 100%;
                width: 26%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                span {
                    width: 28px;
                    height: 28px;
                    background: #f9f8f8;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
                .left-o:hover {
                    background: var(--bgColor);
                    color: white;
                }
                .right-o:hover {
                    background: var(--bgColor);
                    color: white;
                }
            }
        }
    }
    .top .table-box td {
        height: 44px;
        width: 38px;
        border-radius: 10px;
        text-align: center;
    }
    .wnl {
        padding: 0 15px;

        .title {
            height: 48px;
            font-size: 16px;
            line-height: 48px;
        }
        .wnl-content {
            height: 42px;
            background: #f9f8f8;
            border-radius: 4px;
            font-size: 14px;
            padding-left: 18px;
            line-height: 42px;
        }
    }
    .dsj {
        padding: 10px 15px;
        font-size: 16px;
        .title {
            height: 34px;
            line-height: 34px;
        }
        .dsj-content {
            position: relative;
            height: 72px;
            padding: 7px 20px;
            background: #f9f8f8;
            border-left: 1px solid var(--bgColor);
            display: flex;
            align-items: center;
            span {
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
    .flhd {
        padding: 10px 13px 0 13px;
        font-size: 16px;
        .title {
            height: 34px;
            line-height: 34px;
        }
        .flhd-content {
            background: #f9f8f8;
            position: relative;
            border-radius: 4px;
            margin-bottom: 12px;
            height: 72px;
            display: flex;
            align-items: center;
            position: relative;
            .span-status {
                position: absolute;
                width: 52px;
                right: 0px;
                top: -9px;
                font-size: 12px;
                text-align: center;
                line-height: 20px;
                height: 20px;
                border-radius: 4px;
            }
            .color-b {
                color: #12b5a7;
                background: #f0faf9;
            }
            .color-a {
                color: #f76400;
                background: rgba(255, 191, 146, 0.22);
            }
            img {
                width: 48px;
                height: 48px;
                margin-left: 10px;
                border-radius: 4px;
            }
        }
    }

    .calendar-detail::-webkit-scrollbar {
        width: 0px;
    }
    .calendar-detail::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
    }
    .calendar-detail::-webkit-scrollbar-track {
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
    }
}
</style>
