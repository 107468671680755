<template>
    <div class="personal-duty-table">
        <div class="month-cell">
            <div class="month-cell_font">日期：</div>
            <div class="month-cell_date-box">
                <el-date-picker v-model="row.dutyDate" class="riqi" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd HH:mm:ss" disabled></el-date-picker>
            </div>
        </div>
        <el-table :data="tableData" :highlight-current-row="false" style="width: 100%" :header-cell-style="{ background: '#fff' }">
            <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
            <el-table-column prop="xs" label="学生" width="140" align="center">
                <template slot-scope="scope">
                    <div @click="selRow(scope.$index)">
                        <form-item
                            :ref="'scoreUserName' + scope.$index"
                            :field="{
                                defaultValue: null,
                                elementCanEdit: '{${canEdit}}',
                                elementCssHeight: null,
                                elementCssWidth: null,
                                elementFormatter: null,
                                elementReadonly: 0,
                                elementType: 'input',
                                emptyMessage: '',
                                errorMessage: null,
                                isRequired: 0,
                                onClickEvent: null,
                                passenger: {},
                                propComment: '学生姓名',
                                propName: 'scoreUserName',
                                propType: 'String',
                                textFormatter: 'textfield',
                                valueMaxLength: 100,
                                valueMinLength: null,
                                tbFieldName: 'scoreUserName',
                                model: 'vo.scoreUserName',
                                option: [],
                                formTdAlign: 'text-left',
                                dialog: 1,
                                dialogType: 'listpage',
                                dialogListviewurl: 'bus.student.list',
                                formTdColspan: 24
                            }"
                            :vo="vo"
                            :can-edit="canEdit"
                        />
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="ss" label="宿舍" width="110" align="center">
                <template slot-scope="scope">{{ tableData[scope.$index].roomNo }}</template>
            </el-table-column>
            <el-table-column prop="bj" label="班级" width="105" align="center">
                <template slot-scope="scope">{{ tableData[scope.$index].gradeClass }}</template>
            </el-table-column>

            <el-table-column prop="val" label="早上迟出" width="110" align="center">
                <template slot-scope="scope">
                    <el-input-number v-model="tableData[scope.$index].morningDeduct" size="mini" :min="0" :disabled="!canEdit"></el-input-number>
                </template>
            </el-table-column>

            <el-table-column prop="val" label="午睡" width="110" align="center">
                <template slot-scope="scope">
                    <el-input-number v-model="tableData[scope.$index].noonDeduct" size="mini" :min="0" :disabled="!canEdit"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column prop="val" label="晚睡" width="110" align="center">
                <template slot-scope="scope">
                    <el-input-number v-model="tableData[scope.$index].nightDeduct" size="mini" :min="0" :disabled="!canEdit"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column prop="val" label="迟到" width="110" align="center">
                <template slot-scope="scope">
                    <el-input-number v-model="tableData[scope.$index].lateDeduct" size="mini" :min="0" :disabled="!canEdit"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column prop="val" label="违纪" width="110" align="center">
                <template slot-scope="scope">
                    <el-input-number v-model="tableData[scope.$index].breachDeduct" size="mini" :min="0" :disabled="!canEdit"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column prop="bz" label="备注" align="center">
                <template slot-scope="scope">
                    <el-input v-model="tableData[scope.$index].memo" placeholder="请输入" :disabled="!canEdit"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="110">
                <template slot-scope="scope">
                    <el-button size="mini" type="danger" :disabled="!canEdit" @click="deleteRow(scope.$index)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- <div class="btn-box">
      <el-button type="primary" :disabled="!canEdit" @click="addNewTheme">新增</el-button>
    </div> -->
    </div>
</template>

<script>
export default {
    props: {
        row: null,
        updateId: null,
        canEdit: null
    },

    data() {
        return {
            currentSelIndex: 0,
            value1: '',
            tableData: []
        }
    },
    watch: {
        canEdit(val) {
            if (val === true) {
                console.log('valvalvlavlalvalvalvlalvlalvllalvlalval', val)
                this.$nextTick(() => {
                    this.updateInputData()
                })
            }
        }
    },
    mounted() {
        this.$http({
            method: 'get',
            url: '/bus/buildingRoomAttendance/getById',
            params: {
                id: this.updateId,
                date: this.row.dutyDate
            }
        }).then((res) => {
            console.log('获取到更新数据了', res)
            if (res.code === 'success') {
                this.tableData = res.body.vo
                if (this.updateId?.slice(0, 7) === 'classId') {
                    this.tableData.forEach((item) => {
                        item.gradeClass = this.row.gradeClass
                    })
                }
                this.$nextTick(() => {
                    this.updateInputData()
                })
                console.log(res)
            }
        })

        this.$bus.on('updateDuty', this.updateForService)
    },
    beforeDestroy() {
        console.log('快注销了')
        this.$bus.off('updateDuty', this.updateForService)
    },
    methods: {
        updateForService() {
            this.$http({
                method: 'post',
                url: '/bus/buildingRoomAttendance/updateAttendance',
                data: this.tableData
            }).then((res) => {
                console.log('获取到更新数据了', res)
                if (res.code === 'success') {
                    this.$bus.emit('dialogClose')
                }
            })
        },
        addNewTheme() {
            if (this.tableData.length === 0 || this.tableData.at(-1).ownerName !== '') {
                this.tableData.push({
                    classId: null,
                    classNo: null,
                    bedNo: null,
                    buildId: null,
                    gradeClass: null,
                    scoreUserId: null,
                    scoreUserName: '',
                    ownerName: '',
                    morningDeduct: 0,
                    noonDeduct: 0,
                    nightDeduct: 0,
                    lateDeduct: 0,
                    breachDeduct: 0,
                    roomId: null,
                    roomNo: null,
                    memo: null,
                    dutyDate: this.row.dutyDate,
                    scoreTime: ''
                })
            } else {
                this.$message.warning('请编辑数据后再新增数据')
            }
        },
        onSelectListpageRowCompleted(studentInfo) {
            this.tableData[this.currentSelIndex].classId = studentInfo[0].classId
            this.tableData[this.currentSelIndex].classNo = studentInfo[0].classNo
            this.tableData[this.currentSelIndex].gradeClass = studentInfo[0].gradeClass
            this.tableData[this.currentSelIndex].bedNo = studentInfo[0].bedNo
            this.tableData[this.currentSelIndex].buildId = studentInfo[0].buildId
            this.tableData[this.currentSelIndex].scoreUserId = studentInfo[0].id
            this.tableData[this.currentSelIndex].ownerName = studentInfo[0].name
            this.$refs['scoreUserName' + this.currentSelIndex].formItem.value = studentInfo[0].name

            // this.vo.scoreUserName = studentInfo[0].name
            this.$http({
                method: 'post',
                url: '/bus/buildingRoom/getRoomByStudent',
                params: { studentID: studentInfo[0].id }
            }).then((res) => {
                if (res.body.vo.length === 0) {
                    this.tableData[this.currentSelIndex].roomId = ''
                    this.tableData[this.currentSelIndex].roomNo = ''
                } else {
                    this.tableData[this.currentSelIndex].roomId = res.body.vo[0].roomID
                    this.tableData[this.currentSelIndex].roomNo = res.body.vo[0].roomOn
                }
            })
        },
        selRow(row, column, cell, event) {
            this.currentSelIndex = row
        },
        deleteRow(index) {
            let ids = ''
            if (this.tableData[index].id) {
                ids = this.tableData[index].id
            }

            this.$http({
                method: 'delete',
                url: '/bus/buildingRoomAttendance/delete',
                params: {
                    ids: ids
                }
            }).then((res) => {
                if (res.code === 'success') {
                    console.log('获取到更新数据了', res)
                }
            })

            this.tableData.splice(index, 1)
            this.tableData.forEach((item, tableIndex) => {
                console.log(this.$refs['scoreUserName' + tableIndex].formItem.value)
                this.$refs['scoreUserName' + tableIndex].formItem.value = item.ownerName
            })
        },
        updateInputData() {
            this.tableData.forEach((item, tableIndex) => {
                console.log(this.$refs['scoreUserName' + tableIndex])
                this.$refs['scoreUserName' + tableIndex].formItem.value = item.ownerName
            })
        }
    }
}
</script>

<style scoped lang="scss">
td {
    border: 1px;
}
.personal-duty-table {
    padding-right: 2px;
}
div {
}

.month-cell {
    display: flex;
    height: 44px;
    border: 1px solid #e2e7ed !important;
    border-bottom: 0px !important;
    align-items: center;
    .month-cell_font {
        text-align: right;
        line-height: 44px;
        font-size: 13px;
        font-weight: 600;
        width: 190px;
        border-right: #e2e7ed 1px solid;
        text-rendering: optimizeLegibility;
        font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
        color: #000;
    }
    .month-cell_date-box {
        margin-left: 20px;
    }
}

.sel-group {
    display: flex;
    justify-content: space-around;
}
.el-select {
    width: 60px;
}

.el-input-number {
    width: 90px !important;
}

.btn-box {
    padding: 15px;
    position: relative;
    left: -2px;
    z-index: 999;
    background: #fff;
    display: flex;
    justify-content: center;
}
/deep/.el-table {
    border: 1px solid #eee !important;
}
/deep/.el-table__cell {
    border-right: 1px solid #eee !important;
}
</style>
