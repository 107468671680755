<template>
    <div class="app-wrapper" style="margin-top: 60px" :style="{ 'padding-left': layoutStyle === 1 ? '63px' : '0' }">
        <div class="main-page-panle">
            <!-- <el-scrollbar :native="false" tag="div">
      </el-scrollbar> -->
            <main v-loading="loading" class="page-container not-left_menu-page-container">
                <div class="app-container" :class="!proShow ? 'localApp' : ''" :style="{ padding: proShow ? '0px' : '1px 1px 10px 10px' }">
                    <div class="myApp system_out" :style="{ 'margin-top': !proShow ? '0px' : '', height: !proShow ? 'calc(100vh - 82px)' : 'calc(100vh - 300px)' }" style="padding-bottom: 0; margin-bottom: 10px; padding-top: 0px; position: relative">
                        <div class="lines"></div>
                        <div v-if="!loading" class="header">
                            <el-radio-group v-model="radio1" style="display: flex" @input="input">
                                <el-radio-button label="1" icon="el-icon-film"><i class="el-icon-film"></i></el-radio-button>
                                <el-radio-button label="2" icon="el-icon-menu"><i class="el-icon-menu"></i></el-radio-button>
                            </el-radio-group>
                            <div class="header-right">
                                <div v-if="organList.length >= 2 && !proShow" style="line-height: 36px">
                                    <span style="margin-left: 15px">
                                        <el-dropdown trigger="click" @command="switchUserOrgan" @visible-change="dowmFn">
                                            <span class="el-dropdown-link" style="cursor: pointer">
                                                {{ loginUser.orgName }}
                                                <i class="el-icon-arrow-down el-icon--right"></i>
                                            </span>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item v-for="item of organList" :key="item.code" :disabled="orgCode === item.code" :command="item.code">
                                                    {{ item.name }}
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </span>
                                </div>
                                <div v-else style="width: 220px"></div>
                                <el-input v-model="inputVal" placeholder="应用名称..." style="width: 220px" suffix-icon="el-icon-search el-icon--right" @change="changeFn">
                                    <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
                                </el-input>
                            </div>
                        </div>
                        <div v-if="!loading && hideCommon" style="margin-bottom: 36px; display: flex; justify-content: space-between">
                            <div style="display: inline" class="useapp">
                                <div style="display: flex; height: 34px; line-height: 34px">
                                    <span class="line" />
                                    <span class="typetitle">常用应用</span>
                                </div>
                            </div>
                            <div id="addApp" class="">
                                <div v-if="!isSetApp && !proShow" class="custom" @click="toDefault">自定义</div>
                                <div v-else class="savebtn">
                                    <span style="text-align: center; margin-right: 10px; display: inline-block; width: 79px; height: 36px; line-height: 36px; border-radius: 16px; border: 1px solid #0ea04d; color: #0ea04d; cursor: pointer" @click="clockFn">取消</span>
                                    <span style="text-align: center; display: inline-block; width: 79px; height: 36px; line-height: 36px; border-radius: 16px; border: 1px solid #0ea04d; color: #0ea04d; cursor: pointer" @click="saveCommonApp">保存</span>
                                </div>
                            </div>
                        </div>
                        <!-- 常用应用 -->
                        <div v-if="isisshow && hideCommon" class="system_box">
                            <div id="sortable" ref="sorTable" class="system_content">
                                <div v-if="activeBtn">
                                    <div style="display: flex; flex-wrap: wrap">
                                        <template v-for="(sys, index) in dataList">
                                            <div :id="sys.resId" :key="sys.resId" class="common_app sortList" style="margin-bottom: 40px" :class="{ hand: !isSetApp }">
                                                <div class="item">
                                                    <span v-if="isSetApp" class="app_check" style="background: #f75854" @click="checkedApps(sys, index)">
                                                        <i class="el-icon-minus" style="width: 100%; height: 100%" />
                                                    </span>
                                                    <div style="display: flex" @click="clickSysTem(sys)">
                                                        <!-- <div class="systeam_img"><img style="width: 100%;" :src="sys.sysLogo|| './images/w-imgs/wflow/'+sys.meta.icon+'.png'" @click="checkedApps(sys,index)"></div> -->
                                                        <div class="systeam_img"><img style="width: 100%" :src="baseUrl + '/base/sysSetup/downloadLogoBySysCode?sysCode=' + sys.passenger.sysCode" @click="checkedApps(sys, index)" /></div>
                                                        <el-popover v-show="sys.isShow" popper-class="application-popover-detail" placement="bottom-start" width="auto" trigger="hover" :content="sys.remark" :disabled="sys.remark ? false : true">
                                                            <template slot="reference">
                                                                <div class="systeam_title" @mouseenter="(e) => isShowToltip1(e, sys, index)">
                                                                    <div class="flowname">
                                                                        <span>{{ sys.resName }}</span>
                                                                    </div>
                                                                    <div style="font-size: 13px; font-family: MicrosoftYaHei; color: #999999; white-space: break-spaces; width: 150px; padding-top: 10px; display: -webkit-box; overflow: hidden; -webkit-line-clamp: 2; -webkit-box-orient: vertical">{{ sys.remark }}</div>
                                                                </div>
                                                            </template>
                                                        </el-popover>
                                                        <div v-show="!sys.isShow" class="systeam_title" @mouseenter="(e) => isShowToltip1(e, sys, index)">
                                                            <div class="flowname">
                                                                <span>{{ sys.resName }}</span>
                                                            </div>
                                                            <div style="font-size: 13px; font-family: MicrosoftYaHei; color: #999999; white-space: break-spaces; width: 150px; padding-top: 10px; display: -webkit-box; overflow: hidden; -webkit-line-clamp: 2; -webkit-box-orient: vertical">{{ sys.remark }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>

                                <div v-else style="display: flex; flex-wrap: wrap">
                                    <template v-for="(sys, index) in dataList">
                                        <div :id="sys.resId" :key="sys.resId" class="noimgallapp sortList">
                                            <div class="item">
                                                <span v-if="isSetApp" class="app_check" style="background: #f75854" @click="checkedApps(sys, index)">
                                                    <i class="el-icon-minus" />
                                                </span>
                                                <div class="centerstyle">
                                                    <div class="systeam_img" @click="clickSysTem(sys)"><img style="width: 100%" :src="baseUrl + '/base/sysSetup/downloadLogoBySysCode?sysCode=' + sys.passenger.sysCode" @click="checkedApps(sys, index)" /></div>
                                                    <div class="noimgtitle">
                                                        <div class="flowname">
                                                            <span>{{ sys.resName }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <!-- 全部应用 -->
                        <div v-if="!loading && !isAppType" style="margin-bottom: 36px; display: flex; justify-content: space-between">
                            <div style="display: inline" class="useapp">
                                <div style="display: flex; height: 34px; height: 34px; line-height: 34px">
                                    <span class="line" />
                                    <span class="typetitle">全部应用</span>
                                </div>
                            </div>
                        </div>
                        <div v-for="(items, indexa) in lastArr" :key="indexa">
                            <div v-if="!loading && isAppType" style="margin-bottom: 36px; display: flex; justify-content: space-between">
                                <div style="display: inline" class="useapp">
                                    <div style="display: flex; height: 34px; height: 34px; line-height: 34px">
                                        <span class="line" />
                                        <span class="typetitle">{{ items.name }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="system_box">
                                <div id="sortable" ref="sorTable" class="system_content">
                                    <div v-if="activeBtn" style="display: flex; justify-content: space-between">
                                        <template>
                                            <div class="content">
                                                <template v-for="(item, index) in items.listdata">
                                                    <div :id="item.resId" :key="item.resId" class="common_app" style="margin-bottom: 40px" :class="{ hand: !isSetApp }">
                                                        <div class="item">
                                                            <span v-if="isSetApp" class="app_check" :class="{ appactive: item.common }" @click="checkedApp(item, index)">
                                                                <i class="el-icon-plus" />
                                                            </span>
                                                            <div style="display: flex" @click="clickSysTem(item, index)">
                                                                <div v-if="item.resId === 'yygljsc'" class="systeam_img"><img style="width: 100%" :src="'./images/w-imgs/cockpit/' + item.resId + 'icon.png'" @click="checkedApp(item, index)" /></div>
                                                                <div v-else class="systeam_img"><img style="width: 100%" :src="baseUrl + '/base/sysSetup/downloadLogoBySysCode?sysCode=' + item.passenger.sysCode" @click="checkedApp(item, index)" /></div>
                                                                <el-popover v-show="item.isShow" popper-class="application-popover-detail" placement="bottom-start" width="auto" trigger="hover" :content="item.remark" :disabled="item.remark ? false : true">
                                                                    <template slot="reference">
                                                                        <div class="systeam_title" @mouseenter="(e) => isShowToltip(e, item, index)">
                                                                            <div class="flowname">
                                                                                <span>{{ item.resName }}</span>
                                                                            </div>
                                                                            <div style="font-size: 13px; font-family: MicrosoftYaHei; color: #999999; white-space: break-spaces; width: 150px; padding-top: 10px; display: -webkit-box; overflow: hidden; -webkit-line-clamp: 2; -webkit-box-orient: vertical">{{ item.remark }}</div>
                                                                        </div>
                                                                    </template>
                                                                </el-popover>
                                                                <div v-show="!item.isShow" class="systeam_title" @mouseenter="(e) => isShowToltip(e, item, index)">
                                                                    <div class="flowname">
                                                                        <span>{{ item.resName }}</span>
                                                                    </div>
                                                                    <div style="font-size: 13px; font-family: MicrosoftYaHei; color: #999999; white-space: break-spaces; width: 150px; padding-top: 10px; display: -webkit-box; overflow: hidden; -webkit-line-clamp: 2; -webkit-box-orient: vertical">{{ item.remark }}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </template>
                                    </div>
                                    <div v-else style="display: flex; justify-content: space-between">
                                        <template>
                                            <div class="content">
                                                <template v-for="(item, index) in items.listdata">
                                                    <div :key="item.id" class="noimgallapp">
                                                        <div class="item">
                                                            <span v-if="isSetApp" class="app_check" :class="{ appactive: item.common }" @click="checkedApp(item, index)">
                                                                <i class="el-icon-plus" />
                                                            </span>
                                                            <div class="centerstyle">
                                                                <div v-if="item.resId === 'yygljsc'" class="systeam_img"><img style="width: 100%" :src="'./images/w-imgs/cockpit/' + item.resId + 'icon.png'" @click="checkedApp(item, index)" /></div>
                                                                <div v-else class="systeam_img" @click="clickSysTem(item, index)"><img style="width: 100%" :src="baseUrl + '/base/sysSetup/downloadLogoBySysCode?sysCode=' + item.passenger.sysCode" @click="checkedApp(item, index)" /></div>
                                                                <div class="noimgtitle">
                                                                    <div class="flowname">
                                                                        <span>{{ item.resName }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import { uuid } from '@/global/utils/index.js'
import { mapGetters } from 'vuex'
import $ from 'jquery'
// import 'jquery-ui/ui/widgets/sortable'
export default {
    components: {},
    props: {
        style1: {
            type: String,
            default: 'padding-bottom:0;margin-bottom:10px;margin-top:75px;padding-top: 0;'
        },
        proShow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            curindex: 0,
            isSetApp: false,
            isShowOne: false,
            isisshow: true,
            loginUser: this.$store.getters.loginUser,
            organList: this.$store.getters.organList,
            sysType: [
                { value: 'XXMH', text: '信息门户' },
                { value: 'ZWBG', text: '政务办公' },
                { value: 'RSFW', text: '人事服务' },
                { value: 'GZXT', text: '工作协同' },
                { value: 'XXZX', text: '消息中心' },
                { value: 'XZFW', text: '行政服务' },
                { value: 'XXGX', text: '信息共享' },
                { value: 'XMGL', text: '项目管理' },
                { value: 'CWGL', text: '财务管理' }
            ],
            orgName: '',
            commonAppType: 'sysApp',
            sysObj: null,
            timeInterval: null,
            dataList: [], // 我的应用
            showApps: [],
            allApps: [],
            activeBtn: true,
            routes: this.$store.getters.routes,
            baseUrl: process.env.VUE_APP_BASE_API,
            loading: true,
            dataShow: false,
            inputVal: '',
            bigScreen: false,
            radio1: '1',
            typeData: [],
            appType: [],
            lastArr: [],
            hideCommon: true,
            layoutStyle: 0,
            copyLastArr: [],
            topMenus: [],
            isAppType: true
        }
    },
    computed: {
        orgCode() {
            return this.loginUser.orgCode
        },
        ...mapGetters(['routes'])
    },
    watch: {},
    created() {
        if (this.$route.meta.params && this.$route.meta.params.indexOf('isAppShow=true') > -1) {
            this.hideApp()
        }

        if (this.$route.meta.params && this.$route.meta.params.indexOf('isAppType=false') > -1) {
            this.isAppType = false
        }
        this.baseUrl = process.env.VUE_APP_BASE_API
        this.layoutStyle = window.layoutStyle
        this.getAppType()
        this.orgName = this.loginUser.orgName
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getInfo)
    },
    mounted() {
        setTimeout(() => {
            this.getInfo()
        }, 500)
        window.addEventListener('resize', this.getInfo)
    },
    methods: {
        // 获取参数配置(应用类型)
        getAppType() {
            const data = [
                { compare: '=', field: 'orgCode', merge: true, value: 'plat' },
                { compare: '=', field: 'paramName', merge: true, value: 'app.busi.type' }
            ]
            this.$http({
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: data,
                url: 'base/paramConfig/querydata'
            }).then((res) => {
                if (res.code === 'success') {
                    this.typeData = res.body.listdata.length > 0 ? res.body.listdata[0] : []
                    this.appType = this.typeData.paramValue.split('；')
                    this.findCommonApp()
                }
            })
        },
        getInfo() {
            const dom = document.querySelector('.system_out').children
            let heights = 92
            for (var i = 1; i < dom.length; i++) {
                heights += Number(dom[i].clientHeight)
            }
            if (!this.proShow) {
                if (heights > document.querySelector('.system_out').clientHeight) {
                    document.querySelector('.localApp').style.padding = '1px 0 10px 10px '
                } else {
                    document.querySelector('.localApp').style.padding = '10px'
                }
            }
        },
        changeFn(val) {
            if (val) {
                if (this.copyLastArr.length > 0) {
                    this.lastArr = JSON.parse(JSON.stringify(this.copyLastArr))
                    this.lastArr = this.lastArr.filter((item) => {
                        item.listdata = item.listdata.filter((item) => item.resName.includes(val))
                        return item.listdata.length > 0
                    })
                }
                this.hideCommon = false
                // if (this.dataList.length > 0) {
                //     this.dataList = this.dataList.filter(item => item.resName.includes(val))
                // }
            } else {
                this.findCommonApp()
            }
        },
        hideApp() {
            const topMenus = this.routes.filter((item) => {
                if (item.meta && item.meta.level === 1 && item.meta.topFlag === 1) {
                    return true
                }
            })
            this.topMenus = topMenus
        },
        dowmFn(val) {
            if (val) {
                $('.el-icon-arrow-down').css({
                    transform: 'rotate(180deg)'
                })
            } else {
                $('.el-icon-arrow-down').css({
                    transform: 'rotate(0deg)'
                })
            }
        },
        switchUserOrgan(orgCode) {
            this.$http({
                method: 'GET',
                url: 'base/sysSetup/switchOrgLogin',
                params: { orgCode: orgCode },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                if (response.code === 'success') {
                    this.$store
                        .dispatch('user/tokenSsoLogin', response.body)
                        .then(async (res) => {
                            await this.$store.dispatch('route/updateRoutes', [])
                            await this.$store.dispatch('tagsView/delAllViews')
                            var url = location.href.split('#')
                            location.replace(url[0])
                            // this.$router.push({ path: '/', query: this.otherQuery })
                            this.$bus.emit('getWaterMarkConfig')
                        })
                        .catch((error) => {
                            this.$message({ message: error.msg, type: 'error' })
                        })
                } else {
                    this.$message({ message: response.message, type: 'error' })
                }
            })
        },
        textRange(e, row) {
            const TemporaryTag = document.createElement('span') // 创建一个dom来装载文本原本的内容
            TemporaryTag.innerText = row.remark
            TemporaryTag.className = 'getTextWidth'
            document.querySelector('body').appendChild(TemporaryTag)
            document.querySelector('.getTextWidth').style = 'font-size:14px'
            const currentWidth = document.querySelector('.getTextWidth').offsetWidth
            document.querySelector('.getTextWidth').remove()
            const cellWidth = e.target.offsetWidth
            /* 当文本宽度小于||等于容器宽度两倍时，代表文本显示未超过两行*/
            return currentWidth >= 2 * cellWidth
        },
        textRange1(e, row) {
            const TemporaryTag = document.createElement('span') // 创建一个dom来装载文本原本的内容
            TemporaryTag.innerText = row.remark
            TemporaryTag.className = 'getTextWidth'
            document.querySelector('body').appendChild(TemporaryTag)
            document.querySelector('.getTextWidth').style = 'font-size:14px'
            const currentWidth = document.querySelector('.getTextWidth').offsetWidth
            document.querySelector('.getTextWidth').remove()
            const cellWidth = e.target.offsetWidth
            /* 当文本宽度小于||等于容器宽度两倍时，代表文本显示未超过两行*/
            return currentWidth >= 2 * cellWidth
        },
        isShowToltip(e, row, index) {
            const bool = this.textRange(e, row)
            this.allApps[index].isShow = bool
            // this.dataList[index].isShow = bool
            this.$forceUpdate()
        },
        isShowToltip1(e, row, index) {
            const bool = this.textRange1(e, row)
            // this.allApps[index].isShow = bool
            this.dataList[index].isShow = bool
            this.$forceUpdate()
        },
        toDefault() {
            this.isSetApp = true
            this.isisshow = false

            this.$nextTick(() => {
                this.isisshow = true
                this.$nextTick(() => {
                    $('#sortable').sortable({
                        items: '.sortList'
                    })
                })
            })
        },
        format(list) {
            const data = []
            list.forEach((item) => {
                let flag = false
                this.topMenus.forEach((val) => {
                    if (val.meta.sysCode === item.passenger.sysCode) {
                        flag = true
                    }
                })
                if (!flag) {
                    data.push(item)
                }
            })
            return data
        },
        async findCommonApp() {
            this.dataList = []
            this.allApps = []
            this.lastArr = []
            this.hideCommon = true
            this.$http({
                url: 'user/commonApp/queryCommoneApps',
                method: 'POSt',
                contentType: 'multipart/form-data',
                params: {
                    queryAll: 1,
                    resType: this.commonAppType
                }
            }).then((res) => {
                if (res.code === 'success') {
                    this.dataList = res.body.commonApps
                    this.allApps = res.body.allCommonApps
                    if (this.topMenus.length > 0) {
                        this.dataList = this.format(this.dataList)
                        this.allApps = this.format(this.allApps)
                    }
                    //运营驾驶舱代码已删除，如项目上需使用可从yoa9.0代码上找 cockpit1 文件夹，
                    // const jscObj = {
                    //     resName: '运营驾驶舱',
                    //     remark: '运营管理驾驶舱',
                    //     resId: 'yygljsc',
                    //     busiType: 'GZXT',
                    //     orgCode: '',
                    //     passenger: {
                    //         sysCode: ''
                    //     }
                    // }
                    // this.allApps.push(jscObj)
                    const typeData = []
                    this.appType.forEach((item) => {
                        typeData.push({
                            value: item.split('：')[0],
                            text: item.split('：')[1]
                        })
                    })
                    let tempData = []
                    typeData.forEach((item) => {
                        tempData = []
                        this.allApps.forEach((val) => {
                            if (item.value === val.busiType) {
                                tempData.push(val)
                            }
                        })
                        this.lastArr.push({
                            name: item.text,
                            listdata: tempData
                        })
                    })
                    this.lastArr = this.lastArr.filter((item) => {
                        return item.listdata.length > 0
                    })
                    this.lastArr.forEach((item) => {
                        if (item.listdata.length > 1) {
                            item.listdata.sort(function (a, b) {
                                return a.orderNo - b.orderNo
                            })
                        }
                    })
                    this.copyLastArr = JSON.parse(JSON.stringify(this.lastArr))
                    console.log(this.lastArr, 'this.lastArr')
                    this.isShowOne = true
                    this.dataShow = true
                    this.getCommon()
                    this.$forceUpdate()
                    this.loading = false
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        getCommon() {
            this.allApps.forEach((item) => {
                if (item.status) {
                    item.common = true
                } else {
                    item.common = false
                }
            })
            this.dataList.forEach((item) => {
                if (item.status) {
                    item.common = true
                } else {
                    item.common = false
                }
            })
        },
        sortedArray: function () {
            // 比较函数，按 orderNo 属性正序排序
            function compare(a, b) {
                return a.orderNo - b.orderNo
            }

            // 调用 sort() 方法进行排序，并返回排序后的数组
            return this.dataList.sort(compare)
        },
        input(val) {
            if (val === '1') {
                this.activeBtn = true
            } else {
                this.activeBtn = false
            }
        },
        clockFn() {
            // this.toDefault()
            this.isSetApp = false
            this.findCommonApp()
            $('#sortable').sortable('disable')
        },
        // 全部应用  --勾选应用
        checkedApp(app, index) {
            // if (!this.isSetApp) return
            // const apps = this.allApps.filter(item => item.common)
            // if (apps.length >= 20 && !app.common) {
            //     this.$message.warning('常用应用最多可添加20个！')
            //     return
            // }
            // if (app.common) {
            //     this.$message.warning('已经是常用应用！')
            //     return
            // }
            // app.common = !app.common
            // this.dataList.push(app)
            if (!this.isSetApp) return
            if (this.dataList.length >= 20 && !app.common) {
                this.$message.warning('常用应用最多可添加20个！')
                return
            }
            if (app.common) {
                this.$message.warning('已经是常用应用！')
                return
            } else {
                app.common = !app.common
                this.dataList.push(app)
            }
        },
        // 常用应用  ---勾选应用
        checkedApps(app, index) {
            if (!this.isSetApp) return
            let ind = ''
            app.common = !app.common
            this.dataList.forEach((item, index) => {
                if (app.resId === item.resId) {
                    ind = index
                }
            })
            const element = this.allApps.find((item) => item.resId === app.resId)
            if (element) {
                element.common = !element.common // 修改 common 属性的值
            }
            this.dataList.splice(ind, 1)
        },

        // 保存应用
        saveCommonApp() {
            const moment = require('moment')
            const updatalist = []
            let order = 1000
            const showApps = []
            $('.sortList').each((i, item) => {
                const id = $(item).attr('id')
                this.dataList.forEach((apply, i) => {
                    if (apply.resId === id) {
                        showApps.push(apply)
                        order += 5
                        updatalist.push({
                            createTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss.S'),
                            id: uuid(),
                            orderNo: order,
                            orgCode: this.loginUser.orgCode,
                            orgName: this.loginUser.orgName,
                            ownerId: this.loginUser.personId,
                            remark: null,
                            resId: apply.resId,
                            resName: apply.resName,
                            resType: this.commonAppType,
                            status: 1
                        })
                    }
                })
            })
            this.allApps.forEach((apply, i) => {
                if (!apply.common && apply.defaultSubscribe === 1) {
                    showApps.push(apply)
                    order += 5
                    updatalist.push({
                        createTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss.S'),
                        id: uuid(),
                        orderNo: order,
                        orgCode: this.loginUser.orgCode,
                        orgName: this.loginUser.orgName,
                        ownerId: this.loginUser.personId,
                        remark: null,
                        resId: apply.resId,
                        resName: apply.resName,
                        resType: this.commonAppType,
                        status: 1
                    })
                }
            })

            var newArr = this.allApps.filter((v) => updatalist.every((val) => val.resId !== v.resId))
            newArr.forEach((apply, i) => {
                order += 5
                updatalist.push({
                    createTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss.S'),
                    id: uuid(),
                    orderNo: order,
                    orgCode: this.loginUser.orgCode,
                    orgName: this.loginUser.orgName,
                    ownerId: this.loginUser.personId,
                    remark: null,
                    resId: apply.resId,
                    resName: apply.resName,
                    resType: this.commonAppType,
                    status: 0
                })
            })
            this.$http({
                method: 'POST',
                url: 'user/commonApp/batchUpdate',
                data: updatalist
            }).then((res) => {
                if (res.code === 'success') {
                    this.showApps = showApps
                    // this.toDefault()
                    this.isSetApp = false
                    this.$message.success('常用应用保存成功！')
                    $('#sortable').sortable('disable')
                    this.findCommonApp()
                    this.$forceUpdate()
                } else {
                    this.$alert(res.message, '错误', { type: 'error' })
                }
            })
        },

        findChildren(routes, parentId) {
            const children = []
            routes.forEach((e) => {
                if (e.meta && e.meta.parentId === parentId) {
                    children.push(e)
                }
            })
            if (children.length > 0) {
                children.forEach((o) => {
                    if (o.children && o.children.length > 0) {
                        return
                    } else {
                        o.children = this.findChildren(this.routes, o.resId)
                    }
                })
            }
            return children
        },
        // 选择应用
        async clickSysTem(sys, index) {
            this.$bus.emit('closeDilog')
            if (this.layoutStyle === 1) {
                if (sys.resName !== '内部邮箱' && sys.resName !== '文字识别' && sys.resName !== '运营驾驶舱') {
                    this.$bus.emit('openSideBar', true)
                } else {
                    this.$bus.emit('openSideBar', false)
                    if (sys.resName === '内部邮箱') {
                        this.$bus.emit('enterEmailChangeSide', true)
                    }
                }
            } else {
                if (sys.resName === '内部邮箱') {
                    this.$bus.emit('enterEmailChangeSide', true)
                }
            }
            if (sys.indexUrl && (sys.indexUrl.indexOf('http://') > -1 || sys.indexUrl.indexOf('https://') > -1)) {
                window.open(sys.indexUrl)
                return
            }
            if (this.isSetApp) return
            if (sys.resId === 'yygljsc') {
                // 运营管理驾驶舱
                const routeUrl = this.$router.resolve('/yygljsc').href
                window.open(routeUrl, '_blank')
                return
            }
            if (sys.resName === '文字识别') {
                // "文字识别"
                const routeUrl = this.$router.resolve('/OCR').href
                window.open(routeUrl, '_blank')
                return
            }
            localStorage.setItem('sys-menuName', sys.resName)
            if (this.commonAppType === 'sysApp') {
                const children = this.findChildren(this.routes, sys.resId)
                if (children.length > 0) {
                    if (children[0].children[0].children) {
                        if (children[0].children[0].children[0].meta.activeMenu != null) {
                            this.href = children[0].children[0].children[0].meta.activeMenu + '?sys=' + sys.resName
                        }
                    } else {
                        if (children[0].children[0].meta.activeMenu != null) {
                            this.href = children[0].children[0].meta.activeMenu + '?sys=' + sys.resName
                        } else {
                            if (children[0].children[0].fullPath) {
                                this.href = children[0].children[0].fullPath + '?sys=' + sys.resName
                            } else {
                                this.href = children[0].children[0].path + '?sys=' + sys.resName
                            }
                        }
                    }

                    this.$router.push({ path: this.href })
                } else {
                    this.$message.warning('暂未开通此产品！')
                }
            } else if (this.commonAppType === 'wflowApp') {
                let href = '/wflow?busiCode=' + sys.meta.href + '&wfName=' + encodeURI(sys.meta.wfName) + '&startNodeName=' + encodeURI(sys.meta.wfNodeName)
                if (sys.meta.wfEngineUrl !== null && sys.meta.wfEngineUrl !== '') {
                    href += '&wfEngineUrl=' + encodeURIComponent(sys.meta.wfEngineUrl)
                }
                window.open('./#' + href)
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/wapp/common/styles/variables.module.scss';
// /deep/.el-input__inner{
//   &:hover{
//     border-color:var(--subjectColor)
//   }
//   &:focus{
//     border-color:var(--subjectColor)
//   }
// }
/deep/.el-input__suffix {
    color: #000;
}
.lines {
    position: absolute;
    height: 100%;
    width: 1px;
    background: #dee2e6;
    top: 0px;
    right: 0px;
}
.header {
    display: flex;
    height: 72px;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ebebeb;
    .header-right {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 78px);
    }
}
.hh {
    width: 500px;
}

.centerstyle {
    width: 100px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    &:hover {
        cursor: pointer;
        background-color: #eee;
        border-radius: 8px;
    }
}
.line {
    width: 3px;
    height: 18px;
    margin: 8px 3px 8px 0;
    background: var(--subjectColor);
    border-radius: 2px;
    display: inline-block;
}
.typetitle {
    // width: 64px;
    height: 19px;
    display: inline-block;
}
.app-container {
    background-color: #f0f3f8;
    padding: 10px 1px 10px 10px;
    // padding: 31px;
    min-height: calc(100vh - #{$headerHeightOuter});
    padding-top: 0;
    .myApp {
        .useapp {
            font-size: 16px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #333333;
        }
        #addApp {
            .custom {
                width: 79px;
                line-height: 32px;
                text-align: center;
                border-radius: 16px;
                border: 1px solid #0ea04d;
                font-size: 13px;
                font-family: MicrosoftYaHei;
                color: #0ea04d;
                cursor: pointer;
            }
        }
        .text-c {
            text-align: center;
        }
        .mydesc {
            font-size: 14px;
            color: #333;
            margin-top: 9px;
        }
    }
}
.noimgallapp {
    width: 150px;
    height: 66px;
    float: left;
    text-align: center;
    cursor: pointer;
    margin-bottom: 50px;
    .item {
        position: relative;
        .app_check {
            cursor: pointer;
            position: absolute;
            left: 20px;
            top: -2px;
            z-index: 10;
            width: 20px;
            height: 20px;
            border: 1px solid #d8dce5;
            border-radius: 50%;
            // background: #fff;
            color: #fff;
            background: #0ea04d;
            &.appactive {
                // background: var(--subjectColor);
                background: #b8e8cd;
                color: #fff;
                font-size: 17px;
            }
            &.appactive1 {
                // background: var(--subjectColor);
                background: #0ea04d;
                color: #fff;
                font-size: 17px;
            }
        }
        .flowname {
            padding-top: 5px;
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #000000;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
        }
    }
}
.hand {
    cursor: pointer;
}
.common_app {
    width: 280px;
    height: 76px;
    padding: 5px 10px;
    float: left;
    text-align: center;
    &:hover {
        // cursor: pointer;
        border-radius: 8px;
        background-color: #eee;
    }
    .item {
        position: relative;
        .app_check {
            cursor: pointer;
            position: absolute;
            // right: 15px;
            left: -6px;
            top: -2px;
            z-index: 10;
            width: 20px;
            height: 20px;
            border: 1px solid #d8dce5;
            border-radius: 50%;
            // background: #fff;
            color: #fff;
            background: #0ea04d;
            &.appactive {
                // background: var(--subjectColor);
                background: #b8e8cd;
                color: #fff;
                font-size: 17px;
            }
            &.appactive1 {
                // background: var(--subjectColor);
                background: #0ea04d;
                color: #fff;
                font-size: 17px;
            }
        }
        .flowname {
            padding-top: 5px;
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #000000;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
        }
    }
}
.systeam_img {
    cursor: pointer;
    padding-top: 5px;
    width: 56px;
    height: 56px;
    img {
        border-radius: 6px;
    }
}
.system_out::-webkit-scrollbar {
    width: 7px;
    background-color: rgba(144, 147, 153, 0.3);
}
.system_out::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #d3d5db;
}
.system_out::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}
.system_out {
    width: 100%;
    // height: calc(100vh - 90px);
    // height: calc(100vh - 82px);
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #dee2e6;
    .allapp {
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
    }
    .system_box {
        // padding-left: 28px;
        .system_title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
            color: #606266;
            display: inline-block;
            line-height: 2;
            margin-bottom: 10px;
            border-bottom: 3px solid var(--subjectColor);
        }
        &:last-child.system_title {
            margin-bottom: 0;
        }

        .system_content {
            margin-bottom: 14px;
            flex-wrap: wrap;
            .system_list {
                // width: 90px;
                display: flex;
                padding: 10px;
                text-align: center;
                border-radius: 5px;
                margin-right: 20px;
                cursor: pointer;
            }
            .move:hover {
                background: #eee;
                cursor: move;
            }
            // .system_list:not(:first-child){margin-left:20px;}
        }
    }
}
.systeam_title {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // margin-top:10px;
    padding-left: 10px;
    font-size: 14px;
    color: #333;
}
.noimgtitle {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // margin-top:10px;
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 14px;
    color: #333;
}
.main-page-panle {
    background-color: #f0f3f8;
    padding-top: 10px;
    z-index: 2000;
}
/deep/ .el-icon-minus {
    width: 100%;
    height: 100%;
    color: #fff;
}
.mainpage {
    background: #f0f3f8;
}
</style>
<style lang="scss">
/* 指示层 */
.application-popover-detail {
    height: 35px;
    padding: 8px 8px 0 8px;
    text-align: center;
    background: #303133 !important;
    font-size: 14px;
    color: #fff !important;
}
.el-popper[x-placement^='bottom'] .popper__arrow::after {
    //border-bottom-color: #303133 !important;
}
</style>
