<template>
  <div>
    <div style="padding: 10px">
      <table
        style="
          border-collapse: collapse;
          border: 1px solid #e2e7ed;
          width: 100%;
        "
      >
        <tr v-if="!isOne" style="height: 45px">
          <td
            style="
              border: 1px solid #e2e7ed;
              text-align: right;
              width: 200px;
              background: #eef0f3;
              padding: 0 10px;
              font-size: 13px;
              font-weight: 700;
            "
          >
            日期
          </td>
          <td style="border: 1px solid #e2e7ed; text-align: left">
            <el-date-picker
              v-model="value1"
              class="riqi"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </td>
          <td
            style="
              border: 1px solid #e2e7ed;
              text-align: right;
              width: 200px;
              background: #eef0f3;
              padding: 0 10px;
              font-size: 13px;
              font-weight: 700;
            "
          >
            学校
          </td>
          <td style="border: 1px solid #e2e7ed; text-align: left">
            <el-select v-model="valueSc" style="width:96%;" placeholder="请选择">
              <el-option
                v-for="item in optionsSc"
                :key="item.id"
                :label="item.schoolName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </td>
          <td
            style="
              border: 1px solid #e2e7ed;
              text-align: right;
              width: 200px;
              background: #eef0f3;
              padding: 0 10px;
              font-size: 13px;
              font-weight: 700;
            "
          >
            楼栋
          </td>
          <td style="border: 1px solid #e2e7ed; text-align: left">
            <el-select v-model="valueLd" style="width:96%;" placeholder="请选择">
              <el-option
                v-for="item in optionsLd"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr v-if="isOne" style="height: 45px">
          <td
            style="
              border: 1px solid #e2e7ed;
              text-align: right;
              width: 200px;
              background: #eef0f3;
              padding: 0 10px;
              font-size: 13px;
              font-weight: 700;
            "
          >
            日期
          </td>
          <td style="border: 1px solid #e2e7ed; text-align: left">
            <el-date-picker
              v-model="value1"
              class="riqi"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </td>
          <td
            style="
              border: 1px solid #e2e7ed;
              text-align: right;
              width: 200px;
              background: #eef0f3;
              padding: 0 10px;
              font-size: 13px;
              font-weight: 700;
            "
          >
            学校
          </td>
          <td style="border: 1px solid #e2e7ed; text-align: left">
            <el-select v-model="valueSc" placeholder="请选择">
              <el-option
                v-for="item in optionsSc"
                :key="item.id"
                :label="item.schoolName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr v-if="isOne" style="height: 45px">
          <td
            style="
              border: 1px solid #e2e7ed;
              text-align: right;
              width: 200px;
              background: #eef0f3;
              padding: 0 10px;
              font-size: 13px;
              font-weight: 700;
            "
          >
            年级
          </td>
          <td style="border: 1px solid #e2e7ed; text-align: left">
            <el-select v-model="valueA" placeholder="请选择">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td
            style="
              border: 1px solid #e2e7ed;
              text-align: right;
              width: 200px;
              background: #eef0f3;
              padding: 0 10px;
              font-size: 13px;
              font-weight: 700;
            "
          >
            班级
          </td>
          <td style="border: 1px solid #e2e7ed; text-align: left">
            <el-select v-model="valueB" placeholder="请选择">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr v-if="isOne" style="height: 45px; text-align: center">
          <td colspan="4" style="border-top: 1px solid #e2e7ed;">
            <div>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchClick()"
              >开始搜索</el-button>
              <el-button icon="el-icon-close" @click="cleanUp">清除搜索</el-button>
            </div>
          </td>
        </tr>
        <tr v-if="!isOne" style="height: 55px; text-align: center">
          <td colspan="6" style="border-top: 1px solid #e2e7ed;">
            <div>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchClick()"
              >开始搜索</el-button>
              <el-button icon="el-icon-close" @click="cleanUp">清除搜索</el-button>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="block">
      <div
        data-v-0e92aae7=""
        class="el-button-group"
        style="padding-left: 10px"
      >
        <button
          data-v-0e92aae7=""
          type="button"
          class="el-button el-button--default el-button--medium"
          @click="isTwoFn"
        >
          <!----><i class="el-icon-menu"></i><!---->
        </button>
        <button
          data-v-0e92aae7=""
          type="button"
          class="el-button el-button--default el-button--medium"
          @click="isOneFn"
        >
          <!----><i class="el-icon-film"></i><!----></button>

      </div>

      <div v-if="isOne" class="right">

        <div
          data-v-fd7a5c9c=""
          class="el-button-group toolbar-button-group el-popover__reference"
          aria-describedby="el-popover-4191"
          tabindex="0"
        >
          <button
            data-v-fd7a5c9c=""
            type="button"
            class="el-button el-button--default el-button--small is-plain plus_btn"
            @click="addForm1()"
          >
            <span><i data-v-fd7a5c9c="" class="el-icon-plus el_icon"></i><span data-v-fd7a5c9c="">出勤情况</span></span>
          </button>
          <button
            data-v-fd7a5c9c=""
            type="button"
            class="el-button el-button--default el-button--small is-plain plus_btn"
            @click="addForm2()"
          >
            <span><i data-v-fd7a5c9c="" class="el-icon-plus el_icon"></i><span data-v-fd7a5c9c="">早上迟出</span></span>
          </button>
          <button
            data-v-fd7a5c9c=""
            type="button"
            class="el-button el-button--default el-button--small is-plain import_btn"
            @click="importData"
          >
            <span>
              <svg
                data-v-5d08e6e2=""
                data-v-fd7a5c9c=""
                aria-hidden="true"
                class="svg_icon svg-icon"
              >
                <use data-v-5d08e6e2="" xlink:href="#icon-import" />
              </svg>
              <span data-v-fd7a5c9c="">导入</span>
            </span>
          </button>
          <button
            data-v-fd7a5c9c=""
            type="button"
            class="el-button el-button--default el-button--small is-plain export_btn"
            @click="exportData"
          >
            <span>
              <svg
                data-v-5d08e6e2=""
                data-v-fd7a5c9c=""
                aria-hidden="true"
                class="svg_icon svg-icon"
              >
                <use data-v-5d08e6e2="" xlink:href="#icon-export" />
              </svg>
              <span data-v-fd7a5c9c="">导出</span></span>
          </button>
        </div>
      </div>
    </div>

    <div v-if="isOne" class="table-box">
      <el-table
id="ystbMx"
                :data="chuqing"
                border
                :span-method="objectSpanMethod"
                style="width: 100%"
                @cell-click="openUpdateDialog"
      >
        <el-table-column
          prop="gradeClass"
          label="项目/班"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="roomNo" label="宿舍" width="100" align="center">
        </el-table-column>
        <el-table-column prop="ownerName" label="学生" width="150" align="center"> </el-table-column>
        <el-table-column prop="morningDeduct" label="早上迟出" width="150" align="center">
          <template slot-scope="scope">
            {{ scope.row.morningDeduct==undefined?0:scope.row.morningDeduct }}
          </template> </el-table-column>

        <el-table-column label="宿舍区" align="center">
          <el-table-column prop="noonDeduct" label="午睡" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.row.noonDeduct==undefined?0:scope.row.noonDeduct }}
            </template>
          </el-table-column>
          <el-table-column prop="nightDeduct" label="晚睡" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.row.nightDeduct==undefined?0:scope.row.nightDeduct }}
            </template>
          </el-table-column>
          <el-table-column prop="lateDeduct" label="迟到" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.row.lateDeduct==undefined?0:scope.row.lateDeduct }}
            </template>
          </el-table-column>
          <el-table-column prop="breachDeduct" label="违纪" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.row.breachDeduct==undefined?0:scope.row.breachDeduct }}
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column prop="address" label="扣分" width="100" align="center">
          <template slot-scope="scope">
            {{
              Number(scope.row.breachDeduct) +
                Number(scope.row.lateDeduct) +
                Number(scope.row.nightDeduct) +
                Number(scope.row.noonDeduct) +
                Number(scope.row.morningDeduct)

            }}
          </template>
        </el-table-column>
        <el-table-column prop="memo" label="备注">
        </el-table-column></el-table>
    </div>

    <div v-if="!isOne" class="table-box">
      <div class="content" style="overflow-x: auto;">

        <table v-if="suSheList.length>0" v-loading="loading" class="talleDiy" style="border-collapse: collapse;border: 1px solid #e2e7ed;width:100%;min-width: 1057px;">
          <tr v-for="(item,index) in suSheList" :key="index" style="border: 1px solid #e2e7ed;">
            <td style="width: 100px;min-width: 100px;height: 80px;background: #f5f7fa;">
              <div style="font-size: 16px;font-weight: 700;color: #333333;">{{ item.floorName }}{{ item.floor }}层</div>
            </td>
            <td v-for="(val,idx) in item.buildingRoomList" :key="idx">
              <div :class="val.passenger.scored?'reviewed':'not'" style="color: #fff;border-radius: 2px;padding: 8px 0;margin: 0 8px;cursor: pointer;" @click="addFormOne(val)">
                <div style="margin-bottom: 10px;">{{ val.roomNo }}</div>
                <div>
                  {{ val.passenger.scored?'已评':'未评' }}
                </div>
              </div>
            </td>

          </tr>
        </table>
        <div v-else style="text-align:center;border: 1px solid #f2f2f2;height: 200px;">
          <img :src="img" style="max-width:180px;margin-top: 40px;"><br>
          <span style="font-size:15px;color:#dbdbdb">暂无数据</span>
        </div>
      </div>

    </div>

    <Basicform
      :title="title"
      :win-width="moduleInfo.winWidth"
      :win-height="moduleInfo.winHeight"
      :on-basic-form-opened="onBasicFormOpened"
      :on-basic-form-before-submit="onBasicFormBeforeSubmit"
      :on-basic-form-submited="onBasicFormSubmited"
      :on-basic-form-before-open="onBasicFormBeforeOpen"
    >
      <template #default="slotProps">
        <busi-form
          ref="busiForm"
          style="border:none;"
          :vo="slotProps.vo"
          :can-edit="slotProps.canEdit"
          :module-info="moduleInfo"
        />
      </template>
    </Basicform>

    <!-- 更新弹窗 -->
    <el-dialog
      title="出勤情况"
      :visible.sync="dialogVisible"
    >
      <updateDutyTable v-if="dialogVisible&&!isOneA" :update-id="updateId" :row="updateRow" :can-edit="updateCanEdit" />
      <updateDutyTable1 v-if="dialogVisible&&isOneA" :update-id="updateId" :row="updateRow" :can-edit="updateCanEdit" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateBtnClick()">{{ btnTitle }}</el-button>
      </span>
    </el-dialog>
    <importCq />
  </div>
</template>

<script>
import updateDutyTable from './update-duty-table.vue'
import updateDutyTable1 from './update-duty-tableAll.vue'
import importCq from './import/importCq.vue'
export default {
    components: {
        updateDutyTable,
        updateDutyTable1,
        importCq
    },
    props: {

    },
    data() {
        return {
            isOne: false,
            img: './images/w-imgs/nomessage.png',
            suSheList: [],
            loginUser: this.$store.getters.loginUser,
            updateCanEdit: false,
            btnTitle: '修改',
            // 弹窗是否弹出
            dialogVisible: false,
            // 打开的表单标题
            title: '',
            moduleInfo: {
                winWidth: window.mobileWidth,
                winHeight: window.mobileHeight,
                listDataUrl: '/bus/buildingRoomAttendance/listdata',
                delUrl: '/bus/buildingRoomAttendance/delete',
                updateUrl: '/bus/buildingRoomAttendance/update',
                addNewUrl: '/bus/buildingRoomAttendance/addNew',
                insertUrl: '/bus/buildingRoomAttendance/insert',
                getByIdUrl: '/bus/buildingRoomAttendance/getById',
                deleteUrl: '/bus/buildingRoomAttendance/delete'
            },
            valueA: '全部',
            valueB: '全部',
            valueSc: '全部',
            valueLd: '全部',
            value1: new Date().toJSON().slice(0, 10),
            banji: '',
            options1: [
                { value: '高一', label: '高一' },
                { value: '高二', label: '高二' },
                { value: '高三', label: '高三' },
                { value: '全部', label: '全部' }
            ],
            options2: [{ value: '全部', label: '全部' }],
            optionsSc: [
                { schoolName: '全部', id: '全部' }
            ],
            optionsLd: [
                { name: '全部', id: '全部' }
            ],
            scores: [],
            arrList: [],
            listData: [],
            arr: [],
            arr2: [],
            chuqing: [],
            tableData: [],
            updateId: null,
            updateRow: null

        }
    },
    computed: {

    },
    watch: {
        dialogVisible(val) {
            if (val === false) {
                this.btnTitle = '修改'
            }
        },
        btnTitle(val) {
            val === '修改' ? (this.updateCanEdit = false) : (this.updateCanEdit = true)
        },
        valueA(val) {
            this.valueB = '全部'
            if (val === '全部') {
                this.options2 = [{ value: '全部', label: '全部' }]
                return
            }
            if (val) {
                this.$http({
                    method: 'post',
                    url: '/bus/buildingRoomScore/getGradeClass',
                    params: {
                        grade: this.valueA
                    }
                }).then((res) => {
                    console.log(21312, res)
                    if (res.code === 'success') {
                        console.log(val)
                        this.options2 = [{ value: '全部', label: '全部' }]
                        const arr = res.body.vo
                        if (arr.length > 0) {
                            arr.forEach((item) => {
                                this.options2.push({
                                    label: item + '班',
                                    value: item
                                })
                            })
                        }
                    }
                })
            }
        },
        valueSc(val) {
            if (val === '全部') {
                this.getLdAll()
            } else {
                this.getLd()
            }
        }
    },

    created() {
        this.getSchoolData()
        this.$bus.emit('showNavSearch', false)
        this.getLdAll()
    },
    beforeDestroy() {

    },
    mounted() {
        this.$bus.on('dialogClose', this.dialogClose)
        this.getAllBuildScore()
        this.searchClick()
        this.$bus.$on('getDataByOther', (res) => {
            this.tableData = res
        })
    },
    methods: {
        getLd() {
            this.$http({
                method: 'post',
                url: '/bus/building/querydata?type=1',
                data: [
                    { 'compare': '=', 'field': 'schoolId', 'merge': false, 'value': this.valueSc },
                    { 'compare': '=', 'field': 'orgCode', 'merge': false, 'value': this.loginUser.orgCode },
                    { 'compare': '=', 'field': 'status', 'merge': false, 'value': 1 },
                    { 'compare': '=', 'field': 'type', 'merge': false, 'value': 1 }
                ],
                params: {
                    orderBy: 'orderNo'
                }
            }).then(res => {
                console.log(res)
                if (res.code === 'success') {
                    if (res.body.listdata.length > 0) {
                        this.optionsLd = [{ name: '全部', id: '全部' }]
                        res.body.listdata.forEach(item => {
                            this.optionsLd.push(item)
                        })
                    } else {
                        this.valueLd = '全部'
                        this.optionsLd = [{ name: '全部', id: '全部' }]
                    }
                }
            })
        },
        getLdAll() {
            this.$http({
                method: 'post',
                url: '/bus/building/querydata',
                data: [
                    { 'compare': '=', 'field': 'orgCode', 'merge': false, 'value': this.loginUser.orgCode },
                    { 'compare': '=', 'field': 'status', 'merge': false, 'value': 1 },
                    { 'compare': '=', 'field': 'type', 'merge': false, 'value': 1 }
                ],
                params: {
                    orderBy: 'orderNo'
                }
            }).then(res => {
                console.log(res)
                if (res.code === 'success') {
                    if (res.body.listdata.length > 0) {
                        this.optionsLd = [{ name: '全部', id: '全部' }]
                        res.body.listdata.forEach(item => {
                            this.optionsLd.push(item)
                        })
                    } else {
                        this.valueLd = '全部'
                        this.optionsLd = [{ name: '全部', id: '全部' }]
                    }
                }
            })
        },
        addFormOne(val) {
            if (val.passenger.scored) {
                // this.moduleInfo = {
                //     winWidth: 1000,
                //     winHeight: window.mobileHeight,
                //     listDataUrl: '/bus/buildingRoomAttendance/listdata',
                //     delUrl: '/bus/buildingRoomAttendance/delete',
                //     updateUrl: '/bus/buildingRoomAttendance/update',
                //     addNewUrl: '/bus/buildingRoomAttendance/addNew',
                //     insertUrl: '/bus/buildingRoomAttendance/insert',
                //     getByIdUrl: '/bus/buildingRoomAttendance/getById',
                //     deleteUrl: '/bus/buildingRoomAttendance/delete'
                // }
                // this.title = '出勤情况'
                // this.moduleInfo.formTemplate = '#/dcontent/vuebusiform/W/bus.buildingRoomAttendance.form_cqqk.vue?sysCode=' + this.$route.meta.sysCode
                // this.$options.components['BusiForm'] = this.$loadComponent(this.moduleInfo.formTemplate)
                // this.$nextTick(() => {
                //     this.$bus.emit('openBusiform', this.moduleInfo, { id: `date:${this.value1}room:` + val.id }, 1, (res) => {
                //         if (res.code === 'success') {
                //             console.log(132323, res)
                //         }
                //     })
                // })
                this.isOneA = true
                this.updateId = 'roomId:' + val.id
                val.dutyDate = this.value1 + ' 00:00:00'
                this.updateRow = val
                this.dialogVisible = true
            } else {
                this.title = '出勤情况'
                this.moduleInfo.obj = val
                this.moduleInfo.obj.dutyDate = this.value1
                this.moduleInfo.addNewUrl = `/bus/buildingRoomAttendance/addNew?roomId=${val.id}`
                this.moduleInfo.formTemplate = '#/dcontent/vuebusiform/W/bus.buildingRoomAttendance.form_cqqk.vue?sysCode=' + this.$route.meta.sysCode
                this.$options.components['BusiForm'] = this.$loadComponent(this.moduleInfo.formTemplate)
                this.$nextTick(() => {
                    this.$bus.emit('openBusiform', this.moduleInfo, null, 1, (res) => {
                        if (res.code === 'success') {
                            console.log(132323, res)
                        }
                    })
                })
            }
        },
        getSchoolData() {
            console.log(this.loginUser)
            this.$http({
                method: 'post',
                url: '/bus/school/querydata',
                data: [
                    { 'compare': '=', 'field': 'orgCode', 'merge': false, 'value': this.loginUser.orgCode },
                    { 'compare': '=', 'field': 'status', 'merge': false, 'value': 1 }
                ]
            }).then(res => {
                console.log(res)
                if (res.code === 'success') {
                    res.body.listdata.forEach(item => {
                        this.optionsSc.push(item)
                    })
                }
            })
        },
        isOneFn() {
            console.log('true')
            this.isOne = true
            this.searchClick()
        },
        isTwoFn() {
            console.log('false')
            this.isOne = false
            this.searchClick()
        },
        cleanUp() {
            this.value1 = new Date().toJSON().slice(0, 10)
            this.banji = ''
            this.valueA = '全部'
            this.valueB = '全部'
            this.valueSc = '全部'
            this.valueLd = '全部'
        },
        importData() {
            this.$bus.emit('openImportForm')
        },
        // 新增弹窗
        onBasicFormBeforeSubmit($scope, vo, defer) {
            $scope.vo.attendanceList = this.tableData
            $scope.vo.passenger.dataList = this.tableData
            if ((this.tableData.length === 0) || (!this.tableData.at(-1).dutyDate) || (!this.tableData.at(-1).ownerName)) {
                this.$alert('日期或学生不能为空', '提示', { type: 'warning' })
                defer.resolve(false)
            } else {
                defer.resolve(true)
            }
        },
        // 提交之后
        onBasicFormSubmited() {
            this.searchClick()
        },
        // 导出数据
        exportData() {
            if (this.valueSc !== '全部') {
                window.open(`apigw/bus/buildingRoomAttendance/exportAttendanceScore?date=${this.value1}&schoolId=${this.valueSc}&grade=${this.valueA}&classNo=${this.valueB}&pageNum=1&pageSize=100000&fileName=出勤情况评分`)
            } else {
                window.open(`apigw/bus/buildingRoomAttendance/exportAttendanceScore?date=${this.value1}&grade=${this.valueA}&classNo=${this.valueB}&pageNum=1&pageSize=100000&fileName=出勤情况评分`)
            }
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (row.rowSpan) {
                    return {
                        rowspan: row.rowSpan,
                        colspan: 1
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    }
                }
            }
        },
        dataFormat() {
            const mxList = this.chuqing
            const rowSpanObj = {}
            let j = 1
            for (let i = 0; i < mxList.length; i++) {
                if (i > 0) {
                    if (mxList[i].gradeClass === mxList[i - 1].gradeClass) {
                        j++
                    } else {
                        rowSpanObj[i - j] = j
                        j = 1
                    }
                    if (i === mxList.length - 1) {
                        rowSpanObj[i + 1 - j] = j
                    }
                } else {
                    rowSpanObj[0] = 1
                }
            }
            for (const k in rowSpanObj) {
                mxList[k].rowSpan = rowSpanObj[k]
            }
        },

        searchClick() {
            if (this.isOne) {
                this.chuqing = ''
                this.$http({
                    method: 'post',
                    url: '/bus/buildingRoomAttendance/getAllAttendance',
                    params: {
                        grade: this.valueA,
                        classNo: this.valueB,
                        date: this.value1,
                        schoolId: this.valueSc
                    }
                }).then((res) => {
                    if (res.code === 'success') {
                        console.log('776', res, this.valueA, this.valueB, this.value1)
                        const arr = []
                        Object.keys(res.body.vo).forEach((key) => {
                            arr.push({
                                list: res.body.vo[key]
                            })
                        })

                        this.arr = arr
                        console.log(arr, 12121)

                        this.arr2 = []
                        for (let i = 0; i < arr.length; i++) {
                            console.log(arr[i].list, 1234)
                            this.arr1 = arr[i].list
                            console.log(this.arr1.length)
                            for (let j = 0; j < this.arr1.list.length; j++) {
                                this.arr2.push(this.arr1.list[j])
                            }
                            this.chuqing = this.arr2
                            console.log('出勤', this.chuqing)
                        }
                        this.dataFormat()
                    // this.dataFormat1()
                    }
                })
            } else {
                this.suSheList = []
                this.$http({
                    method: 'post',
                    url: '/bus/buildingRoomAttendance/selectAttendanceScoreInfo',
                    params: {
                        buildingId: this.valueLd,
                        date: this.value1,
                        schoolId: this.valueSc
                    }
                }).then(res => {
                    if (res.code === 'success') {
                        console.log(res)
                        if (res.body.listdata.length > 0) {
                            this.suSheList = []
                            res.body.listdata.forEach(item => {
                                if (item.passenger.floorList && item.passenger.floorList.length > 0) {
                                    item.passenger.floorList.forEach(val => {
                                        this.suSheList.push(val)
                                    })
                                }
                            })
                            console.log('this.suSheList', this.suSheList)
                        }
                    }
                })
            }
        },
        addForm1() {
            this.moduleInfo.addNewUrl = '/bus/buildingRoomAttendance/addNew'
            this.title = '出勤情况'
            this.moduleInfo.formTemplate =
        '#/dcontent/vuebusiform/W/bus.buildingRoomAttendance.form.vue?sysCode=' +
        this.$route.meta.sysCode
            this.$options.components['BusiForm'] = this.$loadComponent(
                this.moduleInfo.formTemplate
            )

            this.$nextTick(() => {
                this.$bus.emit('openBusiform', this.moduleInfo, null, 1, (res) => {
                    if (res.code === 'success') {
                        console.log(res)
                    }
                })
            })
        },
        addForm2() {
            this.title = '早上迟出'
            this.moduleInfo.formTemplate =
        '#/dcontent/vuebusiform/W/bus.buildingRoomAttendance.form1.vue?sysCode=' +
        this.$route.meta.sysCode
            this.$options.components['BusiForm'] = this.$loadComponent(
                this.moduleInfo.formTemplate
            )

            this.$nextTick(() => {
                this.$bus.emit('openBusiform', this.moduleInfo, null, 1, (res) => {
                    if (res.code === 'success') {
                        console.log(132323, res)
                    }
                })
            })
        },
        getAllBuildScore() {
            this.$http({
                method: 'post',
                url: '/bus/buildingRoomAttendance/getAllAttendance',
                params: {
                    grade: this.valueA,
                    classNo: this.valueB,
                    date: this.value1
                }
            }).then((res) => {
                console.log(111, res)
                if (res.code === 'success') {
                    console.log(res)
                }
            })
        },

        async openUpdateDialog(row, column, cell, event) {
            this.isOneA = false
            //  || column.property === 'gradeClass'
            if (column.property === 'roomNo') {
                this.updateId = 'roomId:' + row.roomId
                this.updateRow = row
                this.dialogVisible = true
            } else if (column.property === 'gradeClass') {
                this.updateId = 'classId:' + row.classId
                // this.updateId = row.classId
                this.updateRow = row
                this.dialogVisible = true
            }
        },

        updateBtnClick() {
            if (this.btnTitle === '修改') {
                this.btnTitle = '保存'
            } else {
                this.$bus.emit('updateDuty')
            }
        },
        dialogClose() {
            this.dialogVisible = false
            this.searchClick()
        }

    },
    onBasicFormBeforeOpen($scope, vo, defer) {
        console.log('表单打开前事件', $scope)
        debugger
        console.log('calendar form before open')
        defer.resolve(true)
    }
}
</script>

<style scoped lang="scss">
.right {
  margin-right: 10px;
  margin-bottom: 10px;
  float: right;
  display: flex;
  justify-content: flex-end;
}

div.block {
  margin:10px 0;
  width: 100%;
  // background-color: red;
}
.el-select {
  margin: 0 5px;
  width: 98%;
}
.riqi {
  margin: 0 5px;
  width: 98%;
}
.table-box {
  width: 100%;
  padding: 0 10px 20px;
}

.el-table {
  overflow: hidden;
}

// 设置弹窗宽度
/deep/ .el-dialog[aria-label="出勤情况"]{
		width:1320px !important;
	}

/deep/ .el-dialog[aria-label="早上迟出"]{
		width:800px !important;
	}

  .el-table--border::after {
    width: 0;
  }
  .talleDiy {
  margin-top: 10px;
  td {
    border: 1px solid #e2e7ed;
    text-align: center;
    height: 40px;
  }
  th {
    border: 1px solid #e2e7ed;
    text-align: center;
    height: 40px;
  }
}

.reviewed {
  background: #409eff;
}
.not {
  background: #ccc;
}
</style>
