<template>
  <div>
    <div style="margin:20px 0 10px;text-align:right">
      <el-button
        v-if="update"
        v-table-toxls="exportTable"
        plain
        class="exportbtn"
        :file-name="exportName"
        size="mini"
      ><svg-icon :icon-class="'export'" /> 导出</el-button>
    </div>
    <list-table v-if="update" id="exportTable" :list-tb="column" :list-data="listData" :empty-text="emptyText" />
  </div>
</template>

<script>
import ListTable from '../components/ListTable'

export default {
    components: {
        ListTable
    },
    props: {
    },
    data: function() {
        return {
            loginUser: this.$store.getters.loginUser,
            listTb: [
                { 'prop': 'DEPARTMENT', 'width': '', 'align': 'left', 'title': '部门' },
                { 'prop': 'NAME', 'width': '100px', 'align': 'center', 'title': '姓名 ' }
            ],
            listData: [],
            emptyText: '正在加载..',
            loadingMast: null,
            copycolumn: [
                { 'prop': 'lateNum', 'width': '95px', 'align': 'center', 'title': '迟到次数' },
                { 'prop': 'comeLate', 'width': '135px', 'align': 'center', 'title': '迟到总时长' },
                { 'prop': 'earlyNum', 'width': '95px', 'align': 'center', 'title': '早退次数' },
                { 'prop': 'leaveEarly', 'width': '135px', 'align': 'center', 'title': '早退总时长' },
                { 'prop': 'overNum', 'width': '95px', 'align': 'center', 'title': '加班次数' },
                { 'prop': 'overTime', 'width': '135px', 'align': 'center', 'title': '加班总时长' }
            ],
            column: [],
            update: false
        }
    },
    computed: {
    },
    watch: {
    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {
        setTimeout(() => {
            this.getData()
        }, 50)
    },
    methods: {
        // 获取数据，部门人员工作时长
        getData() {
            this.update = false
            const datatype = this.$vnode.context.dataType
            if (datatype === '1') {
                this.column = JSON.parse(JSON.stringify(this.listTb))
                this.column.push(this.copycolumn[4])
                this.column.push(this.copycolumn[5])
                this.exportName = '部门加班次数统计'
            } else if (datatype === '2') {
                this.column = JSON.parse(JSON.stringify(this.listTb))
                this.column.push(this.copycolumn[0])
                this.column.push(this.copycolumn[1])
                this.exportName = '部门迟到次数统计'
            } else if (datatype === '3') {
                this.column = JSON.parse(JSON.stringify(this.listTb))
                this.column.push(this.copycolumn[2])
                this.column.push(this.copycolumn[3])
                this.exportName = '部门早退次数统计'
            }

            // if (this.loadingMast !== null) this.loadingMast.close()
            this.emptyText = '正在加载..'
            // this.loadingMast = this.$loading({
            //     target: '.app-container .el-table__body-wrapper'
            // })
            this.$http({
                url: 'yykq/homePageIndex/deptWorkSituationInfo',
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                params: {
                    startTime: this.$vnode.context.chooseDate[0],
                    endTime: this.$vnode.context.chooseDate[1],
                    orgCode: this.$vnode.context.orgCode,
                    deptId: this.$vnode.context.selectDept,
                    type: datatype
                }
            }).then(res => {
                if (res.code === 'success') {
                    this.listData = res.body.listdata.dateList
                    this.$nextTick(() => {
                        this.update = true
                    })
                    // this.loadingMast.close()
                    if (this.listData.length === 0) {
                        this.emptyText = '暂无数据'
                    }
                } else {
                    this.$message.error(res.message)
                }
            })
        }

    }
}
</script>

<style scoped>
    /* /deep/ .el-table-toolbar{display: none;} */
</style>
