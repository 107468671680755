var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"AreaLayout",class:_vm.isInlineLayout && 'AreaLayout_inline'},[(_vm.layoutVisible)?_c('grid-layout',{ref:"gridlayout",attrs:{"id":"AreaLayoutContent","vertical-compact":true,"is-draggable":_vm.isDraggable,"is-resizable":_vm.isResizable,"layout":_vm.layout,"col-num":Number(_vm.col),"row-height":_vm.height,"margin":[1, 1]},on:{"update:layout":function($event){_vm.layout=$event}},nativeOn:{"click":function($event){return _vm.handleClickGridItem(null)}}},_vm._l((_vm.layout),function(item,idx){return _c('grid-item',{key:item.i,class:{
                'vue-grid-item__selected': _vm.selected && _vm.selected.areaId === item.areaId,
                'vue-grid-item__hasHiddenWall': item.config.hidden && item.config.hidden.length
            },style:({ background: item.config.backgroundColor }),attrs:{"x":item.x,"y":item.y,"w":item.w,"h":item.h,"i":item.i},nativeOn:{"mousedown":function($event){return _vm.handlerRightClickEvent($event, item)},"click":function($event){$event.stopPropagation();return _vm.handleClickGridItem(item)}}},[(_vm.$options.components[item.name + 'Layout'])?_c(item.name + 'Layout',{tag:"component",attrs:{"layout-item":_vm.layout[idx],"selected":_vm.selected},on:{"update:layoutItem":function($event){return _vm.$set(_vm.layout, idx, $event)},"update:layout-item":function($event){return _vm.$set(_vm.layout, idx, $event)}},scopedSlots:_vm._u([_vm._l((_vm.$slots),function(index,name){return {key:name,fn:function(){return [_vm._t(name)]},proxy:true}})],null,true)}):_vm._e()],1)}),1):_vm._e(),(_vm.isVisible)?_c('el-scrollbar',{style:({
            top: _vm.menuConfig.y + 'px',
            left: _vm.menuConfig.x + 'px'
        }),attrs:{"id":"menu"}},[_vm._l((_vm.menus),function(menu){return [_c('div',{key:menu.type,staticClass:"menu-item",on:{"click":function($event){$event.preventDefault();return menu.handler.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(menu.title))])])]})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }