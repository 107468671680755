<template>
  <div id="AreaEditor">
    <!-- 第一标题头部 -->
    <div class="new-head">
      <div class="head-left">
        <img src="/images/articleHeadLogo.png">
        <div class="head-title">
          <div class="head-span"><span class="moduleT">仓库物料区设计</span></div>
        </div>
      </div>
      <div v-if="id" class="head-right">
        <el-button @click="handleClose"><svg-icon style="color:red;margin-right:5px" icon-class="glyphicon-off" />关闭</el-button>
        <el-button @click="handleSave(true)"><svg-icon style="margin-right:5px" icon-class="save" />保存关闭</el-button>
        <el-button type="primary" @click="handleSave(false)"><svg-icon style="margin-right:5px" icon-class="save" />保存</el-button>
      </div>
    </div>
    <!-- 主内容区 -->
    <div class="Editor-container">
      <!-- 左侧元素选择区 -->
      <div class="Editor-container__left">
        <div class="Editor-container__title">
          画布元素
        </div>
        <AreaElements />
      </div>
      <!-- 中间编辑区 -->
      <div class="Editor-container__center">
        <div class="Editor-container__title" style="border-left:1px solid #EFEFEF;border-right:1px solid #EFEFEF;">
          {{ areaName }}
        </div>
        <el-scrollbar class="content">
          <AreaLayout
            :col="areaConfig.areaCol"
            :height="areaConfig.areaHeight"
            :layout.sync="layout"
            :selected.sync="selected"
            :occupied="occupied"
          />
        </el-scrollbar>
      </div>
      <!-- 右侧属性区 -->
      <div class="Editor-container__right">
        <div class="Editor-container__title">
          {{ !isSelectLayout ? '仓库属性' : '区域属性' }}
        </div>
        <AreaConfig
          :selected.sync="selected"
          :area-config.sync="areaConfig"
          :occupied="occupied"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AreaLayout from './AreaLayout.vue'
import AreaElements from './AreaElements.vue'
import AreaConfig from './AreaConfig.vue'
export default {
    components: { AreaLayout, AreaElements, AreaConfig },
    data() {
        return {
            id: '',
            areaName: '',
            areaConfig: {
                key: 'area',
                areaCol: 24,
                areaHeight: 30
            },
            layout: [],
            selected: {},
            occupied: [] // 被原料占用的区域 禁止修改名称以及删除
        }
    },
    computed: {
        isSelectLayout() {
            return this.selected !== null && this.selected !== undefined && JSON.stringify(this.selected) !== '{}'
        }
    },
    created() {
        this.initAreaBaseData()
    },
    mounted() {
        document.title = '仓库物料区设计 - 金叶烟草薄片OA'
    },
    methods: {
        handleClose() {
            window.close()
        },
        handleSave(closeAfterSaved = false) {
            if (this.layout.some(lay => lay.title === '')) {
                return this.$message.warning('存在未命名区域，请检查！')
            }
            const data = {
                id: this.id,
                stashData: JSON.stringify(this.layout),
                areaConfig: JSON.stringify(this.areaConfig)
            }
            this.$http({
                url: 'shicgl/wlqsz/saveStashMaterialAreaDesignById',
                method: 'POST',
                data
            }).then(res => {
                if (res.code === 'success') {
                    this.$message.success(res.message)
                    if (closeAfterSaved) this.handleClose()
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                this.$message.error(err)
            })
        },
        // 初始化数据区数据
        initAreaBaseData() {
            const id = this.$route.query.id
            if (!id) return this.$message.warning('无效的仓库id！')
            this.id = id
            this.$http({
                url: 'shicgl/wlqsz/getStashMaterialAreaDesignById?id=' + id,
                method: 'GET'
            }).then(res => {
                if (res.code === 'success') {
                    const result = res.body
                    this.areaName = result.name
                    result.areaConfig && (this.areaConfig = JSON.parse(result.areaConfig))
                    result.listdata && (this.layout = JSON.parse(result.listdata))
                    this.occupied = result.subList.reduce((prev, curr) => {
                        if (!prev.includes(curr.materialId)) {
                            prev.push(curr.materialId)
                        }
                        return prev
                    }, [])
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                this.$message.error(err)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
#AreaEditor{
    height: 100%;
    background: #f0f2f5;
    display: flex;
    flex-direction: column;
    min-width: 1300px;
    .new-head{
        height: 68px;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        background: white;
        align-items: center;
        border-bottom: 1px solid #EFEFEF;
        .head-left{
            display: flex;
            align-items: center;
            img{
                width:44px;
                height:44px
            }
            .head-title{
                margin-left: 10px;
                font-size: 14px;
                color: #999999;
                .moduleT{
                    margin-right: 13px;
                    position: relative;
                }
            }
        }
    }
    .sec-head {
        height: 48px;
        line-height: 48px;
        padding: 0 300px 0 290px;
        text-align: center;
        font-size: 16px;
        color:#333333;
        background: #ffffff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.04);
        border-bottom: 1px solid #EFEFEF;
    }
    .Editor-container{
        flex: 1;
        display: flex;
        overflow: hidden;
    }
    .Editor-container__left{
        width: 290px;
        background: #ffffff;
    }
    .Editor-container__center{
        flex: 1;
        display: flex;
        flex-direction: column;
        .content{
            flex: 1;
            padding: 12px;
        }
    }
    .Editor-container__right{
        width: 300px;
        background: #ffffff;
    }
    .Editor-container__title{
        height: 60px;
        width: 100%;
        line-height: 60px;
        text-align: center;
        font-family: MicrosoftYaHeiSemibold;
        font-size: 16px;
        color: #333333;
        font-weight: 400;
        border-bottom: 1px solid #EFEFEF;
        background: #ffffff;
    }
    /deep/ .el-scrollbar__wrap{
        margin-right: 0 !important;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    /deep/ .el-scrollbar__view {
        height: 100%;
    }
}
</style>
