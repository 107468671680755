<template>
  <div>
    <div
      style="padding: 10px;     font-size: 14px;   display: flex; position: relative;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: space-between;"
    >
      <div style="display: flex;align-items: center;">
        <div class="dj">
          登记日期：
          <el-date-picker
            v-model="value1"
            class="riqi"
            type="month"
            placeholder="选择月"
            format="yyyy年M月"
            value-format="yyyy-MM"
          />

        </div>
        <statistics-search ref="statistics" :search-type="searchType" @statisticsClick="statistics" />
      </div>
      <el-button-group>
        <el-button @click="getListData"><i class="el-icon-refresh" /></el-button>
        <el-button @click="importHandler">
          <svg aria-hidden="true" class="svg-icon" style="height: 13px; width: 13px" v-on="$listeners">
            <use :xlink:href="'#icon-import'" style="fill:#EC8423 " />
          </svg>导入</el-button>
        <el-button v-table-toxls="views" file-name="考勤登记表">
          <svg aria-hidden="true" class="svg-icon" style="height: 13px; width: 13px" v-on="$listeners">
            <use :xlink:href="'#icon-export'" style="fill:#EC8423 " />
          </svg>导出</el-button>
      </el-button-group>
    </div>

    <div
      style="    display: flex;
    justify-content: space-around;
    padding: 20px 20px 5px 20px;;
    font-size: larger;"
    >
      <div style="width:33%;display: flex;font-size: 16px;">
        <div style="padding: 20px 0px 5px;font-size: 14px;">科室负责人：{{ ksPersonVo.ksPrincipal || '' }}</div>
        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        <div v-if="ksPersonVo.ksManager"><div style="padding: 20px 0px 5px;font-size: 14px;margin-right:50px">科室考勤员：{{ ksPersonVo.ksManager || '' }}</div></div>
      </div>
      <div style="width:33%;text-align: center;">{{ month }}月份考勤登记表</div>
      <div style="width:33%;display: flex;font-size: 16px;justify-content: right;">
        <div style="padding: 20px 0px 5px;font-size: 14px;">考勤人数：{{ ksPersonVo.kqPersonNumber ||0 }}人</div> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        <div style="padding: 20px 0px 5px;font-size: 14px;">实有人数：{{ ksPersonVo.actualPersonNumber ||0 }}人</div>
      </div>
    </div>
    <div class="table-box">

      <el-table id="domm views" ref="dommm" v-loading="loading" class="custom-table" :header-row-class-name="handleTableHeader" :data="dateList" style="width: 100%" border>
        <el-table-column :label="month + '月份考勤登记表'" align="center">
          <el-table-column align="center" prop="name" label="姓名" width="120px">
            <template slot-scope="scope">
              <div>{{ scope.row.name }}</div>
            </template>
          </el-table-column>

          <el-table-column
            v-for="(item,index) in time1"
            :key="index"
            :label="item"
            :min-width="50"
            align="center"
          >
            <template slot-scope="scope">
              <div
                v-if="scope.row.recordResultList && scope.row.recordResultList.filter(item=>{return item.number == (index+1)}).length > 0"
                style="height: 40px;"
                :style="{
                  'background-color': setBgcolor(scope),
                  'color': scope.row.recordResultList.filter(item=>{return item.number == (index+1)})[0].isShow === 'true' && 'red'
                }"
                @click="itemClick(scope.row,index)"
              >
                {{ scope.row.recordResultList.filter(item=>{return item.number === (index+1)})[0].classesNumber }}
              </div>
              <div v-else style="height: 40px;" :style="{'background-color': setBgcolor(scope)}"> - </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

    </div>
    <ImportForm />
  </div>

</template>

<script>
import StatisticsSearch from '@/wapp/IndustryVersionCode/kqgl/kqhz/common/StatisticsSearch'
import ImportForm from '@/wapp/IndustryVersionCode/kqgl/kqhz/common/ImportFormkqdj'
// import qs from 'qs'

const moment = require('moment')
export default {
    filters: {
        symbolFilter(value) {
            if (value === '0' || value === null || value === '' || value === 0) {
                return '-'
            } else if (value === '1' || value === 1) {
                return '√'
            } else if (value === '2' || value === 2) {
                return 'C'
            } else if (value === '3' || value === 3) {
                return 'X'
            } else if (value === '4' || value === 4) {
                return 'Z'
            } else {
                return value
            }
        }
    },
    components: {
        StatisticsSearch, ImportForm
    },
    props: {

    },
    data() {
        return {
            month: '',
            ksPersonVo: {},
            loading: true,
            savelist: [],
            visible: true,
            dateList: [],
            loginUser: this.$store.getters.loginUser,
            thisweek: [],
            currentMonthHolidays: [],
            value1: '',
            time1: '',
            time: '',
            selectDept: '',
            content: '',
            searchType: [
                {
                    type: 'subOrg',
                    title: '登记科室',
                    kqManager: false
                }
            ],
            moduleInfo: { 'formTemplate': 'dcontent/busiformurl?vwtype=W&url=/yykq/recordResult/form.html', 'addNewUrl': 'yykq/recordResult/addNew', 'insertUrl': 'yykq/recordResult/insert', 'getByIdUrl': 'yykq/recordResult/getById', 'updateUrl': 'yykq/recordResult/update', 'delUrl': 'yykq/recordResult/delete', 'listDataUrl': 'yykq/recordResult/listdata?kqType=1', 'winWidth': 1000, 'winHeight': 600, 'moduleCode': 'recordResult', 'openViewType': '0', 'listAdvSearchAllFields': 0, 'listAdvSearchExpandPanel': 0 },
            duilist: [],
            change1: '',
            change2: '',
            ksManager: false, // 科室管理员
            kqManager: false, //  考勤管理员
            checkList: ''
        }
    },
    computed: {
    },
    watch: {

    },
    created() {
        this.moduleInfo.listpageId = 'bf3ac590ef51444caeb8baa27b7cf3a4'
        if (this.$store.state.user.roles.indexOf('kqManager') !== -1) {
            // 考勤管理员
            this.kqManager = true
            this.searchType[0].kqManager = this.kqManager
        }
        if (this.$store.state.user.roles.indexOf('ksManager') !== -1) {
            // 科室管理员
            this.ksManager = true
        }
        this.$bus.emit('showNavSearch', false)
        const date = new Date()
        this.value1 = this.$moment(date).format('YYYY-MM')
        const time1 = this.value1.split('-')
        const y = time1[0]
        const m = time1[1]
        this.getDays(y, m)
        this.getHolidays(this.value1)

        this.$bus.on('refreshViewData', this.statistics)
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true)
        this.$bus.off('refreshViewData', this.statistics)
    },
    mounted() {
        this.$bus.on('statisticsClick', (data) => {
            this.statistics(data)
        })
    },
    methods: {
        handleTableHeader(head) {
            if (head.rowIndex === 0) return 'tableHeaderTitle'
        },
        // 单格点击
        itemClick(row, index) {
            console.log('row', row, index)
            // if (!row.data[0].passenger.listdata[index].recordInfos || !row.data[0].passenger.listdata[index].recordInfos.length) return
            const obj = row.recordResultList.filter(item => { return item.number === (index + 1) })[0]
            if (obj.classesNumber === '借') return
            this.$emit('openDailong', row, obj.clockInDate)
        },
        setBgcolor(scope) {
            const columnIndex = scope.column.label
            const targetColumns = this.currentMonthHolidays
            return targetColumns.includes(columnIndex) ? '#f5f5f5' : ''
        },
        statistics(vals) {
            // this.loading = true
            // this.dateList = []
            if (vals) {
                this.selectDept = vals.subOrg.id
            }
            if (!this.selectDept) {
                return this.$message.warning('请选择登记科室后查询！')
            }
            this.getDays(new Date(this.value1).getFullYear(), new Date(this.value1).getMonth() + 1)
            this.getHolidays(this.value1)
            // console.log('valssss', vals)
            // console.log('this.selectDept', this.selectDept)
            // this.$bus.emit('refreshListData')
            this.month = this.value1.split('-')[1]
            this.getListData()
        },
        getWeekends(year, month) {
            const weekends = []
            const date = new Date(year, month - 1, 1)
            while (date.getMonth() === month - 1) {
                if (date.getDay() === 0 || date.getDay() === 6) {
                    weekends.push(date.getDate())
                }
                date.setDate(date.getDate() + 1)
            }

            return weekends.join(',')
        },
        getDays(year, month) {
            month = Number(month)
            const days = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
            if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
                days[2] = 29
            }

            this.time1 = days[month]
            const weekendss = this.getWeekends(year, month)
            const weeks = weekendss.split(',')
            const thisweek = []

            for (let index = 1; index <= this.time1; index++) {
                for (let i = 0; i < weeks.length; i++) {
                    if (weeks[i] == index) {
                        thisweek.push(index)
                    }
                }
            }
            this.thisweek = thisweek
        },
        getHolidays(timer) {
            const formatTimer = moment(new Date(timer)).format('YYYY-MM')
            const formatTimerInfo = formatTimer.split('-')
            const params = [
                { 'compare': '=', 'field': 'orgCode', 'merge': false, 'value': this.loginUser.orgCode },
                { 'compare': '=', 'field': 'year', 'merge': false, 'value': formatTimerInfo[0] }
            ]
            this.$http({
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: params,
                url: 'yykq/holiday/days/querydata'
            }).then(res => {
                const month = formatTimerInfo[1]
                if (res.code === 'success') {
                    if (!res.body.listdata.length) return
                    const dayList = res.body.listdata[0].day.split(',')
                    const result = []
                    dayList.forEach(item => {
                        const timerArr = item.split('-')
                        if (timerArr[1] === month) {
                            result.push(Number(timerArr[2]))
                        }
                    })
                    this.currentMonthHolidays = result
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    })
                }
            }).catch(err => this.$message.error(err))
        },
        // 导入
        importHandler() {
            this.$bus.emit('openImportForm')
        },
        getListData() {
            const data = {
                // pbNy: this.value1,
                // staffDeptId: this.selectDept,
                // kqswStatus: 1,
                deptId: this.selectDept,
                // kqxwStatus: 1,
                clockInDate: this.value1
            }
            this.$http({
                method: 'GET',
                // url: '/yykq/rypbkqdj/AttendanceRegistration',
                url: '/yykq/recordResult/getKQView',
                contentType: 'application/x-www-form-urlencoded',
                params: data
            }).then(res => {
                if (res.code === 'success') {
                    this.loading = false
                    this.ksPersonVo = res.body.vo
                    // const data = res.body.list
                    // const data1 = Object.keys(data)
                    // const data2 = data1.map((item) => {
                    //     return {
                    //         name: item.split('_')[1],
                    //         data: data[item]
                    //     }
                    // })
                    this.dateList = res.body.listdata
                    // this.dateList.forEach(nameList => {
                    //     nameList.data[0].passenger.listdata.forEach(data => {
                    //         if (data.showStatus) {
                    //             if (data.showStatus.endsWith('/')) {
                    //                 data.showStatus = data.showStatus.slice(0, -1) // 去掉结尾的 '/'
                    //             }
                    //         }
                    //     })
                    // })
                    console.log(this.dateList, 123)
                } else {
                    this.loading = false
                    this.$message.error(res.message)
                }
            })
        }
    }
}
</script>

<style>
.recordInfos-class {
  text-align: center;
  width: auto !important;
  padding: 10px 5px !important;
  min-width: unset !important;
}
.tableHeaderTitle{
    display: none;
}
</style>
<style scoped lang="scss">
.dj{
        padding: 5px;
}
.square {
  width: 100px;
  height: 100px;
  position: relative;
}

.diagonal-line {
    width: 1px;
    height: 100%;
    background-color: #ebeef5b8;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 1px;
    right: 22px;
}
/deep/.line{
  background-color: #F2F5FB;
    width: 0.1px;
    height: 50%;
    background-color: #000000b8;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    position: absolute;
    top: 10px;
    right: 20px;
}
.up{
    position: absolute;
    top: -5px;
    right: 8px;
}
.down{
    position: absolute;
    bottom: -12px;
    right: 7px;
}
.square::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
   /* 可根据需要调整斜线的样式 */
  transform: rotate(45deg);
}
.content {
  position: absolute;
  color: black;
}

.content:first-child {
  top: 5px;
  left: 5px;
}

.content:last-child {
  bottom: 5px;
  right: 5px;
}
/deep/.el-table--medium .el-table__cell {
    padding: 0px 0 !important;
}
/deep/.el-table .cell {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    line-height: 40px !important;
    padding-left: 10px;
    padding-right: 10px;
    padding: 0px 0px !important;
    width: 100% !important;
}
.right {
  margin-right: 10px;
  margin-bottom: 10px;
  float: right;
  display: flex;
  justify-content: flex-end;
}

.table-box {
  width: 100%;
  padding: 0 10px 10px 10px;
}

.el-table {
  overflow: hidden;
}
/deep/.el-radio-button__inner, .el-radio-group {
    display: contents !important;
    line-height: 1;
    vertical-align: middle;
}
/deep/.el-popper {
    margin-bottom: 12px;
    width: 40px !important;
    min-width: 110px !important;
}
/deep/.el-table--scrollable-x .el-table__body-wrapper{
  overflow-x: auto !important;
}
/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: rgba(248, 247, 247, 0.87) !important;
}

/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
  margin: 0 2px !important;
  border-radius: 6px !important;
  background-color: rgba(199, 199, 199, 0.65) !important;
}

/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: hsla(240, 1%, 35%, 0.418)  !important;
}
</style>

