<template>
  <div class="student-class-input">
    <el-input
      ref="studentInput"
      v-model="selStudent"
      placeholder="请选择学生"
      :disabled="disabled"
      readonly
      @focus="focusHandler"
      @click="dialogFormVisible = true"
    >
      <el-button slot="append" :disabled="disabled" icon="el-icon-more" @click="startListClick()"></el-button>
    </el-input>
    <el-dialog v-if="dialogFormVisible" :visible.sync="dialogFormVisible" destroy-on-close :modal="false" title="学生信息" @close="closeClick">
      <div v-if="dialogFormVisible">
        <el-form :inline="true" :model="formInline" class="demo-form-inline" style="text-align: left;">
          <el-form-item v-if="roomId" label="宿舍:" class="sci-form-item">
            <span style="color:red">{{ studentList.length > 0 ? studentList[0].roomNo:'无' }}</span>
          </el-form-item>
          <el-form-item label="姓名:" class="sci-form-item">
            <el-input v-model="formInline.studentName" :disabled="roomId"></el-input>
          </el-form-item>
          <el-form-item label="年级:" class="sci-form-item">
            <el-select v-model="formInline.grade" placeholder="请选择年级" :disabled="roomId">
              <el-option label="全部" value="全部"></el-option>
              <el-option label="高一" value="高一"></el-option>
              <el-option label="高二" value="高二"></el-option>
              <el-option label="高三" value="高三"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班级:" class="sci-form-item">
            <el-select v-model="formInline.class" placeholder="请选择班级" :disabled="roomId">
              <el-option v-for="item in classList" :key="item.id" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="sci-form-item">
            <el-button type="primary" :disabled="roomId" @click="onSearch">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table
          v-loading="loading"
          max-height="500"
          :data="studentList"
          border
          style="width: 100%"
        >
          <el-table-column
            prop="name"
            label="姓名"
          >
          </el-table-column>
          <el-table-column
            v-if="dormitory !== false"
            prop="roomNo"
            label="宿舍"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="gender"
            label="性别"
            width="90"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.gender==='1'?'男':'女' }}
            </template>

          </el-table-column>
          <el-table-column
            prop="gradeClass"
            label="班级"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            width="80"
            align="center"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleSelect(scope.row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
    props: {
        dormitory: null,
        curVal: null,
        disabled: null,
        roomId: null,
        vo: null
    },
    data() {
        return {
            loading: true,
            selStudent: '',
            dialogFormVisible: false,
            formInline: {
                grade: '全部',
                class: '全部',
                studentName: ''
            },
            classList: [{ label: '全部', value: '全部' }],
            studentList: []
        }
    },
    watch: {
        // 获取班级
        'formInline.grade': {
            async handler(val) {
                if (val === '全部') {
                    this.classList = [{ label: '全部', value: '全部' }]
                    this.formInline.class = '全部'
                    return
                }
                const { body: { vo }} = await this.$http({
                    method: 'get',
                    url: '/bus/gradeClass/getAllClass',
                    params: { grade: val }
                })
                this.classList = vo.map((item, index) => {
                    return { label: item.classNo + '班', value: index, classid: item.id }
                })
                this.formInline.class = this.classList[0].value
            }
        }
    },
    mounted() {
        this.studentList = []
        this.selStudent = this.curVal
        console.log(this.roomId)
        console.log('vovovovovov', this.vo)
    },
    methods: {
        focusHandler() {
        },
        /**
         * 打开学生列表事件
         */
        startListClick() {
            if (this.roomId === null || this.roomId === void 0) {
                debugger
                this.searchAllStudent()
            } else {
                this.searchByRoomId()
            }
            this.dialogFormVisible = true
        },
        /**
         * 点击搜索按钮时间
         */
        async onSearch() {
            if (this.formInline.class === '全部') {
                await this.searchAllStudent()
                this.filterStudent()
            } else {
                await this.searchByClass()
                this.filterStudent()
            }
        },
        /**
         * 列表选择关闭事件
         */
        closeClick() {
            this.formInline = { grade: '全部', class: '全部', studentName: '' }
        },
        /**
         * 查询所有学生信息
         */
        async searchAllStudent() {
            const res = await this.$http({
                method: 'post',
                url: '/bus/student/listdata',
                params: {
                    pageSize: 1000000,
                    total: 0,
                    pageNum: 1,
                    searchAndOr: '',
                    listpageId: '02f4a4672b444877bf4cadc2161ab52e'
                    // urlsearch: `schoolId:${this.vo.schoolId}`
                }
            })

            this.studentList = res.body.pageInfo.list.map(item => {
                return {
                    bedId: item.bedId,
                    bedNo: item.bedNo,
                    buildId: item.buildId,
                    classId: item.classId,
                    classNo: item.classNo,
                    gradeClass: item.gradeClass,
                    roomId: item.roomId,
                    memo: item.memo,
                    name: item.name,
                    gender: item.gender,
                    id: item.id,
                    roomNo: item.roomNo,
                    startYear: item.startYear
                }
            })
            this.filterDormitory()
            this.loading = false
        },
        /**
         * 根据班级查询学生
         */
        async searchByClass() {
            const res = await this.$http({
                method: 'get',
                url: '/bus/student/getClassStudent',
                params: {
                    classId: this.classList[this.formInline.class].classid
                }
            })
            console.log('res', res)
            this.studentList = res.body.list.map(item => {
                return {
                    bedId: item.bedId,
                    bedNo: item.bedNo,
                    buildId: item.buildId,
                    classId: item.classId,
                    classNo: item.classNo,
                    gradeClass: item.gradeClass,
                    roomId: item.roomId,
                    memo: item.memo,
                    name: item.name,
                    gender: Number(item.gender) === 2 ? '女' : '男',
                    id: item.id,
                    roomNo: item.roomNo,
                    startYear: item.startYear
                }
            })
            this.filterDormitory()
        },
        /**
         * 根据宿舍id查询学生
         */
        async searchByRoomId() {
            const res = await this.$http({
                method: 'get',
                url: '/bus/student/getRoomStudent',
                params: {
                    roomId: this.roomId
                }
            })
            console.log('res', res)
            this.studentList = res.body.list.map(item => {
                return {
                    bedId: item.bedId,
                    bedNo: item.bedNo,
                    buildId: item.buildId,
                    classId: item.classId,
                    classNo: item.classNo,
                    gradeClass: item.gradeClass,
                    roomId: item.roomId,
                    memo: item.memo,
                    name: item.name,
                    gender: Number(item.gender) === 2 ? '女' : '男',
                    id: item.id,
                    roomNo: item.roomNo,
                    startYear: item.startYear
                }
            })
            this.filterDormitory()
            this.loading = false
        },
        /**
         * 选择学生事件
         * @param {*} row 选择的行信息
         */
        handleSelect(row) {
            this.$emit('sel-row', row)
            this.selStudent = row.name
            this.dialogFormVisible = false
        },
        /**
         * 宿舍字段是否显示过滤或隐藏
         */
        filterDormitory() {
            if (this.dormitory === null || this.dormitory === void 0) {
                this.studentList.forEach((item) => {
                    if (item.roomNo === null) {
                        item.roomNo = '无'
                    }
                })
                return
            }
            if (this.dormitory === true) {
                this.studentList = this.studentList.filter((item) => {
                    return !(item.roomNo === null)
                })
            } else {
                this.studentList = this.studentList.filter((item) => {
                    return item.roomNo === null
                })
            }
        },
        /**
         * 学生查找功能
         */
        filterStudent() {
            if (this.formInline.studentName === '') return
            this.studentList = this.studentList.filter((item) => item.name.includes(this.formInline.studentName))
        }

    }
}
</script>

<style lang="scss" scoped>
.sci-form-item {
  display: inline-flex;
  align-items: center;
}
</style>
