<template>
  <div class="be-late-table">
    <el-input v-show="false"></el-input>
    <div class="month-cell">
      <div class="month-cell_font">日期：</div>
      <div class="month-cell_date-box">
        <el-date-picker
          v-model="value1"
          class="riqi"
          type="date"
          placeholder="选择日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </div>
    </div>
    <el-table
      :data="tableData"
      :highlight-current-row="false"
      style="width: 100%"
      border
      :header-cell-style="{background:'#fff'}"
    >
      <el-table-column
        type="index"
        label="序号"
        width="50"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="xs"
        label="迟出人"
        width="140"
        align="center"
      >
        <template slot-scope="scope">
          <div @click="selRow(scope)">
            <StudentClassInput :ref="'scoreUserName'+scope.$index" :disabled="false" :dormitory="true" :cur-val="tableData[scope.$index].ownerName" @sel-row="onSelectListpageRowCompleted" />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="ss"
        label="宿舍"
        align="center"
      >
        <template slot-scope="scope">{{ tableData[scope.$index].roomNo }}</template>
      </el-table-column>
      <el-table-column prop="bj" label="班级" align="center">
        <template slot-scope="scope">{{ tableData[scope.$index].gradeClass }}</template>
      </el-table-column>
      <el-table-column prop="val" label="扣分" width="160" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="tableData[scope.$index].morningDeduct" size="mini" :min="0"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="110">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="deleteRow(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="btn-box">
      <el-button type="primary" @click="addNewTheme">新增</el-button>
    </div>
  </div>
</template>

<script>
import StudentClassInput from './student-class-input.vue'
export default {
    components: {
        StudentClassInput
    },
    props: {
        vo: null
    },
    data() {
        return {
            currentSelIndex: 0,
            value1: '',
            refsArr: [],
            tableData: [{
                classId: null,
                classNo: null,
                gradeClass: null,
                bedNo: null,
                buildId: null,
                scoreUserId: null,
                scoreUserName: '',
                ownerName: '',
                morningDeduct: 0,
                noonDeduct: 0,
                nightDeduct: 0,
                lateDeduct: 0,
                breachDeduct: 0,
                memo: '',
                roomId: null,
                roomNo: null,
                dutyDate: '',
                scoreTime: ''
            }]

        }
    },
    watch: {
        tableData: {
            deep: true,
            immediate: true,
            handler(newVal) {
                this.tableData.forEach((item) => {
                    item.scoreTime = this.value1
                    item.dutyDate = this.value1
                })
                console.log(this.tableData)
                this.$bus.emit('getDataByOther', newVal)
            }
        }, value1(newVal) {
            this.tableData.forEach((item) => {
                item.scoreTime = newVal
                item.dutyDate = newVal
            })
        }
    },
    mounted() {
        this.$parent.$el.children[0].setAttribute('class', 'el-table el-table--fit')
    },
    methods: {
        addNewTheme() {
            if ((this.tableData.length === 0) || this.tableData.at(-1).ownerName !== '') {
                this.tableData.push({
                    classId: null,
                    bedNo: null,
                    buildId: null,
                    classNo: null,
                    gradeClass: null,
                    scoreUserId: null,
                    scoreUserName: '',
                    ownerName: '',
                    morningDeduct: 0,
                    noonDeduct: 0,
                    nightDeduct: 0,
                    lateDeduct: 0,
                    breachDeduct: 0,
                    dutyDate: '',
                    roomId: null,
                    roomNo: null,
                    memo: null,
                    scoreTime: '' })
            } else {
                console.log(this.canEdit)
                this.$message.warning('请编辑数据后再新增数据')
            }
        },
        onSelectListpageRowCompleted(studentInfo) {
            this.tableData[this.currentSelIndex].classId = studentInfo.classId
            this.tableData[this.currentSelIndex].classNo = studentInfo.classNo
            this.tableData[this.currentSelIndex].gradeClass = studentInfo.gradeClass
            this.tableData[this.currentSelIndex].bedNo = studentInfo.bedNo
            this.tableData[this.currentSelIndex].buildId = studentInfo.buildId
            this.tableData[this.currentSelIndex].scoreUserId = studentInfo.id
            this.tableData[this.currentSelIndex].ownerName = studentInfo.name
            this.$http({
                method: 'post',
                url: '/bus/buildingRoom/getRoomByStudent',
                params: { studentID: studentInfo.id }
            }).then((res) => {
                if (res.body.vo.length === 0) {
                    this.tableData[this.currentSelIndex].roomId = ''
                    this.tableData[this.currentSelIndex].roomNo = ''
                } else {
                    this.tableData[this.currentSelIndex].roomId = res.body.vo[0].roomID
                    this.tableData[this.currentSelIndex].roomNo = res.body.vo[0].roomOn
                }
            })
        },
        selRow(scope) {
            this.currentSelIndex = scope.$index
        },
        deleteRow(index) {
            this.tableData.splice(index, 1)
            this.tableData.forEach((item, tableIndex) => {
                console.log(this.$refs['scoreUserName' + tableIndex].selStudent, item.ownerName)
                this.$refs['scoreUserName' + tableIndex].selStudent = item.ownerName
            })
        }
    }
}
</script>

  <style scoped lang="scss">
  .be-late-table {
    padding-right: 2px;
  }

  .month-cell{
      display: flex;
      height: 44px;
      border: 1px solid #e2e7ed !important;
      border-bottom: 0px !important;
      align-items: center;

      .month-cell_font{
          text-align: right;
          line-height: 44px;
          font-size: 13px;
          font-weight: 600;
          width: 190px;
          border-right: #e2e7ed 1px solid;
          text-rendering: optimizeLegibility;
          font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
          color: #000;
      }
      .month-cell_date-box{
          margin-left: 20px;
      }
  }

  .sel-group{
      display: flex;
      justify-content: space-around;
  }
  .el-select {
      width: 80px;
  }

  .el-input-number{
      width: 140px !important;
  }

  .btn-box {
      padding: 15px;
      position:relative;
      left: -2px;
       z-index:999;
       background:#fff;
       display: flex;
       justify-content: center;
  }
  </style>
