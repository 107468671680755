<template>
  <div class="right-bar">
    <el-button-group>
      <el-button plain size="small" @click="clearNonWorkday">清理非工作日</el-button>
      <el-button type="primary" size="small" @click="saveNonWorkday">保 存</el-button>
    </el-button-group>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            curDataId: '',
            curYear: 2000,
            moduleInfo: {
                winWidth: 800,
                winHeight: 600,
                delUrl: 'holiday/days/delete',
                addNewUrl: 'holiday/days/addNew',
                insertUrl: 'holiday/days/insert',
                updateUrl: 'holiday/days/update',
                listDataUrl: 'holiday/days/listdata',
                queryDataUrl: 'holiday/days/querydata',
                getByIdUrl: 'holiday/days/getById'
            }
        }
    },
    computed: {
        ...mapGetters([
            'loginUser'
        ])
    },
    watch: {

    },
    created() {
        this.$bus.$on('getCurDataId', this.handleCurDataId)
        this.$bus.$on('getCurYear', this.handleCurYear)
    },
    beforeDestroy() {
        this.$bus.$off('getCurDataId', this.handleCurDataId)
        this.$bus.$off('getCurYear', this.handleCurYear)
    },
    mounted() {

    },
    methods: {
        // 初始化非工作日
        // initNonWorkday() {
        //     const weekends = document.getElementsByClassName('weekend')
        //     for (let i = 0; i < weekends.length; i++) {
        //         weekends[i].classList.add('active')
        //     }
        // },
        // 清理非工作日
        clearNonWorkday() {
            const dates = document.getElementsByClassName('date')
            for (let i = 0; i < dates.length; i++) {
                dates[i].classList.remove('active')
            }
        },
        // 保存
        saveNonWorkday() {
            const id = this.curDataId
            // const id = ''
            if (id === undefined || id === '' || id === null) {
                this.$http({
                    method: 'POST',
                    contentType: 'application/x-www-form-urlencoded',
                    url: this.moduleInfo.addNewUrl
                }).then(response => {
                    if (response.code === 'success') {
                        const nid = response.body.vo.id
                        this.saveData(id, nid)
                    } else {
                        this.$message({
                            message: response.message,
                            type: 'warning'
                        })
                    }
                }, err => {
                    this.$message.error(err)
                })
            } else {
                this.saveData(id, undefined)
            }
        },
        saveData(id, nid) {
            // id为curDataId,即为页面初始化时请求后台节假日对象的id
            // nid为请求后台分配的初次添加的节假日对象的id

            // 一个id对应一条某个年度的节假日的信息
            const arr = []
            const calendarList = document.getElementsByClassName('minicalendar')
            for (let i = 0; i < calendarList.length; i++) {
                const actives = calendarList[i].getElementsByClassName('active')
                for (let j = 0; j < actives.length; j++) {
                    arr.push(actives[j].getAttribute('id'))
                }
            }
            const day = arr.join(',')
            const data = {
                'id': (id === undefined) ? nid : id,
                'day': day,
                'orgName': this.loginUser.orgName,
                'orgCode': this.loginUser.orgCode,
                'year': this.curYear
            }

            this.$http({
                method: (id === undefined || id === '' || id === null) ? 'POST' : 'PUT',
                contentType: 'application/x-www-form-urlencoded',
                data: data,
                url: (id === undefined || id === '' || id === null) ? this.moduleInfo.insertUrl : this.moduleInfo.updateUrl
            }).then(response => {
                if (response.code === 'success') {
                    this.curDataId = (id === undefined || id === '' || id === null) ? nid : id
                    this.$message({
                        message: response.message,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: response.message,
                        type: 'warning'
                    })
                }
            }, err => {
                this.$message.error(err)
            })
        },
        handleCurDataId(curDataId) {
            this.curDataId = curDataId
        },
        handleCurYear(curYear) {
            this.curYear = curYear
        }
    }
}
</script>

<style scoped lang="scss">
    .right-bar {
        float: right;
    }
</style>
