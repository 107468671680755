const moment = require('moment')
const BMap_AK = '3yStvkb0ZSeJuqV8CyU71PqWW71nPhD5' // 百度地图AK密钥
export default {
    BMap_AK,
    /**
     * @description:
     * @param {*} $scope Vue 实例化对象，传this
     * @param {*} params { busiCode, busiType, busidataId, dataUrl, memo, title } 其中dataUrl 为#号后全链接
     * @return {*}
     */
    setBrowsingHistory: function($scope, params) {
        // params
        const loginUser = $scope.$store.getters.loginUser
        const defaultParams = {
            ifBrowHistory: 1,
            openTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            readedTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            readersPersonId: loginUser.personId,
            readersName: loginUser.personName,
            status: 1
        }
        if (!params) {
            params = {}
            return console.warn('busiDataReadlog insert failed => params is null')
        }
        if ($scope.vo.status === -1) return
        const vo = Object.assign({}, Object.assign({}, defaultParams, params), defaultParams, params)
        $scope.$http({
            url: '/user/busiDataReadlog/insertBrowseHistory',
            method: 'POST',
            data: {
                'sysCode': loginUser.sysCode,
                'sysName': loginUser.sysName,
                'title': vo.title,
                'busiCode': vo.busiCode,
                'busiType': vo.busiType,
                'busidataId': vo.busidataId,
                'dataUrl': vo.dataUrl
            }
        }).then(res => {
            if (res.code !== 'success') {
                console.warn('busiDataReadlog insert failed=> ', res.message)
            }
        })
    },
    // 百度地图初始化方法，建议需要使用地图插件的页面先调用一次这个方法
    // 没有的时候会加载地图资源，有的时候会直接使用（这时候不会请求，不会占用资源，加载速度正常）
    initMapConfig() {
        return new Promise((resolve, reject) => {
            if (window.BMapGL) {
                resolve(true)
                console.warn('Map has been loaded!')
            } else {
                let counter = 0
                const resolveFunc = () => setTimeout(() => {
                    counter++
                    console.warn('initMapConfig resolveFunc counter => ', counter)
                    if (window.BMapGL && window.BMapGL.Map instanceof Function) {
                        resolve(true)
                    } else {
                        resolveFunc()
                    }
                }, 200)
                try {
                    const script = document.createElement('script')
                    script.src = '//api.map.baidu.com/api?v=1.0&&type=webgl&ak=' + BMap_AK + '&callback=initialize'
                    script.type = 'text/javaScript'
                    document.head.appendChild(script)
                    script.onload = resolveFunc
                } catch (err) {
                    reject(err)
                    console.error('Map loading failure! Message(' + err + ')')
                }
            }
        })
    }
}
