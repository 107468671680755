<template>
  <div class="login-container">
    <div class="title-container">
      <div class="title-center">
        <h3 class="title">
          <div>
            <img v-if="isShowLogo" :src="logoInfo.logoUrl" class="sidebar-logo">{{ logoInfo.title }}
          </div>

          <div>
            <el-popover
              placement="bottom-end"
              trigger="hover"
              :popper-class="'login-APPDownload'"
              @show="appDownloadPopoverShow = true"
              @hide="appDownloadPopoverShow = false"
            >
              <template slot="reference">
                <el-button type="primary" :plain="!appDownloadPopoverShow" class="login-APPDownload-btn">
                  <svg-icon icon-class="download2" />
                  <span>客户端下载</span>
                </el-button>
              </template>
              <template>
                <div class="login-APPDownload-box login-APPDownload-app">
                  <img class="login-APPDownload-img" :src="appDownloadQrCode">
                  <div class="login-APPDownload-text">扫描二维码<br>下载手机客户端</div>
                </div>
                <div class="login-APPDownload-box  login-APPDownload-pc">
                  <svg-icon class="login-APPDownload-icon" icon-class="Mac" />
                  <div class="login-APPDownload-text">Mac客户端</div>
                  <div class="login-APPDownload-download" @click.prevent="downloadPc(7)">
                    <svg-icon class="login-APPDownload-icon" icon-class="download2" />
                    <div class="login-APPDownload-text">下载Mac客户端</div>
                  </div>
                </div>
                <div class="login-APPDownload-box  login-APPDownload-pc">
                  <svg-icon class="login-APPDownload-icon" icon-class="Windows" />
                  <div class="login-APPDownload-text">Windows客户端</div>
                  <div class="login-APPDownload-download" @click.prevent="downloadPc(5)">
                    <svg-icon class="login-APPDownload-icon" icon-class="download2" />
                    <div class="login-APPDownload-text">下载Windows客户端</div>
                  </div>
                </div>
                <div class="login-APPDownload-box  login-APPDownload-pc">
                  <svg-icon class="login-APPDownload-icon" icon-class="Linux" />
                  <div class="login-APPDownload-text">Linux客户端</div>
                  <div class="login-APPDownload-download" @click.prevent="downloadPc(6)">
                    <svg-icon class="login-APPDownload-icon" icon-class="download2" />
                    <div class="login-APPDownload-text">下载Linux客户端</div>
                  </div>
                </div>
              </template>
            </el-popover>
          </div>
        </h3>
      </div>
      <lang-select class="set-language" />
    </div>
    <div class="center" :style="{backgroundImage: 'url(' + backgroundImage + ')'}">
      <div class="main">
        <div class="right">
          <div class="middle">
            <div class="box" :class="loginTab" :style="{'height':loginType==='qrCode'?'532px':''}">
              <!-- 扫码返回 -->
              <div v-if="loginType==='qrCode'" class="qrcode_back" @click="gobackLoginType">
                <i class="el-icon-back" /><span> 返 回</span>
              </div>
              <h4>
                <span v-show="loginType!='qrCode'">
                  <p class="title" :class="{'active' : loginTab == 'phone'}" @click="toggleTab('phone')"><i class="el-icon-mobile-phone" /> {{ $t('login.mobileLogin') }}</p>
                  <p class="title" :class="{'active' : loginTab == 'acc'}" @click="toggleTab('acc')"><i class="el-icon-user" /> {{ $t('login.accountLogin') }}</p>
                </span>
              </h4>
              <el-form v-if="loginType!='qrCode'" ref="loginForm" :model="loginForm" class="login-form" autocomplete="off" label-position="left">
                <el-form-item v-if="loginTab == 'phone'" prop="phone">
                  <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" :name="$t('login.usermobile')" rules="required|phone">
                    <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                      <el-input ref="phone" v-model="loginForm.phone" :placeholder="$t('login.usermobile')" suffix-icon="el-icon-mobile-phone" name="phone" type="text" @keyup.enter.native="nextFocus('password')" />
                    </el-tooltip>
                  </ValidationProvider>
                </el-form-item>
                <el-form-item v-if="loginTab == 'acc' && !loginOrgSelect" prop="orgcode">
                  <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" :name="$t('login.orgCode')" rules="required">
                    <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                      <el-input ref="orgcode" v-model="loginForm.orgcode" :placeholder="$t('login.orgCode')" suffix-icon="el-icon-office-building" name="orgcode" type="text" @keyup.enter.native="nextFocus('account')" />
                    </el-tooltip>
                  </ValidationProvider>
                </el-form-item>
                <el-form-item v-if="loginTab == 'acc' && loginOrgSelect" prop="orgcode">
                  <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="机构名称" rules="required">
                    <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                      <el-autocomplete
                        ref="orgName"
                        v-model="loginForm.orgName"
                        :fetch-suggestions="querySearchAsync"
                        :trigger-on-focus="false"
                        :hide-loading="false"
                        suffix-icon="el-icon-office-building"
                        name="orgName"
                        placeholder="机构名称"
                        style="width: 100%;"
                        @keyup.enter.native="nextFocus('account')"
                        @select="loginSelect"
                      />
                    </el-tooltip>
                  </ValidationProvider>
                </el-form-item>
                <el-form-item v-if="loginTab == 'acc'" prop="account">
                  <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" :name="$t('login.username')" rules="required">
                    <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                      <el-input ref="account" v-model="loginForm.account" :placeholder="$t('login.username')" suffix-icon="el-icon-user" name="account" type="text" @keyup.enter.native="nextFocus('password')" />
                    </el-tooltip>
                  </ValidationProvider>
                </el-form-item>
                <el-form-item prop="password">
                  <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" :name="$t('login.password')" rules="required">
                    <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                      <el-input ref="password" v-model="loginForm.password" :placeholder="$t('login.password')" suffix-icon="el-icon-lock" name="password" type="password" autocomplete="new-password" @keyup.native="checkCapslock" @blur="capsTooltip = false" @keyup.enter.native="nextFocus('kaptcha')" />
                    </el-tooltip>
                  </ValidationProvider>
                </el-form-item>
                <div v-if="verifyWay === 'slideVerify'" style="margin-bottom: 18px">
                  <SlideVerify ref="slideVerify" :disabled="slideVerifyDisabled" @confim="slideVerifyConfim" />
                </div>
                <el-row v-else>
                  <el-col :span="15">
                    <el-form-item prop="kaptcha">
                      <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" :name="$t('login.kaptcha')" rules="required">
                        <el-tooltip :disabled="errors[0]===undefined||khide" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                          <el-input ref="kaptcha" v-model="loginForm.kaptcha" :placeholder="$t('login.kaptcha')" type="text" name="kaptcha" autocomplete="off" @keyup.enter.native="nextFocus('btnSubmit')" @focus="khide=true" @blur="khide=false" />
                        </el-tooltip>
                      </ValidationProvider>
                    </el-form-item>
                  </el-col>
                  <el-col :span="9" style="text-align:right;">
                    <div class="el-image" style="cursor:pointer;" name="kaptcha-img"><img style="height:36px;border:1px solid #dcdfe6;" :src="kaptchaUrl" @click="changekaptcha"></div>
                  </el-col>
                </el-row>

                <div class="login_bottom_ben">
                  <p class="error">{{ error }}</p>
                  <el-button id="subbtn" ref="btnSubmit" :loading="loading" type="primary" :disabled="loginBtnDisable" @click.native.prevent="handleLogin" @keyup.enter.native.prevent="handleLogin">
                    {{ $t('login.logIn') }}
                  </el-button>
                  <el-row class="other">
                    <el-col :span="12"><el-checkbox v-model="remember">{{ $t('login.remember') }}</el-checkbox></el-col>
                    <el-col :span="12" style="text-align:right;"><router-link to="/retrieve">{{ $t('login.forget') }}</router-link> | <el-link href="http://www.ecinc.com.cn/">{{ $t('login.trial') }}</el-link></el-col>
                  </el-row>
                </div>
              </el-form>
              <!-- 加载扫码二维码 -->
              <div v-else>
                <div style="margin-top:15px">
                  <ValidationProvider v-if="qrCodeType!=='app'" ref="ValidationProvider" v-slot="{ errors }" name="单位名称" rules="required">
                    <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                      <el-autocomplete
                        ref="selectOrgName"
                        v-model="searchKey.orgName"
                        class="selectOrgNames"
                        :fetch-suggestions="querySearchAsync"
                        :trigger-on-focus="false"
                        :hide-loading="false"
                        :popper-append-to-body="false"
                        name="orgName"
                        placeholder="请输入您的单位"
                        style="width: 100%;"
                        suffix-icon="el-icon-search"
                        @select="handleSelect"
                        @blur="searchChange"
                      >
                        <template slot="prefix">
                          <i v-if="searchKey.orgName" class="el-input__icon el-icon-office-building"></i>
                          <span v-else class="empty-icon"><img :src="'./images/w-imgs/login/errortip.png'"></span>
                        </template>
                        <template #default="scope">
                          <div style="line-height: normal;">
                            <div style="font-size:14px;color:#333;font-weight:bold;" v-html="scope.item.orgName" />
                            <div style="font-size:12px;color:#999;margin-top:5px">{{ scope.item.orgCode }}</div>
                          </div>
                        </template>
                      </el-autocomplete>
                    </el-tooltip>
                  </ValidationProvider>
                  <div v-else class="show_selece_orgName">
                    <span class="orgname">请使用[易办公]客户端扫描二维码登录</span>
                  </div>
                </div>
                <template v-if="updateQrCode">
                  <div
                    style="text-align:center;display: table;width: 100%;margin-top:10px;position:relative;z-index:1;"
                    :style="{'margin-bottom':qrCodeType==='app'?'20px':''}"
                  >
                    <div style="display: inline-block;vertical-align: middle;width: 28px;height: 28px;">
                      <img style="width:28px;height:28px;" :src="'./images/w-imgs/login/'+(qrCodeType==='dd'?'ddlogo.png':(qrCodeType==='app'?'applogo.png':'wxlogo.png'))">
                    </div>
                    <div style="display: inline-block;vertical-align: middle;font-size:14px;margin-left:10px;font-weight: bold;">{{ qrCodeType==='dd'?'钉钉扫码登录':(qrCodeType==='app'?'易办公扫码登录':'企业微信扫码登录') }}</div>
                  </div>
                  <div v-if="qrCodeType==='wx'&&!fakeQrcode" id="wx_qrcode" />
                  <div v-if="qrCodeType==='dd'&&!fakeQrcode" id="dd_qrcode" style="position:relative;top:-20px" />
                  <div v-if="qrCodeType==='dd'&&!fakeQrcode" style="position:relative;top:-40px">
                    <p style="font-size:12px;color:#999;text-align:center;margin:0;">使用钉钉扫一扫码登录</p>
                  </div>
                  <div v-if="fakeQrcode" class="fake_qrcode">
                    <div class="fake_qrcode_shade">
                      <div>无效的二维码</div>
                    </div>
                    <div class="fake_qrcode_img">
                      <img :src="'./images/w-imgs/login/qrcode.png'">
                    </div>
                  </div>
                  <div v-if="qrCodeType==='app'" style="text-align:center;">
                    <img v-if="qrCodeSrc" :src="qrCodeSrc" style="width:200px;height:200px;">
                    <p v-show="qrCodeSrc" id="wx_reg" style="font-size:12px;color:#999;text-align:center;margin:0;margin-top:20px">使用易办公的扫一扫<span style="color:#409EFF;cursor: pointer;" @click="getLoginQrCode('app')"><i class="el-icon-refresh" /> 刷新</span> </p>
                    <p v-show="error" class="error">{{ error }}</p>
                  </div>
                </template>
                <!-- <div v-if="!updateQrCode" v-loading="!updateQrCode" :text="'正在加载...'" /> -->
              </div>
              <!-- 扫码方式 -->
              <div v-if="loginTab==='phone' && qrCodeLogin" class="qrCode_login">
                <el-divider style="font-size:12px;color:#999">扫码登录</el-divider>
                <div class="qrCode_type">
                  <span v-if="qrCodeLoginType.indexOf('wx') > -1"><img :src="'./images/w-imgs/login/wxlogo.png'" @click="getLoginQrCode('wx')"></span>
                  <span v-if="qrCodeLoginType.indexOf('dd') > -1"><img :src="'./images/w-imgs/login/ddlogo.png'" @click="getLoginQrCode('dd')"></span>
                  <span v-if="qrCodeLoginType.indexOf('app') > -1"><img :src="'./images/w-imgs/login/applogo.png'" @click="getLoginQrCode('app')"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-dialog :title="$t('login.thirdparty')" :visible.sync="showDialog">
        {{ $t('login.thirdpartyTips') }}
        <br>
        <br>
        <br>
        <social-sign />
      </el-dialog>

      <p class="copyright">{{ logoInfo.copyright }}</p>
    </div>

  </div>
</template>

<script>

import { loginMixins } from './components/loginMixins.js'
import $ from 'jquery'
if (!window.jQuery) {
    window.jQuery = $
}
export default {
    name: 'Login',
    components: { },
    directives: { focus },
    mixins: [loginMixins],
    data() {
        return {
            logoUrl: './images/w-imgs/login/logo.png',
            loginCode: './images/w-imgs/login/login-code.png',
            loginPassword: './images/w-imgs/login/login-password.png',
            login1: './images/w-imgs/login/login-1.jpg',
            login2: './images/w-imgs/login/login-2.jpg',
            login3: './images/w-imgs/login/login-3.jpg'
        }
    },
    computed: {
        backgroundImage() { // 随机背景图
            if (parseInt(Math.random() * 10 % 3) === 0) {
                return this.login1
            } else if (parseInt(Math.random() * 10 % 3) === 1) {
                return this.login2
            } else {
                return this.login3
            }
        }
    },
    watch: {

    },
    created() {
        document.title = '易臣云办公'
    },
    beforeDestroy() {
    },
    mounted() {

    },
    destroyed() {
    },
    methods: {

    }
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */
@import "~@/wapp/common/styles/element-variables.scss";
// input::-webkit-input-placeholder{
//   color: red;
// }
$dark_gray: #666;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
  .el-form-item--medium .el-form-item__content{
    line-height: 35px;
  }
}
.login-container .el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: #409EFF !important;
    border-color: #409EFF !important;
}
.login-container .el-button--primary.is-plain {
    color: #409eff !important;
    background: #ecf5ff !important;
    border-color: #b3d8ff !important;
}
.login-container .el-button--primary {
    color: #fff !important;
    background-color: #409eff !important;
    border-color: #409eff !important;
}
/* reset element-ui css */
.login-container {
  .el-input {
    input {
		-webkit-appearance: none;
		border-radius: 2px;
		color: $dark_gray;
    height:38px;
      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $cursor inset !important;
        color: $dark_gray !important;
        -webkit-text-fill-color: $dark_gray !important;
      }
    }
  }

  .el-form-item {
   // background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
    margin-bottom:18px;
  }
  .other{
    margin-bottom:8px;
  }
  .el-checkbox__label, .el-link.el-link--default{
    color:#999;
    font-size: 12px;

  }
  .el-checkbox__label:hover, .el-link.el-link--default:hover{
    color:$--color-primary;
  }

}
.copyright{
	text-align:center;
  color: $dark_gray;
  margin-bottom:0;
  padding-bottom:16px;
}
</style>

<style lang="scss" scoped>
@import "~@/wapp/common/styles/element-variables.scss";
$dark_gray:#889aa4;

.login-container {
	height: 100%;
	width: 100%;
  overflow: auto;

	.center{
		background-size:cover;
		background-position:center top;
		background-repeat: no-repeat;
		height:680px;

		.main{
			width:1000px;
			height: 100%;
			margin:0 auto;

			.right{
				float:right;
				display: table;
				height: 100%;

				.middle{
					display: table-cell;
					vertical-align:middle;
				}
			}
		}
		.box{
			position:relative;
			width: 360px;
            height: 460px;
			padding:24px 35px;
			background:#fff;
			margin: 0 auto;
			overflow: hidden;
			box-shadow:0 0 2px 1px rgba(0, 0, 0, 0.12);
            .qrcode_back{
                cursor: pointer;
                color: #409EFF;
                display: inline-block;
            }
            // .login-form{
            //     position: relative;
            //     height: calc(100% - 60px - 89px);
            //     .login_bottom_ben{
            //         position: absolute;
            //         bottom: 0;
            //         width: 100%;
            //     }
            // }
			h4{
				margin:0;
                text-align: center;
                span{
                    display: inline-block;
                    width: 100%;
                 }
				.title{
					margin-top:0;
					margin-bottom:30px;
					display: inline-block;
                    width: 49%;
					padding:5px 0;
                    cursor:pointer;
                    color:#777;
                    position: relative;
                    z-index:1;
				}
				.title.active{
					// border-bottom: 1px solid #409eff;
					// color:$--color-primary;
          color: #409eff;
				}
				img{
					position: absolute;
					width:64px;
					right:10px;
					top:10px;
					cursor:pointer;
				}
			}
            #subbtn{
                width:100%;
                margin-bottom:8px;
            }
            .other{
                margin-bottom:8px;
				font-size:12px;
				color:#999;
				a:hover{
					color:$--color-primary;
				}
            }
            .error{
                color:$--color-danger;
                font-size:12px;
                margin:0;
                margin-top:20px;
            }
            .qrCode_login{
                position: absolute;
                bottom: 30px;
                width: calc(100% - 72px);
                .el-divider{
                    .el-divider__text{ color: #999; }
                }
                .qrCode_type{
                    display: flex;
                    text-align: center;
                    &>span{
                        flex: 1;
                        img{
                            width: 36px;
                            height: 36px;
                            cursor: pointer;
                        }
                    }
                }
            }
		}
        // .phone{
        //     .el-form-item{
        //         margin-bottom:26px;
        //     }
        //     #subbtn{
        //         margin-bottom:18px;
        //     }
        // }
	}

	.tips {
		font-size: 14px;
		color: #fff;
		margin-bottom: 10px;

		span {
			&:first-of-type {
				margin-right: 16px;
			}
		}
	}

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    background:#fff;

    .title-center{
      position: relative;
      width:1000px;
      margin:0 auto;
      .login-APPDownload-btn{
        &.is-plain{
          background-color: #fff;
          &:hover{
            border-color: #3a8ee6;
          }
          &:active {
            background-color: #3a8ee6;
          }
          &:focus {
            background-color: #3a8ee6;
          }
        }
      }
    }

    .title {
       display: flex;
      justify-content:space-between;
      font-size: 26px;
      color: #777;
      line-height:90px;
      text-align: left;
      font-weight: bold;
      margin:0;

        .sidebar-logo {
        width: 32px;
        // height: 32px;
        vertical-align: middle;
        position: relative;
        top: -2px;
        }
    }

    .set-language {
      position: absolute;
      top: 36px;
      font-size: 18px;
      right: 30px;
      cursor: pointer;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
    .selectOrgNames{
        /deep/ .el-autocomplete-suggestion{
            .el-autocomplete-suggestion__wrap{
                padding: 0;
                .el-autocomplete-suggestion__list{
                &>li{
                    padding: 15px 16px;
                    position: relative;
                    &::after{
                        position: absolute;
                        content: '';
                        height: 1px;
                        background: #EBEDF0;
                        width: calc(100% - 32px);
                        left: 16px;
                        bottom: 0;
                    }
                    &:last-child::after{ display: none; }
                    &>div div{
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            }
        }
        input::-webkit-input-placeholder,
        input:-moz-placeholder,
        input::moz-placeholder,
        input:-ms-input-placeholder{
            color: #E94243!important;
        }
        /deep/ .el-input{
            .el-input__icon{ line-height: 38px; }
            .empty-icon{
                display: inline-block;
                line-height: 38px;
                margin-left: 2px;
                img{
                    width: 16px;
                    height: 16px;
                    position: relative;
                    top: 3px;
                }
            }
        }
    }
  .show_selece_orgName{
      line-height: 38px;
      text-align: center;
      .orgname{
          font-size: 13px;
          color:#333;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 5px;
          font-weight: bold;
      }
  }
  .fake_qrcode{
    text-align: center;
    position: relative;
    .fake_qrcode_shade{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,0.8);
        &>div{
            font-weight: bold;
            margin-top: 30%;
        }
    }
    .fake_qrcode_img{
        width: 200px;
        height: 200px;
        margin: 20px auto 0;
        img{
            width: 100%;
            height: 100%;
        }
    }
  }
}
</style>
<style lang="scss">
.login-APPDownload{
  width: 950px;
  height: 264px;
  padding: 20px;
  display: flex;
  .login-APPDownload-box{
    position: relative;
    flex: 1;
    margin: 10px;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .login-APPDownload-img{
      width: 100px;
      height: 100px;
    }
    .login-APPDownload-icon{
      width: 70px;
      height: 70px;
      color: #ccc;
    }
    .login-APPDownload-text{
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      text-align: center;
    }
    .login-APPDownload-btn{
      width: 138px;
      &.is-plain{
        background-color: #fff;
        &:hover{
          background-color: #3a8ee6;
        }
        &:active {
          background-color: #3a8ee6;
        }
        &:focus {
          background-color: #3a8ee6;
        }
      }
    }
    .login-APPDownload-download{
      background-color: #f7f8fa;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .login-APPDownload-icon{
        color: #999;
      }
    }
    &:hover {
      .login-APPDownload-download{
        display: flex;
      }
    }
  }
  .login-APPDownload-app{
    .login-APPDownload-text{
      margin-top: 15px;
    }
  }
  .login-APPDownload-pc{
    .login-APPDownload-text{
      margin-top: 26px;
    }
  }
  .popper__arrow{
    left: auto!important;right: 20px;
  }
 }
</style>
