<template>
    <div class="wflow-container">
        <PageHeader :sys-title="logoTitle" sys-logo="./images/articleHeadLogo.png"></PageHeader>
        <div class="mainform-container wflow-container-loaded">
            <div v-show="!updateEvents" v-if="loading" class="page-container">
                <div v-loading="loading" element-loading-text="正在加载..." />
            </div>

            <el-scrollbar v-show="updateEvents" :native="false" tag="div">
                <!-- 富文本 -->
                <div v-if="vo.editoral == 0 || !vo.editoral" class="page-container" style="background-color: #fff; width: 1320px; /* height: 1322px; */">
                    <div class="form-container" style="width: 1120px; margin: 0 auto; padding: 30px; margin-bottom: 30px">
                        <!-- <p class="l-text">{{ wfInstance.wfName }}<i class="el-icon-d-arrow-right" />{{ vo.flDocumentType===undefined?vo.type:vo.flDocumentType }}</p> -->
                        <div class="form-header">
                            <div class="form-title">{{ vo.flSubject }}</div>
                            <div class="section-title">
                                <span v-if="vo.publishTime" class="r-text">发布时间：{{ publishTime | dateTransform }}</span>
                                <span v-else class="r-text">创建时间：{{ vo.flCreateDate | dateTransform }}</span>
                                <span class="r-text">作者：{{ vo.flCreator }}</span>
                            </div>
                        </div>

                        <!-- 富文本编辑器 -->
                        <!-- <RichtextEditor v-model="vo.flContent" /> -->
                        <div class="form_main" style="margin-bottom: 36px; border-top: 1px solid #edebf0; margin: 0 0 15px 0; width: 100%" :style="{ 'border-bottom': attachments.filter((obj) => obj.fileType === 'ATTACH').length > 0 ? '1px solid #edebf0' : '' }">
                            <div v-if="vo.flContent !== '' || vo.flContent !== null || vo.flContent !== 'null'" class="form_flContent">
                                <ViewBodyArticle :article-data="vo.flContent" />
                            </div>
                        </div>
                        <!-- 附件 -->
                        <div v-for="(attach, index) in attachments.filter((obj) => obj.fileType === 'ATTACH')" id="richtextEditor" :key="index" class="attach-list">
                            <!-- 附件{{ index+1 }}： -->
                            <el-link type="primary">
                                <img :src="imgUrl(attach)" style="width: 16px; padding-top: 2px; float: left" alt="" />
                                <span>{{ attach.fileName }}&nbsp;&nbsp;&nbsp;&nbsp;{{ attach.fileSize }}</span>
                            </el-link>
                            <span class="preview" @click="handlePreview(attach)">预览</span>
                            <span class="preview" @click="handleDownload(attach)">下载</span>
                        </div>
                    </div>
                </div>
                <div v-if="vo.editoral && vo.editoral == 1 && fileType == 'BODY'" class="page-container" style="margin-top: 1px">
                    <div class="form-container form-w">
                        <div class="form_main">
                            <div class="form_flContent from-left">
                                <ViewBodyFile :fl-bodyfile="bodyfile" :wf-attach-url="wfAttachUrl" :water-mark="true" height="calc(100vh - 100px) !important" style="width: 100%" />
                            </div>
                            <div v-if="fileType == 'BODY'" class="form_flContent_right from-right">
                                <el-scrollbar :native="false" tag="div">
                                    <div class="fileNmae">
                                        <span class="name">文件信息</span>
                                    </div>
                                    <div class="datatab">发布者：{{ vo.flCreator }}</div>
                                    <div class="datatab">发布时间：{{ vo.flCreateDate | dateTransform }}</div>
                                    <!-- <div class="datatab">浏览量：{{ vo.visitNum || 0 }}次</div> -->
                                    <!-- <span
                    v-if="attachments.length > 0"
                    style="margin-left: 32px; color: #409eff; cursor: pointer"
                    class="preview"
                    @click="pdfDownload()"
                  >下载</span> -->

                                    <div v-if="attachments.some((obj) => obj.fileType === 'ATTACH')" class="master">附件</div>
                                    <div v-for="(attach, index) in attachments.filter((obj) => obj.fileType === 'ATTACH')" :key="index" class="attach-list otherattach">
                                        <el-link type="primary" :underline="false">
                                            <img :src="imgUrl(attach)" style="width: 16px; padding-top: 2px; float: left" alt="" />
                                            <span>{{ attach.fileName || attach.name }}&nbsp;&nbsp;&nbsp;&nbsp;{{ attach.fileSize }}</span>
                                        </el-link>
                                        <div class="download">
                                            <span class="preview" @click="handlePreview(attach)">预览</span>
                                            <span class="preview" @click="handleDownload(attach)">下载</span>
                                        </div>
                                    </div>
                                </el-scrollbar>
                            </div>
                        </div>
                    </div>
                    <!-- form-container -->
                </div>
                <div v-if="fileType !== 'BODY'" class="page-footer">
                    <p>Copyright&copy;{{ loginUser.orgName }}. All Rights Reserved</p>
                </div>
                <div v-if="!(vo.editoral == 1 && fileType == 'BODY')" style="height: 30px; background: #f3f3f3"></div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ViewBodyArticle from '@/wapp/common/components/ViewBodyArticle'
import { title } from '@/wapp/settings'
const moment = require('moment')

export default {
    components: {
        ViewBodyArticle
    },
    filters: {
        dateTransform: function (val) {
            if (!val) {
                return ''
            } else {
                return moment(val).format('YYYY-MM-DD HH:mm')
            }
        }
    },
    props: {},
    data() {
        return {
            fileType: '',
            attachList: [],
            loading: true,
            wfParams: {},
            wfInstance: {},
            vo: {},
            wfJsonData: {},
            curTN: {},
            attachments: [],
            updateEvents: false,
            logo: './images/titleLogo2.png',
            title: title,
            logoTitle: [],
            bodyfile: null,
            collectionStatus: false, // 收藏状态
            passageType: '',
            viewContent: '',
            base_api: ''
        }
    },
    computed: {
        ...mapGetters(['routes', 'loginUser']),
        publishTime() {
            if (this.vo.signDate !== undefined && this.vo.signDate !== null) {
                return this.vo.signDate.split('.')[0]
            } else if (this.wfInstance.endTime) {
                return this.wfInstance.endTime.substr(0, 16)
            } else {
                return this.vo.publishDate || this.$route.query.time
            }
        }
    },
    watch: {
        $route() {
            if (this.$route.query.documentTitle) {
                document.title = this.$route.query.documentTitle
            } else if (this.$route.query.logoTitle) {
                document.title = this.$route.query.logoTitle
            }
        }
    },
    created() {
        // console.log(this.loginUser, '241')
        // window.signatureBtn = () => {
        //     alert('签名')
        // }
        // window.stampBtn = () => {
        //     alert('盖章')
        // }
        this.viewContent = this.$route.query.viewContent
        console.log('this.viewContent', this.viewContent)
        this.wfParams = this.$route.query
        this.base_api = process.env.VUE_APP_BASE_API
        console.log('this.base_api', this.base_api)
        this.logoTitle = this.$route.query.logoTitle ? decodeURIComponent(this.$route.query.logoTitle).split('/') : []
        console.log('this.$route.query', this.$route.query)
    },
    beforeDestroy() {},
    mounted() {
        this.getCollectionNumber()
        this.getCollectionStatus()
        console.log('vo', this.vo)
        this.loadWfInstance()
        const cookieStyle = this.Cookies.get('uiStyle')
        let style = ''
        if (cookieStyle !== this.loginUser.uiStyle) {
            style = this.loginUser.uiStyle
            this.Cookies.set('uiStyle', style)
        } else {
            style = cookieStyle
        }
        document.querySelector('body').setAttribute('id', this.loginUser.orgCode !== 'plat' ? style : 'skinScienceBlue')
        if (this.$route.query.documentTitle) {
            document.title = this.$route.query.documentTitle
        } else if (this.$route.query.logoTitle) {
            document.title = this.$route.query.logoTitle
        }

        const dom = document.querySelector('.sidebar-logo')
        dom.style.width = '36px'
    },
    methods: {
        loadWfInstance() {
            // console.log(this.viewContent, 'this.viewContent')
            console.log('111', this, this.wfParams)
            // if (this.wfParams.type === 'zsgl') {
            this.$wfEngine.loadWfInstance(this, this.wfParams).then((rst) => {
                if (rst.code === 'success') {
                    console.log('rst', rst)
                    this.attachList = rst.body.attachList
                    if (this.attachList.length > 0) {
                        this.fileType = this.attachList[0].fileType
                    }

                    document.title = this.wfInstance.wfName + ' - ' + this.wfInstance.title
                    if (this.wfInstance.udmNodes) {
                        document.title = this.vo.flFormName + ' - ' + this.wfInstance.title
                    } else {
                        document.title = this.wfInstance.wfName + ' - ' + this.wfInstance.title
                    }
                    this.loading = false
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.updateEvents = true
                        }, 200)
                    })
                } else {
                    this.$alert(rst.message, '操作提示', {
                        type: 'error',
                        callback: () => {
                            window.close()
                        }
                    })
                }
            })
            // } else {
            //     this.loading = false
            // }
        },
        pdfDownload() {
            console.log('this.attachments', this.attachments)
            let attach = {}
            this.attachments.forEach((item) => {
                if (item.fileType === 'BODY' && item.fileName.indexOf('.pdf') > -1) {
                    attach = item
                }
            })
            if (attach.id) {
                window.open(process.env.VUE_APP_BASE_API + '/' + this.wfAttachUrl + '/downloadById?attachIds=' + attach.id)
            }
        },
        // 返回图片路径
        imgUrl(item) {
            if (Number(item.resoureType) === 1) {
                // 文件夹类型
                return 'images/w-imgs/email/folder.png'
            } else {
                // 文件类型
                var extension = item.fileName || item.name
                extension = extension.substring(extension.lastIndexOf('.'))
                if (extension === '.doc' || extension === '.docx') {
                    return 'images/w-imgs/email/word.png'
                }
                if (extension === '.ppt' || extension === '.pptx') {
                    return 'images/w-imgs/email/ppt.png'
                }
                if (extension === '.xls' || extension === '.xlsx') {
                    return 'images/w-imgs/email/excel.png'
                }
                if (extension === '.pdf') {
                    return 'images/w-imgs/email/pdf.png'
                }
                if (extension === '.txt') {
                    return 'images/w-imgs/email/txt.png'
                } else if (extension === '.audio' || extension === '.amr' || extension === '.mp3' || extension === '.wav') {
                    return 'images/w-imgs/email/audio.png'
                } else if (extension === '.video' || extension === '.mp4' || extension === '.mov') {
                    return 'images/w-imgs/email/video.png'
                } else if (extension === '.zip' || extension === '.rar') {
                    return 'images/w-imgs/email/zip.png'
                } else if (extension === '.image' || extension === '.mp4' || extension === '.jpg' || extension === '.jpeg' || extension === '.png' || extension === '.gif' || extension === '.bmp' || extension === '.svg') {
                    return 'images/w-imgs/email/picture.png'
                } else {
                    return 'images/w-imgs/email/other.png'
                }
            }
        },
        getCookie(cname) {
            var name = cname + '='
            var ca = document.cookie.split(';')
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i].trim()
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length)
                }
            }
            return ''
        },
        handlePreview(attach) {
            if (attach.id === undefined) return
            console.log('attach.fileName.split', attach.fileName.split('.')[1])
            if (attach.fileName.split('.')[1] === 'txt') {
                window.open(process.env.VUE_APP_BASE_API + '/' + this.wfAttachUrl + '/downloadById?inline=true&attachIds=' + attach.id)
                return
            }

            window.open(process.env.VUE_APP_BASE_API + '/' + this.wfAttachUrl + '/downloadById?inline=true&attachIds=' + attach.id + '&topdf=true')
        },
        handleDownload(attach) {
            if (attach.id === undefined) return
            window.open(process.env.VUE_APP_BASE_API + '/' + this.wfAttachUrl + '/downloadById?attachIds=' + attach.id)
        },

        // 收藏
        collection() {
            if (this.wfParams.readonly) {
                return
            }
            this.$http({
                contentType: 'application/x-www-form-urlencoded',
                url: '/user/collection/addNew',
                method: 'POST',
                data: {}
            }).then((res) => {
                if (res.code === 'success') {
                    console.log(res.body, '333')
                    res.body.vo.busiDataId = this.$route.query.busiDataId
                    if (this.$route.query.viewContent === '1') {
                        res.body.vo.title = this.vo.flSubject
                    } else {
                        res.body.vo.title = this.vo.subject
                    }
                    res.body.vo.type = this.passageType
                    res.body.vo.url = this.$route.fullPath
                    res.body.vo.operationType = 1
                    res.body.vo.module = this.wfInstance.module
                    res.body.vo.sysCode = this.wfInstance.sysCode
                    console.log('收藏的按钮', res)
                    this.$http({
                        contentType: 'application/x-www-form-urlencoded',
                        url: '/user/collection/insert',
                        method: 'POST',
                        data: res.body.vo
                    }).then((innerRes) => {
                        if (innerRes.code === 'success') {
                            console.log(innerRes, '解救i')
                            this.collectionStatus = !this.collectionStatus
                            this.$message.success(innerRes.message)
                            this.getCollectionNumber()
                        } else {
                            this.$message.error(innerRes.message)
                        }
                    })
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 更新阅读量

        updatePviews() {
            if (this.$route.query.readonly) return
            this.$http({
                contentType: 'application/x-www-form-urlencoded',
                url: '/edu/announcement/updatePviews',
                method: 'POST',
                data: {
                    id: this.$route.query.busiDataId,
                    type: this.$route.query.viewContent
                }
            }).then((res) => {
                this.vo.pviews++
                console.log('res', res)
            })
        },
        // 获取文章收藏状态
        getCollectionStatus() {
            this.$http({
                contentType: 'application/x-www-form-urlencoded',
                url: `/user/collection/getIfCollection?busiDataId=${this.$route.query.busiDataId}&operationType=1&ownerId=${this.loginUser.personId}`,
                method: 'GET'
            }).then((res) => {
                if (res.code === 'success') {
                    this.collectionStatus = res.body.data
                } else {
                    this.$alert.error(res.message)
                }
            })
        },
        // 获取文章收藏数量
        getCollectionNumber() {
            this.$http({
                contentType: 'application/x-www-form-urlencoded',
                url: `/user/collection/getCollectionCount?busiDataId=${this.$route.query.busiDataId}&operationType=1`,
                method: 'GET'
            }).then((res) => {
                if (res.code === 'success') {
                    this.collectionNum = res.body.data
                    console.log(res.body.data, '收藏量')
                } else {
                    this.$message.error(res.message)
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '~@/wapp/common/styles/variables.module.scss';
/deep/.view-body-article {
    margin: 20px 0 !important;
    border: none !important;
    // padding: 20px;
}
.wflow-container {
    background-color: #f3f3f3;
    font-weight: 400;
    font-size: $contentFontSize;
    /deep/ .sidebar-logo-link {
        background-color: #fff;
        .sidebar-logo {
            margin-left: 20px;
            height: 36px !important;
            width: 36px !important;
        }
    }
    .top_title {
        line-height: 34px;
        border-left: 1px solid #dee2e6;
        color: #303133;
        & > span {
            display: inline-block;
            font-size: 16px;
        }
        .main_head {
            font-size: 16px;
            &.main_head_slicer {
                // padding-right: 10px;
                // margin-right: 10px;
                position: relative;
            }
        }
        .sub_head {
            font-size: 16px;
            // margin-right: 6px;
        }
    }

    .sidebar-logo-container {
        float: none;
        width: 100%;
        height: 72px;
        line-height: 72px;
    }

    .mainform-container {
        height: calc(100vh);
        overflow-y: auto;
        .el-scrollbar {
            width: 100%;
            height: 100%;
            /deep/ .el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    height: calc(100vh - 186px) !important;
                }
            }
        }
        .page-container {
            // width: 1000px;
            box-sizing: border-box;
            // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            // width: 1200px;
            min-height: 100%;
            margin: 0 auto;
            overflow: hidden;
            // background: #eee;
            // padding: 30px;
            margin-top: 20px;
            .form-container {
                width: 1320px;
                margin: 0 auto;
                .form-header {
                    background-color: #fff;
                    .form-title {
                        font-size: 24px;
                        font-weight: bold;
                        text-align: center;
                        // margin: auto;
                        color: #303133;
                        padding: 30px 0 14px;
                        // position: relative;
                        max-width: 100%;
                        min-height: 100%;
                        // width: max-content;
                        // .form-title-child{
                        //   display: inline-block;
                        //   text-align: center;
                        // }
                        .startBox {
                            position: absolute;
                            right: 0;
                            bottom: 23px;
                            font-size: 12px;
                            display: flex;
                            align-items: center;
                            color: #999999;
                        }
                    }
                    .section-title {
                        // font-size: 14px !important;
                        overflow: hidden;
                        line-height: 22px;
                        text-align: center;
                        padding-bottom: 20px;
                        .l-text {
                            float: left;
                            margin-bottom: 0px;
                        }
                        .r-text {
                            color: #999999;
                            margin-right: 30px;
                        }
                        .r-text_r {
                            float: right;
                            color: #999999;
                            margin-right: 4px;
                        }
                    }
                    .labelBox {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        // padding-bottom: 10px;
                        .labelItem {
                            //   color: #999999;
                            //   border: 1px solid #999999;
                            padding: 5px 15px;
                            border-radius: 14px;
                            margin-right: 10px;
                            margin-bottom: 10px;
                        }
                    }
                }

                /deep/ .richtext-container {
                    margin-top: 5px;
                }
                .attachtab {
                    border-left: 4px solid #212a77;
                    font-weight: bold;
                    margin-left: 36px;
                    padding-left: 8px;
                    margin-bottom: 18px;
                    margin-top: 24px;
                }

                .attach-list {
                    // margin: 10px 0px;
                    background-color: #fff;
                    height: 39px;
                    line-height: 39px;
                    /deep/ .is-underline ::after {
                        text-decoration: none !important;
                        content: none;
                    }
                    .el-link {
                        // width: 221px;
                        margin-left: 48px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 14px;
                        line-height: 24px;
                        margin-bottom: 10px;
                        justify-content: left !important;
                        margin-right: 24px !important;
                        color: #303133;
                        :hover {
                            cursor: default;
                        }
                        .el-link--inner {
                            span {
                                display: block;
                                margin-left: 24px;
                            }
                        }
                    }
                    .el-link.is-underline:hover:after {
                        border: none !important;
                    }
                    .download {
                        margin-left: 66px;
                        margin-bottom: 10px;
                    }
                    .preview {
                        cursor: pointer;
                        color: #409eff !important;
                        width: 36px;
                        margin: 0 6px;
                    }
                }
                #richtextEditor {
                    // margin-top: 10px;
                    .el-link {
                        margin-bottom: 0 !important;
                        margin-left: 0 !important;
                        margin-right: 24px !important;
                        .el-link--inner {
                            span {
                                padding-left: 2px !important;
                            }
                        }
                    }
                }
            }

            /deep/ .el-loading-mask {
                margin-top: 100px;
            }
        }
        .page-footer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1320px;
            height: 76px;
            margin: 0 auto;
            background-color: #fff;
            border-top: #ebedf0 solid 1px;
            p {
                width: 502px;
                height: 15px;
                font-size: 13px;
                font-family: MicrosoftYaHei;
                color: #666666;
                // line-height: 24px;
                text-align: center;
                margin: 0 auto;
                // transform: translate(0, 100%);
            }
        }
    }
    .form_flContent {
        // width: 100%;
        cursor: text;
        font-size: 14px;
        line-height: 1.6;
        word-wrap: break-word;
        font-family: sans-serif, Arial, Verdana, 'Trebuchet MS', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        color: #333;
        // margin-bottom: 20px;
        background-color: #fff;
        // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
    }
    //
    .form_main {
        display: flex;
        justify-content: center;
    }
    .form_flContent_right {
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
        height: calc(100vh - 78px) !important;
        // width: 300px;
        background-color: #fff;
        .fileNmae {
            display: flex;
            align-items: center;
            // justify-content: space-around;
            margin: 16px 32px;
            height: 34px;
            span {
                height: 14px;
                line-height: 14px;
            }
            .name {
                border-left: 4px solid #212a77;
                font-weight: bold;
                padding-left: 8px;
            }
            .collet {
                color: #999999;
            }
        }
        .datatab {
            margin-bottom: 16px;
            color: #1e1e1e;
            margin-left: 32px;
        }
        .master {
            border-left: 4px solid #212a77;
            font-weight: bold;
            margin-left: 36px;
            padding-left: 8px;
            margin-bottom: 18px;
            margin-top: 12px;
        }
    }
}
/deep/ .el-form .el-table__body {
    line-height: normal;
}
.wflow-container-loaded {
    height: calc(100vh - 60px) !important;
}
.sidebar-logo-container {
    // background: var(--bgColor);
    // background-color: var(--bgColor);
    color: var(--textColor);
}
#mainContainer {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
}
#sidebarContent {
    background-color: #fff !important;
}
#bodyfilebox {
    height: calc(100vh - 80px) !important;
    background-color: #fff;
}
.main_head_slicer:nth-child(1) {
    font-size: 16px;
    margin-left: 20px;
    color: #333333;
}
.main_head_slicer {
    color: #333333;
    font-size: 16px;
}
@media (max-width: 1569px) {
    .form-w {
        // width: 1261px !important;
        width: 100% !important;
    }
    .from-right {
        width: 280px;
    }
    .from-left {
        // width:980px;
        width: calc(100% - 350px);
    }
}
@media (min-width: 1570px) {
    .form-w {
        // width: 1551px !important;
        width: 100% !important;
    }
    .from-right {
        width: 350px;
    }
    .from-left {
        // width:1570px;
        width: calc(100% - 350px);
    }
}
</style>
