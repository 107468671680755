export { formatTime, parseTime } from '@/global/utils'

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
    const si = [
        { value: 1024 * 1024 * 1024 * 1024 * 1024 * 1024, symbol: 'E' },
        { value: 1024 * 1024 * 1024 * 1024 * 1024, symbol: 'P' },
        { value: 1024 * 1024 * 1024 * 1024, symbol: 'T' },
        { value: 1024 * 1024 * 1024, symbol: 'G' },
        { value: 1024 * 1024, symbol: 'M' },
        { value: 1024, symbol: 'k' }
    ]
    for (let i = 0; i < si.length; i++) {
        if (num >= si[i].value) {
            return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
        }
    }
    return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
    return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * 1000 => 10,000.00
 */
const digitsRE = /(\d{3})(?=\d)/g
export function currency(value, currency, decimals) {
    value = parseFloat(value)
    if (!isFinite(value) || (!value && value !== 0)) return ''
    currency = currency != null ? currency : '¥'
    decimals = decimals != null ? decimals : 2
    var stringified = Math.abs(value).toFixed(decimals)
    var _int = decimals
        ? stringified.slice(0, -1 - decimals)
        : stringified
    var i = _int.length % 3
    var head = i > 0
        ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
        : ''
    var _float = decimals
        ? stringified.slice(-1 - decimals)
        : ''
    var sign = value < 0 ? '-' : ''
    return sign + currency + head +
    _int.slice(i).replace(digitsRE, '$1,') +
    _float
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Lower case first char
 * @param {String} string
 */
export function lowercaseFirst(string) {
    return string.charAt(0).toLowerCase() + string.slice(1)
}

/**
 * 对象数组排序
 * @param {*} array
 * @param {*} key
 */
export function sortKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key]
        var y = b[key]
        return ((x < y) ? -1 : (x > y) ? 1 : 0)
    })
}
