<template>
  <div>
    <GiantTree :nodes="treeData" @onCreated="onTreeCreated" @onClick="onTreeNodeClick">
      <template #context-menu="slotProps">
        <ul v-if="slotProps.contextNode.id==='0' || slotProps.contextNode.parentId==='0' ||slotProps.contextNode.menuType===0">
          <li @click="addTreeNode(slotProps.treeId,slotProps.contextNode)">新增分类</li>
          <li v-if="slotProps.contextNode.id!=='0'" @click="editTreeNode(slotProps.treeId,slotProps.contextNode)">修改分类</li>
          <li v-if="slotProps.contextNode.id!=='0'" @click="deleteTreeNode(slotProps.treeId,slotProps.contextNode)">删除分类</li>
        </ul>
        <ul v-else>
          <li @click="editTreeNode(slotProps.treeId,slotProps.contextNode)">修改分类</li>
          <li @click="deleteTreeNode(slotProps.treeId,slotProps.contextNode)">删除分类</li>
        </ul>
      </template>
    </GiantTree>
  </div>
</template>

<script>
export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            zTree: {},
            show: false,
            treeData: [],
            treeNode: {},
            loginUser: this.$store.getters.loginUser,
            folderUser: '/plugins/zTree/css/img/folder_user.png'
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        console.log(43)
        this.getTreeData()
        this.$bus.on('setOrgItemTree', this.setOrgItem)
    },
    beforeDestroy() {
        this.$bus.off('setOrgItemTree', this.setOrgItem)
    },
    mounted() {
        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    },
    methods: {
        onTreeCreated(zTree) {
            this.zTree = zTree
            this.zTree = zTree
            const treenodes = zTree.getNodes()
            if (treenodes.length === 0) {
                return false
            }
            if (treenodes[0].children === undefined) {
                this.onTreeNodeClick('', treenodes[0].id, treenodes[0])// 获取节点数据
                zTree.selectNode(treenodes[0])// 选中当前节点
                treenodes[0].tlevel = 1
            } else if (treenodes[0].children.length >= 1) {
                this.onTreeNodeClick('', treenodes[0].children[0].id, treenodes[0].children[0])// 获取节点数据
                zTree.selectNode(treenodes[0].children[0])// 选中当前节点
            } else {
                this.onTreeNodeClick('', treenodes[0].id, treenodes[0])// 获取节点数据
                zTree.selectNode(treenodes[0])// 选中当前节点
                treenodes[0].tlevel = 1
            }
        },
        setOrgItem(val) {
            this.getTreeData()
        },
        getTreeData() {
            this.$http({
                method: 'POST',
                url: 'base/helpDocumentMenu/querydata',
                contentType: 'application/x-www-form-urlencoded',
                data: [{
                    'compare': '=',
                    'field': 'status',
                    'merge': true,
                    'value': 1
                }],
                params: { 'orderBy': 'orderNo asc' }
            }).then(res => {
                if (res.code === 'success') {
                    this.treeData = this.initTreeData(res.body.listdata)
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        initTreeData(treedata) {
            treedata.unshift({ id: '0', name: '帮助中心', menuName: '帮助中心', parentId: '', orderNo: '' })
            treedata.forEach(node => {
                node.pId = node.parentId
                node.title = node.menuName
                node.name = node.menuName
                if (node.id === '0') {
                    node.checked = true
                    node.open = true
                }
            })
            return treedata
        },
        onTreeNodeClick(evt, treeId, treeNode) {
            localStorage.setItem('addVoCate', JSON.stringify(treeNode))
            this.$nextTick(() => {
                this.$bus.emit('treeClick', treeNode)
            })
        },
        addTreeNode(treeId, contextNode) {
            this.zTree.selectNode(contextNode)
            this.treeNode = contextNode
            localStorage.setItem('addVoCate', JSON.stringify(contextNode))
            this.$nextTick(() => {
                this.$bus.emit('openCateForm', null, 1)
            })
        },
        editTreeNode(treeId, contextNode) {
            this.zTree.selectNode(contextNode)
            this.treeNode = contextNode

            this.$nextTick(() => {
                this.$bus.emit('openCateForm', contextNode, 1)
            })
        },
        deleteTreeNode(treeId, contextNode) {
            this.$confirm('确定要删除“' + contextNode.name + '”分类吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http({
                    method: 'DELETE',
                    contentType: 'application/json charset=utf-8',
                    params: { ids: contextNode.id },
                    url: 'base/helpDocumentMenu/delete'
                }).then(res => {
                    if (res.code === 'success') {
                        this.zTree.removeNode(contextNode)
                        this.$message({ message: res.message, type: 'success' })
                    } else {
                        this.$alert(res.message, '提示', {
                            confirmButtonText: '确定',
                            type: 'warning'
                        })
                    }
                })
            }).catch(() => {})
        }
    }
}
</script>

  <style scoped lang="scss">
  /deep/.el-input__inner{
      border-top: 0;
      border-left: 0;
      border-right: 0;
  }
  </style>
