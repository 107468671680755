<template>
  <WfdList />
</template>

<script>
export default {

    data() {
        return {

        }
    }
}
</script>
