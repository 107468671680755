<template>
  <div>
    <el-form :model="vo">
      <div style="width:100%;" class="el-table el-table--fit el-table--border">
        <div class="el-table__body-wrapper">
          <table cellspacing="0" cellpadding="0" border="0" class="el-table__body ">
            <tbody>
              <tr>
                <td ref="tdlbl_分类名称" class="el-table__cell tdlbl tdw17" colspan="1">
                  <span class="required">*</span>
                  <span v-field-lbl="'menuName'" class="field">分类名称</span>
                </td>
                <td class="el-table__cell tdcont tdw83" colspan="5">
                  <form-item ref="menuName" :field="{'defaultValue':null,'elementCanEdit':'{${canEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'请输入分类名称','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'分类名称','propName':'menuName','propType':'String','textFormatter':'textfield','valueMaxLength':100,'valueMinLength':null,'model':'vo.menuName','option':[]}" :vo="vo" :can-edit="canEdit" />
                </td>
              </tr>
              <tr>
                <td ref="tdlbl_模块菜单代码" class="el-table__cell tdlbl tdw17" colspan="1">
                  <span class="required">*</span>
                  <span v-field-lbl="'moduleCode'" class="field">模块菜单代码</span>
                </td>
                <td class="el-table__cell tdcont tdw83" colspan="5">
                  <form-item ref="moduleCode" :field="{'defaultValue':null,'elementCanEdit':'{${canEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'请输入模块菜单代码','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'模块菜单代码','propName':'moduleCode','propType':'String','textFormatter':'textfield','valueMaxLength':null,'valueMinLength':null,'model':'vo.moduleCode','option':[]}" :vo="vo" :can-edit="canEdit" />
                </td>
              </tr>
              <tr>
                <td ref="tdlbl_菜单类型 " class="el-table__cell tdlbl tdw17" colspan="1">
                  <span class="required">*</span>
                  <span v-field-lbl="'menuType'" class="field">菜单类型 </span>
                </td>
                <td class="el-table__cell tdcont tdw83" colspan="5">
                  <form-item ref="menuType" :field="{'defaultValue':'0','elementCanEdit':'{{canEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':'0：文件夹\n1：菜单','elementReadonly':null,'elementType':'radio','emptyMessage':'请选择菜单类型 ','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'菜单类型 ','propName':'menuType','propType':'Short','textFormatter':null,'valueMaxLength':null,'valueMinLength':null,'model':'vo.menuType','option':[{'value':'0','name':'文件夹'},{'value':'1','name':'菜单'}],'optionGenerateType':0}" :vo="vo" :can-edit="canEdit" />
                </td>
              </tr>
              <tr v-if="vo.menuType===1">
                <td ref="tdlbl_是否显示标题 " class="el-table__cell tdlbl tdw17" colspan="1">
                  <span class="required">*</span>
                  <span v-field-lbl="'showTitle'" class="field">是否显示标题 </span>
                </td>
                <td class="el-table__cell tdcont tdw83" colspan="5">
                  <form-item ref="showTitle" :field="{'defaultValue':'1','elementCanEdit':'{{canEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':'0：隐藏\n1：显示','elementReadonly':null,'elementType':'radio','emptyMessage':'请选择 ','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'是否显示标题 ','propName':'showTitle','propType':'Short','textFormatter':null,'valueMaxLength':null,'valueMinLength':null,'model':'vo.showTitle','option':[{'value':'0','name':'隐藏'},{'value':'1','name':'显示'}],'optionGenerateType':0}" :vo="vo" :can-edit="canEdit" />
                </td>
              </tr>
              <tr>
                <td ref="tdlbl_分类排序" class="el-table__cell tdlbl tdw17" colspan="1">

                  <span v-field-lbl="'orderNo'" class="field">分类排序</span>
                </td>
                <td class="el-table__cell tdcont tdw83" colspan="5">
                  <form-item ref="orderNo" :field="{'defaultValue':null,'elementCanEdit':'{${canEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'请输入分类排序','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'分类排序','propName':'orderNo','propType':'String','textFormatter':'textfield','valueMaxLength':4,'valueMinLength':'4','model':'vo.orderNo','option':[]}" :vo="vo" :can-edit="canEdit" />
                </td>
              </tr>
              <tr>
                <td ref="tdlbl_状态" class="el-table__cell tdlbl tdw17" colspan="1">

                  <span v-field-lbl="'status'" class="field">状态</span>
                </td>
                <td class="el-table__cell tdcont tdw83" colspan="5">
                  <form-item ref="status" :field="{'defaultValue':'1','elementCanEdit':'{{canEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':'1：启用\n0：禁用','elementReadonly':null,'elementType':'radio','emptyMessage':'','errorMessage':null,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'状态','propName':'status','propType':'Short','textFormatter':null,'valueMaxLength':null,'valueMinLength':null,'tbFieldName':'status','model':'vo.status','option':[{'value':'1','name':'启用'},{'name':'禁用','value':'0'}],'formTdAlign':'text-left'}" :vo="vo" :can-edit="canEdit" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-form>
    <div style="margin: 20px auto;display: flex;justify-content: center;">
      <el-button v-if="(vo!==null && vo.menuType ==0) || vo.id==='0'" plain icon="el-icon-plus" @click="addObject">添加子分类</el-button>
      <el-button v-if="vo!==null && vo.id!='0'" plain icon="el-icon-edit" @click="editObject(vo)">修改</el-button>
      <el-button v-if="vo!==null && vo.id!='0'" plain icon="el-icon-close" @click="deleteObject(vo)">删除</el-button>
    </div>
  </div>

</template>

<script>
export default {
    props: {
        vo: { type: Object, default: function() { return {} } },
        canEdit: { type: Number, default: 1 },
        moduleInfo: { type: Object, default: function() { return {} } }
    },
    data: function() {
        return {
            loginUser: this.$store.getters.loginUser
        }
    },
    computed: {
    },
    watch: {
    },
    created: function() {
        this.$set(this.$vnode.context, 'wfAttach', false)
        console.log(85, this.vo)
    },
    beforeDestroy: function() {
    },
    mounted: function() {
    },
    methods: {
        addObject() {
            this.$nextTick(() => {
                this.$bus.emit('openCateForm', null, 1)
            })
        },
        editObject(vo) {
            this.$nextTick(() => {
                this.$bus.emit('openCateForm', vo, 1)
            })
        },
        deleteObject(vo) {
            this.$confirm('确定要删除“' + vo.name + '”分类吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http({
                    method: 'DELETE',
                    contentType: 'application/json charset=utf-8',
                    params: { ids: vo.id },
                    url: 'base/helpDocumentMenu/delete'
                }).then(res => {
                    if (res.code === 'success') {
                        this.$bus.emit('setOrgItemTree')
                        this.$message({ message: res.message, type: 'success' })
                    } else {
                        this.$alert(res.message, '提示', {
                            confirmButtonText: '确定',
                            type: 'warning'
                        })
                    }
                })
            }).catch(() => {})
        }
    }
}
</script>

<style scoped>
</style>
