<template>
    <div>
        <div style="padding: 10px">
            <table style="border-collapse: collapse; border: 1px solid #e2e7ed; width: 100%">
                <tr v-if="!isOne" style="height: 45px">
                    <td style="border: 1px solid #e2e7ed; text-align: right; width: 200px; background: #eef0f3; padding: 0 10px; font-size: 13px; font-weight: 700">日期</td>
                    <td style="border: 1px solid #e2e7ed; text-align: left">
                        <el-date-picker v-model="value1" class="riqi1" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"></el-date-picker>
                    </td>
                    <td style="border: 1px solid #e2e7ed; text-align: right; width: 200px; background: #eef0f3; padding: 0 10px; font-size: 13px; font-weight: 700">学校</td>
                    <td style="border: 1px solid #e2e7ed; text-align: left">
                        <el-select v-model="valueSc" style="width: 96%" placeholder="请选择">
                            <el-option v-for="item in optionsSc" :key="item.id" :label="item.schoolName" :value="item.id"></el-option>
                        </el-select>
                    </td>
                    <td style="border: 1px solid #e2e7ed; text-align: right; width: 200px; background: #eef0f3; padding: 0 10px; font-size: 13px; font-weight: 700">楼栋</td>
                    <td style="border: 1px solid #e2e7ed; text-align: left">
                        <el-select v-model="valueLd" style="width: 96%" placeholder="请选择">
                            <el-option v-for="item in optionsLd" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </td>
                </tr>
                <tr v-if="isOne" style="height: 45px">
                    <td style="border: 1px solid #e2e7ed; text-align: right; width: 200px; background: #eef0f3; padding: 0 10px; font-size: 13px; font-weight: 700">日期</td>
                    <td style="border: 1px solid #e2e7ed; text-align: left">
                        <el-date-picker v-model="value1" class="riqi" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"></el-date-picker>
                    </td>
                    <td style="border: 1px solid #e2e7ed; text-align: right; width: 200px; background: #eef0f3; padding: 0 10px; font-size: 13px; font-weight: 700">学校</td>
                    <td style="border: 1px solid #e2e7ed; text-align: left">
                        <el-select v-model="valueSc" placeholder="请选择">
                            <el-option v-for="item in optionsSc" :key="item.id" :label="item.schoolName" :value="item.id"></el-option>
                        </el-select>
                    </td>
                </tr>
                <tr v-if="isOne" style="height: 45px">
                    <td style="border: 1px solid #e2e7ed; text-align: right; width: 200px; background: #eef0f3; padding: 0 10px; font-size: 13px; font-weight: 700">年级</td>
                    <td style="border: 1px solid #e2e7ed; text-align: left">
                        <el-select v-model="valueA" placeholder="请选择">
                            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </td>
                    <td style="border: 1px solid #e2e7ed; text-align: right; width: 200px; background: #eef0f3; padding: 0 10px; font-size: 13px; font-weight: 700">班级</td>
                    <td style="border: 1px solid #e2e7ed; text-align: left">
                        <el-select v-model="valueB" placeholder="请选择">
                            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </td>
                </tr>
                <!-- <tr v-if="!isOne" style="height: 45px">

          <td
            style="
              border: 1px solid #e2e7ed;
              text-align: right;
              width: 200px;
              background: #eef0f3;
              padding: 0 10px;
            "
          >

          </td>
          <td style="border: 1px solid #e2e7ed; text-align: left">

          </td>
        </tr> -->
                <tr v-if="isOne" style="height: 55px; text-align: center">
                    <td colspan="4" style="border-top: 1px solid #e2e7ede2e7ed">
                        <div>
                            <el-button type="primary" icon="el-icon-search" @click="searchClick()">开始搜索</el-button>
                            <el-button icon="el-icon-close" @click="cleanUp">清除搜索</el-button>
                        </div>
                    </td>
                </tr>
                <tr v-if="!isOne" style="height: 55px; text-align: center">
                    <td colspan="6" style="border-top: 1px solid #e2e7ede2e7ed">
                        <div>
                            <el-button type="primary" icon="el-icon-search" @click="searchClick()">开始搜索</el-button>
                            <el-button icon="el-icon-close" @click="cleanUp">清除搜索</el-button>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="block">
            <div data-v-0e92aae7="" class="el-button-group" style="padding-left: 10px">
                <button data-v-0e92aae7="" type="button" class="el-button el-button--default el-button--medium" @click="isTwoFn">
                    <!---->
                    <i class="el-icon-menu"></i>
                    <!---->
                </button>
                <button data-v-0e92aae7="" type="button" class="el-button el-button--default el-button--medium" @click="isOneFn">
                    <!---->
                    <i class="el-icon-film"></i>
                    <!---->
                </button>
            </div>

            <div v-if="isOne" class="right">
                <div data-v-fd7a5c9c="" class="el-button-group toolbar-button-group el-popover__reference" aria-describedby="el-popover-4191" tabindex="0">
                    <button data-v-fd7a5c9c="" type="button" class="el-button el-button--default el-button--small is-plain plus_btn" @click="addForm()">
                        <span>
                            <i data-v-fd7a5c9c="" class="el-icon-plus el_icon"></i>
                            <span data-v-fd7a5c9c="">新增</span>
                        </span>
                    </button>
                    <button data-v-fd7a5c9c="" type="button" class="el-button el-button--default el-button--small is-plain import_btn" @click="importData">
                        <span>
                            <svg data-v-5d08e6e2="" data-v-fd7a5c9c="" aria-hidden="true" class="svg_icon svg-icon">
                                <use data-v-5d08e6e2="" xlink:href="#icon-import" />
                            </svg>
                            <span data-v-fd7a5c9c="">导入</span>
                        </span>
                    </button>
                    <button data-v-fd7a5c9c="" type="button" class="el-button el-button--default el-button--small is-plain export_btn" @click="exportData">
                        <span>
                            <svg data-v-5d08e6e2="" data-v-fd7a5c9c="" aria-hidden="true" class="svg_icon svg-icon">
                                <use data-v-5d08e6e2="" xlink:href="#icon-export" />
                            </svg>
                            <span data-v-fd7a5c9c="">导出</span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="isOne" class="table-box">
            <el-table v-if="scores.length > 0" id="ystbMx" :data="scores" border :span-method="objectSpanMethod" style="width: 100%" @cell-click="dataUpdate">
                <el-table-column prop="gradeClass" label="项目/班" min-width="120px" align="center"></el-table-column>

                <el-table-column prop="roomNo" label="宿舍" width="100" align="center"></el-table-column>
                <el-table-column prop="dutyStudentName" label="负责人" width="150" align="center"></el-table-column>
                <el-table-column label="电器" align="center">
                    <el-table-column prop="aircondDeduct" label="空调" width="80" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.aircondDeduct == undefined ? 0 : scope.row.aircondDeduct }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="fanDeduct" label="风扇" width="80" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.fanDeduct == undefined ? 0 : scope.row.fanDeduct }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="lampDeduct" label="灯" width="80" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.lampDeduct == undefined ? 0 : scope.row.lampDeduct }}
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column prop="floorDeduct" label="地面" width="80" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.floorDeduct == undefined ? 0 : scope.row.floorDeduct }}
                    </template>
                </el-table-column>
                <el-table-column prop="totalBedScoreDeduct" label="床铺" width="80" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.totalBedScoreDeduct == undefined ? 0 : scope.row.totalBedScoreDeduct }}
                    </template>
                </el-table-column>
                <el-table-column prop="deskDeduct" label="桌椅" width="80" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.deskDeduct == undefined ? 0 : scope.row.deskDeduct }}
                    </template>
                </el-table-column>
                <el-table-column prop="shoeDeduct" label="鞋子" width="80" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.shoeDeduct == undefined ? 0 : scope.row.shoeDeduct }}
                    </template>
                </el-table-column>
                <el-table-column prop="trunkDeduct" label="行李箱" width="80" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.trunkDeduct == undefined ? 0 : scope.row.trunkDeduct }}
                    </template>
                </el-table-column>
                <el-table-column prop="washDeduct" label="洗手台阳台" width="100" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.washDeduct == undefined ? 0 : scope.row.washDeduct }}
                    </template>
                </el-table-column>
                <el-table-column prop="toiletDeduct" label="卫生间" width="80" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.toiletDeduct == undefined ? 0 : scope.row.toiletDeduct }}
                    </template>
                </el-table-column>
                <el-table-column prop="trashDeduct" label="垃圾篓" width="80" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.trashDeduct == undefined ? 0 : scope.row.trashDeduct }}
                    </template>
                </el-table-column>
                <el-table-column label="阳台物品摆放" align="center">
                    <el-table-column prop="toolDeduct" label="卫生工具" width="100" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.toolDeduct == undefined ? 0 : scope.row.toolDeduct }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="bucketDeduct" label="桶盆" width="100" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.bucketDeduct == undefined ? 0 : scope.row.bucketDeduct }}
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="扣分" width="100" align="center">
                    <template slot-scope="scope">
                        {{ Number(scope.row.aircondDeduct) + Number(scope.row.fanDeduct) + Number(scope.row.lampDeduct) + Number(scope.row.floorDeduct) + Number(scope.row.totalBedScoreDeduct) + Number(scope.row.bucketDeduct) + Number(scope.row.toolDeduct) + Number(scope.row.trashDeduct) + Number(scope.row.toiletDeduct) + Number(scope.row.washDeduct) + Number(scope.row.trunkDeduct) + Number(scope.row.shoeDeduct) + Number(scope.row.deskDeduct) }}
                    </template>
                </el-table-column>
            </el-table>
            <div v-else style="text-align: center; border: 1px solid #f2f2f2; height: 200px">
                <img :src="img" style="max-width: 180px; margin-top: 40px" />
                <br />
                <span style="font-size: 15px; color: #dbdbdb">暂无数据</span>
            </div>
        </div>
        <div v-if="!isOne" class="table-box">
            <div class="content" style="overflow-x: auto">
                <table v-if="suSheList.length > 0" v-loading="loading" class="talleDiy" style="border-collapse: collapse; border: 1px solid #e2e7ed; width: 100%; min-width: 1057px">
                    <tr v-for="(item, index) in suSheList" :key="index" style="border: 1px solid #e2e7ed">
                        <td style="width: 100px; min-width: 100px; height: 80px; background: #f5f7fa">
                            <div style="font-size: 16px; font-weight: 700; color: #333333">{{ item.floorName }}{{ item.floor }}层</div>
                        </td>
                        <td v-for="(val, idx) in item.buildingRoomList" :key="idx">
                            <div :class="val.passenger.scored ? 'reviewed' : 'not'" style="color: #fff; border-radius: 2px; padding: 8px 0; margin: 0 8px; cursor: pointer" @click="addFormOne(val)">
                                <div style="margin-bottom: 10px">{{ val.roomNo }}</div>
                                <div>
                                    {{ val.passenger.scored ? '已评' : '未评' }}
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
                <div v-else style="text-align: center; border: 1px solid #f2f2f2; height: 200px">
                    <img :src="img" style="max-width: 180px; margin-top: 40px" />
                    <br />
                    <span style="font-size: 15px; color: #dbdbdb">暂无数据</span>
                </div>
            </div>
        </div>

        <Basicform :title="'内务检查'" :win-width="moduleInfo.winWidth" :win-height="moduleInfo.winHeight" :on-basic-form-opened="onBasicFormOpened" :on-basic-form-before-submit="onBasicFormBeforeSubmit" :on-basic-form-submited="onBasicFormSubmited">
            <template #default="slotProps">
                <busi-form style="border: none" :vo="slotProps.vo" :can-edit="slotProps.canEdit" :module-info="moduleInfo" />
            </template>
        </Basicform>
        <importNw />
    </div>
</template>

<script>
import importNw from './import/importNw.vue'
export default {
    components: { importNw },
    props: {},
    data() {
        return {
            img: './images/w-imgs/nomessage.png',
            suSheList: [],
            isOne: false,
            loginUser: this.$store.getters.loginUser,
            moduleInfo: {
                winWidth: window.mobileWidth,
                winHeight: window.mobileHeight,
                listDataUrl: '/bus/buildingRoomScore/listdata',
                delUrl: '/bus/buildingRoomScore/delete',
                updateUrl: '/bus/buildingRoomScore/update',
                addNewUrl: '/bus/buildingRoomScore/addNew',
                insertUrl: '/bus/buildingRoomScore/insert',
                getByIdUrl: '/bus/buildingRoomScore/getById',
                deleteUrl: '/bus/buildingRoomScore/delete'
            },

            valueA: '全部',
            valueB: '全部',
            valueSc: '全部',
            valueLd: '全部',
            value1: '',
            banji: '',
            options1: [
                { value: '全部', label: '全部' },
                { value: '高一', label: '高一' },
                { value: '高二', label: '高二' },
                { value: '高三', label: '高三' }
            ],
            optionsSc: [{ schoolName: '全部', id: '全部' }],
            optionsLd: [{ name: '全部', id: '全部' }],
            options2: [{ value: '全部', label: '全部' }],
            scores: [],
            arrList: [],
            listData: [],
            arr: [],
            arr2: []
        }
    },
    computed: {},
    watch: {
        valueA(val) {
            this.valueB = '全部'
            if (val === '全部') {
                this.options2 = [{ value: '全部', label: '全部' }]
                return
            }
            if (val) {
                this.$http({
                    method: 'post',
                    url: '/bus/buildingRoomScore/getGradeClass',
                    params: {
                        grade: this.valueA
                    }
                }).then((res) => {
                    console.log(21312, res)
                    if (res.code === 'success') {
                        console.log(val)

                        this.options2 = [{ value: '全部', label: '全部' }]
                        const arr = res.body.vo
                        if (arr.length > 0) {
                            arr.forEach((item) => {
                                this.options2.push({
                                    label: item + '班',
                                    value: item
                                })
                            })
                        }
                    }
                })
            }
        },
        valueSc(val) {
            if (val === '全部') {
                this.getLdAll()
            } else {
                this.getLd()
            }
        }
    },

    created() {
        var today = new Date()
        var year = today.getFullYear() // 获取年份
        var month = today.getMonth() + 1 // 获取月份（注意要加1，因为 getMonth 函数返回值范围是0~11）
        var day = today.getDate() // 获取日期

        // 使用模板字符串进行拼接，并对月份和日期进行补零处理
        var formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`

        console.log(formattedDate) // 输出结果：2023-04-22

        this.value1 = formattedDate

        this.getSchoolData()
        this.$bus.emit('showNavSearch', false)
        this.getAllBuildScore()
        this.getLdAll()
    },
    beforeDestroy() {},
    mounted() {
        this.searchClick()
    },
    methods: {
        addFormOne(val) {
            if (val.passenger.scored) {
                this.moduleInfo = {
                    winWidth: window.mobileWidth,
                    winHeight: window.mobileHeight,
                    listDataUrl: '/bus/buildingRoomScore/listdata',
                    delUrl: '/bus/buildingRoomScore/delete',
                    updateUrl: '/bus/buildingRoomScore/update',
                    addNewUrl: '/bus/buildingRoomScore/addNew',
                    insertUrl: '/bus/buildingRoomScore/insert',
                    getByIdUrl: '/bus/buildingRoomScore/getById',
                    deleteUrl: '/bus/buildingRoomScore/delete'
                }
                this.moduleInfo.formTemplate = '#/dcontent/vuebusiform/W/bus.buildingRoomScore.form.vue?sysCode=' + this.$route.meta.sysCode
                this.$options.components['BusiForm'] = this.$loadComponent(this.moduleInfo.formTemplate)
                this.$nextTick(() => {
                    this.$bus.emit('openBusiform', this.moduleInfo, { id: val.roomScoreId }, 1, (res) => {
                        if (res.code === 'success') {
                            console.log(132323, res)
                        }
                    })
                })
            } else {
                this.moduleInfo.obj = val
                this.moduleInfo.formTemplate = '#/dcontent/vuebusiform/W/bus.buildingRoomScore.form.vue?sysCode=' + this.$route.meta.sysCode
                this.$options.components['BusiForm'] = this.$loadComponent(this.moduleInfo.formTemplate)
                this.$nextTick(() => {
                    this.$bus.emit('openBusiform', this.moduleInfo, null, 1, (res) => {
                        if (res.code === 'success') {
                            console.log(132323, res)
                        }
                    })
                })
            }
        },
        getSchoolData() {
            this.$http({
                method: 'post',
                url: '/bus/school/querydata',
                data: [
                    { compare: '=', field: 'orgCode', merge: false, value: this.loginUser.orgCode },
                    { compare: '=', field: 'status', merge: false, value: 1 }
                ]
            }).then((res) => {
                console.log(res)
                if (res.code === 'success') {
                    res.body.listdata.forEach((item) => {
                        this.optionsSc.push(item)
                    })
                    // this.getLd()
                }
            })
        },
        getLd() {
            this.$http({
                method: 'post',
                url: '/bus/building/querydata',
                data: [
                    { compare: '=', field: 'schoolId', merge: false, value: this.valueSc },
                    { compare: '=', field: 'orgCode', merge: false, value: this.loginUser.orgCode },
                    { compare: '=', field: 'status', merge: false, value: 1 },
                    { compare: '=', field: 'type', merge: false, value: 1 }
                ],
                params: {
                    orderBy: 'orderNo'
                }
            }).then((res) => {
                console.log(res)
                if (res.code === 'success') {
                    if (res.body.listdata.length > 0) {
                        this.optionsLd = [{ name: '全部', id: '全部' }]
                        res.body.listdata.forEach((item) => {
                            this.optionsLd.push(item)
                        })
                    } else {
                        this.valueLd = '全部'
                        this.optionsLd = [{ name: '全部', id: '全部' }]
                    }
                }
            })
        },
        getLdAll() {
            this.$http({
                method: 'post',
                url: '/bus/building/querydata',
                data: [
                    { compare: '=', field: 'orgCode', merge: false, value: this.loginUser.orgCode },
                    { compare: '=', field: 'status', merge: false, value: 1 },
                    { compare: '=', field: 'type', merge: false, value: 1 }
                ],
                params: {
                    orderBy: 'orderNo'
                }
            }).then((res) => {
                console.log(res)
                if (res.code === 'success') {
                    if (res.body.listdata.length > 0) {
                        this.optionsLd = [{ name: '全部', id: '全部' }]
                        res.body.listdata.forEach((item) => {
                            this.optionsLd.push(item)
                        })
                    } else {
                        this.valueLd = '全部'
                        this.optionsLd = [{ name: '全部', id: '全部' }]
                    }
                }
            })
        },
        cleanUp() {
            this.value1 = new Date().toJSON().slice(0, 10)
            this.banji = ''
            this.valueA = '全部'
            this.valueB = '全部'
            this.valueSc = '全部'
            this.valueLd = '全部'
        },
        isOneFn() {
            console.log('true')
            this.isOne = true
            this.searchClick()
        },
        isTwoFn() {
            console.log('false')
            this.isOne = false
            this.searchClick()
        },
        importData() {
            this.$bus.emit('openImportForm')
        },
        onBasicFormSubmited() {
            this.searchClick()
        },
        onBasicFormOpened($scope, vo) {
            vo.isUpdate = !$scope.isnew
        },
        exportData() {
            if (this.valueSc !== '全部') {
                window.open(`apigw/bus/buildingRoomScore/exportRoomScore?date=${this.value1}&schoolId=${this.valueSc}&grade=${this.valueA}&classNo=${this.valueB}&pageNum=1&pageSize=100000&fileName=内务检查评分`)
            } else {
                window.open(`apigw/bus/buildingRoomScore/exportRoomScore?date=${this.value1}&grade=${this.valueA}&classNo=${this.valueB}&pageNum=1&pageSize=100000&fileName=内务检查评分`)
            }
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (row.rowSpan) {
                    return {
                        rowspan: row.rowSpan,
                        colspan: 1
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    }
                }
            }
        },
        onBasicFormBeforeSubmit($scope, vo, defer) {
            if (vo.scoreDate === '' || vo.scoreDate == null || vo.dutyStudentName === '' || vo.dutyStudentName == null) {
                this.$alert('日期或责任人不能为空', '提示', { type: 'warning' })
                defer.resolve(false)
            } else if (!vo.roomNo) {
                this.$alert('该责任人还未入住宿舍，不能评分', '提示', {
                    type: 'warning'
                })
                defer.resolve(false)
            } else {
                defer.resolve(true)
            }
        },
        dataFormat() {
            const mxList = this.scores
            // mxList = mxList.sort((a, b) => {
            //     return a.parent.localeCompare(b.parent, 'zh')
            // })
            const rowSpanObj = {}
            let j = 1
            for (let i = 0; i < mxList.length; i++) {
                if (i > 0) {
                    if (mxList[i].gradeClass === mxList[i - 1].gradeClass) {
                        j++
                    } else {
                        rowSpanObj[i - j] = j
                        j = 1
                    }
                    if (i === mxList.length - 1) {
                        rowSpanObj[i + 1 - j] = j
                    }
                } else {
                    rowSpanObj[0] = 1
                }
            }

            for (const k in rowSpanObj) {
                mxList[k].rowSpan = rowSpanObj[k]
            }
        },

        searchClick() {
            if (this.isOne) {
                this.scores = ''
                this.$http({
                    method: 'post',
                    url: '/bus/buildingRoomScore/getRoomScoreInfo',
                    params: {
                        grade: this.valueA,
                        classNo: this.valueB,
                        date: this.value1,
                        schoolId: this.valueSc
                    }
                }).then((res) => {
                    if (res.code === 'success') {
                        console.log('776', res, this.valueA, this.valueB, this.value1)
                        const arr = []
                        Object.keys(res.body.vo).forEach((key) => {
                            arr.push({
                                list: res.body.vo[key]
                            })
                        })

                        this.arr = arr
                        console.log(arr, 12121)

                        this.arr2 = []
                        for (let i = 0; i < arr.length; i++) {
                            console.log(arr[i].list, 1234)
                            this.arr1 = arr[i].list
                            for (let j = 0; j < this.arr1.length; j++) {
                                this.arr2.push(this.arr1[j])
                            }
                            this.scores = this.arr2
                        }
                        this.dataFormat()
                    }
                })
            } else {
                this.suSheList = []
                this.$http({
                    method: 'post',
                    url: '/bus/buildingRoomScore/selectRoomScoreInfo',
                    params: {
                        buildingId: this.valueLd,
                        date: this.value1,
                        schoolId: this.valueSc
                    }
                }).then((res) => {
                    if (res.code === 'success') {
                        console.log(res)
                        if (res.body.listdata.length > 0) {
                            this.suSheList = []
                            res.body.listdata.forEach((item) => {
                                if (item.passenger.floorList && item.passenger.floorList.length > 0) {
                                    item.passenger.floorList.forEach((val) => {
                                        this.suSheList.push(val)
                                    })
                                }
                            })
                            console.log('this.suSheList', this.suSheList)
                        }
                    }
                })
            }
        },

        addForm() {
            this.moduleInfo = {
                winWidth: window.mobileWidth,
                winHeight: window.mobileHeight,
                listDataUrl: '/bus/buildingRoomScore/listdata',
                delUrl: '/bus/buildingRoomScore/delete',
                updateUrl: '/bus/buildingRoomScore/update',
                addNewUrl: '/bus/buildingRoomScore/addNew',
                insertUrl: '/bus/buildingRoomScore/insert',
                getByIdUrl: '/bus/buildingRoomScore/getById',
                deleteUrl: '/bus/buildingRoomScore/delete'
            }
            this.moduleInfo.formTemplate = '#/dcontent/vuebusiform/W/bus.buildingRoomScore.form.vue?sysCode=' + this.$route.meta.sysCode
            this.$options.components['BusiForm'] = this.$loadComponent(this.moduleInfo.formTemplate)
            this.$nextTick(() => {
                this.$bus.emit('openBusiform', this.moduleInfo, null, 1, (res) => {
                    if (res.code === 'success') {
                        console.log(132323, res)
                    }
                })
            })
        },

        dataUpdate(row, column, cell, event) {
            console.log(row, column, cell, event)
            if (column.property === 'roomNo') {
                this.moduleInfo = {
                    winWidth: window.mobileWidth,
                    winHeight: window.mobileHeight,
                    listDataUrl: '/bus/buildingRoomScore/listdata',
                    delUrl: '/bus/buildingRoomScore/delete',
                    updateUrl: '/bus/buildingRoomScore/update',
                    addNewUrl: '/bus/buildingRoomScore/addNew',
                    insertUrl: '/bus/buildingRoomScore/insert',
                    getByIdUrl: '/bus/buildingRoomScore/getById',
                    deleteUrl: '/bus/buildingRoomScore/delete'
                }
                this.moduleInfo.formTemplate = '#/dcontent/vuebusiform/W/bus.buildingRoomScore.form.vue?sysCode=' + this.$route.meta.sysCode
                this.$options.components['BusiForm'] = this.$loadComponent(this.moduleInfo.formTemplate)
                this.$nextTick(() => {
                    this.$bus.emit('openBusiform', this.moduleInfo, { id: row.id }, 0, (res) => {
                        if (res.code === 'success') {
                            console.log(123)
                        }
                    })
                })
            }
        },

        getAllBuildScore() {
            this.$http({
                method: 'post',
                url: '/bus/buildingRoomScore/getRoomScoreInfo',
                params: {
                    grade: this.valueA,
                    classNo: this.valueB,
                    date: this.value1
                }
            }).then((res) => {
                console.log(111, res)
                if (res.code === 'success') {
                    this.options = res.body.vo
                    if (this.options.length > 0) {
                        this.value = this.options[0].id
                    }
                }
            })
        }

        // getGrade() {
        //     this.$http({
        //         method: 'post',
        //         url: '/bus/buildingRoomScore/getGradeClass',
        //         params: {
        //             grade: this.valueA
        //         }
        //     }).then((res) => {
        //         console.log(222, res)
        //         if (res.code === 'success') {
        //             this.options2 = []
        //             const arr = res.body.vo

        //             // const arr1 = arr.filter((item, index, array) => {
        //             //     return array.indexOf(item) === index
        //             // })
        //             if (arr.length > 0) {
        //                 arr.forEach((item) => {
        //                     this.options2.push({
        //                         label: item + '班',
        //                         value: item
        //                     })
        //                 })
        //             }
        //         }
        //     })
        // }
    }
}
</script>

<style scoped lang="scss">
.talleDiy {
    margin-top: 10px;
    td {
        border: 1px solid #e2e7ed;
        text-align: center;
        height: 40px;
    }
    th {
        border: 1px solid #e2e7ed;
        text-align: center;
        height: 40px;
    }
}
.right {
    margin-right: 10px;
    float: right;
    display: flex;
    justify-content: flex-end;
}

div.block {
    margin: 10px 0;
    width: 100%;
    // background-color: red;
}
.el-select {
    margin: 0 5px;
    width: 98%;
}
.riqi {
    margin: 0 5px;
    width: 98%;
}
.riqi1 {
    margin: 0 5px;
    width: 96%;
}
.table-box {
    width: 100%;
    padding: 0 10px 20px;
}

.el-table {
    overflow: hidden;
}
.reviewed {
    background: #409eff;
}
.not {
    background: #ccc;
}
</style>
