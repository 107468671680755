<template>
  <div id="print" class="print-container" style="width:1000px;height:calc(100% - 10px);margin:auto;">
    <div class="form-container form-container-print">
      <!-- 处理状态 -->
      <div class="form-header header">
        <div class="form-title">{{ vo.flFormName }}</div>
        <div class="section-title">
          <!-- <p class="l-text"> -->
          <p v-if="vo.status !== 8" class="l-text">当前状态：{{ (curTN.tnNodeName===""||!curTN.tnNodeName)?vo.wfNodeNames:curTN.tnNodeName }}</p>
          <p v-if="vo.status === 8" class="l-text">当前状态：已归档</p>
        </div>
      </div>
      <el-form v-if="!loading" :model="vo" class="footer">
        <div class="el-table el-table--fit el-table--border">
          <div class="el-table__body-wrapper border" :style="{'border-bottom':(showBorder?'1px solid #545454':'none')}">
            <!-- 业务表单 -->
            <BusiForm ref="busiForm" :wf-instance="wfInstance" :vo="vo" :cur-t-n="curTN" :can-edit="canEdit" />
            <BodyFile v-if="(curTN.tnCanEdit==1&&bodyFileEditor>=2) || (bodyfile!=null)" :body-file-title="$tx('ecwapp.Wflowform.BodyFile.fileBody', '文件正文')" :vo="vo" :cur-t-n="curTN" :fl-bodyfile="bodyfile" :attachments="attachments" :file-is-must="fileIsMust" :wf-attach-url="wfAttachUrl" :file-need-sign="fileNeedSign" accept=".doc,.pdf,.ofd" viewer_dis="dcs=http://portal.ecinc.com.cn:18080/dcscloud" />
            <!-- 公文管理 发文 上传正文/显示正文 -->
            <!-- <BodyFile :body-file-title="$tx('ecwapp.Wflowform.BodyFile.fileBody', '文件正文')" v-if="(curTN.tnCanEdit==1&&bodyFileEditor>=2&&wfInstance.module==='fawen'&&wfInstance.todoNodeNames==='文印室排版') || (wfInstance.module==='fawen'&&bodyfile!=null)" :vo="vo" :cur-t-n="curTN" :fl-bodyfile="bodyfile" :attachments="attachments" :wf-attach-url="wfAttachUrl" :file-need-sign="fileNeedSign" accept=".doc,.pdf,.ofd" viewer_dis="dcs=http://portal.ecinc.com.cn:18080/dcscloud" /> -->
            <!-- 富文本编辑器 -->
            <RichtextEditor v-if="bodyFileEditor==1" v-model="vo.flContent" toolbar="multimedia" :height="500" />
            <!-- 历史意见 -->
            <HandleIdea1 :wf-idea-list="wfIdeaList" :show-empty-label="true" :wf-json-data="wfJsonData" />
            <div v-if="wfInstance.module==='carApply'" class="apply">
              <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
                <tbody>
                  <tr v-if="wfInstance.todoNodeNames==='党政办公室审批'||wfInstance.todoNodeNames==='党政办公室阅知'||wfInstance.status===2">
                    <td ref="tdlbl_车辆信息" class="el-table__cell tdlbl tdw17" colspan="1">
                      车辆信息
                    </td>
                    <td class="el-table__cell tdcont tdw83" colspan="5">
                      <form-item ref="carMsg" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':70,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'textarea','emptyMessage':'请输入车辆信息','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'车辆信息','propName':'carMsg','propType':'String','textFormatter':'textarea','valueMaxLength':null,'valueMinLength':null,'model':'vo.carMsg','option':[],'autosize':false}" :vo="vo" :can-edit="canEdit" :autosize="false" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="wfInstance.module==='sendCarApply'" class="apply">
              <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
                <tbody>
                  <tr v-if="(wfInstance.todoNodeNames==='党政办公室办理'||wfInstance.status===2)">
                    <td ref="tdlbl_车辆信息" class="el-table__cell tdlbl tdw17" colspan="1">
                      车辆信息
                    </td>
                    <td class="el-table__cell tdcont tdw83" colspan="5">
                      <form-item ref="carMsg" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':70,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'textarea','emptyMessage':'请输入车辆信息','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'车辆信息','propName':'carMsg','propType':'String','textFormatter':'textarea','valueMaxLength':null,'valueMinLength':null,'model':'vo.carMsg','option':[],'autosize':false}" :vo="vo" :can-edit="canEdit" :autosize="false" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div />
          </div></div>
        <Attachment
          ref="attachments"
          :cur-t-n="curTN"
          :vo="vo"
          :fl-bodyfile="bodyfile"
          :attachments="attachments"
          online-file=".doc,.docx,.xls,.xlsx,.ppt,.pptx"
          :wf-attach-url="wfAttachUrl"
          :viewer="onlineViewer"
          :show-download-link="true"
          :online-editor="officeType"
          :batch-save-namefield="vo.flSubject+'['+vo.flCreateDept+'].zip'"
          show-mode="sidebar"
        /></el-form>

      <H5Office v-if="bodyFileEditor==2&&(officeType=='yzh5'||officeType=='wpsh5')" :office-type="officeType" />

    </div> <!-- form-container -->
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { mapGetters } from 'vuex'
// import H5Office from '../../webOffice/H5Office'
// import HandleIdea1 from './components/HandleIdea1'

export default {
    components: {
        // H5Office
    },
    props: {
        type: { type: String, default: '' }
    },
    data() {
        return {
            loading: true,
            wfParams: {},
            wfInstance: {},
            formEvents: null,
            vo: {},
            commandBar: [],
            wfIdeaList: [],
            wfJsonData: {},
            submitHandle: null,
            commonIdeaList: [],
            submitTemplateList: [],
            curTN: {},
            attachments: [],
            bodyFileEditor: 0,
            bodyfile: null,
            fileNeedSign: 0,
            officeType: 'ecexe',
            canEdit: 0,
            showBorder: false
        }
    },
    computed: {
        ...mapGetters([
            'routes',
            'loginUser'
        ])
    },
    watch: {
        formEvents() {
            if (this.formEvents !== null) {
                this.formEvents.onWflowFormOpened(this, this.wfParams, this.wfParams, this.wfEngineUrl)
            }
        }
        // 'curTN.tnCanEdit': {
        //     handler(val) {
        //         this.canEdit = val
        //     }
        // }
    },
    created() {
        this.submitMode = window.submitMode
        this.wfParams = this.$route.query
    },
    beforeDestroy() {

    },
    mounted() {
        const cookieStyle = this.Cookies.get('uiStyle')
        let style = ''
        if (cookieStyle !== this.loginUser.uiStyle) {
            style = this.loginUser.uiStyle
            this.Cookies.set('uiStyle', style)
        } else {
            style = cookieStyle
        }
        document.querySelector('body').setAttribute('id', this.loginUser.orgCode !== 'plat' ? style : 'skinScienceBlue')
        this.$bus.$on('refreshBorder', (showBorder) => {
            this.showBorder = showBorder
            this.$forceUpdate()
        })

        this.loadWfInstance()

        window.onbeforeprint = function(event) {
            var containerPrint = document.querySelector('.form-container-print .border')
            containerPrint.style['border-left'] = '1.5px solid #545454'
            containerPrint.style['border-right'] = '1.5px solid #545454'
        }
        window.onafterprint = function(event) {
            var printContainer = document.querySelector('.print-container')
            printContainer.style['overflow-y'] = 'auto'
            var containerPrint = document.querySelector('.form-container-print .border')
            containerPrint.style['border-left'] = '1px solid #545454'
            containerPrint.style['border-right'] = '1px solid #545454'
        }
    },
    methods: {
        loadWfInstance() {
            this.$wfEngine.loadWfInstance(this, this.wfParams).then(rst => {
                if (rst.code === 'success') {
                    if (this.vo.passenger.flTypeOptions) {
                        delete this.vo.passenger.flTypeOptions
                    }
                    this.curTN.tnCanEdit = 0
                    if (this.wfInstance.udmNodes) {
                        document.title = this.vo.flFormName + ' - ' + this.wfInstance.title
                    } else {
                        document.title = this.wfInstance.wfName + ' - ' + this.wfInstance.title
                    }
                    this.officeType = this.wfInstance.passenger.officeType || 'wpsjs'

                    let workform = this.curTN.workform || this.wfInstance.workForm
                    // workform = '@/wapp/ecoa/dzgg/form'
                    if (workform.indexOf('@/') === 0) {
                        workform = workform.replace(/@\/mapp/g, '@/wapp')
                        this.$options.components['BusiForm'] = this.$loadComponent(workform)

                        this.$nextTick(() => {
                            this.formEvents = {
                                'onWflowFormOpened': this.$options.components['BusiForm'].methods.onWflowFormOpened,
                                'onWflowFormBeforeSubmit': this.$options.components['BusiForm'].methods.onWflowFormBeforeSubmit,
                                'onWflowFormSubmited': this.$options.components['BusiForm'].methods.onWflowFormSubmited
                            }
                            const formCompData = this.$options.components['BusiForm'].data()
                            if (formCompData.bodyFileEditor) {
                                this.bodyFileEditor = formCompData.bodyFileEditor
                                this.fileNeedSign = formCompData.fileNeedSign
                            }
                        })
                    } else if (workform.indexOf('/dcontent') !== -1) {
                        workform = workform.split('/dcontent')[1]
                        if (workform.substring(workform.length - 1) === '/') workform = workform.substring(0, workform.length - 1)
                        workform = '#/dcontent/vuebusiform/W/' + workform + '.vue?sysCode=' + this.wfInstance.sysCode
                        this.$options.components['BusiForm'] = this.$loadComponent(workform)
                    }
                    this.loading = false

                    if (this.curTN.openedReadItemList && this.curTN.openedReadItemList.length > 0) {
                        const btnRefresh = window.opener.document.querySelector('.el-icon-refresh')
                        if (btnRefresh !== null) btnRefresh.parentElement.click()
                    }

                    this.$nextTick(() => {
                        setTimeout(() => {
                            if (this.type === 'saveas') {
                                // 返回base64图片地址
                                html2canvas(document.getElementById('print'), {
                                    // async: true,
                                    // dpi: window.devicePixelRatio * scale,
                                    scale: 1 // 添加的scale 参数
                                }).then(canvas => {
                                    const dataURL = canvas.toDataURL('image/png')
                                    this.$bus.emit('dataurl', dataURL)
                                    // _this.downloadPic(this.dataURL, '处理单')
                                })
                            } else {
                                const list = document.querySelectorAll('.content-column div')
                                list.forEach(item => {
                                    item.style.whiteSpace = 'normal'
                                })
                                window.print()
                            }
                        }, 1500)
                    })
                } else {
                    this.$alert(rst.message, '操作提示', { type: 'error', callback: () => {
                        window.close()
                    } })
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    /deep/.sidebar-area{
        border-bottom: 1px solid #545454 !important;
        .sidebar-area-title{
            border-bottom: 1px solid #545454 !important;
            border-right:0.5px solid #545454 !important;
        }
        .sidebar-area-content{
            border-right: 0.5px solid #545454 !important;
            padding: 5px 20px !important;
        }
    }
    /deep/ .sidebar-area:last-child{
        width: calc(100% - 1px);
        border-left: 0.5px solid #545454 !important;
        border-left: 0.5px solid #545454 !important;
    }

</style>
<style lang="scss" media="screen">
    * {
        -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
        color-adjust: exact !important;                 /*Firefox*/
    }
    .form-container-print .tablelist{
        border-top: 1px solid #545454!important;
    }
    // .print-container{
    //     overflow-y:hidden;
    // }
    // page-break-after:always!important;
    // *,*:before, *:after{
    //     box-sizing:	unset!important;
    // }
    // .el-table--border::after, .el-table--group::after{
    //     width: 0.5px!important;
    //     background-color: #545454!important;
    //     box-sizing: unset!important;
    // }
    // .el-table--border:first{
    //      border: 1px solid #545454!important;
    // }
    // .cke_reset{
    //     border: none !important;
    // }
    // .cke_chrome{
    //     border-right: 1px solid #545454!important;
    //     border-top: 1px solid #545454!important;
    // }
    // .el-table--border::after, .el-table--group::after{
    //     width: 0!important;
    // }
    .form-container-print .richtext-container{
        border-top: 0.5px solid #545454!important;
        margin-top: 0 !important;
        border-right: 0.5px solid #545454!important;
    }
    // .el-table--border::after, .el-table--group::after, .el-table::before{
    //     // display: none;
    //     background-color:#545454!important;
    // }
    // .el-table{
    //     color:#545454!important;
    //     font-weight:600!important;
    // }
    // .el-table__row{
    //     color:#545454!important;
    //     font-weight:600!important;
    // }
    .form-container-print .show-money-up span{
        border-right:0.5px solid #545454!important;
    }
    .form-container-print .show-money-up span:last-child{
        border-right:none!important;
    }
    .form-container-print .el-table--border td, .form-container-print .el-table--border th, .form-container-print .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
        border-right:1px solid #545454!important;
        border-bottom:1px solid #545454!important;
    }

    .form-container-print .el-table::before {
        left: 0;
        bottom: 0;
        width: 0!important;
        height: 1px;
    }
    .form-container-print .border{
        border-left:1px solid #545454;
        border-top:0.5px solid #545454;
        border-right:1px solid #545454;
        // border-bottom: 0.5px solid #545454;
    }
    .form-container-print .el-table td,  .form-container-print .el-table th.is-leaf{
        // border-bottom: none!important;
        border-bottom: 0.5px solid #545454!important;
    }
    .form-container-print .el-table--border td,  .form-container-print .el-table--border th,  .form-container-print .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
        // border: 1px solid #545454!important;
         border-right: 0.5px solid #545454!important;
    }
   .form-container-print .el-table__header-wrapper .cell {
        // color: #606266;
        color:#545454!important;
    }
    .form-container-print .slicer{
        color:#606266;
        font-weight: 600;
    }
    .form-container-print .select_people .tablelist .el-table .el-table__body-wrapper table tbody tr:last-child td {
        border-bottom: 0!important;
    }
    .form-container-print .select_people .tablelist .el-table .el-table__header-wrapper table thead tr th:nth-last-child(2) {
        border-right: 0!important;
    }
    .form-container-print .select_people .tablelist .el-table .el-table__body-wrapper table tbody tr:last-child td:last-child {
        border-right: 0!important;
    }
     .form-container-print .select_people .tablelist .el-table .el-table__body-wrapper table tbody tr td:last-child {
        border-right: 0!important;
    }
    .form-container-print{
        width:800px;
        // height: 100%;
        min-height: 600px;
        padding-top: 40px;
        margin:auto;
        // overflow-y: auto!important;
        // scrollbar-width: none; /* firefox */
        // -ms-overflow-style: none; /* IE 10+ */
        // overflow-x: hidden;
        // overflow-y: auto;
        // &::-webkit-scrollbar {
        //     display: none; /* Chrome Safari */
        // }
        .form-header{
            .form-title{
                font-size: 24px;
                font-weight: normal;
                text-align: center;
                color: #545454;
            }
            .section-title{
                overflow: hidden;
                line-height: 22px;
                // font-size: 14px;
                .l-text{
                    // float: left;
                    margin-bottom: 0px;
                    text-align: left;
                }
            }
        }
        .apply{
            width:100%;
        }
        .el-table__body{
                width: 100%;
                margin: 0 auto;
                // border: 1px solid #545454!important;
                // border-collapse: collapse;
            // width:100%;
            // margin:0 auto;
            // // margin-bottom:20px;
            // border:none!important;
            // border-top: 1px solid #EBEEF5!important;
            // border-left: 1px solid #EBEEF5!important;
            // border: 1px solid #545454!important;
            // border-collapse: collapse;
            // td{
            //     padding: 4px;
            //     height: 39px;
            //     line-height: 39px;
            //     border:none!important;
            //     border-right: 1px solid #EBEEF5!important;
            //     border-bottom: 1px solid #EBEEF5!important;
            // }
            // td:last-child{
            //     border-right: none!important;
            // }
            // .el-table__row .column_number{
            //     border-right: none!important;
            // }
            .tdlbl{
                text-align: right;
                font-weight: 600;
            }
            .tdcont{
                text-align: left;
                font-weight: 400;
            }
            .tdw17{
                width:17%;
                text-align: right;
                // font-weight: 600;
                padding: 4px;
                height: 39px;
                line-height: 39px;
                // border: 1px solid #545454!important;

            }
            .tdw33{
                width:33%;
                text-align: left;
                // font-weight: 600;
                padding: 4px;
                height: 39px;
                line-height: 39px;
                // border: 1px solid #545454!important;
            }
            .tdw83{
                width:83%;
                text-align: left;
                // font-weight: 600;
                padding: 4px;
                height: 39px;
                line-height: 39px;
                // border: 1px solid #545454!important;
            }
        }
        #innerTable{
            .el-table__body{
                border-left: none!important;
                border-right: none!important;
            }
        }
        .idealist{
            td{
                line-height:20px;
            }
            .ideaDiv{
                margin: 5px 0;
                p{
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
                span{
                    display:inline-block;
                }
                .ideaSignature{
                    max-width:100px;
                    max-height:50px;
                }
            }
            .ideaContentDiv{
                min-height: 55px;
            }
        }
        .wfIdeas-container{
            display: none;
        }
    }

    #print {
        .el-form .el-table__body .tdlbl {
            border-bottom: 1px solid #000 !important;
        }
        .el-form .el-table::after {
           // border-right: 1px solid #000;
        }

        .el-table--border{
            border: 0px solid !important;
        }
    }

    // @page {
    //     size: A4 landscape;
    //     margin-left:10px;
    //     margin-right:10px;
    //     margin-top:37px;
    //     margin-bottom:20px;
    //     @top-center { content: element(header) };
    //     @bottom-center { content: element(footer) } ;
    // }
    // .header {
    //     position: running(header);
    //     text-align:left;
    // }
    // .footer {
    //     position: running(footer);
    //     font-size: 90%;
    //     text-align:center;
    // }
    // .content{}
    // #pagenumber:before {
    //     content: counter(page);
    // }
    // #pagecount:before {
    //     content: counter(pages);
    // }

//    .form-container .form-header .form-title{font-size: 24px;font-weight: normal;text-align: center;color: #545454;}
//     .form-container .form-header .section-title{overflow: hidden;line-height: 22px;}
//     .form-container .form-header .section-title .l-text{float: left;margin-bottom: 0px;}
//     .form-container .el-table__body{width:100%;margin:0 auto;margin-bottom:20px;border:none!important;border-top: 1px solid #EBEEF5!important;border-left: 1px solid #EBEEF5!important}
//     .form-container .el-table__body td{padding: 4px;height: 39px;line-height: 39px;border:none!important;border-right: 1px solid #EBEEF5!important;border-bottom: 1px solid #EBEEF5!important;}
//     .form-container .el-table__body .tdlbl{text-align: right;font-weight: 600;}
//     .form-container .el-table__body .tdcont{text-align: left;font-weight: 400;}
//     .form-container .el-table__body .tdw17{width:17%}
//     .form-container .el-table__body .tdw33{width:33%}
//     .form-container .el-table__body .tdw83{width:83%}
//     .form-container .idealist td{line-height:20px;}
//     .form-container .idealist .ideaDiv{ margin: 5px 0;}
//     .form-container .idealist .ideaDiv p {margin-top: 0px; margin-bottom: 0px;}
//     .form-container .idealist .ideaDiv span {display:inline-block;}
//     .form-container .idealist .ideaContentDiv{min-height: 55px;}
//     .form-container .idealist .ideaDiv .ideaSignature {max-width:100px;max-height:50px;}
</style>
<style media="print">
    @page{ size: auto;margin: 0mm; }
    .attachment-sidebar .sidebar-area{
            /* border-left: 1px solid; */
    }
</style>
