<template>
  <div>
    <work-condition-echart ref="WorkConditionEchart" @echartsClick="echartsClick" />
    <work-condition-list id="exportTable" ref="WorkConditionList" />
  </div>
</template>
<script>
import WorkConditionEchart from './WorkConditionEchart'
import WorkConditionList from './WorkConditionList'
export default {
    name: '',
    components: { WorkConditionEchart, WorkConditionList },
    props: {
        dataType: { type: String, default: '' }
    },
    data() {
        return {
            selectDept: '',
            chooseDate: []
        }
    },
    watch: {
        dataType(val) {
            this.changeDate()
        }
    },
    created() {
        this.selectDept = this.$vnode.context.selectDept
        this.orgCode = this.$vnode.context.orgCode
        this.chooseDate = this.$vnode.context.chooseDate
        this.$bus.emit('showNavSearch', false) // 取消右上搜索按钮
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true) // 还原右上搜索按钮
    },
    methods: {
        changeDate() {
            this.selectDept = this.$vnode.context.selectDept
            this.orgCode = this.$vnode.context.orgCode
            this.chooseDate = this.$vnode.context.chooseDate
            this.$nextTick(() => {
                this.$refs.WorkConditionEchart.getData()
                this.$refs.WorkConditionList.getData()
            })
        },
        echartsClick(id) {
            this.selectDept = id
            this.$nextTick(() => {
                this.$refs.WorkConditionList.getData()
            })
        }
    }
}
</script>
