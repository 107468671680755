const elementIcons = [
    'info',
    'error',
    'success',
    'warning',
    'question',
    'back',
    'arrow-left',
    'arrow-down',
    'arrow-right',
    'arrow-up',
    'caret-left',
    'caret-bottom',
    'caret-top',
    'caret-right',
    'd-arrow-left',
    'd-arrow-right',
    'minus',
    'plus',
    'remove',
    'circle-plus',
    'remove-outline',
    'circle-plus-outline',
    'close',
    'check',
    'circle-close',
    'circle-check',
    'zoom-out',
    'zoom-in',
    'd-caret',
    'sort',
    'sort-down',
    'sort-up',
    'tickets',
    'document',
    'goods',
    'sold-out',
    'news',
    'message',
    'date',
    'printer',
    'time',
    'bell',
    'mobile-phone',
    'service',
    'view',
    'menu',
    'more',
    'more-outline',
    'star-on',
    'star-off',
    'location',
    'location-outline',
    'phone',
    'phone-outline',
    'picture',
    'picture-outline',
    'delete',
    'search',
    'edit',
    'edit-outline',
    'rank',
    'refresh',
    'share',
    'setting',
    'upload',
    'upload2',
    'download',
    'loading'
]

export default elementIcons
