<template>
  <Basicform
    ref="basicform"
    :title="formTitle"
    :win-width="moduleInfo.winWidth"
    :win-height="moduleInfo.winHeight"
    :module-code="moduleInfo.moduleCode"
    :toolbar-btns="busiformBtns"
    :on-basic-form-before-open="onBasicFormBeforeOpen"
    :on-basic-form-opened="onBasicFormOpened"
    :on-basic-form-before-submit="onBasicFormBeforeSubmit"
    :on-basic-form-submited="onBasicFormSubmited"
  >
    <template #default="slotProps">
      <busi-form ref="busiform" :vo="slotProps.vo" :can-edit="slotProps.canEdit" :module-info="moduleInfo" />
    </template>
  </Basicform>
</template>

<script>
const moment = require('moment')
export default {
    data: function() {
        return {
            loginUser: {},
            moduleInfo: {
                'formTemplate': 'dcontent/busiformurl?vwtype=W&url=/yykq/personSch/manage_form.html',
                'addNewUrl': 'yykq/personSch/addNew',
                'insertUrl': 'yykq/personSch/insert',
                'getByIdUrl': 'yykq/personSch/getById',
                'updateUrl': 'yykq/personSch/update',
                'delUrl': 'yykq/personSch/delete',
                'listDataUrl': 'yykq/personSch/listdata',
                'winWidth': 1000,
                'winHeight': 600,
                'moduleCode': 'personSch',
                'openViewType': '0',
                'listAdvSearchAllFields': 0,
                'listAdvSearchExpandPanel': 0
            },
            busiformBtns: [],
            formTitle: '批量排班'
        }
    },
    created() {
        let formurl = this.moduleInfo.formTemplate.split('url=')[1]
        formurl = formurl.replace(/\//g, '.')
        formurl = '#/dcontent/vuebusiform/W/' + formurl.substring(1).replace('.html', '.vue')
        formurl += '?sysCode=' + (this.$route.meta.sysCode || '')
        this.$options.components['BusiForm'] = this.$loadComponent(formurl)
    },
    methods: {
        openSchedulesAddForm(cb) {
            this.$bus.emit('openBusiform', this.moduleInfo, null, 1, cb)
        },
        /**
        * 表单打开检查，vo为已加载personSch对象，允许打开：defer.resolve(true)，阻止打开：defer.reject(reason)
        */
        onBasicFormBeforeOpen: function($scope, vo, defer) {
            console.log('personSch form before open')
            var userTypeOptions = [{ 'text': '指定群组', 'value': '2' }, { 'text': '指定人员', 'value': '4' }]
            if (this.$store.state.user.roles.indexOf('kqManager') !== -1) {
                userTypeOptions.unshift({ 'value': '3', 'text': '指定部门' })
                if (vo.isnew) vo.userType = 3
            }
            this.$set(vo.passenger, 'userTypeOptions', userTypeOptions)
            defer.resolve(true)
        },
        /**
         * 表单打开完成，vo为已加载的personSch对象，前端页面dom对象已完成初始化、已经呈现，不需要返回值
        */
        onBasicFormOpened: function($scope, vo) {
            console.log('personSch form opened')
            if (!vo.classesName) {
                vo.classesName = []
            }
        },
        /**
         * 表单提交事件，可执行业务数据高级校验，vo为待提交的personSch对象，校验成功：defer.resolve(true)，校验失败：defer.reject(reason)
         */
        onBasicFormBeforeSubmit: function($scope, vo, defer) {
            // 直接复制在线表单方法覆盖 以在线表单的为准
            console.log('personSch form submit validation success', vo)
            function deepClone(val) {
                return JSON.parse(JSON.stringify(val))
            }
            function getTimes(start, end) {
                var result = []
                var currentDate = null
                var startDate = new Date(start)
                var endDate = new Date(end)
                currentDate = startDate.getTime()
                result.push(start)
                while (currentDate < endDate.getTime()) {
                    var tempDate = new Date(currentDate)
                    tempDate.setDate(tempDate.getDate() + 1)
                    currentDate = tempDate.getTime()
                    result.push(moment(tempDate).format('YYYY-MM-DD'))
                }
                return result
            }

            if (vo.schWay == '2' && (vo.pbDate === null || vo.pbDate.length === 0)) {
                this.$message.warning('请选择排班日期！')
                return defer.resolve(false)
            }
            // 时间数据处理
            var times = null
            if (vo.schWay == '2') {
                times = deepClone(vo.pbDate)
            } else {
                times = getTimes(vo.startDate, vo.endDate)
            }
            // 排班数据处理
            var personSchInfoList = []
            var timeSpan = []
            var otherTimeSpan = []
            var classesName = []
            var classesId = []
            if (vo.timeSpan) {
                timeSpan = deepClone(vo.timeSpan)
            }
            if (vo.otherSchedules) {
                otherTimeSpan = deepClone(vo.otherSchedules)
            }
            var resultTimeSpan = timeSpan.concat(otherTimeSpan)
            if (resultTimeSpan.length === 0) {
                this.$message.warning('至少添加一个班次！')
                return defer.resolve(false)
            }

            times.forEach(function(time) {
                resultTimeSpan.forEach(function(timeSpan) {
                    var temp = deepClone(timeSpan)
                    temp.personSchId = vo.id
                    temp.cycleTime = time
                    temp.workOrRest = 1
                    temp.status = 1
                    personSchInfoList.push(temp)
                })
            })
            for (var j = 0; j < resultTimeSpan.length; j++) {
                var currentSpan = resultTimeSpan[j]
                if (currentSpan.timeId) classesId.push(currentSpan.timeId)
                classesName.push(currentSpan.timeName)
            }
            // 存储一份需要处理的数据，方便提交失败时重新赋值
            var defaultFormData = {
                classesName: deepClone(vo.classesName),
                timeSpan: deepClone(vo.timeSpan),
                pbDate: deepClone(vo.pbDate)
            }
            vo.classesName = classesName.join(',')
            vo.classesId = classesId.join(',')
            vo.personSchInfoList = personSchInfoList
            vo.timeSpan = resultTimeSpan
            vo.pbDate = times.join(',')
            setTimeout(function() {
                vo.classesName = deepClone(defaultFormData.classesName)
                vo.pbDate = deepClone(defaultFormData.pbDate)
                setTimeout(function() {
                    vo.timeSpan = deepClone(defaultFormData.timeSpan)
                }, 50)
            }, 50)
            this.$loading({
                target: '.el-dialog__body',
                text: '正在提交中'
            })
            defer.resolve(true)
        },
        /**
        * 表单提交完成，vo为已保存成功的personSch对象，窗口即将关闭，不需要返回值
        */
        onBasicFormSubmited: function($scope, vo) {
            console.log('personSch form submited')
        }
    }
}
</script>

<style>

</style>
