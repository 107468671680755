<template>
  <!-- <div class="app-container"> -->
  <!-- <PageContent v-loading="!showRule" :title="'考勤规则'"> -->
  <div v-if="showRule" class="out-box">
    <el-row :gutter="20">
      <el-col :span="4"><span>班次时段跨两日时:</span></el-col>
      <el-col :span="20">
        <el-radio-group v-model="vo.classBgnDay">
          <el-radio v-for="(day,daykey) in classBgnDayOption" :key="daykey" :disabled="canEdit===0" :label="day.val">{{ day.text }}</el-radio>
        </el-radio-group>
      </el-col>
      <el-col :span="4"><span>外勤规则:</span></el-col>
      <el-col :span="20">
        <el-radio-group v-model="vo.outWorkRule">
          <el-radio v-for="(outWork,outWorkkey) in outWorkRuleOption" :key="outWorkkey" :disabled="canEdit===0" :label="outWork.val">{{ outWork.text }}</el-radio>
        </el-radio-group>
      </el-col>
      <el-col :span="4"><span>加班规则:</span></el-col>
      <el-col :span="20">
        <el-radio-group v-model="vo.workOvertimeRule">
          <el-radio v-for="(workOver,workOverkey) in workOvertimeRuleOption" :key="workOverkey" :disabled="canEdit===0" :label="workOver.val">{{ workOver.text }}</el-radio>
        </el-radio-group>
      </el-col>
      <el-col :span="4"><span>销假规则:</span></el-col>
      <el-col :span="20">
        当月
        <el-select v-model="vo.cancelDay" :disabled="canEdit===0" class="inpurRule" placeholder="请选择">
          <el-option v-for="day in days" :key="day" :label="day" :value="day" />
        </el-select>
        日前可进行核销上月请休假。
      </el-col>
    </el-row>
    <el-row class="tow-row">
      <el-col :span="24">
        <span>每月从</span>
        <el-select v-model="vo.monthStart" :disabled="canEdit===0" class="inpurRule" placeholder="请选择">
          <el-option v-for="day in days" :key="day" :label="day" :value="day" />
        </el-select>
        <span>日开始</span>
      </el-col>
      <el-col :span="12">
        <span>上班超过</span>
        <validation-provider v-slot="{ errors }" name=" " rules="required|integer|min_value:0">
          <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
            <el-input v-model="vo.comeLateTime" :disabled="canEdit===0" class="inpurRule" />
          </el-tooltip>
        </validation-provider>
        <span>分钟签到记迟到</span>
      </el-col>
      <el-col :span="12">
        <span>下班提前</span>
        <validation-provider v-slot="{ errors }" name=" " rules="required|integer|min_value:0">
          <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
            <el-input v-model="vo.leaveEarlyTime" :disabled="canEdit===0" class="inpurRule" />
          </el-tooltip>
        </validation-provider>
        <span>分钟签退记早退</span>
      </el-col>
      <el-col :span="12">
        <el-checkbox v-model="backupvo.absenceComeLate.check" :disabled="canEdit===0" @change="checkbosChaneg('absenceComeLate')" />
        <span>一次迟到大于</span>
        <validation-provider v-slot="{ errors }" name=" " rules="integer|min_value:0">
          <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
            <el-input v-model="vo.absenceComeLate" :disabled="!backupvo.absenceComeLate.check || canEdit===0" class="inpurRule" @change="inputChange('absenceComeLate')" />
          </el-tooltip>
        </validation-provider>
        <span>分钟记旷工</span>
      </el-col>
      <el-col :span="12">
        <el-checkbox v-model="backupvo.absenceLeaveEarly.check" :disabled="canEdit===0" @change="checkbosChaneg('absenceLeaveEarly')" />
        <span>一次早退大于</span>
        <validation-provider v-slot="{ errors }" name=" " rules="integer|min_value:0">
          <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
            <el-input v-model="vo.absenceLeaveEarly" :disabled="!backupvo.absenceLeaveEarly.check || canEdit===0" class="inpurRule" @change="inputChange('absenceLeaveEarly')" />
          </el-tooltip>
        </validation-provider>
        <span>分钟记旷工</span>
      </el-col>
      <el-col :span="12">
        <el-checkbox v-model="backupvo.beforeOvertimeSpan.check" :disabled="canEdit===0" @change="checkbosChaneg('beforeOvertimeSpan')" />
        <span>上班前签到计加班的时间间隔</span>
        <validation-provider v-slot="{ errors }" name=" " rules="integer|min_value:0">
          <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
            <el-input v-model="vo.beforeOvertimeSpan" :disabled="!backupvo.beforeOvertimeSpan.check || canEdit===0" class="inpurRule" @change="inputChange('beforeOvertimeSpan')" />
          </el-tooltip>
        </validation-provider>
        <span>分钟</span>
      </el-col>
      <el-col :span="12">
        <el-checkbox v-model="backupvo.maxBeforeOvertime.check" :disabled="canEdit===0" @change="checkbosChaneg('maxBeforeOvertime')" />
        <span>限制上班前最大加班时长为</span>
        <validation-provider v-slot="{ errors }" name=" " rules="integer|min_value:0">
          <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
            <el-input v-model="vo.maxBeforeOvertime" :disabled="!backupvo.maxBeforeOvertime.check || canEdit===0" class="inpurRule" @change="inputChange('maxBeforeOvertime')" />
          </el-tooltip>
        </validation-provider>
        <span>分钟</span>
      </el-col>
      <el-col :span="12">
        <el-checkbox v-model="backupvo.afterOvertimeSpan.check" :disabled="canEdit===0" @change="checkbosChaneg('afterOvertimeSpan')" />
        <span>下班后签退计加班的时间间隔</span>
        <validation-provider v-slot="{ errors }" name=" " rules="integer|min_value:0">
          <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
            <el-input v-model="vo.afterOvertimeSpan" :disabled="!backupvo.afterOvertimeSpan.check || canEdit===0" class="inpurRule" @change="inputChange('afterOvertimeSpan')" />
          </el-tooltip>
        </validation-provider>
        <span>分钟</span>
      </el-col>
      <el-col :span="12">
        <el-checkbox v-model="backupvo.maxAfterOvertime.check" :disabled="canEdit===0" @change="checkbosChaneg('maxAfterOvertime')" />
        <span>限制下班后最大加班时长为</span>
        <validation-provider v-slot="{ errors }" name=" " rules="integer|min_value:0">
          <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
            <el-input v-model="vo.maxAfterOvertime" :disabled="!backupvo.maxAfterOvertime.check || canEdit===0" class="inpurRule" @change="inputChange('maxAfterOvertime')" />
          </el-tooltip>
        </validation-provider>
        <span>分钟</span>
      </el-col>
      <el-col :span="24">
        <span>年休假初始化日期：</span>
        <validation-provider v-slot="{ errors }" name="日期" rules="required">
          <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
            <el-date-picker v-model="vo.holidayInitDate" :editable="false" type="date" :disabled="canEdit===0" value-format="yyyy-MM-dd" format="MM-dd" placeholder="选择日期" style="width:110px" />
          </el-tooltip>
        </validation-provider>
      </el-col>
    </el-row>
  </div>
  <!-- <div slot="footer">
        <el-button plain icon="el-icon-check" @click="submitForm">保存</el-button>
      </div> -->
  <!-- </PageContent> -->
  <!-- </div> -->
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {

    },
    props: {
        personSchVo: { type: Object, default: function() { return null } },
        canEdit: { type: Number, default: 1 }
    },
    data() {
        return {
            formtype: '',
            vo: {},
            backupvo: {},
            showRule: false,
            classBgnDayOption: [
                { val: 1, text: '记为第一日' },
                { val: 2, text: '记为第二日' }
            ],
            outWorkRuleOption: [
                { val: 2, text: '外勤打卡' },
                { val: 3, text: '外勤申请' }
            ],
            workOvertimeRuleOption: [
                { val: 1, text: '忽略该加班状态' },
                { val: 2, text: '直接记为加班' },
                { val: 3, text: '以审批时间计加班时长' },
                { val: 4, text: '审批后，以打卡时间计加班' }
            ],
            days: []
        }
    },
    computed: {
        ...mapGetters([
            'loginUser'
        ])
    },
    watch: {

    },
    created() {
        this.$bus.on('saveRule', this.submitForm)
        const month = []
        for (let i = 0; i <= 31; i++) {
            if (i > 0) {
                month.push(i)
            }
        }
        this.days = month
    },
    beforeDestroy() {
        this.$bus.off('saveRule', this.submitForm)
    },
    mounted() {
        if (this.personSchVo !== null) {
            this.getRule()
        }
    },
    methods: {
        // 获取规则
        getRule() {
            this.$http({
                method: 'post',
                contentType: 'application/x-www-form-urlencoded',
                data: [
                    { 'compare': '=', 'field': 'id', 'merge': true, 'value': this.personSchVo.ruleId || '' }
                ],
                url: 'yykq/rule/querydata'
            }).then(response => {
                if (response.code === 'success') {
                    // 如果大于零  该单位存在考勤规则
                    if (response.body.listdata.length > 0) {
                        this.vo = response.body.listdata[0]
                        this.initBackupvo(response.body.listdata[0])
                        this.formtype = 'updata'
                    } else {
                        // 否则初始化新增表单
                        this.$http({
                            method: 'post',
                            contentType: 'application/x-www-form-urlencoded',
                            url: 'yykq/rule/addNew'
                        }).then(res => {
                            if (res.code === 'success') {
                                this.formtype = 'addnew'
                                delete res.body.vo.passenger
                                this.initvo(res.body.vo)
                            }
                        })
                    }
                }
            })
        },
        // 初始化备份vo
        initBackupvo(vo) {
            const backvo = {}
            for (const key in vo) {
                backvo[key] = {}
                backvo[key].check = vo[key] > 0
                backvo[key].value = vo[key]
            }
            this.backupvo = backvo
            this.showRule = true
        },
        // 初始化数据
        initvo(vo) {
            for (const key in vo) {
                if (key === 'classBgnDay') {
                    vo[key] = 1
                } else if (key === 'outWorkRule') {
                    vo[key] = 2
                } else if (key === 'workOvertimeRule') {
                    vo[key] = 2
                } else if (key === 'holidayInitDate') {
                    vo[key] = new Date().getFullYear() + '-03-01'
                } else {
                    if (key !== 'orgCode' && key !== 'orgName' && key !== 'id') {
                        vo[key] = 1
                    }
                }
            }
            this.$set(this.personSchVo, 'ruleId', vo.id)
            this.vo = vo
            this.initBackupvo(vo)
        },
        // 选择改变时
        checkbosChaneg(module) {
            if (this.backupvo[module].check) {
                if (this.backupvo[module].value > 0) {
                    this.vo[module] = this.backupvo[module].value
                } else {
                    this.vo[module] = 1
                    this.backupvo[module].value = 1
                }
            } else {
                this.backupvo[module].value = this.vo[module]
                this.vo[module] = 0
            }
        },
        // 输入框改变是
        inputChange(module) {
            if (this.vo[module] === '' || Number(this.vo[module]) === 0) {
                this.vo[module] = 0
                this.backupvo[module].check = false
                this.backupvo[module].value = 0
            }
        },
        submitForm(callback) {
            if (this.vo.comeLateTime === '' || this.vo.leaveEarlyTime === '') {
                this.$message({ message: '数据不能为空,若不需要请填0', type: 'warning' })
                return
            }
            this.formValidate(() => {
                if (this.formtype === 'updata') {
                    this.$http({
                        method: 'PUT',
                        contentType: 'application/x-www-form-urlencoded',
                        data: this.vo,
                        url: 'yykq/rule/update'
                    }).then(response => {
                        if (response.code === 'success') {
                            // this.$message({ message: '保存成功', type: 'success' })
                            // callback({ code: 'success' })
                        } else {
                            // callback(response)
                            this.$message.error(response.message)
                        }
                    })
                } else if (this.formtype === 'addnew') {
                    this.$http({
                        method: 'POST',
                        contentType: 'application/x-www-form-urlencoded',
                        data: this.vo,
                        url: 'yykq/rule/insert'
                    }).then(response => {
                        if (response.code === 'success') {
                            // this.getRule()
                            // callback({ code: 'success' })
                            // this.$message({ message: '保存成功', type: 'success' })
                        } else {
                            // callback(response)
                            this.$message.error(response.message)
                        }
                    })
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .out-box{
        padding:0 10px;
        text-align: left;
        font-size: 14px;
        border: 1px solid #EBEEF5;
        overflow: hidden;
        margin:0 20px;
        .el-col{
            line-height: 48px
        }
        .el-col.el-col-4{
            font-weight: 600;
            width: 150px;
        }
        .el-row:first-child{
            border-bottom: 1px solid #EBEEF5;
        }
        .inpurRule{
            width: 70px;
        }
        .tow-row{
            .el-col{
                line-height: 50px;
            }
        }
    }
</style>
