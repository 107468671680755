<template>
  <div
    id="searchContent"
    style="  max-width:1200px;
  margin: 0 auto;
  background-color:#FFFFFF; height: 780px;"
  >
    <!-- <div
      style="
    height: 44px;
    line-height: 44px;
    font-size: 12px;
    color: #666666;
       margin: 0 20px;
    border-bottom: 1px solid #EEEEEE ;display: flex;"
    >
      <div style="margin-top: 1px;margin-right: 5px;">
        <svg
          aria-hidden="true"
          class="svg-icon"
          style="height: 12px; width: 12px"
          v-on="$listeners"
        >
          <use :xlink:href="'#icon-wjjs_ssuo'" />
        </svg>
      </div>
      <div>全局搜索</div>
    </div> -->
    <div class="file_search_results">
      <div class="file_search_results_explain">
        <div class="results_explain_left">
          <span>关于&nbsp;&nbsp;</span>
          <span class="results_explain_red results_explain_text_middle">“{{ searchKey }}</span>
        </div>
        <div class="results_explain_right">
          <div class="results_explain_text_end">
            <span class="results_explain_red">”&nbsp;&nbsp;</span>
            <span>共找到{{ pageConfig.pageTotal }}条搜索结果</span>
          </div>
          <!-- <div style="cursor: pointer" @click="clearSearchKey">
            <i class="el-icon-delete" />清除搜索
          </div> -->
        </div>
      </div>
      <div v-if="type!=='通讯录'" class="file_search_results_list">
        <div
          v-for="item in fileSearchList"
          :key="item.id"
          class="results_list_cell"
        >
          <div v-if="item.attachType !=='news'" class="results_list_cell_head">
            <div
v-if="item.attachName"
                 class="results_list_cell_title"
                 @click="item.attachId?clickCellTitle(item):openBusiform(item)"
                 v-html="item.attachName"
            />
            <div
              v-else
              class="results_list_cell_title"
              @click="item.attachId?clickCellTitle(item):openBusiform(item)"
              v-html="item.title"
            />
            <!-- <span v-if="item.attachType !=='frm'">.{{ item.attachType }}</span> -->
            <!-- v-html="item.attachId?item.title:item.formTitle||item.title" -->
            <div v-if="item.category">
              <div class="results_list_cell_type">{{ item.category }}</div>
            </div>
          </div>
          <div v-if="type === '文档' && item.type !== 'wfAttach'" class="results_list_cell_text">
            <div style=" display: flex;">
              <div v-if="item.logoUrl">
                <img v-if="item.logoUrl.includes('http://') || item.logoUrl.includes('https://')" style="border-radius:3px;width:200px;height:126px;" :src="item.logoUrl" class="photo">
                <img v-else style="border-radius:3px;width:200px;height:126px;" :src="api+'/'+item.logoUrl" class="photo">
              </div>
              <div v-else style="border-radius:3px;width:200px;height:126px;"> <div
                style="border-radius:3px; width: 100%; height: 100%;text-align: center;line-height: 126px;background-color: #f5f7fa;  color: #C0C4CC;font-size: 14px;"
              >加载失败</div></div>
              <div style="    padding-left: 15px;">
                <div class="results_list_cell_head">
                  <div
                    v-if="item.attachName"
                    class="results_list_cell_title"
                    @click="item.attachId?clickCellTitle(item):openBusiform(item)"
                    v-html="item.attachName"
                  />
                  <div
                    v-else
                    class="results_list_cell_title"
                    @click="item.attachId?clickCellTitle(item):openBusiform(item)"
                    v-html="item.title"
                  />
                  <!-- v-html="item.attachId?item.title:item.formTitle||item.title" -->
                  <div v-if="item.category">
                    <div class="results_list_cell_type">{{ item.category }}</div>
                  </div>
                </div>
                <span v-html="item.content" />
                <div class="results_list_cell_explain">
                  <div class="results_list_cell_level" v-html="item.createDate" />
                  <div class="results_list_cell_division" />
                  <div class="results_list_cell_formtitle" @click="openBusiform(item)" v-html="item.formTitle" />
                  <div class="results_list_cell_ministry">
                    <svg-icon icon-class="ministry" class="cell_ministry_icon" />
                    <span class="cell_ministry_text">{{ item.user }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <span class="results_list_cell_date">{{
              dateTransform(item.createDate)
            }}</span> -->
            <!-- <div v-if="item.logoUrl"><img style="border-radius:3px;width:200px;height:126px;float: left;" :src="api+'/'+item.logoUrl" class="photo"></div> -->
            <!-- <div style="border-radius:3px;width:200px;height:126px;float: left;"> <span>加载失败</span></div>
            <span v-html="item.content" />
            <div class="results_list_cell_explain">
              <div class="results_list_cell_level" v-html="item.createDate" />
              <div class="results_list_cell_division" />
              <div class="results_list_cell_formtitle" @click="openBusiform(item)" v-html="item.formTitle" />
              <div class="results_list_cell_ministry">
                <svg-icon icon-class="ministry" class="cell_ministry_icon" />
                <span class="cell_ministry_text">{{ item.user }}</span>
              </div>
            </div> -->
          </div>
          <div v-else class="results_list_cell_text">
            <span class="results_list_cell_date">{{
              dateTransform(item.createDate)
            }}</span>
            <span style="display: block;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;height: 18px;" v-html="item.content" />
          </div>
          <div v-if="item.attachType !=='news'" class="results_list_cell_explain">
            <div v-if="item.attachId" class="results_list_cell_level">附件的表单</div>
            <!-- <div class="results_list_cell_division" /> -->
            <div class="results_list_cell_formtitle" @click="openBusiform(item)" v-html="item.formTitle" />
            <div class="results_list_cell_ministry">
              <svg-icon icon-class="ministry" class="cell_ministry_icon" />
              <span class="cell_ministry_text">{{ item.user }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="type==='通讯录'">
        <telCenter ref="telCenter" @setTotal="setTotalFn" />
      </div>

      <div v-if="!fileSearchList.length&&type!=='通讯录'" class="file_search_results_nosearch">
        <img
          :src="'./images/w-imgs/nosearch.png'"
          style="width: 240px; vertical-align: middle"
        >
        <div class="results_nosearch_text">抱歉，无相关内容</div>
      </div>
      <div v-if="type!=='通讯录'" style="text-align:center;margin-top: 30px;">
        <el-pagination
          v-if="pageConfig.pageTotal"
          :current-page="pageConfig.pageNum"
          :page-sizes="pageConfig.pageSizes"
          :page-size="pageConfig.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageConfig.pageTotal"
          @size-change="pageSizeChange"
          @current-change="pageIndexChange"
        />
      </div>
      <Basicform
        v-if="showView=='forms'"
        :title="title"
        :win-width="moduleInfo.winWidth"
        :win-height="moduleInfo.winHeight"
        :module-code="moduleInfo.moduleCode"
        :on-basic-form-before-open="onBasicFormBeforeOpen"
        :on-basic-form-opened="onBasicFormOpened"
        :on-basic-form-before-submit="onBasicFormBeforeSubmit"
        :append-to-body="true"
        :wf-attach="true"
        :batch-save-namefield="'attachmentName'"
        :toolbar-btns="formBtns"
      >
        <template #default="slotProps">
          <busi-form :vo="slotProps.vo" :can-edit="slotProps.canEdit" :module-info="moduleInfo" />
        <!-- <FileForm
          :vo="slotProps.vo"
          :can-edit="slotProps.canEdit"
          :module-info="moduleInfo"
        /> -->
        </template>
      </Basicform>
    </div>
  </div>
</template>

<script>
import telCenter from './telCenter'
export default {
    components: { telCenter },
    props: {
        // searchKey: { type: String, default: function() { return '' } }
    },
    data() {
        return {
            showView: '',
            // 搜索词
            searchKey: '',
            type: '', // 搜索数据类型
            // 文件搜索列表
            fileSearchList: [],
            // 分页配置
            pageConfig: {
                pageNum: 1,
                pageSize: 5,
                pageSizes: [5],
                pageTotal: 0
            },
            title: '分类文件',
            moduleInfo: {
                getByIdUrl: '/gwgl/gdwj/getById',
                listDataUrl: '',
                addNewUrl: '/gwgl/gdwj/addNew',
                insertUrl: '/gwgl/gdwj/insert',
                updateUrl: '/gwgl/gdwj/update',
                uploadUrl: '/gwgl/gdwj/upload',
                moduleCode: 'gdwj',
                winWidth: 1000, // 查看、编辑窗口宽度
                winHeight: 650 // 查看、编辑窗口高度
            },
            formBtns: [
                {
                    id: 'btn_close',
                    btnName: '关 闭',
                    btnIcon: 'close',
                    btnClick: 'closeDialog',
                    btnPermission: '',
                    btnType: ''
                }
            ],
            loginUser: this.$store.getters.loginUser,
            syslist: [],
            tSessionId: '',
            api: process.env.VUE_APP_BASE_API,
            noGeglData: [],
            geglData: [],
            newsType: []
        }
    },
    computed: {},
    watch: {
        '$route.query': {
            immediate: true,
            deep: true,
            handler() {
                // debugger
                // this.searchKeyChange(searchKey)
            }
        }
        // searchKey: {
        //     immediate: true,
        //     deep: true,
        //     handler() {
        //         this.getFileSearchList()
        //         // this.searchKeyChange(searchKey)
        //     }
        //     // this.getFileSearchList()
        //     // this.searchKeyChange(val)
        // }
    },
    created() {
        this.$bus.on('getListData', this.getListData)
        this.getGwglData()
        this.getNewType()
    },
    beforeDestroy() {
        this.$bus.off('getListData', this.getListData)
    },
    mounted() {
        this.getSysTeamList()
        this.getSessonId()
        // console.log('表单里面搜索的值', this.searchKey)
    },
    methods: {
        setTotalFn(val) {
            // console.log('setTotalFn', val)
            this.pageConfig.pageTotal = val
        },
        getListData(val) {
            if (val.type) {
                this.type = val.type
                this.searchKey = val.key
                this.getFileSearchList()
            }
        },
        // 获取公文管理对应流程
        getGwglData() {
            this.$http({
                url: 'homepage/findAllCreateWflowMenu',
                method: 'get',
                params: {
                    productCode: 'ecgwgl'
                }
            }).then(res => {
                if (res.code === 'success') {
                    res.body.listdata.forEach(item => {
                        this.geglData.push(item.wfName)
                        this.noGeglData.push('!' + item.wfName)
                    })
                    this.noGeglData.push('!通讯录')

                    // console.log('geglData', this.geglData)
                    // console.log('noGeglData', this.noGeglData)
                }
            })
        },
        // 获取所有新闻分类
        getNewType() {
            this.$http({
                method: 'post',
                url: 'portal/cms/articleCate/listdata',
                params: {
                    pageNum: 1,
                    pageSize: 60000,
                    listpageViewUrl: 'portal.cms.articleCate.list.vue'
                }
            }).then(res => {
                if (res.code === 'success') {
                    res.body.pageInfo.list.forEach(item => {
                        this.newsType.push(item.name)
                    })
                    // console.log('newsType', this.newsType)
                }
            })
        },
        // 搜索词改变
        // searchKeyChange(searchKey) {
        //     // this.searchKey = searchKey
        //     this.getFileSearchList()
        // },
        // 清空搜索词
        sousuoclick() {
            debugger
        },
        clearSearchKey() {
            this.$emit('clearSearchKey')
            // const { query: oldQuery } = this.$route
            // const query = JSON.parse(JSON.stringify(oldQuery))
            // // delete query.searchKey
            // delete query.searchKey
            // delete query.searchTime
            // this.$router.go(-1)
        },
        // 分页当前页码改变
        pageIndexChange(e) {
            this.pageConfig.pageNum = e
            this.getFileSearchList()
        },
        // 分页展示数量改变
        pageSizeChange(e) {
            debugger
            const { pageNum, pageTotal } = this.pageConfig
            if (pageNum * e > pageTotal - e) return
            this.getFileSearchList()
        },
        // 获取文件搜索列表
        getFileSearchList() {
            const searchDom = document.querySelector('#searchContent')
            searchDom.style.height = '780px'
            if (this.type === '通讯录') {
                this.$nextTick(() => {
                    this.$refs.telCenter.init(this.searchKey, 1)
                })
            }
            if (this.type === '全部') {
                this.$http({
                    method: 'get',
                    params: {
                        keyword: this.searchKey,
                        pageSize: 5,
                        pageNum: this.pageConfig.pageNum
                    },
                    url: 'common/search'
                }).then((res) => {
                    // console.log(res, '获取搜索结果')
                    if (res.code !== 'success') {
                        return this.$message.error(res.message)
                    }
                    const listdata = res.body.listdata
                    listdata.forEach((v) => {
                        v.formTitle = v.title
                        // v.title = v.name
                        v.content = v.content || v.attachment.content
                    })
                    this.pageConfig.pageTotal = res.body.total
                    this.fileSearchList = listdata
                    // console.log('this.fileSearchList', this.fileSearchList)
                })
            } else if (this.type === '文档') {
                searchDom.style.height = '1080px'
                // console.log('文档')
                if (this.newsType.length === 0) {
                    return
                }
                this.$http({
                    method: 'get',
                    params: {
                        keyWord: this.searchKey,
                        category: this.newsType.join(','),
                        pageSize: 5,
                        pageNum: this.pageConfig.pageNum

                    },
                    url: 'common/elasticsearch/esSearch'
                }).then((res) => {
                    // console.log(res, '获取搜索结果')
                    if (res.code !== 'success') {
                        return this.$message.error(res.message)
                    }
                    const listdata = res.body.listdata

                    this.$http({
                        method: 'get',
                        url: 'portal/searchEs/getCmsDomain'
                    }).then((res) => {
                        if (res.code === 'success') {
                            const cmsUrl = res.body.url
                            listdata.forEach((v) => {
                                v.formTitle = v.title
                                // v.title = v.name
                                v.content = v.content || v.attachment.content
                                if (v.logoUrl && v.logoUrl.indexOf('picture') != -1 && cmsUrl) v.logoUrl = cmsUrl + v.logoUrl
                            })
                            this.pageConfig.pageTotal = res.body.total
                            this.fileSearchList = listdata
                        }
                    })
                })
            } else if (this.type === '公文') {
                // console.log('公文')
                this.$http({
                    method: 'get',
                    params: {
                        category: this.geglData.join(','),
                        // title: this.searchKey,
                        // content: this.searchKey,
                        // fileSn: this.searchKey,
                        // level: this.searchKey,
                        // createDept: this.searchKey,
                        // creator: this.searchKey,
                        keyWord: this.searchKey,
                        pageSize: 5,
                        pageNum: this.pageConfig.pageNum

                    },
                    url: 'common/elasticsearch/esSearch'
                }).then((res) => {
                    // console.log(res, '获取搜索结果')
                    if (res.code !== 'success') {
                        return this.$message.error(res.message)
                    }
                    const listdata = res.body.listdata
                    listdata.forEach((v) => {
                        v.formTitle = v.title
                        // v.title = v.name
                        v.content = v.content || v.attachment.content
                    })
                    this.pageConfig.pageTotal = res.body.total
                    this.fileSearchList = listdata
                })
            } else if (this.type === '流程') {
                // console.log('流程')
                this.$http({
                    method: 'get',
                    params: {
                        category: this.noGeglData.join(','),
                        // title: this.searchKey,
                        // content: this.searchKey,
                        // fileSn: this.searchKey,
                        // level: this.searchKey,
                        // createDept: this.searchKey,
                        // creator: this.searchKey,
                        keyWord: this.searchKey,
                        pageSize: 5,
                        pageNum: this.pageConfig.pageNum

                    },
                    url: 'common/elasticsearch/esSearch'
                }).then((res) => {
                    // console.log(res, '获取搜索结果')
                    if (res.code !== 'success') {
                        return this.$message.error(res.message)
                    }
                    const listdata = res.body.listdata
                    listdata.forEach((v) => {
                        v.formTitle = v.title
                        // v.title = v.name
                        v.content = v.content || v.attachment.content
                    })
                    this.pageConfig.pageTotal = res.body.total
                    this.fileSearchList = listdata
                })
            } else if (this.type === '附件') {
                // console.log('附件')
                this.$http({
                    method: 'get',
                    params: {
                        content: this.searchKey,
                        pageSize: 5,
                        pageNum: this.pageConfig.pageNum
                    },
                    url: 'common/advSearch'
                }).then((res) => {
                    // console.log(res, '获取搜索结果')
                    if (res.code !== 'success') {
                        return this.$message.error(res.message)
                    }
                    const listdata = res.body.listdata
                    listdata.forEach((v) => {
                        v.formTitle = v.title
                        // v.title = v.name
                        v.content = v.content || v.attachment.content
                    })
                    this.pageConfig.pageTotal = res.body.total
                    this.fileSearchList = listdata
                })
            }
        },
        // 点击行title
        clickCellTitle(fileid) {
            if (!fileid) return
            window.open(
                `${process.env.VUE_APP_BASE_API}/${fileid.wfEngineUrl}/downloadById?inline=true&attachIds=${fileid.attachId}&topdf=true&httpPort=${location.port}`
            )
        },
        // 日期转化
        dateTransform(date) {
            if (!date) return ''
            return window.moment(date).format('YYYY年MM日DD日')
        },
        getSessonId() {
            this.$http({
                method: 'get',
                contentType: 'application/x-www-form-urlencoded',
                url: '/base/user/getSessionId'
            }).then(res => {
                if (res.code === 'success') {
                    this.tSessionId = res.body.tSessionId
                    const timeLength = (Number(res.body.timeLenth) - 1) * 60 * 1000
                    this.timeInterval = setInterval(() => {
                        this.getSessonId()
                    }, timeLength)
                } else {
                    console.log(res.message)
                }
            })
        },
        async getSysTeamList() {
            const data = [
                { 'compare': '=', 'field': 'sysType', 'merge': true, 'value': 2 },
                { 'compare': '=', 'field': 'status', 'merge': true, 'value': 1 },
                { 'compare': '=', 'field': 'orgCode', 'merge': true, 'value': this.loginUser.orgCode },
                { 'compare': '=', 'field': 'portalShow', 'merge': true, 'value': 1 }
            ]
            const rest = await this.$app.querydata('base/clientSystem/querydata', data, 'orderNo asc')
            if (rest.code === 'success') {
                this.syslist = rest.body.listdata
            }
        },
        // 打开表单
        async openBusiform(row) {
            if (row.type === 'news') {
                // 打开新闻表单
                const activeItem = {
                    name: '新闻',
                    type: 2,
                    moduleInfo: { 'getByIdUrl': '/portal/cms/article/getById' },
                    fieldInfo: { timeField: 'publishTime', senderField: 'creator', titleField: 'title', contField: 'content' },
                    desInfo: { timeDes: '时间：', senderDes: '发布人：' }
                }
                window.open(row.wfEngineUrl + '&activeItem=' + encodeURIComponent(JSON.stringify(activeItem)))
            } else if (row.type === 'frm' || row.type === 'wfAttach') {
                // 打开流程表单
                if (row.wfEngineUrl) {
                    // row.wfEngineUrl = '/wflow?wfEngineUrl=' + row.wfEngineUrl + '&busiDataId=' + row.id
                    if (row.category == '外网发布申请') {
                        // window.open('/#/wflow?wfEngineUrl=' + row.wfEngineUrl + '&busiDataId=' + row.id)
                        window.open('/#/viewcontent?wfEngineUrl=oa/wwfb&busiDataId=' + row.id + '&viewContent=1' + '&type=zsgl&logoTitle=' + encodeURIComponent('信息发布/外网发布'))
                    } else {
                        window.open('/#/wflow?wfEngineUrl=' + row.wfEngineUrl + '&busiDataId=' + row.id)
                    }
                }
                // let sysurl = ''
                // // let sysurlParam = ''
                // // console.log(1111, this.syslist)
                // if (row.sysCode) {
                //     const listdata = this.syslist.filter(item => item.systemCode === row.sysCode)
                //     if (listdata.length > 0) {
                //         sysurl = listdata[0].indexUrl.split('#')[0] + '#'
                //         // sysurlParam = listdata[0].indexUrl.split('?')[1]
                //     } else {
                //         this.$message.warning('该系统未接入到门户！')
                //         return
                //     }
                // }
                // if (row.wfEngineUrl) {
                //     // row.wfEngineUrl = '/wflow?wfEngineUrl=' + row.wfEngineUrl + '&busiDataId=' + row.id
                //     window.open(sysurl + '/wflow?wfEngineUrl=' + row.wfEngineUrl + '&busiDataId=' + row.id)
                // }
            } else if (row.type === 'otherAttach') {
            // 其它附件表单
            }

            // if (row.itemUrl.indexOf('?') !== -1) {
            //     row.itemUrl += '&sessionId=' + this.tSessionId
            // } else {
            //     row.itemUrl += '?sessionId=' + this.tSessionId
            // }
            // if (row.itemUrl.indexOf('http') === 0) {
            //     window.open(row.itemUrl)
            // } else if (row.itemUrl === undefined) {
            //     let wfEngineUrl = this.moduleInfo.listDataUrl.split('/')
            //     wfEngineUrl = encodeURI(wfEngineUrl[0] + '/' + wfEngineUrl[1])
            //     row.itemUrl = '/wflow?wfEngineUrl=' + wfEngineUrl + '&busiDataId=' + row.id
            //     window.open(sysurl + row.itemUrl + '&' + sysurlParam)
            // } else {
            //     window.open(sysurl + row.itemUrl + '&' + sysurlParam)
            // }

            // if (row.attachType === 'frm') {
            //     window.open('/#/wflow?wfEngineUrl=' + row.wfEngineUrl + '&busiDataId=' + row.id)
            // } else {
            //     window.open(row.wfEngineUrl)
            // }

            // else {
            //     // debugger
            //     this.moduleInfo = {
            //     // getByIdUrl: '/gwgl/gdwj/getById',
            //         getByIdUrl: `${row.wfEngineUrl}/getById`,
            //         listDataUrl: '',
            //         addNewUrl: `${row.wfEngineUrl}/addNew`,
            //         insertUrl: `${row.wfEngineUrl}/insert`,
            //         updateUrl: `${row.wfEngineUrl}/update`,
            //         uploadUrl: `${row.wfEngineUrl}/upload`,
            //         moduleCode: 'gdwj',
            //         winWidth: 1000, // 查看、编辑窗口宽度
            //         winHeight: 650 // 查看、编辑窗口高度
            //     }
            //     this.showView = 'forms'
            //     this.$bus.emit('openBusiform', this.moduleInfo, row, 0)
            // }
        }
    }
}
</script>

<style scoped lang="scss">
.file_search_results {
  @mixin ellipsis($clamp: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    @if $clamp == 1 {
      white-space: nowrap;
    } @else {
      display: -webkit-box;
      -webkit-line-clamp: $clamp;
      -webkit-box-orient: vertical;
    }
  }
  .link {
    cursor: pointer;
    &:hover {
      color: #1890ff;
      text-decoration: underline;
    }
  }
  //
//   width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 20px;
  // overflow: scroll;
  // height: 708px;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ / Edge */
//   background-color: #ebe8e8 ;
  /deep/ em {
    font-style: normal;
    color: #e94243;
  }
  .file_search_results_explain {
    font-size: 14px;
    color: #999999;
    line-height: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    .results_explain_red {
      color: #E94243;
    }
    .results_explain_left {
      display: flex;
      overflow: hidden;
    }
    .results_explain_right {
      flex: 1;
      display: flex;
    }
    .results_explain_text_middle {
      flex: 1;
      @include ellipsis;
    }
    .results_explain_text_end {
      flex: 1;
      display: flex;
      margin-right: 70px;
    }
  }
  .file_search_results_list {
    .results_list_cell {
      padding: 13px 0;
      border-bottom: 1px solid #ebeef5;
    }
    .results_list_cell_head {
      display: flex;
    }
    .results_list_cell_title {
      cursor: pointer;
      font-size: 18px;
      color: #333333;
      line-height: 20px;
      @include ellipsis;
      @extend .link;
    }
    .results_list_cell_type {
      // 外层需套一层父盒子脱离flex影响
      max-width: 100px;
      margin-left: 5px;
      padding: 2px 10px;
      border: 1px solid #ccc;
      border-radius: 1000px;
      font-size: 11px;
      color: #999999;
      line-height: 15px;
      @include ellipsis;
    }
    .results_list_cell_text {
      font-size: 12px;
      color: #333333;
      line-height: 20px;
      padding-top: 10px;
      @include ellipsis(2);
    }
    .results_list_cell_date {
      color: #999999;
      margin-right: 5px;

    }
    .results_list_cell_explain {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #999999;
      line-height: 20px;
      margin-left: -4px;
    }
    .results_list_cell_level {
      padding: 0 4px;
    }
    .results_list_cell_division {
      width: 1px;
      height: 10px;
      background-color: #999999;
    }
    .results_list_cell_formtitle {
      max-width: 150px;
      padding: 0 4px;
      @include ellipsis;
      @extend .link;
    }
    .results_list_cell_ministry {
      flex: 1;
      padding: 0 4px;
      display: flex;
      align-items: center;
      overflow: hidden;
      .cell_ministry_icon {
        min-width: 14px;
        height: 14px;
        margin-right: 4px;
      }
      .cell_ministry_text {
        @include ellipsis;
      }
    }
  }
  .file_search_results_nosearch {
    text-align: center;
    padding: 85px 0;
    .results_nosearch_text {
      font-size: 14px;
      line-height: 19px;
      color: #999;
    }
  }
}

</style>
