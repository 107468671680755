<template>
  <div>
    <el-link v-if="row.passenger.canRp" @click="down('toTb')"> 填报 </el-link>
    <el-link v-if="!row.passenger.canRp" :disabled="row.rpStatus===0" @click="down('look')"> 预览 </el-link>
    <el-link v-if="!row.passenger.canRp" :disabled="row.rpStatus===0" @click="down('down')"> 下载 </el-link>
  </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        row: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        console.log(31, this.row)
    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
        down(type) {
            if (type === 'look') {
                this.$bus.emit('rpLook', this.row)
            } else if (type === 'down') {
                this.$bus.emit('rpLook', this.row, true)
            } else {
                this.$bus.emit('toRp', 1)
            }
        }
    }
}
</script>

<style scoped lang="scss">
/deep/.is-disabled .el-link--inner{
    color: #C0C4CC !important;
}
/deep/ .el-link--inner{
    color: #1890ff;
}
</style>
