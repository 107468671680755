<template>
  <div>
    <span>{{ row.startTime.substr(0,16) }}</span>
    <span>至</span>
    <span>{{ row.endTime.substr(0,16) }}</span>
  </div>
</template>

<script>
export default {
    props: {
        column: { type: Object, default: null },
        row: { type: Object, default: null },
        index: { type: Number, default: null }
    }
}
</script>

<style scoped lang="scss">

</style>
