<template>
  <div>
    <div style="padding:10px;">
      <el-radio-group v-model="viewType" size="medium">
        <el-radio-button label="视图"></el-radio-button>
        <el-radio-button label="列表"></el-radio-button>
      </el-radio-group>
    </div>
    <ViewType v-if="viewType === '视图'" @openDailong="openDailong" />
    <ListType v-else @openDailong="openDailong" />
    <el-dialog title="考勤登记" :visible.sync="editFormShow" :close-on-click-modal="false" width="600px">
      <el-form ref="changeForm" :model="editForm" style="margin: 10px 0px;text-align: center">
        <div class="el-table el-table--fit el-table--border">
          <div class="el-table__body-wrapper">
            <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
              <tbody>
                <tr>
                  <td class="el-table__cell tdlbl tdw33" colspan="2">被考勤人员：</td>
                  <td class="el-table__cell tdcont tdw67" colspan="4">
                    <div>{{ editForm.name }}</div>
                  </td>
                </tr>
                <tr>
                  <td class="el-table__cell tdlbl tdw33" colspan="2">考勤日期：</td>
                  <td class="el-table__cell tdcont tdw67" colspan="4">
                    <div>{{ editForm.clockInDate }}</div>
                  </td>
                </tr>
                <template v-if="editForm.recordResultList">
                  <template v-for="(item,index) in editForm.recordResultList">
                    <tr v-if="item.statuslist" :key="item.id">
                      <td class="el-table__cell tdlbl tdw33" colspan="2">{{ item.timeName }}-考勤登记</td>
                      <td class="el-table__cell tdcont tdw67" colspan="4">
                        <el-checkbox-group v-model="editForm.recordResultList[index].statuslist" @change="statusChange($event,editForm.recordResultList[index])">
                          <el-checkbox :disabled="item.statuslist.includes('19')" label="2">迟到</el-checkbox>
                          <el-checkbox :disabled="item.statuslist.includes('19')" label="3">早退</el-checkbox>
                          <el-checkbox :disabled="item.statuslist.includes('2') || item.statuslist.includes('3')" label="19">旷工</el-checkbox>
                        </el-checkbox-group>
                      </td>
                    </tr>
                    <tr v-if="item.statuslist.includes('2')" :key="item.id+2">
                      <td class="el-table__cell tdlbl tdw33" colspan="2">{{ item.timeName }}签到</td>
                      <td class="el-table__cell tdcont tdw67" colspan="4">
                        <el-time-picker v-model="item.signOnTime" style="    width: 150px;margin-right: 10px;" value-format="HH:mm" format="HH:mm" :default-value="new Date(0,0,0,item.logonTime.split(':')[0],item.logonTime.split(':')[1],0)" :picker-options="{ selectableRange: `${item.logonTime}:00 - 23:59:00` ,format: 'HH:mm'}" placeholder="请选择时间" @change="timeChange($event,editForm.recordResultList[index],'checkComeLateTime')"></el-time-picker>
                        <span>迟到</span> <el-input v-model="item.comeLateHour" style="width: 40px;margin-right: 10px;" placeholder="请输入内容" @change="handlerHourChange(item, 'checkComeLateTime')"></el-input><span>小时</span>
                        <el-input v-model="item.comeLateMin" style="width: 50px;margin-right: 10px;" placeholder="请输入内容" type="number" @change="handlerMinuteChange(item, 'checkComeLateTime')"></el-input><span>分钟</span>
                      </td>
                    </tr>
                    <tr v-if="item.statuslist.includes('3') " :key="item.id+2">
                      <td class="el-table__cell tdlbl tdw33" colspan="2">{{ item.timeName }}签退</td>
                      <td class="el-table__cell tdcont tdw67" colspan="4">
                        <el-time-picker v-model="item.signOffTime" style="    width: 150px;margin-right: 10px;" value-format="HH:mm" :default-value="new Date(0,0,0,item.logoffTime.split(':')[0],item.logoffTime.split(':')[1],0)" format="HH:mm" :picker-options="{ selectableRange: `00:00:00 - ${item.logoffTime}:00`,format: 'HH:mm'}" placeholder="请选择时间" @change="timeChange($event,editForm.recordResultList[index],'checkLeaveEarlyTime')"></el-time-picker>
                        <span>早退</span> <el-input v-model="item.leaveEarlyHour" style="width: 40px;margin-right: 10px;" placeholder="请输入内容" @change="handlerHourChange(item, 'checkLeaveEarlyTime')"></el-input><span>小时</span>
                        <el-input v-model="item.leaveEarlyMin" style="width: 50px;margin-right: 10px;" placeholder="请输入内容" type="number" @change="handlerMinuteChange(item, 'checkLeaveEarlyTime')"></el-input><span>分钟</span>
                      </td>
                    </tr>

                  </template>

                </template>
                <template v-else>
                  <tr>
                    <td class="el-table__cell tdlbl tdw100" colspan="6" style="text-align: center;color: #bb9090;">暂无班次</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editFormShow = false">关 闭</el-button>
        <el-button type="primary" @click="saveBtn">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ViewType from './components/view'
import ListType from './components/list'
export default {
    components: {
        ViewType, ListType
    },
    props: {

    },
    data() {
        return {
            editFormShow: false,
            viewType: '视图',
            editForm: {

            }
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {

    },
    methods: {
        // 处理小时变更
        handlerHourChange(item, prototype) {
            if (prototype === 'checkComeLateTime') {
                this.$set(item, prototype, parseInt(item.comeLateHour) * 60 + parseInt(item.comeLateMin))
            }
            if (prototype === 'checkLeaveEarlyTime') {
                this.$set(item, prototype, parseInt(item.leaveEarlyHour) * 60 + parseInt(item.leaveEarlyMin))
            }
            console.log('hour change => ', item)
        },
        // 处理分钟变更
        handlerMinuteChange(item, prototype) {
            if (prototype === 'checkComeLateTime') {
                if (item.comeLateMin > 59) {
                    this.$set(item, 'comeLateMin', 59)
                }
                if (item.comeLateMin < 0) {
                    this.$set(item, 'comeLateMin', 0)
                }
                this.$set(item, prototype, parseInt(item.comeLateHour) * 60 + parseInt(item.comeLateMin))
            }
            if (prototype === 'checkLeaveEarlyTime') {
                if (item.leaveEarlyMin > 59) {
                    this.$set(item, 'leaveEarlyMin', 59)
                }
                if (item.leaveEarlyMin < 0) {
                    this.$set(item, 'leaveEarlyMin', 0)
                }
                this.$set(item, prototype, parseInt(item.leaveEarlyHour) * 60 + parseInt(item.leaveEarlyMin))
            }
            console.log('minutes change => ', item)
        },
        statusChange(val, item) {
            // console.log(val, 'val')
            item.signStatus = val.join(',')
        },
        timeChange(val, item, field) {
            // console.log(val, 'val')

            if (field === 'checkLeaveEarlyTime') {
                if (item.signOffTime) {
                    let time1 = 0
                    let time2 = 0
                    const str1 = item.signOffTime.split(':')
                    time1 = Number(str1[0]) * 60 + Number(str1[1])
                    const str2 = item.logoffTime.split(':')
                    time2 = Number(str2[0]) * 60 + Number(str2[1])
                    const count = time2 - time1
                    this.$set(item, field, count)
                    this.$set(item, 'leaveEarlyHour', parseInt(count / 60))
                    this.$set(item, 'leaveEarlyMin', count % 60)
                }
            } else {
                if (item.signOnTime) {
                    let time1 = 0
                    let time2 = 0
                    const str1 = item.signOnTime.split(':')
                    time1 = Number(str1[0]) * 60 + Number(str1[1])
                    const str2 = item.logonTime.split(':')
                    time2 = Number(str2[0]) * 60 + Number(str2[1])
                    const count = time1 - time2
                    this.$set(item, field, count)
                    this.$set(item, 'comeLateHour', parseInt(count / 60))
                    this.$set(item, 'comeLateMin', count % 60)
                }
            }
            console.log('item => ', item)
            // console.log(item, 'item')
        },
        openDailong(row, clockInDate) {
            // scope.row.recordResultList.filter(item=>{return item.number === (index+1)})[0]

            this.$http({
                method: 'get',
                url: '/yykq/recordResult/getKQRecordResultInfoList',
                params: this.viewType === '视图' ? {
                    account: row.account,
                    clockInDate: clockInDate
                } : {
                    id: row.id
                }
            }).then(res => {
                if (res.code === 'success') {
                    this.editForm = res.body.vo
                    const resultList = this.editForm.recordResultList.map((node) => {
                        node.statuslist = node.signStatus ? node.signStatus.split(',') : []
                        node.comeLateHour = 0
                        node.comeLateMin = 0
                        node.leaveEarlyHour = 0
                        node.leaveEarlyMin = 0
                        if (node.checkComeLateTime !== 0) {
                            node.comeLateHour = parseInt(node.checkComeLateTime / 60)
                            node.comeLateMin = node.checkComeLateTime % 60
                        }
                        if (node.checkLeaveEarlyTime !== 0) {
                            node.leaveEarlyHour = parseInt(node.checkLeaveEarlyTime / 60)
                            node.leaveEarlyMin = node.checkLeaveEarlyTime % 60
                        }
                        return node
                    })
                    this.editForm.recordResultList = JSON.parse(JSON.stringify(resultList))
                    // console.log(this.editForm, 'this.editForm')
                    setTimeout(() => {
                        this.editFormShow = true
                    }, 10)
                }
            })
        },
        // 保存
        saveBtn() {
            // let isb = true
            // this.editForm.selectList.forEach(item => {
            //     if (item.attendRegister == '' || item.workShift == '') {
            //         this.$alert('选择项不能为空', { type: 'error' })
            //         isb = false
            //     }
            // })
            // if (!isb) return
            this.$http({
                method: 'post',
                url: '/yykq/recordResult/saveKQRecordResultInfoList',
                // params: {
                //     rypbId: this.editForm.rypbId,
                //     index: this.editForm.index
                // },
                data: this.editForm
            }).then(res => {
                if (res.code === 'success') {
                    if (res.code === 'success') {
                        this.$message.success(res.message)
                        this.editFormShow = false
                        this.$bus.emit('refreshViewData')
                        // this.$refs.statistics.statisticsClick()
                    }
                }
            })
        },
        // 添加一行
        addList() {
            this.editForm.list.push({ 'attendRegister': '', 'id': '', 'pbNy': null, 'workShift': '' })
        }
    }
}
</script>

<style scoped lang="scss">

</style>
