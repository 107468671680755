<template>
    <div id="new-busi-form">
        <div class="new-head">
            <div class="head-left">
                <img src="/images/titleLogo.png" />
                <div class="head-left-info">
                    <!-- <svg-icon style="font-size:44px" icon-class="wf_form" /> -->
                    <div class="head-title">{{ `投标管理` }} | {{ busiInfo }}</div>
                    <div class="header-tab">
                        <div class="tab-item" :class="activeTabId === 'tbxmlx' ? 'active' : ''" @click="handleHeaderTabClick('tbxmlx')">立项信息</div>
                        <div class="tab-item" :class="activeTabId === 'tbypf' ? 'active' : ''" @click="handleHeaderTabClick('tbypf')">投标预评分</div>
                        <div class="tab-item" :class="activeTabId === 'kbdj' ? 'active' : ''" @click="handleHeaderTabClick('kbdj')">开标信息</div>
                        <div class="tab-item" :class="activeTabId === 'jgdj' ? 'active' : ''" @click="handleHeaderTabClick('jgdj')">结果信息</div>
                        <div class="tab-item" :class="activeTabId === 'tbhtsp' ? 'active' : ''" @click="handleHeaderTabClick('tbhtsp')">合同信息</div>
                        <div class="tab-item" :class="activeTabId === 'wfInfo' ? 'active' : ''" @click="handleHeaderTabClick('wfInfo')">流程审批</div>
                        <div class="tab-item" :class="activeTabId === 'zlgd' ? 'active' : ''" @click="handleHeaderTabClick('zlgd')">执行资料归档</div>
                    </div>
                </div>
            </div>

            <div class="head-right">
                <el-button @click="close">
                    <svg-icon style="color: red; margin-right: 5px" icon-class="glyphicon-off" />
                    关闭
                </el-button>
            </div>
        </div>

        <div v-if="show" class="busi-box mainform-container">
            <div class="busi-content page-container not-have-bottom-idealist" style="width: 100%">
                <div class="busi-components form-container" style="width: 100%">
                    <tbxmlx v-if="activeTabId === 'tbxmlx'" ref="tbxmlx" :vo="vo" class="table-gap" />
                    <tbypf v-if="activeTabId === 'tbypf'" ref="tbypf" :vo="vo" class="table-gap" />
                    <kbdj v-if="activeTabId === 'kbdj'" ref="kbdj" :vo="vo" class="table-gap" />
                    <jgdj v-if="activeTabId === 'jgdj'" ref="jgdj" :vo="vo" class="table-gap" />
                    <tbhtsp v-if="activeTabId === 'tbhtsp'" ref="tbhtsp" :vo="vo" class="table-gap" />
                    <wfInfo v-if="activeTabId === 'wfInfo'" ref="wfInfo" :vo="vo" class="table-gap" />
                    <zlgd v-if="activeTabId === 'zlgd'" ref="zlgd" :vo="vo" class="table-gap" />
                </div>
            </div>
            <!-- 通用弹框 -->
            <select-dialog />
            <!-- 个别模块需要打开表单 -->
            <!-- <Basicform v-if="showForm" :title="formTitle" :win-width="moduleInfoT.winWidth" :win-height="moduleInfoT.winHeight" :module-code="moduleInfoT.moduleCode" :toolbar-btns="busiformBtns" :on-basic-form-before-open="onBasicFormBeforeOpen" :on-basic-form-opened="onBasicFormOpened" :on-basic-form-before-submit="onBasicFormBeforeSubmit" :on-basic-form-submited="onBasicFormSubmited">
        <template #default="slotProps">
          <busi-form ref="busiform" :vo="slotProps.vo" :can-edit="slotProps.canEdit" :module-info="moduleInfoT" />
        </template>
</Basicform> -->
            <!-- 工作报告的分享 -->
            <!-- <button v-show="showShare" v-select-dialog="'staff'" class="xzry" multiple="true" :root-org-code="loginUser.orgCode" name="Share"></button> -->
            <!-- 工作报告回复 -->
            <!-- <replyDiaLog ref="repForm" :replay-vo="replayVo" :parent-vo="parentVo" /> -->
        </div>
    </div>
</template>

<script>
// import otherInfo from './components/otherInfo.vue'
import tbxmlx from './components/tbxmlx'
import tbypf from './components/tbypf'
import kbdj from './components/kbdj'
import jgdj from './components/jgdj'
import tbhtsp from './components/tbhtsp'
import wfInfo from './components/wfInfo'
import zlgd from './components/zlgd'

export default {
    name: 'Archive',
    components: {
        tbxmlx,
        tbypf,
        kbdj,
        jgdj,
        tbhtsp,
        wfInfo,
        zlgd
    },
    props: {},
    data() {
        return {
            voId: '',
            moduleInfo: {},
            replayVo: {},
            parentVo: {},
            vo: {},
            canEdit: 0,
            fileList: [],
            showForm: false,
            show: false,
            formType: '',
            title: '',
            formUrl: '',
            showShare: false,
            newId: '',
            moduleInfoT: {},
            noEdit: '',
            btnDisabled: false,
            busiInfo: '项目标题',
            row: {},
            loginUser: this.$store.getters.loginUser,
            busiformBtns: [],
            formTitle: '',
            uploadFiles: [],
            formItem: {},
            addChangeUpdate: false,
            tableHeaderBgColor: '#f1f5fb!important',
            activeTabId: 'archiveInfo',
            urlT: process.env.VUE_APP_BASE_API
        }
    },
    computed: {},
    watch: {},
    created() {
        // this.$bus.on('cellClick1', this.cellClick)
        // this.$bus.on('addUploadFiles', this.addUploadFiles)
        console.log('this.$route.query.moduleInfo:', this.$route.query)
        this.moduleInfo = JSON.parse(this.$route.query.moduleInfo)
        if (this.$route.query.rowdata) {
            this.row = JSON.parse(this.$route.query.rowdata)
        }
        this.title = this.$route.query.title
        this.noEdit = this.$route.query.noEdit
        this.busiInfo = this.$route.query.busiInfo || this.busiInfo
        console.log(this.title, this.busiInfo, 'this.title, this.busiInfo')
        console.log(this.noEdit, 'this.noEdit')
        console.log(this.moduleInfo, 'this.moduleInfo')
        console.log(this.row, 'this.row')
        document.title = this.$route.query.title
        window.addEventListener('beforeunload', (event) => {
            if (window.opener && window.opener.loadList) {
                window.opener.loadList()
            }
        })
    },
    beforeDestroy() {
        // this.$bus.off('cellClick1', this.cellClick)
        // this.$bus.off('addUploadFiles', this.addUploadFiles)
    },
    mounted() {
        this.vo = {}
        this.formUrl = this.$route.query.formUrl
        console.log(this.formUrl, 'this.formUrl')

        if (this.$route.query.canEdit) {
            this.canEdit = this.$route.query.canEdit
        }
        if (this.$route.query.voId) {
            this.voId = this.$route.query.voId
            this.getVo(this.voId, this.moduleInfo)
            this.canEdit = 0
        } else if (JSON.stringify(this.row) !== '{}') {
            this.vo = this.row
            this.canEdit = 0
            this.$nextTick(() => {
                this.show = true
            })
        }
    },
    methods: {
        onBasicFormBeforeSubmit($scope, vo, defer) {
            defer.resolve(true)
        },
        onBasicFormSubmited($scope, callBack) {
            if (callBack) {
                callBack()
            }
        },

        close() {
            window.close()
        },
        edit(val) {
            this.show = false
            this.canEdit = val
            this.$nextTick(() => {
                this.show = true
            })
            this.$forceUpdate()
        },
        getVo(id, moduleInfo) {
            this.$http({
                method: 'get',
                url: moduleInfo.getByIdUrl,
                params: {
                    id: id
                }
            }).then((res) => {
                if (res.code === 'success') {
                    this.vo = res.body.vo
                }
                this.handleHeaderTabClick('tbxmlx')
                this.show = true
            })
        },
        handleHeaderTabClick(tabId) {
            this.activeTabId = tabId
            // document.getElementById(tabId).scrollIntoView()
        }
    }
}
</script>
<style scoped lang="scss">
/deep/.el-table__body-wrapper::-webkit-scrollbar {
    height: 8px;
}

/deep/.el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 12px;
    /*滚动条的背景区域的圆角*/
}

/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 12px;
    /*滚动条的圆角*/
    background-color: #f5f5f5;
    /*滚动条的背景颜色*/
}

/deep/.el-form .el-table__body .tdlbl {
    text-align: left !important;
    background: unset !important;
}

#new-busi-form {
    height: 100%;
    background: #f0f2f5;
    min-width: 1360px;

    .new-head {
        height: 68px;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        background: white;
        align-items: center;

        .head-left {
            display: flex;
            align-items: center;

            img {
                width: 44px;
                height: 44px;
            }

            .head-left-info {
                margin-left: 10px;

                .head-title {
                    font-size: 14px;
                    color: #999999;
                    padding-top: 10px;
                    padding-left: 5px;
                }
            }
        }

        .head-right {
            min-width: 400px;
            text-align: right;
        }
    }

    .busi-box {
        height: calc(100% - 60px);
        overflow: auto;
        // padding-top: 20px;
        // padding-left: 10px;
        padding: 20px;

        .busi-content {
            width: 1200px;
            padding-bottom: 20px;
            background: white;
            min-height: calc(100vh - 108px);
            margin: 0px auto;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);

            .title-info {
                height: 93px;
                text-align: center;
                line-height: 93px;
                font-size: 24px;
                color: #000000;
            }

        }
    }

    /deep/ .tdlbl .required {
    color: #ff0000;
    margin-right: 3px;
}

    /* 设置滚动条thumb（轨道）的颜色 */
    .busi-box::-webkit-scrollbar {
        background-color: #f0f2f5;
        width: 8px;
    }

    /* 设置滚动条thumb（滑块）的颜色 */
    .busi-box::-webkit-scrollbar-thumb {
        border-radius: 12px;
    }

    /* 设置滚动条hover时thumb的颜色 */
    .busi-box:hover.busi-box::-webkit-scrollbar-thumb {
        background-color: #d5d6d8;
    }

    /deep/.el-table .el-table__header-wrapper .el-table__header thead th {
        background: #f2f5fb !important;
    }
}
</style>

<style scoped lang="scss">
@import '@/wapp/common/styles/variables.module.scss';

.table-gap {
    // margin-top: 20px;
    margin-bottom: 20px;
}

.header-tab {
    display: flex;
    // margin-top: 10px;
    height: 38px;
    line-height: 38px;

    .tab-item {
        position: relative;
        margin-right: 10px;
        padding: 0 5px;
        // color: #666;
        color: #000000;
        display: inline-block;
        width: auto;
        white-space: nowrap;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        cursor: pointer;
    }

    .active {
        position: relative;
        color: var(--subjectColor);

        &::after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0px;
            width: 100%;
            height: 1px;
            background: var(--subjectColor);
        }
    }
}

/deep/.el-form .el-table__body td.tdw20 {
    width: 20% !important;
}

/deep/.el-form .el-table__body td.tdw13 {
    width: 13% !important;
}

/deep/ .el-form .el-table__body {
    min-width: 100%;
}
/deep/ .el-table::before{
    background: #999;
}

/deep/ .el-table__body td{
    position: relative;
    padding: 4px 6px !important;
    height: 39px;
    line-height: 39px;
    border-bottom: 1px solid #999 !important;
    border-right: 1px solid #999 !important;
}
/deep/ .el-table__body .tdcont-innertb .el-table__body{
    padding: 0px 0px !important;
    border-bottom: 0px;
    border-right: 0px;
}
/deep/ .el-table__body .tdcont-innertb{
    padding: 0px 0px !important;
}
/deep/ .el-table__body{
    border-top: 1px solid #999 !important;
    border-left: 1px solid #999 !important;
}

/deep/ .el-table__header thead th{
    border-right-color: #999 !important;
    // border-bottom-color: #999 !important;
}

/deep/ .el-form .el-table__body .tdlbl{
    background-color: #f2f5fb !important
}

/deep/ .el-form .el-table::after{
    background: #999 !important;
}

/deep/.el-table__header{
    margin-left: 1px;
}

/deep/ .el-form .el-table__body .tdlbl{
    border-bottom: 1px solid #999 !important;
}

/deep/ .el-table .el-table__cell{
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    text-align: left;
}

/deep/ .tdlbl {
    height: 39px !important;
    line-height: 39px !important;
    font-size: 14px !important;
    color: #333333 !important;
    font-weight: 600 !important;
    padding: 4px 6px 4px 4px !important;
    background-color: #f2f5fb !important;
    text-align: left !important;
}

.table-gap /deep/ .app-container{
    width: calc(100% - 10%);
    margin: 0 auto;
}
.table-gap /deep/ .app-container  .el-table__header{
    border-top: 1px solid #999;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    margin: 0 auto;
}

/deep/ .el-table--border::after{
    background: #999;
}

/deep/ .el-table--border .el-table__cell:first-child .cell{
    text-align: center;
}

/deep/ .el-table__empty-block {
    border-left: 1px solid #999;
}

/deep/ .el-table .cell.el-tooltip{
    text-align: center;
}

/deep/ .el-table th.el-table__cell>.cell{
    text-align: center;
}
</style>
