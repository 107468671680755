<template>
  <el-dialog v-if="dialogVisible" v-el-drag-dialog :title="title" :visible.sync="dialogVisible" :close-on-click-modal="false" width="1100px">
    <el-radio-group v-if="tbId===7" v-model="tabovertime" size="small" style="margin-bottom:10px;">
      <el-radio-button :label="0">打卡加班</el-radio-button>
      <el-radio-button :label="1">加班申请</el-radio-button>
    </el-radio-group>

    <el-table v-if="(tbId===7&&tabovertime===1) || tbId!==7" id="rankPersonage" v-loading="loading" :data="listdata" border width="100%">
      <el-table-column
        type="index"
        label="序号"
        width="50"
        align="center"
      />
      <template v-for="(td, index) in fieldsArr[tbId]">
        <template v-if="td.isFormatters">
          <template v-if="td.formatType == 'combi'">
            <el-table-column
              :key="index"
              :label="td.name"
              :width="td.width"
              align="center"
            >
              <template slot-scope="scope">
                签到（{{ scope.row.signOnTime || '缺卡' }}）签退（{{ scope.row.signOffTime || '缺卡' }}）
              </template>
            </el-table-column>
          </template>
          <template v-else-if="td.formatType == 'unit'">
            <el-table-column
              :key="index"
              :label="td.name"
              :width="td.width"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row[td.fieldsName] + computedUnit(scope.row, td) }}
              </template>
            </el-table-column>
          </template>
          <template v-else-if="td.formatType == 'dateLimit'">
            <el-table-column
              :key="index"
              :label="td.name"
              :width="td.width"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row[td.fieldsName] | formatTime('yyyy-MM-dd') }}
              </template>
            </el-table-column>
          </template>
          <template v-else-if="td.formatType == 'dateCombi'">
            <el-table-column
              :key="index"
              :label="td.name"
              :width="td.width"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.startDate + ' 至 ' + scope.row.endDate }}
              </template>
            </el-table-column>
          </template>
        </template>
        <template v-else>
          <el-table-column
            :key="index"
            :prop="td.fieldsName"
            :label="td.name"
            :width="td.width"
            align="left"
          />
        </template>
      </template>
    </el-table>
    <el-table v-if="tbId===7&&tabovertime===0" id="rankPersonage" v-loading="loading" :data="kqruleListdata" border width="100%">
      <el-table-column
        type="index"
        label="序号"
        width="50"
        align="center"
      />
      <el-table-column
        prop="clockInDate"
        label="考勤日期"
        :width="'120'"
        align="center"
      />
      <el-table-column
        prop="timeSpan"
        label="考勤时段"
        :width="''"
        align="left"
      />
      <el-table-column
        prop="logoffTime"
        label="正常签退时间"
        :width="'120'"
        align="center"
      />
      <el-table-column
        prop="signOffTime"
        label="实际签退时间"
        :width="'120'"
        align="center"
      />
      <el-table-column
        prop="checkWorkOvertime"
        label="加班时长"
        :width="'100'"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.checkWorkOvertime }}{{ scope.row.passenger.unit }}</span>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer">
      <!-- <el-button v-table-toxls="rankPersonage" type="primary" :file-name="title" icon="el-icon-download" plain>保存</el-button> -->
      <el-button plain icon="el-icon-close" @click="dialogVisible = false">关 闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
    name: '',
    data() {
        return {
            loading: true,
            curUser: this.$store.getters.loginUser,
            dialogVisible: false,
            listdata: [],
            title: '',
            computations: [],
            statusObj: { '1': '勤奋', '2': '迟到', '3': '早退', '7': '加班', '9': '请假', '10': '旷工' },
            fieldsArr: {
                '1': [
                    { name: '考勤时段', fieldsName: 'timeSpan', width: '', isFormatters: false, formatType: '', unit: '' },
                    { name: '考勤日期', fieldsName: 'clockInDate', width: '110px', isFormatters: false, formatType: '', unit: '' },
                    { name: '打卡信息', fieldsName: '', width: '', isFormatters: true, formatType: 'combi', unit: '' },
                    { name: '工作时长', fieldsName: 'checkWorkTime', width: '120px', isFormatters: true, formatType: 'unit', unit: '分钟' }
                ],
                '2': [
                    { name: '考勤时段', fieldsName: 'timeSpan', width: '', isFormatters: false, formatType: '', unit: '' },
                    { name: '考勤日期', fieldsName: 'clockInDate', width: '110px', isFormatters: false, formatType: '', unit: '' },
                    { name: '打卡信息', fieldsName: '', width: '', isFormatters: true, formatType: 'combi', unit: '' },
                    { name: '迟到时长', fieldsName: 'checkComeLateTime', width: '120px', isFormatters: true, formatType: 'unit', unit: '', hide: false }
                ],
                '3': [
                    { name: '考勤时段', fieldsName: 'timeSpan', width: '', isFormatters: false, formatType: '', unit: '' },
                    { name: '考勤日期', fieldsName: 'clockInDate', width: '110px', isFormatters: false, formatType: '', unit: '' },
                    { name: '打卡信息', fieldsName: '', width: '', isFormatters: true, formatType: 'combi', unit: '' },
                    { name: '早退时长', fieldsName: 'checkLeaveEarlyTime', width: '120px', isFormatters: true, formatType: 'unit', unit: '', hide: false }
                ],
                '7': [
                    { name: '加班事由', fieldsName: 'flSubject', width: '', isFormatters: false, formatType: '', unit: '' },
                    { name: '加班时间', fieldsName: '', width: '280px', isFormatters: true, formatType: 'dateCombi', unit: '' },
                    { name: '加班时长', fieldsName: 'flRealityTime', width: '100px', isFormatters: true, formatType: 'unit', unit: '', hide: false },
                    { name: '申请时间', fieldsName: 'flCreateDate', width: '150px', isFormatters: true, formatType: 'dateLimit', unit: '', hide: false }
                ],
                '9': [
                    { name: '请假事由', fieldsName: 'flSubject', width: '', isFormatters: false, formatType: '', unit: '' },
                    { name: '请假类型', fieldsName: 'leaveType', width: '100px', isFormatters: false, formatType: '', unit: '' },
                    { name: '请假时间', fieldsName: '', width: '280px', isFormatters: true, formatType: 'dateCombi', unit: '' },
                    { name: '请假时长', fieldsName: 'flRealityTime', width: '100px', isFormatters: true, formatType: 'unit', unit: '' },
                    { name: '申请时间', fieldsName: 'flCreateDate', width: '150px', isFormatters: true, formatType: 'dateLimit', unit: '' }
                ],
                '10': [
                    { name: '考勤时段', fieldsName: 'timeSpan', width: '', isFormatters: false, formatType: '', unit: '' },
                    { name: '考勤日期', fieldsName: 'clockInDate', width: '110px', isFormatters: false, formatType: '', unit: '' },
                    { name: '打卡信息', fieldsName: '', width: '', isFormatters: true, formatType: 'combi', unit: '' }
                ]
            },
            tabovertime: 0,
            kqruleListdata: [],
            chooseDates: []
        }
    },
    created() {
        this.$bus.on('rankPersonageDialogOpen', this.dialogOpen)
    },
    beforeDestroy() {
        this.$bus.off('rankPersonageDialogOpen', this.dialogOpen)
    },
    methods: {
        async dialogOpen(row, status, args, chooseDate) {
            this.loading = true
            this.dialogVisible = true
            this.tbId = status
            this.account = row.account || row.ACCOUNT
            let param, sp
            this.chooseDates = chooseDate
            if (status === 1) {
                param = [
                    { 'compare': '=', 'field': 'signOnStatus', 'merge': true, 'value': 1 },
                    { 'compare': '=', 'field': 'signOffStatus', 'merge': true, 'value': 1 },
                    { 'compare': '=', 'field': 'account', 'merge': true, 'value': this.account },
                    { 'compare': '=', 'field': 'orgCode', 'merge': true, 'value': this.curUser.orgCode },
                    { 'compare': '>=', 'field': 'clockInDate', 'merge': true, 'value': chooseDate[0] },
                    { 'compare': '<=', 'field': 'clockInDate', 'merge': true, 'value': chooseDate[1] }
                ]
                this.personageMessage(param).then(res => {
                    this.loading = false
                    this.title = chooseDate[0] + '至' + chooseDate[1] + ' ' + (row.name || row.NAME) + '的' + this.statusObj[status] + '明细表 共' + res.length + '条记录'
                })
            } else if (status === 2) {
                param = [
                    { 'compare': '=', 'field': 'signOnStatus', 'merge': true, 'value': 2 },
                    { 'compare': '=', 'field': 'account', 'merge': true, 'value': this.account },
                    { 'compare': '=', 'field': 'orgCode', 'merge': true, 'value': this.curUser.orgCode },
                    { 'compare': '>=', 'field': 'clockInDate', 'merge': true, 'value': chooseDate[0] },
                    { 'compare': '<=', 'field': 'clockInDate', 'merge': true, 'value': chooseDate[1] }
                ]
                sp = [{ 'compare': '=', 'field': 'computeItem', 'merge': true, 'value': '迟到' }, { 'compare': '=', 'field': 'orgCode', 'merge': true, 'value': this.curUser.orgCode }]
                this.personageMessage(param).then(res => {
                    this.title = chooseDate[0] + '至' + chooseDate[1] + ' ' + (row.name || row.NAME) + '的' + this.statusObj[status] + '明细表 共' + res.length + '条记录'
                    this.loading = false
                })
                await this.computation(sp)
            } else if (status === 3) {
                param = [{ 'compare': '=', 'field': 'signOffStatus', 'merge': true, 'value': 3 }, { 'compare': '=', 'field': 'account', 'merge': true, 'value': this.account }, { 'compare': '=', 'field': 'orgCode', 'merge': true, 'value': this.curUser.orgCode }]
                sp = [{ 'compare': '=', 'field': 'computeItem', 'merge': true, 'value': '早退' }, { 'compare': '=', 'field': 'orgCode', 'merge': true, 'value': this.curUser.orgCode }]
                this.personageMessage(param).then(res => {
                    this.title = chooseDate[0] + '至' + chooseDate[1] + ' ' + (row.name || row.NAME) + '的' + this.statusObj[status] + '明细表 共' + res.length + '条记录'
                    this.loading = false
                })
                await this.computation(sp)
            } else if (status === 7) {
                param = [
                    { 'compare': '=', 'field': 'signOffStatus', 'merge': true, 'value': 7 },
                    { 'compare': '=', 'field': 'account', 'merge': true, 'value': this.account },
                    { 'compare': '=', 'field': 'orgCode', 'merge': true, 'value': this.curUser.orgCode },
                    { 'compare': '>=', 'field': 'clockInDate', 'merge': true, 'value': chooseDate[0] },
                    { 'compare': '<=', 'field': 'clockInDate', 'merge': true, 'value': chooseDate[1] }
                ]
                const res2 = await this.personageMessage(param)
                const res1 = await this.overTimeWork()

                this.kqruleListdata = res2

                this.title = chooseDate[0] + '至' + chooseDate[1] + ' ' + (row.name || row.NAME) + '的' + this.statusObj[status] + '明细表 共' + (res1.length + res2.length) + '条记录'
                this.loading = false
            } else if (status === 9) {
                this.vacate().then(res => {
                    this.title = chooseDate[0] + '至' + chooseDate[1] + ' ' + (row.name || row.NAME) + '的' + this.statusObj[status] + '明细表 共' + res.length + '条记录'
                    this.loading = false
                })
            } else if (status === 10) {
                param = [
                    // { 'compare': '=', 'field': 'signOnStatus', 'merge': true, 'value': 4 },
                    // { 'compare': '=', 'field': 'signOffStatus', 'merge': true, 'value': 4 },
                    { 'compare': '=', 'field': 'account', 'merge': true, 'value': this.account },
                    { 'compare': '=', 'field': 'orgCode', 'merge': true, 'value': this.curUser.orgCode },
                    { 'compare': '>=', 'field': 'clockInDate', 'merge': true, 'value': chooseDate[0] },
                    { 'compare': '<=', 'field': 'clockInDate', 'merge': true, 'value': chooseDate[1] }
                ]
                this.personageMessage(param).then(res => {
                    this.listdata = res.filter(item => item.signOnStatus === 4 || item.signOffStatus === 4)
                    this.title = chooseDate[0] + '至' + chooseDate[1] + ' ' + (row.name || row.NAME) + '的' + this.statusObj[status] + '明细表 共' + this.listdata.length + '条记录'
                    this.loading = false
                })
            }
        },
        // 查询个人详情
        personageMessage(param) {
            return new Promise((resolve, reject) => {
                this.$http({
                    method: 'POST',
                    contentType: 'application/x-www-form-urlencoded',
                    data: param,
                    params: { 'orderBy': 'clockInDate desc' },
                    url: 'yykq/recordResult/querydata'
                }).then(response => {
                    if (response.code === 'success') {
                        this.listdata = response.body.listdata
                        resolve(JSON.parse(JSON.stringify(this.listdata)))
                    } else {
                        reject(response.message)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 计算规则
        computation(p) {
            return new Promise((resolve, reject) => {
                this.$http({
                    method: 'POST',
                    contentType: 'application/x-www-form-urlencoded',
                    data: p,
                    url: 'yykq/computeRule/querydata'
                }).then(response => {
                    if (response.code === 'success') {
                        this.computations = response.body.listdata
                        this.computations.forEach(function(v) {
                            switch (v.minUnit) {
                                case 1:
                                    v.unitAccount = '分钟'
                                    break

                                case 2:
                                    v.unitAccount = '小时'
                                    break

                                case 3:
                                    v.unitAccount = '天'
                                    break
                            }
                        })
                        resolve(this.computations)
                    } else {
                        reject(response.message)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 加班
        overTimeWork() {
            const sp = {
                pageNum: 1,
                pageSize: 65000,
                orderBy: 'flCreateDate desc',
                searchAndOr: 'and',
                searchMap: {
                    startDate_START: this.chooseDates[0] + ' 00:00:00',
                    endDate_END: this.chooseDates[1] + ' 23:59:59'
                },
                urlsearch: 'createorAccount:' + this.account + ';orgCode:' + this.curUser.orgCode
            }
            return new Promise((resolve, reject) => {
                this.$http({
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    data: $.param(sp),
                    url: 'yykq/overtimeWork/listdata'
                }).then(response => {
                    if (response.code === 'success') {
                        this.listdata = response.body.pageInfo.list
                        this.listdata.forEach(function(v) {
                            switch (v.computeUnit) {
                                case 1:
                                    v.unitAccount = '分钟'
                                    break
                                case 2:
                                    v.unitAccount = '小时'
                                    break
                                case 3:
                                    v.unitAccount = '天'
                                    break
                            }
                        })
                        resolve(this.listdata)
                        // this.personagessTitle = $scope.startTime + '至' + $scope.endTime + ' ' + name + '的加班明细表 共' + $scope.personagess.length + '条记录'
                    } else {
                        reject(response.message)
                    }
                })
            })
        },
        // 请假
        vacate() {
            const sp = {
                pageNum: 1,
                pageSize: 65000,
                orderBy: 'flCreateDate desc',
                filter: 'kqpmtj',
                searchMap: {
                    startDate_START: this.chooseDates[0] + ' 00:00:00',
                    endDate_END: this.chooseDates[1] + ' 23:59:59'
                },
                searchAndOr: 'and',
                urlsearch: 'status>:2;createorAccount:' + this.account + ';orgCode:' + this.curUser.orgCode
            }
            return new Promise((resolve, reject) => {
                this.$http({
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    data: $.param(sp),
                    url: 'yykq/vacate/listdata'
                }).then(response => {
                    if (response.code === 'success') {
                        this.listdata = response.body.pageInfo.list
                        this.listdata.forEach(function(v) {
                            v.flRealityTime = v.flRealityTime - v.cancelDay
                            switch (v.flLeaveType) {
                                case '1':
                                    v.leaveType = '事假'
                                    break
                                case '2':
                                    v.leaveType = '病假'
                                    break
                                case '3':
                                    v.leaveType = '婚假'
                                    break
                                case '4':
                                    v.leaveType = '丧假'
                                    break
                                case '5':
                                    v.leaveType = '产假'
                                    break
                                case '6':
                                    v.leaveType = '陪产假'
                                    break
                                case '7':
                                    v.leaveType = '年假'
                                    break
                                case '8':
                                    v.leaveType = '调休'
                                    break
                                case '9':
                                    v.leaveType = '其他'
                                    break
                            }
                            switch (v.computeUnit) {
                                case 1:
                                    v.unitAccount = '分钟'
                                    break

                                case 2:
                                    v.unitAccount = '小时'
                                    break

                                case 3:
                                    v.unitAccount = '天'
                                    break
                            }
                        })
                        resolve(this.listdata)
                        // this.personagessTitle = $scope.startTime + '至' + $scope.endTime + ' ' + name + '的加班明细表 共' + $scope.personagess.length + '条记录'
                    } else {
                        reject(response.message)
                    }
                })
            })
        },
        computedUnit(row, td) {
            if (td.unit) return td.unit
            if (row.unitAccount) return row.unitAccount
            if (this.computations && this.computations.length > 0) return this.computations[0].unitAccount
        }

    }
}
</script>
<style lang="scss" scoped>

</style>
