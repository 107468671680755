<template>
  <div>
    <template v-for="(item,index) in listData">
      <el-submenu v-if="item.children" :key="index" :index="item.id">
        <template slot="title">
          <span>{{ item.menuName }}</span>
        </template>
        <helpItem :list-data="item.children"></helpItem>
      </el-submenu>
      <el-menu-item v-if="!item.children" :key="index" :index="item.id">
        <span slot="title">{{ item.menuName }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
    name: 'HelpItem',
    components: {

    },
    props: {
        listData: {
            type: Array,
            default: function() { return [] }
        }
    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    created() {
    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
/deep/.el-menu-item.is-active::after{
    content: '';
    display: inline-block;
    position: absolute;
    right: 4px;
    transform: translateY(-50%);
    top: 50%;
    width: 2px;
    border-radius: 4px;
    height: 24px;
    background: #1a82e3;
    }
</style>
