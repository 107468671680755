<template>
    <el-col class="minicalendar" :xs="12" :md="6" @click.native="setWorkTime">
        <div v-html="createCalendarDirectiveHtml(year, sonMonth)"></div>
    </el-col>
</template>

<script>
export default {
    components: {},
    props: {
        fatherMonth: { type: Number, default: 1 }
    },
    data() {
        return {
            year: 2000,
            sonMonth: this.fatherMonth
        }
    },
    computed: {},
    watch: {},
    created() {
        this.$bus.$on('getCurYear', this.handleCurYear)
    },
    beforeDestroy() {
        this.$bus.$off('getCurYear', this.handleCurYear)
    },
    mounted() {},
    methods: {
        // 日期格式化
        formatDate(y, m, d) {
            var ny = y < 10 ? '0' + y : y
            var nm = m < 10 ? '0' + m : m
            var nd = d < 10 ? '0' + d : d
            return ny + '-' + nm + '-' + nd
        },
        createCalendarDirectiveHtml(year, month) {
            const date = new Date(year, month - 1)
            // 根据指令参数的月份，动态生成小日历块
            // 日历块头部
            let clr_hd = '<table><tr align="center"><th colspan="7">' + (year + '年' + month + '月') + '</th></tr><tr align="center" class="week-title">'
            const weekNames = ['日', '一', '二', '三', '四', '五', '六']
            for (let i = 0; i < 7; i++) {
                clr_hd = clr_hd + '<td>' + weekNames[i] + '</td>'
            }
            clr_hd = clr_hd + '</tr>'

            // 通过知道1号是星期几，获取1号前面空白的数量
            date.setMonth(month - 1, 1)
            const firstDay = date.getDay()
            // 通过知道月份最大天数生成数字块
            date.setMonth(month, 0)
            const maxDay = date.getDate()
            let dayCount = 1
            // 日历块主体
            var clr_bd = ''
            for (let i = 0; i < 6; i++) {
                clr_bd = clr_bd + '<tr align="center">'
                for (let j = 0; j < 7; j++) {
                    if (i === 0 && j < firstDay) {
                        clr_bd = clr_bd + '<td>&nbsp;</td>'
                        continue
                    }
                    if (i * 7 + j + 1 <= maxDay + firstDay) {
                        if (j % 6 === 0 || j % 7 === 0) {
                            clr_bd = clr_bd + '<td id=' + this.formatDate(year, month, dayCount) + ' class="date weekend" title="设为非工作日">' + dayCount + '</td>'
                        } else {
                            clr_bd = clr_bd + '<td id=' + this.formatDate(year, month, dayCount) + ' class="date" title="设为非工作日">' + dayCount + '</td>'
                        }
                        dayCount++
                    } else {
                        clr_bd = clr_bd + '<td>&nbsp;</td>'
                    }
                }
                clr_bd = clr_bd + '</tr>'
            }
            clr_bd = clr_bd + '</table>'
            return clr_hd + clr_bd
        },
        setWorkTime(ev) {
            const target = ev.target
            if (target.getAttribute('class')) {
                if (target.getAttribute('class').indexOf('date') > -1) {
                    if (target.getAttribute('class').indexOf('active') > -1) {
                        target.classList.remove('active')
                        target.setAttribute('title', '设为非工作日')
                    } else {
                        target.classList.add('active')
                        target.setAttribute('title', '设为工作日')
                    }
                }
            }
        },
        handleCurYear(curYear) {
            this.year = curYear
        }
    }
}
</script>

<style scoped lang="scss"></style>
