<template>
  <PermissionMatrixMgr />
</template>

<script>
export default {
    props: {
    },
    data() {
        return {
        }
    }
}
</script>
