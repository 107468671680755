<template>
  <el-row :gutter="20">
    <el-col :span="4">
      <div class="tw-item" style="background:#E0F7F8">
        <!-- <div class="twi-icon">
          <img src="@/wapp/yykq/index/images/icon_cq.png" alt="">
        </div> -->
        <div class="tw-item-inner" style="background:#E0F7F8">
          <p style="color:#333"><b>{{ personData.workdays || 0 }}</b></p>
          <span>出勤天数</span>
        </div>
      </div>
    </el-col>
    <el-col :span="4">
      <div class="tw-item" style="background:#FFF4EB">
        <!-- <div class="twi-icon">
          <img src="@/wapp/yykq/index/images/icon_qj.png" alt="">
        </div> -->
        <div class="tw-item-inner">
          <p style="color:#EC8423"><b>{{ personData.vacateNum || 0 }}</b></p>
          <span>请假次数</span>
        </div>
      </div>
    </el-col>
    <el-col :span="4">
      <div class="tw-item" style="background:#FFEBEA">
        <!-- <div class="twi-icon">
          <img src="@/wapp/yykq/index/images/icon_cd.png" alt="">
        </div> -->
        <div class="tw-item-inner">
          <p style="color:#E94243"><b>{{ personData.lateNum || 0 }}</b></p>
          <span>迟到次数</span>
        </div>
      </div>
    </el-col>
    <el-col :span="4">
      <div class="tw-item" style="background:#EBF5FF">
        <!-- <div class="twi-icon">
          <img src="@/wapp/yykq/index/images/icon_zt.png" alt="">
        </div> -->
        <div class="tw-item-inner">
          <p style="color:#E94243"><b>{{ personData.earlyNum || 0 }}</b></p>
          <span>早退次数</span>
        </div>
      </div>
    </el-col>
    <el-col :span="4">
      <div class="tw-item" style="background:#FFF4EB">
        <!-- <div class="twi-icon">
          <img src="@/wapp/yykq/index/images/icon_qk.png" alt="">
        </div> -->
        <div class="tw-item-inner">
          <p style="color:#E94243"><b>{{ personData.noCardNum || 0 }}</b></p>
          <span>缺卡次数</span>
        </div>
      </div>
    </el-col>
    <el-col :span="4">
      <div class="tw-item" style="background:#E5FAEE">
        <!-- <div class="twi-icon">
          <img src="@/wapp/yykq/index/images/icon_bk.png" alt="">
        </div> -->
        <div class="tw-item-inner">
          <p style="color:#48BA79"><b>{{ personData.resignNum || 0 }}</b></p>
          <span>补卡次数</span>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'KQINFO',
    props: {

    },
    data() {
        return {
            personData: {}
        }
    },
    computed: {
        ...mapGetters(['loginUser'])
    },
    created() {
        this.$bus.on('refreshKqData', this.requestData)
        this.requestData()
    },
    destroyed() {
        this.$bus.off('refreshKqData', this.requestData)
    },
    methods: {
        // 数据请求
        requestData() {
            // 我申请的
            this.$http({
                method: 'POST',
                url: 'yykq/homePageIndex/personMonthAttendance',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                params: {
                    account: this.loginUser.personAccount,
                    orgCode: this.loginUser.orgCode
                }
            }).then(res => {
                if (res.code === 'success') {
                    this.personData = res.body.listdata.map ? res.body.listdata.map : {}
                } else {
                    this.$message.error(res.message)
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.tw-item {
    padding: 16px;
    // padding-left: 60px;
    // font-size: 13px;
    // background-color: inherit;
    border-radius: 4px;
    position: relative;
    cursor: default;
    // border: 1px solid #d8dce5;
    // .twi-icon {
    //     width: 24px;
    //     height: 24px;
    //     // background-color: rgba(0, 0, 0, 0.3);
    //     border-radius: 50%;
    //     position: absolute;
    //     top: 50%;
    //     left: 20px;
    //     transform: translateY(-50%);
    //     img {
    //         width: 100%;
    //         height: 100%;
    //         position: absolute;
    //         top: 50%;
    //         left: 50%;
    //         transform: translateX(-50%) translateY(-50%);
    //     }
    // }
    .tw-item-inner {
        min-width: 80px;
        height: auto;
        text-align: center;
        color: #777;
        p {
            font-size: 28px;
            margin-top: 4px;
            margin-bottom: 4px;
            // color: #409EFF;
            b{ font-weight:600;}
        }
        span{
          font-size:14px;
          color: #333;
        }
    }
}
</style>
