<template>
  <el-form :model="vo">
    <div class="el-table el-table--fit el-table--border" style="100%">
      <div class="el-table__body-wrapper">
        <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
          <tbody>
            <tr>
              <td class="el-table__cell tdlbl tdw17"><span class="required">*</span>主题</td>
              <td class="el-table__cell tdcont tdw83" colspan="5">
                <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="主题" rules="required">
                  <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                    <el-input v-model="vo.title" v-can-edit name="title" placeholder="请输入主题" />
                  </el-tooltip>
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <td class="el-table__cell tdlbl tdw17"><span class="required">*</span>日程时间</td>
              <td class="el-table__cell tdcontle__cell tdcont tdw83" colspan="5">
                <div style="overflow:hidden">
                  <div style="float:left">
                    <ValidationProvider v-if="canEdit===1" ref="ValidationProvider" v-slot="{ errors }" name="日程开始时间" rules="required">
                      <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                        <el-date-picker
                          v-model="vo.startDate"
                          :clearable="false"
                          :editable="false"
                          name="startDate"
                          type="datetime"
                          format="yyyy-MM-dd HH:mm"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="请选择日程开始时间"
                        />
                      </el-tooltip>
                    </ValidationProvider>
                    <span v-else>{{ vo.startDate.substr(0,16) }}</span>
                  </div>
                  <span style="float:left;margin:0 10px">至</span>
                  <div style="float:left">
                    <ValidationProvider v-if="canEdit===1" ref="ValidationProvider" v-slot="{ errors }" name="日程结束时间" rules="required">
                      <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                        <el-date-picker
                          v-model="vo.endDate"
                          :clearable="false"
                          :editable="false"
                          name="endDate"
                          type="datetime"
                          format="yyyy-MM-dd HH:mm"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="请选择日程结束时间"
                        />
                      </el-tooltip>
                    </ValidationProvider>
                    <span v-else>{{ vo.endDate.substr(0,16) }}</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="el-table__cell tdlbl tdw17">提醒</td>
              <td class="el-table__cell tdcontle__cell tdcont tdw83" colspan="5">
                <el-checkbox-group v-model="alertTimeValue" @change="alertTimeValueChange">
                  <template v-for="item in alertTimeOption">
                    <el-checkbox :key="item.value" :disabled="canEdit === 0" :label="item.value">{{ item.text }}</el-checkbox>
                  </template>
                </el-checkbox-group>
              </td>
            </tr>
            <tr v-if="alertTimeValue.indexOf('不提醒')===-1">
              <td class="el-table__cell tdlbl tdw17">指定时间提醒</td>
              <td class="el-table__cell tdcontle__cell tdcont tdw83" colspan="5">
                <div v-if="canEdit===1">
                  <el-date-picker
                    v-model="selectAlertTime"
                    :clearable="false"
                    :editable="false"
                    name="selectAlertTime"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="请选择指定时间提醒"
                    :picker-options="pickerOptions1"
                    @change="selectAlertTimeChange"
                  />
                </div>
                <el-divider v-if="selectAlertTimeArry.length > 0&&canEdit===1" style="margin:10px 0;" />
                <div class="select_alerttime">
                  <template v-for="(time,index) in selectAlertTimeArry">
                    <span :key="index" style="margin-right:20px;display: inline-block;" @click="removeSelectAlertTime(index)">{{ time.substr(0,16) }}<i style="margin-left:5px" class="el-icon-close" /></span>
                  </template>
                </div>
              </td>
            </tr>
            <tr v-if="alertTimeShow">
              <td class="el-table__cell tdlbl tdw17"><span class="required">*</span>提醒方式</td>
              <td class="el-table__cell tdcontle__cell tdcont tdw83" colspan="5">
                <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="提醒方式" rules="required">
                  <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                    <el-radio-group v-model="vo.alertType">
                      <template v-for="item in vo.passenger.alertType">
                        <el-radio :key="item.value" :disabled="canEdit===0" :label="item.value">{{ item.text }}</el-radio>
                      </template>
                    </el-radio-group>
                  </el-tooltip>
                </ValidationProvider>
              </td>
            </tr>
            <tr>
              <td class="el-table__cell tdlbl tdw17">重复</td>
              <td class="el-table__cell tdcontle__cell tdcont tdw83" colspan="5">
                <el-radio-group v-if="canEdit===1" v-model="dupConfigValue" @change="dupConfigValueChange">
                  <template v-for="item in dupConfigOptions">
                    <el-radio :key="item.value" :disabled="canEdit===0" :label="item.value">{{ item.text }}</el-radio>
                  </template>
                </el-radio-group>
                <span v-else>{{ dupConfigValue===0? '不重复' : tipcontent }}</span>
              </td>
            </tr>
            <tr v-if="dupConfigValue===9&&canEdit===1">
              <td class="el-table__cell tdlbl tdw17">自定义</td>
              <td class="el-table__cell tdcontle__cell tdcont tdw83" colspan="5">
                <div v-if="canEdit===1">
                  <span>每</span>
                  <el-select v-model="vo.intervalNum" style="width:80px;" placeholder="请选择">
                    <el-option v-for="item in 99" :key="item" :label="item" :value="item" />
                  </el-select>
                  <el-select v-model="customDupConfig" style="width:80px;" placeholder="请选择">
                    <el-option label="天" :value="1" />
                    <el-option label="周" :value="2" />
                    <el-option label="月" :value="3" />
                    <el-option label="年" :value="4" />
                  </el-select>
                  <span>重复</span>
                </div>
                <div v-else>
                  <span>每{{ vo.intervalNum }}</span>
                  <span v-if="customDupConfig===1">天</span>
                  <span v-if="customDupConfig===2">周</span>
                  <span v-if="customDupConfig===3">月</span>
                  <span v-if="customDupConfig===4">年</span>
                  <span>重复</span>
                </div>
              </td>
            </tr>
            <tr v-if="canEdit===1&&dupConfigValue===9&&(customDupConfig===2||customDupConfig===3)">
              <td class="el-table__cell tdlbl tdw17">指定天</td>
              <td class="el-table__cell tdcontle__cell tdcont tdw83" colspan="5">
                <!-- 自定义指定周的周几 -->
                <el-checkbox-group v-if="customDupConfig===2" v-model="dupdateValue" @change="dupdateValueChange">
                  <template v-for="item in weekdays">
                    <el-checkbox :key="item.value" :disabled="canEdit === 0" :label="item.value">{{ item.text }}</el-checkbox>
                  </template>
                </el-checkbox-group>
                <!-- 自定义指定月 -->
                <el-radio-group v-else v-model="weekDup" @change="weekDupChange">
                  <!-- 每月的几号 -->
                  <el-radio :disabled="canEdit===0" style="margin-right:0" :label="0">{{ formatTime(vo.startDate,'day') }}</el-radio>
                  <el-select v-model="monthDay" style="width:100px;margin-right:20px" :disabled="weekDup===1" placeholder="几号" @change="monthDayChange">
                    <el-option v-for="(day,key) in monthDayOptions" :key="key" :label="day.text" :value="day.value" />
                  </el-select>
                  <!-- 指定月的第几周 -->
                  <el-radio :disabled="canEdit===0" :label="1" style="margin-right:0">{{ formatTime(vo.startDate,'week') }}</el-radio>
                  <el-select v-model="weekNum" style="width:100px" placeholder="第几个" :disabled="weekDup===0" @change="weekNumChange">
                    <el-option v-for="num in 6" :key="num" :label="num<6?('第'+num+'个'):'最后1个'" :value="num" />
                  </el-select>
                  <!-- 指定月的第几周的周几 -->
                  <el-select v-model="dupweek" placeholder="周几" style="width:100px;margin-left:10px" :disabled="weekDup===0" @change="dupweekChange">
                    <el-option v-for="week in weekdays" :key="week.value" :label="week.text" :value="week.value" />
                  </el-select>
                </el-radio-group>
              </td>
            </tr>
            <tr v-if="vo.dupConfig>0&&canEdit===1">
              <td class="el-table__cell tdlbl tdw17"><span class="required">*</span>持续时间</td>
              <td class="el-table__cell tdcontle__cell tdcont tdw83" colspan="5">
                <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="持续时间" rules="required">
                  <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                    <el-radio-group v-model="vo.continueConfig">
                      <template v-for="item in continueConfigOptions">
                        <el-radio :key="item.value" :disabled="canEdit===0" :label="item.value">{{ item.text }}</el-radio>
                      </template>
                    </el-radio-group>
                  </el-tooltip>
                </ValidationProvider>
              </td>
            </tr>
            <tr v-if="vo.dupConfig>0&&vo.continueConfig>1&&canEdit===1">
              <td class="el-table__cell tdlbl tdw17">
                <span class="required">*</span><span>{{ vo.continueConfig === 2 ? '指定次数' : '指定时间' }}</span>
              </td>
              <td class="el-table__cell tdcontle__cell tdcont tdw83" colspan="5">
                <div v-if="canEdit===1">
                  <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" :name="vo.continueConfig === 2 ? '指定次数' : '指定时间'" rules="required">
                    <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                      <!-- 指定次数重复 -->
                      <el-select v-if="vo.continueConfig===2" v-model="vo.assignNum" placeholder="请选择指定次数">
                        <el-option v-for="item in 99" :key="item" :label="item" :value="item" />
                      </el-select>
                      <!-- 指定时间重复 -->
                      <el-date-picker v-if="vo.continueConfig===3" v-model="vo.assignDate" :editable="false" type="date" :picker-options="pickerOptions3" format="yyyy年MM月dd日" value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择指定指定时间" />
                    </el-tooltip>
                  </ValidationProvider>
                </div>
                <div v-else>
                  <span v-if="vo.continueConfig===2">{{ vo.assignNum }}</span>
                  <span v-else>{{ formatTime(vo.assignDate) }}</span>
                </div>
              </td>
            </tr>
            <!-- 重复提示语 -->
            <!-- <tr v-if="canEdit===1&&vo.dupConfig>0">
              <td class="el-table__cell tdcontle__cell tdcont tdw83" colspan="6" style="text-align:center;">
                <span>{{ tipcontent }}</span>
              </td>
            </tr> -->
            <tr>
              <td class="el-table__cell tdlbl tdw17">地点</td>
              <td class="el-table__cell tdcontle__cell tdcont tdw83" colspan="5">
                <el-input v-model="vo.address" v-can-edit name="address" placeholder="请输入地点" />
              </td>
            </tr>
            <tr>
              <td class="el-table__cell tdlbl tdw17">受邀人</td>
              <td class="el-table__cell tdcontle__cell tdcont tdw83" colspan="5">
                <el-input v-model="vo.userName" v-can-edit v-select-dialog="'staff'" readonly name="userName" multiple="true" :root-org-code="loginUser.orgCode" placeholder="请选择受邀人" />
              </td>
            </tr>
            <tr>
              <td class="el-table__cell tdlbl tdw17">备注</td>
              <td class="el-table__cell tdcontle__cell tdcont tdw83" colspan="5">
                <el-input v-model="vo.memo" v-can-edit type="textarea" name="memo" placeholder="请输入备注" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </el-form>
</template>

<script>
const moment = require('moment')
export default {
    components: {
    },
    props: {
        moduleInfo: { type: Object, default: function() { return {} } },
        vo: { type: Object, default: function() { return { } } },
        canEdit: { type: Number, default: 1 }
    },
    data() {
        return {
            loginUser: this.$store.getters.loginUser,
            pickerOptions1: {
                disabledDate: (time) => {
                    return time.getTime() < (new Date().getTime() - 24 * 60 * 60 * 1000)
                }
            },
            pickerOptions3: {
                disabledDate: (time) => {
                    return time.getTime() < (new Date(this.vo.endDate).getTime() - 24 * 60 * 60 * 1000)
                }
            },
            alertTimeValue: [], // 提醒
            oldAlertTimeValue: [],
            selectAlertTime: '',
            selectAlertTimeArry: [],
            alertTimeOption: [
                { value: '不提醒', text: '不提醒' },
                { value: '时间发生时', text: '时间发生时' },
                { value: '5分钟前', text: '5分钟前' },
                { value: '15分钟前', text: '15分钟前' },
                { value: '30分钟前', text: '30分钟前' },
                { value: '1小时前', text: '1小时前' },
                { value: '2小时前', text: '2小时前' },
                { value: '1天前', text: '1天前' }
            ],
            dupConfigValue: null, // 重复
            customDupConfig: null, // 自定义重复
            dupConfigOptions: [
                { value: 0, text: '不重复' },
                { value: 1, text: '每天' },
                { value: 5, text: '每个工作日(周一至周五)' },
                { value: 2, text: '每周' },
                { value: 3, text: '每月' },
                { value: 4, text: '每年' },
                { value: 9, text: '自定义' }
            ],
            continueConfigOptions: [
                { value: 1, text: '一直持续' },
                { value: 2, text: '指定次数' },
                { value: 3, text: '指定时间' }
            ],
            weekdays: [
                { value: '1', text: '周一' },
                { value: '2', text: '周二' },
                { value: '3', text: '周三' },
                { value: '4', text: '周四' },
                { value: '5', text: '周五' },
                { value: '6', text: '周六' },
                { value: '7', text: '周日' }
            ],
            monthDayOptions: [], // 月份所有天数
            weekDup: null, // 自定义月的指定哪天的方式
            dupdateValue: [],
            weekNum: null, // 第几个
            dupweek: null, // 周几
            monthDay: null// 月的第几日
        }
    },
    computed: {
        tipcontent() {
            let tip = ''
            const dupConfig = this.dupConfigOptions.filter(otion => otion.value === this.vo.dupConfig)
            if (dupConfig.length > 0 && this.dupConfigValue !== 9) {
                tip += this.dupConfigValue === 5 ? '每个工作日重复' : (dupConfig[0].text + '重复')
            } else if (this.dupConfigValue === 9) {
                const obj = { 1: '天', 2: '周', 3: '个月', 4: '年' }
                tip += '每' + this.vo.intervalNum + obj[this.vo.dupConfig]
                if (this.vo.dupConfig === 2) {
                    let text = ''
                    this.weekdays.filter(week => {
                        if (this.dupdateValue.indexOf(week.value) > -1) {
                            if (text !== '')text += '、'
                            text += week.text
                        }
                    })
                    if (text !== '')tip += '(' + text + ')'
                } else if (this.vo.dupConfig === 3) {
                    if (this.weekDup === 0) {
                        tip += this.monthDay < 32 ? this.monthDay + '号' : '最后一天'
                    } else {
                        const w = this.weekdays.filter(week => week.value === this.dupweek)[0]
                        tip += '的第' + (this.weekNum < 6 ? this.weekNum + '个' : '最后1个') + w.text
                    }
                }
                tip += '重复'
            }
            if (this.vo.continueConfig === 1) {
                tip += '，一直重复'
            } else if (this.vo.continueConfig === 2) {
                tip += '，重复' + this.vo.assignNum + '次'
            } else if (this.vo.continueConfig === 3) {
                tip += '，直到' + moment(new Date(this.vo.assignDate)).format('YYYY年MM月DD日')
            }
            return tip
        },
        alertTimeShow() {
            return this.vo.alertTime && this.vo.alertTime.indexOf('不提醒') === -1
        }
    },
    watch: {
        // 自定义重复周期
        customDupConfig(val) {
            this.vo.dupConfig = val
        },
        'vo.dupConfig': {
            handler(val) {
                if (this.dupConfigValue === 9 && val === 3) {
                    this.weekDup = 0
                }
            }
        },
        'vo.startDate': {
            handler(val) {
                this.formatTime(val, 'day', 'changedate')
                this.formatTime(val, 'week', 'changedate')
            }
        },
        'vo.endDate': {
            handler(val) {
                this.vo.assignDate = moment(new Date(val)).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        selectAlertTimeArry: {
            handler() {
                this.vo.alertTime = this.alertTimeValue.join(',')
                if (this.vo.alertTime !== '') this.vo.alertTime += ','
                if (this.selectAlertTimeArry.length > 0) this.vo.alertTime += this.selectAlertTimeArry.join(',')
            },
            deep: true
        }
    },
    created() {
        this.initDate()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    methods: {
        initDate() {
            for (let i = 0; i < 32; i++) {
                const obj = { value: (i + 1), text: i + 1 < 32 ? (i + 1) + '号' : '最后一天' }
                this.monthDayOptions.push(obj)
            }

            if (!this.vo.calendarId) this.vo.calendarId = this.vo.id
            if (!this.vo.type) this.vo.type = '0'
            if (!this.vo.fileId) this.vo.fileId = this.vo.id
            // 初始化提醒
            if (this.vo.alertTime) {
                const alerttime = this.vo.alertTime.split(',')
                alerttime.filter(time => {
                    if (time.indexOf('-') > -1) {
                        this.selectAlertTimeArry.push(time)
                    } else {
                        this.alertTimeValue.push(time)
                        this.oldAlertTimeValue.push(time)
                    }
                })
            } else {
                this.vo.alertTime = '不提醒'
                this.alertTimeValue = ['不提醒']
                this.oldAlertTimeValue = ['不提醒']
            }
            // 初始化提醒方式
            if (this.vo.alertType === null || this.vo.alertType === '') {
                this.vo.alertType = 0
            } else {
                this.vo.alertType = Number(this.vo.alertType)
            }
            // 初始化重复
            if (this.vo.dupConfig !== null && this.vo.dupConfig !== '') {
                this.dupConfigValue = this.vo.dupConfig
                // 重复间隔等于1时按照每天
                if (this.vo.intervalNum === 1 || this.vo.intervalNum === null || this.vo.intervalNum === '') {
                    this.dupConfigValue = this.vo.dupConfig
                } else if (this.vo.intervalNum > 1) {
                // 大于1时按自定义
                    this.dupConfigValue = 9
                    this.customDupConfig = this.vo.dupConfig
                }
            } else {
                this.vo.dupConfig = 0
                this.dupConfigValue = 0
            }
            if ((this.vo.dupDate !== null && this.vo.dupDate !== '' && this.vo.dupDate !== '0') || (this.vo.weekNum !== null && this.vo.weekNum !== '' && this.vo.weekNum > 0)) {
                this.dupConfigValue = 9
                this.customDupConfig = this.vo.dupConfig
            }

            if (this.vo.isCustom === 0 && this.vo.dupConfig === 0) this.dupConfigValue = 0

            // 初始化自定义指定天
            if (this.dupConfigValue === 9) {
                if (this.vo.dupConfig === 2) {
                    this.dupdateValue = this.vo.dupDate !== null ? this.vo.dupDate.split(',') : []
                } else if (this.vo.dupConfig === 3) {
                    if (this.vo.weekNum === '' || this.vo.weekNum === null) {
                        this.weekDup = 0
                    } else {
                        this.weekDup = 1
                    }
                }
            }
            // 初始化持续次数/时间
            if (this.vo.assignNum === null || this.vo.assignNum === '' || this.vo.assignNum === 0) {
                this.vo.assignNum = 1
            }

            if (this.vo.assignDate === null || this.vo.assignDate === '') {
                this.vo.assignDate = moment(new Date(this.vo.endDate)).format('YYYY-MM-DD HH:mm:ss')
            }
            // 初始化自定义月时每月的重复时间
            if (this.customDupConfig === 3) {
                if (this.weekDup === 0) this.monthDay = Number(this.vo.dupDate)
                if (this.weekDup === 1) this.weekNum = this.vo.weekNum
                if (this.weekDup === 1) this.dupweek = this.vo.dupDate
            }
        },
        formatTime(time, type, event) {
            if (type === 'day') {
                if (this.monthDay === null || event === 'changedate') this.monthDay = new Date(time).getDate()
                if (this.weekDup === 0) this.vo.dupDate = this.monthDay
                return '每' + this.vo.intervalNum + '个月的'
            } else if (type === 'week') {
                let newday = new Date(time).getDay()
                if (newday === 0)newday = 7
                const d = new Date(time).getDate()
                const md = Math.ceil((d + 6 - newday) / 7)
                const w = this.weekdays.filter(week => Number(week.value) === newday)[0]
                if (this.weekNum === null || event === 'changedate') this.weekNum = md
                if (this.dupweek === null || event === 'changedate') this.dupweek = w.value
                if (this.weekDup === 1) this.vo.dupDate = this.dupweek
                if (this.weekDup === 1) this.vo.weekNum = this.weekNum
                return '每' + this.vo.intervalNum + '个月的'
            } else {
                return moment(new Date(time)).format('YYYY年MM月DD日')
            }
        },
        // 提醒值改变时
        alertTimeValueChange(val) {
            if (val.length === 0) {
                this.vo.alertTime = ''
                if (this.vo.alertTime !== '') this.vo.alertTime += ','
                if (this.selectAlertTimeArry.length > 0) this.vo.alertTime += this.selectAlertTimeArry.join(',')
            } else {
                if (this.oldAlertTimeValue.indexOf('不提醒') === -1 && val.indexOf('不提醒') > -1) {
                    this.vo.alertTime = '不提醒'
                    this.alertTimeValue = ['不提醒']
                } else if (this.oldAlertTimeValue.indexOf('不提醒') > -1 && val.length > 1) {
                    this.alertTimeValue.splice(this.alertTimeValue.indexOf('不提醒'), 1)
                    this.vo.alertTime = this.alertTimeValue.join(',')
                    if (this.vo.alertTime !== '') this.vo.alertTime += ','
                    if (this.selectAlertTimeArry.length > 0) this.vo.alertTime += this.selectAlertTimeArry.join(',')
                } else {
                    this.vo.alertTime = val.join(',')
                    if (this.vo.alertTime !== '') this.vo.alertTime += ','
                    if (this.selectAlertTimeArry.length > 0) this.vo.alertTime += this.selectAlertTimeArry.join(',')
                }
            }
            this.oldAlertTimeValue = this.alertTimeValue
        },
        // 重复值发生变化
        dupConfigValueChange(val) {
            // 重复值改变时
            if (val !== 0) {
                if (this.vo.continueConfig === null || this.vo.continueConfig === '') {
                    this.vo.continueConfig = 1
                }
            }
            if (val !== 9) {
                // 不是自定义时
                this.vo.dupConfig = val
                if (val === 0) {
                    // 不重复时
                    this.vo.intervalNum = ''
                    this.vo.continueConfig = ''
                    this.vo.assignNum = 1
                } else {
                    this.vo.intervalNum = 1
                    this.vo.assignNum = 1
                }
                this.weekNum = null
                this.dupweek = null
                this.monthDay = null
                this.vo.dupDate = ''
                this.vo.weekNum = ''
                this.vo.isCustom = 0
            } else {
                // 自定义时
                this.vo.dupConfig = 1
                this.vo.assignNum = 1
                this.customDupConfig = 1
                this.vo.intervalNum = 1
                this.vo.isCustom = 1
            }
        },
        // 自定义指定周几
        dupdateValueChange(val) {
            if (val.length === 0) {
                this.vo.dupDate = ''
            } else {
                this.vo.dupDate = val.join(',')
            }
        },
        // 自定义月指定天
        weekDupChange(val) {
            if (val === 0) {
                this.vo.dupDate = this.monthDay
                this.vo.weekNum = ''
            } else {
                this.vo.weekNum = this.weekNum
                this.vo.dupDate = this.dupweek
            }
        },
        // 自定义月的第几日重复
        monthDayChange(val) {
            this.vo.dupDate = val
            this.monthDay = val
            this.$forceUpdate()
        },
        // 自定义指定天月的第几个周
        weekNumChange(value) {
            this.vo.weekNum = value
            this.weekNum = value
            this.$forceUpdate()
        },
        // 自定义指定天月的第几个周几
        dupweekChange(value) {
            this.vo.dupDate = value
            this.dupweek = value
            this.$forceUpdate()
        },
        // 选择人员
        onSelectStaffCompleted(selectedNodes, attr) {
            let selected = ''
            let ids = ''
            selectedNodes.forEach(function(node) {
                if (selected !== '') selected += '、'
                if (ids !== '') ids += ','
                selected += node.name
                ids += node.userId
            })
            this.vo.userName = selected
            this.vo.userId = ids
        },
        // 添加指定提醒时间
        selectAlertTimeChange(val) {
            const times = this.selectAlertTimeArry.filter(time => time.indexOf(val.substr(0, 16)) > -1)
            if (times.length > 0) {
                this.$message.warning('提醒时间已存在，不能重复！')
            } else {
                this.selectAlertTimeArry.push(val)
            }
        },
        // 移除指定提醒时间
        removeSelectAlertTime(index) {
            this.selectAlertTimeArry.splice(index, 1)
        }
    }
}
</script>

<style scoped lang="scss">
/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
  width:100%;
}
/deep/ .el-date-editor{
  .el-input__inner{padding:0 30px;}
}
.tr_two .el-select {
  width: 220px;
}
.td_button {
 position: relative;
  .el-input-group__append {
      position: absolute;
      right: 4px;
      top: 5px;
      height: 36px;
  }
}
.select_alerttime{
  cursor: pointer;
  line-height: 25px;
}
/deep/ .el-divider{
  margin: 10px 0;
  word-break:normal;
}
</style>

