<template>
  <div id="AreaConfig">
    <div class="right-content">
      <el-form label-position="top">
        <!-- 仓库设置 -->
        <template v-if="!isSelectLayout">
          <el-form-item label="仓库区域最大列" required>
            <el-input v-model="areaConfig.areaCol" placeholder="仓库区域最大列" />
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item style="margin-bottom: 5px;">
            <span class="return-button" @click="selected = {}"><i class="el-icon-d-arrow-left"></i>返回仓库区属性</span>
          </el-form-item>
          <el-form-item label="区域编号" required>
            <el-input v-model="selected.areaNumber" placeholder="区域编号" />
          </el-form-item>
          <el-form-item label="区域名称" required>
            <el-input v-model="selected.title" placeholder="区域名称" :disabled="occupied.indexOf(selected.areaId) !== -1" />
          </el-form-item>
          <el-form-item label="行" required>
            <el-input v-model="selected.w" type="number" placeholder="行" @input="handleLayoutUpdate" />
          </el-form-item>
          <el-form-item label="高" required>
            <el-input v-model="selected.h" type="number" placeholder="高" @input="handleLayoutUpdate" />
          </el-form-item>
          <template v-if="selected.name === 'room'">
            <el-form-item label="门设置" required>
              <LayoutRoomConfig :selected.sync="selected" />
            </el-form-item>
          </template>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
import LayoutRoomConfig from './components/LayoutRoomConfig'
export default {
    components: { LayoutRoomConfig },
    props: {
        selected: { type: Object, default: () => {} },
        areaConfig: { type: Object, default: () => {}, required: true },
        occupied: { type: Object, default: () => [], required: true }
    },
    computed: {
        isSelectLayout() {
            return this.selected !== null && this.selected !== undefined && JSON.stringify(this.selected) !== '{}'
        }
    },
    watch: {
        selected: {
            deep: true,
            handler(val) {
                const selected = val
                selected.w = Number(selected.w)
                selected.h = Number(selected.h)
                this.$emit('update:selected', selected)
            }
        },
        areaConfig: {
            deep: true,
            handler(val) {
                this.$emit('update:areaConfig', this.areaConfig)
            }
        }
    },
    methods: {
        handleLayoutUpdate() {
            this.$bus.emit('updateLayout')
        }
    }
}
</script>

<style lang="scss" scoped>
#AreaConfig{
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
    .right-title{
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 14px;
        color: #333333;
        font-weight: 400;
        border-bottom: 1px solid #EFEFEF;
    }
    .right-content{
        flex: 1;
        overflow: auto;
        padding: 16px 16px;
    }

    .return-button{
        color:#3D92E0;
        user-select: none;
        cursor: pointer;
    }
}
</style>
