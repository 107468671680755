
<template>

  <div class="page-content bc page-content-inner container-fluid">
    <!-- PAGE CONTENT BEGINS -->
    <div class="top-tools">
      <div class="l">
        <statistics-search :search-type="searchType" @statisticsClick="statistics" />
      </div>
    </div>
    <div class="main">
      <table class="m-kq-dprev">
        <thead>
          <tr>
            <td>
              <div class="m-kq-dt"><img :src="'./images/w-imgs/yykq/cqts@2x.png'" alt=""> 出勤天数 </div>
            </td>
            <td>
              <div class="m-kq-dt"><img :src="'./images/w-imgs/yykq/cd@2x.png'" alt=""> 迟到 </div>
            </td>
            <td>
              <div class="m-kq-dt"><img :src="'./images/w-imgs/yykq/zt@2x.png'" alt="" style="width:15px"> 早退 </div>
            </td>
            <td>
              <div class="m-kq-dt"><img :src="'./images/w-imgs/yykq/qk@2x.png'" alt=""> 缺卡 </div>
            </td>
            <td>
              <div class="m-kq-dt"><img :src="'./images/w-imgs/yykq/wq@2x.png'" alt=""> 外勤 </div>
            </td>
            <td>
              <div class="m-kq-dt"><img :src="'./images/w-imgs/yykq/cc@2x.png'" alt=""> 出差</div>
            </td>
            <td>
              <div class="m-kq-dt"><img :src="'./images/w-imgs/yykq/jb@2x.png'" alt=""> 加班</div>
            </td>
            <td>
              <div class="m-kq-dt"><img :src="'./images/w-imgs/yykq/qj@2x.png'" alt=""> 请假</div>
            </td>
            <td>
              <div class="m-kq-dt"><img :src="'./images/w-imgs/yykq/bk@2x.png'" alt=""> 补卡</div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td :class="{'cur': curIndex == 1}" @click="initList(1)">{{ personData.workdays || 0 }}天</td>
            <td :class="{'cur': curIndex == 2}" @click="initList(2)">{{ personData.lateNum || 0 }}次<br><span class="sub-info">共{{ personData.lateTime || '0.00分钟' }}</span></td>
            <td :class="{'cur': curIndex == 3}" @click="initList(3)">{{ personData.earlyNum || 0 }}次<br><span class="sub-info">共{{ personData.earlyTime || '0.00分钟' }}</span></td>
            <td :class="{'cur': curIndex == 4}" @click="initList(4)">{{ personData.noCardNum || 0 }}次<br><span class="sub-info" /></td>
            <td :class="{'cur': curIndex == 10}" @click="initList(10)">{{ personData.outWorkNum || 0 }}次<br><span class="sub-info" /></td>
            <td :class="{'cur': curIndex == 5}" @click="otherList(0)">{{ personData.travelNum || 0 }}天<br><span class="sub-info" /></td>
            <td :class="{'cur': curIndex == 7}" @click="initList(7)">{{ personData.overNum || 0 }}次<br><span class="sub-info">共{{ personData.overTime || '0.00分钟' }}</span></td>
            <td :class="{'cur': curIndex == 8}" @click="otherList(1)">{{ personData.vacateNum || 0 }}次<br><span class="sub-info" /></td>
            <td :class="{'cur': curIndex == 6}" @click="otherList(2)">{{ personData.resignNum || 0 }}次<br><span class="sub-info" /></td>
          </tr>
        </tbody>
      </table>
      <div class="breadcrumb cur-msg">
        <div class="l">
          <span style="padding-right: 12px;">{{ startDate + " 至 " + endDate }}</span>
          <span v-if="curIndex == 1">出勤天数：共{{ personData.workdays || 0 }}天</span>
          <span v-if="curIndex == 2">迟到：{{ personData.lateNum || 0 }}次 &nbsp;&nbsp;&nbsp; 共{{ personData.lateTime || '0.00分钟' }}</span>
          <span v-if="curIndex == 3">早退：{{ personData.earlyNum || 0 }}次 &nbsp;&nbsp;&nbsp; 共{{ personData.earlyTime || '0.00分钟' }}</span>
          <span v-if="curIndex == 4">缺卡：{{ personData.noCardNum || 0 }}次</span>
          <span v-if="curIndex == 10">外勤：{{ personData.outWorkNum || 0 }}次</span>
          <span v-if="curIndex == 5">出差：{{ personData.travelNum || 0 }}天</span>
          <span v-if="curIndex == 7">加班：{{ personData.overNum || 0 }}次 &nbsp;&nbsp;&nbsp; 共{{ personData.overTime || '0.00分钟' }}</span>
          <el-button v-if="curIndex > 0" size="mini" style="margin-left: 12px;" @click="initList('')">清除信息</el-button>
        </div>
        <div class="r">
          <el-button v-table-toxls="wdkq" plain file-name="我的考勤" size="">
            <svg-icon :icon-class="'export'" style="color:#EC8423;" /> 导 出
          </el-button>
        </div>
      </div>
      <el-table
        id="wdkq"
        :data="listdata"
        border
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
          align="center"
        />
        <el-table-column
          prop="TIME_SPAN"
          label="考勤时段"
          width="180"
          align="center"
        />
        <el-table-column
          prop="CLOCK_IN_DATE"
          label="考勤日期"
          width="140"
          align="center"
        />
        <el-table-column
          label="打卡信息"
          width=""
          align="left"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.SIGN_ON_STATUS == 4" class="merror" style="padding-right: 8px;">
              签到：<span>{{ scope.row.SIGN_ON_TIME }} </span><span>（{{ statusArr[scope.row.SIGN_ON_STATUS] }}）</span>
            </span>
            <span v-if="scope.row.SIGN_ON_STATUS == 2 || scope.row.SIGN_ON_STATUS == 3" class="mwarn" style="padding-right: 8px;">
              签到：<span>{{ scope.row.SIGN_ON_TIME }} </span><span>（{{ statusArr[scope.row.SIGN_ON_STATUS] }}）</span>
            </span>
            <span v-if="scope.row.SIGN_ON_STATUS == 1" class="mprimary" style="padding-right: 8px;">
              签到：<span>{{ scope.row.SIGN_ON_TIME }} </span><span>（{{ statusArr[scope.row.SIGN_ON_STATUS] }}）</span>
            </span>
            <span v-if="scope.row.SIGN_ON_STATUS > 4" style="padding-right: 8px;">
              签到：<span>{{ scope.row.SIGN_ON_TIME }} </span><span>（{{ statusArr[scope.row.SIGN_ON_STATUS] }}）</span>
            </span>
            <span v-if="scope.row.SIGN_ON_TIME && scope.row.SIGN_ON_STATUS === null" class="merror" style="padding-right: 8px;">
              签到：<span>（缺卡）</span>
            </span>
            <span v-if="scope.row.SIGN_OFF_STATUS == 4" class="merror" style="padding-right: 8px;">
              签退：<span>{{ scope.row.SIGN_OFF_TIME }} </span><span>（{{ statusArr[scope.row.SIGN_OFF_STATUS] }}）</span>
            </span>
            <span v-if="scope.row.SIGN_OFF_STATUS == 2 || scope.row.SIGN_OFF_STATUS == 3" class="mwarn" style="padding-right: 8px;">
              签退：<span>{{ scope.row.SIGN_OFF_TIME }} </span><span>（{{ statusArr[scope.row.SIGN_OFF_STATUS] }}）</span>
            </span>
            <span v-if="scope.row.SIGN_OFF_STATUS == 1" class="mprimary" style="padding-right: 8px;">
              签退：<span>{{ scope.row.SIGN_OFF_TIME }} </span><span>（{{ statusArr[scope.row.SIGN_OFF_STATUS] }}）</span>
            </span>
            <span v-if="scope.row.SIGN_OFF_STATUS > 4" style="padding-right: 8px;">
              签退：<span>{{ scope.row.SIGN_OFF_TIME }} </span><span>（{{ statusArr[scope.row.SIGN_OFF_STATUS] }}）</span>
            </span>
            <span v-if="scope.row.SIGN_OFF_TIME && scope.row.SIGN_OFF_STATUS === null" class="merror" style="padding-right: 8px;">
              签退：<span>（缺卡）</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center" :class-name="'column_operate'">
          <template slot-scope="scope">
            <el-button
              v-if="detailStatus.indexOf(scope.row.SIGN_ON_STATUS) === -1 || detailStatus.indexOf(scope.row.SIGN_OFF_STATUS) === -1"
              type="text"
              size="mini"
              @click="showDetail(scope.row,scope.$index)"
            >打卡明细</el-button>
            <el-button
              v-if="scope.row.SIGN_ON_STATUS === 4 || scope.row.SIGN_OFF_STATUS === 4"
              type="text"
              size="mini"
              style="margin-left: 0;"
              @click="applyBK('resign', '补卡申请', '', 'yykq/resign',scope.row)"
            >申请补卡</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="m-kq-bottom">
        共 {{ listdata.length }} 条数据
      </div>
    </div>

    <dk-detail />
  </div>
</template>

<script>
import DkDetail from './components/dkDetail'
import StatisticsSearch from '@/wapp/common/components/StatisticsSearch'
const moment = require('moment')
// import Vue from 'vue'
import { mapGetters } from 'vuex'
export default {
    name: '',
    components: { DkDetail, StatisticsSearch },
    data() {
        return {
            statusArr: ['', '正常', '迟到', '早退', '缺卡', '出差', '其他请假', '加班', '事假', '病假'], // 状态
            startDate: this.getDate(-7),
            endDate: this.getDate(-1),
            maxdates: this.getDate(-1),
            personData: {},
            listdata: [],
            curData: {},
            curIndex: 0,
            detailStatus: [4, 5, 6, 8, 9],
            searchType: [
                {
                    type: 'range',
                    title: '统计日期',
                    rangeType: 'daterange',
                    rangeSeparator: '至',
                    dateFormat: 'yyyy-MM-dd'
                }
            ]
        }
    },
    computed: {
        ...mapGetters(['loginUser', 'routes'])
    },
    watch: {

    },
    created() {
        this.$bus.emit('showNavSearch', false)

        // this.searchData()
        // this.initList()
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true)
    },
    methods: {
        statistics(val) {
            console.log(213, val)
            this.startDate = moment(val.range[0]).format('YYYY-MM-DD')
            this.endDate = moment(val.range[1]).format('YYYY-MM-DD')
            console.log(216, this.startDate, this.endDate)
            this.searchData()
            this.initList(this.curIndex)
        },
        searchData() {
            const $scope = this
            this.$http({
                url: 'yykq/homePageIndex/personAttendance',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    account: this.loginUser.personAccount,
                    startTime: $scope.startDate + ' 00:00:00',
                    endTime: $scope.endDate + ' 23:59:59',
                    orgCode: this.loginUser.orgCode
                }

            }).then(function(data, status, headers, config) {
                $scope.personData = data.body.listdata.map || {}
            }).catch(err => {
                // 错误处理
                console.log(err)
            })
        },

        initList($status) {
            const $scope = this
            $scope.curIndex = $status
            this.$http({
                url: 'yykq/homePageIndex/personAttendanceInfo',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    account: this.loginUser.personAccount,
                    startTime: $scope.startDate + ' 00:00:00',
                    endTime: $scope.endDate + ' 23:59:59',
                    orgCode: this.loginUser.orgCode,
                    type: $status,
                    orderBy: 'clockInDate desc'
                }
            }).then(data => {
                $scope.listdata = data.body.listdata.datailList
                // const datailList = data.body.listdata.datailList
                // datailList.forEach((item) => {
                //     if (item.SIGN_OFF_STATUS !== null) {
                //         $scope.listdata.push(item)
                //     }
                // })
            }).catch(err => {
                // 错误处理
                console.log(err)
            })
        },
        /**
        * 查看明细弹出框
        */
        showDetail($d) {
            this.curData = $d
            this.$bus.emit('dkDetailDialogOpen', $d)
        },
        otherList(i) {
            // const applyArr = ['myVacate', 'myResign', 'myTravel']
            const applyArr = ['kqglCcsqDraft', 'kqglQjsqDraft', 'kqglBksqDraft']
            this.gotoPages(applyArr[i])
        },
        /**
         * 补卡申请
         */
        applyBK(code, name, start, wfEngineUrl, row) {
            let href = '/wflow?busiCode=' + code + '&wfName=' + (encodeURI(name)) + '&startNodeName=' + start
            href += '&wfEngineUrl=' + encodeURIComponent(wfEngineUrl)

            window.open('/#' + href + '&detialId=' + row.ID)
        },

        /**
        * 距离当天的天数（wd,0 当天）
        */
        getDate(wd) {
            wd = Number(wd)
            if (isNaN(wd)) { wd = 0 }
            const now = new Date()
            const t = now.getTime() + wd * 24 * 60 * 60 * 1000
            const tod = new Date(t)
            const y = tod.getFullYear()
            const m = tod.getMonth()
            const d = tod.getDate()

            return y + '-' + (`${m + 1}`.padStart(2, '0')) + '-' + (`${d}`.padStart(2, '0'))
        },

        /**
         * 两个日期间的大小
         */
        compareDate(s, e) {
            var oDate1 = new Date(s)
            var oDate2 = new Date(e)
            return oDate1.getTime() - oDate2.getTime()
        },
        // 跳转页面
        gotoPages(code) {
            const toPath = this.getMenuCode(code)
            if (toPath && toPath.path) {
                let path
                switch (toPath.meta.level) {
                    case 3:
                        path = toPath.meta.activeMenu || toPath.path
                        break
                    case 4:
                        path = toPath.meta.activeMenu + '/' + toPath.path
                        break
                    default:
                        path = toPath.path
                }
                this.$router.push({ path: path })
            }
        },
        // 递归查询菜单代码
        getMenuCode(code) {
            let toPath
            function findCode(ary) {
                ary.forEach(val => {
                    if (val.children) {
                        findCode(val.children)
                    }
                    if (!val.children && val.meta && val.meta.code === code) {
                        toPath = val
                        return false
                    }
                })
            }
            findCode(this.routes)
            return toPath
        }

    }
}
</script>

<style scoped lang="scss">
.top-tools, .breadcrumb {
    overflow: hidden;
    margin-top: 15px;
    width: 100%;
    padding: 0 15px;
    .l {
      float: left;
      // width: 480px;
    }
    .r {
      float: right;
      width: 200px;
      text-align: right
    }
}
.cur-msg {
  line-height: 30px;
  font-size: 14px;
  color: #777;
  margin-top: 30px;
  margin-bottom: 6px;
  .l {
    width: 600px;
  }
}
.main {
    padding: 0 15px;
}
	.m-kq-dprev {
    width: 100%;
    border: 1px solid #EEEEEE;
    margin-top: 20px;
    border-collapse: collapse;
    td {
      border: 1px solid #EEEEEE;
    }
    thead tr {
      height: 60px;
      td { text-align: center; font-size: 15px; color: #333; width: 11.11%;background: #F7F8FA;}
    }
    tbody tr {
      height: 80px;
      td{
        height: 80px;
        text-align: center;
        font-size: 16px;
        color: #303133;
        cursor: pointer;
        .sub-info { color: #909399; font-size: 13px;}
      }
      .cur {
        color: #1890FF;
        font-weight: 600;
        .sub-info{ color: #189FFF;}
      }
    }
  }
	.m-kq-dt {
    // cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-size:13px;
    img{
      width: 17px;
      height: 17px;
      margin-right: 8px;
      float: left;
    }
  }
	.m-kq-bottom { margin: 15px 0; padding: 15px;font-size: 14px; color: #777; }
	.merror {color: #F5222D;}
	.mprimary {color: #1890FF;}
	.mwarn {color: #FAAD14;}
  .input-group.date input{height:36px;}
  /deep/ .el-range-separator{width: 30px;}
</style>

