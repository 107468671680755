<template>
  <div v-loading="loading" :element-loading-text="ssoLoginMsg" style="width:100%;height:100%" />
</template>

<script>

export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            loading: true,
            ssoLoginMsg: '正在登陆...',
            corpId: '',
            agentId: ''
        }
    },
    computed: {

    },
    watch: {
    },
    created() {
        if (location.href.indexOf('&state=') > -1 && location.href.indexOf('?authCode=') > -1) {
            const urlData = location.search.split('=')
            const first = urlData[1].split('&state')[0]
            const second = urlData[2]
            this.$http({
                url: '/login/dingtalk/dingDingOAuth2Login',
                method: 'post',
                params: {
                    authCode: first,
                    state: second
                }
            }).then(resp => {
                history.replaceState(null, null, '/')
                if (resp.code === 'failure') {
                    this.ssoLoginMsg = resp.message
                    this.$confirm('登陆失败请返回登陆页面', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'error' })
                        .then(() => {
                            this.$router.replace('/login')
                        }).catch(() => {})
                } else {
                    this.ssoLoginMsg = '登录成功，即将进入系统...'
                    this.$store.dispatch('user/tokenSsoLogin', resp.body)
                        .then(res => {
                            this.$router.replace('/#home')
                        })
                }
            }).catch((err) => {
                this.ssoLoginMsg = err
                this.$confirm('登陆失败请返回登陆页面', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error' })
                    .then(() => {
                        this.$router.replace('/login')
                    }).catch(() => {})
            })
        }
    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
    }
}
</script>

  <style scoped lang="scss">

  </style>
