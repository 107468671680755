<template>
  <el-table
    v-if="isUpdate"
    class="elTable"
    :data="showList"
    :empty-text="emptyText"
    border
    :height="'calc(100vh - 316px)'"
  >
    <el-table-column
      type="index"
      label="序号"
      width="45"
      align="center"
      :fixed="true"
    >
      <template slot-scope="scope">
        <span>{{ scope.row.$index + 1 }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="NAME"
      width="100"
      align="left"
      :fixed="true"
      :show-overflow-tooltip="true"
    />
    <el-table-column
      label="部门"
      prop="DEPARTMENT"
      width="120px"
      :fixed="true"
      :show-overflow-tooltip="true"
    />
    <el-table-column
      v-for="(option,optionKey) in listTb"
      :key="optionKey"
      class="kq_cell"
      :label="option.title"
      width="90"
      align="center"
      :show-overflow-tooltip="true"
    >
      <template slot-scope="scope">
        <template v-if="scope.row[scope.column.label]">
          <span v-for="(status,key) in scope.row[scope.column.label]" :key="key" class="kqstatus" :style="{'color':status.color}">{{ status.status }}</span>
        </template>
        <span v-else class="kqstatus">{{ (scope.column.label &lt; today && (option.isWeek || scope.row.isHoliday )) ? '休' : ((option.isHoliday || option.isWeek) ? '休' : '无') }}</span>
      </template>
    </el-table-column>
  </el-table>

</template>

<script>

export default {
    components: {

    },
    props: {
        listTb: { type: Array, default: function() { return [] } },
        listData: { type: Array, default: function() { return [] } },
        today: { type: String, default: function() { return '' } },
        emptyText: { type: String, default: function() { return '' } }
    },
    data() {
        return {
            isUpdate: true,
            showList: [],
            page: 1,
            oldScrollTop: 0
        }
    },
    computed: {
    },
    watch: {
        listData: {
            handler(val) {
                this.isUpdate = false
                this.$nextTick(() => {
                    this.isUpdate = true
                })
                setTimeout(() => {
                    this.initListdata()
                }, 500)
            },
            deep: true
        }
    },
    created() {

    },
    beforeDestroy() {
        this.tableScroll.removeEventListener('scroll', this.getEventListenerSrcoll, true)
    },
    mounted() {
        setTimeout(() => {
            this.initListdata()
        }, 500)
    },
    methods: {
        initListdata() {
            this.tableScroll = document.querySelector('.elTable .el-table__body-wrapper')
            this.tableScroll.addEventListener('scroll', this.getEventListenerSrcoll, true)
            this.page = 1
            this.showRowNum = Math.ceil(this.tableScroll.clientHeight / 42) + 10
            // this.copyListdata = JSON.parse(JSON.stringify(this.listData))
            const filterList = this.listData.filter((list, index) => {
                if (index < this.page * this.showRowNum) return true
            })
            this.showList = []
            this.showList.push(...filterList)
        },
        getEventListenerSrcoll() {
            // this.tableScroll
            const sh = this.tableScroll.scrollHeight// 滚动区域总高度
            const ch = this.tableScroll.clientHeight// 滚动区域可视高度
            const st = this.tableScroll.scrollTop// 滚动条顶部距离
            if (this.listData.length <= 0) return
            if (st - this.oldScrollTop >= 5) {
                this.oldScrollTop = st
                if (Math.ceil(ch + st + 5) >= sh) {
                    if (this.showList[this.showList.length - 1] && this.showList[this.showList.length - 1].$index && this.showList[this.showList.length - 1].$index >= this.listData.length - 1) {
                        return
                    } else {
                        const showlist = []
                        this.page++
                        const filterList = this.listData.filter((list, index) => {
                            if (index + 1 >= (this.page - 2) * this.showRowNum && index + 1 < this.page * this.showRowNum) return true
                        })
                        showlist.push(...filterList)
                        this.showList = showlist
                        this.tableScroll.scrollTop = Math.ceil(ch / 2) * 3.5
                        this.oldScrollTop = this.tableScroll.scrollTop
                    }
                } else if (st - 5 <= 0) {
                    if (this.showList[0].$index === 0) {
                        return
                    } else {
                        const showlist = []
                        this.page--
                        const filterList = this.listData.filter((list, index) => {
                            if (index + 1 < (this.page + 2) * this.showRowNum && index + 1 >= this.page * this.showRowNum) return true
                        })
                        showlist.push(...filterList)
                        this.showList = showlist
                        this.tableScroll.scrollTop = Math.ceil(ch / 2) * 3
                        this.oldScrollTop = this.tableScroll.scrollTop
                    }
                }
            }
            this.$forceUpdate()
        }
    }
}
</script>

<style scoped lang="scss">
.kqstatus:not(:first-child){
    padding-left: 5px;
}
</style>
