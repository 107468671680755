<template>
  <div>
    <el-card :class="elCardShow?'box-card':'box1-card1'">
      <div>
        <div style="margin: 0 auto;padding-top:30px;width:1000px" class="searchinput">
          <el-input
            v-model="input2"
            placeholder="搜索..."
            clearable
            style="height: 40px"
            @keyup.enter.native="searchinput"
            @clear="clearclick"
          >
            <el-button slot="append" class="btn" style="height: 40px;width: 100px;color: #ffff;" @click="searchinput"> 搜全部</el-button>
          </el-input>
          <div v-if="listdatas.length>0" style="font-size: 13px;color: #999999;padding-top: 14px;"><span>最近搜索</span><i style="padding-left: 10px;" class="el-icon-delete" @click="zuijdelete" /></div>
          <div v-if="listdatas.length>0" style="padding-top: 14px;" class="listdatas">
            <span
              v-for="(item,index) in listdatas"
              :key="index"
              style="padding: 9px 20px;
                   border-radius: 20px;
                  display: inline-block;
                 background-color: #F7F8FA;cursor: pointer;margin-bottom: 14px;margin-right: 20px;
                     max-width: 185px; overflow: hidden;text-overflow: ellipsis; white-space: nowrap;"
              @click="listdataclick(item.value)"
            >{{ item.value }}</span>
          </div>
        </div>
      </div>
    </el-card>
  </div>

</template>

<script>
export default {
    components: {

    },
    props: {
        elCardShow: { type: Boolean, default: false },
        searchKey: { type: String, default: function() { return '' } }
    },
    data() {
        return {
            input2: '',
            listdatas: []
        }
    },
    computed: {

    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {
        this.listdatas = JSON.parse(localStorage.getItem('jobOrderNoArr')) || []
        this.input2 = this.searchKey
    },
    methods: {
        // 搜索内容
        searchinput() {
            if (this.input2.trim() !== '') {
                // window.open('/#/globalsearch?searchKey=' + this.input2)
                this.elCardShow = false
                // this.Cookies.remove('portal_topMenuIndex')
                // this.$router.push({ path: '/globalsearch', query: { searchKey: this.input2, searchTime: ~window.moment() }})
                this.setIontoStorage('jobOrderNoArr')
                this.$emit('searchinput', this.input2)
            } else {
                return
            }
        },
        // 选择历史记录
        listdataclick(item) {
            this.input2 = item
        },
        // 删除历史记录
        zuijdelete() {
            window.localStorage.removeItem('jobOrderNoArr')
            this.listdatas = JSON.parse(localStorage.getItem('jobOrderNoArr')) || []
        },
        clearclick() {

        },
        setIontoStorage(type) {
            const self = this
            const noArr = []; let text = ''; let value = ''
            switch (type) {
                case 'srcOrderNoArr':
                    text = 'srcOrderNoArr'
                    value = self.srcOrderNoArr
                    break
                case 'jobOrderNoArr':
                    text = 'jobOrderNoArr'
                    value = this.input2
                    break
                case 'cntNo':
                    text = 'cntNoArr'
                    value = self.cntNo
                    break
            }
            noArr.push({ value: value })
            if (JSON.parse(localStorage.getItem(text))) {
                if (localStorage.getItem(text).indexOf(value) > -1) {
                    return
                }
                if (JSON.parse(localStorage.getItem(text)).length > 5) {
                    const arr = JSON.parse(localStorage.getItem(text))
                    arr.pop()
                    localStorage.setItem(text, JSON.stringify(arr))
                }
                localStorage.setItem(text, JSON.stringify(noArr.concat(JSON.parse(localStorage.getItem(text)))))
            } else {
                localStorage.setItem(text, JSON.stringify(noArr))
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.box-card{
    position: absolute;
    left: 0px;
    top: 60px;
    height: 225px;
    width: 100%;
}
.box1-card1{
    display: none;
}
/deep/.searchinput input{
    height: 44px !important;
}
/deep/.searchinput .el-input-group__append{
    background-color: var(--bgColor) !important ;
    &:hover{
      background-color: var(--bgColor) ;
      color:#fff;
    }
}
</style>
