<template>
  <div>
    <div class="towType">
      <!-- 布局 -->
      <div v-if="isOldUnit!=='false'" class="brief">
        <h4>布局</h4>
        <div v-if="listColorLayout.length>0" class="item">
          <div v-for="(list,index) in listColorLayout" :key="index" class="box" style="background:transparent" @click="changeLayout(list)">
            <div :style="list===layoutStyle?'border: 2px solid #0EA04D;border-right: 2px solid #0EA04D':''">
              <div class="header" style="height:16px;background:#EBEDF4;"></div>
              <div class="footer" :style="{'width':list===layoutStyle?'146px':'150px'}" style="background:transparent;margin-top:2px;display:flex">
                <div class="left" :style="{'width':list===1?'10px':'30px'}" style="height:92px;margin-right: 2px;background:#EBEDF4;"></div>
                <div v-if="list===1" style="width:20px;background:#EBEDF4;height:92px;margin-right: 2px;"></div>
                <div class="right" style="background:#ebedf4;width: calc(100% - 32px);"></div>
              </div>
              <div v-if="list===layoutStyle" class="sure"></div>
              <div v-if="list===layoutStyle" class="icon"><i class="el-icon-check"></i></div>
            </div>
          </div>
        </div>
        <div v-else style="text-align:center;margin-top:30px;">
          <img src="images/w-imgs/nomessage.png" style="max-width:180px"><br>
          <span style="font-size:15px;color:#dbdbdb">暂无数据</span>
        </div>
      </div>
      <!-- 简约 -->
      <div class="brief">
        <h4>简约纯色</h4>
        <div v-if="listColorChu.length>0" class="item">
          <div v-for="(list,index) in listColorChu" :key="index" class="box" @click="vo.uiStyle=list.styleCode,updateData()">
            <div v-if="vo.uiStyle==list.styleCode" style="border: 2px solid #0EA04D;     border-right: 2px solid #0EA04D">
              <div class="header" :style="{'background':list.bg} "></div>
              <div class="footer" style="width:146px">
                <div class="left" :style="{'background': list.columnMenu===0?list.lmBg:list.leftToolbarBg}"></div>
                <div class="right" style="width:146px"></div>
              </div>
              <div v-if="vo.uiStyle==list.styleCode" class="sure"></div>
              <div v-if="vo.uiStyle==list.styleCode" class="icon"><i class="el-icon-check"></i></div>
            </div>
            <div v-else>
              <div class="header" :style="{'background': list.bg}"></div>
              <div class="footer">
                <div class="left" :style="{'background':list.columnMenu===0?list.lmBg:list.leftToolbarBg}"></div>
                <div class="right"></div>
              </div>
            </div>

          </div>
        </div>
        <div v-else style="text-align:center;margin-top:30px;">
          <img src="images/w-imgs/nomessage.png" style="max-width:180px"><br>
          <span style="font-size:15px;color:#dbdbdb">暂无数据</span>
        </div>
      </div>
      <!-- 主题皮肤 -->
      <div class="cool">
        <h4>四季节日</h4>
        <div v-if="listColorImg.length>0" style="display:flex;" class="item">
          <div v-for="list in listColorImg" :key="list.id" class="boxImg" @click="vo.uiStyle=list.styleCode,updateData()">
            <div v-if="vo.uiStyle==list.styleCode" style="border: 2px solid #0EA04D;height:114px">
              <div class="sureImg"></div>
              <div class="iconImg"><i class="el-icon-check"></i></div>
              <img style="width: 150px;height: 110px;object-fit: cover;" :src="api_url + '/base/styleConfig/download?nameField=workspaceBgImg&dataField=workspaceBgImgFile&id=' + list.id" alt="">
            </div>
            <div v-else>
              <img style="width: 150px;height: 110px;object-fit: cover;" :src="api_url + '/base/styleConfig/download?nameField=workspaceBgImg&dataField=workspaceBgImgFile&id=' + list.id" alt="">
            </div>
          </div>
        </div>
        <div v-else style="text-align:center;margin-top:30px;">
          <img src="images/w-imgs/nomessage.png" style="max-width:180px"><br>
          <span style="font-size:15px;color:#dbdbdb">暂无数据</span>
        </div>
      </div>
      <div class="cool">
        <h4>商务风格</h4>
        <div v-if="listColorsw.length>0" style="display:flex;" class="item">
          <div v-for="list in listColorsw" :key="list.id" class="boxImg" @click="vo.uiStyle=list.styleCode,updateData()">
            <div v-if="vo.uiStyle==list.styleCode" style="border: 2px solid #0EA04D;height:114px">
              <div class="sureImg"></div>
              <div class="iconImg"><i class="el-icon-check"></i></div>
              <img style="width: 150px;height: 110px;object-fit: cover;" :src="api_url + '/base/styleConfig/download?nameField=workspaceBgImg&dataField=workspaceBgImgFile&id=' + list.id" alt="">
            </div>
            <div v-else>
              <img style="width: 150px;height: 110px;object-fit: cover;" :src="api_url + '/base/styleConfig/download?nameField=workspaceBgImg&dataField=workspaceBgImgFile&id=' + list.id" alt="">
            </div>
          </div>
        </div>
        <div v-else style="text-align:center;margin-top:30px;">
          <img src="images/w-imgs/nomessage.png" style="max-width:180px"><br>
          <span style="font-size:15px;color:#dbdbdb">暂无数据</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            moduleInfo: {
                updateUrl: 'base/user/updateBasicSetup',
                listdataUrl: 'base/styleConfig/listdata',
                getByIdUrl: 'base/user/getById'
            },
            listColor: [], // 皮肤
            vo: {
                uiStyle: '', // 皮肤颜色
                isLoginSms: 0 // 短信通知
            },
            listColorLayout: [0, 1],
            listColorImg: [],
            listColorChu: [],
            listColorsw: [],
            listColorImgA: [],
            listColorChuA: [],
            listColorswA: [],
            listColorImgB: [],
            listColorChuB: [],
            listColorswB: [],
            api_url: '',
            layoutStyle: 0,
            loginUser: this.$store.getters.loginUser,
            isOldUnit: false
        }
    },
    computed: {
        curUser() {
            return this.$store.getters.loginUser
        }
    },
    watch: {

    },
    created() {
        if (this.$route.meta.params && this.$route.meta.params.indexOf('isOldUnit') > -1) {
            this.isOldUnit = this.$route.meta.params.replace('isOldUnit=', '')
        }
        this.api_url = process.env.VUE_APP_BASE_API
        this.$bus.emit('showNavSearch', false) // 取消右上搜索按钮
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true) // 还原右上搜索按钮
    },
    mounted() {
        this.$http({ // 访问用户基本设置信息
            method: 'GET',
            contentType: 'application/x-www-form-urlencoded',
            params: { id: this.curUser.userId },
            url: this.moduleInfo.getByIdUrl
        }).then(response => {
            if (response.code === 'success') {
                this.vo = response.body.vo
                if (this.vo.isLoginSms === null) {
                    this.vo.isLoginSms = 0
                }
                const uiStyle = document.querySelector('body').getAttribute('id')
                if (uiStyle) {
                    this.vo.uiStyle = uiStyle
                }
                this.getList(this.vo.uiStyle)
            } else {
                this.$message.error(response.message)
            }
        }, err => {
            console.log(err)
        })
    },
    methods: {
        getList(uiStyle) {
            const data = new FormData()
            data.append('urlsearch', 'orgCode:' + this.curUser.orgCode)
            this.$http({ // 获取颜色列表
                method: 'POST',
                data: data,
                url: this.moduleInfo.listdataUrl
            }).then(response => {
                if (response.code === 'success') {
                    this.listColor = response.body.pageInfo.list
                    if (this.listColor.length > 0) {
                        this.listColor.forEach(item => {
                            if (uiStyle === item.styleCode) {
                                this.layoutStyle = item.columnMenu
                            }
                            if (this.isOldUnit === 'false') {
                                if (item.columnMenu === 0) {
                                    if (item.styleType === 1) {
                                        this.listColorImgA.push(item)
                                    } else if (item.styleType === 2) {
                                        this.listColorswA.push(item)
                                    } else {
                                        this.listColorChuA.push(item)
                                    }
                                }
                            } else {
                                if (item.columnMenu === 0) {
                                    if (item.styleType === 1) {
                                        this.listColorImgA.push(item)
                                    } else if (item.styleType === 2) {
                                        this.listColorswA.push(item)
                                    } else {
                                        this.listColorChuA.push(item)
                                    }
                                } else {
                                    if (item.styleType === 1) {
                                        this.listColorImgB.push(item)
                                    } else if (item.styleType === 2) {
                                        this.listColorswB.push(item)
                                    } else {
                                        this.listColorChuB.push(item)
                                    }
                                }
                            }
                        })
                        this.changeArray(this.layoutStyle)
                    }
                } else {
                    this.$message.error(response.message)
                }
            }, err => {
                console.log(err)
            })
        },
        changeLayout(list) {
            this.layoutStyle = list
            this.changeArray(list)
        },
        changeArray(type) {
            if (type === 0) {
                this.listColorImg = JSON.parse(JSON.stringify(this.listColorImgA))
                this.listColorsw = JSON.parse(JSON.stringify(this.listColorswA))
                this.listColorChu = JSON.parse(JSON.stringify(this.listColorChuA))
            } else {
                this.listColorImg = JSON.parse(JSON.stringify(this.listColorImgB))
                this.listColorsw = JSON.parse(JSON.stringify(this.listColorswB))
                this.listColorChu = JSON.parse(JSON.stringify(this.listColorChuB))
            }
        },
        updateData() { // 保存用户勾选的信息到后台
            this.$http({
                method: 'PUT',
                contentType: 'application/x-www-form-urlencoded',
                data: this.vo,
                url: this.moduleInfo.updateUrl
            }).then(response => {
                if (response.code === 'success') {
                    this.$message.success(response.message)
                    this.Cookies.set('ECWEB-JWTSSO-TOKEN-' + location.port, response.body['ECWEB-JWTSSO-TOKEN'])
                    this.Cookies.set('uiStyle', this.vo.uiStyle)
                    var _this = this
                    _this.$store.getters.loginUser.uiStyle = _this.vo.uiStyle
                    _this.$store.commit('loginUser', this.$store.getters.loginUser)
                    location.reload()
                } else {
                    this.$message.error(response.message)
                }
            }, err => {
                console.log(err)
                this.$message.error(err)
            })
        }
    }
}
</script>

<style scoped lang="scss">
.towType{
  h4{
    height: 30px;
    font-size: 13px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #333333;
    line-height: 17px;
    // padding-left: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-bottom: 1px solid #EBEEF5;
  }
  .brief{
    .item{
      display: flex;
      padding-left: 10px;
      margin-bottom: 28px;
      .box{
        width: 150px;
        margin-right: 30px;
        background: #f0f3f8;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        .sure{
          position: absolute;
          font-size: 12px;
          top: -10px;
          left: 120px;
          width: 50px;
          height: 30px;
          color:#fff;
          background: #339966;
          transform: rotate(45deg);
        }
        .icon{
          position: absolute;
          font-size: 14px;
          top: 4px;
          right: 1px;
          color:#fff;
        }
        .header{
          width: 150px;
          height: 30px;
          background: green;
        }
        .footer{
          width: 150px;
          background: #f0f3f8;
          .left{
            width: 30px;
            height: 80px;
            background: blueviolet;
          }
        }
      }
    }
  }
  .cool{
    .item{
      padding-left: 10px;
      .change{
        width: 150px;
        height: 100px;
        background: linear-gradient(to bottom right, #008ca2, #004ab3);
      }
    }
  }
}
.boxImg {
    position: relative;
    overflow: hidden;
    margin-right: 30px;
    cursor: pointer;
}
.sureImg {
    position: absolute;
    font-size: 12px;
    top: -10px;
    left: 123px;
    width: 50px;
    height: 30px;
    color: #fff;
    background: #339966;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.iconImg {
    position: absolute;
    font-size: 14px;
    top: 4px;
    right: 1px;
    color: #fff;
}

</style>
