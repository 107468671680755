<template>
  <PageContent :title="formTitle">
    <el-form>
      <div class="el-table el-table--fit el-table--border">
        <div class="el-table__body-wrapper">
          <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
            <tbody>
              <tr>
                <td class="el-table__cell tdlbl tdw17">首页文件数量提示</td>
                <td class="el-table__cell tdcont tdw83">
                  <el-checkbox-group v-model="vo.todoCountHint">
                    <el-checkbox v-for="(option,optKey) in queryNchData" :key="optKey" :label="option.code">{{ option.name }}</el-checkbox>
                  </el-checkbox-group>
                </td>
              </tr>

              <tr>
                <td class="el-table__cell tdlbl tdw17">待办待阅消息通知</td>
                <td class="el-table__cell tdcont tdw83">
                  <el-radio-group v-model="vo.isMessageNotice">
                    <el-radio :label="1">仅限待办</el-radio>
                    <el-radio :label="2">待办待阅</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                  </el-radio-group>
                </td>
              </tr>
              <tr>
                <td class="el-table__cell tdlbl tdw17">消息通知方式</td>
                <td class="el-table__cell tdcont tdw83">
                  <el-checkbox-group v-model="vo.msgTypes">
                    <el-checkbox v-for="(option,optKey) in msgTypeList" :key="optKey" :label="option.value">{{ option.text }}</el-checkbox>
                  </el-checkbox-group>
                </td>
              </tr>
              <tr v-show="vo.isMessageNotice>0">
                <td class="el-table__cell tdlbl tdw17">接收通知时间</td>
                <td class="el-table__cell tdcont tdw83">
                  <el-radio-group v-model="vo.messageNoticeType">
                    <el-radio :label="1">24小时接收</el-radio>
                    <el-radio :label="0">定时接收</el-radio>
                  </el-radio-group>
                </td>
              </tr>
              <tr v-show="vo.isMessageNotice>0 && vo.messageNoticeType === 0">
                <td class="el-table__cell tdlbl tdw17">接收通知时间范围:</td>
                <td class="el-table__cell tdcont tdw83">
                  <div class="time">
                    <span>时间范围1：</span>
                    <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="开始时间" rules="required">
                      <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                        <el-time-select v-model="vo.startTime" placeholder="起始时间" :picker-options="{start: '00:00',step: '00:30',end: '23:30'}" />
                      </el-tooltip>
                    </ValidationProvider>
                    至
                    <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="结束时间" rules="required">
                      <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                        <el-time-select v-model="vo.endTime" placeholder="结束时间" :picker-options="{start: '00:00', step: '00:30', end: '23:30', minTime: vo.startTime }" />
                      </el-tooltip>
                    </ValidationProvider>
                  </div>
                  <div class="time">
                    <span>时间范围2：</span>
                    <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="开始时间" rules="required">
                      <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                        <el-time-select v-model="vo.startTime1" placeholder="起始时间" :picker-options="{start: '00:00',step: '00:30',end: '23:30'}" />
                      </el-tooltip>
                    </ValidationProvider>
                    至
                    <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="结束时间" rules="required">
                      <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                        <el-time-select v-model="vo.endTime1" placeholder="结束时间" :picker-options="{start: '00:00',step: '00:30',end: '23:30',minTime:vo.startTime1}" />
                      </el-tooltip>
                    </ValidationProvider>
                  </div>
                </td>
              </tr>
              <tr v-show="vo.isMessageNotice>0">
                <td class="el-table__cell tdlbl tdw17" :rowspan="vo.wfType === 0?2:1">接收通知文件类型</td>
                <td class="el-table__cell tdcont tdw83">
                  <el-radio-group v-model="vo.wfType">
                    <el-radio :label="1">所有文件类型</el-radio>
                    <el-radio :label="0">仅指定的文件类型</el-radio>
                  </el-radio-group>
                </td>
              </tr>
              <tr v-if="vo.isMessageNotice>0 && vo.wfType === 0">
                <td class="el-table__cell tdcont tdw83">
                  <WfNames :vo="vo" :can-edit="1" :value-field="'name'" />
                </td>
              </tr>
              <tr v-show="vo.isMessageNotice>0&&fileValueList">
                <td class="el-table__cell tdlbl tdw17">接收通知文件紧急程度</td>
                <td class="el-table__cell tdcont tdw83">
                  <el-checkbox-group v-model="vo.fileLevel">
                    <el-checkbox v-for="(option,optKey) in fileValueList" :key="optKey" :label="option.value">{{ option.text }}</el-checkbox>
                  </el-checkbox-group>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-form>
    <div slot="footer">
      <el-button plain icon="el-icon-check" @click="submitForm">保 存</el-button>
    </div>
  </PageContent>
</template>

<script>
const moment = require('moment')

export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            formTitle: '办文提醒',
            moduleInfo: {
                updateNoticeSetupUrl: 'usersetting/noticeSetup/update', // 修改办文提醒信息url
                insertNoticeSetupUrl: 'usersetting/noticeSetup/insert',
                queryParamConfigUrl: 'base/paramConfig/findParamValueByName',
                queryNoticeDataUrl: 'usersetting/noticeSetup/querydata', // 查询办文提醒是否存在数据
                addNewNoticeDataUrl: 'usersetting/noticeSetup/addNew', // 初始化办文提醒
                queryNodoCountHintDataUrl: 'base/menuMgr/querydata' // 查询已办待办数量提醒
            },
            vo: {
                todoCountHint: [],
                wfName: [],
                fileLevel: [],
                msgTypes: []
            },
            doing: false,
            time: [],
            time1: [],
            queryNchData: [],
            fileValueList: [],
            msgTypeList: []
        }
    },
    computed: {
        curUser() {
            return this.$store.getters.loginUser
        }
    },
    created() {
        this.$bus.emit('showNavSearch', false) // 取消右上搜索按钮
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true) // 还原右上搜索按钮
    },
    mounted() {
        const _this = this
        // 进入页面时，先检查是否存在数据，若有，则调用该数据;若无，先初始化.
        this.$http({
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: [{ 'compare': '=', 'field': 'orgCode', 'merge': false, 'value': this.curUser.orgCode },
                { 'compare': '=', 'field': 'ownerAccount', 'merge': false, 'value': this.curUser.personAccount }],
            url: this.moduleInfo.queryNoticeDataUrl
        }).then(response => {
            if (response.code === 'success') {
                if (response.body.listdata.length === 0) {
                    this.$http({
                        method: 'POST',
                        contentType: 'application/x-www-form-urlencoded',
                        url: this.moduleInfo.addNewNoticeDataUrl
                    }).then(response => {
                        if (response.code === 'success') {
                            this.vo = response.body.vo
                            // 从未保存进入时给以下属性赋初值
                            this.vo.isMessageNotice = 1
                            this.vo.messageNoticeType = 1
                            this.vo.wfType = 1
                            this.vo.status = -1
                            this.vo.fileLevel = []
                            this.vo.todoCountHint = []
                            this.vo.msgTypes = []

                            this.vo.startTime = '08:30'
                            this.vo.endTime = '12:00'
                            this.vo.startTime1 = '14:00'
                            this.vo.endTime1 = '18:00'
                        }
                    })
                } else {
                    _this.vo = response.body.listdata[0]
                    if (_this.vo.fileLevel === null) {
                        _this.vo.fileLevel = []
                    } else {
                        _this.vo.fileLevel = _this.vo.fileLevel.split(',')
                    }
                    if (_this.vo.todoCountHint === null) {
                        _this.vo.todoCountHint = []
                    } else {
                        _this.vo.todoCountHint = _this.vo.todoCountHint.split(',')
                    }
                    if (_this.vo.startTime !== null && this.vo.startTime !== '') {
                        const st = new Date('1970/01/01 ' + _this.vo.startTime)

                        _this.vo.startTime = moment(st).format('HH:mm')
                    } else {
                        _this.vo.startTime = '08:30'
                    }
                    if (_this.vo.endTime !== null && this.vo.endTime !== '') {
                        _this.vo.endTime = moment(new Date('1970/01/01 ' + _this.vo.endTime)).format('HH:mm')
                    } else {
                        _this.vo.endTime = '12:00'
                    }
                    if (_this.vo.startTime1 !== null && this.vo.startTime1 !== '') {
                        _this.vo.startTime1 = moment(new Date('1970/01/01 ' + _this.vo.startTime1)).format('HH:mm')
                    } else {
                        _this.vo.startTime1 = '14:00'
                    }
                    if (_this.vo.endTime1 !== null && this.vo.endTime1 !== '') {
                        _this.vo.endTime1 = moment(new Date('1970/01/01 ' + _this.vo.endTime1)).format('HH:mm')
                    } else {
                        _this.vo.endTime1 = '18:00'
                    }
                    if (_this.vo.msgTypes === null) {
                        // 默认消息类型为短信，兼容现有产品
                        _this.vo.msgTypes = [1]
                    } else {
                        var msgTypesArray = _this.vo.msgTypes.split(',') // 分割字符串
                        _this.vo.msgTypes = msgTypesArray.map(function(str) {
                            return parseInt(str, 10) // 转换为整数
                        })
                    }

                    _this.vo.status = 1
                }
            } else {
                this.$message.error(response.message)
            }
        }, err => {
            console.log(err)
        })
        // 获取已办待办数量提醒数据，产生多选
        this.$http({
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: [{ 'compare': '=', 'field': 'sysCode', 'merge': false, 'value': this.curUser.sysCode },
                { 'compare': 'like', 'field': 'href', 'merge': false, 'value': 'workflow' },
                { 'compare': 'like', 'field': 'href', 'merge': false, 'value': 'wfTaskItem' },
                { 'compare': 'notLike', 'field': 'href', 'merge': false, 'value': '?' },
                { 'compare': 'notLike', 'field': 'href', 'merge': false, 'value': 'Draft' }],
            url: this.moduleInfo.queryNodoCountHintDataUrl,
            params: { orderBy: 'orderNo' }
        }).then(response => {
            if (response.code === 'success') {
                this.queryNchData = response.body.listdata
            } else {
                console.log(response.message)
            }
        }, err => {
            console.log(err)
        })

        // 获取系统配置消息通知方式
        this.$http({
            method: 'GET',
            contentType: 'application/x-www-form-urlencoded',
            url: 'base/sysSetup/queryOpenMsgType'
        }).then(response => {
            if (response.code === 'success') {
                const openMsgType = response.body.listdata || []
                if (openMsgType.length === 0) {
                    openMsgType.push({ value: 1, text: '短信通知' })
                }
                this.msgTypeList = openMsgType
            }
        }, err => {
            console.log(err)
        })

        // 获取缓急程度数据，产生多选
        this.getEnevleList()
    },
    methods: {
        getEnevleList() {
            const loginUser = this.$store.getters.loginUser
            const param = {
                urlsearch: `orgCode:${loginUser.orgCode}`
            }
            this.$app.listdata('gwgl/fileLevel/listdata', param).then(res => {
                if (res.code === 'success') {
                    if (res.body.pageInfo.total > 0) {
                        const colorsList = res.body.pageInfo.list
                        colorsList.forEach(level => {
                            this.fileValueList.push({ value: level.eName, text: level.eName })
                        })
                    } else {
                        this.$http({
                            method: 'GET',
                            contentType: 'application/x-www-form-urlencoded',
                            params: { 'orgCode': this.curUser.orgCode, 'paramName': 'file.s.level' },
                            url: this.moduleInfo.queryParamConfigUrl
                        }).then(response => {
                            if (response.code === 'success') {
                                const paramValue = response.body.value
                                if (paramValue !== undefined && paramValue !== null) {
                                    const levels = paramValue.split(':')
                                    levels.forEach((level, index) => {
                                        this.fileValueList.push({ value: level, text: level })
                                    })
                                }
                            }
                        })
                    }
                }
            }
            )
        },
        // 点击保存,向后台传递用户数据
        submitForm() {
            if (this.doing) return

            this.formValidate(() => {
                this.doing = true

                // 通过验证后，准备提交数据
                const voBak = JSON.parse(JSON.stringify(this.vo))
                voBak.fileLevel = voBak.fileLevel.join(',')
                voBak.todoCountHint = voBak.todoCountHint.join(',')
                voBak.msgTypes = voBak.msgTypes.join(',')

                if (voBak.todoCountHint === null) voBak.todoCountHint = ''
                if (voBak.msgTypes === null) voBak.msgTypes = ''

                if (voBak.startTime.split(':').length === 2) voBak.startTime += ':00'
                if (voBak.endTime.split(':').length === 2) voBak.endTime += ':00'
                if (voBak.startTime1.split(':').length === 2) voBak.startTime1 += ':00'
                if (voBak.endTime1.split(':').length === 2) voBak.endTime1 += ':00'

                // 保存
                this.$http({
                    method: this.vo.status === 1 ? 'PUT' : 'POST',
                    contentType: 'application/x-www-form-urlencoded',
                    data: voBak,
                    url: this.vo.status === 1 ? this.moduleInfo.updateNoticeSetupUrl : this.moduleInfo.insertNoticeSetupUrl
                }).then(response => {
                    if (response.code === 'success') {
                        this.vo.status = 1
                        this.$message.success(response.message)
                    } else {
                        this.$message.error(response.message)
                    }

                    this.doing = false
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    div.time{
        margin:5px;
        /deep/ .el-date-editor.el-range-editor{
            width:100%;
            cursor: pointer;
        }
        /deep/ .el-range-editor--medium .el-range-input{
            cursor: pointer;
        }
        /deep/ .el-range-editor--medium .el-range__icon{
            display: none!important;
        }
    }
</style>

