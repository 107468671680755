<template>
  <div>
    <el-dialog v-if="dialogFormVisible" v-el-drag-dialog :visible.sync="dialogFormVisible" :close-on-click-modal="false" :width="'1000px'" :title="'导入值班人员'">
      <el-dialog
        v-el-drag-dialog
        :close-on-click-modal="false"
        title="导入信息"
        :visible.sync="dialogVisible"
        width="30%"
        append-to-body
      >
        <!-- <div v-if="successNum>0" style="font-size:14px;margin:20px 10px"><span style="color:green;font-size:16px;font-weight:600">{{ successNum }}</span>条数据导入成功!</div>
        <div v-if="failNum>0" style="font-size:14px;margin:20px 10px"><span style="color:red;font-size:16px;font-weight:600">{{ failNum }}</span>条数据失败，请检查数据格式。</div>
        <div style="color:#409eff;font-size:14px;margin:20px 10px">导入失败序号：{{ failText }}</div>
        <div v-if="failList && failList.length>0" style="max-height: 350px;  overflow: auto;">
          <div v-for="item in failList" :key="item" style="color:#409eff;font-size:14px;margin-left:20px;line-height: 39px;">{{ item }}</div>
        </div> -->
        <span style="color:#000;;font-size:16px;font-weight:600">处理成功！其中导入成功<span style="color:green;">{{ successNum }}</span>条，导入失败<span style="color:red">{{ failNum }}</span>条。</span>
        <div v-if="failList.length" style="max-height: 350px;  overflow: auto;">
          <div v-for="item in failList" :key="item" style="color:#409eff;font-size:14px;line-height: 20px; margin: 10px 0;">{{ item }}</div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cliseDia">关 闭</el-button>
        </span>
      </el-dialog>
      <el-form :model="vo">
        <div class="el-table el-table--fit el-table--border inner-table">
          <div class="el-table__body-wrapper">
            <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
              <tbody>
                <tr>
                  <td class="el-table__cell tdlbl tdw17"><span class="required">*</span>导入文件</td>
                  <td class="el-table__cell tdcont tdw83">
                    <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="值班人员文件" rules="required">
                      <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                        <el-upload
                          ref="upload"
                          :on-change="handleUploadChange"
                          action=""
                          :file-list="fileList"
                          :show-file-list="false"
                          :auto-upload="false"
                          :with-credentials="true"
                          :accept="'.xls,.xlsx'"
                        >
                          <el-input v-model="salaryAttachName" name="salaryAttachName" placeholder="请选择导入的值班人员文件">
                            <el-button slot="append" icon="el-icon-upload2" />
                          </el-input>
                        </el-upload>
                      </el-tooltip>
                    </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td class="el-table__cell tdlbl tdw17">文件模板</td>
                  <td class="el-table__cell tdcont tdw83">
                    <div style="line-height:25px">
                      <span>请按模板进行导入！</span><br>
                      <span>
                        <a title="点击下载" style="color:rgb(64, 158, 255)" @click="fileDownload('/templates/importDutyPerson.xlsx')">导入值班人员模板.xsl</a>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button v-for="(btn,key) in busiformBtns" :key="key" :disabled="doing" :plain="btn.btnType===null || btn.btnType===''" :icon="'el-icon-'+btn.btnIcon" :type="btn.btnType!==null?btn.btnType:''" @click="btn.btnClick">{{ btn.btnName }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            loginUser: this.$store.getters.loginUser,
            dialogFormVisible: false,
            salaryAttachName: '',
            fileList: [],
            dialogVisible: false,
            isDisabled: false,
            doing: false,
            busiformBtns: [
                {
                    id: 'btn_close',
                    btnName: '关 闭',
                    btnIcon: 'close',
                    btnClick: this.closeDialog,
                    btnPermission: '',
                    btnType: ''
                }, {
                    id: 'btn_save',
                    btnName: '确 定',
                    btnIcon: 'check',
                    btnClick: this.submitSalary,
                    btnPermission: 'saveObject',
                    btnType: 'primary'
                }
            ],
            successNum: '',
            failNum: '',
            failMsg: '',
            failList: [],
            failText: '',
            second: false
        }
    },
    computed: {},
    watch: {},
    created() {
        this.$bus.on('openImportForm', this.openImportForm)
    },
    beforeDestroy() {
        this.$bus.off('openImportForm', this.openImportForm)
        this.dialogVisible = false
        this.dialogFormVisible = false
    },
    mounted() {},
    methods: {
        openImportForm() {
            this.dialogFormVisible = true
        },
        handleUploadChange(filed, fileList) {
            if (filed.name.indexOf('.xls') === -1 && filed.name.indexOf('.xlsx')) {
                this.$alert('上传文件格式错误', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                })
                this.fileList = []
                this.salaryAttachName = ''
                return
            }
            this.fileList = [filed]
            this.salaryAttachName = filed.name
        },
        // 导入
        submitSalary() {
            if (!this.salaryAttachName) {
                this.$alert('请先上传导入文件！', '操作提示', { type: 'warning' })
                return
            }
            this.formValidate(() => {
                this.isDisabled = true
                this.doing = true
                const file = new FormData()
                file.append('file', this.fileList[0].raw)
                file.append('orgCode', this.loginUser.orgCode)
                file.append('orgName', this.loginUser.orgCode.orgName)
                this.$http({
                    url: '/zbgl/dutyPerson/importData',
                    method: 'POST',
                    data: file
                }).then(res => {
                    this.isDisabled = false
                    if (res.code === 'success') {
                        // if (res.body.msgList && (res.body.msgList.length > 0)) {
                        //     // 导入失败
                        //     this.failNum = res.body.msgList.length
                        //     this.failMsg = res.message
                        //     this.successNum = res.body.successCount
                        //     this.dialogVisible = true
                        //     this.failList = res.body.msgList
                        //     this.doing = false
                        // } else {
                        //     // 导入成功
                        //     this.$message.success('导入成功！')
                        //     this.dialogVisible = false
                        //     this.successNum = res.body.successCount
                        //     this.dialogFormVisible = false
                        //     this.doing = false
                        //     this.salaryAttachName = ''
                        //     this.fileList = []
                        // }
                        this.successNum = res.body.successCount
                        this.failList = res.body.msgList
                        this.failNum = res.body.failureCount
                        this.doing = false
                        this.dialogVisible = true
                        this.fileList = []
                        this.$nextTick(() => {
                            this.$bus.emit('refreshListData')
                        })
                    } else {
                        this.$message.error(res.message)
                    }
                    this.$nextTick(() => {
                        this.doing = false
                    })
                })
            })
        },
        // 完成
        cliseDia() {
            this.dialogVisible = false
            this.salaryAttachName = ''
            this.fileList = []
        },
        closeDialog() {
            this.dialogVisible = false
            this.dialogFormVisible = false
            this.salaryAttachName = ''
            this.fileList = []
        }
    }
}
</script>

  <style scoped lang="scss">
  .el-tooltip{
    /deep/ .el-upload.el-upload--text{
        width: 100%;
    }
  }
  </style>
