import router from './index'
import { store } from 'ecinc-cloud-wappaio'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
//import getPageTitle from '@/wapp/common/utils/get-page-title'
import { constantRoutes } from './index'
import defaultSettings from '@/wapp/settings'
import { httpRequest } from 'ecinc-cloud-wappaio'
import Cookies from 'js-cookie'
NProgress.configure({ showSpinner: false }) // NProgress Configuration
const whiteList = ['/login', '/retrieve', '/cmccLogin', '/ddLogin', '/auth-redirect', '/ssLoginError'] // no redirect whitelist
router.beforeEach(async (to, from, next) => {
    let num = 1
    if (num === 1) {
        if (location.href.indexOf('productCode') > -1) {
            // 单独进入子产品
            localStorage.setItem('subProducts', 1)
            num++
        }
    }
    if (to.query.jwtToken !== undefined) {
        //门户sso登录OA
        httpRequest({
            method: 'POST',
            url: 'login/ssoTokenLogin',
            data: { jwtToken: to.query.jwtToken, productCode: to.query.productCode || window.productCode }
        }).then((response) => {
            if (response.code === 'success') {
                delete to.query.sessionId
                delete to.query.tSessionId
                delete to.query.jwtToken
                delete to.query.productCode
                Cookies.set('setupShow', 1)
                store.dispatch('user/tokenSsoLogin', response.body).then(async (res) => {
                    await store.dispatch('tagsView/delAllViews')
                    next({ ...to, replace: true })
                })
            } else {
                next({
                    path: '/ssLoginError',
                    query: {
                        errorMessage: response.message
                    }
                })
            }
        })
        return
    } else if (to.query.tSessionId !== undefined) {
        //门户sso登录OA
        let reqParam = {
            method: 'POST',
            url: 'login/ssoTokenLogin',
            data: { tSessionId: to.query.tSessionId, productCode: to.query.productCode || window.productCode }
        }

        if (to.query.from === 'ecyoa') {
            //易办公客户端9.0.0、9.0.1、9.0.2版本打开OA网页，9.0.3版本开始弃用
            reqParam = {
                method: 'POST',
                url: 'login/tSessionIdLogin',
                params: { tSessionId: to.query.tSessionId, productCode: to.query.productCode || window.productCode }
            }
        }

        httpRequest(reqParam).then((response) => {
            if (response.code === 'success') {
                delete to.query.sessionId
                delete to.query.tSessionId
                delete to.query.jwtToken
                delete to.query.productCode
                Cookies.set('setupShow', 1)
                store.dispatch('user/tokenSsoLogin', response.body).then(async (res) => {
                    await store.dispatch('tagsView/delAllViews')
                    next({ ...to, replace: true })
                })
            } else {
                next({
                    path: '/ssLoginError',
                    query: {
                        errorMessage: response.message
                    }
                })
            }
        })
        return
    } else if (to.query.appid && to.query.code) {
        // 企业微信扫码登录
        const params = { corpId: to.query.appid, agentId: to.query.agentId, code: to.query.code }
        if (window.productCode) {
            params.productCode = window.productCode
        }
        httpRequest({
            method: 'POST',
            url: 'login/wxqyh/wxqyhSsoLogin',
            params: params
        }).then((response) => {
            if (response.code === 'success') {
                delete to.query.appid
                delete to.query.agentId
                delete to.query.code
                delete to.query.state
                store.dispatch('user/tokenSsoLogin', response.body).then(async (res) => {
                    await store.dispatch('tagsView/delAllViews')
                    next({ ...to, replace: true })
                })
            }
        })
        return
    } else if (to.query.corpId && to.query.agentId && to.query.authCode) {
        // 钉钉扫码登陆
        httpRequest({
            method: 'POST',
            params: { corpId: to.query.corpId, agentId: to.query.agentId, code: to.query.authCode },
            url: 'login/dingtalk/dingDingScanLogin'
        }).then((response) => {
            if (response.code === 'success') {
                delete to.query.corpId
                delete to.query.agentId
                delete to.query.authCode
                store.dispatch('user/tokenSsoLogin', response.body).then(async (res) => {
                    await store.dispatch('tagsView/delAllViews')
                    next({ ...to, replace: true })
                })
            }
        })
        return
    }

    // start progress bar
    NProgress.start()

    // determine whether the user has logged in
    let hasToken = window.$auth.getToken() // Cookies.get('ECWEB-JWTSSO-TOKEN-' + location.port) // this.$auth.getToken()

    if (hasToken) {
        /*if (to.meta.level >= 4) {
            document.title = getPageTitle(to.meta.parentTitle)
        } else {
            document.title = getPageTitle(to.meta.title)
        }*/
        const constRoute = constantRoutes.some((route) => {
            return route.path === to.path
        })

        // 添加皮肤样式
        let addStyle = false
        if (defaultSettings.addStyle || to.query.parentId) {
            addStyle = true
        }

        if (constRoute) {
            await store.dispatch('user/updateStore')
            if (addStyle) {
                await store.dispatch('user/setUIStyle', addStyle)
                defaultSettings.addStyle = false
            }
            next()
        } else if (to.path === '/formLogin') {
            // if is logged in, redirect to the home page
            next({ path: '/' })
            NProgress.done()
        } else {
            // determine whether the user has obtained his permission routes
            const hasRoutes = store.getters.routes && store.getters.routes.length > 0
            if (hasRoutes) {
                if (to.matched.length > 0 && to.matched[0].meta && to.matched[0].meta.parentId) {
                    if (to.matched[0].meta.level === 1) {
                        window.$bus.emit('updateTopMenuIndex', to.matched[0].meta.id)
                    } else {
                        window.$bus.emit('updateTopMenuIndex', to.matched[0].meta.parentId, to.matched[0].meta.id)
                    }
                    await store.dispatch('user/updateStore')
                    if (addStyle) {
                        await store.dispatch('user/setUIStyle', addStyle)
                        defaultSettings.addStyle = false
                    }
                    next()
                } else {
                    const paths = to.fullPath.split('/')
                    if (paths.length > 3) {
                        next(`/tm/${paths[2]}?redirect=${to.path}`)
                    } else {
                        await store.dispatch('user/updateStore')
                        if (addStyle) {
                            await store.dispatch('user/setUIStyle', addStyle)
                            defaultSettings.addStyle = false
                        }
                        next()
                    }
                }
            } else {
                try {
                    await store.dispatch('user/updateStore')
                    if (addStyle) {
                        await store.dispatch('user/setUIStyle', addStyle)
                        defaultSettings.addStyle = false
                    }

                    const loginUser = store.getters.loginUser
                    defaultSettings.title = loginUser.sysName
                    defaultSettings.logo = process.env.VUE_APP_BASE_API + '/base/sysSetup/downloadLogoBySysCode?sysCode=' + loginUser.sysCode
                    document.title = loginUser.sysName
                    store.dispatch('settings/changeSetting', defaultSettings)

                    // generate accessible routes map based on roles
                    const accessRoutes = await store.dispatch('route/generateRoutes')

                    if (accessRoutes.length > 0) {
                        // dynamically add accessible routes
                        router.addRoutes(accessRoutes)

                        // hack method to ensure that addRoutes is complete
                        // set the replace: true, so the navigation will not leave a history record
                        next({ ...to, replace: true })
                    } else {
                        next('/401')
                    }
                } catch (error) {
                    // remove token and go to login page to re-login
                    await store.dispatch('user/resetToken')
                    console.log(error || 'Has Error')
                    next(`/login`)
                    NProgress.done()
                }
            }
        }
    } else {
        /* has no token*/
        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            next()
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            next(`/login`)
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})
