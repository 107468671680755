<template>
    <div class="zs_view_content">
        <div class="page-container">
            <div v-if="loading" v-loading="loading" element-loading-text="正在加载..." />
            <div v-if="editoral == 1" class="pdf-container">
                <div>
                    <ViewBodyFile :vo="vo" :water-mark="true" :fl-bodyfile="bodyfile" :wf-attach-url="'ecoa/document'" style="width: 1000px" />
                </div>
                <div class="form_flContent_right">
                    <div class="fileNmae">
                        <span class="name">文件信息</span>
                    </div>
                    <div class="datatab">发布者：{{ vo.publishUer }}</div>
                    <div class="datatab">发布时间：{{ vo.publishDate | dateTransform }}</div>
                    <span v-if="attachments.length > 0" style="margin-left: 32px; color: #409eff; cursor: pointer" class="preview" @click="pdfDownload()">下载</span>
                    <div v-if="attachments.some((obj) => obj.fileType === 'ATTACH')" class="master">附件</div>
                    <div v-for="(attach, index) in attachments.filter((obj) => obj.fileType === 'ATTACH')" :key="index" class="attach-list otherattach">
                        <el-link type="primary" :underline="false" style="margin-left: 48px">
                            <img :src="imgUrl(attach)" style="width: 16px; padding-top: 2px; float: left" alt="" />
                            <span style="width: 198px">{{ attach.fileName || attach.name }}&nbsp;&nbsp;&nbsp;&nbsp;{{ attach.fileSize }}</span>
                        </el-link>
                        <div class="download">
                            <span class="preview" @click="handlePreview(attach)">预览</span>
                            <span class="preview" @click="handleDownload(attach)">下载</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!loading && editoral !== 1" class="form-container" style="padding-bottom: 30px; padding: 0 100px">
                <div class="form-header">
                    <div class="form-title">
                        <div v-if="viewContent === '1'" class="form-title-child">
                            {{ vo.flSubject }}
                        </div>
                    </div>
                    <div class="section-title">
                        <span class="r-text">作者：{{ vo.flCreator }}</span>
                        <span class="r-text">发布时间：{{ vo.flCreateDate | dateTransform }}</span>
                        <span class="r-text">
                            <svg-icon icon-class="liulan" style="width: 16px; height: 16px; padding-top: 4px" />
                            <span style="margin-left: 5px">{{ vo.visitCounts || 0 }}次</span>
                        </span>
                        <span class="r-text">
                            <svg-icon
                                v-if="!collectionStatus"
                                icon-class="fiveStar"
                                :style="{
                                    width: '13px',
                                    height: '13px',
                                    cursor: wfParams.readonly ? 'default' : 'pointer'
                                }"
                                @click="collection()"
                            />
                            <svg-icon
                                v-else
                                icon-class="fiveStar1"
                                :style="{
                                    width: '13px',
                                    height: '13px',
                                    cursor: wfParams.readonly ? 'default' : 'pointer'
                                }"
                                @click="collection()"
                            />
                            <span style="margin-left: 5px">{{ collectionNum || 0 }} 次</span>
                        </span>
                    </div>
                    <!-- 标签 -->
                    <div v-if="vo.labelNames && vo.labelNames.split(',').length > 0" class="labelBox" style="color: #999">
                        <div>
                            标签：
                            <span v-for="(item, index) in vo.labelNames.split(',')" :key="index" style="margin-right: 10px">{{ item }}</span>
                        </div>
                        <div style="border-bottom: 1px solid #edebf0; margin: 15px 0; width: 100%" />
                    </div>
                </div>

                <!-- 内容 -->
                <ViewBodyArticle v-if="viewContent === '1'" :article-data="vo.flContent" />
                <div v-if="viewContent === '4' && vo.coverName" style="text-align: center">
                    <img class="courseImg" :src="base_api + '/zsgl/config/mapTemplate/download?dataField=coverData&nameField=coverName&id=' + vo.id" alt="" style="width: 100%" />
                </div>
            </div>
            <!-- 附件 -->
            <div v-if="viewContent !== '3'">
                <!-- 知识附件 -->
                <div v-if="viewContent === '1'">
                    <div v-if="attachments.filter((obj) => obj.fileType === 'ATTACH').length > 0" style="padding-bottom: 30px">
                        <div class="titleAttachment" style="margin-bottom: 20px">知识附件</div>
                        <div v-for="(item, index) in attachments.filter((obj) => obj.fileType === 'ATTACH')" :key="index" @click="handleUploadPreview(item)">
                            <div style="display: flex; align-items: center; cursor: pointer; line-height: 3">
                                <svg-icon :icon-class="changeIcons(item.fileName)" style="width: 16px; height: 16px" />
                                <span style="margin-left: 10px; color: #333">{{ item.fileName }}</span>
                                <span style="margin-left: 20px; color: #999">
                                    {{ item.fileSize }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
            </div>
        </div>
        <!-- 评论 -->
        <!-- <div class="page-container comment-discuss" style="min-height: 30%">
      <comment
        :vo="vo"
        :work-type="passageType"
        :readonly="wfParams.readonly"
        class="form-container"
        style="padding-top: 30px"
      />
    </div> -->
    </div>
</template>

<script>
// _this.$options.components['BusiForm'] = _this.$loadComponent("#/dcontent/vuebusiform/W/htgl.htda.htdaDetail.vue?sysCode="+_this.$route.meta.sysCode)
const moment = require('moment')
// import comment from '@/wapp/zsgl/components/comment/index.vue'
import ViewBodyArticle from '@/wapp/common/components/ViewBodyArticle/index'
import { mapGetters } from 'vuex'

export default {
    components: {
        // comment,
        ViewBodyArticle
    },
    filters: {
        dateTransform: function (val) {
            if (!val) {
                return moment().format('YYYY-MM-DD HH:mm:ss')
            }
            return val
        }
    },
    props: {},
    data() {
        return {
            loading: true,
            wfParams: {},
            wfInstance: {},
            vo: {},
            wfJsonData: {},
            curTN: {},
            attachments: [],
            viewContent: '',
            base_api: '',
            fileList: [],
            mapCatalogueTree: [],
            passageType: '',
            collectionStatus: false, // 收藏状态
            collectionNum: 0,
            contentTitle: '',
            bodyfile: null,
            wfAttachUrl: '',
            editoral: 0
        }
    },
    computed: {
        ...mapGetters(['routes', 'loginUser']),
        publishTime() {
            if (this.vo.signDate !== undefined && this.vo.signDate !== null) {
                return this.vo.signDate.split('.')[0]
            } else {
                return this.vo.publishDate
            }
        },
        // 知识信息
        changeIcons() {
            return (name) => {
                // if()
                const last = name.split('.').pop()
                if (last === 'doc' || last === 'doc') {
                    return 'word1'
                } else if (last === 'ppt') {
                    return 'ppt1'
                } else if (last === 'txt') {
                    return 'txt1'
                } else if (last === 'xlsx' || last === 'xls') {
                    return 'excel1'
                } else if (last === 'jpg' || last === 'jpeg' || last === 'Jpeg' || last === 'png' || last === 'gif' || last === 'bmp') {
                    return 'file_img'
                } else {
                    return 'pdf1'
                }
            }
        }
    },
    watch: {},
    created() {
        this.viewContent = this.$route.query.viewContent
        console.log(this)
        this.wfParams = this.$route.query
        this.base_api = process.env.VUE_APP_BASE_API
        // this.updatePviews()
        this.loadWfInstance()
    },
    beforeDestroy() {},
    mounted() {
        this.getCollectionNumber()
        this.getCollectionStatus()
        const cookieStyle = this.Cookies.get('uiStyle')
        let style = ''
        if (cookieStyle !== this.loginUser.uiStyle) {
            style = this.loginUser.uiStyle
            this.Cookies.set('uiStyle', style)
        } else {
            style = cookieStyle
        }
        document.querySelector('body').setAttribute('id', this.loginUser.orgCode !== 'plat' ? style : 'skinScienceBlue')
    },
    methods: {
        // 更新阅读量
        updatePviews() {
            if (this.$route.query.readonly) return
            this.$http({
                contentType: 'application/x-www-form-urlencoded',
                url: '/portal/cms/article/getByReadNum?id=' + this.$route.query.busiDataId,
                method: 'get'
            }).then((res) => {
                this.vo.pviews++
                console.log('res', res)
            })
        },
        // 收藏
        collection() {
            if (this.wfParams.readonly) {
                return
            }
            this.$http({
                contentType: 'application/x-www-form-urlencoded',
                url: '/user/collection/addNew',
                method: 'POST',
                data: {}
            }).then((res) => {
                if (res.code === 'success') {
                    console.log(res.body, this, '333')
                    res.body.vo.busiDataId = this.$route.query.busiDataId
                    if (this.$route.query.viewContent === '1') {
                        res.body.vo.title = this.vo.flSubject ? this.vo.flSubject : this.vo.subject
                    } else {
                        res.body.vo.title = this.vo.subject
                    }
                    res.body.vo.type = this.passageType
                    res.body.vo.url = this.$route.fullPath
                    res.body.vo.operationType = 1
                    res.body.vo.module = 'document'
                    // res.body.vo.module = this.wfInstance.module
                    res.body.vo.sysCode = this.$store.getters.loginUser.sysCode
                    res.body.vo.workflowType = this.vo.workflowType
                    res.body.vo.flDocumentType = this.vo.flDocumentType
                    res.body.vo.fileCode = this.vo.flWjbh
                    res.body.vo.flGkbm = this.vo.flGkbm
                    res.body.vo.flGkbmId = this.vo.flGkbmId
                    this.$http({
                        contentType: 'application/x-www-form-urlencoded',
                        url: '/user/collection/insert',
                        method: 'POST',
                        data: res.body.vo
                    }).then((innerRes) => {
                        if (innerRes.code === 'success') {
                            console.log(innerRes, '解救i')
                            this.collectionStatus = !this.collectionStatus
                            this.$message.success(innerRes.message)
                            this.getCollectionNumber()
                        } else {
                            this.$message.error(innerRes.message)
                        }
                    })
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 获取文章收藏状态
        getCollectionStatus() {
            this.$http({
                contentType: 'application/x-www-form-urlencoded',
                url: `/user/collection/getIfCollection?busiDataId=${this.$route.query.busiDataId}&operationType=1&ownerId=${this.loginUser.personId}`,
                method: 'GET'
            }).then((res) => {
                if (res.code === 'success') {
                    this.collectionStatus = res.body.data
                } else {
                    this.$alert.error(res.message)
                }
            })
        },
        // 获取文章收藏数量
        getCollectionNumber() {
            this.$http({
                contentType: 'application/x-www-form-urlencoded',
                url: `/user/collection/getCollectionCount?busiDataId=${this.$route.query.busiDataId}&operationType=1`,
                method: 'GET'
            }).then((res) => {
                if (res.code === 'success') {
                    this.collectionNum = res.body.data
                    console.log(res.body.data, '收藏量')
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        pdfDownload() {
            console.log('this.attachments', this.attachments)
            let attach = {}
            this.attachments.forEach((item) => {
                if (item.fileType === 'BODY' && item.fileName.indexOf('.pdf') > -1) {
                    attach = item
                }
            })
            if (attach.id) {
                window.open(process.env.VUE_APP_BASE_API + '/' + 'workflow/wfBusiAttach/downloadById?attachIds=' + attach.id)
            }
        },
        loadWfInstance() {
            console.log(this.viewContent, this.vo, 'this.viewContent')

            // document.title = this.wfInstance.wfName1 + ' - ' + this.wfInstance.title
            this.loading = false

            if (this.viewContent === '1') {
                this.passageType = '新闻头条'
                this.$http({
                    contentType: 'application/x-www-form-urlencoded',
                    url: '/ecoa/documentCenter/getById?id=' + this.$route.query.busiDataId,
                    method: 'GET',
                    data: {}
                }).then((res) => {
                    this.loading = false
                    if (res.code === 'success') {
                        console.log('deleteComment', res)
                        this.vo = res.body.vo
                        if (this.vo.passenger.attachments.length > 0) {
                            console.log('11')
                            this.editoral = 1
                        } else {
                            console.log('00')
                            this.editoral = 0
                        }
                        if (!this.bodyfile) {
                            this.vo.passenger.attachments.forEach((item) => {
                                if (item.fileType === 'BODY' && item.fileName.indexOf('.pdf') !== -1) {
                                    this.bodyfile = item
                                }
                            })
                        }
                    } else {
                        this.$alert(res.message, '操作提示', {
                            type: 'error',
                            callback: () => {
                                window.close()
                            }
                        })
                    }
                })
                this.wfInstance.wfName1 = '知识中心'
            }
        },
        getCookie(cname) {
            var name = cname + '='
            var ca = document.cookie.split(';')
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i].trim()
                if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
            }
            return ''
        },
        // 知识信息 附件下载
        handleUploadPreview(attach) {
            if (attach.id === undefined) return
            window.open(process.env.VUE_APP_BASE_API + '/' + this.wfAttachUrl + '/downloadById?inline=true&attachIds=' + attach.id)
        }
    }
}
</script>

<style scoped lang="scss">
@import '~@/wapp/common/styles/variables.module.scss';
.zs_view_content {
    .page-container {
        // width: 1000px;
        // min-height: 50%;
        // background: #fff;
        // margin: 0 auto;

        .form-container {
            // width: 824px;
            // margin: 0 auto;
            // padding-top: 1px;
            // padding-bottom: 30px;
            .courseImg {
                max-width: 824px;
                padding: 15px;
            }
            /deep/ .el-table {
                .cell {
                    padding: 0 15px !important;
                }
                .el-table__row {
                    cursor: pointer;
                }
            }
            .zsztFj {
                /deep/ .el-table {
                    .el-table__header-wrapper {
                        th.is-leaf {
                            background: #f5f7fa;
                        }
                    }
                }
            }
            // /deep/ .el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
            //     border-right: 0;
            // }
            .form-header {
                .form-title {
                    font-size: 24px;
                    font-weight: bold;
                    text-align: left;
                    margin: auto;
                    color: #333333;
                    padding: 30px 0 20px;
                    position: relative;
                    max-width: 100%;
                    width: max-content;
                    font-weight: 700;
                    // .form-title-child{
                    //   display: inline-block;
                    //   text-align: center;
                    // }
                    .startBox {
                        position: absolute;
                        right: 0;
                        bottom: 23px;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        color: #999999;
                    }
                }
                .section-title {
                    overflow: hidden;
                    line-height: 22px;
                    text-align: center;
                    padding-bottom: 30px;
                    .l-text {
                        float: left;
                        margin-bottom: 0px;
                    }
                    .r-text {
                        color: #999999;
                        margin-right: 30px;
                    }
                }
                .labelBox {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    // padding-bottom: 10px;
                    .labelItem {
                        //   color: #999999;
                        //   border: 1px solid #999999;
                        padding: 5px 15px;
                        border-radius: 14px;
                        margin-right: 10px;
                        margin-bottom: 10px;
                    }
                }
            }

            /deep/ .richtext-container {
                margin-top: 5px;
            }

            .attach-list {
                height: 20px;
                margin: 10px 0px;
            }
        }

        /deep/ .el-loading-mask {
            margin-top: 100px;
        }
    }
    .comment-discuss {
        position: relative;
        &::before {
            position: absolute;
            content: '';
            height: 10px;
            top: 0;
            left: 0;
            background: #f3f3f3;
            // width: 1250px;
            width: 100%;
        }
    }
}

/deep/ .el-form .el-table__body {
    line-height: normal;
}
.titleAttachment {
    color: #333;
    font-size: 13px;
    font-weight: bold;
}
.pdf-container {
    display: flex;
    justify-content: space-between;
    background-color: #eee;
    height: calc(100vh - 60px);
}
#bodyfilebox[data-v-26760d23] {
    height: 100vh !important;
    background-color: #fff;
}
.form-title-child {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 14px;
}
.form_flContent_right {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
    // height:  calc(100vh + 36px);
    width: 300px;
    background-color: #fff;
    .fileNmae {
        display: flex;
        align-items: center;
        // justify-content: space-around;
        margin: 16px 32px;
        height: 34px;
        span {
            height: 14px;
            line-height: 14px;
        }
        .name {
            border-left: 4px solid #212a77;
            font-weight: bold;
            padding-left: 8px;
        }
        .collet {
            color: #999999;
        }
    }
    .datatab {
        margin-bottom: 16px;
        color: #1e1e1e;
        margin-left: 32px;
    }
    .master {
        border-left: 4px solid #212a77;
        font-weight: bold;
        margin-left: 36px;
        padding-left: 8px;
        margin-bottom: 18px;
        margin-top: 12px;
    }
}
</style>
