<template>
    <div style="padding-top: 30px;padding-bottom: 20px;">
        <div class="form-header" style="text-align: center;margin-bottom:20px"><div class="form-title">流程审批</div><!----></div>
    <DataTable :project_vo="vo" />
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        vo: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {
        this.$options.components['DataTable'] = this.$loadComponent(`#/dcontent/vuelistpage/tbgl.wfInfo.list.vue?sysCode=${this.$store.getters.loginUser.sysCode}`)
    },
    beforeDestroy() {},
    mounted() {},
    methods: {}
}
</script>

<style scoped lang="scss"></style>
