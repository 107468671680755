<template>
  <div class="blank-container page-holiday">
    <div class="app-container">
      <div class="el-table-toolbar">
        <div class="toolbar-left">
          <div style="display:flex;align-items:center">
            <span class="label">部门：</span>
            <el-select v-model="searchDate.dept" placeholder="请选择">
              <el-option v-for="item in departmentList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <span class="label" style="padding:0 10px">月份：</span>
            <el-date-picker v-model="searchDate.year" type="monthrange" :clearable="false" value-format="yyyy-MM" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" />
            <span style="padding:0 10px">
              <el-button type="primary" @click="getListInfo">统计</el-button>
            </span>
          </div>
        </div>
        <div class="toolbar-right">
          <el-button-group>
            <el-button v-table-toxls="views" style="height:32px" plain file-name="月度值班安排表">
              <svg-icon icon-class="export" /> 导出
            </el-button>
          </el-button-group>
        </div>
      </div>
      <div>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="workDay" label="日期" width="100" align="center">
            <template slot-scope="scope">
              <span>{{ changeDay(scope.row.workDay) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="reponseLevel" label="响应级别" width="100" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.reponseLevel">{{ scope.row.reponseLevel+'级' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="daliyLeader" label="带班领导" width="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.leaderConttact">{{ scope.row.daliyLeader+'（'+scope.row.leaderConttact+'）' }}</span>
              <span v-else>{{ scope.row.daliyLeader }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="dayStaff" label="白天值班人员" width="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.dayContact">{{ (scope.row.dayStaff || scope.row.dayName)+'（'+scope.row.dayContact+'）' }}</span>
              <span v-else>{{ scope.row.dayStaff || scope.row.dayName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="nightStaff" label="晚班值班人员" width="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.nightContact">{{ ( scope.row.nightStaff || scope.row.nightName )+'（'+scope.row.nightContact+'）' }}</span>
              <span v-else>{{ scope.row.nightStaff || scope.row.nightName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="90" align="center" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <el-link @click="editRow(scope.row)">编辑</el-link>
            </template>
          </el-table-column>
        </el-table>
        <div style="float:right;padding:2px 5px;margin-top:10px">
          <el-pagination :current-page="pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
        <!-- 导出 -->
        <el-table id="views" :data="tableData" border style="width: 100%">
          <el-table-column prop="workDay" label="日期" width="100" align="center">
            <template slot-scope="scope">
              <span>{{ changeDay(scope.row.workDay) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="reponseLevel" label="响应级别" width="100" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.reponseLevel">{{ scope.row.reponseLevel+'级' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="daliyLeader" label="带班领导" width="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.leaderConttact">{{ scope.row.daliyLeader+'（'+scope.row.leaderConttact+'）' }}</span>
              <span v-else>{{ scope.row.daliyLeader }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="dayStaff" label="白班值班人员" width="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.dayContact">{{ (scope.row.dayStaff || scope.row.dayName)+'（'+scope.row.dayContact+'）' }}</span>
              <span v-else>{{ scope.row.dayStaff || scope.row.dayName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="nightStaff" label="晚班值班人员" width="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.nightContact">{{ ( scope.row.nightStaff || scope.row.nightName )+'（'+scope.row.nightContact+'）' }}</span>
              <span v-else>{{ scope.row.nightStaff || scope.row.nightName }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <Basicform title="月度值班安排" :win-width="moduleInfo.winWidth" :win-height="moduleInfo.winHeight" :toolbar-btns="busiformBtns" :on-basic-form-before-open="onBasicFormBeforeOpen" :on-basic-form-opened="onBasicFormOpened" :on-basic-form-before-submit="onBasicFormBeforeSubmit" :on-basic-form-submited="onBasicFormSubmited">
      <template #default="slotProps">
        <busi-form :vo="slotProps.vo" :can-edit="slotProps.canEdit" :module-info="moduleInfo" />
      </template>
    </Basicform>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const moment = require('moment')
import qs from 'qs'
export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            searchDate: {
                year: [],
                dept: '111'
            },
            pageSize: 20,
            pageNum: 1,
            total: 0,
            moduleInfo: {
                winHeight: 600,
                winWidth: 1000
            },
            departmentList: [],
            tableData: []
        }
    },
    computed: {
        ...mapGetters(['routes', 'loginUser']),
        changeDay() {
            return (day) => {
                return moment(day).format('MM月D号')
            }
        }
    },
    watch: {

    },
    created() {
        this.$bus.$emit('showNavSearch', false)
    },
    beforeDestroy() {
        this.$bus.$emit('showNavSearch', true)
    },
    mounted() {
        this.getDepartmentList()
        this.getListInfo()
    },
    methods: {
        handleSizeChange(val) {
            this.pageSize = val
            this.getListInfo()
        },
        handleCurrentChange(val) {
            this.pageNum = val
            this.getListInfo()
        },
        // 编辑
        editRow(row) {
            console.log('row', row)
            if (this.searchDate.dept === '111') {
                this.moduleInfo.formTemplate = '#/dcontent/vuebusiform/W/xzhq.gwhScheduleDaliy.form.vue?sysCode='
                this.moduleInfo.getByIdUrl = 'xzhq/gwhScheduleDaliy/getById'

                this.moduleInfo.updateUrl = 'xzhq/gwhScheduleDaliy/update'
                this.moduleInfo.delUrl = 'xzhq/gwhScheduleDaliy/delete'
                this.moduleInfo.addNewUrl = 'xzhq/gwhScheduleDaliy/addNew'
                this.moduleInfo.insertUrl = 'xzhq/gwhScheduleDaliy/insert'
                this.moduleInfo.listDataUrl = 'xzhq/gwhScheduleDaliy/listdata'
            } else {
                this.moduleInfo.formTemplate = '#/dcontent/vuebusiform/W/xzhq.deptScheduleDaliy.form.vue?sysCode='
                this.moduleInfo.getByIdUrl = 'xzhq/deptScheduleDaliy/getById'

                this.moduleInfo.updateUrl = 'xzhq/deptScheduleDaliy/update'
                this.moduleInfo.delUrl = 'xzhq/deptScheduleDaliy/delete'
                this.moduleInfo.addNewUrl = 'xzhq/deptScheduleDaliy/addNew'
                this.moduleInfo.insertUrl = 'xzhq/deptScheduleDaliy/insert'
                this.moduleInfo.listDataUrl = 'xzhq/deptScheduleDaliy/listdata'
            }
            this.$options.components['BusiForm'] = this.$loadComponent(this.moduleInfo.formTemplate)
            this.$nextTick(() => {
                this.$bus.emit('openBusiform', this.moduleInfo, row, 1, (res) => {
                })
            })
        },
        // 查询
        getListInfo() {
            var obj = {}
            if (this.searchDate.dept === '111') {
                obj = {
                    gwhId: '9fc0b03490d54cb5b12ab0459227c155',
                    deptId: '',
                    startTime: this.searchDate.year[0],
                    endTime: this.searchDate.year[1],
                    orgCode: this.loginUser.orgCode,
                    pageSize: this.pageSize,
                    pageNum: this.pageNum
                }
                this.$http({
                    method: 'POST',
                    contentType: 'application/json charset=utf-8',
                    url: '/xzhq/deptScheduleDaliy/listdata',
                    data: qs.stringify(obj)
                }).then((res) => {
                    if (res.code === 'success') {
                        this.tableData = res.body.pageInfo.list
                        this.total = res.body.pageInfo.total
                    }
                })
            } else {
                obj = {
                    gwhId: '',
                    deptId: this.searchDate.dept,
                    startTime: this.searchDate.year[0],
                    endTime: this.searchDate.year[1],
                    pageSize: this.pageSize,
                    orgCode: this.loginUser.orgCode,
                    pageNum: this.pageNum
                }
                this.$http({
                    method: 'POST',
                    contentType: 'application/json charset=utf-8',
                    url: '/xzhq/deptScheduleDaliy/listdata',
                    data: qs.stringify(obj)
                }).then((res) => {
                    if (res.code === 'success') {
                        this.tableData = res.body.pageInfo.list
                        this.total = res.body.pageInfo.total
                    }
                })
            }
        },
        // 获取部门列表
        getDepartmentList() {
            this.searchDate.year = [moment().format('YYYY-MM'), moment().format('YYYY-MM')]
            this.$http({
                method: 'GET',
                contentType: 'application/json charset=utf-8',
                url: '/base/organMgr/findAllOrganByOrgCode?orgCode=' + this.loginUser.orgCode
            }).then((res) => {
                if (res.code === 'success') {
                    this.departmentList = []
                    res.body.listdata.map((item) => {
                        if (item.orgLevel === 2 || item.orgLevel === 3) {
                            this.departmentList.push({ label: item.name, value: item.id })
                        }
                    })
                    if (this.departmentList.length > 0) {
                        this.searchDate.dept = this.departmentList[0].value
                    } else {
                        this.searchDate.dept = '暂无数据'
                    }
                    console.log('this.departmentList', this.searchDate)
                }
            })
        },
        onBasicFormSubmited() {
            this.getListInfo()
        }
    }
}
</script>

<style scoped lang="scss">
.page-holiday {
  .el-table-toolbar {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .toolbar-right {
      /deep/ .el-button-group {
        .el-button:hover {
          background: #fff;
          border-color: #409eff;
          color: #409eff;
        }
      }
    }
  }
  .label {
    line-height: 36px;
    font-size: 14px;
    color: #606266;
  }
  /deep/.el-date-editor .el-range-separator {
    width: 20px;
    padding: unset;
  }
}
#views {
  position: fixed;
  top: 10000000px;
}
</style>
