<template>
  <div class="app-container" style="padding-top:20;">
    <div style="font-size: 14px;   z-index:11;  top: 10px;  display: flex; position: absolute;align-items: center;">
      <div class="dj">
        考勤月份：
        <el-date-picker
          v-model="value1"
          class="riqi"
          type="month"
          placeholder="选择月"
          format="yyyy年M月"
          value-format="yyyy-MM"
        />

      </div>
      <statistics-search ref="statistics" :search-type="searchType" @statisticsClick="statistics" />
    </div>
    <data-table
      v-if="showView==='listpage'"
      style="margin-top: 10px;"
      :module-info="moduleInfo"
      :tb-columns="tbColumns"
      :toolbar-btns="listpageBtns"
      :on-cell-click="editObject"
      :on-list-data-before-load="onListDataBeforeLoad"
      :on-list-data-loaded="onListDataLoaded"
      :on-delete-successed="onDeleteSuccessed"
    />
    <Basicform
      ref="basicform"
      :title="formTitle"
      :module-code="moduleInfo.moduleCode"
      :win-width="moduleInfo.winWidth"
      :win-height="moduleInfo.winHeight"
      :toolbar-btns="busiformBtns"
      :on-basic-form-before-open="onBasicFormBeforeOpen"
      :on-basic-form-opened="onBasicFormOpened"
      :on-basic-form-before-submit="onBasicFormBeforeSubmit"
      :on-basic-form-submited="onBasicFormSubmited"
    >
      <template #default="slotProps">
        <busi-form ref="busiform" :vo="slotProps.vo" :can-edit="slotProps.canEdit" :module-info="moduleInfo" />
      </template>
    </Basicform>
    <ImportForm />
  </div>
</template>

<script>
import StatisticsSearch from '@/wapp/IndustryVersionCode/kqgl/kqhz/common/StatisticsSearch'
import ImportForm from '@/wapp/IndustryVersionCode/kqgl/kqhz/common/ImportFormkqdj'
export default {
    components: {
        StatisticsSearch, ImportForm
    },
    data() {
        return {
            showView: 'listpage',
            selectDept: '',
            selectDeptLevel: '',
            value1: '',
            searchType: [
                {
                    type: 'subOrg',
                    title: '登记科室',
                    kqManager: false
                }
            ],
            loginUser: {},
            moduleInfo: { 'formTemplate': 'dcontent/busiformurl?vwtype=W&url=/yykq/recordResult/form.html', 'addNewUrl': 'yykq/recordResult/addNew', 'insertUrl': 'yykq/recordResult/insert', 'getByIdUrl': 'yykq/recordResult/getById', 'updateUrl': 'yykq/recordResult/update', 'delUrl': 'yykq/recordResult/delete', 'listDataUrl': 'yykq/recordResult/listdata?kqType=1', 'winWidth': 1000, 'winHeight': 600, 'moduleCode': 'recordResult', 'openViewType': '0', 'listAdvSearchAllFields': 0, 'listAdvSearchExpandPanel': 0 },
            tbColumns: [
                { 'field': 'state', 'checkbox': true, 'width': '45px', 'align': 'center', 'sortable': false },
                { 'align': 'center', 'field': 'index', 'title': '序号', 'width': '45px', 'sortable': false, 'listAdvSearchElement': '', 'searchable': false, 'propType': '' },
                { 'align': 'center', 'field': 'name', 'title': '姓名', 'width': '100px', 'sortable': false, 'listAdvSearchElement': 'input', 'searchable': true, 'listAdvSearchIndex': 0, 'propType': 'String', 'elementFormatter': '' },
                { 'align': 'left', 'field': 'department', 'title': '科室', 'width': '', 'sortable': false, 'listAdvSearchElement': 'input', 'searchable': true, 'listAdvSearchIndex': 1, 'propType': 'String', 'propFormatter': 'function：deptNameRenderFunc' },
                { 'align': 'center', 'field': 'clockInDate', 'title': '考勤日期 ', 'width': '150px', 'sortable': true, 'listAdvSearchElement': 'date', 'searchable': true, 'listAdvSearchIndex': 2, 'propType': 'String', 'elementFormatter': 'format：yyyy-MM-dd' },
                { 'align': 'center', 'field': 'timeName', 'title': '班次', 'width': '100px', 'sortable': false, 'listAdvSearchElement': 'input', 'searchable': true, 'listAdvSearchIndex': 1, 'propType': 'String', 'elementFormatter': '' },
                { 'align': 'center', 'field': 'timeSpan', 'title': '班次时段', 'width': '100px', 'sortable': false, 'listAdvSearchElement': 'input', 'searchable': true, 'listAdvSearchIndex': 1, 'propType': 'String', 'elementFormatter': '' },
                { 'align': 'center', 'field': 'workHours', 'title': '时长', 'width': '100px', 'sortable': false, 'listAdvSearchElement': 'input', 'searchable': true, 'listAdvSearchIndex': 1, 'propType': 'String', 'elementFormatter': '' },
                { 'align': 'center', 'field': 'kqInfo', 'title': '考勤登记情况', 'width': '200px', 'sortable': false, 'listAdvSearchElement': 'input', 'searchable': true, 'listAdvSearchIndex': 1, 'propType': 'String', 'elementFormatter': '' },
                { 'align': 'center', 'field': 'updatePerson', 'title': '操作人', 'width': '100px', 'sortable': false, 'listAdvSearchElement': 'input', 'searchable': true, 'listAdvSearchIndex': 1, 'propType': 'String', 'elementFormatter': '' },
                { 'align': 'center', 'field': 'updateTime', 'title': '操作时间 ', 'width': '150px', 'sortable': true, 'listAdvSearchElement': 'date', 'searchable': true, 'listAdvSearchIndex': 2, 'propType': 'String', 'elementFormatter': 'format：yyyy-MM-dd' }
            ],
            listpageBtns: [
                { 'id': 'btn_deleteIds', 'btnType': 'toolbar', 'btnName': '删除', 'btnIcon': 'close', 'btnPermission': 'deleteIds', 'btnClick': 'deleteIds();', 'btnI18nCode': 'delete' },
                { 'id': 'btn_deleteIds2', 'btnType': 'toolbar', 'btnName': '导入', 'btnIcon': 'import.svg', 'btnPermission': 'deleteIds', 'btnClick': this.importHandler, 'btnI18nCode': 'delete' },
                { 'id': 'btn_deleteIds3', 'btnType': 'toolbar', 'btnName': '导出', 'btnIcon': 'export.svg', 'btnPermission': 'deleteIds', 'btnClick': 'exportWform();', 'btnI18nCode': 'delete' },
                { 'id': 'btn_edit', 'btnType': 'column', 'btnName': '编辑', 'btnIcon': 'edit', 'btnPermission': 'editObject', 'btnClick': this.editObject, 'btnI18nCode': 'edit' },
                { 'id': 'btn_deleteId', 'btnType': 'column', 'btnName': '删除', 'btnIcon': 'delete', 'btnPermission': 'deleteIds', 'btnClick': 'deleteIds(row.id);', 'btnI18nCode': 'delete' }
            ],
            busiformBtns: [],
            formTitle: '考勤记录' }
    },
    computed: {
    },
    watch: {
        $route(a, b) {
            this.setShowView()
        }
    },
    created() {
    // 列表页面配置ID
        const date = new Date()
        this.value1 = this.$moment(date).format('YYYY-MM')
        if (this.$store.state.user.roles.indexOf('kqManager') !== -1) {
        // 考勤管理员
            this.searchType[0].kqManager = this.kqManager
        }
        this.moduleInfo.listpageId = 'bf3ac590ef51444caeb8baa27b7cf3a4'
        const _this = this
        this.listpageBtns.forEach(function(btn) {
            btn.btnParams = null
            if (typeof btn.btnClick === 'string') {
                let btnClick = btn.btnClick
                if (btn.btnClick.indexOf('(') !== -1) {
                    let btnParams = btnClick.substring(btnClick.indexOf('(') + 1, btnClick.lastIndexOf(')'))
                    if (btnParams !== '') {
                        btnParams = btnParams.replace(/'/g, '').replace(/ /g, '').split(',')
                        btn.btnParams = btnParams
                    }
                    btnClick = btnClick.split('(')[0]
                }
                if (_this[btnClick]) {
                    btn.btnClick = _this[btnClick]
                }
            }
        })
        // 操作列定义
        this.tbColumns[this.tbColumns.length] = {
            field: 'column_operate',
            title: '操作',
            width: '70px',
            align: 'center',
            valign: 'middle'
        }
        let formurl = this.moduleInfo.formTemplate.split('url=')[1]
        formurl = formurl.replace(/\//g, '.')
        formurl = '#/dcontent/vuebusiform/W/' + formurl.substring(1).replace('.html', '.vue')
        formurl += '?sysCode=' + (this.$route.meta.sysCode || '')
        this.$options.components['BusiForm'] = this.$loadComponent(formurl)
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true)
    },
    mounted() {
        this.$bus.emit('showNavSearch', false)
        this.setShowView()
    },
    methods: {
        deptNameRenderFunc(createElement, row, column, cellValue, index, $scope) {
            return createElement('span', row.deptL3Name || row.department)
        },
        editObject(row, count, index) {
            console.log(row, count, index, 'row')
            this.$emit('openDailong', row, row.clockInDate)
        },
        setShowView() {
            if (this.$route.fullPath.indexOf('itemurl=') !== -1) {
                this.showView = 'mainform'
            } else {
                this.showView = 'listpage'
            }
        },
        statistics(vals) {
            this.selectDept = vals.subOrg.id
            this.selectDeptLevel = vals.subOrg.orgLevel
            this.$bus.emit('refreshListData')
        },
        // 导入
        importHandler() {
            this.$bus.emit('openImportForm')
        },
        /**
    * 加载数据准备，qparams为查询参数对象，不需要返回值
    */
        onListDataBeforeLoad($scope, qparams) {
            this.loginUser = $scope.loginUser
            console.log(qparams, 'qparams')
            if (!qparams.orderBy) qparams.orderBy = 'clockInDate desc'
            qparams.urlsearch = `clockInDate like ${this.value1}`
            if (this.selectDeptLevel && this.selectDeptLevel > 2) {
                qparams.urlsearch += `;deptL3Id:${this.selectDept}`
            } else {
                qparams.urlsearch += `;departmentId:${this.selectDept}`
            }
            console.log('recordResult listdata before load..')
        },
        /**
    * 加载数据完成，pageInfo为包含查询结果的分页信息对象，不需要返回值
    */
        onListDataLoaded($scope, pageInfo) {
            console.log('recordResult listdata loaded')
        },
        /**
    * 数据删除成功，ids为已删除成功的recordResult对象ID数组，即将刷新列表，不需要返回值
    */
        onDeleteSuccessed($scope, ids) {
            console.log('recordResult delete successed')
        },
        /**
    * 表单打开检查，vo为已加载recordResult对象，允许打开：defer.resolve(true)，阻止打开：defer.reject(reason)
    */
        onBasicFormBeforeOpen($scope, vo, defer) {
            if (process.env.NODE_ENV === 'development') console.log('recordResult form before open')
            defer.resolve(true)
        },
        /**
     * 表单打开完成，vo为已加载的recordResult对象，前端页面dom对象已完成初始化、已经呈现，不需要返回值
    */
        onBasicFormOpened($scope, vo) {
            if (process.env.NODE_ENV === 'development') console.log('recordResult form opened')
        },
        /**
     * 表单提交事件，可执行业务数据高级校验，vo为待提交的recordResult对象，校验成功：defer.resolve(true)，校验失败：defer.reject(reason)
     */
        onBasicFormBeforeSubmit($scope, vo, defer) {
        /* this.$confirm('您是否确认提交保存?', '确认操作', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        })
            .then(() => {
                defer.resolve(true)
            })
            .catch(err => {
                defer.reject(err)
            })*/

            if (process.env.NODE_ENV === 'development') console.log('recordResult form submit validation success')
            defer.resolve(true)
        },
        /**
    * 表单提交完成，vo为已保存成功的recordResult对象，窗口即将关闭，不需要返回值
    */
        onBasicFormSubmited($scope, vo) {
            if (process.env.NODE_ENV === 'development') console.log('recordResult form submited')
        } }
}
</script>

<style scoped>
.app-container{
position: relative;
}
.dj{
    padding: 5px;
}
</style>
