<template>
  <div class="kqdk" style="background: #fff;">
    <BlockFrame :header="{title:'考勤',icon:'hyjh.svg',more:true,code:'kqglWdkq.svg'}">
      <div class="im-lists dk-box">
        <el-row>
          <!-- <el-col :span="14">
          <div class="dk-datetime">
            <div class="dk-dt-inner">
              <p>{{ tDate }}</p>
              <span style="padding-left: 10px">{{ week[day] }}</span>
              <p>{{ hms }}</p>
            </div>
          </div>
        </el-col> -->
          <el-col :span="24">
            <div class="dk-btn-wrap">
              <button class="dk-btn" :disabled="timeSpanData.dakaType == 0 || timeSpanData.officeSign === 2" @click.prevent="dkEvent()">
                <span>{{ dakaType[timeSpanData.dakaType] }}</span>
                <span>{{ hms }}</span>
              </button>
            </div>
          </el-col>
        </el-row>
        <div class="timespan"><span style="padding-right: 10px">考勤班次：</span><span>{{ timeSpanData.personBcTimeSpan }}</span></div>
        <div class="dk-status-list-outer">
          <el-scrollbar :native="false">
            <ul class="dk-status-list">
              <li v-for="(item, i) in timeSpanData.timeIntervalList" :key="i">
                <!-- <div :style="{'background-image': item.logonRecordTime ? 'url(\'@/wapp/yykq/index/images/icon_dk_success.png\')' : judgeOn(item) ? 'none' : 'url(@/wapp/yykq/index/images/icon_dk_error.png)'}"> -->
                <div>
                  <span>上班打卡</span>
                  <template v-if="!item.isNoBc">
                    <small>{{ '(' + item.logonTime + ')' }}</small>
                    <span v-if="item.logonRecordTime" class="dk-time" style="padding: 0 8px">{{ item.logonRecordTime }}</span>
                    <span v-if="item.logonRecordTime" class="dk-time">
                      <el-tag effect="plain" :type="tagType[item.logonRecordStatus - 1]" size="mini">{{ dkType[item.logonRecordStatus - 1] }}</el-tag>
                    </span>
                    <span v-else-if="!item.logonRecordTime && judgeOn(item)" class="dk-time">
                      <el-tag effect="plain" type="info" size="mini">暂无打卡</el-tag>
                    </span>
                    <img v-if="item.logonRecordTime" :src="'./images/w-imgs/yykq/icon_dk_success.png'" class="dk-status-img" alt="">
                    <img v-else-if="judgeOn(item)" :src="'./images/w-imgs/yykq/icon_dk_success_1.png'" class="dk-status-img" alt="">
                    <img v-else :src="'./images/w-imgs/yykq/icon_dk_warn.png'" class="dk-status-img" alt="">
                  </template>
                  <template v-else>
                    <small>暂无打卡班次</small>
                    <img :src="'./images/w-imgs/yykq/icon_dk_warn.png'" class="dk-status-img" alt="">
                  </template>
                </div>
                <div>
                  <span>下班打卡</span>
                  <template v-if="!item.isNoBc">
                    <small>{{ '(' + item.logoffTime + ')' }}</small>
                    <span v-if="item.logoffRecordTime" class="dk-time" style="padding: 0 8px">{{ item.logoffRecordTime }}</span>
                    <span v-if="item.logoffRecordTime" class="dk-time">
                      <el-tag effect="plain" :type="tagType[item.logoffRecordStatus - 1]" size="mini">{{ dkType[item.logoffRecordStatus - 1] }}</el-tag>
                    </span>
                    <span v-else-if="!item.logoffRecordTime && judgeOff(item)" class="dk-time">
                      <el-tag effect="plain" type="info" size="mini">暂无打卡</el-tag>
                    </span>
                    <img v-if="item.logoffRecordTime" :src="'./images/w-imgs/yykq/icon_dk_success.png'" class="dk-status-img" alt="">
                    <img v-else-if="judgeOff(item)" :src="'./images/w-imgs/yykq/icon_dk_success_1.png'" class="dk-status-img" alt="">
                    <img v-else :src="'./images/w-imgs/yykq/icon_dk_warn.png'" class="dk-status-img" alt="">
                  </template>
                  <template v-else>
                    <small>暂无打卡班次</small>
                    <img :src="'./images/w-imgs/yykq/icon_dk_warn.png'" class="dk-status-img" alt="">
                  </template>
                </div>
              </li>
            </ul>
          </el-scrollbar>
        </div>
      </div>
    </BlockFrame>
  </div>
</template>
<script>
const moment = require('moment')
import { mapGetters } from 'vuex'
import BlockFrame from '@/wapp/common/components/BlockFrame/index'
export default {
    name: '',
    components: {
        BlockFrame
    },
    props: {

    },
    data() {
        return {
            week: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
            dakaType: ['不可打卡', '上班打卡', '下班打卡', '更新打卡'],
            timeSpanData: {},
            homeDkInterval: null, // 获取打卡信息的定时器
            dkType: ['正常', '迟到', '早退', '缺卡', '加班'],
            tagType: ['', 'warning', 'warning', 'danger', 'success'],
            tDate: null, // 今天日期
            timestamp: null, // 当前时间戳
            hms: '-', // 页面显示的当前时间
            canDk: true
        }
    },
    computed: {
        ...mapGetters(['loginUser', 'routes'])
    },
    watch: {

    },
    created() {
        this.getDateTime()
        this.requestDkInfo()
        this.homeDkInterval = setInterval(this.timeAndDkData, 1000)
    },
    mounted() {
        // this.homeDkInterval = setInterval(freshData, gap)
    },
    beforeDestroy() {
        clearInterval(this.homeDkInterval)
    },
    methods: {
        // 获取打卡时段信息
        requestDkInfo() {
            const scope = this
            return new Promise((resolve, reject) => {
                this.$http({
                    method: 'POST',
                    url: 'yykq/timeInterval/getDakaTime'
                }).then((data) => {
                    if (data.code === 'success') {
                        resolve(data.body)
                        // console.log(JSON.parse(data.body.json))
                        scope.timeSpanData = JSON.parse(data.body.json)
                        scope.timeSpanData.dakaType = Number(scope.timeSpanData.dakaType)
                        if (!scope.timeSpanData.timeIntervalList || scope.timeSpanData.timeIntervalList.length === 0) {
                            const timeIntervalList = [{ isNoBc: true }]
                            scope.timeSpanData.timeIntervalList = timeIntervalList
                        }
                    } else {
                        reject(data.message)
                    }
                }).catch(err => {
                    // 异常处理
                    reject(err)
                })
            })
        },
        // 获取时间信息
        getDateTime() {
            const today = new Date()
            const y = today.getFullYear()
            const m = today.getMonth()
            const d = today.getDate()
            const h = today.getHours()
            const mins = today.getMinutes()
            // const sec = today.getSeconds()
            const nTimeH = h > 9 ? h : '0' + h
            const nTimeM = mins > 9 ? mins : '0' + mins
            this.day = today.getDay()
            this.tDate = y + '-' + (m + 1).toString().padStart(2, '0') + '-' + d.toString().padStart(2, '0')
            this.nTime = nTimeH + ':' + nTimeM
            this.timestamp = today.valueOf()
        },
        // 时间和数据
        timeAndDkData() {
            this.timestamp += 1000
            this.hms = moment(new Date(this.timestamp)).format('HH:mm:ss')
            // const t = this.timestamp + 8 * 60 * 60 * 1000 // 误差8小时
            // const h = parseInt((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            // const m = parseInt((t % (1000 * 60 * 60)) / (1000 * 60))
            // const s = Math.ceil((t % (1000 * 60)) / 1000) - 1
            // this.hms = (h < 12 ? '上午 ' + `${h}`.padStart(2, '0') : ('下午 ' + `${h - 12}`.padStart(2, '0'))) + ':' + `${m}`.padStart(2, '0') + ':' + `${s}`.padStart(2, '0')
        },
        // 打卡事件
        dkEvent() {
            const scope = this
            const u = this.loginUser
            const departmentId = u.staffList[0].orgFullId.split('-')
            const departmentName = u.staffList[0].orgFullName.split('-')
            this.departmentId = departmentId[departmentId.length - 1]
            this.departmentName = departmentName[departmentName.length - 1]
            if (this.canDk) {
                this.canDk = false
            } else {
                return
            }
            setTimeout(() => { this.canDk = true }, 5000)

            const now = parseInt((new Date()).getTime() / 1000)

            // eslint-disable-next-line no-unused-vars
            let onTime = null
            let offTime = null
            let logonTime = scope.timeSpanData.timeInterval.logonTime// 时段签到时间
            let logoffTime = scope.timeSpanData.timeInterval.logoffTime// 时段签退时间
            // const logonTimeStart = (parseInt(scope.timeSpanData.timeInterval.logonStart.split(':')[0]) * 60 * 60 * 1000) + (parseInt(scope.timeSpanData.timeInterval.logonStart.split(':')[1]) * 60 * 1000)// 签到开始时间
            const logonTimeEnd = (parseInt(scope.timeSpanData.timeInterval.logonEnd.split(':')[0]) * 60 * 60 * 1000) + (parseInt(scope.timeSpanData.timeInterval.logonEnd.split(':')[1]) * 60 * 1000) // 签到结束时间
            const nowTime = (new Date().getHours() * 60 * 60 * 1000) + (new Date().getMinutes() * 60 * 1000)

            if (logonTime) {
                var onNowTime = new Date()
                logonTime = logonTime.split(':')
                onTime = onNowTime.setHours(logonTime[0])
                onTime = onNowTime.setMinutes(logonTime[1])
                onTime = onNowTime.setSeconds(0)
                onTime = parseInt(onTime / 1000)
            }
            if (logoffTime) {
                const offNowTime = new Date()
                logoffTime = logoffTime.split(':')
                offTime = offNowTime.setHours(logoffTime[0])
                offTime = offNowTime.setMinutes(logoffTime[1])
                offTime = offNowTime.setSeconds(0)
                offTime = parseInt(offTime / 1000)
            }

            if ((scope.timeSpanData.dakaType === 2 || scope.timeSpanData.dakaType === 3) && now < offTime && nowTime > logonTimeEnd) {
                const diff = offTime - now
                // eslint-disable-next-line no-unused-vars
                const diffHour = parseInt(diff / 60 / 60 % 24)
                // eslint-disable-next-line no-unused-vars
                const diffMin = parseInt(diff / 60 % 60)
                return this.$confirm('还未到下班时间，您确定要打卡么？', '提示', { type: 'warning' }).then(() => {
                    this.dkRequest().then((data) => {
                        if (data.code === 'success') {
                            this.$nextTick(() => {
                                this.$bus.emit('refreshKqData')
                            })
                            this.requestDkInfo() // 刷新数据；
                            requestDk()
                        } else {
                            this.$message({ message: '打卡失败！', type: 'error' })
                        }
                    })
                }).catch(() => {})
            }
            this.dkRequest().then((data) => {
                if (data.code === 'success') {
                    this.$nextTick(() => {
                        this.$bus.emit('refreshKqData')
                    })
                    this.requestDkInfo() // 刷新数据；
                    requestDk()
                } else {
                    this.$message({ message: '打卡失败！', type: 'error' })
                    // this.$alert(data.message, '错误提示')
                }
            })
            function requestDk() {
                if (scope.timeSpanData.dakaType === 1) {
                    if (onTime < now) {
                        var diff = now - onTime
                        var diffHour = parseInt(diff / 60 / 60 % 24)
                        var diffMin = parseInt(diff / 60 % 60)
                        const dkinfo = '迟到了' + (diffHour >= 1 ? diffHour + '小时' : '') + diffMin + '分钟 '
                        scope.$alert(dkinfo, '打卡提示', { type: 'warning' })
                    } else {
                        scope.$message({ message: '上班打卡成功！', type: 'success' })
                    }
                } else if (scope.timeSpanData.dakaType === 2) {
                    scope.$message({ message: '下班打卡成功！', type: 'success' })
                } else if (scope.timeSpanData.dakaType === 3) {
                    scope.$message({ message: '更新打卡成功！', type: 'success' })
                }
            }
        },
        // 打卡请求
        dkRequest() {	// 打卡请求
            const u = this.loginUser
            return new Promise((resolve, reject) => {
                this.$http({
                    url: 'yykq/recordDetail/saveDakaDetail',
                    method: 'POST',
                    contentType: 'application/x-www-form-urlencoded',
                    data: {
                        'account': u.personAccount,
                        'departmentId': this.departmentId,
                        'departmentName': this.departmentName,
                        'name': u.personName,
                        'occureDate': this.tDate + ' ' + this.nTime,
                        'orgCode': u.orgCode,
                        'orgName': u.orgName,
                        'personSchId': this.timeSpanData.personSchId,
                        'timeId': this.timeSpanData.timeInterval.id
                    }
                }).then((data) => {
                    resolve(data)
                }).catch(err => {
                    // 异常处理
                    reject(err)
                })
            })
        },

        // 判断是否缺卡；
        judgeOn(data) {
            return this.compareTimes(data.logonEnd)
        },
        judgeOff(data) {
            return this.compareTimes(data.logoffEnd)
        },
        compareTimes(et) {
            const stre = et.split(':')
            if (stre.length !== 2) {
                return false
            }
            const b = new Date()
            const e = new Date()
            e.setHours(stre[0])
            e.setMinutes(stre[1])
            return e.getTime() - b.getTime() > 0
        }
    }
}
</script>

<style scoped lang="scss">
.kqdk {
    height: 100%;
    width: 100%;
    .im-lists {
        width: 100%;
        height: 256px;
        font-size: 14px;
        padding: 16px;
        p {
            margin: 0;
        }
        ul,li {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        .dk-btn-wrap, .dk-datetime {
            width: 100%;
            display: table;
            text-align: center;
            // padding: 12px 0;
            .dk-btn, .dk-dt-inner {
                // display: table-cell;
                vertical-align: middle;
                text-align: center;
            }
            .dk-btn {
                width: 100px;
                height: 100px;
                background-color: var(--bgColor);
                font-size: 13px;
                color: #FFFFFF;
                border-radius: 50%;
                cursor: pointer;
                border: none;
                outline: none;
                box-shadow: 2px 2px 8px 1px rgba(64, 158, 255, 0.3);
                &:active {opacity: 0.8;}
                &[disabled] {cursor: default;}
                &[disabled]:active {opacity: 1;}
                .nt {font-size: 18px;}
                span{line-height: 25px;}
            }
            .dk-dt-inner{
                p {
                    font-size: 14px;
                    color: var(--bgColor);
                    text-align: center;
                    line-height: 24px;
                    &:first-child {
                        margin-top: 12px
                    }
                }

            }
        }
        .timespan {
            text-align: center;
            font-size: 13px;
            color: #666;
            // line-height: 24px;
            // height: 48px;
            margin: 4px 0 8px;
        }
        .dk-status-list-outer {
            height:calc(100% - 96px - 60px);
            overflow: auto;
            /deep/ .el-scrollbar {
                height: 100%;
                width: 100%;
                .el-scrollbar__wrap {
                    overflow-x: auto;
                }
            }
            li > div small {
                color: #999;
            }
        }

    }
    .im-lists .iml { height: 116px; padding: 10px 20px; position: relative; }
    .im-lists .iml:after { display: block; content: ''; width: 100%; height: 0; border-bottom: 1px solid #EEEEEE; position: absolute; bottom: 0; left: 0; }
    .im-lists .iml:last-child:after { border: none; }
    .im-lists .iml:hover {background-color: #F8F8F8; cursor: pointer;}
    .dk-status-list li > div {
        padding-left: 26px;
        position: relative;
        .dk-status-img {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 5px;
            left: 0;
        }
    }
    .dk-status-list .dk-time .el-tag{
        border-radius: 9px;
        padding: 0 7px;
        line-height: 17px;
    }
    .dk-box {padding: 15px 20px; font-size:14px; line-height: 26px; overflow-y: auto;}

}
</style>
