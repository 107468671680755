<template>
  <div class="left-bar">
    <el-select v-model="yearObj.curYear" class="sel_year" size="small" @change="changeCalendarView">
      <el-option
        v-for="(item,index) in yearObj.years"
        :key="index"
        :value="item"
      />
    </el-select><span style="font-size:13px;"> &nbsp;年</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            yearObj: {},
            curDataId: ''
        }
    },
    computed: {
        ...mapGetters([
            'loginUser'
        ])
    },
    watch: {

    },
    created() {
        this.createYearObj()
        this.queryData(this.yearObj.curYear)
    },
    beforeDestroy() {

    },
    mounted() {
        this.$bus.emit('getCurYear', this.yearObj.curYear)
    },
    methods: {
        createYearObj() {
            const thisYear = new Date().getFullYear()
            const years = []
            for (let i = thisYear - 10; i <= thisYear + 10; i++) {
                years.push(i)
            }
            this.yearObj = { curYear: thisYear, years: years }
        },
        changeCalendarView() {
            this.$bus.emit('getCurYear', this.yearObj.curYear)

            this.queryData(this.yearObj.curYear)
        },
        // 请求用户的节假日信息
        queryData(yy) {
            yy = (yy === undefined || yy === '') ? new Date().getFullYear() : yy
            var params = [{ 'compare': '=', 'field': 'orgCode', 'merge': false, 'value': this.loginUser.orgCode }, { 'compare': '=', 'field': 'year', 'merge': false, 'value': yy }]
            this.$http({
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: params,
                url: 'holiday/days/querydata'
            }).then(response => {
                const listdata = response.body.listdata
                if (response.code === 'success') {
                    if (listdata.length > 0) {
                        this.curDataId = listdata[0].id
                        this.renderDay(listdata[0].day)
                        this.$bus.emit('getCurDataId', this.curDataId)
                    } else {
                        this.$bus.emit('getCurDataId', '')
                    }
                } else {
                    this.$message({
                        message: response.message,
                        type: 'warning'
                    })
                }
            }, err => {
                this.$message.error(err)
            })
            return
        },
        renderDay(day) {
            const arr = day === '' ? [] : day.split(',')
            let noWorkday
            for (let i = 0; i < arr.length; i++) {
                noWorkday = document.getElementById(arr[i])
                if (noWorkday !== null && noWorkday !== undefined) {
                    noWorkday.classList.add('active')
                    noWorkday.setAttribute('title', '设为工作日')
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .left-bar {
        float: left;
        .sel_year {
            width: 88px;
            font-size: 12px;
        }
    }
</style>
