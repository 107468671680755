<template>
  <div>
    <div
      style="
        padding: 10px;
        font-size: 14px;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: space-between;
      "
    >
      <div style="display: flex;align-items: center;">
        <div class="dj">
          登记日期：
          <el-date-picker
            v-model="value"
            class="riqi"
            type="month"
            placeholder="选择月"
            format="yyyy年M月"
            value-format="yyyy-M"
          />
        </div>
        <statistics-search
          :search-type="searchType"
          @statisticsClick="statistics"
        />
      </div>
      <div style="padding: 5px 15px; text-align: right">
        <el-button v-table-toxls="views" file-name="个人考勤登记表">
          <svg
            aria-hidden="true"
            class="svg-icon"
            style="height: 13px; width: 13px"
            v-on="$listeners"
          >
            <use :xlink:href="'#icon-export'" style="fill: #ec8423" /></svg>导出</el-button>
      </div>
    </div>

    <div class="table-box">
      <el-table
        id="domm views"
        ref="dommm"
        v-loading="loading"
        class="custom-table"
        style="width: 100%"
        border
        :data="dataList2"
      >
        <el-table-column label="序号" width="60" type="index" align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">
              {{ scope.row.index }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" width="120" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.staffName }}</div>
          </template>
        </el-table-column>
        <el-table-column label="科室" width="200" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.staffDept }}</div>
          </template>
        </el-table-column>

        <el-table-column label="迟到（次）" width="100" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.cdNum || (scope.row.show?0:'') }}</div>
          </template>
        </el-table-column>
        <el-table-column label="早退（次）" width="100" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.ztNum || (scope.row.show?0:'') }}</div>
          </template>
        </el-table-column>
        <el-table-column label="旷工（天）" width="100" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.kgNum || (scope.row.show?0:'') }}</div>
          </template>
        </el-table-column>
        <el-table-column label="进修（天）" width="100" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.jxNum || (scope.row.show?0:'') }}</div>
          </template>
        </el-table-column>
        <el-table-column label="公差（天）" width="100" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.gcNum || (scope.row.show?0:'') }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(option, optionkey) in dataList.top"
          :key="optionkey"
          align="center"
          header-align="center"
          :label="option + '（天）'"
          width="100"
        >
          <template slot-scope="scope">
            <div v-if="dataList2[scope.$index].passenger.list&&dataList2[scope.$index].passenger.list.length>0">
              {{ dataList2[scope.$index].passenger.list.find((item) => item.typeName === option)?.day || 0 }}
            </div>
            <div v-else>{{ '' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="出勤（天）" width="100" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.cqNum || (scope.row.show?0:'') }}</div>
          </template>
        </el-table-column>
        <el-table-column label="排班时长" width="150" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.passenger.pbHours }}</div>
          </template>
        </el-table-column>
        <el-table-column label="工作时长" width="150" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.passenger.workHours }}</div>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            <div>{{ scope.row.remark }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
// import qs from 'qs'
import StatisticsSearch from '@/wapp/IndustryVersionCode/kqgl/kqhz/common/StatisticsSearchKQTJ'
const moment = require('moment')
export default {
    components: {
        StatisticsSearch
    },
    props: {},
    data() {
        return {
            loading: true,
            dataList: [],
            value: '',
            time: '',
            selectDept: '',
            selectName: '',
            content: '',
            ksManager: false, // 科室管理员
            kqManager: false, //  考勤管理员
            searchType: [
                {
                    type: 'subOrg',
                    title: '登记科室'
                },
                {
                    type: 'orgStaff',
                    title: '姓名',
                    parentType: 'subOrg'
                }
            ],
            dataList2: []
        }
    },
    computed: {},
    watch: {
        value(val) {
            console.log(val)
            const time = val.split('-')
            const y = time[0]
            const m = time[1]
            this.getDays(y, m)
        },
        cqNum(val) {
            console.log(val)
        }
    },
    created() {
        if (this.$store.state.user.roles.indexOf('kqManager') !== -1) {
            // 考勤管理员
            this.kqManager = true
            this.searchType[0].kqManager = this.kqManager
        }
        if (this.$store.state.user.roles.indexOf('ksManager') !== -1) {
            // 科室管理员
            this.ksManager = true
        }
        this.$bus.emit('showNavSearch', false)
        const date = new Date()
        this.value = date.getFullYear() + '-' + (date.getMonth() + 1)
        const time = this.value.split('-')
        const y = time[0]
        const m = time[1]
        this.getDays(y, m)
        const orgIds = this.$store.getters.loginUser.staffList[0].orgFullId.split('-')
        this.selectDept = orgIds[orgIds.length - 1]
        // this.getdata()
        this.getshuju()
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true)
    },
    mounted() {
        // let isAdmin = false
        // this.$store.getters.roles.map((item) => {
        //     if (item === 'kqManager') {
        //         isAdmin = true
        //     }
        // })

        // if (!isAdmin) {
        //     setTimeout(() => {
        //         this.$nextTick(() => {
        //             const domArr = document.querySelectorAll('.search_out>div')
        //             console.log('domArr', domArr)
        //             domArr[0].style.display = 'none'
        //         })
        //     }, 0)
        // }
    },
    methods: {
        // todao1() {
        //     console.log(123)
        //     // 导出的表头
        //     var title = [
        //         { title: '序号', width: 60, align: 'center', colspan: 1, rowspan: 1 },
        //         { title: '名字', width: 80, align: 'center', colspan: 1, rowspan: 1 },
        //         { title: '科室', width: 80, align: 'center', colspan: 1, rowspan: 1 },
        //         {
        //             title: '迟到（次）',
        //             width: 100,
        //             align: 'center',
        //             colspan: 1,
        //             rowspan: 1
        //         },
        //         {
        //             title: '早退（次）',
        //             width: 100,
        //             align: 'center',
        //             colspan: 1,
        //             rowspan: 1
        //         },
        //         {
        //             title: '旷工（天）',
        //             width: 100,
        //             align: 'center',
        //             colspan: 1,
        //             rowspan: 1
        //         }
        //     ]
        //     for (let i = 0; i < this.dataList.top.length; i++) {
        //         var obj = { width: 100, align: 'center', colspan: 1, rowspan: 1 }
        //         obj.title = this.dataList.top[i] + '（天）'
        //         title.push(obj)
        //     }
        //     const title1 = [
        //         {
        //             title: '出勤（天）',
        //             width: 100,
        //             align: 'center',
        //             colspan: 1,
        //             rowspan: 1
        //         },
        //         { title: '备注', align: 'center', colspan: 1, rowspan: 1 }
        //     ]
        //     title = title.concat(title1)
        //     console.log(title, 'title')
        //     const tbtitle = []
        //     tbtitle.push(title)
        //     console.log(tbtitle, 'tbtitle')
        //     const tbdata = this.dataList.list
        //     console.log(tbdata, 'tbdata')
        //     // 数据处理

        //     //       this.dataList.list.forEach((list, listIndex) => {
        //     //           console.log(list, listIndex, ' this.dataList')

        //     // 	// 状态处理
        //     //           // 	if (list.assetStatus === '1') {
        //     //           // 		list.assetStatus = '闲置'
        //     //           // 	} else if (list.assetStatus === '2') {
        //     //           //  		list.assetStatus = '在用'
        //     //           //  	} else if (list.assetStatus === '3') {
        //     //           // 		list.assetStatus = '借用'
        //     //           //   	}
        //     // // var timefarmat = []
        //     // // 时间处理
        //     // // if (list.checkTime != null) {
        //     // // 	timefarmat = list.checkTime.split(' ')
        //     //           //   	list.checkTime = timefarmat[0]
        //     // // }
        //     // // 导出的值
        //     // // const row = [

        //     //           //   	{ value: list.assetName, align: 'center', colspan: 1, rowspan: 1 },
        //     //           //   	{ value: list.assetStatus, align: 'center', colspan: 1, rowspan: 1 },
        //     //           //   	{ value: list.checkTime, align: 'center', colspan: 1, rowspan: 1 }
        //     //           //           ]
        //     //           //           tbdata.push(row)
        //     //       })

        //     // 发起请求---固定写法
        //     this.$http({
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json;charset=UTF-8'
        //         },
        //         data: {
        //             rows: tbdata,
        //             titles: tbtitle
        //         },
        //         // data: qs.stringify({
        //         //     rows: JSON.stringify(tbdata),
        //         //     titles: JSON.stringify(tbtitle),
        //         //     fileName: '资产盘点一览表'
        //         // }),
        //         url: '/yykq/kqhz/grExportdata?httpPort=' + location.port
        //     }).then((res) => {
        //         if (res.code === 'success') {
        //             // 写法一
        //             // window.open(encodeURI('/apigw/' + res.body.xlsurl), '_blank')

        //             // 写法二（推荐）
        //             var href = '/apigw/' + res.body.xlsurl
        //             var tempLink = document.createElement('a') // 创建一个a标签
        //             tempLink.style.display = 'none'
        //             tempLink.href = encodeURI(href)
        //             tempLink.setAttribute('download', '我的请休假.xls') // 给a标签添加下载属性
        //             if (typeof tempLink.download === 'undefined') {
        //                 tempLink.setAttribute('target', '_blank')
        //             }
        //             document.body.appendChild(tempLink) // 将a标签添加到body当中
        //             console.log('tempLink==', tempLink)
        //             tempLink.click() // 启动下载
        //             document.body.removeChild(tempLink) // 下载完毕删除a标签
        //             href = ''
        //         } else {
        //             this.$alert(res.message, '操作提示', { type: 'error' })
        //         }
        //     })
        // },
        btnFn(scope) {
            console.log(scope, 'scope')
        },
        statistics(vals) {
            this.loading = true
            this.dateList = []
            console.log('valssss', vals)
            this.selectDept = vals.subOrg.id
            this.selectName = vals.orgStaff.personId
            console.log('this.selectDept', this.selectDept)
            this.$bus.emit('refreshListData')
            this.getshuju(this.time)
        },
        getshuju() {
            const data = {
                hzDate: moment(new Date(this.value)).format('YYYY-MM'),
                staffDeptId: this.selectDept,
                staffValue: this.selectName
            }
            this.$http({
                method: 'POST',
                url: '/yykq/kqhz/personStatistics',
                contentType: 'application/x-www-form-urlencoded',
                params: data
            }).then((res) => {
                if (res.code === 'success') {
                    console.log('res', res)
                    this.dataList = res.body
                    this.dataList2 = this.dataList.list
                    this.dataList2.forEach((item, index) => {
                        item.index = index + 1
                    })
                    this.loading = false
                }
                // this.dataList2.forEach((item, index) => {
                //     item.index = index + 1
                // })
            })
        },
        tosave() {
            const date = {
                date: this.dataList
            }
            this.$http({
                method: 'PUT',
                contentType: 'application/x-www-form-urlencoded',
                data: date,
                url: '/yykq/kqhz/save'
            }).then((res) => {
                if (res.code === 'success') {
                    this.datelist = res.body.listdata
                    this.$message.success('保存成功')
                    this.$bus.emit('refreshListData')
                }
            })
        },
        daoru() {
            this.$bus.emit('openImportFamilyForm')
        },
        daochu() {},
        getWeekends(year, month) {
            const weekends = []
            const date = new Date(year, month - 1, 1)
            while (date.getMonth() === month - 1) {
                if (date.getDay() === 0 || date.getDay() === 6) {
                    weekends.push(date.getDate())
                }
                date.setDate(date.getDate() + 1)
            }

            return weekends.join(',')
        },
        getDays(year, month) {
            const days = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
            if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
                days[2] = 29
            }

            console.log(days[month])
            this.time = days[month]
        }
    }
}
</script>

<style scoped lang="scss">
.dj {
  padding: 10px !important;
}
.right {
  margin-right: 10px;
  margin-bottom: 10px;
  float: right;
  display: flex;
  justify-content: flex-end;
}
// /deep/.el-table th.el-table__cell.is-leaf {
//   border: 1px solid #ebeef5 !important;
// }
.table-box {
  width: 100%;
  padding: 0 10px 10px 10px;
}
/deep/.el-table__empty-block {
  border: 1px solid #ebeef5 !important;
}
.el-table {
  overflow: hidden;
}
/deep/.el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: auto !important;
}
/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: rgba(248, 247, 247, 0.87) !important;
}

/deep/
  .el-table--scrollable-x
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
  margin: 0 2px !important;
  border-radius: 6px !important;
  background-color: rgba(199, 199, 199, 0.65) !important;
}

/deep/
  .el-table--scrollable-x
  .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: hsla(240, 1%, 35%, 0.418) !important;
}
</style>
