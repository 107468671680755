module.exports = {
    navbar: {
        dashboard: 'トップ',
        github: 'GitHub',
        logOut: 'ログアウト',
        profile: 'プロフィール',
        theme: 'テーマ変更',
        size: '画面サイズ'
    },
    login: {
        title: 'ユーザログイン',
        logIn: 'ログイン',
        mobileLogin: '手机 ログイン',
        username: 'ユーザ名',
        password: 'パスワード',
        any: 'password',
        thirdparty: '外部IDでログイン',
        thirdpartyTips: 'ローカル環境ではログインできません。実装が必要です。'
    },
    tagsView: {
        refresh: '更新',
        close: '閉じる',
        closeOthers: 'その他閉じる',
        closeAll: 'すべて閉じる'
    },
    settings: {
        title: 'システムテーマ',
        theme: 'テーマ色',
        tagsView: 'Tags-View 開く',
        fixedHeader: 'Fixed Header',
        showTopMenus: 'TopMenu 開く'
    }
}
