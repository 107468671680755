<template>
  <div class="detail">
    <template v-for="(img,key) in images">
      <el-image
        v-if="key<=4"
        :key="key"
        :src="img"
        :preview-src-list="images"
      />
    </template>
  </div>
</template>

<script>
export default {
    props: {
        column: { type: Object, default: null },
        row: { type: Object, default: null },
        index: { type: Number, default: null }
    },
    data() {
        return {

        }
    },
    computed: {
        images() {
            let imgs = this.row[this.column['field']]
            if (imgs !== '' && imgs !== null) {
                imgs = this.row[this.column['field']].split(',')
                for (let i = 0; i < imgs.length; i++) {
                    imgs[i] = process.env.VUE_APP_BASE_API + '/' + imgs[i]
                }
            }
            return imgs
        }
    }
}
</script>

<style scoped lang="scss">
  .detail{
    line-height: 0;
    /deep/ .el-image{
      &:not(:last-child){margin-right:5px ;}
      .el-image__inner{
        max-width: 55px;
        max-height: 23px;
      }
    }
  }
</style>
