<template>
  <div>
    <h3><svg-icon icon-class="moduleCodeMgr" /><span>最近应用</span></h3>
    <ul>
      <li v-for="(app,index) in visitLog" :key="index">
        <el-link :underline="false" :href="app.link" @click="closePop">
          <svg-icon :icon-class="app.code.split('.')[0]" />
          <span class="name">{{ app.name }}</span>
          <span class="time">{{ timeCompute(app.passenger.visitTime) }}</span>
        </el-link>
      </li>
    </ul>
  </div>
</template>

<script>
const moment = require('moment')
import { mapGetters } from 'vuex'

export default {
    components: {

    },
    props: {
        visitLog: { type: Array, default() { return [] } }
    },
    data() {
        return {
            appMenus: []
        }
    },
    computed: {
        ...mapGetters([
            'routes'
        ])
    },
    watch: {
        visitLog(nv, ol) {
            if (nv.length > 0) {
                this.visitLog.forEach(app => {
                    for (let i = 0; i < this.appMenus.length; i++) {
                        if (app.id === this.appMenus[i].meta.id) {
                            app.link = this.getLink(this.appMenus[i])
                            break
                        }
                    }
                })
            }
        }
    },
    created() {
        this.appMenus = this.routes.filter(item => {
            if (item.meta && item.meta.level === 2) return true
        })
    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
        closePop() {
            this.$bus.emit('closePop')
        },
        timeCompute(time) { // 处理时间显示方式
            var tdate = new Date(time)
            var ndata = new Date()
            var timeZone = ndata.getTime() - tdate.getTime()
            if (timeZone / 3600 / 1000 / 24 > 1) {
                return moment(tdate).format('YYYY-MM-DD HH:mm')
            } else {
                return moment(tdate).format('HH:mm')
            }
        },
        getLink(item) {
            let href = '#'
            const url = this.getUrl(item)
            href += url
            return href
        },
        getUrl(obj) {
            let u = obj.path

            if (obj.children && obj.children.length > 0) {
                if (obj.children[0].path.indexOf('lm') > -1 || obj.children[0].path.indexOf('tm') > -1) {
                    u = '' + this.getUrl(obj.children[0])
                } else {
                    u += '/' + this.getUrl(obj.children[0])
                }
            }
            return u
        }
    }
}
</script>

<style scoped lang="scss">
    h3{
        border-bottom: 1px solid #d8dce5;padding:28px 20px;margin-top:10px;color:#606266;

        svg{font-size:22px;}
        span{margin-left:20px;font-size:16px;}
    }
    ul{
        line-height:50px;position: relative;list-style:none;padding-left:0;

        li{padding-left:20px;}
        a{display:block;font-size:14px!important;}
        svg{font-size:20px;}
        .name{margin-left:20px;}
        .time{position: absolute;right:20px;}
    }
</style>
