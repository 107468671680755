// 导入单个组件
// components
import zbglCalendarPopover from './src/components/calendarPopover'
import zbglZbdjForm from './src/components/zbdjForm'

// dhpb 电话排班
import zbglDhpbIndex from './src/dhpb/index'
import zbglDhpbImportDutyMobileForm from './src/dhpb/importDutyMobileForm'

//import
import zbglImportImportForm from './src/import/importForm'

// jbjl 接报记录
import zbglJbjlInnerForm from './src/jbjl/innerForm'

// setting 值班设置
import zbglSettingIndex from './src/setting/index'

// yjpb 应急排班
import zbglYjpbImportDriverForm from './src/yjpb/importDriverForm'
import zbglYjpbImportDutyForm from './src/yjpb/importDutyForm'

// zbkb 值班看板
import zbglZbkbViewSchedul from './src/zbkb/viewSchedul'
// 以数组的结构保存组件，便于遍历
const components = {
    zbglCalendarPopover,
    zbglZbdjForm,
    zbglDhpbIndex,
    zbglDhpbImportDutyMobileForm,
    zbglImportImportForm,
    zbglJbjlInnerForm,
    zbglSettingIndex,
    zbglYjpbImportDriverForm,
    zbglYjpbImportDutyForm,
    zbglZbkbViewSchedul
}
// 定义 install 方法
const install = function(Vue) {
    if (install.installed) return
    install.installed = true
    // 遍历并注册全局组件
    Object.keys(components).forEach(key => {
        Vue.component(key, components[key])
    })
}

if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue)
}

export default {
    // 导出的对象必须具备一个 install 方法
    install,
    // 组件列表
    ...components
}
