export const hourArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
export const gpsScopeList = [
    {
        value: 50,
        txt: '50米'
    }, {
        value: 100,
        txt: '100米'
    }, {
        value: 200,
        txt: '200米'
    }, {
        value: 300,
        txt: '300米'
    }, {
        value: 400,
        txt: '400米'
    }, {
        value: 500,
        txt: '500米'
    }, {
        value: 1000,
        txt: '1000米'
    }
]

export const monthArr = [{
    cycleTime: '1',
    cycleTimeShow: '1日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '2',
    cycleTimeShow: '2日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '3',
    cycleTimeShow: '3日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '4',
    cycleTimeShow: '4日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '5',
    cycleTimeShow: '5日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '6',
    cycleTimeShow: '6日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '7',
    cycleTimeShow: '7日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '8',
    cycleTimeShow: '8日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '9',
    cycleTimeShow: '9日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '10',
    cycleTimeShow: '10日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '11',
    cycleTimeShow: '11日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '12',
    cycleTimeShow: '12日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '13',
    cycleTimeShow: '13日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '14',
    cycleTimeShow: '14日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '15',
    cycleTimeShow: '15日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '16',
    cycleTimeShow: '16日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '17',
    cycleTimeShow: '17日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '18',
    cycleTimeShow: '18日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '19',
    cycleTimeShow: '19日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '20',
    cycleTimeShow: '20日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '21',
    cycleTimeShow: '21日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '22',
    cycleTimeShow: '22日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '23',
    cycleTimeShow: '23日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '24',
    cycleTimeShow: '24日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '25',
    cycleTimeShow: '25日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '26',
    cycleTimeShow: '26日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '27',
    cycleTimeShow: '27日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '28',
    cycleTimeShow: '28日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '29',
    cycleTimeShow: '29日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '30',
    cycleTimeShow: '30日',
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '31',
    cycleTimeShow: '31日',
    timeSpanIds: [],
    usable: false
}
]
export const week = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日']
export const weeks = [{
    cycleTime: '1',
    cycleTimeShow: '星期一',
    orderNo: 1,
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '2',
    cycleTimeShow: '星期二',
    orderNo: 2,
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '3',
    cycleTimeShow: '星期三',
    orderNo: 3,
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '4',
    cycleTimeShow: '星期四',
    orderNo: 4,
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '5',
    cycleTimeShow: '星期五',
    orderNo: 5,
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '6',
    cycleTimeShow: '星期六',
    orderNo: 6,
    timeSpanIds: [],
    usable: false
},
{
    cycleTime: '7',
    cycleTimeShow: '星期日',
    orderNo: 7,
    timeSpanIds: [],
    usable: false
}
]
