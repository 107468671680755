<template>
    <ul v-if="listdata.length > 0" v-loading="loading" class="tb-box">
        <li class="tb-head block-table-heder">
            <div class="li-td td-col-2">{{ titMsg }}</div>
            <div class="li-td td-col-1">{{ args.name }}</div>
            <div class="li-td td-col-3">
                <span class="title-text" @click="openMore()">
                    更多
                    <i class="el-icon-d-arrow-right" />
                </span>
            </div>
        </li>
        <template v-for="li in listdata">
            <li :key="li.id" class="tb-body" @click="handleRowClick(li)">
                <div class="li-td td-col-2">{{ li[args.fields[1]] }}</div>
                <div class="li-td td-col-1">{{ li[args.fields[0]] }}</div>
                <div class="li-td td-col-3">
                    <template>
                        <span v-if="args.id == 9 && li[args.fields[2]]">{{ li[args.fields[2]] + '次' }}</span>
                        <span v-else>{{ li[args.fields[2]] }}</span>
                    </template>
                </div>
            </li>
        </template>
    </ul>
</template>
<script>
import { deepClone } from '@/global/utils'
export default {
    name: 'RankTable',
    props: {
        args: {
            type: Object,
            default() {
                return { id: '', name: '', api: '' }
            }
        }
    },
    data() {
        return {
            loading: true,
            curUser: this.$store.getters.loginUser,
            list: [],
            listdata: [],
            titMsg: '',
            total: '',
            chooseDate: []
        }
    },
    computed: {},
    watch: {},
    created() {
        this.init()
        this.$bus.on('refreshrank', this.init)
    },
    beforeDestroy() {
        this.$bus.off('refreshrank', this.init)
    },
    methods: {
        init() {
            this.chooseDate = this.$vnode.context.chooseDate
            this.loading = true
            this.loadData().then((res) => {
                if (!res.code) {
                    this.loading = false
                    this.len = res.length
                    if (res.length < 5 && res.length > 0) {
                        this.listdata = deepClone(res)
                        for (let i = this.len; i < 5; i++) {
                            this.listdata.push({})
                        }
                    } else {
                        this.listdata = res.slice(0, 5)
                    }
                    this.computedTit(res.length)
                } else {
                    this.listdata = [{}, {}, {}, {}, {}]
                    this.computedTit(0)
                    this.loading = false
                }
            })
        },
        loadData() {
            const param = {
                pageNum: 1,
                pageSize: 5,
                startTime: this.chooseDate ? this.chooseDate[0] : '',
                endTime: this.chooseDate ? this.chooseDate[1] : '',
                orgCode: this.curUser.orgCode
            }
            return new Promise((resolve, reject) => {
                this.$http({
                    method: 'POST',
                    contentType: 'application/x-www-form-urlencoded',
                    data: $.param(param),
                    url: this.args.api
                })
                    .then((response) => {
                        if (response.code === 'success') {
                            this.list = response.body.pageInfo.list
                            this.total = response.body.pageInfo.total
                            resolve(this.list)
                        } else {
                            resolve(response)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        computedTit(len) {
            this.titMsg = this.args.titMsg.replace('$num', this.total ? this.total.toString() : '0')
        },
        openMore() {
            console.log(105, this.list)
            const title = this.chooseDate[0] + ' 至 ' + this.chooseDate[1] + ' ' + this.args.name + ' 共' + this.total + '条记录'
            this.$bus.emit('rankDetailDialogOpen', this.list, this.args, title, this.chooseDate)
        },
        handleRowClick(row) {
            if (Object.keys(row).length === 0) return
            this.$bus.emit('rankPersonageDialogOpen', row, this.args.id, this.args, this.chooseDate)
        }
    }
}
</script>
<style lang="scss" scoped>
@import '~@/wapp/common/styles/variables.module.scss';

ul,
li {
    list-style: none;
    padding: 0;
    margin: 0;
}
$lw: 92px;
$rw: 118px;
.tb-box {
    width: 100%;
    height: auto;
    border: 1px solid #dcdfe6;
    border-radius: 3px;
    li {
        width: 100%;
        height: 44px;
        line-height: 32px;
        border-bottom: 1px solid #dcdfe6;
        overflow: hidden;
        font-size: 13px;
        color: #909399;
        &.tb-head {
            font-size: 14px;
            font-weight: 600;
        }
        &.tb-body {
            cursor: pointer;
            color: #606266;
            &:last-child {
                border-bottom: unset;
            }
        }

        .li-td {
            padding: 6px 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .td-col-2 {
            width: 100%;
            padding-left: $lw;
            padding-right: $rw;
            float: left;
        }
        .td-col-1 {
            width: $lw;
            float: left;
            margin-left: -98%;
        }
        .td-col-3 {
            width: $lw;
            float: left;
            margin-left: -$lw;
            text-align: right;
        }
    }
    .title-text {
        cursor: pointer;
        &:hover {
            color: $light-blue;
        }
    }
}
</style>
