<template>
  <div id="todo-body" ref="todoElement" style="background: #fff;">
    <BlockFrame :header="{title:[{name:'我的申请',click:getData,type:'myCreate',key:0},{name:'我的考勤',more:true,click:getData,type:'',key:1,code:'kqglWdkq.svg'}],icon:'mytask.svg'}">
      <div class="hideline">
        <el-table
          v-if="typeKey === 0&&todolist.length>0"
          key="myCreate"
          class="tablelist"
          :data="todolist"
          :show-header="true"
          :border="true"
          style="width: 100%"
          @row-click="openWorkFlow"
        >
          <el-table-column type="index" label="序号" width="45" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.title? scope.$index+ 1:'' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="title"
            label="文件标题"
            align="left"
            min-width="100"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            prop="todoNodeNames"
            label="处理环节"
            align="center"
            width="120"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            prop="createTime"
            :label="'申请时间'"
            width="120"
            align="center"
            :formatter="columnFormatter"
            :show-overflow-tooltip="true"
          />
        </el-table>
        <div v-if="typeKey === 0&&todolist.length===0" style="text-align:center;margin: 5% auto;">
          <img src="images/w-imgs/nomessage.png" style="max-width:180px"><br>
          <span style="font-size:15px;color:#dbdbdb">暂无数据</span>
        </div>

        <el-table
          v-if="typeKey === 1&&todolist.length>0"
          key="kqglWdkq"
          class="tablelist"
          :data="todolist"
          :show-header="true"
          :border="true"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="序号"
            width="45"
            align="center"
          />
          <el-table-column
            label="打卡信息"
            align="left"
            min-width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span class="wflin ddtype">签到</span>
              <span :class="{'iprimary': scope.row.signOnStatus == 1, 'ierror': scope.row.signOnStatus == 2 || scope.row.signOnStatus == 3, 'iwarn': scope.row.signOnStatus == 4}">
                <span v-if="scope.row.signOnTime" class="wflin dktime">{{ scope.row.signOnTime }}</span>
                <span class="wflin dkstatus">{{ statusArr[scope.row.signOnStatus - 1] }}</span>
              </span>
              <span>
                <span class="wflin">|</span>
                <span class="wflin ddtype">签退</span>
                <span :class="{'iprimary': scope.row.signOffStatus == 1, 'ierror': scope.row.signOffStatus == 2 || scope.row.signOffStatus == 3, 'iwarn': scope.row.signOffStatus == 4}">
                  <span v-if="scope.row.signOffTime" class="wflin dktime">{{ scope.row.signOffTime }}</span>
                  <span class="wflin dkstatus">{{ statusArr[scope.row.signOffStatus - 1] }}</span>
                  <span v-if="!scope.row.signOffStatus && !scope.row.signOffTime">未打卡</span>
                </span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="clockInDate"
            label="考勤日期"
            align="center"
            width="120"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="操作"
            align="center"
            width="120"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.signOnStatus == 4||scope.row.signOnStatus == 2||scope.row.signOffStatus == 3||scope.row.signOffStatus == 4">
                <resign-apply :attr="scope.row" />
              </span>
            </template>
          </el-table-column>

        </el-table>
        <div v-if="typeKey === 1&&todolist.length===0" style="text-align:center;margin: 5% auto;">
          <img src="images/w-imgs/nomessage.png" style="max-width:180px"><br>
          <span style="font-size:15px;color:#dbdbdb">暂无数据</span>
        </div>
      </div>
    </BlockFrame>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ResignApply from './ResignApply.vue'
import BlockFrame from '@/wapp/common/components/BlockFrame/index'
export default {
    name: 'ItemTask',
    components: {
        ResignApply, BlockFrame
    },
    props: {
        urlsearch: { type: String, default: function() { return '' } },
        wfCodes: { type: String, default: function() { return 'vacate,outsideWork,travel,overtimeWork,resign' } }// 我创建的单独传筛选值
    },
    data() {
        return {
            todolist: [],
            statusArr: ['正常', '迟到', '早退', '缺卡', '出差', '其他请假', '加班', '事假', '病假'], // 状态；
            typeKey: 0
        }
    },
    computed: {
        ...mapGetters([
            'routes',
            'loginUser'
        ])
    },
    created() {
    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
        // 查询待办待阅事项
        getData(obj) {
            if (obj.key === 0) {
                this.requestCreate()
            } else {
                this.requestMineKq()
            }
            this.typeKey = obj.key
            this.$forceUpdate()
        },
        // 我的申请 请求
        requestCreate() {
            return new Promise(async(resolve, reject) => {
                const data = await this.$app.listdata('/workflow/wfTaskItemDone/listdata', { listpageViewUrl: 'workflow.wfTaskItem.myCreated.vue', pageNum: 1, pageSize: 5, order: 'asc', urlsearch: 'module:vacate,outsideWork,travel,overtimeWork,resign' })
                if (data.code === 'success') {
                    const list = data.body.pageInfo.list
                    const length = list.length
                    if (length < 5 && length > 0) {
                        for (let i = 0; i < 5 - length; i++) {
                            list.push({})
                        }
                    }
                    this.todolist = list
                    resolve(data.body.pageInfo.list)
                } else {
                    reject(data.message)
                }
            })
        },
        // 个人考勤
        requestMineKq() {
            const wfparams = {
                pageNum: 1,
                pageSize: 5,
                orderBy: 'clockInDate desc',
                urlsearch: 'account:' + this.loginUser.personAccount + ';orgCode:' + this.loginUser.orgCode
            }
            return new Promise((resolve, reject) => {
                this.$http({
                    method: 'POST',
                    url: 'yykq/recordResult/listdata',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: $.param(wfparams)
                }).then(data => {
                    if (data.code === 'success') {
                        const list = data.body.pageInfo.list
                        const length = list.length
                        if (length < 5 && length > 0) {
                            for (let i = 0; i < 5 - length; i++) {
                                list.push({})
                            }
                        }
                        this.todolist = list
                        resolve(data.body.pageInfo.list)
                    } else {
                        reject(data.message)
                    }
                }).catch(err => {
                // 异常处理
                    reject(err)
                })
            })
        },

        columnFormatter(row, column, cellValue, index) {
            if (cellValue) {
                return cellValue.substr(0, 16)
            } else {
                return
            }
        },
        openWorkFlow(row, column, cell, event) {
            if (row.itemUrl && row.itemUrl.indexOf('/wflow') !== -1 && row.module) {
                window.open('/#' + row.itemUrl)
            }
        }
    }
}
</script>

<style scoped lang="scss">
    #todo-body{
        width:100%;
        height:100%;
        // border:1px solid #EBEEF5;
        border-radius:3px;
        .todo-title{
            border:1px solid #EBEEF5;
            border-bottom:0;
            height:38px;
            border-radius:3px 3px 0 0;
            position: relative;
            .active{
                color: #409EFF;
                border-bottom: 2px solid #409EFF;
            }
            .todo-title-t{
                font-size: 14px;
                line-height: 38px;
                padding: 8px 12px;
                cursor: pointer;
            }
            .more{
                font-size: 14px;
                position:absolute;
                right:0;
                line-height:21px;
                padding: 8px 12px;
                cursor: pointer;
            }
            .more:hover{
                color: #409EFF;
            }
        }
        /deep/ .tablelist{
            border-radius:0 0 3px 3px;
            .el-table__body{cursor: pointer;}
        }
        .wflin { padding-right: 12px;}
        .ierror {color: #F5222D;}
        .iprimary {color: #1890FF;}
        .iwarn {color: #FAAD14;}
        // /deep/.hideline .el-table__row>td {
        //   border:1px solid #EBEEF5 !important
        // }
        /deep/ .el-table .el-table__header-wrapper .el-table__header thead th{
            background-color: #fff;
}
    }

</style>
