<template>
  <el-dropdown trigger="click" class="international" @command="handleSetLanguage">
    <div class="box">
      <svg-icon class-name="international-icon" icon-class="language" :style="{'color':styleType?'white':''}" :class="styleType?'languageA':'language'" style="cursor: pointer;margin-right:5px" />
      <el-link v-if="!styleType" :class="styleType?'noMo':'Mo'" target="_blank">语言切换</el-link>
      <span v-if="styleType" class="Mo">
        语言切换
      </span>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item :disabled="language==='zhCN'" command="zhCN">
        中文
      </el-dropdown-item>
      <el-dropdown-item :disabled="language==='en'" command="en">
        English
      </el-dropdown-item>
      <el-dropdown-item :disabled="language==='es'" command="es">
        Español
      </el-dropdown-item>
      <el-dropdown-item :disabled="language==='ja'" command="ja">
        日本語
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
    props: {
        styleType: {
            type: String,
            default: ''
        }
    },
    computed: {
        language() {
            return this.$store.getters.language
        }
    },
    mounted() {
        this.loadLocalize(this.$i18n.locale)
    },
    methods: {
        handleSetLanguage(lang) {
            this.$i18n.locale = lang
            this.$store.dispatch('app/setLanguage', lang)
            location.reload()
            this.$message({
                message: 'Switch Language Success',
                type: 'success'
            })
        }
    }
}
</script>
<style scoped>
.box:hover .language{
    color: #419EFF;
}
.box:hover .languageA{
    color: white;
}
.Mo{
  color: white;
  font-size: 13px;
  cursor: pointer;
  display: inline-block;
}
.Mo:hover{
  border-bottom:1px solid white;
}
.el-link.el-link--default:hover{
  color: #419EFF !important;
}
</style>
