<template>
  <BusiformDesigner />
</template>

<script>
export default {
    data() {
        return {

        }
    },
    created() {
        window.openInterface = true // 是否启用伪数据
    }
}
</script>
