<template>
  <div class="findPassword">
    <header>
      <a class="title">
        <h3>
          <img :src="logoUrl">
          {{ title }}
          <span>找回密码</span>
        </h3>
      </a>
    </header>
    <div class="main-content">
      <div v-if="step === 0" class="center">
        <!-- <el-row>
          <el-col :span="5">单位/机构</el-col>
          <el-col :span="19">
            <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="单位编码/机构代码" rules="required">
              <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                <el-input ref="orgcode" v-model="data.orgcode" placeholder="单位编码/机构代码" @keyup.enter.native="nextFocus('account')" />
              </el-tooltip>
            </ValidationProvider>
          </el-col>
        </el-row> -->
        <el-row>
          <!-- <el-col :span="5">身份确认</el-col> -->
          <el-col style="text-align:center;font-size:14px;">为确认是您本人操作，请选择以下任意方式完成身份确认</el-col>
        </el-row>
        <el-row><el-radio v-model="way" label="sms" border @keyup.enter.native="nextFocus('account')">手机短信验证</el-radio></el-row>
        <el-row><el-radio v-model="way" label="mail" border @keyup.enter.native="nextFocus('account')">电子邮箱验证</el-radio></el-row>
        <el-row>
          <!-- <el-col :span="5">用户账号</el-col>
          <el-col :span="19"> -->
          <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="手机号码/电子邮箱" rules="required|phone_email">
            <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
              <el-input ref="account" v-model="data.account" placeholder="请输入已注册手机号码/电子邮箱" @keyup.enter.native="nextFocus('btnSubmit')" />
            </el-tooltip>
          </ValidationProvider>
          <!-- </el-col> -->
        </el-row>
        <el-row>
          <p class="error">{{ error }}</p>
          <el-button id="subbtn" ref="btnSubmit" type="primary" @click="nextStep()" @keyup.enter.native="nextStep()">下一步</el-button>
        </el-row>
      </div>
      <div v-else class="section">
        <el-steps :active="step" align-center>
          <el-step title="身份确认" icon="el-icon-user-solid" />
          <el-step title="设置密码" icon="el-icon-edit" />
          <el-step title="完成" icon="el-icon-circle-check" />
        </el-steps>
        <!-- <div class="step-head">
                    <div class="step">
                        <span class="step-num" :class="{cur: step >= 1}">1</span>
                        <span class="step-inline"></span>
                        <span class="step-txt">身份确认</span>
                    </div>
                    <div class="step-line"></div>
                    <div class="step">
                        <span class="step-num" :class="{cur: step >= 2}">2</span>
                        <span class="step-inline"></span>
                        <span class="step-txt">设置操作</span>
                    </div>
                    <div class="step-line"></div>
                    <div class="step">
                        <span class="step-num" :class="{cur: step >= 3}">3</span>
                        <span class="step-inline"></span>
                        <span class="step-txt">完成&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </div>
                </div> -->
        <!-- <div v-if="step === 1">
          <div class="step-main phone">
            <div class="step-main-inner">
              <el-button id="subbtn" ref="btnSubmit" type="primary" style="margin-top:30px;" @click="nextStep()" @keyup.enter.native="nextStep()">下一步</el-button>
            </div>
          </div>
        </div> -->
        <div v-if="step === 1" class="step-main set-pwd">
          <div class="step-main-inner">
            <p style="overflow: hidden;line-height: 16px;">
              <span>验证码已发送至 <span class="phone-num">{{ data.reveiver }}</span></span>
              <el-link type="primary" style="float:right;" @click="getVerifyCode()">重新发送验证码</el-link>
            </p>
            <el-row>
              <el-col :span="6" style="text-align:right">验证码：</el-col>
              <el-col :span="18">
                <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="验证码" rules="required">
                  <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                    <el-input ref="captcha" v-model="data.captcha" placeholder="请输入验证码" @keyup.enter.native="nextFocus('btnSubmit')" />
                  </el-tooltip>
                </ValidationProvider>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" style="text-align:right">新密码：</el-col>
              <el-col :span="18">
                <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="新密码" rules="required">
                  <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                    <el-input ref="password" v-model="data.password" type="password" class="ipt" placeholder="请设置新密码（6-12位字母和数字组合)" @keyup.enter.native="nextFocus('password1')" />
                  </el-tooltip>
                </ValidationProvider>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" style="text-align:right">确认密码：</el-col>
              <el-col :span="18">
                <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="确认密码" rules="required">
                  <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                    <el-input ref="password1" v-model="data.password1" type="password" class="ipt" placeholder="请确认新密码（6-12位字母和数字组合)" @keyup.enter.native="nextFocus('btnSubmit')" />
                  </el-tooltip>
                </ValidationProvider>
              </el-col>
            </el-row>
            <el-row>
              <p class="error">{{ error }}</p>
              <el-button id="subbtn" ref="btnSubmit" type="primary" @click="nextStep()" @keyup.enter.native="nextStep()">提 交</el-button>
            </el-row>
          </div>
        </div>
        <div v-else-if="step === 2" class="step-main set-success">
          <div class="step-main-inner" style="text-align:center;">
            <img :src="successUrl" class="success-img">
            <p style="font-size: 18px;font-weight: 600;">重置密码成功！</p>
            <el-button id="subbtn" ref="btnSubmit" type="primary" @click="navigateToLogin()" @keyup.enter.native="navigateToLogin()">重新登录</el-button>
          </div>
        </div>
      </div>
    </div>
    <p class="copyright">Copyright&copy;{{ loginUser?loginUser.orgName:'' }}. All Rights Reserved</p>
  </div>
</template>

<script>

import { title } from '@/wapp/settings'

export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            logoUrl: '/images/logo.png',
            successUrl: '/images/w-imgs/success.png',
            loginUser: this.$store.getters.loginUser,
            title: title,
            way: 'sms', // 验证方式  sms:短信验证，mail:邮箱验证
            step: 0, // 当前进行的步骤
            data: {
                // orgcode: '',
                account: '', // 账号/邮箱/手机号
                captcha: '',
                password: '',
                password1: '',
                reveiver: '' // 返回的手机号或邮箱
            },
            verification: {},
            isSend: true, // 定时控制
            iNow: 0, // 记数器，5秒内不得重复发送
            error: ''

        }
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {
        this.$refs.account.focus()
    },
    methods: {
        nextStep() { // 下一步
            this.error = ''
            this.formValidate(() => {
                const _s = this.step
                if (_s === 0) {
                    this.getVerifyCode(() => {
                        this.step = 1
                        setTimeout(() => { this.$refs.captcha.focus() }, 1000)
                    })
                }
                if (_s === 1) {
                    this.modification(() => {
                        setTimeout(() => {
                            this.step = 2
                        }, 1000)
                    })
                }
            })
        },
        getVerifyCode(fn) { // 发送用户信息 获取验证码
            const timer = setInterval(() => {
                this.iNow++
                if (this.iNow >= 5) {
                    this.isSend = true
                    this.iNow = 0
                    clearInterval(timer)
                }
            }, 1000)
            if (this.isSend) {
                this.$http({
                    method: 'POST',
                    url: 'userpwd/sendVerifyCode',
                    contentType: 'application/x-www-form-urlencoded',
                    params: {
                        // account: this.data.orgcode + ':' + this.data.account,
                        uid: this.data.account,
                        receiveType: this.way
                    }
                }).then(data => {
                    this.verification.sending = false
                    if (data.code === 'failure') {
                        this.error = data.message
                    } else if (data.code === 'success') {
                        this.data.reveiver = data.body.mail || data.body.mobile
                        this.verification.userMsg = false
                        const targetData = data.body
                        this.verification.passwordMsg = targetData.pwdHint // 密码级别信息
                        this.verification.passwordLevel = targetData.pwdLevel // 密码级别
                        typeof fn === 'function' && fn()
                    }
                }, err => {
                    this.error = err
                })
                this.isSend = false
            }
        },
        modification(fn) { // 修改密码
            this.verification.newPassword = this.data.password
            this.verification.queryNewPassword = this.data.password1
            if (this.verification.newPassword !== this.verification.queryNewPassword) {
                this.error = '两次输入密码不一致'
                return
            }
            this.$http({
                method: 'POST',
                url: 'userpwd/modifyPassword',
                contentType: 'application/x-www-form-urlencoded',
                params: {
                    // account: this.data.orgcode + ':' + this.data.account,
                    uid: this.data.account,
                    newPw: this.verification.newPassword,
                    verifyCode: this.data.captcha
                }
            }).then(data => {
                if (data.code === 'failure') {
                    this.error = data.message
                } else if (data.code === 'success') {
                    typeof fn === 'function' && fn()
                }
            }, err => { // 异常处理
                console.log(err)
            })
        },
        navigateToLogin() { // 跳转到登录页面
            this.$router.push({ path: '/login' })
        },
        nextFocus(ref) { // 下一个控件获取焦点
            if (ref === 'btnSubmit') {
                document.getElementById('subbtn').focus()
            } else {
                this.$refs[ref].focus()
            }
        }
    }
}
</script>

<style lang="scss">
    /* .findPassword .el-radio__input{
        width: 20.83333%;
        padding-right: 20px;
        text-align: right;
    }
    .findPassword .el-radio__label{
        width:79.16667%;
        padding:0;
        display:inline-block;
    } */
    .findPassword .el-step__title{
        font-size:13px;
    }
    .el-form-item--medium .el-form-item__content{
        line-height: 35px;
    }
</style>

<style scoped lang="scss">
@import "~@/wapp/common/styles/element-variables.scss";

$dark_gray:#606266;
$light_gray:#999;

header{
    border-bottom:1px solid #ccc;
    a.title{
        width: 1000px;
        margin:0 auto;
        display:block;
        cursor:default;
        h3{
            font-size: 26px;
            color: #777;
            line-height: 82px;
            text-align: left;
            font-weight: bold;
            margin: 0;
            img{
                width: 32px;
                height: 32px;
                vertical-align: middle;
                position: relative;
                top: -2px;
            }
            span{
                float:right;
                font-size:14px;
            }
        }
    }
}
/deep/.el-button.el-button--primary{
    background-color: #409EFF !important;
    border-color: #409EFF !important;
}
/deep/.el-radio .el-radio__input.is-checked .el-radio__inner{
   background-color: #409EFF !important;
    border-color: #409EFF !important;
}
.main-content{
    font-size:13px;
    color:$dark_gray;
    .el-row{
        margin-bottom:20px;
        line-height:36px;
        .el-button{
            width:100%;
            font-weight:600;
        }
    }
    .el-col-5{
        padding-right:20px;
        text-align:right;
        font-weight:600;
    }
    .center{
        width: 420px;
        margin: 100px auto;
        font-weight: 600;
        .el-radio{
            width: 100%;
            /* line-height: 36px;
            &.is-checked span.text{
                border: 1px solid $--color-primary;
                color: $--color-primary;
            } */
        }
    }
    .section{
        margin-top: 122px;
        width:760px;
        margin:100px auto;
        position: relative;
        .step{
            width: 88px;
            height: 36px;
            position: relative;
            float: left;
        }
        .step:before {
            position: absolute;
            top: 0px;
            right: 0;
            left: 0;
            bottom: 0;
            border-bottom: 36px solid #BBBBBB;
            border-left: 36px solid transparent;
            content: "";
        }
        .step:after {
            position: absolute;
            left: 1px;
            right: 0;
            top: 0;
            bottom: 0;
            border-bottom: 36px solid #fff;
            border-left: 36px solid transparent;
            content: "";
        }
        .step-num {
            font-size: 26px;
            color: $light_gray;
            position: absolute;
            left: 0;
            top: -16px;
            z-index: 2;
        }
        .step-num.cur {
            color: #333;
        }
        .step-txt {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
        }
        .step-line {
            width: 200px;
            height: 0;
            border-top: 1px solid #BBBBBB;
            margin: 20px 20px;
            float:left;
        }
        .step-main {
            padding-top: 60px;
            clear: both;
            .el-button{
                width:100%;
            }
        }
        .step-main-inner {
            width: 350px;
            height: auto;
            margin: 0 auto;
        }
    }
    .error{
        color: $--color-danger;
        font-size: 12px;
        height:23px;
    }
}
.copyright{
	text-align:center;
	color: $dark_gray;
}
</style>
