<template>
  <div id="CalendarPicker">
    <div class="calendar-header">
      <div class="calendar-header_title">
        {{ currentDate | timeText('YYYY年M月') }}
      </div>
      <div class="calendar-header_timer">
        <el-button class="pointer-button" :disabled="!canSelectPrevMonth && isSameMonth(currentDate, new Date())" @click="changeMonth('prev')"><i class="el-icon-arrow-left"></i></el-button>
        <div class="timer" @click="changeMonth('current')">本月</div>
        <el-button class="pointer-button" @click="changeMonth('next')"><i class="el-icon-arrow-right"></i></el-button>
      </div>
    </div>
    <div class="calendar-container">
      <table border="1" class="month-table">
        <tbody>
          <tr>
            <th v-for="(week, index) in weeks" :key="index">{{ week }}</th>
          </tr>
          <tr v-for="x in 5" :key="x">
            <td v-for="y in 7" :key="y">
              <div
                class="month-item"
                :class="{
                  'month-item__notNowMonth': getDayInfo(x, y).name !== 'nowMonth',
                  'month-item__disabled': checkIsDisabled(getDayInfo(x, y)),
                  'month-item__selected': checkIsSelected(getDayInfo(x, y))
                }"
                :style="{
                  'pointer-events': canEdit == 1 ? 'all' : 'none'
                }"
                @mousedown="handlerMouseDown(getDayInfo(x, y))"
                @mouseup="handlerMouseUp(getDayInfo(x, y))"
                @mousemove="handlerMouseMove(getDayInfo(x, y))"
              >
                <span>{{ Number(getDayInfo(x, y).date) }}</span>
                <span :class="getDayInfo(x, y).festival && 'festival-highlight'">
                  {{ getDayInfo(x, y).festival || getDayInfo(x, y).Term || getDayInfo(x, y).IDayCn }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import calendarUtil from '@/wapp/portal/components/calendarUtil/utli'
import { calendar } from '@/wapp/portal/components/calendarUtil/calendarUtil'
import { deepClone } from '@/global/utils'

const moment = require('moment')
// 判断是否为同年同月
const isSameMonth = (left, right) => {
    if (!(left instanceof Date)) {
        left = new Date(left)
        if (!isNaN(left.getTime())) { // 判断是否为有效 Date类型
            return false
        }
    }
    if (!(right instanceof Date)) {
        right = new Date(right)
        if (!isNaN(right.getTime())) { // 判断是否为有效 Date类型
            return false
        }
    }
    const isSameYear = left.getFullYear() === right.getFullYear()
    const isSameMouth = left.getMonth() === right.getMonth()
    return isSameYear && isSameMouth
}
const actionConfig_DEFAULT = { // 统一存储交互控制字段
    onClick: false,
    onMoving: false,
    onDelete: false,
    startTarget: null,
    endTarget: null
}
const weekDataOne = ['日', '一', '二', '三', '四', '五', '六']
export default {
    filters: {
        timeText(time, format = 'YYYY-M', CNTimer = false) {
            if (CNTimer) {
                if (isSameMonth(time, new Date())) {
                    return '本月'
                }
            }
            return moment(time).format(format)
        }
    },
    props: {
        dataList: { type: Array, default: () => [] }, // 原选中数组
        canSelectPrevMonth: { type: Boolean, default: false }, // 是否可选本月往前的月份
        canEdit: { type: [Number, String], default: 1 }, // 是否禁用
        valueFormat: { type: [String, Date], default: () => Date }, // 返回值类型
        calendarOption: {
            type: Object,
            default: () => {
                return {
                    disabledDay: [], // 禁用日期列表
                    dayFilter: null // 日期过滤函数
                }
            }
        }
    },
    data() {
        return {
            dateList: [], // 展示月份数据
            selectedDates: [], // 选中日期
            preSelectedDates: [], // 预选日期
            currentDate: new Date(), // 全部数据处理都是基于 Date类型
            weeks: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
            actionConfig: { // 统一存储交互控制字段
                onClick: false,
                onMoving: false,
                onDelete: false,
                startTarget: null,
                endTarget: null
            }
        }
    },
    computed: {
        // 选中数据
        selected() {
            if (this.selectedDates.length === 0) return []
            return this.selectedDates.flat().map(s => s.data)
        },
        // 预选中数据
        preSelected() {
            return this.preSelectedDates.map(s => s.data)
        },
        // 格式化禁用列表，格式化成与目前日历展示的格式
        formatDisabledDay() {
            if (!this.calendarOption.disabledDay) return []
            if (!this.calendarOption.disabledDay.length) return []
            return this.calendarOption.disabledDay.map(day => {
                if (day instanceof Date) {
                    day = moment(day).format('YYYY-MM-DD')
                }
                if ((typeof day === 'string' || typeof day === 'number') && !isNaN(new Date(day).getTime())) {
                    day = moment(new Date(day)).format('YYYY-MM-DD')
                }
                return day
            })
        }
    },
    watch: {
        dataList: {
            deep: true,
            immediate: true,
            handler(val) {
                if (!val) return
                // 一维数组转二维数组回显
                if (!this.selectedDates.length) {
                    this.selectedDates = val.map(item => {
                        const day = item.split('-')
                        const currentDayInfo = calendar.solar2lunar(day[0], day[1], day[2])
                        item = {
                            IDayCn: currentDayInfo.IDayCn,
                            IMonthCn: currentDayInfo.IMonthCn,
                            Term: currentDayInfo.term,
                            cMonth: currentDayInfo.cMonth,
                            data: item,
                            date: day[2],
                            festival: currentDayInfo.festival,
                            week: weekDataOne[currentDayInfo.nWeek]
                        }
                        if (!(item instanceof Array)) {
                            item = [item]
                        }
                        return item
                    })
                }
            }
        },
        preSelected(pres) {
            let conflict = null
            // 遍历“预选项”，已选中内容冲突时，删除带有冲突项的“已选项”
            pres.forEach(pre => {
                this.selected.forEach(s => {
                    if (pre === s) {
                        conflict = pre
                    }
                })
            })
            if (conflict) {
                if (pres.length === 1) {
                    this.actionConfig.onDelete = true
                    this.deleteSingleByDate(conflict)
                } else {
                    this.deleteGroupByDate(conflict)
                }
            }
        },
        // 选中数据更新回原字段
        selected: {
            deep: true,
            handler(val) {
                if (val.length === 0) return
                this.$emit('update:data-list', val)
            }
        }
    },
    created() {
        this.currentDate = new Date()
        this.handlerMonthChanged()
    },
    mounted() {
        if (this.dataList && this.dataList.length) {
            this.setEarlyMonth(this.dataList)
        }
    },
    methods: {
        isSameMonth,
        // 获取最早选中的日期 - 月份
        setEarlyMonth(dates) {
            const timerArr = dates.map(date => new Date(date).getTime())
            const earlyDate = Math.min.apply(null, timerArr)
            if (typeof earlyDate === 'number') {
                this.currentDate = new Date(earlyDate)
                this.handlerMonthChanged()
            }
        },
        // 月份变更
        handlerMonthChanged() {
            const dateList = calendarUtil.createNowMonthDate(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1)
            this.dateList = dateList.map(date => {
                date.data = moment(new Date(date.data)).format('YYYY-MM-DD')
                return date
            })
        },
        // 判断当前day是否需要被 dayFilter/disabledDay 禁用
        checkIsDisabled(day) {
            if (this.calendarOption.dayFilter && this.calendarOption.dayFilter(day)) return true
            if (this.formatDisabledDay && this.formatDisabledDay.length && this.formatDisabledDay.indexOf(day.data) !== -1) {
                return true
            }
            return false
        },
        // 判断当前day是否存在于 selectedDates 以及 preSelectedDates 中
        checkIsSelected(day) {
            let isSelected = false
            if (this.preSelected.indexOf(day.data) !== -1) {
                isSelected = true
            }
            if (this.selected.indexOf(day.data) !== -1) {
                isSelected = true
            }
            return isSelected
        },
        // 获取对应日期信息
        getDayInfo(x, y) {
            this.dateList[(x - 1) * 7 + (y - 1)].index = (x - 1) * 7 + (y - 1)
            return this.dateList[(x - 1) * 7 + (y - 1)]
        },
        // type: prev上一个月 next下一个月
        changeMonth(type) {
            if (type === 'current') {
                this.currentDate = new Date()
            }
            if (type === 'prev') {
                this.currentDate.setMonth(this.currentDate.getMonth() - 1)
            }
            if (type === 'next') {
                this.currentDate.setMonth(this.currentDate.getMonth() + 1)
            }
            this.handlerMonthChanged()
            this.$forceUpdate()
        },
        // 删除单项
        deleteSingleByDate(date) {
            this.selectedDates.forEach((group, index) => {
                this.selectedDates[index] = this.selectedDates[index].filter(groupItem => groupItem.data !== date)
            })
            // 过滤为空的选中组
            this.selectedDates = this.selectedDates.filter(group => group.length !== 0)
        },
        // 删除带有冲突项的块
        deleteGroupByDate(date) {
            let deleteIndex = null
            this.selectedDates.forEach((group, index) => {
                group.forEach(item => {
                    if (item.data === date) {
                        deleteIndex = index
                    }
                })
            })
            this.selectedDates.splice(deleteIndex, 1)
        },
        // 交互相关
        handlerMouseDown(day) {
            if (this.checkIsDisabled(day)) return
            this.actionConfig.onClick = true
            this.actionConfig.startTarget = day.index
            this.actionConfig.endTarget = day.index
            this.preSelectedDates.push(day)
        },
        handlerMouseUp(day) {
            if (this.checkIsDisabled(day)) return
            if (this.preSelectedDates && this.preSelectedDates.length) {
                if (this.preSelectedDates.length === 1 && this.actionConfig.onDelete) {
                    console.log('delete => ')
                } else {
                    this.selectedDates.push(this.preSelectedDates)
                }
            }
            this.actionConfig = deepClone(actionConfig_DEFAULT)
            this.preSelectedDates = []
        },
        handlerMouseMove(day) {
            if (this.calendarOption.dayFilter && this.calendarOption.dayFilter(day)) return
            if (this.actionConfig.endTarget === null) return
            if (this.actionConfig.onClick && day.index !== this.actionConfig.endTarget) {
                this.actionConfig.onMoving = true
                this.actionConfig.endTarget = day.index

                if (this.actionConfig.onMoving) {
                    this.preSelectedDates = this.dateList.slice(this.actionConfig.startTarget, this.actionConfig.endTarget + 1)
                }
            }
        },
        // ---------------对外暴露函数--------------------

        // 获取当前选择器选中Date
        // format 是否需要格式化时间 例：YYYY-MM
        // default return: currentDate【Date】
        getDate(format = '') {
            if (typeof format === 'string' && format) {
                return moment(this.currentDate).format(format)
            }
            return this.currentDate
        }
    }
}
</script>

<style lang="scss" scoped>
#CalendarPicker{
    user-select: none;
    .calendar-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f2f5fb;
        padding: 10px;
        padding-bottom: 0;
        border: 1px solid #EBEBEB;
        border-bottom: none;
    }
    .calendar-header_title{
        font-weight: 700;
        font-size: 22px;
    }
    .calendar-header_timer{
        display: flex;
        .timer{
            margin: 0 5px;
            background: #ffffff;
            height: 30px;
            line-height: 30px;
            font-size: 13px;
            color: #606266;
            width: 60px;
            text-align: center;
        }
    }
    .calendar-container{
        width: 100%;
        position: relative;
        margin: 0 auto;
        border-radius: 8px;
        .month-table{
            width: 100%;
            border: 1px solid #EBEBEB;
            border-top: none;
            border-collapse: collapse;
            tr{
                th,td{
                    width: 14.28%;
                    height: 46px;
                    position: relative;
                    padding: 0;
                    line-height: 18px;
                    .month-item{
                        width: 100%;
                        height: 100%;
                        font-size: 13px;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        color: #333333;
                    }
                    .month-item__disabled{
                        pointer-events: none;
                        cursor: not-allowed;
                        background: #e3e3e3 !important;
                    }
                    .month-item__notNowMonth{
                        color: #C0C4CC !important;
                    }
                    .month-item__selected{
                        color: #ffffff;
                        background: var(--subjectColor) !important;
                        span{
                            color: #ffffff;
                        }
                    }
                    .festival-highlight{
                        color: var(--subjectColor);
                        font-weight: 600;
                    }
                }
                td:hover{
                    background: #f2f5fb;
                    .month-item{
                        color: #333333 !important;
                    }
                }
                th{
                    font-size: 16px;
                    font-weight: 700;
                    border: none;
                    background: #f2f5fb;
                    text-align: center;
                }
            }
        }

    }
    .pointer-button{
        padding: 0 3px;
        height: 30px;
        border: none;
        border-radius: 0;
    }
}
</style>
