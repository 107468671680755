<template>
    <div id="article" class="header-content-outer">
        <div class="header-content" :class="{ 'hideTopMenus-header-container': !showTopMenus }">
            <div class="sidebar-logo-container" :class="{ collapse: isCollapse && !showTopMenus }">
                <transition name="sidebarLogoFade">
                    <div class="sidebar-logo-link">
                        <div>
                            <img v-if="logoUrl" :src="logoUrl" class="sidebar-logo" />
                            <h1 class="sidebar-title">
                                {{ title }}
                                <small />
                            </h1>
                            <TopMenu v-if="topMenuShow" :is-detail="true" />
                        </div>

                        <div class="right-menu-item avatar-box">
                            <div style="color: #333; height: 60px; line-height: 60px">{{ loginUser.personName }}</div>
                            <div style="height: 60px; line-height: 60px; margin: 7px 25px 7px 10px">
                                <el-avatar size="small" :src="avatar + '&httpPort=' + port" shape="circle" fit="cover" class="picture" alt="头像" />
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { logoUrl, title } from '@/wapp/settings'
import TopMenu from './TopMenu'
export default {
    name: 'SidebarLogo',
    components: {
        TopMenu
    },
    props: {
        topMenuShow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            title: title,
            count: 0,
            logoUrl: logoUrl,
            // logo: './images/logo.png'
            loginUser: this.$store.getters.loginUser
        }
    },
    computed: {
        ...mapGetters(['sidebar', 'avatar']),
        showTopMenus() {
            return this.$store.state.settings.showTopMenus
        },
        isCollapse() {
            return !this.$store.state.app.sidebar.opened
        }
    },
    mounted() {
        // console.log(this.avatar, this.loginUser, 44)
        window.addEventListener('resize', this.onresize)
        this.$nextTick(() => {
            this.setHeight()
            const timer = setInterval(() => {
                this.setHeight()
                this.count++
                if (this.count > 5) {
                    clearInterval(timer)
                }
            }, 50)
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onresize)
    },
    methods: {
        onresize() {
            this.$nextTick(() => {
                this.setHeight()
                const timer = setInterval(() => {
                    this.setHeight()
                    this.count++
                    if (this.count > 5) {
                        clearInterval(timer)
                    }
                }, 50)
            })
        },
        setHeight() {
            const dom2 = document.getElementById('article').offsetParent
            dom2.style.transform = 'translate3d(0px, 0px, 0px)'
            dom2.style.height = '60px'
            dom2.style.width = '100%'
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/wapp/common/styles/variables.module.scss';

.sidebarLogoFade-enter-active {
    transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
    opacity: 0;
}

.sidebar-logo-container {
    transition: width 0.28s;
    transition-duration: 0.28s;
    position: relative;
    width: auto;
    height: 60px;
    line-height: 60px;
    text-align: left;
    overflow: hidden;
    /* background-color: $headerBackground; */
    // float: left;

    & .sidebar-logo-link {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;

        & .sidebar-logo {
            // width: 56px;
            height: 30px;
            background: var(--bgColor);
            vertical-align: middle;
            margin-right: 14px;
            margin-top: -6px;
            margin-left: 20px;
        }

        & .sidebar-title {
            margin-bottom: 2px !important;
            margin-left: -4px !important;
            display: inline-block;
            margin: 0;
            color: $headerText;
            font-weight: bold;
            line-height: 50px;
            font-size: 20px;
            font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
            vertical-align: middle;
            small {
                font-size: 22px;
                font-weight: normal;
                padding-left: 6px;
            }
        }
    }

    &.collapse {
        width: 54px;

        .sidebar-logo {
            margin-left: 10px;
            margin-right: 0px;
        }
        .sidebar-title {
            display: none;
        }
    }
}
.sidebar-logo-container .sidebar-logo-link .sidebar-title {
    color: var(--bgColor);
}

.hideTopMenus-header-container .sidebar-logo-container {
    overflow: visible;
    .sidebar-logo-link:after {
        content: '';
        width: 100%;
        position: absolute;
        height: 1px;
        background: #475871;
        bottom: -1px;
        display: block;
    }
}
.right-menu-item {
    display: inline-block;
    height: 100%;
    font-size: 20px;
    color: $headerText;
    vertical-align: text-bottom;

    &.avatar-box {
        font-size: 13px;
        display: flex;
        .el-avatar--small {
            width: 46px;
            height: 46px;
        }
        .user-avatar {
            float: left;
            margin-top: (60px - 46px) / 2;
            margin-right: 6px;
            &.el-avatar > img {
                width: 100%;
            }
        }
    }
}
</style>
