<template>
  <div class="calendar-detail" onselectstart="return false;">
    <div v-loading="loading" class="left-content">
      <div class="gray-line" :style="{'height':liHeight+'px'}"></div>
      <!-- 左边顶部操作按钮 -->
      <div v-if="!loading" class="left-top">
        <div class="year-title" style="font-size: 22px;color: #24292B;width:210px;font-weight: 600;">
          <span v-if="spanIndex==1">{{ dateTile }}</span>
          <span v-if="spanIndex==2">{{ weekTitle }}</span>
          <span v-if="spanIndex==3">{{ MonthTitle }}</span>
        </div>

        <div class="oa-title">
          <div class="tab">

            <span> 图例： </span>
            &nbsp;
            <i style="width: 15px;height: 10px;display: inline-block;background: rgb(253, 0, 0);"></i>
            &nbsp;
            <span> 调班 </span>
            &nbsp;
            <i style="width: 15px;height: 10px;display: inline-block;background: #f27707;"></i>
            &nbsp;
            <span>  未排 </span>
            &nbsp;&nbsp;
          </div>
          <template v-if="spanIndex==3">
            <span class="left-o" @click="preMonth"> <i class="el-icon-arrow-left" /> </span>
            <span class="toDay center-o" @click="gotoDay">本月</span>
            <span class="right-o" @click="nextMonth"><i class="el-icon-arrow-right" /></span>
          </template>
        </div>
      </div>
      <!-- 月视图 -->
      <div v-if="spanIndex==3 && !loading" class="month-box">
        <div class="month-week">
          <div v-for="(item,index) in monthWeekData" :key="index" :style="{'border-right':index==6?'none':''}">
            {{ item }}
          </div>
        </div>
        <table v-if="dateListData.length>0" border="1" class="month-table">
          <tbody>
            <tr v-for="x in 5" :key="x">
              <td v-for="y in 7" :key="y" :class="dateListData[(x - 1) * 7 + (y - 1)].data==chooseDate?'':''" @click.stop="monthClick(dateListData[(x - 1) * 7 + (y - 1)])">
                <div class="month-item">
                  <div class="item-first">
                    <span v-if="dateListData[(x - 1) * 7 + (y - 1)].date==='01'" style="color:var(--bgColor)">{{ dateListData[(x - 1) * 7 + (y - 1)].cMonth+'月' }}</span>
                    <span v-if="dateListData[(x - 1) * 7 + (y - 1)].date!=='01'" style="">{{ dateListData[(x - 1) * 7 + (y - 1)].date }}</span>
                    <template v-if="!dateListData[(x - 1) * 7 + (y - 1)].Term">
                      <span v-if="dateListData[(x - 1) * 7 + (y - 1)].IDayCn==='初一'" style="color:var(--bgColor)">{{ dateListData[(x - 1) * 7 + (y - 1)].IMonthCn }}</span>
                      <span v-if="dateListData[(x - 1) * 7 + (y - 1)].IDayCn!=='初一'" style="">{{ dateListData[(x - 1) * 7 + (y - 1)].IDayCn }}</span>
                    </template>
                    <template v-if="dateListData[(x - 1) * 7 + (y - 1)].Term">
                      <div v-if="dateListData[(x - 1) * 7 + (y - 1)].Term && !dateListData[(x - 1) * 7 + (y - 1)].listDetail">
                        <span> {{ dateListData[(x - 1) * 7 + (y - 1)].Term }}</span>
                      </div>
                    </template>
                  </div>
                  <template v-if="dateListData[(x - 1) * 7 + (y - 1)].listDetail">
                    <div v-if="dateListData[(x - 1) * 7 + (y - 1)].listDetail.length>0" style="padding: 5px;width:100%;height:calc(100% - 28px);">
                      <popover which-type="2" :list-detail="dateListData[(x - 1) * 7 + (y - 1)].listDetail" :set-data-vo="setDataVo" />
                    </div>
                  </template>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <zbdj-form></zbdj-form>
    <Basicform
      ref="busiform"
      :title="'查看'"
      :win-width="moduleInfo.winWidth"
      :win-height="moduleInfo.winHeight"
      :append-to-body="true"
      :toolbar-btns="busiformBtns"
      :on-basic-form-before-open="onBasicFormBeforeOpened"
      :on-basic-form-opened="onBasicFormOpened"
      :on-basic-form-before-submit="onBasicFormBeforeSubmit"
      :on-basic-form-submited="onBasicFormSubmited"
    >
      <template #default="slotProps">
        <busi-form ref="basicform" :vo="slotProps.vo" :can-edit="slotProps.canEdit" :module-info="moduleInfo" />
      </template>
    </Basicform>
  </div>
</template>

<script>
import calendarUtil from './components/calendarUtil/utli.js'
import popover from '../components/calendarPopover.vue'
import zbdjForm from '../components/zbdjForm.vue'
import { calendar } from './components/calendarUtil/calendarUtil'
const moment = require('moment')
export default {
    components: {
        popover, zbdjForm
    },
    data() {
        return {
            loginUser: this.$store.getters.loginUser,
            incidentsList: [],
            wnlData: {},
            weekData: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
            WeekArray: [
                { title: '星期一', date: '', dataValue: '' },
                { title: '星期二', date: '', dataValue: '' },
                { title: '星期三', date: '', dataValue: '' },
                { title: '星期四', date: '', dataValue: '' },
                { title: '星期五', date: '', dataValue: '' },
                { title: '星期六', date: '', dataValue: '' },
                { title: '星期日', date: '', dataValue: '' }
            ],
            monthWeekData: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
            timeData: ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
            currentTime: this.getCurrentDateTime(),
            noChangeNowTime: this.getCurrentDateTime(),
            listData: [],
            loading: true,
            dateListData: [],
            chooseDate: this.getCurrentDateTime(),
            preOrNextDay: '',
            canlendarData: [],
            scheduleData: [],
            scheduleWeek: [],
            finallyData: [],
            incidents: [],
            listdataWeek: [],
            preOrNextDate: '',
            dateTile: moment(new Date()).format('YYYY年MM月DD日'),
            weekTitle: '',
            MonthTitle: moment(new Date()).format('YYYY年MM月'),
            spanIndex: 3,
            yearAndMonth: moment(this.currentTime).format('YYYY年MM月'),
            bigScreen: false,
            moduleInfo: {
                'winWidth': 1000,
                'delUrl': 'zbgl/dutyRecord/delete',
                'addNewUrl': 'zbgl/dutyRecord/addNew',
                'insertUrl': 'zbgl/dutyRecord/insert',
                'winHeight': 550,
                'updateUrl': 'zbgl/dutyRecord/update',
                'listDataUrl': 'zbgl/dutyRecord/listdata',
                'getByIdUrl': 'zbgl/dutyRecord/getById'
            },
            busiformBtns: [],
            formStartTime: '',
            formEndTime: '',
            isEnd: '',
            addMonthStartTime: '',
            liHeight: 0,
            rowData: {},
            isnew: true,
            roles: [],
            setDataVo: {}
        }
    },
    watch: {
        currentTime(val) {
            if (val) {
                this.yearAndMonth = val.split('-')[0] + '年' + val.split('-')[1] + '月'
            }
        }
    },
    created() {
        this.getSetData()
        this.$bus.emit('showNavSearch', false)
        this.$bus.on('editForm', this.openForm)
        this.$bus.on('liH', this.changeLih)
        this.initData()
        if (this.spanIndex === 2) {
            this.initWeek()
        }
        this.roles = this.$store.state.user.roles
        console.log(this.roles, 331)
    },
    mounted() {
        this.getWindowInfo()
        window.addEventListener('resize', this.getWindowInfo)
        if (document.querySelector('.navbar')) {
            document.querySelector('.navbar').style = 'display:none'
        }
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true)
        this.$bus.off('editForm', this.openForm)
        this.$bus.off('liH', this.changeLih)
        if (document.querySelector('.navbar')) {
            document.querySelector('.navbar').style = 'display:block'
        }
        window.removeEventListener('resize', this.getWindowInfo)
    },
    methods: {
        getSetData() {
            this.$http({
                method: 'POST',
                url: '/zbgl/dutySet/getDutySet'
            }).then(res => {
                if (res.code === 'success') {
                    this.setDataVo = res.body.vo
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    })
                }
            })
        },
        openZbdjForm() {
            console.log('rowData', this.rowData)
            // var day = this.rowData.dutyDate.split(' ')[0]
            var day = moment(new Date(this.rowData.dutyDate)).add(1, 'days').format('YYYY-MM-DD')
            var hour = moment(new Date()).format('HH:mm:ss')
            var currentTime = day + ' ' + hour
            this.rowData.currentTime = currentTime
            this.$bus.emit('openZbdjForm', 1, this.rowData, 'add')
        },
        changeLih(val) {
            this.liHeight = val
        },
        onBasicFormBeforeOpened($scope, vo, defer) {
            this.rowData = vo
            this.isnew = vo.isnew
            defer.resolve(true)
        },
        onBasicFormBeforeSubmit($scope, vo, defer) {
            $scope.vo.passenger.dutyPersonList = $scope.$vnode.context.$refs.basicform.tableData
            debugger
            defer.resolve(true)
        },
        onBasicFormSubmited($scope, vo) {
            this.addMonthStartTime = ''
            this.initData(this.chooseDate)
            this.yearAndMonth = moment(this.chooseDate).format('YYYY年MM月')
            this.$forceUpdate()
        },
        onBasicFormOpened($scope, vo) {
            if (this.addMonthStartTime) {
                vo.dutyDate = moment(new Date(this.addMonthStartTime)).format('YYYY-MM-DD HH:mm:ss')
            }
            if (!vo.creator) {
                vo.creator = $scope.loginUser.personName
                vo.createTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        deleteIds() {
            let current = 2
            const _this = this
            if (this.rowData.userId) {
                var arr = this.rowData.userId.split(',')
                arr.forEach(data => {
                    if (data === this.loginUser.userId) {
                        this.$alert('受邀人不允许删除次日程！')
                    } else {
                        if (this.rowData.dupConfig !== 0) {
                            this.$bus.emit('openListitemDialog', '选择删除方式', [{ text: '删除当前日程', value: '1' }, { text: '删除当前以及后续日程', value: '0' }, { text: '删除全部重复日程', value: '2' }], rest => {
                                if (rest.code === 'success') {
                                    current = rest.value
                                    _this.deleteCalendar(current)
                                }
                            }, null, null, '当前为重复日程，请选择：')
                        } else {
                            this.$confirm('您是否确认删除该日程？', '确认操作', { type: 'warning' })
                                .then(() => {
                                    _this.deleteCalendar(current)
                                }).catch(() => {})
                        }
                    }
                })
            } else {
                this.$confirm('您是否确认删除该日程？', '确认操作', { type: 'warning' })
                    .then(() => {
                        _this.deleteCalendar(current)
                    }).catch(() => {})
            }
        },
        deleteCalendar(current) {
            this.$http({
                method: 'DELETE',
                contentType: 'application/json charset=utf-8',
                url: 'ecoa/calendar/delete',
                params: { 'id': this.rowData.id, startDate: this.rowData.startDate, isCurrent: current }
            }).then(res => {
                if (res.code === 'success') {
                    this.$message.success(res.message)
                    this.$refs['busiform'].closeDialog()
                    this.onBasicFormSubmited()
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        openForm(vo, type, isEnd) {
            var _this = this
            this.isEnd = isEnd
            if (vo) {
                if (this.roles && this.roles.indexOf('zbManager') === -1) {
                    _this.busiformBtns = [
                        {
                            id: 'btn_edit',
                            btnName: '值班登记',
                            btnIcon: '',
                            btnClick: this.openZbdjForm,
                            btnPermission: 'openZbdjForm',
                            btnType: 'primary'
                        },
                        {
                            id: 'btn_close',
                            btnName: '关 闭',
                            btnIcon: '',
                            btnClick: 'closeDialog',
                            btnPermission: '',
                            btnType: ''
                        }
                    ]
                } else {
                    _this.busiformBtns = [
                        {
                            id: 'btn_edit',
                            btnName: '修 改',
                            btnIcon: '',
                            btnClick: this.editObject,
                            btnPermission: 'editObject',
                            btnType: 'primary'
                        },
                        {
                            id: 'btn_edit',
                            btnName: '值班登记',
                            btnIcon: '',
                            btnClick: this.openZbdjForm,
                            btnPermission: 'openZbdjForm',
                            btnType: 'primary'
                        },
                        {
                            id: 'btn_close',
                            btnName: '关 闭',
                            btnIcon: '',
                            btnClick: 'closeDialog',
                            btnPermission: '',
                            btnType: ''
                        }
                    ]
                }
            } else {
                _this.busiformBtns = [
                    {
                        id: 'btn_save',
                        btnName: '保 存',
                        btnIcon: 'check',
                        btnClick: 'submitBusiform',
                        btnPermission: 'saveObject',
                        btnType: 'primary'
                    },
                    {
                        id: 'btn_close',
                        btnName: '关 闭',
                        btnIcon: '',
                        btnClick: 'closeDialog',
                        btnPermission: '',
                        btnType: ''
                    }
                ]
            }
            console.log(vo, type, isEnd)
            _this.moduleInfo.formTemplate = '#/dcontent/vuebusiform/W/zbgl.dutyRecord.form.vue?sysCode='
            _this.$options.components['BusiForm'] = _this.$loadComponent(_this.moduleInfo.formTemplate)
            if (type === 'edit') {
                this.$nextTick(() => {
                    this.$bus.emit('openBusiform', this.moduleInfo, vo, 0)
                })
            } else {
                this.$nextTick(() => {
                    this.$bus.emit('openBusiform', this.moduleInfo, null, 1)
                })
            }
        },
        editObject() {
            var _this = this
            _this.busiformBtns = [
                {
                    id: 'btn_save',
                    btnName: '保 存',
                    btnIcon: 'check',
                    btnClick: 'submitBusiform',
                    btnPermission: 'saveObject',
                    btnType: 'primary'
                },
                {
                    id: 'btn_edit',
                    btnName: '值班登记',
                    btnIcon: '',
                    btnClick: this.openZbdjForm,
                    btnPermission: 'openZbdjForm',
                    btnType: 'primary'
                },
                {
                    id: 'btn_close',
                    btnName: '关 闭',
                    btnIcon: '',
                    btnClick: 'closeDialog',
                    btnPermission: '',
                    btnType: ''
                }
            ]
            this.$nextTick(() => {
                this.$bus.emit('openBusiform', this.moduleInfo, this.rowData, 1)
            })
        },
        getWindowInfo() {
            if (document.querySelectorAll('.el-scrollbar__view').length === 3) {
                this.liHeight = document.querySelectorAll('.el-scrollbar__view')[2].clientHeight
            }
            if (document.body.clientWidth > 1600) {
                this.bigScreen = true
            } else {
                this.bigScreen = false
            }
        },
        wnlFormat(val) {
            const date = val.split('-')
            const year = date[0]
            const month = date[1]
            const day = date[2]
            const Idate = calendar.solar2lunar(year, month, day).IMonthCn + calendar.solar2lunar(year, month, day).IDayCn
            const festival = calendar.solar2lunar(year, month, day).festival
            return {
                Idate,
                festival
            }
        },
        gotoDay() {
            if (this.spanIndex === 1 || this.spanIndex === 3) {
                const val = this.getCurrentDateTime().split('-')
                this.listData = calendarUtil.createNowMonthDate(val[0], val[1])
                this.preOrNextDay = this.noChangeNowTime
                this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.noChangeNowTime)
                this.dateTile = moment(this.noChangeNowTime).format('YYYY年MM月DD日')
                this.yearAndMonth = moment(this.noChangeNowTime).format('YYYY年MM月')
                this.MonthTitle = moment(this.noChangeNowTime).format('YYYY年MM月')
                this.chooseDate = this.noChangeNowTime
                this.currentTime = this.noChangeNowTime
            } else {
                this.listdataWeek = calendarUtil.getNowWeekDayA(this.noChangeNowTime)
                this.preOrNextDate = this.noChangeNowTime
                this.weekTitle = moment(this.listdataWeek[0].data).format('MM月DD日') + '-' + moment(this.listdataWeek[this.listdataWeek.length - 1].data).format('MM月DD日')
                for (let i = 0; i <= this.listdataWeek.length - 1; i++) {
                    this.WeekArray[i].date = this.listdataWeek[i].month + ' ' + this.listdataWeek[i].date
                    this.WeekArray[i].dataValue = this.listdataWeek[i].data
                }
                this.getWeekListData(this.listdataWeek[0].data, this.listdataWeek[this.listdataWeek.length - 1].data, this.listdataWeek) // 获取本周的日程数据
            }
        },
        nowLine() {
            var d = new Date()
            var hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
            var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
            var startHeight = (minutes - 0) + (30 - 0)
            var time = hours + ':' + minutes
            return {
                hours,
                minutes,
                time,
                startHeight
            }
        },
        initWeek() {
            const val = this.chooseDate
            this.listdataWeek = calendarUtil.getNowWeekDayA(val)
            this.weekTitle = moment(this.listdataWeek[0].data).format('MM月DD日') + '-' + moment(this.listdataWeek[this.listdataWeek.length - 1].data).format('MM月DD日')
            for (let i = 0; i <= this.listdataWeek.length - 1; i++) {
                this.WeekArray[i].date = this.listdataWeek[i].month + ' ' + this.listdataWeek[i].date
                this.WeekArray[i].dataValue = this.listdataWeek[i].data
            }
            this.getWeekListData(this.listdataWeek[0].data, this.listdataWeek[this.listdataWeek.length - 1].data, this.listdataWeek) // 获取本周的日程数据
        },
        initData(time) {
            this.currentTime = time || this.currentTime
            const val = time ? this.chooseDate.split('-') : this.getCurrentDateTime().split('-')
            this.listData = calendarUtil.createNowMonthDate(val[0], val[1])
            this.wnlData = this.wnlFormat(this.currentTime)
            this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.currentTime) // 获取一月的日程数据
        },
        getWeekListData(start, end, weekData) {
            this.loading = true
            let canlendarData = []
            this.$http({
                method: 'post',
                params: {
                    startDate: moment(start).format('YYYY-MM-DD') + ' ' + '00:00:00',
                    endDate: moment(end).format('YYYY-MM-DD') + ' ' + '23:59:59'
                },
                url: '/zbgl/dutyRecord/dayView'
            }).then((res) => {
                if (res.code === 'success') {
                    canlendarData = res.body.calendarList
                    this.finallyWeekData = this.arrayAdapterWeek(weekData, canlendarData).weeklist
                    // if (this.spanIndex === 2) {
                    //     const scrlloBox = document.querySelector('.week-content')
                    //     this.$nextTick(() => {
                    //         if (this.nowLine().hours < 17) {
                    //             scrlloBox.scrollTop = this.nowLine().hours * 60 - 50
                    //         } else {
                    //             scrlloBox.scrollTop = 900
                    //         }
                    //     })
                    // }
                    this.loading = false
                    this.$forceUpdate()
                }
            })
        },
        getMonthListData(start, end, listdata, currentTime) { // 获取一个月的日程数据
            this.canlendarData = []
            this.weeklist = []
            this.loading = true
            this.$http({
                method: 'post',
                params: {
                    startDate: moment(start).format('YYYY-MM-DD') + ' ' + '00:00:00',
                    endDate: moment(end).format('YYYY-MM-DD') + ' ' + '23:59:59'
                },
                url: '/zbgl/dutyRecord/dayView'
            }).then((res) => {
                if (res.code === 'success') {
                    this.$http({
                        method: 'POST',
                        data: [

                            { compare: '=', field: 'type', merge: true, value: '3' }
                        ],
                        url: '/portal/cms/article/querydata'
                    }).then(resp => {
                        if (resp.code === 'success') {
                            this.incidents = resp.body.listdata
                            this.canlendarData = res.body.calendarList
                            this.dateListData = this.arrayAdapter(listdata, this.canlendarData, currentTime, this.incidents).dateList
                            this.finallyData = this.arrayAdapter(listdata, this.canlendarData, currentTime, this.incidents).schedule // 获取日程数据结构

                            if (this.incidents.length > 0) {
                                this.incidentsList = this.incidents.filter(val => {
                                    return val.publishTime && val.publishTime.split(' ')[0] === currentTime
                                })
                            }
                            this.loading = false
                            this.$forceUpdate()
                        }
                    })
                }
            })
        },
        // 组件日程列表数据(周)
        arrayAdapterWeek(weekData, arrayB) { // 1周日历数据，2日程数据
            this.scheduleWeek = []
            if (arrayB.length > 0) {
                arrayB.forEach((item) => {
                    if (weekData.length > 0) {
                        weekData.forEach((week) => {
                            if (item.date === week.data) {
                                week.flag = true
                                week.listDetail = item.listDetail
                                item.listDetail.forEach((el) => {
                                    const timeHeight = this.timeDiff(this.timeFor(el.startDate, el.endDate, el.startTime, el.endTime).start, this.timeFor(el.startDate, el.endDate, el.startTime, el.endTime).end)
                                    const time = this.timeFor(el.startDate, el.endDate, el.startTime, el.endTime).time
                                    const startHeight = this.timeFor(el.startDate, el.endDate, el.startTime, el.endTime).startHeight
                                    const endTime = this.timeFor(el.startDate, el.endDate, el.startTime, el.endTime).end
                                    this.scheduleWeek.push({
                                        timeHeight: timeHeight,
                                        time: time,
                                        listDetail: el,
                                        date: item.date,
                                        isEnd: new Date(endTime).getTime() < new Date().getTime(),
                                        startHeight: startHeight
                                    })
                                })
                            }
                        })
                    }
                })
            }

            weekData.forEach(val => {
                val.allData = []
                this.scheduleWeek.forEach(item => {
                    if (val.data === item.date) {
                        val.allData.push(item)
                    }
                })
            })
            let allTime = {}
            let num = 1
            const weeklist = []
            this.timeData.forEach((data) => {
                allTime = {
                    timeDate: num < 10 ? '0' + num : num + '',
                    title: data
                }
                num++
                weeklist.push(JSON.parse(JSON.stringify(allTime)))
            })
            const finallyWeekList = []
            weeklist.forEach(item => {
                const valDataA = []
                weekData.forEach(val => {
                    const temp = []
                    val.listdata = []
                    if (val.allData.length > 0) {
                        val.allData.forEach(el => {
                            if (item.timeDate === el.time) {
                                temp.push(el)
                            }
                        })
                    }
                    val.listdata = temp
                    valDataA.push(val)
                })
                finallyWeekList.push({
                    timeDate: item.timeDate,
                    title: item.title,
                    valData: JSON.parse(JSON.stringify(valDataA))
                })
            })
            console.log(653, finallyWeekList)
            return {
                weeklist: finallyWeekList
            }
        },
        // 组件日程列表数据(月跟日)
        arrayAdapter(arrayA, arrayB, current, incidents) { // 1日历数据，2日程数据,3当天日期
            this.scheduleData = []
            if (arrayB.length > 0) {
                arrayB.forEach((item) => {
                    if (arrayA.length > 0) {
                        arrayA.forEach((val) => {
                            if (moment(new Date(item.date)).format('YYYY-MM-DD') === val.data) {
                                val.flag = true
                                val.listDetail = item.listDetail
                                item.listDetail.forEach((el) => {
                                    if (current === item.date) {
                                        const timeHeight = this.timeDiff(this.timeFor(el.dutyDate, el.dutyDate, '00:00:00', '00:00:00').start, this.timeFor(el.dutyDate, el.dutyDate, '00:00:00', '00:00:00').end)
                                        const time = this.timeFor(el.dutyDate, el.dutyDate, '00:00:00', '00:00:00').time
                                        const startHeight = this.timeFor(el.dutyDate, el.dutyDate, '00:00:00', '00:00:00').startHeight
                                        const endTime = this.timeFor(el.dutyDate, el.dutyDate, '00:00:00', '00:00:00').end
                                        this.scheduleData.push({
                                            timeHeight: timeHeight,
                                            time: time,
                                            listDetail: el,
                                            date: item.date,
                                            isEnd: new Date(endTime).getTime() < new Date().getTime(),
                                            startHeight: startHeight
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }
            let allTime = {}
            let num = 1
            const showData = []
            this.timeData.forEach((data) => {
                allTime = {
                    timeDate: num < 10 ? '0' + num : num + '',
                    title: data,
                    currentStart: current + ' ' + data,
                    currentEnd: current + ' ' + (num < 9 ? ('0' + ((data.split(':')[0] - 0) + 1) + ':00') : (data.split(':')[0] - 0) + 1 + ':00')
                }
                num++
                showData.push(JSON.parse(JSON.stringify(allTime)))
            })
            showData.forEach((itemA) => {
                const tempData = []
                if (this.scheduleData.length > 0) {
                    this.scheduleData.forEach((val) => {
                        if (itemA.timeDate === val.time) {
                            tempData.push(val)
                        }
                    })
                }
                itemA.valData = tempData
            })
            return {
                schedule: showData,
                dateList: arrayA
            }
        },
        getCurrentDateTime() {
            var d = new Date()
            var year = d.getFullYear()
            var month = d.getMonth() + 1
            var date = d.getDate()
            var curDateTime = ''
            curDateTime = year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date)
            return curDateTime
        },
        monthClick(item) {
            this.addMonthStartTime = item.data + ' ' + (new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()) + ':' + (new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes())
            if (this.roles && this.roles.indexOf('zbManager') === -1) {
                return
            }
            this.openForm(null)
            this.$forceUpdate()
        },
        timeDiff(startTime, endTime) {
            const start = new Date(startTime).getTime()
            const end = new Date(endTime).getTime()
            const val = Number(end) - Number(start)
            const minutes = val / 1000 / 60
            return minutes
        },
        timeFor(startDate, endDate, startTime, endTime) {
            const time = startTime.split(':')[0]
            const startHeight = (startTime.split(':')[1] - 0)
            const start = startDate + ' ' + startTime.split(':')[0] + ':' + startTime.split(':')[1]
            const end = endDate + ' ' + endTime.split(':')[0] + ':' + endTime.split(':')[1]
            return {
                time,
                startHeight,
                start,
                end
            }
        },
        preMonth() {
            let year = new Date(this.currentTime).getFullYear()
            let month = new Date(this.currentTime).getMonth() + 1
            if (month <= 1) {
                this.listData = calendarUtil.createNowMonthDate(--year, '12')
                this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.currentTime)
                this.currentTime = year + '-' + 12
                this.MonthTitle = moment(this.currentTime).format('YYYY年MM月')
            } else {
                this.listData = calendarUtil.createNowMonthDate(year, --month < 10 ? '0' + month : month)
                this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.currentTime)
                this.currentTime = year + '-' + (month < 10 ? '0' + month : month)
                this.MonthTitle = moment(this.currentTime).format('YYYY年MM月')
            }
        },
        nextMonth() {
            let year = new Date(this.currentTime).getFullYear()
            let month = new Date(this.currentTime).getMonth() + 1
            if (month >= 12) {
                this.listData = calendarUtil.createNowMonthDate(++year, '01')
                this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.currentTime)
                this.currentTime = year + '-' + '01'
                this.MonthTitle = moment(this.currentTime).format('YYYY年MM月')
            } else {
                this.listData = calendarUtil.createNowMonthDate(year, ++month < 10 ? '0' + month : month)
                this.getMonthListData(this.listData[0].data, this.listData[this.listData.length - 1].data, this.listData, this.currentTime)
                this.currentTime = year + '-' + (month < 10 ? '0' + month : month)
                this.MonthTitle = moment(this.currentTime).format('YYYY年MM月')
            }
        }
    }
}
</script>
<style lang="scss">
 .pro-detail{
   border-radius: 8px !important;
 }
</style>
<style scoped lang="scss">
/deep/.el-loading-spinner {
  margin-top: 200px !important;
}
.potop{
    width: 100%;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    .left-radius{
      display:inline-block;
      width:8px;
      height:8px;
      border-radius:50%;
      margin-right:5px;
    }
}
@import '~@/wapp/common/styles/variables.module.scss';
.calendar-detail{
   // background: #f3f6f9;
    width:100%;
    margin: 0 auto;
    display: flex;
    // min-height: 591.6px;
   // height: calc(100vh - 130px);
    .left-content{
        width:100%;
        height: 100%;
        background: white;
        position: relative;
        .gray-line{
          position: absolute;
          z-index: 100;
          width: 10px;
          height: 100%;
          background: #f2f6f9;
          top: 0;
          right: -10px;
        }
        .left-top{
          margin: 0 auto;
          width: 98%;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .time-title{
            display: flex;
            position: relative;
            span{
              height: 26px;
              width: 83px;
              text-align: center;
              cursor: pointer;
              line-height: 26px;
              background: #F9F8F8;
              color: #24292B;
            }
            .spanActive{
               background:  var(--bgColor);
               border-radius: 4px;
              color: white;
            }
          }
          .oa-title{
            display: flex;
            width:400px;
            justify-content: space-around;
            align-items: center;
            span{
            //   width: 28px;
              height: 28px;
              line-height: 28px;
            //   background: #F9F8F8;
            //   border-radius: 4px;
            //   display: flex;
            //   align-items: center;
            //   justify-content: center;
            //   cursor: pointer;
            }
            .left-o:hover{
              background:  var(--bgColor);
              color: white;
            }
             .right-o:hover{
              background:  var(--bgColor);
              color: white;
            }
             .center-o:hover{
              background:  var(--bgColor);
              color: white;
            }
            .toDay{
               width: 76px;
               height: 28px;
               background: #F9F8F8;
               border-radius: 4px;
               display: flex;
               align-items: center;
               justify-content: center;
               cursor: pointer;
            }
          }
        }
        .month-box{
          width: 98%;
          position: relative;
          margin: 0 auto;
          overflow: hidden;
         // border: 1px solid #EBEBEB;
         // border-radius: 8px;
          .month-week{
            width: 100%;
            height: 48px;
            background: #F2F5FB;
            border: 1px solid #EBEBEB;
            border-bottom: none;
            display: flex;
            font-size: 14px;
            font-weight: bold;
            div{
              height: 100%;
              cursor: pointer;
              width: 14.28%;
              text-align: center;
              line-height: 48px;
              border-right: 1px solid #EBEBEB;
            }
            // &:last-child(){
            //   border-right: none;
            // }
          }
           .month-week:last-child{
              border-right: none;
           }
          .month-table{
            width: 100%;
            border: 1px solid #EBEBEB;
            border-bottom: none;
            border-right: none;
            border-collapse: collapse;
            tr{
              td{
                width: 14.28%;
                height: 88px;
                position: relative;
                border: none;
                padding: 0;
                .month-item{
                  width: 100%;
                  height: 100%;
                  font-size: 14px;
                  border-right: 1px solid #EBEBEB;
                  border-bottom: 1px solid #EBEBEB;
                  cursor: pointer;
                  .item-first{
                    display: flex;
                    padding: 5px 12px;
                    justify-content: space-between;
                  }
                  .item-second{
                    width: 96%;
                    text-align: center;
                    height: 20px;
                    line-height: 20px;
                    color: var(--bgColor);
                    background: rgba(47,71,126,0.06);
                    margin: 0 auto;
                    margin-top: 10px;
                  }
                }
              }
               td:hover{
                // background: rgba(47,71,126,0.06);
              }
              .monthChose{
                 background: rgba(47,71,126,0.06);
                 color:var(--bgColor);
                }
            }
          }
        }
        .content-box{
          width: 100%;
          height: calc(100% - 70px);
          position: relative;
          margin: 0 auto;
          border: 1px solid #EBEBEB;
          border-left: none;
          border-right: none;
          overflow-y: hidden;
          .shu{
            position: absolute;
            width:1px;
            height: 10px;
            left: 59.5px;
            top: 0;
            background: #EBEBEB;
          }
        }
        .content-box::-webkit-scrollbar {
        display: none;
        }
    }
}
    .weekData {
      color: rgba(0, 0, 0, 0.45);
      opacity: 0.6;
    }
    .festivalData {
      color: rgba(240, 36, 36, 0.45);
    }
@media screen and (max-width: 1600px) {
  .gray-line{
          position: absolute;
          z-index: 100;
          width: 10px;
          height: 100%;
          background: #f2f6f9;
          top: 0;
          right: -10px;
        }
}

// @media screen and (min-width: 1920px) {
//   .gray-line{
//           position: absolute;
//           z-index: 100;
//           width: 10px;
//           height: calc(100vh - 132px);
//           background: #f2f6f9;
//           top: 0;
//           left: -10px;
//         }
// }
@media screen and (min-width: 1600px) {
  .calendar-detail {
    width: 100%;
    //height: calc(100vh - 130px);
    // min-height: 591.6px;
    margin:  0 auto;
     display: flex;
     .left-content{
        background: white;
        width: 100%;
        height: 100%;
        position: relative;
        .gray-line{
          position: absolute;
          z-index: 100;
          width: 10px;
          height: 100%;
          background: #f2f6f9;
          top: 0;
          right: -10px;
        }
        .left-top{
          margin: 0 auto;
          width: 98%;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .time-title{
            display: flex;
            position: relative;
            span{
              height: 26px;
              width: 83px;
              text-align: center;
              cursor: pointer;
              line-height: 26px;
              background: #F9F8F8;
              color: #24292B;
            }
            .spanActive{
               background:  var(--bgColor);
               border-radius: 4px;
              color: white;
            }
          }
          .oa-title{
            display: flex;
            width: 400px;
            justify-content: space-around;
            .tab{
                display: inline-block;
                display: flex;
                align-items: center;
                justify-content: center;
                span{
                    background: #ffffff;
                    direction: ltr;
                    display: inline-block;
                    width: 60px;
                    height: 28px;
                    line-height: 28px;
                }
            }
            span{
              width: 28px;
              height: 28px;
              background: #F9F8F8;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
            .left-o:hover{
              background: var(--bgColor);
              color: white;
            }
             .right-o:hover{
              background: var(--bgColor);
              color: white;
            }
             .center-o:hover{
              background: var(--bgColor);
              color: white;
            }
            .toDay{
               width: 76px;
               height: 28px;
               background: #F9F8F8;
               border-radius: 4px;
               display: flex;
               align-items: center;
               justify-content: center;
               cursor: pointer;
            }
          }
        }
        .month-box{
          width: 98%;
          position: relative;
          margin: 0 auto;
          overflow: hidden;
         // border: 1px solid #EBEBEB;
         // border-radius: 8px;
          .month-week{
            width: 100%;
            height: 48px;
            background: #F2F5FB;

            border: 1px solid #EBEBEB;
            border-bottom: none;
            display: flex;
            font-size: 16px;
            font-weight: bold;
            div{
              height: 100%;
              cursor: pointer;
              width: 14.28%;
              text-align: center;
              line-height: 48px;
              border-right: 1px solid #EBEBEB;
            }
          }
           .month-week:last-child{
              border-right: none;
           }
          .month-table{
            width: 100%;
            border: 1px solid #EBEBEB;
            border-bottom: none;
            border-right: none;
            border-collapse: collapse;
            tr{
              td{
                width: 14.28%;
                height: 140px;
                position: relative;
                border: none;
                padding: 0;
                .month-item{
                  width: 100%;
                  height: 100%;
                  font-size: 16px;
                  border-right: 1px solid #EBEBEB;
                  border-bottom: 1px solid #EBEBEB;
                  cursor: pointer;
                  .item-first{
                    display: flex;
                    padding: 5px 12px;
                    justify-content: space-between;
                  }
                  .item-second{
                    width: 96%;
                    text-align: center;
                    height: 26px;
                    line-height: 26px;
                    color: var(--bgColor);
                    background: rgba(47,71,126,0.06);
                    margin: 0 auto;
                    margin-top: 10px;
                  }
                }
              }
              td:hover{
                // background: rgba(47,71,126,0.06);
              }
              .monthChose{
                 background: rgba(47,71,126,0.06);
                 color:var(--bgColor);
                }
            }
          }
        }
        .content-box{
          width: 100%;
          height: calc(100% - 70px);
          position: relative;
          margin: 0 auto;
          border: 1px solid #EBEBEB;
          border-left: none;
          border-right: none;
          border-bottom: none;
          overflow-y: hidden;
          .shu{
            position: absolute;
            width:1px;
            height: 10px;
            left: 59.5px;
            top: 0;
            background: #EBEBEB;
          }
        }
        .content-box::-webkit-scrollbar {
        display: none;
        }
    }
  }
     .wnl{
        padding: 0 15px;

        .title{
          height: 48px;
          font-size: 16px;
          line-height: 48px;
        }
        .wnl-content{
          height: 42px;
          background: #F9F8F8;
          border-radius: 4px;
          font-size: 14px;
          padding-left: 18px;
          line-height: 42px;
        }
    }
}
</style>
