<template>
  <el-link :underline="false" style="padding: 0 15px 0px 30px;">
    <el-popover
      :key="index"
      v-model="visible"
      placement="bottom"
      :width="proWidth"
      trigger="manual"
      :popper-class="isToggleClick?'allAppPro':'allAppProElse'"
    >
      <div style="width:100%;height:346px;overflow:hidden">
        <appPopover @closePro="closeP" />
      </div>
      <svg-icon slot="reference" class="spanAll" icon-class="jiugongge" @mouseenter="showPro" />
    <!-- <div slot="reference" class="spanAll" @mouseenter="showPro">全部应用</div> -->
    </el-popover>
  </el-link>
</template>
<script>
import appPopover from '../../../components/popover/appPopover.vue'
export default {
    components: {
        appPopover
    },
    props: {

    },
    data() {
        return {
            proWidth: 416,
            allAppTime: '',
            visible: false,
            proTimer: '',
            isToggleClick: true
        }
    },
    computed: {

    },
    watch: {
        opened() {
            return this.$store.getters.sidebar.opened
        }
    },
    created() {
        this.$bus.on('theSiderShow', this.theSiderShow)
        this.$bus.on('theSiderHide', this.theSiderHide)
    },
    beforeDestroy() {
        // window.removeEventListener('resize', this.getWidth)
        this.$bus.off('theSiderShow', this.theSiderShow)
        this.$bus.off('theSiderHide', this.theSiderHide)
        document.removeEventListener('mousemove', this.leave)
    },
    mounted() {
        document.addEventListener('mousemove', this.leave)
    },
    methods: {
        theSiderShow() {
            setTimeout(() => {
                this.isToggleClick = this.$store.getters.sidebar.opened
            }, 300)
        },
        theSiderHide() {
            this.isToggleClick = false
        },
        leave(event) {
            clearTimeout(this.proTimer)
            this.proTimer = setTimeout(() => {
                // 获取鼠标的位置
                var mouseX = event.pageX
                var mouseY = event.pageY
                // 获取div的位置和尺寸
                if (document.querySelector('.spanAll')) {
                    var div1Rect = document.querySelector('.spanAll').getBoundingClientRect()
                }
                if (document.querySelector('.top_app_popover')) {
                    var div2Rect = document.querySelector('.top_app_popover').getBoundingClientRect()
                }
                // 判断鼠标是否在div之外
                if ((mouseX < div1Rect.left || mouseX > div1Rect.right || mouseY < div1Rect.top || mouseY > div1Rect.bottom) && (mouseX < div2Rect.left || mouseX > div2Rect.right ||
      mouseY < div2Rect.top - 21 || mouseY > div2Rect.bottom)) {
                    this.visible = false
                }
            }, 100)
        },
        closeP() {
            this.visible = false
        },
        showPro() {
            this.visible = true
        },
        getWidth() {
            clearTimeout(this.allAppTime)
            this.allAppTime = setTimeout(() => {
                this.proWidth = document.getElementById('wapp').clientWidth - 15
            }, 500)
        }
    }
}
</script>
<style>
.allAppProElse{
     line-height: 1.1;
     margin-top: 20px !important;
}
.allAppPro{
    line-height: 1.1;
    margin-top: 20px !important;
    margin-left: 215px;

}
 .allAppPro .popper__arrow{
    display: none;
    }
</style>
<style scoped lang="scss">
  .spanAll{
            font-size: 20px;
            color:var(--textColor);
            cursor: pointer;
            vertical-align: middle !important;

    }
</style>
