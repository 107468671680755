<script>
export default {
    name: 'MenuItem',
    functional: true,
    props: {
        icon: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        level: {
            type: Number,
            default: -1
        }
    },
    render(h, context) {
        const { icon, title, level } = context.props
        const vnodes = []

        if (icon) {
            if (icon.indexOf('.svg') !== -1) {
                const svgicon = icon.substring(0, icon.lastIndexOf('.svg'))
                vnodes.push(<svg-icon class={'menu-icon-' + level} icon-class={svgicon}/>)
            } else if (icon.indexOf('udm_') === 0 && icon.indexOf('.svg') === -1) {
                // 用户新配置的自定义模块，使用默认图标
                vnodes.push(<svg-icon class={'menu-icon-' + level} icon-class='XTGL_XTRZ'/>)
            } else {
                const elicon = 'el-icon-' + icon
                vnodes.push(<i class={elicon + ' ' + 'menu-icon-' + level} />)
            }
        }

        if (title) {
            if (level > 3) {
                vnodes.push(<span slot='title' class={'menu-level-' + level}><span class='prefix'/>{(title)}</span>)
            } else {
                vnodes.push(<span slot='title' class={'menu-level-' + level}>{(title)}</span>)
            }
        }
        return vnodes
    }
}
</script>
