<template>
  <div class="search_out">
    <div v-if="treeNode!==null">
      <span v-if="treeNode.title">{{ treeNode.title }}：</span>
      <div class="select_input" style="float:left;width:230px;position:relative">
        <i v-if="JSON.stringify(node)!=='{}'" class="custom_input_icon el-input__icon el-icon-close" @click="clearInput('node')" />
        <el-input v-model="node.name" v-select-dialog="'treeNode'" :clearable="true" node-level="3" dialog-title="选择区域" readonly placeholder="请选择区域" name="treeNode" @clear="clearInput('node')" />
      </div>
    </div>
    <div v-if="rootOrg!==null">
      <div v-if="!rootOrg.parentType||(rootOrg.parentType&&searchinput[rootOrg.parentType]!==null&&JSON.stringify(searchinput[rootOrg.parentType])!=='{}')">
        <span v-if="rootOrg.title">{{ rootOrg.title }}：</span>
        <div class="select_input" style="float:left;width:230px;position:relative">
          <i v-if="JSON.stringify(rootorg)!=='{}'" class="custom_input_icon el-input__icon el-icon-close" @click="clearInput('rootorg')" />
          <el-input v-if="rootOrg.parentType" v-model="rootorg.name" v-select-dialog="'rootorg'" :clearable="true" readonly dialog-title="选择机构" :node-id="node.id" placeholder="请选择机构" name="orgName" @clear="clearInput('rootorg')" />
          <el-input v-else v-model="rootorg.name" v-select-dialog="'rootorg'" :clearable="true" readonly dialog-title="选择机构" placeholder="请选择机构" name="orgName" @clear="clearInput('rootorg')" />
        </div>
      </div>
    </div>
    <div v-if="subOrg!==null">
      <div v-if="!subOrg.parentType||(subOrg.parentType&&searchinput[subOrg.parentType]!==null&&JSON.stringify(searchinput[subOrg.parentType])!=='{}')">
        <span v-if="subOrg.title">{{ subOrg.title }}：</span>
        <div class="select_input" style="float:left;width:200px;position:relative">
          <i v-if="JSON.stringify(suborg)!=='{}'&&isKQ" class="custom_input_icon el-input__icon el-icon-close" @click="clearInput('suborg')" />
          <el-input v-if="isKQ" v-model="suborg.name" v-select-dialog="'suborg'" :clearable="true" readonly org-level="" multiple="false" dialog-title="选择科室" :root-org-code="orgCode" placeholder="请选择科室" name="orgCode" @clear="clearInput('suborg')" />
          <el-input v-if="isKS" v-model="suborg.name" v-select-dialog="'suborg'" :clearable="false" readonly disabled org-level="" multiple="false" dialog-title="选择科室" :root-org-code="orgCode" placeholder="请选择科室" name="orgCode" />
        </div>
      </div>
    </div>
    <div v-if="orgStaff!==null">
      <div v-if="(!orgStaff.parentType||(orgStaff.parentType&&searchinput[orgStaff.parentType]!==null&&JSON.stringify(searchinput[orgStaff.parentType])!=='{}')&&isKQ)">
        <span v-if="orgStaff.title">{{ orgStaff.title }}：</span>
        <div class="select_input" style="float:left;width:200px;position:relative">
          <i v-if="JSON.stringify(orgStaff)!=='{}'" class="custom_input_icon el-input__icon el-icon-close" @click="clearInput('orgStaff')" />
          <el-input
            v-if="orgStaff.parentType"
            v-model="orgstaff.name"
            v-select-dialog="'staff'"
            :clearable="true"
            readonly
            dialog-title="选择人员"
            :org-id="suborg.id"
            :root-org-code="orgCode"
            placeholder="请选择人员"
            name="orgStaff"
            @clear="clearInput('orgstaff')"
          />
          <el-input v-else v-model="orgstaff.name" v-select-dialog="'staff'" :clearable="true" readonly dialog-title="选择人员" :root-org-code="orgCode" placeholder="请选择人员" name="orgStaff" @clear="clearInput('orgstaff')" />
        </div>
      </div>
    </div>
    <div v-if="date!==null">
      <span v-if="date.title">{{ date.title }}：</span>
      <el-date-picker
        v-model="dateValue"
        :type="date.dateType"
        :format="date.dateFormat"
        :value-format="date.dateFormat"
        :editable="false"
        placeholder="选择时间"
      />
    </div>
    <div v-if="range!==null">
      <span v-if="range.title">{{ range.title }}：</span>
      <el-date-picker
        v-model="dateRangeValue"
        :type="range.rangeType"
        :range-separator="range.rangeSeparator"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        :format="range.dateFormat"
        :editable="false"
        :value-format="range.dateFormat"
      />
    </div>
    <div>
      <el-button type="primary" @click="statisticsClick">查询</el-button>
    </div>
  </div>
</template>

<script>
const time = new Date()
// const moment = require('moment')

export default {
    components: {

    },
    props: {
        searchType: { type: Array, default: function() { return [] } },
        searchValue: { type: Object, default: function() { return {} } }
    },
    data() {
        return {
            isKQ: false,
            isKS: false,
            loginUser: this.$store.getters.loginUser,
            dateValue: new Date(), // 时间值
            dateRangeValue: [], // 时间范围值
            rootorg: {}, // 选择的机构对象
            suborg: {}, // 选择的部门对象
            orgCode: '', // 机构代码
            node: {}, // 选择的节点对象
            orgstaff: {}// 选择人员对象
        }
    },
    computed: {
        treeNode() {
            const obj = this.searchType.filter(item => item.type === 'treeNode')
            return obj.length > 0 ? obj[0] : null
        },
        rootOrg() {
            const obj = this.searchType.filter(item => item.type === 'rootOrg')
            return obj.length > 0 ? obj[0] : null
        },
        subOrg() {
            const obj = this.searchType.filter(item => item.type === 'subOrg')
            return obj.length > 0 ? obj[0] : null
        },
        orgStaff() {
            const obj = this.searchType.filter(item => item.type === 'orgStaff')
            return obj.length > 0 ? obj[0] : null
        },
        date() {
            const obj = this.searchType.filter(item => item.type === 'date')
            return obj.length > 0 ? obj[0] : null
        },
        range() {
            const obj = this.searchType.filter(item => item.type === 'range')
            return obj.length > 0 ? obj[0] : null
        }
    },
    watch: {
        searchValue: {
            handler(nval) {
                this.initSelectinputValue()
            },
            deep: true
        },
        node: {
            handler(nval) {
                this.searchinput.treeNode = {}
                this.$nextTick(() => {
                    this.searchinput.treeNode = !nval.name ? {} : nval
                    this.searchinput.rootOrg = {}
                    this.rootorg = {}
                    this.searchinput.subOrg = {}
                    this.suborg = {}
                    this.orgstaff = {}
                    this.$forceUpdate()
                })
            },
            deep: true
        },
        rootorg: {
            handler(nval) {
                this.searchinput.rootOrg = {}
                this.$nextTick(() => {
                    this.searchinput.rootOrg = !nval.name ? {} : nval
                    this.searchinput.subOrg = {}
                    this.suborg = {}
                    this.orgstaff = {}
                    this.$forceUpdate()
                })
            },
            deep: true
        },
        suborg: {
            handler(nval) {
                this.searchinput.subOrg = {}
                this.$nextTick(() => {
                    this.searchinput.subOrg = !nval.name ? {} : nval
                    this.orgstaff = {}
                    this.$forceUpdate()
                })
            },
            deep: true
        },
        orgstaff: {
            handler(nval) {
                this.searchinput.orgStaff = {}
                this.$nextTick(() => {
                    this.searchinput.orgStaff = !nval.name ? {} : nval
                    this.$forceUpdate()
                })
            },
            deep: true
        }
    },
    created() {
        if (this.$store.state.user.roles.includes('kqManager')) {
            this.isKQ = true
        } else if (this.$store.state.user.roles.includes('ksManager')) {
            this.isKS = true
        }
        console.log(this.isKQ, 'this.isKQ', this.isKS, 'this.isKS')

        this.searchinput = {
            treeNode: JSON.parse(JSON.stringify(this.node)),
            rootOrg: JSON.parse(JSON.stringify(this.rootorg)),
            subOrg: JSON.parse(JSON.stringify(this.suborg))
        }
        const year = time.getFullYear()
        const month = time.getMonth() + 1
        if (time.getDate() < 15) {
            const m = month === 1 ? 12 : month - 1
            const y = month === 1 ? year - 1 : year
            const ed = new Date(y, m, 0).getDate()

            const start = new Date(y, m - 1, 1)
            const end = new Date(y, m - 1, ed)

            this.dateRangeValue = [start, end]
        } else {
            this.dateRangeValue = [new Date(year, month - 1, 1), new Date()]
        }
        this.initSelectinputValue()
    },
    beforeDestroy() {

    },
    mounted() {
        this.statisticsClick()
    },
    methods: {
        initSelectinputValue() {
            // 初始化选择节点默认值
            if (this.treeNode !== null && this.searchValue.treeNode && JSON.stringify(this.searchValue.treeNode) !== '{}') {
                this.node = this.searchValue.treeNode
            } else if (this.treeNode !== null) {
                this.node = this.node || this.node
            }
            // 初始化选择机构默认值
            if (this.rootOrg !== null && this.searchValue.rootOrg && JSON.stringify(this.searchValue.rootOrg) !== '{}') {
                this.rootorg = this.searchValue.rootOrg
                this.orgCode = this.rootorg.ocode
            } else if (this.rootOrg !== null) {
                this.rootorg = JSON.stringify(this.rootorg) === '{}' ? (this.loginUser.orgCode !== 'plat' ? { id: this.loginUser.staffList[0].orgFullId.split('-')[0], ocode: this.loginUser.orgCode, name: this.loginUser.orgName } : {}) : this.rootorg
                this.orgCode = this.loginUser.orgCode !== 'plat' ? this.loginUser.orgCode : (this.orgCode || '')
            }
            // 初始化选择部门默认值
            if (this.subOrg !== null && this.searchValue.subOrg && JSON.stringify(this.searchValue.subOrg) !== '{}') {
                this.suborg = this.searchValue.subOrg
                this.orgCode = this.orgCode || this.suborg.orgCode
            } else if (this.subOrg !== null) {
                this.orgCode = this.loginUser.orgCode !== 'plat' ? this.loginUser.orgCode : (this.orgCode || '')
                const orgIds = this.loginUser.staffList[0].orgFullId.split('-')
                const orgNames = this.loginUser.staffList[0].orgFullName.split('-')
                this.suborg = JSON.stringify(this.suborg) === '{}' ? (this.loginUser.orgCode !== 'plat' ? { id: orgIds[orgIds.length - 1], ocode: this.loginUser.orgCode, name: orgNames[orgNames.length - 1] } : {}) : this.suborg
            }
            // 初始化选择人员默认值
            if (this.orgStaff !== null && this.searchValue.orgStaff && JSON.stringify(this.searchValue.orgStaff) !== '{}') {
                this.orgstaff = this.searchValue.orgStaff
                this.orgCode = this.orgCode || this.orgstaff.orgCode
            } else if (this.orgStaff !== null) {
                this.orgCode = this.loginUser.orgCode !== 'plat' ? this.loginUser.orgCode : (this.orgCode || '')
                this.orgstaff = JSON.stringify(this.orgstaff) === '{}' ? (this.loginUser.orgCode !== 'plat' ? { personId: this.loginUser.personId, personName: this.loginUser.personName } : {}) : this.orgstaff
            }
        },
        // 选择机构
        onSelectRootorgCompleted(selectedNodes, attr) {
            if (selectedNodes.length > 0) {
                this.rootorg = selectedNodes[0]
                this.orgCode = selectedNodes[0].ocode
            } else {
                this.rootorg = {}
                this.orgCode = ''
            }
        },
        // 选择部门
        onSelectSuborgCompleted(selectedNodes, attr) {
            if (selectedNodes.length > 0) {
                this.suborg = selectedNodes[0]
            } else {
                this.suborgName = ''
                this.suborg = {}
            }
        },
        // 选择树节点
        onSelectTreeNodeCompleted(selectedNodes, attr) {
            if (selectedNodes.length > 0) {
                this.node = selectedNodes[0]
            } else {
                this.node = {}
            }
        },
        // 选择人员
        onSelectStaffCompleted(selectedNodes, attr) {
            if (selectedNodes.length > 0) {
                this.orgstaff = selectedNodes[0]
            } else {
                this.orgstaff = {}
            }
        },
        // 统计
        statisticsClick() {
            const value = {}
            if (this.date !== null)value.date = this.dateValue || {}
            if (this.range !== null)value.range = this.dateRangeValue || {}
            if (this.treeNode !== null)value.treeNode = this.node || {}
            if (this.rootOrg !== null)value.rootOrg = this.rootorg || {}
            if (this.subOrg !== null)value.subOrg = this.suborg || {}
            if (this.orgStaff !== null)value.orgStaff = this.orgstaff || {}
            this.$emit('statisticsClick', value)
        },
        clearInput(type) {
            this[type] = {}
        }
    }
}
</script>

  <style scoped lang="scss">
  .custom_input_icon{
      position: absolute;
      top: 0;
      right: 43px;
      z-index: 1;
      font-size:13px;
      cursor:pointer;
      display: none;
  }
  .select_input:hover{
      .custom_input_icon{display: inline-block;}
  }
  .search_out{
    display:inline-block;
    div{
      float: left;
      span{
        float: left;
        line-height: 36px;
        font-size:14px ;
      }
    }
    div:not(:last-child){margin-right:15px;}
    /deep/ .el-range-separator{width: 30px;}
  }
  </style>

