<template>
  <div style="height: 350px;margin:10px 0 0;">
    <echart-columnar
      ref="columnar"
      :x-data="xdata"
      :y-data="ydata"
      :y-unit="yunit"
      :x-unit="xUnit"
      :bar-gap="0"
      :x-label-rotate="45"
      left="5%"
      right="3%"
      :tooltip-format="tooltipformat"
      :echarts-click="echartsClick"
    />
    <!-- <div v-else style="text-align:center;">
      <img src="images/w-imgs/nomessage.png"><br>
      <span style="font-size:15px;color:#dbdbdb">暂无数据</span>
    </div> -->
  </div>
</template>

<script>
import { EchartColumnar } from '@/wapp/common/components/Echarts/index'
export default {
    components: {
        EchartColumnar
    },
    props: {
    },
    data() {
        return {
            loginUser: this.$store.getters.loginUser,
            xdata: [],
            ydata: [],
            yunit: { name: '次数', align: 'end', size: '13', minInterval: 1 },
            xUnit: { name: '部门', align: 'end', 'size': '12' },
            ids: []
        }
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {
        setTimeout(() => {
            this.getData()
        }, 50)
    },
    methods: {
        // 获取数据，部门日均工作时长统计
        getData() {
            const datatype = this.$vnode.context.dataType
            this.$http({
                url: 'yykq/homePageIndex/deptWorkSituation',
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                params: {
                    startTime: this.$vnode.context.chooseDate[0],
                    endTime: this.$vnode.context.chooseDate[1],
                    orgCode: this.$vnode.context.orgCode,
                    type: datatype
                }
            }).then(res => {
                if (res.code === 'success') {
                    const workconditiondata = res.body.listdata.list
                    const datatype = this.$vnode.context.dataType
                    const workconditionyid = []
                    const workconditionx = []
                    const workconditiony = []

                    workconditiondata.forEach(function(v, i) {
                        workconditionyid.push(v.DEPARTMENT_ID)
                        workconditionx.push(v.DEPARTMENT)
                        workconditiony.push(datatype === '1' ? v.overNums : (datatype === '2' ? v.lateNums : v.earlyNums))
                    })
                    this.xdata = workconditionx
                    this.ydata = [workconditiony]
                    this.ids = workconditionyid
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        tooltipformat(param) {
            const datatype = this.$vnode.context.dataType
            let tip = param[0].name
            param.forEach(function(val) {
                tip += '<br/>' + (datatype === '1' ? '加班：' : (datatype === '2' ? '迟到：' : '早退：')) + val.value + '次'
            })
            return tip
        },
        echartsClick(column) {
            const id = this.ids[column.dataIndex]
            this.$emit('echartsClick', id)
        }
    }
}
</script>

<style scoped lang="scss">
</style>
