<template>
  <div>
    <el-button @click="back">返回</el-button>
  </div>
</template>

<script>
export default {
    components: {

    },
    props: {

    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
        back() {
            this.$emit('showInfo', false)
        }
    }
}
</script>

<style scoped lang="scss">

</style>
