import { calendar } from './calendarUtil'
export default {
    weekDataOne: ['日', '一', '二', '三', '四', '五', '六'],
    weekData: ['一', '二', '三', '四', '五', '六', '日'],
    getNowMonthWeekDay(year, month) { // 获取该月份第一天是周几
        const date = new Date(year, month - 1, 1)
        return date.getDay()
    },
    getNowMonthDay(year, month) { // 获取本月份最后一天的日期
        const date = new Date(year, month, 0)
        return date.getDate()
    },
    getLastMonthRestDay(year, month) { // 获取上个月要在本月日期展示的天数数组
        let week = this.getNowMonthWeekDay(year, month) // 获取该月份第一天是周几
        if (week == 0) {
            week = 7
        }
        let days = this.getNowMonthDay(year, month - 1) // 获取上月份最后一天日期
        const array = []
        if (month == '01') {
            month = 12
            --year
            while (array.length < week - 1) {
                array.unshift({
                    date: days,
                    name: 'lastMonth',
                    festival: calendar.solar2lunar(year, month, days).festival, // calendar.solar2lunar()农历节假日接口
                    IDayCn: calendar.solar2lunar(year, month, days).IDayCn,
                    IMonthCn: calendar.solar2lunar(year, month, days).IMonthCn,
                    cMonth: calendar.solar2lunar(year, month, days).cMonth,
                    Term: calendar.solar2lunar(year, month, days).Term,
                    week: this.weekDataOne[new Date(year, month - 1, days).getDay()], // 对应日期转换为周几格式
                    data: year + '-' + month + '-' + days
                })
                days--
            }
        } else {
            while (array.length < week - 1) {
                array.unshift({
                    date: days,
                    name: 'lastMonth',
                    festival: calendar.solar2lunar(year, month - 1, days).festival, // calendar.solar2lunar()农历节假日接口
                    IDayCn: calendar.solar2lunar(year, month - 1, days).IDayCn,
                    IMonthCn: calendar.solar2lunar(year, month - 1, days).IMonthCn,
                    Term: calendar.solar2lunar(year, month - 1, days).Term,
                    cMonth: calendar.solar2lunar(year, month - 1, days).cMonth,
                    week: this.weekDataOne[new Date(year, month - 2, days).getDay()], // 对应日期转换为周几格式
                    data: year + '-' + ((month - 1) < 10 ? '0' + (month - 1) : (month - 1)) + '-' + days
                })
                days--
            }
        }
        return array // 结果类似=>[28,29,30,31]
    },
    getNextMonthRestDay(year, month) { // 获取下个月要在本月日期展示的天数数组
        const lastDayNum = this.getLastMonthRestDay(year, month).length // 获取到上个月展示的长度
        const nowDayNum = this.getNowMonthDay(year, month) // 获取本月最后一天日期  当作本月长度
        const nextDayNum = 35 - (lastDayNum + nowDayNum) // 下个月在本月展示的长度
        const array = []
        if (month == 12) {
            month = '1'
            year++
            for (var i = 1; i <= nextDayNum; i++) {
                array.push({
                    date: i < 10 ? '0' + i : i,
                    name: 'nextMonth',
                    festival: calendar.solar2lunar(year, month - 1, i).festival,
                    IDayCn: calendar.solar2lunar(year, month - 1, i).IDayCn,
                    IMonthCn: calendar.solar2lunar(year, month - 1, i).IMonthCn,
                    Term: calendar.solar2lunar(year, month - 1, i).Term,
                    cMonth: calendar.solar2lunar(year, month - 1, i).cMonth,
                    week: this.weekDataOne[new Date(year, month, i).getDay()],
                    data: year + '-' + (Number(month) < 10 ? '0' + Number(month) : Number(month)) + '-' + (i < 10 ? '0' + i : i)
                })
            }
        } else {
            for (var j = 1; j <= nextDayNum; j++) {
                array.push({
                    date: j < 10 ? '0' + j : j,
                    name: 'nextMonth',
                    festival: calendar.solar2lunar(year, Number(month) + 1, j).festival,
                    IDayCn: calendar.solar2lunar(year, Number(month) + 1, j).IDayCn,
                    IMonthCn: calendar.solar2lunar(year, Number(month) + 1, j).IMonthCn,
                    Term: calendar.solar2lunar(year, Number(month) + 1, j).Term,
                    cMonth: calendar.solar2lunar(year, Number(month) + 1, j).cMonth,
                    week: this.weekDataOne[new Date(year, month, j).getDay()],
                    data: year + '-' + ((Number(month) + 1) < 10 ? '0' + (Number(month) + 1) : (Number(month) + 1)) + '-' + (j < 10 ? '0' + j : j)
                })
            }
        }

        return array// 结果类似=>[1,2,3,4,5,6]
    },
    createNowMonthDate(year, month) { // 获取本月展示的数据
        const lastData = this.getLastMonthRestDay(year, month) // 获取上个月在本月展示的数组  =>[28,29,30,31]
        const nextData = this.getNextMonthRestDay(year, month) // 获取下个月在本月展示的数组=>[1,2,3,4,5,6]
        const nowData = []
        for (var i = 1; i <= this.getNowMonthDay(year, month); i++) { // 遍历出本月数据  =>[1,2,3........28,29,30]
            nowData.push({
                date: i < 10 ? '0' + i : i,
                name: 'nowMonth',
                festival: calendar.solar2lunar(year, month, i).festival,
                IDayCn: calendar.solar2lunar(year, month, i).IDayCn,
                IMonthCn: calendar.solar2lunar(year, month, i).IMonthCn,
                Term: calendar.solar2lunar(year, month, i).Term,
                cMonth: calendar.solar2lunar(year, month, i).cMonth,
                week: this.weekDataOne[new Date(year, month - 1, i).getDay()],
                data: year + '-' + month + '-' + (i < 10 ? '0' + i : i)
            }
            )
        }
        const NowMonthDate = [...lastData, ...nowData, ...nextData]
        return NowMonthDate // 结果类似=>[28,29,30,31,1,2,3........28,29,30,1,2,3,4,5,6]
    },
    getLastWeekDay(val) {
        const nowWeekDay = new Date(val)
        const day = nowWeekDay.getDay() // 本周第几天
        const lastWeekFirstDay = new Date(nowWeekDay.getTime() - (day * 24 * 60 * 60 * 1000) - (7 * 24 * 60 * 60 * 1000)) // 上周第一天日期
        const lastWeekArray = []
        for (var i = 0; i < 7; i++) {
            const t = new Date(lastWeekFirstDay.getTime() + (i * 24 * 60 * 60 * 1000))
            lastWeekArray.push({
                date: t.getDate() < 10 ? '0' + t.getDate() : t.getDate(),
                name: 'week',
                week: this.weekDataOne[t.getDay()],
                data: t.getFullYear() + '-' + ((t.getMonth() + 1) < 10 ? '0' + (t.getMonth() + 1) : (t.getMonth() + 1)) + '-' + (t.getDate() < 10 ? '0' + t.getDate() : t.getDate())
            })
        }
        const newDay = new Date(nowWeekDay.getTime() - (7 * 24 * 60 * 60 * 1000))
        return {
            lastWeekArray,
            newDay: newDay.getFullYear() + '-' + ((newDay.getMonth() + 1) < 10 ? '0' + (newDay.getMonth() + 1) : (newDay.getMonth() + 1)) + '-' + (newDay.getDate() < 10 ? '0' + newDay.getDate() : newDay.getDate())
        }
    },
    getNextWeekDay(val) {
        const nowWeekDay = new Date(val)
        const day = nowWeekDay.getDay() // 本周第几天
        const nextWeekFirstDay = new Date(nowWeekDay.getTime() - (day * 24 * 60 * 60 * 1000) + (7 * 24 * 60 * 60 * 1000)) // 上周第一天日期
        const nextWeekArray = []
        for (var i = 0; i < 7; i++) {
            const t = new Date(nextWeekFirstDay.getTime() + (i * 24 * 60 * 60 * 1000))
            nextWeekArray.push({
                date: t.getDate() < 10 ? '0' + t.getDate() : t.getDate(),
                name: 'week',
                week: this.weekDataOne[t.getDay()],
                data: t.getFullYear() + '-' + ((t.getMonth() + 1) < 10 ? '0' + (t.getMonth() + 1) : (t.getMonth() + 1)) + '-' + (t.getDate() < 10 ? '0' + t.getDate() : t.getDate())
            })
        }
        const newDay = new Date(nowWeekDay.getTime() + (7 * 24 * 60 * 60 * 1000))
        return {
            nextWeekArray,
            newDay: newDay.getFullYear() + '-' + ((newDay.getMonth() + 1) < 10 ? '0' + (newDay.getMonth() + 1) : (newDay.getMonth() + 1)) + '-' + (newDay.getDate() < 10 ? '0' + newDay.getDate() : newDay.getDate())
        }
    },
    getNowWeekDay(val) {
        const nowWeekDay = new Date(val)
        const day = nowWeekDay.getDay() // 本周第几天
        const nowWeekFirstDay = new Date(nowWeekDay.getTime() - (day * 24 * 60 * 60 * 1000)) // 本周第一天日期
        const nowWeekArray = []
        for (var i = 0; i < 7; i++) {
            const t = new Date(nowWeekFirstDay.getTime() + (i * 24 * 60 * 60 * 1000))
            nowWeekArray.push({
                date: t.getDate() < 10 ? '0' + t.getDate() : t.getDate(),
                name: 'week',
                week: this.weekDataOne[t.getDay()],
                data: t.getFullYear() + '-' + ((t.getMonth() + 1) < 10 ? '0' + (t.getMonth() + 1) : (t.getMonth() + 1)) + '-' + (t.getDate() < 10 ? '0' + t.getDate() : t.getDate())
            })
        }
        return nowWeekArray
    },
    getNowWeekDayA(val) {
        const nowWeekDay = new Date(val)
        let day = nowWeekDay.getDay() // 本周第几天
        if (day == 0) {
            day = 7
        }
        const nowWeekFirstDay = new Date(nowWeekDay.getTime() - (day * 24 * 60 * 60 * 1000) + (24 * 60 * 60 * 1000)) // 本周第一天日期
        const nowWeekArray = []
        for (var i = 0; i < 7; i++) {
            const t = new Date(nowWeekFirstDay.getTime() + (i * 24 * 60 * 60 * 1000))
            nowWeekArray.push({
                date: t.getDate() < 10 ? '0' + t.getDate() : t.getDate(),
                name: 'week',
                year: t.getFullYear(),
                month: ((t.getMonth() + 1) < 10 ? '0' + (t.getMonth() + 1) : (t.getMonth() + 1)),
                week: this.weekDataOne[t.getDay()],
                data: t.getFullYear() + '-' + ((t.getMonth() + 1) < 10 ? '0' + (t.getMonth() + 1) : (t.getMonth() + 1)) + '-' + (t.getDate() < 10 ? '0' + t.getDate() : t.getDate())
            })
        }
        return nowWeekArray
    },
    getNextWeekDayA(val) {
        const nowWeekDay = new Date(val)
        let day = nowWeekDay.getDay() // 本周第几天
        if (day == 0) {
            day = 7
        }
        const nextWeekFirstDay = new Date(nowWeekDay.getTime() - (day * 24 * 60 * 60 * 1000) + (8 * 24 * 60 * 60 * 1000)) // 上周第一天日期
        const nextWeekArray = []
        for (var i = 0; i < 7; i++) {
            const t = new Date(nextWeekFirstDay.getTime() + (i * 24 * 60 * 60 * 1000))
            nextWeekArray.push({
                date: t.getDate() < 10 ? '0' + t.getDate() : t.getDate(),
                name: 'week',
                year: t.getFullYear(),
                month: ((t.getMonth() + 1) < 10 ? '0' + (t.getMonth() + 1) : (t.getMonth() + 1)),
                week: this.weekDataOne[t.getDay()],
                data: t.getFullYear() + '-' + ((t.getMonth() + 1) < 10 ? '0' + (t.getMonth() + 1) : (t.getMonth() + 1)) + '-' + (t.getDate() < 10 ? '0' + t.getDate() : t.getDate())
            })
        }
        const newDay = new Date(nowWeekDay.getTime() + (7 * 24 * 60 * 60 * 1000))
        return {
            nextWeekArray,
            newDay: newDay.getFullYear() + '-' + ((newDay.getMonth() + 1) < 10 ? '0' + (newDay.getMonth() + 1) : (newDay.getMonth() + 1)) + '-' + (newDay.getDate() < 10 ? '0' + newDay.getDate() : newDay.getDate())
        }
    },
    getLastWeekDayA(val) {
        const nowWeekDay = new Date(val)
        let day = nowWeekDay.getDay() // 本周第几天
        if (day == 0) {
            day = 7
        }
        const lastWeekFirstDay = new Date(nowWeekDay.getTime() - (day * 24 * 60 * 60 * 1000) - (6 * 24 * 60 * 60 * 1000)) // 上周第一天日期
        const lastWeekArray = []
        for (var i = 0; i < 7; i++) {
            const t = new Date(lastWeekFirstDay.getTime() + (i * 24 * 60 * 60 * 1000))
            lastWeekArray.push({
                date: t.getDate() < 10 ? '0' + t.getDate() : t.getDate(),
                name: 'week',
                year: t.getFullYear(),
                month: ((t.getMonth() + 1) < 10 ? '0' + (t.getMonth() + 1) : (t.getMonth() + 1)),
                week: this.weekDataOne[t.getDay()],
                data: t.getFullYear() + '-' + ((t.getMonth() + 1) < 10 ? '0' + (t.getMonth() + 1) : (t.getMonth() + 1)) + '-' + (t.getDate() < 10 ? '0' + t.getDate() : t.getDate())
            })
        }
        const newDay = new Date(nowWeekDay.getTime() - (7 * 24 * 60 * 60 * 1000))
        return {
            lastWeekArray,
            newDay: newDay.getFullYear() + '-' + ((newDay.getMonth() + 1) < 10 ? '0' + (newDay.getMonth() + 1) : (newDay.getMonth() + 1)) + '-' + (newDay.getDate() < 10 ? '0' + newDay.getDate() : newDay.getDate())
        }
    },
    getWeekOnYears(val) {
        const whereWeek = new Date(val)
        const year = whereWeek.getFullYear()
        const month = whereWeek.getMonth() + 1
        const day = whereWeek.getDate()
        const isLeapYear = (year % 400 === 0) || (year % 4 === 0 && year % 100 !== 0) // 判断是否为闰年
        const second = isLeapYear ? 29 : 28
        const monthList = [31, second, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31].splice(0, month - 1) // 获取月份数组
        let currentDays // 当前日期天数
        let currentWeek // 当前周数
        // 计算天数
        currentDays = month === 1 ? day : (monthList.reduce((t, v) => {
            return t + v
        }, 0)) + day
        // 计算是第几周
        currentWeek = currentDays % 7 === 0 ? currentDays / 7 : Math.ceil(currentDays / 7)
        return currentWeek
    }
}
