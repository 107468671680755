import Vue from 'vue'
import { ValidationProvider, configure, localize, extend, localeChanged } from 'vee-validate'

import * as rules from 'vee-validate/dist/rules'
import zh_CN from 'vee-validate/dist/locale/zh_CN.json'
import en from 'vee-validate/dist/locale/en.json'
import i18n from '@/global/lang/index'

import { formItemValidate } from './index'

const moment = require('moment')

extend('phone', {
    // 验证规则
    validate: (value) => {
        return value.length === 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/.test(value)
    },
    message: '{_field_}格式无效，请输入11位手机号码'
})

extend('maxlength', {
    // 验证规则
    validate: (value, params) => {
        const maxLen = parseInt(params[0])
        return isNaN(maxLen) || value.length <= maxLen || value.length === undefined
    }
})

extend('phone_email', {
    // 验证规则
    validate: (value) => {
        return (value.length === 11 && /^((13|14|15|16|17|18)[0-9]{1}\d{8})$/.test(value)) || /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(value)
    }
})

extend('before', {
    // 验证规则
    validate: (value, params) => {
        let _value = value
        if (_value instanceof Date) {
            _value = moment(_value).format('YYYY-MM-DD HH:mm:ss')
        }
        if (_value !== null) {
            _value = _value.replace(' 00:00:00', '')
        }
        let _target = params.target
        if (_target !== undefined && _target !== null && _target !== '') {
            if (_target instanceof Date) {
                _target = moment(_target).format('YYYY-MM-DD HH:mm:ss')
            }
            _target = _target.replace(' 00:00:00', '')
            params.target = _target

            return _value <= _target
        } else {
            return true
        }
    },
    params: [
        {
            name: 'target',
            isTarget: true
        }
    ]
})

extend('after', {
    // 验证规则
    validate: (value, params) => {
        let _value = value
        if (_value instanceof Date) {
            _value = moment(_value).format('YYYY-MM-DD HH:mm:ss')
        }
        if (_value !== null) {
            _value = _value.replace(' 00:00:00', '')
        }
        let _target = params.target
        if (_target !== undefined && _target !== null && _target !== '') {
            if (_target instanceof Date) {
                _target = moment(_target).format('YYYY-MM-DD HH:mm:ss')
            }
            _target = _target.replace(' 00:00:00', '')
            params.target = _target

            return _value >= _target
        } else {
            return true
        }
    },
    params: [
        {
            name: 'target',
            isTarget: true
        }
    ]
})

extend('number', {
    // 验证规则
    validate: (value) => {
        value = Number(value)
        return /^(([^0][0-9]+|0)\.([0-9]{1,100})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,100})$)|^(([1-9]+)$)/.test(value) && value >= 0
    },
    message: '{_field_}必须大于等于0'
})

// 身份证号校验正则
extend('identity', {
    // 验证规则
    validate: (value) => {
        const idre18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
        const idre15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/
        return idre15.test(value) || idre18.test(value)
    },
    message: '{_field_}格式无效'
})

configure({
    fieldsBagName: 'fieldBags'
})
Vue.component('ValidationProvider', ValidationProvider)

localize({
    zh_CN: {
        messages: {
            alpha: '{_field_}只能包含字母字符',
            alpha_dash: '{_field_}能够包含字母数字字符、破折号和下划线',
            alpha_num: '{_field_}只能包含字母数字字符',
            alpha_spaces: '{_field_}只能包含字母字符和空格',
            between: '{_field_}必须在{min}与{max}之间',
            confirmed: '{_field_}不能和{target}匹配',
            digits: '{_field_}必须是数字，且精确到{length}位数',
            dimensions: '{_field_}必须在{width}像素与{height}像素之间',
            email: '邮箱格式无效',
            excluded: '{_field_}不是一个有效值',
            ext: '{_field_}不是一个有效的文件',
            image: '{_field_}不是一张有效的图片',
            oneOf: '{_field_}不是一个有效值',
            integer: '{_field_}必须是整数',
            length: '{_field_}长度必须为{length}',
            maxlength: '{_field_}超过最大允许长度',
            max: '{_field_}不能超过{length}个字符',
            max_value: '{_field_}必须小于或等于{max}',
            mimes: '{_field_}不是一个有效的文件类型',
            min: '{_field_}必须至少有{length}个字符',
            min_value: '{_field_}必须大于或等于{min}',
            numeric: '{_field_}只能包含数字字符',
            regex: '{_field_}格式无效',
            required: '请输入{_field_}',
            required_if: '请输入{_field_}',
            size: '{_field_}必须小于{size}KB',
            phone: '{_field_}格式无效，请输入11位手机号码',
            phone_email: '{_field_}格式无效',
            after: '{_field_}必须在{target}之后',
            before: '{_field_}必须在{target}之前',
            number: '{_field_}必须大于等于0',
            identity: '无效的{_field_}'
        }
    },
    en: {
        messages: {
            phone: '{_field_} invalid，please input valid phone number',
            phone_email: '{_field_} invalid',
            identity: '{_field_} invalid',
            after: '{_field_} must after {target}',
            before: '{_field_} must before {target}',
            number: '{_field_} must greater than or equal to 0'
        }
    }
})

Vue.prototype.loadLocalize = (lang) => {
    if (lang === 'en') {
        localize('en', en)

        for (const rule in rules) {
            extend(rule, {
                ...rules[rule], // add the rule
                message: en.messages[rule] // add its message
            })
        }
    } else {
        localize('zhCN', zh_CN)
        for (const rule in rules) {
            extend(rule, {
                ...rules[rule], // add the rule
                message: zh_CN.messages[rule] // add its message
            })
        }
    }

    localeChanged()
}

Vue.prototype.loadLocalize(i18n.locale)

Vue.prototype.formValidate = function (callback, errCallback, canEdit, editForm$el) {
    let error = ''
    let invalidFields = ''

    new Promise((resolve) => {
        if (this.actionType === 'AutoSave' && this.wfInstance.module === 'shouwen') return resolve(true)
        formItemValidate(this)

        setTimeout(() => {
            invalidFields = (editForm$el || document).querySelector('.invalid-field') || (editForm$el || document).querySelector('.van-field--error')

            if (invalidFields !== null && invalidFields.innerHTML !== '' && (this.canEdit === 1 || canEdit === 1)) {
                error = invalidFields.errorContent || invalidFields.firstElementChild.errorContent

                if (error === null || error === undefined) {
                    const errorField = invalidFields.querySelector('[placeholder]')
                    if (errorField !== null) {
                        error = errorField.getAttribute('placeholder')
                    }
                }
                if (error !== undefined && error !== null && error !== '') {
                    const lastChar = error.substring(error.length - 1)

                    if (lastChar !== '.' && lastChar !== '。' && lastChar !== '!' && lastChar !== '！') {
                        error += '！'
                    }

                    if (invalidFields.className.indexOf('el-tooltip') !== -1) {
                        this.$alert(error, this.$tx('common.operationTip', '操作提示'), { type: 'warning' })
                        invalidFields.focus()
                    } else if (invalidFields.className.indexOf('van-field') !== -1) {
                        const inputField = invalidFields.querySelector('.van-field__control')
                        if (inputField !== null) {
                            error = inputField.getAttribute('error-content') || error
                        }
                        this.$toast.fail(error)
                    }
                }

                resolve(false)
            } else {
                resolve(true)
            }
        })
    }).then((valid) => {
        if (valid) {
            callback(true)
        } else if (errCallback) {
            errCallback(error, invalidFields)
        }
    })
}
