<template>
  <div>
    <span v-if="column.field==='logonStart'">{{ row.logonStart + ' - ' + row.logonEnd }}</span>
    <span v-if="column.field==='logoffStart'">{{ row.logoffStart + ' - ' + row.logoffEnd }}</span>
    <span v-if="column.field==='color'" style="padding:2px 30px" :style="{'background':row.color}" />
  </div>
</template>

<script>
export default {
    props: {
        column: { type: Object, default: null },
        row: { type: Object, default: null },
        index: { type: Number, default: null }
    }
}
</script>

<style scoped lang="scss">

</style>
