<template>
    <div style="padding-top: 30px;padding-bottom: 20px;">
        <div class="form-header" style="text-align: center;margin-bottom:20px"><div class="form-title">开标信息</div><!----></div>
        <busi-form v-if="voYpf.id" ref="baseForm" :vo="voYpf" :can-edit="canEdit" :module-info="moduleInfo" style="width: calc(100% - 13%);margin: 0 auto;" />
    </div>
</template>

<script>
export default {
    name: 'BaseInfo',
    components: {},
    props: {
        vo: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            moduleInfo: {
                formTemplate: '#/dcontent/vuebusiform/W/tbgl.kbdj.form_xmtz.vue',
                delUrl: '/tbgl/kbdj/delete',
                addNewUrl: '/tbgl/kbdj/addNew',
                insertUrl: '/tbgl/kbdj/insert',
                moduleCode: 'kbdj',
                updateUrl: '/tbgl/kbdj/update',
                listDataUrl: '/tbgl/kbdj/listdata',
                getByIdUrl: '/tbgl/kbdj/getKbdjByProjectId'
            },
            canEdit: 0,
            voYpf: {}
        }
    },
    computed: {},
    watch: {},
    created() {
        this.getData()
        this.$options.components['BusiForm'] = this.$loadComponent(this.moduleInfo.formTemplate)
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
        getData() {
            this.$http({
                url: this.moduleInfo.getByIdUrl + '?id=' + this.vo.id

            }).then(res => {
                if (res.code === 'success') {
                    this.voYpf = res.body.vo
                }
            })
        }
    }
}
</script>

<style scoped lang="scss"></style>
