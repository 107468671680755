<template>
  <div>
    <!-- <el-carousel class="app-container" style="padding-bottom: 0;" height="30px" direction="vertical">
      <el-carousel-item v-for="item in noticeArr" :key="item">
        <h3 class="medium"> <svg-icon icon-class="notice" font-size="18px" /> {{ item.title }}</h3>
      </el-carousel-item>
    </el-carousel> -->
    <meetingReservationTable ref="leadOfficialTable" @viewObject="viewObject" @addNewForm="addNewForm" />
    <Basicform
      :title="'场馆预定'"
      :win-width="moduleInfo.winWidth"
      :win-height="moduleInfo.winHeight"
      :toolbar-btns="busiformBtns"
      :on-basic-form-before-open="onBasicFormBeforeOpen"
      :on-basic-form-opened="onBasicFormOpened"
      :on-basic-form-before-submit="onBasicFormBeforeSubmit"
      :on-basic-form-submited="onBasicFormSubmited"
    >
      <template #default="slotProps">
        <busi-form :vo="slotProps.vo" :can-edit="slotProps.canEdit" :module-info="moduleInfo" />
      </template>
    </Basicform>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import meetingReservationTable from './components/meetingReservationTable'

import { moment } from 'fullcalendar'
export default {
    components: {
        meetingReservationTable
        // DateCalendar
    },
    props: {

    },
    data() {
        return {
            moduleInfo: {
                'formTemplate': 'dcontent/busiformurl?vwtype=&url=/site/booking/form.html',
                'winWidth': 1000,
                'delUrl': '/site/booking/delete',
                'addNewUrl': '/site/booking/addNew',
                'insertUrl': '/site/booking/insert',
                'moduleCode': 'meeting',
                'winHeight': 600,
                'listDataUrl': '/site/booking/listdata',
                'getByIdUrl': '/site/booking/getById',
                'updateUrl': '/site/booking/update'
            },
            busiformBtns: [],
            rooms: {},
            cell: '',
            tankuang: [],
            dayNumber: 1,
            noticeArr: [
            ],
            columndate: '', // 临时保存点击的日期
            ddian: ''
        }
    },
    computed: {
        ...mapGetters([
            'loginUser'
        ])
    },
    watch: {

    },
    created() {
        let formurl = this.moduleInfo.formTemplate.split('url=')[1]
        formurl = formurl.replace(/\//g, '.')
        formurl = '#/dcontent/vuebusiform/W/' + formurl.substring(1).replace('.html', '.vue')
        formurl += '?sysCode=' + (this.$route.meta.sysCode || '')
        this.$options.components['BusiForm'] = this.$loadComponent(formurl)
        this.$bus.emit('showNavSearch', false)
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true)
    },
    mounted() {
        // this.SwiperGetDate(this.getThisWeek(0), this.getThisWeek(-6))
    },
    methods: {
        async addNewForm(row, column) {
            console.log('新增row', row)
            this.columndate = column.weekday
            // this.ddian = row.name
            // this.ddianId = row.id
            this.busiformBtns = [
                {
                    id: 'btn_close',
                    btnName: '关 闭',
                    btnIcon: 'close',
                    btnClick: 'closeDialog',
                    btnPermissio: '',
                    btnType: ''
                },
                {
                    id: 'btn_save',
                    btnName: '保 存',
                    btnIcon: 'check',
                    btnClick: 'submitBusiform',
                    btnPermission: 'saveObject',
                    btnType: 'primary'
                }
            ]
            await this.selectRoom(row.id)

            this.$nextTick(() => {
                this.$bus.emit('openBusiform', this.moduleInfo, null, 1)
            })
        },
        // 查看
        async viewObject(vo, type) {
            var canEdit = 0
            if (type === 'edit') {
                canEdit = 1
                this.busiformBtns = [
                    {
                        id: 'btn_close',
                        btnName: '关 闭',
                        btnIcon: 'close',
                        btnClick: 'closeDialog',
                        btnPermissio: '',
                        btnType: ''
                    },
                    {
                        id: 'btn_save',
                        btnName: '保 存',
                        btnIcon: 'check',
                        btnClick: 'submitBusiform',
                        btnPermission: 'saveObject',
                        btnType: 'primary'
                    }
                ]
            } else {
                canEdit = 0
                this.busiformBtns = [
                    {
                        id: 'btn_close',
                        btnName: '关 闭',
                        btnIcon: 'close',
                        btnClick: 'closeDialog',
                        btnPermissio: '',
                        btnType: ''
                    }]
            }
            this.moduleInfo.getByIdUrl = 'site/booking/getById'
            await this.selectRoom(vo.buildingId)

            this.$nextTick(() => {
                this.$bus.emit('openBusiform', this.moduleInfo, vo, canEdit)
            })
        },
        // 场馆信息查询
        selectRoom(roomID) {
            return new Promise((resolve, reject) => {
                this.$http({
                    method: 'POST',
                    url: 'site/buildingManagement/querydata',
                    contentType: 'application/x-www-form-urlencoded',
                    data: [
                        { 'compare': '=', 'field': 'id', 'merge': true, 'value': roomID },
                        {
                            compare: '=',
                            field: 'orgCode',
                            merge: true,
                            value: this.loginUser.orgCode
                        }
                    ]
                }).then(res => {
                    if (res.code === 'success') {
                        this.rooms = res.body.listdata[0]
                        resolve(true)
                    }
                })
            })
        },
        /**
        * 表单打开检查，vo为已加载vote对象，允许打开：defer.resolve(true)，阻止打开：defer.reject(reason)
        */
        onBasicFormBeforeOpen($scope, vo, defer) {
            // vo.site = this.rooms.site
            vo.buildingName = this.rooms.siteName
            vo.buildingId = this.rooms.id
            // 预约人
            if (!vo.applyUserid) {
                vo.applayName = this.loginUser.personName
                vo.applyUserid = this.loginUser.personId
            }

            if (this.columndate) {
                var kais = moment(this.rooms.openTime).format('HH:mm:ss')
                var jies = moment(this.rooms.endTime).format('HH:mm:ss')
                vo.beginTime = this.columndate + ' ' + kais
                vo.endTime = this.columndate + ' ' + jies
            }

            // 使用人
            if (!vo.userId || !vo.userName) {
                vo.userId = this.loginUser.personId
                vo.userName = this.loginUser.personName
                vo.userDeptName = this.loginUser.staffList[0].orgFullName.match(/[^-]+-[^-]+$/)[0]
            }
            if (!vo.phone) {
                vo.phone = this.loginUser.personMobile
            }
            defer.resolve(true)
        },
        onBasicFormOpened($scope, vo) {
            console.log($scope, vo, 111)
        },

        // 表单提交事件，vo为待提交的对象，校验成功：defer.resolve(true)，校验失败：defer.reject(reason)
        // approve == 1 否， 0 是
        onBasicFormBeforeSubmit($scope, vo, defer) {
            vo.status = 1

            var stTime = moment(vo.beginTime).format('YYYY-MM-DD HH:mm')
            var stTime2 = moment(vo.beginTime).format('YYYY-MM-DD') + ' ' + this.rooms.openTime.substr(11, 5)
            var enTime = moment(vo.endTime).format('YYYY-MM-DD HH:mm')
            var enTime2 = moment(vo.endTime).format('YYYY-MM-DD') + ' ' + this.rooms.endTime.substr(11, 5)
            stTime = new Date(stTime).getTime()
            stTime2 = new Date(stTime2).getTime()
            enTime = new Date(enTime).getTime()
            enTime2 = new Date(enTime2).getTime()

            const weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
            const startWeekDay = weeks[new Date(vo.beginTime).getDay()]
            const endWeekDay = weeks[new Date(vo.endTime).getDay()]
            if (this.rooms.weekProperties.indexOf(startWeekDay) === -1 || this.rooms.weekProperties.indexOf(endWeekDay) === -1) {
                this.$alert('场馆开放周期为‘' + this.rooms.weekProperties + '’！请在开放周期内预定', '提示', { type: 'warning' })
                defer.reject()
                return
            }

            if (stTime < stTime2 || enTime > enTime2 || stTime > enTime) {
                this.$alert('场馆开放时间为' + this.rooms.openTime.substr(11, 5) + ' - ' + this.rooms.endTime.substr(11, 5), '提示', { type: 'warning' })
                defer.reject()
                return
            }
            // }
            if (vo.beginTime > vo.endTime) {
                this.$alert('结束时间不能早于开始时间', '提示', { type: 'warning' })
                defer.reject()
                return
            }
            defer.resolve(true)
        },
        /**
        * 表单提交完成，vo为已保存成功的sysLog对象，窗口即将关闭，不需要返回值
        */
        onBasicFormSubmited($scope, vo) {
            if (process.env.NODE_ENV === 'development') console.log('sysLog form submited')
            this.$nextTick(() => {
                this.$bus.emit('refreshtable')
            })
        },
        // 取消通知滚动条获取数据
        SwiperGetDate(startTime, endTime) {
            this.$http({
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: [
                    {
                        'compare': '>=',
                        'field': 'cancelTime',
                        'merge': false,
                        'value': startTime
                    },
                    {
                        'compare': '<=',
                        'field': 'cancelTime',
                        'merge': false,
                        'value': endTime
                    },
                    {
                        compare: '=',
                        field: 'orgCode',
                        merge: true,
                        value: this.loginUser.orgCode
                    }
                ],
                url: '/hygl/meetingCancel/querydata'
            }).then(res => {
                if (res.code === 'success') {
                    this.noticeArr = this.dellCancelNotice(res.body.listdata)
                    // this.noticeArr.forEach(item => {
                    //     this.SwiperNoticString += item.notice + '  '
                    // })
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 获取本周时间
        getThisWeek(n) {
            var now = new Date()
            var year = now.getFullYear()
            // 因为月份是从0开始的,所以获取这个月的月份数要加1才行
            var month = now.getMonth() + 1
            var date = now.getDate()
            var day = now.getDay()
            // 判断是否为周日,如果不是的话,就让今天的day-1(例如星期二就是2-1)
            if (day !== 0) {
                n = n + (day - 1)
            } else {
                n = n + day
            }
            if (day) {
                // 这个判断是为了解决跨年的问题
                if (month <= 1) {
                // 这个判断是为了解决跨年的问题,月份是从0开始的
                    year = year - 1
                    month = 12
                }
            }
            now.setDate(now.getDate() - n)
            year = now.getFullYear()
            month = now.getMonth() + 1
            date = now.getDate()
            const str = new Date(year + '-' + (month < 10 ? ('0' + month) : month) + '-' + (date < 10 ? ('0' + date) : date) + ' ' + '00:00:00')
            return str
        },
        dellCancelNotice(data) {
            data.forEach(item => {
                // item.notice = ''
                // 周期
                if (item.preordainType === '1') {
                    item.title = `从${new Date(item.beginTime).getFullYear()}年${new Date(item.beginTime).getMonth()}月${new Date(item.beginTime).getDate()}日开始，原定于每 ${item.week} ${new Date(item.beginTime).getHours()}:${new Date(item.beginTime).getMinutes()} 到 ${new Date(item.endTime).getHours()}:${new Date(item.endTime).getMinutes()} 的会议取消`
                } else {
                    item.title = `${item.applayName === this.loginUser.personName ? '您' : item.applayName}预订的${moment(item.beginTime).format('YYYY-MM-DD HH:mm')}到${moment(item.endTime).format('YYYY-MM-DD HH:mm')}时段的${item.meetName}已被${item.cancelUser === this.loginUser.personId ? '您' : item.cancelName}取消`
                }
            })
            return data
        }
    }
}
</script>

<style scoped lang="scss">
  /deep/ .el-table tbody tr:hover>td {
    background-color:#ffffff!important
}
.el-carousel__item{
    color: #E6A23C;
}
.el-carousel__item h3 {
    color: #E6A23C;
    font-size: 14px;
    line-height: 30px;
    font-weight: 400;
    margin: 0;
  }

  .el-carousel__item {
    background-color:none;
  }
  /deep/.el-date-editor .el-range-separator{
      width: 8%;
  }

</style>
