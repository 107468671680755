<template>
    <div class="AreaLayout" :class="isInlineLayout && 'AreaLayout_inline'">
        <grid-layout v-if="layoutVisible" id="AreaLayoutContent" ref="gridlayout" :vertical-compact="true" :is-draggable="isDraggable" :is-resizable="isResizable" :layout.sync="layout" :col-num="Number(col)" :row-height="height" :margin="[1, 1]" @click.native="handleClickGridItem(null)">
            <grid-item
                v-for="(item, idx) in layout"
                :key="item.i"
                :class="{
                    'vue-grid-item__selected': selected && selected.areaId === item.areaId,
                    'vue-grid-item__hasHiddenWall': item.config.hidden && item.config.hidden.length
                }"
                :style="{ background: item.config.backgroundColor }"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
                @mousedown.native="handlerRightClickEvent($event, item)"
                @click.native.stop="handleClickGridItem(item)"
            >
                <component :is="item.name + 'Layout'" v-if="$options.components[item.name + 'Layout']" :layout-item.sync="layout[idx]" :selected="selected">
                    <template v-for="(index, name) in $slots" #[name]>
                        <slot :name="name" />
                    </template>
                </component>
            </grid-item>
        </grid-layout>
        <!-- 选项菜单 -->
        <el-scrollbar
            v-if="isVisible"
            id="menu"
            :style="{
                top: menuConfig.y + 'px',
                left: menuConfig.x + 'px'
            }"
        >
            <template v-for="menu in menus">
                <div :key="menu.type" class="menu-item" @click.prevent="menu.handler">
                    <span>{{ menu.title }}</span>
                </div>
            </template>
        </el-scrollbar>
    </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout'
import { Elements } from './AreaConfig'

const GridLayout = VueGridLayout.GridLayout
const GridItem = VueGridLayout.GridItem
/**
 *  随机key
 */
const uniqueKey = () => {
    const key = Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999)
    return key
}
const deepClone = (obj) => JSON.parse(JSON.stringify(obj))
export default {
    name: 'AreaLayout',
    components: { GridLayout, GridItem },
    props: {
        parentId: { type: String, default: '' },
        col: { type: [String, Number], default: 24 },
        height: { type: [String, Number], default: 30 },
        layout: { type: Array, default: () => [], require: true },
        occupied: { type: Array, default: () => [], require: true }, // 被物资占用区域禁止删除更改名称
        selected: { type: Object, default: () => {} },
        isInlineLayout: { type: Boolean, default: false },
        isDraggable: { type: Boolean, default: true },
        isResizable: { type: Boolean, default: true }
    },
    data() {
        return {
            DragPos: {
                x: null,
                y: null,
                w: 1,
                h: 1,
                i: null
            },
            menuConfig: {
                x: null,
                y: null
            },
            isVisible: false,
            menuTarget: null,
            layoutVisible: true
        }
    },
    computed: {
        menus() {
            // 恢复操作
            const addWallMenus = {
                T: { title: '恢复区域“上侧”墙壁', type: 'recoverTopWall', handler: () => this.handleRecoverWall('T') },
                B: { title: '恢复区域“下侧”墙壁', type: 'recoverBottomWall', handler: () => this.handleRecoverWall('B') },
                L: { title: '恢复区域“左侧”墙壁', type: 'recoverLeftWall', handler: () => this.handleRecoverWall('L') },
                R: { title: '恢复区域“右侧”墙壁', type: 'recoverRightWall', handler: () => this.handleRecoverWall('R') }
            }

            let defaultMenu = [
                { title: '移除区域“上侧”墙壁', type: 'removeTopWall', dire: 'T', handler: () => this.handleRemoveWall('T') },
                { title: '移除区域“下侧”墙壁', type: 'removeBottomWall', dire: 'B', handler: () => this.handleRemoveWall('B') },
                { title: '移除区域“左侧”墙壁', type: 'removeLeftWall', dire: 'L', handler: () => this.handleRemoveWall('L') },
                { title: '移除区域“右侧”墙壁', type: 'removeRightWall', dire: 'R', handler: () => this.handleRemoveWall('R') }
            ]
            if (this.occupied.indexOf(this.menuTarget.areaId) === -1) {
                defaultMenu.unshift({ title: '删除', type: 'delete', handler: () => this.handleDeleteLayout() })
            }
            defaultMenu = defaultMenu.map((menu) => {
                if (this.menuTarget && this.menuTarget.config && this.menuTarget.config.hidden.length) {
                    if (menu.dire && this.menuTarget.config.hidden.includes(menu.dire)) {
                        return addWallMenus[menu.dire]
                    }
                }
                return menu
            })
            return defaultMenu
        }
    },
    watch: {
        layout: {
            deep: true,
            handler(val) {
                this.$emit('update:layout', val)
            }
        },
        col() {
            this.layoutVisible = false
            this.$nextTick(() => {
                this.layoutVisible = true
            })
        }
    },
    created() {
        // 初始化挂在可渲染layout组件
        this.initLayoutComponents()
    },
    mounted() {
        if (!this.isInlineLayout) {
            // 初始化右键菜单
            this.$nextTick(() => {
                this.initClickEvent()
            })
            this.$bus.on('handleDragInLayout', this.drag)
            this.$bus.on('handleDragendInLayout', this.dragend)
            this.$bus.on('updateLayout', this.updateLayout)
        }
    },
    beforeDestroy() {
        if (!this.isInlineLayout) {
            // 卸载右键监听
            this.initClickEvent(true)
            this.$bus.off('handleDragInLayout', this.drag)
            this.$bus.off('handleDragendInLayout', this.dragend)
            this.$bus.off('updateLayout', this.updateLayout)
        }
    },
    methods: {
        getNewI(layout) {
            let max = 0
            layout.forEach((l) => {
                if (l.i === 'drop') return
                const newI = Number(l.i)
                if (newI.toString() !== 'NaN' && newI > max) {
                    max = newI
                }
            })
            return max + 1
        },
        initLayoutComponents() {
            Elements.forEach((El) => {
                if (!El.show) return
                const componentName = El.name
                this.$options.components[componentName + 'Layout'] = this.$loadComponent(`@/wapp/areaEditor/layouts/${componentName + 'Layout'}`)
            })
        },
        // 右键菜单初始化
        initClickEvent(remove = false) {
            const _this = this
            const LayoutContent = document.querySelector('.Editor-container__center')
            const preventFunc = (e) => e.preventDefault()
            const handleLeftClick = (e) => {
                if (e.button == 0 && _this.isVisible && e.target.className !== 'menu-item') {
                    _this.isVisible = false
                    _this.menuTarget = null
                }
            }
            if (remove) {
                LayoutContent.removeEventListener('contextmenu', preventFunc)
                document.removeEventListener('mousedown', handleLeftClick)
                return
            }
            LayoutContent.addEventListener('contextmenu', preventFunc)
            document.addEventListener('mousedown', handleLeftClick)
        },
        // 右键菜单定位
        handlerRightClickEvent(e, target) {
            this.menuConfig = {
                x: e.clientX, // mousedown point for menu
                y: e.clientY // mouseup point for menu
            }
            this.menuTarget = target
            this.isVisible = true
            this.$nextTick(() => {
                // 处理弹窗显示位置
                const screenHeight = document.body.clientHeight
                if (this.menuConfig.y > screenHeight / 2) {
                    const menuHeight = document.querySelector('#menu').clientHeight
                    this.menuConfig.y = this.menuConfig.y - menuHeight
                }
            })
            e.preventDefault()
        },
        // 拖拽过程
        drag(e, target = {}) {
            this.$emit('update:selected', null)
            const parentRect = document.getElementById('AreaLayoutContent').getBoundingClientRect()
            const layoutRef = this.$refs['gridlayout']
            let mouseInGrid = false
            if (e.clientX > parentRect.left && e.clientX < parentRect.right && e.clientY > parentRect.top && e.clientY < parentRect.bottom) {
                mouseInGrid = true
            }
            if (mouseInGrid === true && this.layout.findIndex((item) => item.i === 'drop') === -1) {
                this.layout.push({
                    x: (this.layout.length * 2) % (this.col || 12),
                    y: this.layout.length + (this.col || 12), // puts it at the bottom
                    w: 1,
                    h: 1,
                    i: 'drop',
                    ...target
                })
            }
            const index = this.layout.findIndex((item) => item.i === 'drop')
            const newIndex = this.getNewI(this.layout)
            if (index !== -1) {
                try {
                    layoutRef.$children[this.layout.length].$refs.item.style.display = 'none'
                } catch {
                    console.log('error')
                }
                const el = layoutRef.$children[index]
                el.dragging = { top: e.clientY - parentRect.top, left: e.clientX - parentRect.left }
                const new_pos = el.calcXY(e.clientY - parentRect.top, e.clientX - parentRect.left)
                if (mouseInGrid === true) {
                    layoutRef.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, target.h || 1, target.w || 1)
                    this.DragPos.i = String(newIndex)
                    this.DragPos.x = this.layout[index].x
                    this.DragPos.y = this.layout[index].y
                }
                if (mouseInGrid === false) {
                    layoutRef.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, target.h || 1, target.w || 1)
                    this.layout = this.layout.filter((obj) => obj.i !== 'drop')
                }
            }
        },
        // 拖拽结束 - 添加元素
        dragend(e, target = {}) {
            const parentRect = document.getElementById('AreaLayoutContent').getBoundingClientRect()
            const layoutRef = this.$refs['gridlayout']
            let mouseInGrid = false
            if (e.clientX > parentRect.left && e.clientX < parentRect.right && e.clientY > parentRect.top && e.clientY < parentRect.bottom) {
                mouseInGrid = true
            }
            if (mouseInGrid === true) {
                this.layout = this.layout.filter((obj) => obj.i !== 'drop')
                //* *********** 添加到拖动的目标位置
                target.areaId = uniqueKey()
                target.areaNumber = 'area_' + this.DragPos.i
                const newLayout = {
                    x: this.DragPos.x,
                    y: this.DragPos.y,
                    w: 1,
                    h: 1,
                    i: this.DragPos.i,
                    ...target
                }
                this.layout.push(deepClone(newLayout))
                layoutRef.dragEvent('dragend', this.DragPos.i, this.DragPos.x, this.DragPos.y, target.h || 1, target.w || 1)
                try {
                    layoutRef.$children[this.layout.length].$refs.item.style.display = 'block'
                } catch {
                    console.log('error')
                }
            } else {
                this.layout = this.layout.filter((obj) => obj.i !== 'drop')
            }
        },
        // 点击设置
        handleClickGridItem(item) {
            if (!item && JSON.stringify(this.selected) !== '{}') {
                this.$emit('update:selected', {})
                return
            }
            this.$emit('update:selected', item)
        },
        // 移除区域
        handleDeleteLayout() {
            const deleteIndex = this.layout.findIndex((l) => l.areaId === this.menuTarget.areaId)
            if (deleteIndex !== -1) this.layout.splice(deleteIndex, 1)

            this.isVisible = false
            this.menuTarget = null
        },
        // 移除区域 - 墙壁线条
        handleRemoveWall(dire) {
            if (this.menuTarget && this.menuTarget.config && this.menuTarget.config.hidden) {
                this.menuTarget.config.hidden.push(dire)
            }

            this.isVisible = false
            this.menuTarget = null
        },
        // 恢复区域 - 墙壁线条
        handleRecoverWall(dire) {
            if (this.menuTarget && this.menuTarget.config && this.menuTarget.config.hidden) {
                const deleteIndex = this.menuTarget.config.hidden.findIndex((h) => h === dire)
                this.menuTarget.config.hidden.splice(deleteIndex, 1)
            }

            this.isVisible = false
            this.menuTarget = null
        },
        // 更新layout视图
        updateLayout() {
            const layoutRef = this.$refs['gridlayout']
            if (layoutRef) {
                layoutRef.resizeEvent()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.AreaLayout {
    width: 100%;
    // height: 100%;
    background: #ffffff;
    box-sizing: border-box;
    user-select: none;
    .vue-grid-layout {
        width: 100%;
        min-height: 650px;
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    }
    .vue-grid-item:not(.vue-grid-placeholder) {
        outline: 1px solid #c8ccd4;
    }
    .vue-grid-item__selected {
        outline: 1px solid #3d92e0 !important;
        z-index: 100;
    }
    .vue-grid-item__hasHiddenWall {
        z-index: 1;
    }
    .vue-grid-item .resizing {
        opacity: 0.9;
    }

    .vue-grid-item .static {
        background: #cce;
    }

    .vue-grid-item .text {
        font-size: 24px;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 100%;
        width: 100%;
    }

    .vue-grid-item .no-drag {
        height: 100%;
        width: 100%;
    }

    .vue-grid-item .minMax {
        font-size: 12px;
    }

    .vue-grid-item .add {
        cursor: pointer;
    }

    .vue-draggable-handle {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
        background-position: bottom right;
        padding: 0 8px 8px 0;
        background-repeat: no-repeat;
        background-origin: content-box;
        box-sizing: border-box;
        cursor: pointer;
    }
}
#menu {
    position: fixed;
    width: 250px;
    background: rgba($color: #ffffff, $alpha: 0.9);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 4px;
    max-height: 317px;
    overflow: hidden;
    z-index: 9001;
    .menu-item {
        padding: 17px 20px;
        border-bottom: #e6e6e6 1px solid;
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
        span {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            pointer-events: none;
        }
    }
    .menu-item:hover {
        background: rgba($color: #999999, $alpha: 0.3);
    }
    .menu-item:last-child {
        border-bottom: none;
    }

    /deep/ .el-scrollbar__wrap {
        overflow-y: scroll;
        overflow-x: unset;
        margin-bottom: 0 !important;
    }
}
.AreaLayout_inline {
    padding: 0;
    .vue-grid-layout {
        min-height: 100%;
        background: #ffffff;
        box-shadow: unset;
    }
}
</style>
