<template>
  <div>
    签到&nbsp;{{ row.signOnTime }}（<span :class="{'red': row.signOnStatus > 1 || !row.signOnStatus}">{{ status[row.signOnStatus] || '缺卡' }}</span>）&nbsp;&nbsp;
    签退&nbsp;{{ row.signOffTime }}（<span :class="{'red': row.signOffStatus>1 || !row.signOffStatus}">{{ status[row.signOffStatus] || '缺卡' }}</span>）
    <template v-if="row.passenger.details">
      <template v-for="(timespan,i) in row.passenger.details">
        &nbsp;&nbsp;<span :key="i">外勤&nbsp;{{ timespan.occureDate.substr(11,5) }}</span>
      </template>
    </template>
  </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        column: { type: Object, default: null },
        row: { type: Object, default: null },
        index: { type: Number, default: null }
    },
    data() {
        return {
            status: ['', '正常', '迟到', '早退', '缺卡', '出差', '其他假', '加班', '事假', '病假']
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
    .red{color:#F56C6C;}
</style>
