<template>
  <div style="padding:10px 20px">
    <div class="title">
      <div style="width:46%;min-width:458px">
        <!-- 学校：&nbsp;
        <el-select v-model="value" style="width:100px;" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        &nbsp;&nbsp;&nbsp;
        场地：&nbsp;
        <el-select v-model="value" style="width:100px;" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        &nbsp;&nbsp;&nbsp; -->
        <!-- <span style="color:#999999;">{{ buildName }}宿舍床位{{ roomSum }}个 共入住 <span style="color:#0ea04d">{{ yzRoomSum }}</span> 人 空床位 <span style="color:#EC8423">{{ roomSum - yzRoomSum }}</span> 个</span> -->
      </div>
      <!-- <div style="font-size: 18px;font-weight: 700;width:33%;text-align:center;">1栋入住宿舍分布图</div> -->
      <div style="width:56%;text-align:right;">
        <!-- <el-button-group>
          <el-button size="small" icon="el-icon-arrow-left">导入</el-button>
          <el-button size="small">导出<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        </el-button-group> -->
        学校：&nbsp;
        <el-select v-model="valueSc" style="width:100px;" placeholder="请选择">
          <el-option
            v-for="item in optionsSc"
            :key="item.id"
            :label="item.schoolName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        &nbsp;&nbsp;&nbsp;
        场地：&nbsp;
        <el-select v-model="value" style="width:100px;" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        &nbsp;&nbsp;&nbsp;
        <!-- 导入到处按钮 -->
        <div data-v-fd7a5c9c="" class="el-button-group toolbar-button-group el-popover__reference" aria-describedby="el-popover-4191" tabindex="0">
          <!-- <button data-v-fd7a5c9c="" type="button" class="el-button el-button--default el-button--small is-plain import_btn" @click="importData">
            <span>
              <svg data-v-5d08e6e2="" data-v-fd7a5c9c="" aria-hidden="true" class="svg_icon svg-icon"><use data-v-5d08e6e2="" xlink:href="#icon-import" /></svg>
              <span data-v-fd7a5c9c="">导入</span>
            </span>
          </button> -->
          <button data-v-fd7a5c9c="" type="button" class="el-button el-button--default el-button--small is-plain export_btn" @click="exportData">
            <span>
              <svg data-v-5d08e6e2="" data-v-fd7a5c9c="" aria-hidden="true" class="svg_icon svg-icon">
                <use data-v-5d08e6e2="" xlink:href="#icon-export" />
              </svg>
              <span data-v-fd7a5c9c="">导出</span></span>
          </button>
        </div>
      </div>
    </div>
    <div class="content" style="overflow-x: auto;">

      <table v-if="listData.length>0" v-loading="loading" class="talleDiy" style="border-collapse: collapse;border: 1px solid #ccc;width:100%;min-width: 1057px;">
        <tr v-for="(item,index) in listData" :key="index">
          <td style="width: 100px;min-width: 100px;height: 80px;background: #f5f7fa;">
            <div style="font-size: 16px;font-weight: 700;color: #333333;">{{ item.floor }}层</div>
            <div style="color:#999999"><span style="color:#0EA04D">{{ setYzsum(item) }}</span>/{{ setQbsum(item) }}</div>
          </td>
          <td v-for="(val,idx) in item.roomVoList" :key="idx">
            <div class="room">
              <div class="left">{{ val.roomNo }}</div>
              <div class="right">
                <div style="width:100%;height:100%;border: 1px solid #ccc;border-radius: 3px;padding: 0px 5px;color:#999999;" @click="addBtn(item,val)">
                  <template v-if="val.bedList&&val.bedList.length>0">
                    <div v-for="tea in val.bedList" :key="tea.id" style="font-size: 12px;text-align: left;padding: 6px 0;" class="teacher">
                      <div style="height: 25px;line-height: 25px;"><i class="el-icon-user"></i> 姓名：{{ tea.studentName }}</div>
                      <div style="height: 25px;line-height: 25px;"><i class="el-icon-time"></i> 入住时间：{{ setTime(tea.checkinDate) }}</div>
                    </div>
                  </template>
                  <div v-else style="height: 50px;line-height: 50px;font-size: 14px;">暂无入住人员</div>
                </div>
                <!-- <div v-for="(bed,bidx) in Number(val.num)" :key="bidx" @click="addBtn(item,val,bed)">

                  <el-popover
                    v-if="getStudent(val, bed)"
                    trigger="hover"
                    placement="right-start"
                    width="172"
                  >
                    <div class="pop-box">
                      <div class="name">{{ getStudent(val, bed)?.studentName }}</div>
                      <div class="person-info">
                        <div class="grade-class">班级: {{ getStudent(val, bed)?.gradeClass }}</div>
                        <div class="income-time">入住时间: {{ getStudent(val, bed)?.checkinDate?.slice(0,10) }}</div>
                      </div>
                    </div>
                    <div v-if="setName(val.bedList,bidx + 1)?true:false" slot="reference" class="box" style="width:100%;height:45px;background:#0EA04D;margin: 0;border-radius: 2px; cursor: pointer;">
                      <span>{{ bidx + 1 }}</span>
                      <span>{{ (bidx + 1)%2==0?'下':'上' }}</span>
                    </div>
                  </el-popover>

                  <div v-if="setName(val.bedList,bidx + 1)?false:true" style="width:100%;height:100%;;background:#e0e0e0;margin: 0;border-radius: 2px; cursor: pointer;">
                    <span>{{ bidx + 1 }}</span>
                    <span>{{ (bidx + 1)%2==0?'下':'上' }}</span>

                  </div>
                </div> -->
              </div>
            </div>
          </td>

        </tr>
      </table>
      <!-- <div v-else style="height: 150px;text-align: center;border: 1px solid #f2f2f2;color: #ccc;">暂无数据</div> -->
      <div v-else style="text-align:center;border: 1px solid #f2f2f2;height: 200px;">
        <img :src="img" style="max-width:180px;margin-top: 40px;"><br>
        <span style="font-size:15px;color:#dbdbdb">暂无数据</span>
      </div>
    </div>

    <Basicform
      v-if="showBasicform"
      :title="'入住管理'"
      :win-width="moduleInfo.winWidth"
      :win-height="moduleInfo.winHeight"
      :on-basic-form-opened="onBasicFormOpened"
      :on-basic-form-before-submit="onBasicFormBeforeSubmit"
      :on-basic-form-submited="onBasicFormSubmited"
    >
      <template #default="slotProps">
        <busi-form :vo="slotProps.vo" :can-edit="slotProps.canEdit" :module-info="moduleInfo" />
      </template>
    </Basicform>

    <importFile />
  </div>
</template>

<script>
import importFile from './importFile.vue'

export default {
    components: {
        importFile
    },
    props: {

    },
    data() {
        return {
            img: './images/w-imgs/nomessage.png',
            loginUser: this.$store.getters.loginUser,
            valueSc: '',
            optionsSc: [],
            isflesh: true,
            loading: true,
            buildName: '',
            options: [],
            roomSum: 0,
            yzRoomSum: 0,
            isColor: false,
            value: '',
            showBasicform: false,
            listData: [],
            moduleInfo: {
                winWidth: window.mobileWidth,
                winHeight: window.mobileHeight,
                listDataUrl: '/bus/teachingBuildingRoom/listdata',
                delUrl: '/bus/teachingBuildingRoom/delete',
                updateUrl: '/bus/teachingBuildingRoom/update',
                addNewUrl: '/bus/teachingBuildingRoom/addNew',
                insertUrl: '/bus/teachingBuildingRoom/insert',
                getByIdUrl: '/bus/teachingBuildingRoom/getById',
                deleteUrl: '/bus/teachingBuildingRoom/delete'
            }

        }
    },
    computed: {

        getStudent() {
            return (val, bed) => {
                let currentStudent = null
                val.bedList.forEach(item => {
                    if (Number(item.bedNo) === Number(bed)) {
                        currentStudent = item
                        return
                    }
                })
                return currentStudent
            }
        },
        // 按照orderNo的key值排序
        newOptionsSc() {
            return this.optionsSc.sort((a, b) => b.orderNo - a.orderNo)
        }
    },
    watch: {
        value(val) {
            console.log('this.options.find(item=>item.value === val)', this.options.find(item => item.id === val))
            this.buildName = this.options.find(item => item.id === val).name
            this.getDormitoryInfo()
        },
        valueSc(val) {
            this.getDormitoryInfo()
        }

    },
    created() {
        this.getSchoolData()
        this.$bus.emit('showNavSearch', false) // 取消右上搜索按钮
        this.getAllBuild()
    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
        setTime(time) {
        // 原始日期字符串
            var dateString = time

            // 创建一个新的Date对象
            var date = new Date(dateString)

            // 获取年份、月份和日期
            var year = date.getFullYear()
            var month = date.getMonth() + 1 // 月份从0开始，需要加1
            var day = date.getDate()

            // 格式化月份和日期，如果是个位数，前面不补0
            month = month < 10 ? month : month
            day = day < 10 ? day : day

            // 构建最终的日期字符串
            var formattedDate = year + '年' + month + '月' + day + '日'
            return formattedDate
        },
        getSchoolData() {
            this.$http({
                method: 'post',
                url: '/bus/school/querydata',
                data: [
                    { 'compare': '=', 'field': 'orgCode', 'merge': false, 'value': this.loginUser.orgCode },
                    { 'compare': '=', 'field': 'status', 'merge': false, 'value': 1 }
                ]
            }).then(res => {
                console.log(res)
                if (res.code === 'success') {
                    if (res.body.listdata.length > 0) {
                        res.body.listdata.forEach(item => {
                            this.optionsSc.push(item)
                        })

                        this.optionsSc = this.optionsSc.sort((a, b) => {
                            return a.orderNo - b.orderNo
                        })

                        this.valueSc = this.optionsSc[0].id
                    }
                }
            })
        },
        getDormitoryInfo(val) {
            if (this.value) {
                this.loading = true
                this.$http({
                    method: 'get',
                    url: '/bus/teacherBuildingBed/getAllBeds',
                    params: {
                        buildId: this.value,
                        schoolId: this.valueSc
                    }
                }).then(res => {
                    console.log(res)
                    if (res.code === 'success') {
                        let roomSum = 0
                        const yzRoomSum = 0
                        this.listData = res.body.vo

                        // this.listData= res.body.listdata.sort((a, b) => {
                        //     return a.axisX - b.axisX
                        // })

                        this.listData.forEach(item => {
                            item.roomVoList.sort((a, b) => {
                                return a.order - b.order
                            })
                        })

                        this.listData.forEach(item => {
                            item.roomVoList.forEach(val => {
                                roomSum = roomSum + Number(val.num)
                                // if (val.bedList.length > 0) {
                                //     yzRoomSum = yzRoomSum + val.bedList.length
                                // }
                            })
                        })
                        console.log('roomSum', roomSum)
                        console.log('yzRoomSum', yzRoomSum)
                        this.yzRoomSum = yzRoomSum
                        this.roomSum = roomSum
                        this.loading = false
                    }
                })
            }
        },
        setYzsum(value) {
            let setYzsum = 0
            value.roomVoList.forEach(item => {
                if (item.bedList.length > 0) {
                    setYzsum = setYzsum + item.bedList.length
                }
            })
            return setYzsum
        },
        setQbsum(value) {
            let setYzsum = 0
            value.roomVoList.forEach(item => {
                setYzsum = setYzsum + Number(item.num)
            })
            return setYzsum
        },
        setName(arr, index) {
            let name = ''
            arr.forEach(item => {
                if (item.bedNo == index) {
                    name = item.studentName.split('')[0]
                    this.isColor = true
                }
            })

            return name
        },
        addBtn(item, val, bed) {
            console.log(this.$store.getters.roles)

            this.$store.getters.roles.map((item) => {
                if (item === 'schoolKeeper') {
                    this.moduleInfo.formTemplate = '#/dcontent/vuebusiform/W/bus.teachingBuildingRoom.form_rz.vue?sysCode=' + this.$route.meta.sysCode
                    val.id = val.roomId
                    this.showBasicform = true
		                this.$options.components['BusiForm'] = this.$loadComponent(this.moduleInfo.formTemplate)
                    this.$nextTick(() => {
                        this.$bus.emit('openBusiform', this.moduleInfo, val, 1, (res) => {
                            if (res.code === 'success') {
                                this.showBasicform = false
                            }
                        })
                    })
                }
            })
        },
        getAllBuild() {
            this.$http({
                method: 'get',
                url: 'bus/building/getAllBuild?type=1'
            }).then(res => {
                console.log(111, res)
                if (res.code === 'success') {
                    this.options = res.body.vo
                    console.log(this.options, 123)
                    if (this.options.length > 0) {
                        this.value = this.options[0].id
                    }
                }
            })
        },

        onBasicFormOpened($scope, vo) {
            console.log('$scope, vo', $scope, vo)
            this.$http({
                method: 'get',
                url: 'bus/student/getById',
                params: {
                    id: vo.studentId
                }
            }).then(res => {
                if (res.code === 'success' && $scope.canEdit === 0) {
                    this.$set(vo, 'gender', res.body.vo.gender === '1' ? '男' : '女')
                    this.$set(vo, 'gradeClass', res.body.vo.gradeClass)
                }
            })
            console.log($scope, vo)
        },
        exportData() {
            let buildName = ''
            this.options.forEach((item) => {
                if (this.value == item.id) { buildName = item.name }
            })
            window.open(`apigw/bus/teacherBuildingBed/exportRoomScore?buildId=${this.value}&buildName=${buildName}&pageNum=1&pageSize=100000&fileName=宿舍入住情况`)
        },
        importData() {
            this.$bus.emit('openImportForm')
        },
        onBasicFormSubmited() {
            this.$nextTick(() => {
                this.getDormitoryInfo(this.value)
            })
        },
        onBasicFormBeforeSubmit: function($scope, vo, defer) {
            console.log('buildingBed form submit validation success', $scope, vo, defer)
            // if (!vo.studentName || !vo.studentId) {
            //     this.$message.warning('学生和入住时间是必须的')
            //     defer.resolve(false)
            // } else {
            //     defer.resolve(true)
            // }
            defer.resolve(true)
        }
    }
}
</script>

<style scoped lang="scss">
.title {
    display: flex;
    justify-content: space-between;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    color: #333333;
    min-width: 1000px;

}
.talleDiy {
//   margin-top: 10px;
  td {
    border: 1px solid #ccc;
    text-align: center;
    height: 100px;
  }
  .room {
    display: flex;
    height: 100%;
    flex-direction: column;
    // min-width: 87px;
    // max-width: 160px;
    width: 186px;
    padding: 6px;
    .left {
      display: flex;
      align-items: center;
      // width: 50px;
      // background: pink;
      justify-content: center;
      font-size: 13px;
      color: #4A4A4A;
      font-weight: 700;
    }
    .right {
      display: flex;
      width: 100%;
      // height: 100%;
      height: 126px;
      // justify-content: space-around;
      flex-wrap: wrap;
      align-content: flex-start;
      // div {
      //   width: 42%;
      //   height: 45px;
      //   // background: #0EA04D;
      //   margin: 5px 4%;
      //   display: flex;
      //   flex-direction: column;
      //   border-radius: 2px;
      //   color: #fff;
      //   font-size: 13px;
      //   justify-content: space-evenly;
      // }
    }
  }

}

.box {
  width: 42%;
        height: 45px;
        // background: #0EA04D;
        margin: 5px 4%;
        display: flex;
        flex-direction: column;
        border-radius: 2px;
        color: #fff;
        font-size: 13px;
        justify-content: space-evenly;
}

.pop-box{
  font-family: MicrosoftYaHei;
.name{
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}
.person-info{
  font-size: 13px;
color: #999999;
.grade-class{
  margin-bottom: 4px;
}
}
}
</style>
