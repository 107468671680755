<template>
  <PSHourMoniTorings />
</template>

<script>
export default {
    data() {
        return {
        }
    }
}
</script>
