<template>
  <div class="app-container">
    <!-- <DeptTime :change-dept="changeDept" :change-time="changeTime" file-name="工作时长统计" @dateInit="dateInit" /> -->
    <statistics-search :search-type="searchType" @statisticsClick="statistics" />
    <div style="margin:10px 0;">
      <el-radio-group v-model="vidaoType" size="mini">
        <el-radio-button label="0">时长</el-radio-button>
        <el-radio-button label="1">加班</el-radio-button>
        <el-radio-button label="2">迟到</el-radio-button>
        <el-radio-button label="3">早退</el-radio-button>
      </el-radio-group>
    </div>
    <div style="text-align: center;margin:20px 0 10px;">
      <span class="statistics_title">部门{{ vidaoType==='0'?'日均时长':(vidaoType==='1'?'加班次数':(vidaoType==='2'?'迟到次数':'早退次数')) }}统计</span>
    </div>
    <div v-if="vidaoType=='0'">
      <WorkTimeEchart ref="WorkTimeEchart" @echartsClick="echartsClick" />
      <WorkTimeList id="exportTable" ref="WorkTimeList" />
    </div>
    <div v-else>
      <work-condition ref="workCondition" :data-type="vidaoType" />
    </div>
  </div>
</template>

<script>
import WorkTimeEchart from './WorkTimeEchart'
import WorkTimeList from './WorkTimeList'
import workCondition from '../workCondition/index'
import StatisticsSearch from '@/wapp/common/components/StatisticsSearch'
const moment = require('moment')
export default {
    components: {
        WorkTimeEchart, WorkTimeList, StatisticsSearch, workCondition
    },
    data() {
        return {
            loginUser: this.$store.getters.loginUser,
            selectDept: '',
            chooseDate: [],
            orgCode: '',
            vidaoType: '0',
            searchType: [
                {
                    type: 'range',
                    title: '统计周期',
                    rangeType: 'daterange',
                    rangeSeparator: '至',
                    dateFormat: 'yyyy-MM-dd'
                }
            ]
        }
    },
    computed: {
    },
    watch: {
    },
    created() {
        this.$bus.emit('showNavSearch', false) // 取消右上搜索按钮
        this.today = moment(new Date()).format('YYYY-MM-DD')
        const time = new Date()
        const year = time.getFullYear()
        const month = time.getMonth() + 1

        if (time.getDate() < 15) {
            const m = month === 1 ? 12 : month - 1
            const y = month === 1 ? year - 1 : year
            const ed = new Date(y, m, 0).getDate()

            this.chooseDate = [moment(new Date(y, m - 1, 1)).format('YYYY-MM-DD'), moment(new Date(y, m - 1, ed)).format('YYYY-MM-DD')]
        } else {
            this.chooseDate = [moment(new Date(year, month - 1, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')]
        }
        this.selectDept = this.loginUser.staffList[0].orgFullId.split('-')[1]
        this.orgCode = this.loginUser.orgCode
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true) // 还原右上搜索按钮
    },
    mounted() {

    },
    methods: {
        // 筛选条件统计
        statistics(vals) {
            this.chooseDate = [moment(vals.range[0]).format('YYYY-MM-DD'), moment(vals.range[1]).format('YYYY-MM-DD')]
            this.$nextTick(() => {
                if (this.vidaoType === '0') {
                    this.$refs.WorkTimeEchart.getData()
                    this.$refs.WorkTimeList.getData()
                } else {
                    this.$refs.workCondition.changeDate()
                }
            })
        },
        echartsClick(id) {
            this.selectDept = id
            this.$nextTick(() => {
                this.$refs.WorkTimeList.getData()
            })
        }
    }
}
</script>

<style scoped>
</style>
