<template>
  <div>
    <div
      style="padding: 10px;     font-size: 14px;   display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;"
    >
      <div class="dj">
        登记日期：
        <el-date-picker
          v-model="value"
          class="riqi"
          type="month"
          placeholder="选择月"
          format="yyyy年M月"
          value-format="yyyy-M"
        />

      </div>
      <statistics-search :search-type="searchType" @statisticsClick="statistics" />
    </div>
    <div
      style="    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    font-size: larger;"
    >
      考勤汇总表

    </div>
    <div
      style="    text-align: end;
    padding: 5px 10px;"
    >
      <el-button-group>
        <el-button icon="el-icon-check" style="height: 32px;" @click="tosave">保存</el-button>
        <el-button icon="el-icon-download" style="height: 32px;" @click="daoru">导入</el-button>
        <el-button style="height: 32px;" @click="daochu">
          <svg
            aria-hidden="true"
            class="svg-icon"
            style="height: 13px; width: 13px"
            v-on="$listeners"
          >
            <use :xlink:href="'#icon-export'" />
          </svg>导出</el-button>
      </el-button-group>
    </div>
    <div v-if="showedit" class="table-box">
      <el-table
        class="custom-table"
        style="width:100%"
        border
        :data="dataList2"
      >
        <el-table-column
          label="序号"
          width="60"
          type="index"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.index }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" width="80" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.staffName }}</div>

          </template>
        </el-table-column>
        <el-table-column label="科室" width="80" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.staffDept }}</div>

          </template>
        </el-table-column>

        <el-table-column
          label="迟到(次)"
          width="90"
          align="center"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.cdNum"
              type="number"
              :min="0"
              @input="handleInput(scope.row,scope,scope.$index)"
            /></template>
        </el-table-column>
        <el-table-column
          label="早退(次)"
          width="90"
          align="center"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.ztNum"
              type="number"
              :min="0"
              @input="handleInput(scope.row,scope,scope.$index)"
            /></template>
        </el-table-column>

        <el-table-column
          label="旷工(天)"
          width="90"
        >

          <template slot-scope="scope">
            <el-input
              v-model="scope.row.kgNum"
              type="number"
              :min="0"
              @input="handleInput(scope.row,scope,scope.$index)"
            /></template>
        </el-table-column>

        <el-table-column
          v-for="(option, optionkey) in dataList.top"
          :key="optionkey"
          align="center"
          header-align="center"
          :label="option+'(天)'"
          width="90"
        >
          <template slot-scope="scope">
            <div>
              <el-input
                v-model="dataList2[scope.$index].passenger.list[optionkey].day"
                type="number"
                :min="0"
                @input="setDayValue1(scope.row,scope.$index, option, $event,scope,scope.$event)"
              />
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="出勤（天）"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.cqNum"
              type="number"
              :min="0"
              :value="calculateCqNum(scope.row)"
              @input="handleInput(scope.row)"
            /></template>
        </el-table-column>
        <el-table-column
          label="备注"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.remark"
              @input="handleInput(scope.row)"
            /></template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else class="table-box">

      <el-table
        class="custom-table"
        style="width:100%"
        border
        :data="dataList2"
      >
        <el-table-column
          label="序号"
          width="60"
          type="index"
        >
          <template slot-scope="scope">
            <span style="margin-left: 10px">
              {{ scope.row.index }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="姓名">
          <template slot-scope="scope">
            <div>{{ scope.row.staffName }}</div>

          </template>
        </el-table-column>
        <el-table-column label="科室">
          <template slot-scope="scope">
            <div>{{ scope.row.staffDept }}</div>

          </template>
        </el-table-column><el-table-column label="考勤员">
          <template slot-scope="scope">
            <div>{{ scope.row.kqPerson }}</div>

          </template>
        </el-table-column><el-table-column label="科室负责人">
          <template slot-scope="scope">
            <div>{{ scope.row.ksPerson }}</div>

          </template>
        </el-table-column>

        <el-table-column
          label="迟到(次)"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.cdNum }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="早退(次)"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.ztNum }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="旷工(天)"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.kgNum }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(option, optionkey) in dataList.top"
          :key="optionkey"
          align="center"
          header-align="center"
          :label="option"
          width="100"
        >
          <template slot-scope="scope">
            <div>

              {{ dataList2[scope.$index].passenger.list.find(item => item.typeName === option)?.day || 0 }}
            </div>

          </template>
        </el-table-column>
        <el-table-column
          label="出勤（天）"
          width="100"
          align="center"
        >

          <template slot-scope="scope">
            <div>{{ scope.row.cqNum }}</div>
          </template>
        </el-table-column>

        <el-table-column
          label="备注"
          width="100"
        >

          <template slot-scope="scope">
            <div>{{ scope.row.remark }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <importa />
  </div>
</template>

<script>
const currentDate = new Date()
const year = currentDate.getFullYear()
const month = currentDate.getMonth() + 1 // 月份从0开始，所以需要加1
const formattedDate = `${year}-${month}`
import StatisticsSearch from '@/wapp/common/components/StatisticsSearch'
import importa from './components/import'
export default {
    components: {
        StatisticsSearch,
        importa
    },
    props: {

    },
    data() {
        return {
            showedit: true,
            dataList: [],
            value: '',
            time: '',
            selectDept: '',
            content: '',
            searchType: [
                {
                    type: 'subOrg',
                    title: '登记科室'
                }
            ],
            dataList2: [],
            dataList3: [],
            dataList1: {
                list: [
                    {
                        'affairTimes': 0.0,
                        'annualTimes': 0.0,
                        'bereavementTimes': 0.0,
                        'cdNum': 0,
                        'cqNum': null,
                        'gcNum': 0.0,
                        'hzDate': '2023-7',
                        'id': '16942041554e4dcbbc90f0844ff088ac',
                        'illTimes': 0.0,
                        'jxNum': 0.0,
                        'kgNum': 0.0,
                        'kqNum': 34,
                        'kqPerson': '黄珍',
                        'kqPersonId': '41b9c66980a440f8957f4626baee6463',
                        'ksPerson': null,
                        'ksPersonId': null,
                        'marryTimes': 0.0,
                        'maternityTimes': 0.0,
                        'orderNo': null,
                        'orgCode': 'nfyk',
                        'orgName': null,
                        'parentId': null,
                        'passenger': {
                            'list': [
                                {
                                    'day': 1.0,
                                    'id': '4d6a182ed71c4ac0af5164e4411a4a56',
                                    'parentId': '16942041554e4dcbbc90f0844ff088ac',
                                    'passenger': {

                                    },
                                    'typeId': '16942041554e4dcbbc90f0844ff088aj',
                                    'typeName': '婚假'
                                }
                            ]
                        },
                        'realityNum': 34,
                        'registrationDate': null,
                        'remark': null,
                        'staffDept': '运营部',
                        'staffDeptId': '95238ae52b08459c85b5ce2c4bce435b',
                        'staffId': '41b9c66980a440f8957f4626baee6463',
                        'staffName': '黄珍',
                        'visitTimes': 0.0,
                        'ztNum': 0
                    },
                    {
                        'affairTimes': 0.0,
                        'annualTimes': 0.0,
                        'bereavementTimes': 0.0,
                        'cdNum': 0,
                        'cqNum': null,
                        'gcNum': 0.0,
                        'hzDate': '2023-7',
                        'id': '25d06ecb48244e04b03b3032841bb7da',
                        'illTimes': 0.0,
                        'jxNum': 0.0,
                        'kgNum': 0.0,
                        'kqNum': 34,
                        'kqPerson': '黄丽莲',
                        'kqPersonId': 'de2d4228073f4765bb6e5656df656ff1',
                        'ksPerson': null,
                        'ksPersonId': null,
                        'marryTimes': 0.0,
                        'maternityTimes': 0.0,
                        'orderNo': null,
                        'orgCode': 'nfyk',
                        'orgName': null,
                        'parentId': null,
                        'passenger': {

                            'list': [
                                {
                                    'day': 3.0,
                                    'id': '4d6a182ed71c4ac0af5164e4411a4a52',
                                    'parentId': '60f08cea579044daad3cd1d597717a85',
                                    'passenger': {

                                    },
                                    'typeId': '4d6a182ed71c4ac0af5164e4411a4a53',
                                    'typeName': '产假'
                                },
                                {
                                    'day': 2.0,
                                    'id': '4d6a182ed71c4ac0af5164e4411a4a53',
                                    'parentId': '60f08cea579044daad3cd1d597717a85',
                                    'passenger': {

                                    },
                                    'typeId': '60f08cea579044daad3cd1d597717a81',
                                    'typeName': '病假'
                                },
                                {
                                    'day': 4.0,
                                    'id': '4d6a182ed7222164e4411a4a53',
                                    'parentId': '60f0scea579044daad3cd1d597717a85',
                                    'passenger': {

                                    },
                                    'typeId': '60f08cea579044daad3cd1d597717a81',
                                    'typeName': '婚假'
                                }
                            ]
                        },
                        'realityNum': 34,
                        'registrationDate': null,
                        'remark': null,
                        'staffDept': '运营部',
                        'staffDeptId': '95238ae52b08459c85b5ce2c4bce435b',
                        'staffId': 'de2d4228073f4765bb6e5656df656ff1',
                        'staffName': '黄丽莲',
                        'visitTimes': 0.0,
                        'ztNum': 0
                    }
                ],
                top: ['婚假', '婚假', '产假', '婚假', '病假']
            },
            inputValue: '',
            saveList: [],
            add: 0

        }
    },
    computed: {

    },
    watch: {
        value(val) {
            const time = val.split('-')
            const y = time[0]
            const m = time[1]
            this.getDays(y, m)
        },
        cqNum(val) {
            console.log(val)
        }
    },
    created() {
        this.$bus.emit('showNavSearch', false)
        const date = new Date()
        this.value = date.getFullYear() + '-' + (date.getMonth() + 1)
        const time = this.value.split('-')
        const y = time[0]
        const m = time[1]
        this.getDays(y, m)
        console.log(this.value, formattedDate, 999)

        this.selectDept = this.$store.getters.loginUser.staffList[0].orgFullId.split('-')[1]
        // this.getdata()
        // this.getshuju()
        // this.getshuju1()
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true)
    },
    mounted() {

    },
    methods: {
        calculateCqNum(row) {
            if (row.kgNum && row.cqNum) {
                const kgNum = parseFloat(row.kgNum)
                const cqNum = parseFloat(row.cqNum)
                return (cqNum - kgNum).toFixed(2)
            }
            return ''
        },
        getDayValue(index, option) {
            const item = this.dataList2[index].passenger.list.find(item => item.typeName === option)
            return item ? item.day.toString() : '0'
        },
        setDayValue1(val, index, option, value, scope, event) {
            console.log(val, index, option, value, scope, event)
            this.add = 0
            if (val.passenger.list.length > 0) {
                val.passenger.list.forEach(item => {
                    this.add += parseFloat(item.day)
                })
            }
            console.log(this.add)

            const num = this.dataList3[index].cqNum - parseInt(val.ztNum) - parseInt(val.cdNum) - val.kgNum - parseInt(this.add)
            this.dataList2[index].cqNum = num < 0 ? 0 : num

            this.saveList.push(this.dataList2[index])
            const filteredArray = this.saveList.reduce(function(acc, obj) {
                const existingObjIndex = acc.findIndex(function(item) {
                    return item.id === obj.id
                })

                if (existingObjIndex !== -1) {
                    // 如果已存在相同id的对象，则替换为新的对象
                    acc[existingObjIndex] = obj
                } else {
                    // 如果不存在相同id的对象，则将对象添加到结果数组中
                    acc.push(obj)
                }
                return acc
            }, [])
            this.saveList = filteredArray
            console.log(this.saveList, 777)
        },
        setDayValue(val, index, option, value, scope, event) {
            debugger
            console.log(val, index, option, value, scope, event)
            this.add = 0
            // val.passenger.list[index].day = value
            if (val.passenger.list.length > 0) {
                val.passenger.list.forEach(item => {
                    this.add += parseFloat(item.day)
                })
            }

            const num = this.dataList3[index].cqNum - parseInt(val.ztNum) - parseInt(val.cdNum) - val.kgNum - parseInt(this.add)
            this.dataList2[index].cqNum = num < 0 ? 0 : num

            console.log(this.add)

            //            const num1 = this.dataList3[index].cqNum - parseFloat(value)
            //
            //            this.dataList2[index].cqNum = num1 < 0 ? 0 : num1
            // const item = this.dataList2[index].passenger.list.find(item => item.typeName === option)
            // this.inputValue = parseInt(this.inputValue, 10).toString()
            // if (item) {
            //     item.day = value || 0.0
            //     // 移除多余的小数点
            //     item.day = item.day.replace(/(\..*)\./g, '$1')
            //     // 验证小数点的数量
            //     const dotCount = (item.day.match(/\./g) || []).length
            //     if (dotCount > 1) {
            //     // 如果小数点的数量超过一个，则删除多余的小数点
            //         item.day = item.day.replace(/\.$/g, '')
            //     }
            // }
        },
        handleInput(val, scope, index) {
            // val.cqNum = val.cqNum - parseInt(val.ztNum)
            console.log(scope, index, 231)

            this.add = 0
            if (scope.row.passenger.list.length > 0) {
                scope.row.passenger.list.forEach(item => {
                    this.add += parseFloat(item.day)
                })
            }

            const num = this.dataList3[index].cqNum - parseInt(val.ztNum) - parseInt(val.cdNum) - val.kgNum - parseInt(this.add)

            this.dataList2[index].cqNum = num < 0 ? 0 : num
            console.log(val, val.cqNum)

            this.saveList.push(val)

            const filteredArray = this.saveList.reduce(function(acc, obj) {
                const existingObjIndex = acc.findIndex(function(item) {
                    return item.id === obj.id
                })

                if (existingObjIndex !== -1) {
                    // 如果已存在相同id的对象，则替换为新的对象
                    acc[existingObjIndex] = obj
                } else {
                    // 如果不存在相同id的对象，则将对象添加到结果数组中
                    acc.push(obj)
                }
                return acc
            }, [])
            this.saveList = filteredArray
            // console.log(1221, this.saveList)
        },
        btnFn(scope) {
            console.log(scope, 'scope')
        },
        statistics(vals) {
            this.dateList = []

            this.selectDept = vals.subOrg.id

            this.$bus.emit('refreshListData')

            this.getshuju(this.time)
        },
        getshuju1() {
            this.dataList2 = this.dataList1.list
            this.dataList2.forEach((item, index) => {
                item.index = index + 1
            })
            console.log(this.dataList2, 1212)
        },
        getshuju() {
            if (this.value === formattedDate) {
                this.showedit = false
            } else {
                this.showedit = true
            }
            const data = {
                pbNy: this.value,
                hzDate: this.value,
                staffDeptId: this.selectDept

            }
            this.$http({
                method: 'POST',
                url: '/yykq/kqhz/AttendanceSummary',
                contentType: 'application/x-www-form-urlencoded',
                params: data
            }).then(res => {
                if (res.code === 'success') {
                    console.log('res', res)
                    this.dataList = res.body.list
                    this.dataList2 = this.dataList.list
                    this.dataList.top = []
                    this.dataList2[0].passenger.list.forEach(item => {
                        this.dataList.top.push(item.typeName)
                    })
                }
                this.dataList2.forEach((item, index) => {
                    item.index = index + 1
                })
                this.dataList3 = JSON.parse(JSON.stringify(this.dataList2))
                console.log(this.dataList2, 9999)
            })
        },
        tosave() {
            this.$http({
                method: 'PUT',
                contentType: 'application/x-www-form-urlencoded',
                data: this.saveList,
                url: '/yykq/kqhz/save'
            }).then(res => {
                if (res.code === 'success') {
                    this.datelist = res.body.listdata
                    this.$message.success('保存成功')
                    this.$bus.emit('refreshListData')
                }
            })
            this.saveList = []
        },
        daoru() {
            this.$bus.emit('openImportForm')
        },
        daochu() {

        },
        getWeekends(year, month) {
            const weekends = []
            const date = new Date(year, month - 1, 1)
            while (date.getMonth() === month - 1) {
                if (date.getDay() === 0 || date.getDay() === 6) {
                    weekends.push(date.getDate())
                }
                date.setDate(date.getDate() + 1)
            }

            return weekends.join(',')
        },
        getDays(year, month) {
            const days = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
            if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
                days[2] = 29
            }

            console.log(days[month])
            this.time = days[month]
        }

    }
}
</script>

<style scoped lang="scss">
.dj{
  padding: 10px !important;
}
.right {
  margin-right: 10px;
  margin-bottom: 10px;
  float: right;
  display: flex;
  justify-content: flex-end;
}
/deep/.el-table th.el-table__cell.is-leaf {
    border: 1px solid #EBEEF5 !important;
}
.table-box {
  width: 100%;
  padding: 0 10px;
}
/deep/.el-table__empty-block{
   border: 1px solid #EBEEF5 !important;
}
.el-table {
  overflow: hidden;
}
/deep/.el-table--scrollable-x .el-table__body-wrapper{
  overflow-x: auto !important;
}
/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: rgba(248, 247, 247, 0.87) !important;
}

/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
  margin: 0 2px !important;
  border-radius: 6px !important;
  background-color: rgba(199, 199, 199, 0.65) !important;
}

/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: hsla(240, 1%, 35%, 0.418)  !important;
}
</style>
