<template>
    <div style="height: 100%; width: 100%">
        <productPanelLogin v-if="loginType === 'ProductPanel'" />
        <rightPanelLogin v-if="loginType === 'RightPanel'" />
        <portalPanelLogin v-if="loginType === 'PortalPanel'" />
        <techPanelLogin v-if="loginType === 'TechPanel'" />
        <platPanelLogin v-if="loginType === 'PlatPanel'" />
        <oldLogin v-if="loginType === 'oldLogin'" />
    </div>
</template>

<script>
import { loginType } from '@/wapp/settings'
import productPanelLogin from './components/panel/productPanelLogin.vue'
import oldLogin from './components/panel/oldLogin.vue'
import rightPanelLogin from './components/panel/rightPanelLogin.vue'
import portalPanelLogin from './components/panel/portalPanelLogin.vue'
import techPanelLogin from './components/panel/techPanelLogin.vue'
import platPanelLogin from './components/panel/platPanelLogin.vue'

export default {
    name: 'Login',
    components: { productPanelLogin, oldLogin, rightPanelLogin, portalPanelLogin, techPanelLogin, platPanelLogin },
    data() {
        return {
            loginType: loginType,
            WpsOfficeShow: false
        }
    },
    computed: {},
    watch: {},
    created() {},
    beforeDestroy() {},
    mounted() {},
    destroyed() {},
    methods: {}
}
</script>

<style></style>
