<template>
  <div class="app-container">
    <statistics-search :search-type="searchType" @statisticsClick="statistics" />
    <!-- <DeptTime :change-dept="changeDept" :change-time="changeTime" file-name="出勤情况统计" @dateInit="dateInit" /> -->
    <div style="margin:20px 0 10px;text-align: center;overflow: hidden;">
      <span class="statistics_title" style="line-height: 30px;">出勤情况统计</span>
      <span style="float:right">
        <el-button
          class="exportbtn"
          plain
          size="mini"
          @click="exportdata"
        ><svg-icon :icon-class="'export'" /> 导出</el-button>
      </span>
    </div>
    <div class="color-line">
      休息: 休&nbsp;&nbsp;&nbsp;无打卡: 无&nbsp;&nbsp;&nbsp;正常: √&nbsp;&nbsp;&nbsp;
      迟到: <div class="colorbox" style="background-color:#f93" />
      早退: <div class="colorbox" style="background-color:#0107f9" />
      缺卡：<div class="colorbox" style="background-color:#f56c6c" />
      请假：<div class="colorbox" style="background-color:#063" />
      加班：<div class="colorbox" style="background-color:#c0f" />
      出差：<div class="colorbox" style="background-color:#ff0080" />
      外勤：<div class="colorbox" style="background-color:#66f" />
    </div>
    <list-table id="exportTable" :list-tb="listTb" :list-data="listData" :today="today" />
  </div>
</template>

<script>

// import DeptTime from '../components/DeptTime'
import StatisticsSearch from '@/wapp/common/components/StatisticsSearch'
import qs from 'qs'

import ListTable from './ListTable'
const moment = require('moment')

export default {
    components: {
        StatisticsSearch, ListTable
    },
    data() {
        return {
            selectDept: '',
            chooseDate: [],
            loginUser: this.$store.getters.loginUser,
            listTb: [],
            listData: [],
            today: '',
            emptyText: '正在加载..',
            loadingMast: null,
            searchType: [
                {
                    type: 'subOrg',
                    title: '统计部门'
                }, {
                    type: 'range',
                    title: '统计周期',
                    rangeType: 'daterange',
                    rangeSeparator: '至',
                    dateFormat: 'yyyy-MM-dd'
                }
            ],
            holidays: []
        }
    },
    computed: {
    },
    watch: {
    },
    created() {
        this.$bus.emit('showNavSearch', false) // 取消右上搜索按钮
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true) // 还原右上搜索按钮
    },
    mounted() {

    },
    methods: {
        async statistics(vals) {
            this.chooseDate = [moment(vals.range[0]).format('YYYY-MM-DD'), moment(vals.range[1]).format('YYYY-MM-DD')]
            this.selectDept = vals.subOrg.id
            await this.getholidaysData()
            await this.columnDate()
            await this.getData()
        },
        async getholidaysData() {
            const startYear = Number(this.chooseDate[0].substr(0, 4))
            const endYear = Number(this.chooseDate[1].substr(0, 4))
            let years = ''
            for (let i = startYear; i <= endYear; i++) {
                if (years !== '')years += ','
                years += i
            }
            const data = [
                { 'compare': 'in', 'field': 'year', 'merge': true, 'value': years },
                { 'compare': '=', 'field': 'orgCode', 'merge': true, 'value': this.loginUser.orgCode }
            ]
            const res = await this.$app.querydata('holiday/days/querydata', data)

            if (res.code === 'success') {
                const holidayList = res.body.listdata
                let holidays = ''
                holidayList.forEach(item => {
                    holidays += item.day
                })
                this.holidays = holidays.split(',')
            }
        },
        // 获取部门人员出勤汇总信息
        getData() {
            if (this.loadingMast !== null) this.loadingMast.close()
            this.emptyText = '正在加载..'
            this.loadingMast = this.$loading({
                target: '.app-container .el-table__body-wrapper'
            })
            this.$http({
                url: 'yykq/homePageIndex/attendanceInfo',
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                params: {
                    startTime: this.chooseDate[0],
                    endTime: this.chooseDate[1],
                    orgCode: this.loginUser.orgCode,
                    deptId: this.selectDept || null
                }
            }).then(res => {
                if (res.code === 'success') {
                    const list = res.body.listdata

                    const listData = []
                    for (let i = 0; i < list.length; i++) {
                        const tempI = list[i]
                        const changeTempI = this.changeStatus(tempI)
                        let pushed = false
                        for (let j = listData.length - 1; j >= 0; j--) {
                            const tempJ = listData[j]
                            if (tempI.DEPARTMENT === tempJ.DEPARTMENT && tempI.ACCOUNT === tempJ.ACCOUNT) {
                                tempJ[tempI.CLOCK_IN_DATE] = changeTempI
                                pushed = true
                                break
                            }
                        }
                        if (!pushed) {
                            tempI[tempI.CLOCK_IN_DATE] = changeTempI
                            tempI.$index = listData.length
                            listData.push(tempI)
                        }
                    }
                    this.listData = listData
                    this.loadingMast.close()
                    if (this.listData.length < 0) {
                        this.emptyText = '暂无数据'
                    }
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 考勤状态转换
        changeStatus(obj) {
            const status = []

            if (obj.late === 1) {
                status.push({ 'status': '迟到', 'color': '#f93' })
            }
            if (obj.leaveEarly === 1) {
                status.push({ 'status': '早退', 'color': '#0107f9' })
            }
            if (obj.absence === 1) {
                status.push({ 'status': '缺卡', 'color': '#f56c6c' })
            }
            if (obj.business === 1) {
                status.push({ 'status': '出差', 'color': '#ff0080' })
            }
            if (obj.overtime === 1) {
                status.push({ 'status': '加班', 'color': '#c0f' })
            }
            if (obj.thing === 1 || obj.sick === 1 || obj.other === 1) {
                status.push({ 'status': '请假', 'color': '#063' })
            }
            if (obj.outwork === 1) {
                status.push({ 'status': '外勤', 'color': '#66f' })
            }
            if (obj.absence === 0 && obj.business === 0 && obj.late === 0 && obj.leaveEarly === 0 && obj.other === 0 && obj.outwork === 0 && obj.overtime === 0 && obj.sick === 0 && obj.thing === 0) {
                status.push({ status: '√', color: 'inherit' })
            }
            return status
        },
        // 表格日期列
        columnDate() {
            this.listTb = []
            const listTb = []
            let startTime = Date.parse(this.chooseDate[0] + ' 00:00:00')
            const endTime = Date.parse(this.chooseDate[1] + ' 23:59:59')
            while (startTime <= endTime) {
                const cell = moment(startTime).format('YYYY-MM-DD')
                const tm = new Date(startTime).getDay()
                const w = tm === 0 ? true : tm > 5
                listTb.push({ 'prop': cell, 'title': cell, isWeek: w, isHoliday: this.holidays.indexOf(cell) > -1 })
                startTime += 86400000
            }
            this.listTb = listTb
        },
        exportdata() {
            const today = moment(new Date()).format('YYYY-MM-DD')

            const title = [
                { 'title': '序号', 'width': 60, 'align': 'center', 'colspan': 1, 'rowspan': 1 },
                { 'title': '姓名', 'width': 100, 'align': 'left', 'colspan': 1, 'rowspan': 1 },
                { 'title': '部门', 'width': 120, 'align': 'left', 'colspan': 1, 'rowspan': 1 }

            ]
            const tbtitle = []
            this.listTb.forEach(tb => {
                title.push({
                    'title': tb.prop,
                    width: 100,
                    align: 'center',
                    colspan: 1,
                    rowspan: 1
                })
            })
            tbtitle.push(title)

            const tbdata = []
            this.listData.forEach((list, listIndex) => {
                const row = [
                    { 'value': listIndex + 1, 'align': 'center', 'colspan': 1, 'rowspan': 1 },
                    { 'value': list.NAME, 'align': 'left', 'colspan': 1, 'rowspan': 1 },
                    { 'value': list.DEPARTMENT, 'align': 'left', 'colspan': 1, 'rowspan': 1 }
                ]

                this.listTb.forEach(tb => {
                    const ogj = { 'value': '', 'align': 'center', 'colspan': 1, 'rowspan': 1 }
                    if (!list[tb.prop]) {
                        ogj.value = tb.prop <= today && tb.isWeek ? '休' : '无'
                    } else {
                        for (let z = 0; z < list[tb.prop].length; z++) {
                            ogj.value += ' ' + list[tb.prop][z].status
                        }
                    }
                    row.push(ogj)
                })
                tbdata.push(row)
            })

            this.$http({
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                data: qs.stringify({ 'rows': JSON.stringify(tbdata), 'titles': JSON.stringify(tbtitle), 'fileName': '出勤情况统计' }),
                url: 'common/exportXlsFile?httpPort=' + location.port
            }).then(res => {
                if (res.code === 'success') {
                    window.open(encodeURI('/apigw/' + res.body.xlsurl), '_blank')
                } else {
                    this.$alert(res.message, '操作提示', { type: 'error' })
                }
            })
        }

    }
}
</script>

<style scoped lang="scss">
    // .top-line{
    //     margin-bottom: 18px;
    //     .label{line-height: 36px;font-size: 14px;width:80px;}
    // }
    .color-line{
        margin-bottom: 10px;font-size:14px;color:#606266;
        .colorbox{width:30px;height:14px;display:inline-block;margin-right:10px;margin-bottom: -2px;}
    }

    /deep/ .el-date-editor .el-range-separator{width: 8%}
</style>
