<template>

  <el-dialog
    v-if="dialogVisible"
    v-el-drag-dialog
    title="查看值班登记"
    :visible.sync="dialogVisible"
    width="800px"
    :close-on-click-modal="false"
  >
    <div>
      <el-form ref="buisform" :model="vo" @submit.native.prevent>
        <div style="width:100%;" class="el-table el-table--fit el-table--border">
          <div class="el-table__body-wrapper">
            <table cellspacing="0" cellpadding="0" border="0" class="el-table__body ">
              <tbody>
                <tr>
                  <td ref="tdlbl_交班日期" class="el-table__cell tdlbl tdw17" colspan="1">
                    <span class="required">*</span>
                    <span v-field-lbl="'handoverDate'" class="field">交班日期</span>
                  </td>
                  <td class="el-table__cell tdcont tdw83" colspan="5">
                    <form-item ref="handoverDate" :field="{'defaultValue':null,'elementCanEdit':'{${canEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':'','elementReadonly':1,'elementType':'date','emptyMessage':'请选择交班日期','errorMessage':null,'formTdAlign':'text-left','formTdColspan':12,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'交班日期','propName':'handoverDate','propType':'java.util.Date','textFormatter':'yyyy-MM-dd HH:mm','valueMaxLength':null,'valueMinLength':null,'model':'vo.handoverDate','option':[],'weekday':0,'stepNum':5}" :vo="vo" :can-edit="canEdit" />
                  </td>
                </tr>
                <tr>
                  <td ref="tdlbl_交班人" class="el-table__cell tdlbl tdw17" colspan="1">
                    <span class="required">*</span>
                    <span v-field-lbl="'handoverPerson'" class="field">交班人</span>
                  </td>
                  <td class="el-table__cell tdcont tdw83" colspan="5">
                    <form-item ref="handoverPerson" :field="{'defaultValue':null,'elementCanEdit':'0','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'请输入交班人','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'交班人','propName':'handoverPerson','propType':'String','textFormatter':'textfield','valueMaxLength':null,'valueMinLength':null,'model':'vo.handoverPerson','option':[],'autocomplete':'off','showWordLimit':false,'dialog':1,'dialogType':'staff','dialogOrgLevel':'','dialogSelectedOrderBy':'orgOrderNo','dialogMultiple':true,'dialogOrgSelf':false}" :vo="vo" :can-edit="canEdit" />
                  </td>
                </tr>
                <tr>
                  <td ref="tdlbl_登记人" class="el-table__cell tdlbl tdw17" colspan="1">
                    <span v-field-lbl="'creator'" class="field">登记人</span>
                  </td>
                  <td class="el-table__cell tdcont tdw33" colspan="2">
                    <form-item ref="creator" :field="{'defaultValue':null,'elementCanEdit':'0','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'请输入登记人','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'登记人','propName':'creator','propType':'String','textFormatter':'textfield','valueMaxLength':null,'valueMinLength':null,'model':'vo.creator','option':[],'autocomplete':'off','showWordLimit':false}" :vo="vo" :can-edit="canEdit" />
                  </td>
                  <td ref="tdlbl_登记时间" class="el-table__cell tdlbl tdw17" colspan="1">
                    <span v-field-lbl="'createTime'" class="field">登记时间</span>
                  </td>
                  <td class="el-table__cell tdcont tdw33" colspan="2">
                    <form-item ref="createTime" :field="{'defaultValue':null,'elementCanEdit':'0','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':'','elementReadonly':1,'elementType':'date','emptyMessage':'','errorMessage':null,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'登记时间','propName':'createTime','propType':'java.util.Date','textFormatter':'yyyy-MM-dd HH:mm','valueMaxLength':null,'valueMinLength':null,'tbFieldName':'createtime','model':'vo.createTime','option':[],'formTdAlign':'text-left','weekday':0,'stepNum':5}" :vo="vo" :can-edit="canEdit" />
                  </td>
                </tr>
                <tr>
                  <td ref="tdlbl_接班人" class="el-table__cell tdlbl tdw17" colspan="1">
                    <span class="required">*</span>
                    <span v-field-lbl="'contactPerson'" class="field">接班人</span>
                  </td>
                  <td class="el-table__cell tdcont tdw33" colspan="2">
                    <form-item ref="contactPerson" :field="{'defaultValue':null,'elementCanEdit':'{${canEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'请输入接班人','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'接班人','propName':'contactPerson','propType':'String','textFormatter':'textfield','valueMaxLength':null,'valueMinLength':null,'model':'vo.contactPerson','option':[],'autocomplete':'off','showWordLimit':false,'dialog':1,'dialogType':'staff','dialogOrgLevel':'','dialogSelectedOrderBy':'orgOrderNo','dialogMultiple':true,'dialogOrgSelf':false}" :vo="vo" :can-edit="canEdit" />
                  </td>
                  <td ref="tdlbl_接班时间" class="el-table__cell tdlbl tdw17" colspan="1">

                    <span v-field-lbl="'contactPersonDate'" class="field">接班时间</span>
                  </td>
                  <td class="el-table__cell tdcont tdw33" colspan="2">
                    <form-item ref="contactPersonDate" :field="{'defaultValue':null,'elementCanEdit':'0','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':'','elementReadonly':1,'elementType':'date','emptyMessage':'','errorMessage':null,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'接班时间','propName':'contactPersonDate','propType':'java.util.Date','textFormatter':'yyyy-MM-dd HH:mm','valueMaxLength':null,'valueMinLength':null,'tbFieldName':'contactpersondate','model':'vo.contactPersonDate','option':[],'formTdAlign':'text-left','weekday':0,'stepNum':5,'formTdColspan':12}" :vo="vo" :can-edit="canEdit" />
                  </td>
                </tr>
                <tr>
                  <td ref="tdlbl_仪器设备情况" class="el-table__cell tdlbl tdw17" colspan="1">
                    <span class="required">*</span>
                    <span v-field-lbl="'instrument'" class="field">仪器设备情况</span>
                  </td>
                  <td class="el-table__cell tdcont tdw83" colspan="5">
                    <form-item ref="instrument" :field="{'defaultValue':null,'elementCanEdit':'{${canEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'textarea','emptyMessage':'请输入仪器设备情况','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'仪器设备情况','propName':'instrument','propType':'String','textFormatter':'textarea','valueMaxLength':null,'valueMinLength':null,'model':'vo.instrument','option':[],'autocomplete':'off','showWordLimit':false,'autosize':false}" :vo="vo" :can-edit="canEdit" :autosize="false" />
                  </td>
                </tr>
                <tr>
                  <td ref="tdlbl_值班情况" class="el-table__cell tdlbl tdw17" colspan="1">
                    <span class="required">*</span>
                    <span v-field-lbl="'dutySituation'" class="field">值班情况</span>
                  </td>
                  <td class="el-table__cell tdcont tdw83" colspan="5">
                    <form-item ref="dutySituation" :field="{'defaultValue':null,'elementCanEdit':'{${canEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'textarea','emptyMessage':'请输入值班情况','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'值班情况','propName':'dutySituation','propType':'String','textFormatter':'textarea','valueMaxLength':null,'valueMinLength':null,'model':'vo.dutySituation','option':[],'autocomplete':'off','showWordLimit':false,'autosize':false}" :vo="vo" :can-edit="canEdit" :autosize="false" />
                  </td>
                </tr>
                <tr>
                  <td ref="tdlbl_待处理工作" class="el-table__cell tdlbl tdw17" colspan="1">
                    <span class="required">*</span>
                    <span v-field-lbl="'pendingWork'" class="field">待处理工作</span>
                  </td>
                  <td class="el-table__cell tdcont tdw83" colspan="5">
                    <form-item ref="pendingWork" :field="{'defaultValue':null,'elementCanEdit':'{${canEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'textarea','emptyMessage':'请输入待处理工作','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'待处理工作','propName':'pendingWork','propType':'String','textFormatter':'textarea','valueMaxLength':null,'valueMinLength':null,'model':'vo.pendingWork','option':[],'autocomplete':'off','showWordLimit':false,'autosize':false}" :vo="vo" :can-edit="canEdit" :autosize="false" />
                  </td>
                </tr>
                <tr v-if="vo.passenger.attachments.length && !canEdit">
                  <td class="el-table__cell tdlbl tdw17" colspan="1">
                    <span class="required">*</span>
                    <span class="field">交班照片</span>
                  </td>
                  <td class="el-table__cell tdcont tdw83" colspan="5">
                    <div style="display: flex;flex-wrap: wrap;margin-bottom: -10px;">
                      <el-image
                        v-for="(item,index) in vo.passenger.attachments"
                        :key="index"
                        style="height: 100px;margin-right: 10px;margin-bottom: 10px;"
                        :src="`/apigw/zbgl/dutyRegistration/downloadById?attachIds=${item.id}`"
                        :preview-src-list="[`/apigw/zbgl/dutyRegistration/downloadById?attachIds=${item.id}`]"
                      >
                      </el-image>
                    </div>
                  </td>
                </tr>
                <tr v-else>
                  <td ref="tdlbl_交接照片" class="el-table__cell tdlbl tdw17">
                    <span class="required">*</span>
                    交接照片
                  </td>
                  <td class="el-table__cell tdcont tdcont" colspan="5">
                    <Attachment ref="attachments" :vo="vo" :is-required="1" accept="*.*" empty-message="" wf-attach-url="zbgl/dutyRegistration" :limit-count="10" online-editor="" online-file="" :attachments="vo.passenger.attachments" :attachs-type="'ATTACH'" />
                  </td>
                </tr>
                <tr>
                  <td ref="tdlbl_备注" class="el-table__cell tdlbl tdw17" colspan="1">
                    <span v-field-lbl="'remark'" class="field">备注</span>
                  </td>
                  <td class="el-table__cell tdcont tdw83" colspan="5">
                    <form-item ref="remark" :field="{'defaultValue':null,'elementCanEdit':'{${canEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'textarea','emptyMessage':'请输入备注','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'备注','propName':'remark','propType':'String','textFormatter':'textarea','valueMaxLength':null,'valueMinLength':null,'model':'vo.remark','option':[],'autocomplete':'off','showWordLimit':false,'autosize':false}" :vo="vo" :can-edit="canEdit" :autosize="false" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">关 闭</el-button>
      <el-button v-if="canEdit" type="primary" @click="formSubmit">确 定</el-button>
    </span>
  </el-dialog>

</template>

<script>
const moment = require('moment')
export default {
    props: {
        vo: { type: Object, default: function() { return {} } },
        canEdit: { type: Number, default: 1 },
        moduleInfo: { type: Object, default: function() { return {} } }
    },
    data: function() {
        return {
            loginUser: this.$store.getters.loginUser,
            dialogVisible: false,
            rowData: ''
        }
    },
    computed: {
    },
    watch: {
    },
    created: function() {
        this.$bus.on('openZbdjForm', this.openZbdjForm)
    },
    beforeDestroy: function() {
        this.$bus.off('openZbdjForm', this.openZbdjForm)
    },
    mounted: function() {
    },
    methods: {
        openZbdjForm(edit = 0, vo, type) {
            this.canEdit = edit
            this.rowData = vo
            if (type === 'add') {
                this.addNewInfo()
            } else if (type === 'get') {
                this.getByDataId()
            }
        },
        getByDataId() {
            this.$http({
                method: 'POST',
                url: 'zbgl/dutyRegistration/querydata',
                data: [{ compare: '=', field: 'dutyRecordId', value: this.rowData.id }],
                params: { orderBy: 'handoverDate desc' }
            }).then(res => {
                if (res.code === 'success') {
                    var list = res.body.listdata
                    if (list.length) {
                        this.vo = list[0]
                        this.dialogVisible = true
                    } else {
                        this.$alert('暂无值班记录', '操作提示', { type: 'warning' })
                        return
                    }
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        addNewInfo() {
            this.$http({
                contentType: 'application/x-www-form-urlencoded',
                url: `zbgl/dutyRegistration/addNew?dutyRecordId=` + this.rowData.id,
                method: 'POST'
            }).then(res => {
                if (res.code === 'success') {
                    this.vo = res.body.vo
                    if (!this.vo.passenger.attachments) this.vo.passenger.attachments = []
                    if (!this.vo.creator) {
                        this.vo.creator = this.loginUser.personName
                        this.vo.handoverPerson = this.loginUser.personName
                        this.vo.handoverPersonId = this.loginUser.personId
                        this.vo.createTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                    }
                    if (this.rowData.id) {
                        this.vo.handoverDate = this.rowData.currentTime
                    }
                    if (!this.vo.dutyRecordId) {
                        this.vo.dutyRecordId = this.rowData.id
                    }
                    console.log('this.vo===', this.vo)
                    this.dialogVisible = true
                } else {
                    this.$message({ message: res.message, type: 'warning' })
                }
            })
        },
        formSubmit() {
            console.log('this==', this)
            var _this = this
            this.$refs.buisform.formValidate(() => {
                console.log('this.vo==', this.vo)
                if (!this.vo.passenger.attachments.length) {
                    this.$alert('交接照片是必须的。', '操作提示', { type: 'warning' })
                    return
                }
                var list = this.vo.passenger.attachments.filter(item => !item.id)
                this.submitHandler(this.vo).then(result => {
                    if (result.code === 'success') {
                        // _this.uploadAttachs(list)
                        _this.wfInstance = { moduleCode: 'dutyRegistration', bodyDocId: '' }
                        _this.$refs.attachments.uploadWflowAttach(_this).then(rest => {
                            if (rest.code === 'success') {
                                this.$message.success('保存成功。')
                                _this.dialogVisible = false
                            }
                        })
                    } else {
                        this.$message({ message: result.message, type: 'warning' })
                    }
                })
            }, null, 1)
        },
        submitHandler(vo) {
            return new Promise((resolve, reject) => {
                this.$http({
                    contentType: 'application/x-www-form-urlencoded',
                    url: `zbgl/dutyRegistration/insert`,
                    method: 'POST',
                    data: vo
                }).then(res => {
                    resolve(res)
                })
            })
        },
        uploadAttachs(list) {
            var _this = this
            var num = 0
            for (let index = 0; index < list.length; index++) {
                const formData = new FormData()
                formData.append('attachsType', 'ATTACH')
                formData.append('module', 'dutyRegistration')
                formData.append('busiDataId', _this.vo.id)
                formData.append('attachsSize', list[index].file.size)
                formData.append('attachsName', list[index].file.name)
                formData.append('file', list[index].file.raw)
                this.$http({
                    method: 'POST',
                    url: '/zbgl/dutyRegistration/upload',
                    data: formData
                }).then(res => {
                    if (res.code === 'success') {
                        num++
                        if (num === list.length) {
                            this.$message.success('保存成功。')
                            this.dialogVisible = false
                        }
                    }
                })
            }
            // var array = []
            // list.forEach(item => {
            //     const fd = new FormData()
            //     fd.append('file', item.file.raw)
            //     fd.append('module', 'dutyRegistration')
            //     fd.append('attachsSize', item.file.size)
            //     fd.append('busiDataId', _this.vo.id)
            //     fd.append('attachsType', 'ATTACH')
            //     fd.append('attachsName', item.file.name)
            //     fd.append('uploadBy', _this.loginUser.personName)
            //     fd.append('uploadById', _this.loginUser.userId)
            //     array.push(this.$http({
            //         method: 'POST',
            //         url: 'zbgl/dutyRegistration/upload',
            //         data: fd
            //     }))
            // })
            // Promise.all(array).then(res => {
            //     console.log('res==', res)
            // })
        },
        onSelectStaffCompleted(selectedNodes, attr) {
            let selectedNames = ''
            let selectedAccountIds = ''
            if (attr.name === 'handoverPerson') {
                selectedNodes.forEach(function(node) {
                    if (selectedNames !== '') selectedNames += '、'
                    if (selectedAccountIds !== '') selectedAccountIds += ','
                    selectedNames += node.name
                    selectedAccountIds += node.personId
                })
                this.$set(this.vo, 'handoverPerson', selectedNames)
                this.$set(this.vo, 'handoverPersonId', selectedAccountIds)
            }
            if (attr.name === 'contactPerson') {
                selectedNodes.forEach(function(node) {
                    if (selectedNames !== '') selectedNames += '、'
                    if (selectedAccountIds !== '') selectedAccountIds += ','
                    selectedNames += node.name
                    selectedAccountIds += node.personId
                })
                this.$set(this.vo, 'contactPerson', selectedNames)
                this.$set(this.vo, 'contactPersonId', selectedAccountIds)
            }
        }

    }
}
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  margin-top: 6vh !important;
}
</style>
