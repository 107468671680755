<template>
  <div class="LayoutTitle" :style="{ opacity: opacity }">
    {{ title }}
  </div>
</template>

<script>
export default {
    props: {
        title: { type: String, default: '', require: true },
        opacity: { type: String, default: '0.5' }
    }
}
</script>

<style lang="scss" scoped>
.LayoutTitle{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    word-break: break-all;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 10px;
    color: #666666;
    font-weight: bold;
    pointer-events: none;
    user-select: none;
}
</style>
