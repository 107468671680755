<template>
  <PageContent :title="formTitle">
    <el-form :model="vo">
      <div class="el-table el-table--fit el-table--border">
        <div class="el-table__body-wrapper">
          <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
            <tbody>
              <tr>
                <td class="el-table__cell tdlbl tdw17">行政区域</td>
                <td class="el-table__cell tdcont tdw83" colspan="3">
                  <el-input v-model="vo.treeNode" v-select-dialog="'area'" placeholder="请选择行政区域" name="treeNode" />
                </td>
              </tr>
              <tr>
                <td class="el-table__cell tdlbl tdw17">机构名称</td>
                <td class="el-table__cell tdcont tdw33">
                  <el-input v-model="vo.orgName" v-select-dialog="'rootorg'" placeholder="请选择机构名称" name="orgName" />
                </td>
                <td class="el-table__cell tdlbl tdw17">机构代码</td>
                <td class="el-table__cell tdcont tdw33">
                  <el-input v-model="vo.orgCode" v-select-dialog="'rootorg'" placeholder="请选择机构代码" name="orgCode" />
                </td>
              </tr>
              <tr v-if="!forceUpdate">
                <td class="el-table__cell tdlbl tdw17">主办部门</td>
                <td class="el-table__cell tdcont tdw83" colspan="3">
                  <el-input v-model="vo.mainsend" v-select-dialog="'suborg'" org-level="2" dialog-title="选择主办部门" multiple="true" :root-org-code="vo.orgCode" placeholder="请选择主办部门" name="mainsend" />
                </td>
              </tr>
              <tr v-if="!forceUpdate">
                <td class="el-table__cell tdlbl tdw17">主办部门(扩展)</td>
                <td class="el-table__cell tdcont tdw83" colspan="3">
                  <el-input v-model="vo.mainsendExt" v-select-dialog="'suborgExt'" org-level="2,3" dialog-title="选择主办部门" multiple="true" :root-org-code="vo.orgCode" placeholder="请选择主办部门" name="mainsendExt" />
                </td>
              </tr>
              <tr v-if="!forceUpdate">
                <td class="el-table__cell tdlbl tdw17">工作群组</td>
                <td class="el-table__cell tdcont tdw83" colspan="3">
                  <el-input v-model="vo.workgroup" v-select-dialog="'workgroup'" :root-org-code="vo.orgCode" placeholder="请选择工作群组" name="workgroup" />
                </td>
              </tr>
              <tr v-if="!forceUpdate">
                <td class="el-table__cell tdlbl tdw17">工作岗位</td>
                <td class="el-table__cell tdcont tdw83" colspan="3">
                  <el-input v-model="vo.jobPosition" v-select-dialog="'jobPosition'" :root-org-code="vo.orgCode" multiple="true" placeholder="请选择工作岗位" name="jobPosition" />
                </td>
              </tr>
              <tr v-if="!forceUpdate">
                <td class="el-table__cell tdlbl tdw17">机构员工</td>
                <td class="el-table__cell tdcont tdw83" colspan="3">
                  <el-input v-model="vo.orgStaff" v-select-dialog="'staff'" type="textarea" :row="2" multiple="true" :root-org-code="vo.orgCode" placeholder="请选择机构员工" name="orgStaff" resize="none" />
                </td>
              </tr>
              <tr v-if="!forceUpdate">
                <td class="el-table__cell tdlbl tdw17">集团员工(扩展)</td>
                <td class="el-table__cell tdcont tdw83" colspan="3">
                  <el-input v-model="vo.orgStaffExt" v-select-dialog="'staffExt'" multiple="tru2e" org-level="2,3,4" type="textarea" :row="2" :root-org-code="vo.orgCode" placeholder="请选择机构员工" name="orgStaffExt" resize="none" />
                </td>
              </tr>
              <tr v-if="!forceUpdate">
                <td class="el-table__cell tdlbl tdw17">所有人员</td>
                <td class="el-table__cell tdcont tdw83" colspan="3">
                  <el-input v-model="vo.allStaff" v-select-dialog="'allStaff'" type="textarea" :row="2" multiple="true" placeholder="请选择人员" name="allStaff" resize="none" />
                </td>
              </tr>
              <tr v-if="!forceUpdate">
                <td class="el-table__cell tdlbl tdw17">所有机构</td>
                <td class="el-table__cell tdcont tdw83" colspan="3">
                  <el-input v-model="vo.allOrg" v-select-dialog="'allOrg'" type="textarea" :row="2" multiple="true" placeholder="请选择机构" name="allOrg" resize="none" />
                </td>
              </tr>
              <tr v-if="!forceUpdate">
                <td class="el-table__cell tdlbl tdw17">所有机构人员</td>
                <td class="el-table__cell tdcont tdw83" colspan="3">
                  <el-input v-model="vo.allNode" v-select-dialog="'allNode'" type="textarea" :row="2" multiple="true" placeholder="请选择机构人员" name="allNode" resize="none" />
                </td>
              </tr>
              <tr>
                <td class="el-table__cell tdlbl tdw17">部门员工</td>
                <td class="el-table__cell tdcont tdw83" colspan="3">
                  <el-input v-model="vo.deptStaff" v-select-dialog="'staff'" type="textarea" :row="2" multiple="true" placeholder="请选择部门员工" name="deptStaff" resize="none" />
                </td>
              </tr>
              <tr>
                <td class="el-table__cell tdlbl tdw17">工作群组成员</td>
                <td class="el-table__cell tdcont tdw83" colspan="3">
                  <el-input v-model="vo.workgroupStaff" v-select-dialog="'workgroupStaff'" type="textarea" :row="2" multiple="true" :root-org-code="vo.orgCode" placeholder="工作群组成员" name="workgroupStaff" resize="none" />
                </td>
              </tr>
              <tr>
                <td class="el-table__cell tdlbl tdw17">工作岗位人员</td>
                <td class="el-table__cell tdcont tdw83" colspan="3">
                  <el-input v-model="vo.jobPositionStaff" v-select-dialog="'jobPositionStaff'" type="textarea" :row="2" multiple="true" :root-org-code="vo.orgCode" placeholder="工作岗位人员" name="jobPositionStaff" resize="none" />
                </td>
              </tr>
              <tr>
                <td class="el-table__cell tdlbl tdw17">动态列表</td>
                <td class="el-table__cell tdcont tdw83" colspan="3">
                  <el-input
                    v-model="vo.clientSystemList"
                    v-listpage-dialog
                    listviewurl="workflow.wfInstance.list"
                    dialog-title="选择文件"
                    exclude-fields="status"
                    retain-btns="addObject"
                    type="textarea"
                    :row="2"
                    :multiple="true"
                    placeholder="请选择文件"
                    name="wfInstanceList"
                    resize="none"
                  />
                </td>
              </tr>
              <tr>
                <td class="el-table__cell tdlbl tblbl-left" colspan="4">
                  内嵌子表示例
                  <el-button v-table-toxls="innerTable" file-name="tableToxls导出表格测试" type="text">导出</el-button>
                </td>
              </tr>
              <tr>
                <td class="el-table__cell tdcont tdcont-innertb" colspan="4">
                  <inner-table id="innerTable" ref="workgroupList" :listviewurl="'base.workGroup.list'" :exclude-fields="'status'" :can-edit="0" :can-delete="1" :link-prop-name="'orgCode'" />
                </td>
              </tr>
              <tr>
                <td class="el-table__cell tdlbl tdw17">过滤器运行效果示例</td>
                <td class="el-table__cell tdcont tdw83" colspan="3">
                  uppercaseFirst：abc=>{{ 'abc'|uppercaseFirst }}<br>
                  lowercaseFirst：One=>{{ 'One'|lowercaseFirst }}<br>
                  currency('¥',2)：3000=>{{ 3000|currency }}<br>
                  toThousandFilter：3000=>{{ 3000|toThousandFilter }}<br>
                  formatTime：{{ morningS }}=>{{ morningS|formatTime }}<br>
                  numberFormatter(1)：1024=>{{ 1024|numberFormatter(1) }}<br>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-form>

  </PageContent>
</template>

<script>
const moment = require('moment')
import { byteLength } from '@/global/utils/index'

export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            formTitle: '常用选择框',
            vo: { 'id': 'plat' },
            canEdit: 1,
            forceUpdate: false,
            morning: null,
            curTime: null
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        this.morningS = moment(new Date()).format('YYYY/MM/DD') + ' 08:00:00'
        this.morningD = new Date(this.morningS)

        this.$bus.$emit('showNavSearch', false)
    },
    beforeDestroy() {
        this.$bus.$emit('showNavSearch', true)
    },
    mounted() {
        const t = '测，,'
        console.log(byteLength(t))
    },
    methods: {
        // 选择目录
        onSelectTreeNodeCompleted(selectedNodes, attr) {
            let selected = ''
            selectedNodes.forEach(function(node) {
                if (selected !== '') selected += '、'
                selected += node.name
            })
            this.$set(this.vo, attr.name, selected)
        },
        // 选择机构
        onSelectRootorgCompleted(selectedNodes, attr) {
            let selected = ''
            let selectCode = ''
            selectedNodes.forEach(function(node) {
                if (selected !== '') selected += '、'
                if (selected !== '') selectCode += '、'
                selected += node.name
                selectCode += node.ocode
            })
            this.$set(this.vo, 'orgName', selected)
            this.$set(this.vo, 'orgCode', selectCode)

            this.forceUpdate = true
            this.$nextTick(() => {
                this.forceUpdate = false
            })
        },
        // 选择部门、科室、班组
        onSelectSuborgCompleted(selectedNodes, attr) {
            let selected = ''
            selectedNodes.forEach(function(node) {
                if (selected !== '') selected += '、'
                selected += node.name
            })
            this.$set(this.vo, attr.name, selected)
        },
        // 选择工作群组
        onSelectWorkgroupCompleted(selectedNodes, attr) {
            let selected = ''
            selectedNodes.forEach(function(node) {
                if (selected !== '') selected += '、'
                selected += node.name
            })
            this.$set(this.vo, attr.name, selected)
        },
        // 选择工作岗位
        onSelectJobPositionCompleted(selectedNodes, attr) {
            let selected = ''
            selectedNodes.forEach(function(node) {
                if (selected !== '') selected += '、'
                selected += node.name
            })
            this.$set(this.vo, attr.name, selected)
        },
        // 选择人员
        onSelectStaffCompleted(selectedNodes, attr) {
            let selected = ''
            selectedNodes.forEach(function(node) {
                if (selected !== '') selected += '、'
                selected += node.name + '/' + node.account
            })
            this.$set(this.vo, attr.name, selected)
        },
        // 选择所有机构
        onSelectAllOrgCompleted(selectedNodes, attr) {
            let selected = ''
            selectedNodes.forEach(function(node) {
                if (selected !== '') selected += '、'
                selected += node.name
            })
            this.$set(this.vo, attr.name, selected)
        },
        // 选择所有机构人员
        onSelectAllNodeCompleted(selectedNodes, attr) {
            let selected = ''
            selectedNodes.forEach(function(node) {
                if (selected !== '') selected += '、'
                if (node.personId) {
                    selected += node.name + '/' + node.account
                } else {
                    selected += node.name
                }
            })
            this.$set(this.vo, attr.name, selected)
        },
        // 通用列表选择对话框-加载数据前回调
        onDialogListDataBeforeLoad($scope, qparams) {
            console.log($scope.moduleInfo.moduleCode, 'onDialogListDataBeforeLoad')
        },
        // 通用列表选择对话框-加载数据后回调
        onDialogListDataLoaded($scope, pageInfo) {
            console.log($scope.moduleInfo.moduleCode, 'onDialogListDataLoaded')
        },
        // 通用列表选择对话框-选择数据后回调
        onSelectListpageRowCompleted(selectedRows, attr) {
            console.log(attr.listviewurl, 'onSelectListpageRowCompleted')
            this.$set(this.vo, attr.name, JSON.stringify(selectedRows))
        },
        // 内嵌子表-加载数据前回调
        onInnerTableListDataBeforeLoad($scope, qparams) {
            console.log($scope.moduleInfo.moduleCode, 'onInnerTableListDataBeforeLoad')
        },
        // 内嵌子表-加载数据后回调
        onInnerTableListDataLoaded($scope, pageInfo) {
            console.log($scope.moduleInfo.moduleCode, 'onInnerTableListDataLoaded')
        },
        // 内嵌子表-子表单打开前回调
        onInnerFormBeforeOpen($scope, vo, defer) {
            console.log($scope.moduleInfo.moduleCode, 'onInnerFormBeforeOpen')
            defer.resolve(true)
        },
        // 内嵌子表-子表单提交前回调
        onInnerBasicFormBeforeSubmit($scope, vo, defer) {
            console.log($scope.moduleInfo.moduleCode, 'onInnerBasicFormBeforeSubmit')
            defer.resolve(true)
        },
        // 内嵌子表-表头按钮方法
        selectGroup(module) {
            alert('innertable header btn click:' + module)
            // 代码模拟触发指令
            const el = {
                attrs: { 'listviewurl': 'base.clientSystem.list',
                    'dialog-title': '选择接入系统',
                    'exclude-fields': 'status',
                    'retain-btns': 'addObject,editObject'
                }
            }
            const vnode = {
                context: this
            }
            this.$bus.emit('openListpageDialog', el, null, vnode)
        }
    }
}
</script>

<style scoped lang="scss">
.tblbl-left{
    /deep/ .el-button{
        float:right;
    }
}
</style>
