<template>
  <div>
    <el-dialog
      title="排班登记"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div v-if="JSON.stringify(vo) !== '{}'">
        <el-form ref="changeForm" :model="vo" style="margin: 10px 0px;text-align: center">
          <div class="el-table el-table--fit el-table--border">
            <div class="el-table__body-wrapper">
              <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
                <tbody>
                  <tr>
                    <td class="el-table__cell tdlbl tdw33" colspan="2"><span class="required">*</span>排班人员</td>
                    <td class="el-table__cell tdcont tdw67" colspan="4">
                      <!-- <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="排班人员" rules="required">
                        <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                          <el-input
                            v-model="vo.name"
                            v-select-dialog="'staff'"
                            type="input"
                            multiple="false"
                            :root-org-code="loginUser.orgCode"
                            name="name"
                            placeholder="请选择排班人员"
                          >
                          </el-input>
                          <div>{{ vo.name }}</div>
                        </el-tooltip>
                      </ValidationProvider> -->
                      <div>{{ vo.name }}</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="el-table__cell tdlbl tdw33" colspan="2"><span class="required">*</span>排班日期</td>
                    <td class="el-table__cell tdcont tdw67" colspan="4">
                      <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="排班日期" rules="required">
                        <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                          <el-date-picker
                            v-model="vo.data[0].pbDate"
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请选择排班日期"
                          />
                        </el-tooltip>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td class="el-table__cell tdlbl tdw33" colspan="2">排班结束日期</td>
                    <td class="el-table__cell tdcont tdw67" colspan="4">
                      <el-date-picker
                        v-model="vo.data[0].endDate"
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="请选择排班结束日期"
                      />
                    </td>
                  </tr>
                  <tr>
                    <!-- <td class="el-table__cell tdlbl tdw33" colspan="2"><span class="required">*</span>班次</td> -->
                    <td class="el-table__cell tdlbl tdw33" colspan="2">班次</td>
                    <td class="el-table__cell tdcont tdw67" colspan="4">
                      <!-- <ValidationProvider v-if="bcglsList.length" ref="ValidationProvider" v-slot="{ errors }" name="班次" rules="required">
                        <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark"> -->
                      <el-checkbox-group v-if="bcglsList.length" v-model="checkList">
                        <el-checkbox v-for="item in bcglsList" :key="item.id" :label="item">{{ item.bcName }}</el-checkbox>
                      </el-checkbox-group>
                      <!-- </el-tooltip>
                      </ValidationProvider> -->
                      <span v-else>暂无班次</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="el-table__cell tdlbl tdw33" colspan="2"><span class="required">*</span>是否排除节假日</td>
                    <td class="el-table__cell tdcont tdw67" colspan="4">
                      <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="是否排除节假日" rules="required">
                        <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                          <el-radio-group v-model="vo.data[0].filterHoliday">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                          </el-radio-group>
                        </el-tooltip>
                      </ValidationProvider>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeHandler">关 闭</el-button>
        <el-button type="primary" @click="saveHandler('1')">保 存</el-button>
        <el-button type="primary" @click="saveHandler('2')">保存并关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            vo: {},
            dialogVisible: false,
            loginUser: this.$store.getters.loginUser,
            checkList: [], // 选中的班次
            radio: '',
            bcglsList: [] // 所有班次
        }
    },
    computed: {

    },
    watch: {
        'checkList': {
        	immediate: true,
            handler(newVal, oldVal) {
                console.log('newVal', newVal)
            }
        }
    },
    created() {
        this.$bus.on('openPbDialog', this.openPbDialog)
    },
    beforeDestroy() {
        this.$bus.off('openPbDialog', this.openPbDialog)
    },
    mounted() {

    },
    methods: {
        openPbDialog(row, index, list) {
            this.checkList = []
            this.vo = row
            this.bcglsList = list
            var bclist = []
            if (index !== undefined && index !== 'undefined') {
                if (this.vo.data[0].passenger.listdata[index].recordInfos) {
                    bclist = this.vo.data[0].passenger.listdata[index].recordInfos
                    this.checkList = this.bcglsList.filter(v => bclist.some(val => v.id === val.id))
                }
                this.vo.data[0].filterHoliday = this.vo.data[0].passenger.listdata[index].filterHoliday
            }
            this.dialogVisible = true
        },
        saveHandler(type) {
            console.log('vo', this.vo)
            this.formValidate(() => {
                this.$http({
                    contentType: 'application/x-www-form-urlencoded',
                    url: `/yykq/rypbkqdj/scheduling`,
                    method: 'POST',
                    params: {
                        staffId: this.vo.data[0].staffId,
                        staffDeptId: this.vo.data[0].staffDeptId,
                        startDate: this.vo.data[0].pbDate,
                        endDate: this.vo.data[0].endDate,
                        filterHoliday: Number(this.vo.data[0].filterHoliday)
                    },
                    data: this.checkList
                }).then(res => {
                    if (res.code === 'success') {
                        this.$message.success('保存成功。')
                        this.$vnode.context.getListData()
                        if (type === '2') { // 保存关闭
                            this.checkList = []
                            this.dialogVisible = false
                        }
                    }
                })
            }, null, 1)
        },
        // 关闭
        closeHandler() {
            this.checkList = []
            this.vo = {}
            this.dialogVisible = false
        }
    }
}
</script>

<style scoped lang="scss">

</style>
