<template>
  <div>
    <el-upload v-if="canedit == 1" class="my-avatar-uploader" action="" :show-file-list="false" :on-change="handleUploadChange" :multiple="false" :auto-upload="false" :disabled="canedit != 1" :accept="'.jpg,.png'" :with-credentials="true">
      <!-- action 必选参数，上传的地址 默认值为- -->
      <!-- show-file-list 是否显示已上传文件列表 默认值为true  -->
      <!-- multiple 是否支持多选文件 默认值为- -->
      <!-- auto-upload 是否在选取文件后立即进行上传 默认值为true -->
      <!-- disabled 是否禁用 默认值为false -->
      <!-- accept 接受上传的文件类型（thumbnail-mode 模式下此参数无效 默认值为- https://developer.mozilla.org/zh-CN/docs/Web/HTML/Element/Input/file -->
      <!-- with-credentials 支持发送 cookie 凭证信息 默认值为false -->
      <el-input v-model="picName" :readonly="true" class="point" :placeholder="placeholder">
        <el-button slot="append" icon="el-icon-upload2" />
      </el-input>
    </el-upload>
    <el-divider v-if="imageUrl && showImg && canedit == 1" class="diveder" />
    <img v-if="imageUrl && showImg" :src="imageUrl" class="my-avatar">
  </div>
</template>
<script>
export default {
    props: {
        vo: { type: Object, default: function () { return {} }, required: true }, // vo取id和文件名
        canedit: { type: Number, required: true },
        module: { type: String, default: '', required: true }, // 上传文件所需参数
        uploadurl: { type: String, default: '', required: true }, // 下载图片url
        downloadurl: { type: String, default: '', required: true }, // 上传图片url
        datafield: { type: String, default: '', required: true }, // 下载图片所需参数-- 对应表单里 字段属性-属性值
        namefield: { type: String, default: '', required: true }, // 下载图片所需参数--对应表单里 字段属性-文件名称字段
        necessary: { type: Boolean, default: true, required: true }, // 图片是否是必须的
        placeholder: { type: String, default: '', required: true }, // 图片为空时，输入框占位符
        warnMsg: { type: String, default: '请上传图片', required: false } // 图片必须上传时 没上传图片的提醒信息
    },
    data: function () {
        return {
            loginUser: this.$store.getters.loginUser,
            imageUrl: '',
            picName: '',
            showImg: false,
            fd: null
        }
    },
    computed: {},
    created() {
        this.base_api = process.env.VUE_APP_BASE_API
        let url = this.downloadurl
        if (url[0] !== '/') {
            url = '/' + url
        }
        this.imageUrl = this.base_api + url + '?id=' + this.vo.id + '&dataField=' + this.datafield + '&nameField=' + this.namefield + '&httpPort=' + window.location.port
        this.picName = this.vo[this.namefield]
        if (this.vo[this.namefield]) { // 有文件名则显示图片
            this.showImg = true
        }
    },
    beforeDestroy() {
        if (typeof this.imageUrl !== 'string') {
            URL.revokeObjectURL(this.imageUrl)
        }
    },
    methods: {
        handleUploadChange(val) { // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
            if (typeof this.imageUrl !== 'string') {
                URL.revokeObjectURL(this.imageUrl)
            }
            this.imageUrl = URL.createObjectURL(val.raw)
            console.log('imageUrl', this.imageUrl)
            this.showImg = true
            this.picName = val.name
            const files = val.raw
            const fileData = new FormData()
            fileData.append('file', files)
            fileData.append('id', this.vo.id)
            fileData.append('module', this.module)
            this.fd = fileData
            //   this.upLoadPhoto()
        },
        upLoadPhoto() {
            if (this.fd !== null) { // 有上传文件
                let url = this.uploadurl
                if (url[0] !== '/') {
                    url = '/' + url
                }
                return this.$http({
                    method: 'PUT',
                    url: url,
                    data: this.fd
                })
            } else {
                if (this.necessary && !this.vo[this.namefield]) { // 图片是必须的
                    return Promise.reject({ 'code': 'failure', 'message': this.warnMsg })
                } else {
                    return Promise.resolve({ 'code': 'success' })
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
// 表单内样式
.my-avatar-uploader {
  line-height: 0;
  position: relative;
  overflow: hidden;
  /deep/ .el-upload {
    width: 100% !important;
  }
}
.my-avatar {
  width: 100px;
  height: auto;
  display: block;
  margin-bottom: 7px;
  margin-top: 7px;
}
.diveder {
  margin: 10px 0;
}
.point > input {
  cursor: pointer;
}
</style>
