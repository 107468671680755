<template>
  <div>
    <span>{{ row.minNum }}</span>
    <span v-if="row.minUnit===1">分钟</span>
    <span v-if="row.minUnit===2">小时</span>
    <span v-if="row.minUnit===3">天</span>
  </div>
</template>

<script>
export default {
    props: {
        column: { type: Object, default: null },
        row: { type: Object, default: null },
        index: { type: Number, default: null }
    }
}
</script>

<style scoped lang="scss">

</style>
