<template>
  <div style="height: 350px;">
    <echart-columnar
      ref="columnar"
      :x-data="xdata"
      :y-data="ydata"
      :y-unit="yunit"
      :x-unit="xUnit"
      right="3%"
      :x-label-rotate="45"
      :tooltip-format="tooltipformat"
      :echarts-click="echartsClick"
    />
    <!-- <div v-else style="text-align:center;">
      <img src="images/w-imgs/nomessage.png"><br>
      <span style="font-size:15px;color:#dbdbdb">暂无数据</span>
    </div> -->
  </div>
</template>

<script>
import { EchartColumnar } from '@/wapp/common/components/Echarts/index'
export default {
    components: {
        EchartColumnar
    },
    props: {
    },
    data() {
        return {
            loginUser: this.$store.getters.loginUser,
            xdata: ['dasda'],
            ydata: [[1]],
            yunit: { name: '日均工作时长（小时）', align: 'end', size: '13', minInterval: 1 },
            xUnit: { name: '部门', align: 'end', 'size': '12' },
            ids: []
        }
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {
        setTimeout(() => {
            this.getData()
        }, 50)
    },
    methods: {
        // 获取数据，部门日均工作时长统计
        getData() {
            this.$http({
                url: 'yykq/homePageIndex/deptDailyWorkTime',
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                params: {
                    startTime: this.$vnode.context.chooseDate[0],
                    endTime: this.$vnode.context.chooseDate[1],
                    orgCode: this.$vnode.context.orgCode
                }
            }).then(res => {
                if (res.code === 'success') {
                    const allList = res.body.listdata
                    const x = []
                    const y = []
                    const ids = []
                    allList.forEach(function(val) {
                        x.push(val.DEPARTMENT)
                        y.push(val.workTime || 0)
                        ids.push(val.DEPARTMENT_ID)
                    })
                    this.xdata = x
                    this.ydata = []
                    this.ydata.push(y)
                    this.ids = ids
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        tooltipformat(param) {
            let tip = ''
            param.forEach(function(val) {
                tip = val.name + '<br/>日均工作时长：' + (val.value || 0) + '小时'
            })
            return tip
        },
        echartsClick(column) {
            const id = this.ids[column.dataIndex]
            this.$emit('echartsClick', id)
        }
    }
}
</script>

<style scoped lang="scss">
</style>
