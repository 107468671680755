<template>
  <div class="app-container">
    <data-table
      ref="linkedTb"
      :module-info="moduleInfo"
      :tb-columns="tbColumns"
      :toolbar-btns="listpageBtns"
      :custom-toolbar-btns="null"
      :on-list-data-before-load="onListDataBeforeLoad"
      :on-list-data-loaded="onListDataLoaded"
      :on-delete-successed="onDeleteSuccessed"
    />
  </div>
</template>

<script>

export default {
    props: {
        platform: { type: Object, default: function() { return {} } }
    },
    data() {
        return {
            moduleInfo: {
                listDataUrl: 'mpp/platform/findLinkedOrganInfo'
            },
            tbColumns: [
                {
                    field: 'state',
                    checkbox: true,
                    width: '45px'
                },
                {
                    field: 'index',
                    title: '序号',
                    width: '45px',
                    align: 'center',
                    valign: 'middle'
                },
                {
                    field: 'name',
                    width: '250px',
                    title: '机构名称',
                    align: 'left',
                    valign: 'middle',
                    cellStyle: { css: { cursor: 'pointer' }},
                    searchable: false,
                    sortable: true
                },
                {
                    field: 'code',
                    title: '机构编码',
                    width: '150px',
                    align: 'center',
                    valign: 'middle',
                    cellStyle: { css: { cursor: 'pointer' }},
                    sortable: false,
                    searchable: true
                },
                {
                    field: 'treeName',
                    title: '所属区域',
                    width: '',
                    align: 'left',
                    valign: 'middle',
                    cellStyle: { css: { cursor: 'pointer' }},
                    searchable: true,
                    sortable: false
                },

                {
                    field: 'status',
                    title: '机构状态',
                    width: '80px',
                    align: 'center',
                    valign: 'middle',
                    cellStyle: { css: { cursor: 'pointer' }},
                    searchable: true,
                    elementFormatter: '1：有效\n0：无效',
                    listAdvSearchElement: 'radio',
                    propType: 'Short'
                }

            ],
            // 列表页面操作按钮定义
            listpageBtns: []
        }
    },
    mounted() {
        if (this.platform.linkOrgType === 2) {
            this.listpageBtns = [
                {
                    btnName: '添加',
                    btnIcon: 'plus',
                    btnClick: this.selectLinkOrgan,
                    btnPermission: 'selectLinkOrgan',
                    id: 'btn_selectLinkOrgan',
                    btnType: 'toolbar'
                },
                {
                    btnName: '移除',
                    btnIcon: 'close',
                    btnClick: this.deleteLinkedOrgan,
                    btnPermission: 'deleteLinkedOrgan',
                    id: 'btn_deleteLinkedOrgan',
                    btnType: 'toolbar'
                },
                {
                    id: 'btn_deleteId',
                    btnName: '移出',
                    btnIcon: 'delete',
                    btnClick: this.deleteLinkedOrgan,
                    btnPermission: 'deleteLinkedOrgan',
                    btnType: 'column'
                }
            ]

            this.tbColumns.push({
                field: 'column_operate',
                title: '操作',
                width: '70px',
                align: 'center',
                valign: 'middle'
            })
        } else {
            this.listpageBtns = []
        }
    },
    methods: {
        selectLinkOrgan() {
            if (this.platform.isnew === true) {
                this.$alert('请先保存应用平台基本信息', '操作提示', { type: 'warning' })
                return
            }
            // 代码模拟触发指令
            const el = {
                attrs: { }
            }
            const vnode = {
                context: this,
                data: { attrs: { 'old-value': '' }, model: { value: '', expression: 'vo.linkedOrgan' }}
            }
            this.$bus.emit('openSelectDialog', el, { value: 'rootorg' }, vnode, 'tree')
        },
        onSelectRootorgCompleted(selectedNodes, attr) {
            const _this = this

            this.$http({
                method: 'POST',
                url: 'mpp/platform/saveLinkedOrgan',
                params: { platId: this.platform.id, orgCode: selectedNodes[0].ocode }
            }).then(response => {
                if (response.code === 'success') {
                    _this.$refs['linkedTb'].loadListdata()
                } else {
                    this.error = response.msg
                }
            })
        },
        deleteLinkedOrgan(row) {
            const _this = this
            let selectedRows = null
            if (row !== undefined) {
                selectedRows = [row]
            } else {
                selectedRows = this.$ref['linkedTb'].selectedRows
            }
            this.$http({
                method: 'DELETE',
                url: 'mpp/platform/deleteLinkedOrgan?platId=' + this.platform.id,
                data: selectedRows
            }).then(response => {
                if (response.code === 'success') {
                    _this.$refs['linkedTb'].loadListdata()
                } else {
                    this.error = response.msg
                }
            })
        },
        /**
        * 加载数据准备，qparams为查询参数对象，不需要返回值
        */
        onListDataBeforeLoad($scope, qparams) {
            if (process.env.NODE_ENV === 'development') console.log('linked organ listdata before load..')

            if (this.platform.linkOrgType === 0) {
                qparams.nodeName = ''
            } else if (this.platform.linkOrgType === 1) {
                qparams.nodeName = this.platform.linkedArea || ''
            } else {
                qparams.platId = this.platform.id
            }
        },
        /**
        * 加载数据完成，pageInfo为包含查询结果的分页信息对象，不需要返回值
        */
        onListDataLoaded($scope, pageInfo) {
            if (process.env.NODE_ENV === 'development') console.log('linked organ listdata loaded')
        },

        /**
        * 数据删除成功，ids为已删除成功的organ对象ID数组，即将刷新列表，不需要返回值
        */
        onDeleteSuccessed($scope, ids) {
            if (process.env.NODE_ENV === 'development') console.log('linked organ delete successed')
        }
    }
}
</script>
