<template>
  <!-- 加班榜 -->
  <div class="module-layout">
    <BlockFrame :header="{title:appdata.name,icon:appdata.icon,more:true,code:'kqglKqpm'}">
      <div ref="messageContent" class="message-content">
        <div v-if="list.length > 0" class="bw-echarts">
          <div class="quick-content">
            <div v-for="(item,index) in (list.length > 10 ? list.slice(0,10) : list)" :key="index" class="list">
              <svg-icon
                v-if="index===0"
                :icon-class="'jxgl_w_champion'"
                style="width: 16px; height: 23px;position: relative;top: 3px;"
              />
              <svg-icon
                v-if="index===1"
                :icon-class="'jxgl_w_second'"
                style="width: 16px; height: 23px;position: relative;top: 3px;"
              />
              <svg-icon
                v-if="index===2"
                :icon-class="'jxgl_w_third'"
                style="width: 16px; height: 23px;position: relative;top: 3px;"
              />
              <span
                v-if="index===0||index===1||index===2"
                style="color:#FFFFFF;position: absolute;left: 4px;top: 2px;"
              >{{ index+1 }}</span>
              <span v-if="index!==0&&index!==1&&index!==2" style="color:#999999;margin-right: 11px;position: relative;left: 4px;">{{ index+1 }}</span>
              <span style="margin-left: 10px">{{ item.name }}</span>
              <span style="position: absolute;right: 0px;">{{ item.workOverTime }}</span>
            </div>
          </div>
        </div>
        <div v-else slot="empty" style="text-align:center;padding-top:30px;">
          <img src="images/w-imgs/nomessage.png" style="max-width:180px"><br>
          <span style="font-size:15px;color:#dbdbdb">暂无数据</span>
        </div>
      </div>
    </BlockFrame>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BlockFrame from '@/wapp/common/components/BlockFrame/index'
export default {
    // import引入的组件需要注入到对象中才能使用
    components: {
        BlockFrame
    },
    props: {
        name: { type: String, default: '' },
        appdata: { type: Object, default: function() { return {} } },
        appletData: {
            type: Object,
            required: true,
            default() {
                return { }
            }
        },
        searchType: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            list: [],
            star: null,
            end: null,
            pageSize: 0,
            loginUser: this.$store.getters.loginUser
        }
    },
    computed: {
        ...mapGetters([
            'routes'
        ])
    },
    // 监控data中的数据变化
    watch: {
        $route: {
            handler() {
                this.getCreateMenu()
            },
            deep: true
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.getLastMonth()
        this.getLastMonthAndDay()
        this.$nextTick(() => {
            this.pageSize = parseInt(this.$refs.messageContent.clientHeight / 40)
            this.getCreateMenu()
        })
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() {}, // 生命周期 - 创建之前
    beforeMount() {}, // 生命周期 - 挂载之前
    beforeUpdate() {}, // 生命周期 - 更新之前
    updated() {}, // 生命周期 - 更新之后
    beforeDestroy() {}, // 生命周期 - 销毁之前
    destroyed() {}, // 生命周期 - 销毁完成
    activated() {},
    // 方法集合
    methods: {
        // 获取上个月日期
        async getLastMonth() {
            var date = new Date()
            var year = date.getFullYear()
            var month = date.getMonth()
            if (month === 0) {
                year = year - 1
                month = 12
            }
            this.star = `${year}-${month}-1`
        },
        // 获取上个月月底日期
        async  getLastMonthAndDay() {
            var nowDate = new Date()
            var year = nowDate.getFullYear()
            var month = nowDate.getMonth()
            if (month === 0) {
                month = 12
                year = year - 1
            }
            var lastDay = new Date(year, month, 0)
            this.end = year + '-' + month + '-' + lastDay.getDate()
        },
        // 获取排行榜数据
        async  getCreateMenu() {
            var param = {
                pageNum: 1,
                pageSize: this.pageSize,
                // startTime: '2021-07-01',
                // endTime: '2022-02-23',
                startTime: this.star,
                endTime: this.end,
                orgCode: this.loginUser.orgCode
            }
            if (this.$route.meta.orgFullId) param.deptId = this.$route.meta.orgFullId.split('-')[1]
            this.$http({
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: $.param(param),
                url: '/yykq/homePageIndex/workOverTimeRank'
            }).then(response => {
                if (response.code === 'success') {
                    this.list = response.body.pageInfo.list
                } else {
                    this.$message.error(response.message)
                }
            })
        },
        // 更多
        onMoreClick() {
            if (this.appletData.url.indexOf('?') > 1 && this.appletData.url.split('?')[1].indexOf('productCode') > -1) {
                const search = this.appletData.url.split('?')[1]
                if (search.indexOf('&') > -1) {
                    const searchary = search.split('&')
                    searchary.forEach(item => {
                        if (item.indexOf('productCode') > -1) {
                            this.$bus.emit('redirectMoreClick', item.split('=')[1])
                        }
                    })
                } else {
                    this.$bus.emit('redirectMoreClick', search.split('=')[1])
                }
            }
        }
        // async  getCreateMenu() {
        //     this.$http({
        //         contentType: 'application/x-www-form-urlencoded',
        //         url: '/yykq/homePageIndex/workOverTimeRank',
        //         method: 'POST',
        //         data: {
        //             pageNum: 1,
        //             pageSize: 5,
        //             startTime: this.star,
        //             endTime: this.end,
        //             orgCode: this.loginUser.orgCode
        //         }
        //     }).then((res) => {
        //         if (res.code === 'success') {
        //             this.list = res.body.pageInfo.list
        //         } else {
        //             this.$message.error(res.message)
        //         }
        //     })
        // }
    } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.module-layout {
  width: 100%;
  height: 102%;
  .message-content{
      height: 100%;
  }
}
.dialog-quick-content{
  &:after{
    content:".";
    visibility:hidden;
    display:block;
    clear:both;
    }
  .list {
    font-size: 13px;
    color:#303133;
    height: 40px;
    float: left;
    line-height: 40px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    width: 50%;
  }
}
.bw-echarts {
  font-size: 14px;
  width: 100%;
}
.title-top {
  height: 39px;
  line-height: 37px;
  padding: 0 16px;
  .left {
    float: left;
    span {
      display: inline-block;
      padding-left: 10px;
    }
  }
}
.quick-content {
  width: 100%;
  padding: 10px 20px;
  .list {
    font-size: 13px;
    color:#303133;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    position: relative;
    border-bottom: 1px solid #eee !important;
  }
}
</style>
