<template>
  <div id="SchedulesList">
    <data-table
      ref="dataTable"
      :module-info="moduleInfo"
      :tb-columns="tbColumns"
      :toolbar-btns="null"
      :on-list-data-before-load="onListDataBeforeLoad"
      :on-list-data-loaded="onListDataLoaded"
      :on-cell-click="toEditSchedules"
    >
    </data-table>
  </div>
</template>

<script>
export default {
    name: 'SchedulesList',
    props: {
        filterData: { type: Object, require: true, default: () => ({
            clockInDate: '',
            deptId: '',
            deptName: ''
        })
        }
    },
    data() {
        return {
            loginUser: this.$store.getters.loginUser,
            moduleInfo: {
                'listpageId': '55ccdf9d6ae844a3ad68f45429bfe7a7',
                'addNewUrl': 'yykq/personSch/addNew',
                'insertUrl': 'yykq/personSch/insert',
                'getByIdUrl': 'yykq/personSch/getById',
                'updateUrl': 'yykq/personSch/update',
                'delUrl': 'yykq/personSch/delete',
                'listDataUrl': 'yykq/personSch/listdata?pbType=1',
                'winWidth': 1000,
                'winHeight': 600,
                'moduleCode': 'personSch',
                'openViewType': '0',
                'listAdvSearchAllFields': 0,
                'listAdvSearchExpandPanel': 0 },
            tbColumns: [
                { 'field': 'state', 'checkbox': true, 'width': '45px', 'align': 'center', 'sortable': false },
                { 'align': 'center', 'field': 'index', 'title': '序号', 'width': '45px', 'sortable': false, 'listAdvSearchElement': '', 'searchable': false, 'propType': '' },
                { 'align': 'center', 'field': 'creatorOrgName', 'title': '科室', 'width': '130px', 'sortable': true, 'listAdvSearchElement': 'input', 'searchable': true, 'listAdvSearchIndex': 0, 'propType': 'String' },
                { 'align': 'center', 'field': 'personName', 'title': '参与考勤人员', 'width': '', 'sortable': true, 'listAdvSearchElement': 'input', 'searchable': true, 'listAdvSearchIndex': 1, 'propType': 'String' },
                { 'align': 'center', 'field': 'classesName', 'title': '班次', 'width': '', 'sortable': true, 'listAdvSearchElement': 'input', 'searchable': true, 'listAdvSearchIndex': 2, 'propType': 'String' },
                { 'align': 'center', 'field': 'updatePerson', 'title': '操作人', 'width': '120px', 'sortable': true, 'listAdvSearchElement': 'input', 'searchable': true, 'listAdvSearchIndex': 3, 'propType': 'String' },
                { 'align': 'center', 'field': 'modifyDate', 'title': '操作日期', 'width': '150px', 'sortable': true, 'listAdvSearchElement': 'date', 'searchable': true, 'listAdvSearchIndex': 4, 'propType': 'java.util.Date', 'propFormatter': 'format：yyyy年MM月dd日' },
                { 'align': 'center', 'field': 'action', 'title': '操作', 'width': '100px', 'sortable': false, 'listAdvSearchElement': '', 'searchable': false, 'listAdvSearchIndex': 5, 'propType': 'String', 'propFormatter': 'function：actionButtons' }
            ]
        }
    },
    methods: {
        onListDataBeforeLoad($scope, qparams) {
            qparams.urlsearch = `pbDate like ${this.filterData.clockInDate};`
            qparams.orderBy = 'createDate desc'
            if (!this.$store.state.user.roles.includes('kqManager')) {
                const orgArr = this.loginUser.staffList[0].orgFullId.split('-')
                qparams.urlsearch += `creatorOrgId:${orgArr[orgArr.length - 1]}`
            }
        },
        actionButtons(createElement, row, column, cellValue, index, $scope) {
            // $scope表示data-table组件
            if (row.canAdjusted === 1) {
                return createElement('span', {
                    'class': {
                        'row_action': true
                    },
                    style: {
                        color: '#409eff'
                    },
                    domProps: {
                        innerHTML: '调整'
                    },
                    on: {
                        click: () => {
                            this.toEditSchedules(row)
                        }
                    }
                })
            } else {
                return createElement('span', {
                    domProps: {
                        innerHTML: '调整'
                    },
                    style: {
                        color: '#C0C4CC'
                    }
                })
            }
        },
        toEditSchedules(row) {
            const selectedCellData = {
                departmentId: row.creatorOrgId,
                department: row.creatorOrgName,
                personSchId: row.id
            }
            this.$bus.emit('openPbDialog', Object.assign({}, row, selectedCellData))
        },
        exportTableData() {
            this.$refs.dataTable.exportWform()
        }
    }
}
</script>

<style lang="scss" scoped>
#SchedulesList {
  padding: 15px;
  /deep/ .row_action:hover{
    color:#409eff;
    text-decoration: underline;
  }
}
</style>
