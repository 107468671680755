<template>
    <div id="tags-view-container" class="tags-view-container">
        <scroll-pane v-if="updateViews" ref="scrollPane" class="tags-view-wrapper">
            <router-link v-for="tag in visitedViews" ref="tag" :key="tag.fullPath" :class="isActive(tag) ? 'active' : ''" :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }" tag="span" class="tags-view-item" @click.middle.native="closeSelectedTag(tag)" @contextmenu.prevent.native="openMenu(tag, $event)">
                {{ tag.title }}
                <span v-if="!tag.meta.affix" class="el-icon-close" @click.prevent.stop="closeSelectedTag(tag)" />
            </router-link>
            <div v-if="visitedViews.length === 0" disabled class="tags-view-item designTime">最近应用</div>
        </scroll-pane>
        <ul v-show="visible" :style="{ left: left + 'px', top: top + 'px' }" class="contextmenu">
            <li @click="refreshSelectedTag(selectedTag)">
                {{ $t('tagsView.refresh') }}
            </li>
            <li v-if="!(selectedTag.meta && selectedTag.meta.affix)" @click="closeSelectedTag(selectedTag)">
                {{ $t('tagsView.close') }}
            </li>
            <li @click="closeOthersTags">
                {{ $t('tagsView.closeOthers') }}
            </li>
            <li @click="closeAllTags(selectedTag)">
                {{ $t('tagsView.closeAll') }}
            </li>
        </ul>
    </div>
</template>

<script>
import path from 'path'
export default {
    components: {},
    data() {
        return {
            visible: false,
            top: 0,
            left: 0,
            selectedTag: {},
            affixTags: [],
            updateViews: true
        }
    },
    computed: {
        visitedViews() {
            return this.$store.state.tagsView.visitedViews
        },
        routes() {
            return this.$store.state.route.routes
        }
    },
    watch: {
        $route() {
            this.addTags()
            this.moveToCurrentTag()
        },
        visible(value) {
            if (value) {
                document.body.addEventListener('click', this.closeMenu)
            } else {
                document.body.removeEventListener('click', this.closeMenu)
            }
        },
        visitedViews: {
            handler() {
                this.updateViews = false
                this.$nextTick(() => {
                    this.updateViews = true
                })
            },
            deep: true
        }
    },
    mounted() {
        this.initTags()
        this.addTags()
    },
    methods: {
        generateTitle(meta) {
            return this.$i18n.generateTitle(meta, this)
        }, // generateTitle by vue-i18n
        isActive(route) {
            return route.fullPath === this.$route.fullPath || (route.meta.tagTitle !== undefined && route.meta.tagTitle === this.$route.meta.tagTitle)
        },
        filterAffixTags(routes, basePath = '/') {
            let tags = []
            routes.forEach((route) => {
                if (route.meta && route.meta.affix) {
                    const tagPath = path.resolve(basePath, route.path)
                    tags.push({
                        fullPath: tagPath,
                        path: tagPath,
                        name: route.name,
                        meta: { ...route.meta }
                    })
                }
                if (route.children) {
                    const tempTags = this.filterAffixTags(route.children, route.path)
                    if (tempTags.length >= 1) {
                        tags = [...tags, ...tempTags]
                    }
                }
            })
            return tags
        },
        initTags() {
            const affixTags = (this.affixTags = this.filterAffixTags(this.routes))
            for (const tag of affixTags) {
                // Must have tag name
                if (tag.name) {
                    this.$store.dispatch('tagsView/addVisitedView', tag)
                }
            }
        },
        addTags() {
            if (this.visitedViews.length > 40) return
            let needAdd = true
            if (this.$route.meta) {
                if (this.$route.meta.level === 1) {
                    needAdd = false
                } else {
                    if (this.$route.matched.length > 0) {
                        const array = []
                        this.$route.matched.forEach((item) => {
                            if (item.alias.length > 0) {
                                array.push(item.alias[0])
                            }
                        })
                        this.$route.meta.tagTitle = array.join('-')
                    }
                }
            }
            const { name } = this.$route
            if (needAdd && name) {
                this.$store.dispatch('tagsView/addView', this.$route)
            }

            return needAdd
        },
        moveToCurrentTag() {
            const tags = this.$refs.tag
            if (typeof tags === 'undefined') return
            this.$nextTick(() => {
                for (const tag of tags) {
                    if (tag.to.path === this.$route.path) {
                        this.$refs.scrollPane.moveToTarget(tag)
                        // when query is different then update
                        if (tag.to.fullPath !== this.$route.fullPath) {
                            this.$store.dispatch('tagsView/updateVisitedView', this.$route.query)
                        }
                        break
                    }
                }
            })
        },
        refreshSelectedTag(view) {
            this.$store.dispatch('tagsView/delCachedView', view).then(() => {
                const { fullPath } = view
                this.$nextTick(() => {
                    this.$router.replace({
                        path: '/redirect' + fullPath
                    })
                })
            })
        },
        closeSelectedTag(view) {
            this.$store.dispatch('tagsView/delView', view).then(({ visitedViews }) => {
                if (this.isActive(view)) {
                    this.toLastView(visitedViews, view)
                }
            })
        },
        closeOthersTags() {
            this.$router.push(this.selectedTag)
            this.$store.dispatch('tagsView/delOthersViews', this.selectedTag).then(() => {
                this.moveToCurrentTag()
            })
        },
        closeAllTags(view) {
            this.$store.dispatch('tagsView/delAllViews').then(({ visitedViews }) => {
                if (this.affixTags.some((tag) => tag.fullPath === view.fullPath)) {
                    return
                }
                this.toLastView(visitedViews, view)
            })
        },
        toLastView(visitedViews, view) {
            const latestView = visitedViews.slice(-1)[0]
            if (latestView) {
                this.$router.push(latestView)
            } else {
                // now the default is to redirect to the home page if there is no tags-view,
                // you can adjust it according to your needs.
                if (view.name === 'Dashboard') {
                    // to reload home page
                    this.$router.replace({ path: '/redirect' + view.fullPath })
                } else {
                    this.$router.push('/')
                }
            }
        },
        openMenu(tag, e) {
            const menuMinWidth = 105
            const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
            const offsetWidth = this.$el.offsetWidth // container width
            const maxLeft = offsetWidth - menuMinWidth // left boundary
            const left = e.clientX - offsetLeft + 15 // 15: margin right
            if (left > maxLeft) {
                this.left = maxLeft
            } else {
                this.left = left
            }
            this.top = e.clientY - 60
            this.visible = true
            this.selectedTag = tag
        },
        closeMenu() {
            this.visible = false
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/wapp/common/styles/variables.module.scss';
/deep/.scroll-container .el-scrollbar__bar.is-horizontal {
    height: 6px !important;
}
.tags-view-container {
    height: $menuItemHeight;
    width: 100%;
    background: #fff;
    padding: 0 10px;
    //   border-bottom: 1px solid #ececec;

    .tags-view-wrapper {
        line-height: 50px;
        /deep/ .el-scrollbar__wrap {
            .el-scrollbar__view {
                // padding: 5px 10px 0;
            }
        }
        .tags-view-item {
            display: inline-block;
            position: relative;
            cursor: pointer;
            height: 45px;
            line-height: 45px;
            color: $dark;
            background: #fff;
            border: none;
            padding: 0 12px;
            font-size: 12px;
            margin: 0 1px;
            //   border-bottom:1px solid #ececec;
            &:last-of-type {
                margin-right: 3px;
            }
            &.active {
                color: var(--subjectColor);
                background: #f2f3f9;
                font-weight: bold;
                border-radius: 5px 5px 0 0;
            }
            &:hover {
                color: var(--subjectColor);
                background: #f2f3f9;
                border-radius: 5px 5px 0 0;
            }
            &::after {
                position: absolute;
                content: '';
                border-right: 1px solid #f2f3f9;
                right: -1px;
                width: 1px;
                height: 29px;
                top: 8px;
            }
            &:last-child::after {
                display: none;
            }
        }
        .designTime {
            background: white;
            opacity: 0.5;
            margin-top: -1px;
        }
    }
    .contextmenu {
        margin: 0;
        background: #fff;
        z-index: 3000;
        position: absolute;
        list-style-type: none;
        padding: 5px 0;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #333;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
        li {
            margin: 0;
            padding: 7px 16px;
            cursor: pointer;
            &:hover {
                background: #eee;
            }
        }
    }
}
.tags-view-container {
    .tags-view-wrapper {
        border-bottom: 1px solid #eff1f7;
        .tags-view-item {
            &.active {
                // color:var(--bgColor);
            }
            &:hover {
                // background:var(--lmBgColor);
            }
        }
    }
}
</style>

<style lang="scss">
.tags-view-wrapper {
    .tags-view-item {
        .el-icon-close {
            width: 20px;
            height: 20px;
            font-size: 14px;
            font-weight: bold;
            /* width: 16px;
      height: 16px; */

            vertical-align: 2px;
            border-radius: 50%;
            text-align: center;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transform-origin: 100% 50%;
            &:before {
                /* transform: scale(.6); */
                display: inline-block;
                vertical-align: -3px;
            }
            &:hover {
                background-color: #b4bccc;
                color: #fff;
            }
        }
    }
}
</style>
