<template>
  <div>

    <div v-if="canEdit" class="left-cont">
      <validation-provider v-slot="{ errors }" name="请假类型" :rules="required ? { required: required } : {}">
        <el-tooltip :disabled="errors===undefined||errors[0]===undefined" :content="errors===undefined?'':errors[0]" :class="{'invalid-field':errors!==undefined&&errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
          <el-select v-model="vo.flLeaveType" placeholder="请选择请假类型">
            <el-option
              v-for="item in vacateType"
              :key="item.id"
              :label="item.typeName"
              :value="item.typeCode"
            />
          </el-select>
        </el-tooltip>
      </validation-provider>
    </div>
    <div v-else class="left-cont">
      {{ vacateTypeName }}
    </div>

    <!-- <div v-show="$vnode.context.yeardays>0 && $vnode.context.showYeardays" class="right-cont">剩余{{ $vnode.context.yeardays }}天</div> -->
  </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        vo: { type: Object, default: function() { return {} } },
        canEdit: { type: Boolean, default: function() { return true } },
        required: { type: Boolean, default: function() { return true } }
    },
    data() {
        return {

        }
    },
    computed: {
        vacateType() {
            const typeArr = []
            this.vo.passenger.vacateType.forEach((item) => {
                if (item.status !== 2 && item.deleteFlag !== 1) {
                    typeArr.push(item)
                }
            })
            return typeArr
        },
        vacateTypeName() {
            let name = ''
            this.vo.passenger.vacateType.forEach((item) => {
                if ((item.typeCode - 0) === (this.vo.flLeaveType - 0)) {
                    name = item.typeName
                }
            })
            return name
        }
    },
    watch: {

    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
    .left-cont {
        float: left;
        margin-right: 10px;
    }
    .right-cont {
        float: left;
        color: red;
    }
</style>
