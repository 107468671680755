<template>
  <PageContent :title="formTitle">
    <el-form>
      <div class="el-table el-table--fit el-table--border">
        <div class="el-table__body-wrapper">
          <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
            <tbody>
              <tr>
                <td class="el-table__cell tdcont">选择本地照片上传编辑自己的头像</td>
              </tr>
              <tr>
                <td class="el-table__cell tdcont">
                  <el-upload
                    :action="base_api + moduleInfo.uploadPhotoUrl"
                    :on-change="fileChange"
                    :on-error="handleUploadError"
                    :on-success="handleUploadSuccess"
                    accept="image/jpeg, image/png"
                    :auto-upload="false"
                    :show-file-list="false"
                  >
                    <el-button size="small" type="primary">请选择图片</el-button>
                    <div class="el-upload__tip">支持jpg、png格式的图片<br>请上传10M以下，像素150*150以上 图片，以保证头像清晰度</div>
                  </el-upload>
                </td>
              </tr>
              <tr>
                <td class="el-table__cell tdcont">头像预览</td>
              </tr>
              <tr>
                <td class="el-table__cell tdcont">
                  <div class="avatar">
                    <img :src="fileUrl || photoUrl || blankPhotoUrl" width="150" height="150">
                    <p>150*150</p>
                  </div>
                  <div class="avatar thumb">
                    <img :src="fileUrl || photoUrl || blankPhotoUrl" width="80" height="80">
                    <p>80*80</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-form>
    <div slot="footer">
      <el-button plain icon="el-icon-check" @click="uploadFile">保 存</el-button>
    </div>
  </PageContent>
</template>

<script>
export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            formTitle: '修改头像',
            moduleInfo: {
                uploadPhotoUrl: 'base/user/upload',
                getByIdUrl: 'base/user/getById'
            },
            photoFile: null,
            photoUrl: '',
            blankPhotoUrl: '/images/w-imgs/photo/blank_1.png',
            avatar2: '/images/w-imgs/photo/blank_2.png',
            fileUrl: '',
            base_api: ''
        }
    },
    computed: {
        avatar() {
            return this.$store.getters.avatar
        },
        curUser() {
            return this.$store.getters.loginUser
        }
    },
    watch: {

    },
    created() {
        this.$bus.emit('showNavSearch', false) // 取消右上搜索按钮
        this.base_api = process.env.VUE_APP_BASE_API
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true) // 还原右上搜索按钮
    },
    mounted() {
        // 获取用户是否已经上传头像,如果是则让img标签的src地址=获取后台头像的地址
        if (this.avatar) {
            this.photoUrl = this.avatar
            this.photoUrl += '&httpPort=' + location.port
        } else {
            this.$http({ // 访问用户基本设置信息
                method: 'GET',
                contentType: 'application/x-www-form-urlencoded',
                params: { id: this.curUser.userId },
                url: this.moduleInfo.getByIdUrl
            }).then(response => {
                if (response.code === 'success') {
                    const userData = response.body.vo
                    this.blankPhotoUrl = (userData.sex === 1 || userData.sex === null || userData.sex === undefined) ? this.blankPhotoUrl : this.avatar2
                } else {
                    this.$message.error(response.message)
                    console.log(response.message)
                }
            }, err => {
                console.log(err)
            })
        }
    },
    methods: {
        // 图片上传的方法
        uploadFile() {
            if (!this.photoFile) {
                this.$message.warning('请选择头像图片')
                return
            }
            const fd = new FormData()
            fd.append('file', this.photoFile[0].raw)
            fd.append('id', this.curUser.userId)
            fd.append('dataField', 'photoData')
            fd.append('nameField', 'photoName')
            this.$http({
                method: 'PUT',
                url: this.moduleInfo.uploadPhotoUrl,
                data: fd
            }).then(data => {
                // 上传成功
                if (data.code === 'success') {
                    this.$message.success('头像修改成功')
                    location.reload()
                } else {
                    this.$message.error(data.message)
                }
            })
        },
        // 选择文件
        fileChange(file, files) {
            if (this.beforeAvatarUpload(file)) {
                const f = []
                f.push(file)
                this.photoFile = f
            }
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png'
            const isLt2M = file.raw.size / 1024 / 1024 < 10

            if (!isJPG) {
                this.$message.error('头像只能是 JPG/PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('头像大小不能超过 2MB!')
            }
            if (isJPG && isLt2M) {
                this.fileUrl = URL.createObjectURL(file.raw)
            }
            return isJPG && isLt2M
        },
        handleUploadError(err, file, fileList) {
            this.$message.error(err.message)
        },
        handleUploadSuccess(response, file, fileList) {
            this.$message.success(response.message)
        }
    }
}
</script>

<style scoped lang="scss">
    .el-table td{
        padding:4px;
    }
    .el-upload__tip{
        line-height: 16px;
        position: absolute;
        left:106px;
        top:0;
        text-align:left;
    }
    .avatar{
        float:left;
        &.thumb{
            margin-left: 20px;
            padding-top: 70px;
        }
        img{
            border-radius: 50%;
            border: 1px solid #ddd;
        }
        p{
            margin:0;
            line-height:18px;
            text-align:center;
        }
    }

</style>

