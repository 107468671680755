<template>
  <el-dialog v-if="dialogVisible" v-el-drag-dialog class="dialogTable" :title="title" :visible.sync="dialogVisible" :append-to-body="false" :close-on-click-modal="false" width="1100px" @close="dialogClose">
    <div class="right-toolbar-container">
      <el-input v-model="searchText" placeholder="搜索..." clear="searchBar" :style="'width:'+(multiple?'867px':'100%')" @keyup.enter.native="doSearchText">
        <el-button slot="append" icon="el-icon-search" style="width:62px" @click.native="doSearchText" />
      </el-input>
    </div>
    <data-table
      :mode="'dialog'"
      :module-info="moduleInfo"
      :tb-columns="fieldsArr[args.id]"
      :toolbar-btns="listpageBtns"
      :on-cell-click="onCellClick"
      :on-list-data-before-load="onListDataBeforeLoad"
      :on-list-data-loaded="onListDataLoaded"
    />
    <span slot="footer">
      <el-button plain icon="el-icon-close" @click="dialogClose">关 闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
    name: '',
    data() {
        return {
            curUser: this.$store.getters.loginUser,
            dialogVisible: false,
            args: {},
            moduleInfo: {
                listDataUrl: ''
            },
            searchText: '',
            fieldsArr: {
                '1': [
                    { field: 'index', title: '序号', width: '45px', align: 'center', valign: 'middle' },
                    { field: 'name', title: '姓名', width: '100px', align: 'left', valign: 'middle' },
                    { field: 'DEPARTMENT', title: '部门', width: '', align: 'left', valign: 'middle' },
                    { field: 'workDays', title: '出勤天数', width: '100px', align: 'center', valign: 'middle' }, // 天
                    { field: 'workTimes', title: '工作时长', width: '100px', align: 'center', valign: 'middle' },
                    { field: 'avgWorkTimes', title: '日均总时长', width: '100px', align: 'center', valign: 'middle' }
                ],
                '2': [
                    { field: 'index', title: '序号', width: '45px', align: 'center', valign: 'middle' },
                    { field: 'NAME', title: '姓名', width: '100px', align: 'left', valign: 'middle' },
                    { field: 'DEPARTMENT', title: '部门', width: '', align: 'left', valign: 'middle' },
                    { field: 'lateNum', title: '迟到次数', width: '100px', align: 'center', valign: 'middle' }, // 次
                    { field: 'comeLate', title: '迟到时长', width: '100px', align: 'center', valign: 'middle' }
                ],
                '3': [
                    { field: 'index', title: '序号', width: '45px', align: 'center', valign: 'middle' },
                    { field: 'name', title: '姓名', width: '100px', align: 'left', valign: 'middle' },
                    { field: 'deptName', title: '部门', width: '', align: 'left', valign: 'middle' },
                    { field: 'leaveEarlyNum', title: '早退次数', width: '100px', align: 'center', valign: 'middle' }, // 次
                    { field: 'leaveEarly', title: '早退时长', width: '早退时长', align: 'center', valign: 'middle' }
                ],
                '7': [
                    { field: 'index', title: '序号', width: '45px', align: 'center', valign: 'middle' },
                    { field: 'name', title: '姓名', width: '100px', align: 'left', valign: 'middle' },
                    { field: 'deptName', title: '部门', width: '', align: 'left', valign: 'middle' },
                    { field: 'workOverNum', title: '加班次数', width: '100px', align: 'center', valign: 'middle' }, // 次
                    { field: 'workOverTime', title: '加班时长', width: '100px', align: 'center', valign: 'middle' }
                ],
                '9': [
                    { field: 'name', title: '姓名', width: '100px', align: 'left', valign: 'middle' },
                    { field: 'dept', title: '部门', width: '', align: 'left', valign: 'middle' },
                    { field: 'totalNums', title: '请假次数', width: '100px', align: 'center', valign: 'middle' }, // 次
                    { field: 'affairTimes', title: '事假总时长', width: '100px', align: 'center', valign: 'middle' }, // 天
                    { field: 'illTimes', title: '病假总时长', width: '100px', align: 'center', valign: 'middle' }, // 天
                    { field: 'otherTimes', title: '其他假总时长', width: '100px', align: 'center', valign: 'middle' }// 天
                ],
                '10': [
                    { field: 'NAME', title: '姓名', width: '100px', align: 'left', valign: 'middle' },
                    { field: 'DEPARTMENT', title: '部门', width: '', align: 'left', valign: 'middle' },
                    { field: 'absenceNum', title: '旷工次数', width: '100px', align: 'center', valign: 'middle' }, // 次
                    { field: 'absenceTime', title: '旷工时长', width: '100px', align: 'center', valign: 'middle' }
                ],
                chooseDate: []
            }
        }
    },
    created() {
        this.$bus.on('rankDetailDialogOpen', this.dialogOpen)
    },
    beforeDestroy() {
        this.$bus.off('rankDetailDialogOpen', this.dialogOpen)
    },
    methods: {
        dialogOpen(list, args, title, dates) {
            this.args = args
            this.title = title
            this.chooseDate = dates
            this.moduleInfo.listDataUrl = args.api
            this.searchText = ''
            this.dialogVisible = true
        },
        onListDataBeforeLoad($scope, qparam) {
            qparam.startTime = this.chooseDate[0]
            qparam.endTime = this.chooseDate[1]
            qparam.orgCode = this.curUser.orgCode
            if (this.searchText !== '') {
                qparam.keyName = this.searchText
            }
        },
        onListDataLoaded($scope, pageInfo) {
            // pageInfo.list.forEach(list => {
            //     if (Number(this.args.id) === 1) {
            //         list.workDays += '次'
            //     } else if (Number(this.args.id) === 2) {
            //         list.lateNum += '次'
            //     } else if (Number(this.args.id) === 3) {
            //         list.leaveEarlyNum += '次'
            //     } else if (Number(this.args.id) === 7) {
            //         list.workOverNum += '次'
            //     } else if (Number(this.args.id) === 9) {
            //         list.totalNums += '次'
            //         list.illTimes += '天'
            //         list.workOverNum += '天'
            //         list.otherTimes += '天'
            //     } else if (Number(this.args.id) === 10) {
            //         list.absenceNum += '次'
            //     }
            // })
        },
        doSearchText() {
            this.$bus.emit('refreshListData')
        },
        onCellClick(row, column, index) {
            this.$bus.emit('rankPersonageDialogOpen', row, this.args.id, this.args, this.chooseDate)
        },
        dialogClose() {
            this.searchText = ''
            this.dialogVisible = false
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/ .el-table-toolbar{
    display: none;
}
.right-toolbar-container{margin-bottom:10px ;}
.dialogTable{
  /deep/ .el-dialog{
    .el-dialog__body{max-height: 680px;}
  }
}
</style>
