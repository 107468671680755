import { render, staticRenderFns } from "./Menubar.vue?vue&type=template&id=529fdda9&scoped=true"
import script from "./Menubar.vue?vue&type=script&lang=js"
export * from "./Menubar.vue?vue&type=script&lang=js"
import style0 from "./Menubar.vue?vue&type=style&index=0&id=529fdda9&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "529fdda9",
  null
  
)

export default component.exports