<template>
  <div>
    <div class="box-card" @click.self="docFn">
      <div v-if="!isShowList" style="width:100%;text-align:center;font-size:24px;color:#333333;margin:20px 0 30px 0;">全文查询</div>
      <div>

        <div style="margin: 0 auto;width:1000px" class="searchinput1">
          <div>
            <el-tabs v-model="type" @tab-click="handleClick">
              <el-tab-pane label="全部" name="全部"></el-tab-pane>
              <el-tab-pane label="文档" name="文档"></el-tab-pane>
              <el-tab-pane label="公文" name="公文"></el-tab-pane>
              <el-tab-pane label="流程" name="流程"></el-tab-pane>
              <el-tab-pane label="通讯录" name="通讯录"></el-tab-pane>
              <el-tab-pane label="附件" name="附件"></el-tab-pane>
            </el-tabs>
          </div>
          <div style="display: flex;position: relative;">
            <div style="border: 2px solid var(--subjectColor);width:calc(100% - 100px);border-radius: 4px 0 4px 4px;">
              <el-input
                v-model="input2"
                class="searchinput"
                placeholder="搜索..."
                clearable
                style="height: 40px;"
                @focus.self="focusFn"
                @change.self="blurFn"
                @keyup.enter.native="searchinput"
              >

              </el-input>
              <div v-if="ifShowLisi&&listdatas.length>0">
                <div style="border-top: 1px solid #ccc;margin: 0 10px;">
                  <div v-for="(item,index) in listdatas" :key="index" style="height: 40px;line-height: 40px;" @click.self="listdataclick(item.value)">{{ item.value }}</div>
                  <div style="height: 40px;line-height: 40px;text-align:right;"><span style="font-size:13px;color:#ccc;cursor: pointer" @click.self="zuijdelete">清空历史记录</span></div>
                </div>
              </div>
            </div>
            <div style="cursor:pointer;height: 44px;width: 100px;color: #ffff;background: var(--subjectColor);border-radius: 0 4px 4px 0;line-height: 44px;text-align: center;position: absolute;top: 0;right: 2px;" @click.self="searchinput"> 查询</div>
          </div>
          <div v-if="listdatas.length>0&&!isShowList&&!ifShowLisi" style="font-size: 13px;color: #999999;padding-top: 14px;"><span>最近搜索</span><i style="padding-left: 10px;cursor: pointer" class="el-icon-delete" @click.self="zuijdelete" /></div>
          <div v-if="listdatas.length>0&&!isShowList&&!ifShowLisi" style="padding-top: 14px;" class="listdatas">
            <span
              v-for="(item,index) in listdatas"
              :key="index"
              style="padding: 9px 20px;
                   border-radius: 4px;
                  display: inline-block;
                 background-color: #F7F8FA;cursor: pointer;margin-bottom: 14px;margin-right: 20px;
                     max-width: 185px; overflow: hidden;text-overflow: ellipsis; white-space: nowrap;"
              @click.self="listdataclick(item.value)"
            >{{ item.value }}</span>
          </div>
        </div>
        <searchList v-show="isShowList" @click.native="docFn" />

      </div>
    </div>

  </div>

</template>

<script>
import searchList from './components/searchList'
export default {
    components: {
        searchList
    },
    props: {
        elCardShow: { type: Boolean, default: false }
    },
    data() {
        return {
            input2: '',
            listdatas: [],
            isShowList: false,
            type: '全部',
            ifShowLisi: false,
            sssstr: ''
        }
    },
    computed: {

    },
    created() {
        this.$bus.on('newSearch', this.newSearch)
        this.$bus.emit('showNavSearch', false) // 取消右上搜索按钮
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true) // 右上搜索按钮
    },
    mounted() {
        this.listdatas = JSON.parse(localStorage.getItem('jobOrderNoArr')) || []

        const url = decodeURI(window.location.href)
        if (url.indexOf('?seachKey') > -1) {
            const str = url.split('?')[1]
            const newStr = str.split('=')[1]
            this.input2 = newStr
            this.searchinput()
        }
    },
    methods: {
        newSearch(val) {
            console.log(val)
            this.input2 = val
            this.searchinput()
        },
        docFn() {
            this.ifShowLisi = false
        },
        blurFn() {
            console.log(2)
            this.ifShowLisi = false
        },
        focusFn() {
            console.log(1)
            this.ifShowLisi = true
        },
        handleClick(val) {
            console.log('类型', val)
            console.log('类型', this.type)
            this.searchinput()
        },
        // 搜索内容
        searchinput() {
            this.ifShowLisi = false
            if (this.input2.trim() !== '') {
                const obj = {
                    key: this.input2,
                    type: this.type
                }
                this.$bus.emit('getListData', obj)
                // window.open('/#/globalsearch?searchKey=' + this.input2)
                this.elCardShow = false
                // this.Cookies.remove('portal_topMenuIndex')
                // this.$router.push({ path: '/globalsearch', query: { searchKey: this.input2, searchTime: ~window.moment() }})
                this.setIontoStorage('jobOrderNoArr')
                // this.$emit('searchinput', this.elCardShow)
                this.isShowList = true
            } else {
                return
            }
        },
        // 选择历史记录
        listdataclick(item) {
            this.input2 = item
            this.ifShowLisi = false
        },
        // 删除历史记录
        zuijdelete() {
            window.localStorage.removeItem('jobOrderNoArr')
            this.listdatas = JSON.parse(localStorage.getItem('jobOrderNoArr')) || []
        },
        setIontoStorage(type) {
            const self = this
            const noArr = []; let text = ''; let value = ''
            switch (type) {
                case 'srcOrderNoArr':
                    text = 'srcOrderNoArr'
                    value = self.srcOrderNoArr
                    break
                case 'jobOrderNoArr':
                    text = 'jobOrderNoArr'
                    value = this.input2
                    break
                case 'cntNo':
                    text = 'cntNoArr'
                    value = self.cntNo
                    break
            }
            noArr.push({ value: value })
            if (JSON.parse(localStorage.getItem(text))) {
                if (localStorage.getItem(text).indexOf(value) > -1) {
                    return
                }
                if (JSON.parse(localStorage.getItem(text)).length > 5) {
                    const arr = JSON.parse(localStorage.getItem(text))
                    arr.pop()
                    localStorage.setItem(text, JSON.stringify(arr))
                }
                localStorage.setItem(text, JSON.stringify(noArr.concat(JSON.parse(localStorage.getItem(text)))))
            } else {
                localStorage.setItem(text, JSON.stringify(noArr))
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.box-card{
    position: absolute;
    left: 0px;
    top: 60px;
    height: 100%;
    width: 100%;
  overflow: auto;
  /* 隐藏滚动条 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ / Edge */
}
/* Webkit 内核（Chrome、Safari）中使用::-webkit-scrollbar伪元素样式 */
.box-card::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
.box1-card1{
    display: block;
}
.box2-card2{
    display: none;
}
/deep/.searchinput .el-input__inner{
    height: 40px !important;
    border: none;

}
/deep/.searchinput1 .el-input-group__append{
    background-color: var(--bgColor) !important ;
}
/deep/.el-tabs__nav-wrap::after {
    height: 0 !important;
}
</style>
