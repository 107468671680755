<template>
 <div>
    <el-dialog v-if="dialogShow" v-el-drag-dialog :visible.sync="dialogShow" :close-on-click-modal="false" :width="'1000px'" :title="'新增执行资料'">
      <el-form :model="vo">
        <div class="el-table el-table--fit el-table--border inner-table">
          <div class="el-table__body-wrapper">
            <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
              <tbody>

                <tr>
                  <td class="el-table__cell tdlbl tdw17"><span class="required">*</span>预算基本信息文件</td>
                  <td class="el-table__cell tdcont tdw83">
                    <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="预算基本信息文件" rules="required">
                      <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                        <el-upload
                          ref="upload"
                          :on-change="handleUploadChange"
                          action=""
                          :file-list="fileList"
                          :show-file-list="false"
                          :auto-upload="false"
                          :with-credentials="true"
                        >
                          <el-input v-model="salaryAttachName" name="salaryAttachName" placeholder="请选择预算基本信息文件">
                            <el-button slot="append" icon="el-icon-upload2" />
                          </el-input>
                        </el-upload>
                      </el-tooltip>
                    </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td class="el-table__cell tdlbl tdw17"><span class="required">*</span>类型</td>
                  <td class="el-table__cell tdcont tdw83">
                    <el-select v-model="vo.type" style="width:100%;" placeholder="请选择类型">
                      <el-option
                        v-for="item in projectArray"
                        :key="item.value"
                        :label="item.value"
                        :value="item.value"
                      />
                    </el-select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button v-for="(btn,key) in busiformBtns" :key="key" :disabled="doing" :plain="btn.btnType===null || btn.btnType===''" :icon="'el-icon-'+btn.btnIcon" :type="btn.btnType!==null?btn.btnType:''" @click="btn.btnClick">{{ btn.btnName }}</el-button>
      </div>
    </el-dialog>
 </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        projectVo: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            vo: {
                type: ''
            },
            busiformBtns: [
                {
                    id: 'btn_close',
                    btnName: '关 闭',
                    btnIcon: 'close',
                    btnClick: this.closeDialog,
                    btnPermission: '',
                    btnType: ''
                }, {
                    id: 'btn_save',
                    btnName: '保 存',
                    btnIcon: 'check',
                    btnClick: this.submitSalary,
                    btnPermission: 'saveObject',
                    btnType: 'primary'
                }
            ],
            dialogShow: false,
            projectArray: [
                { type: 'Object', value: '工程造价咨询合同' },
                { type: 'Object', value: '施工合同' },
                { type: 'Object', value: '施工图纸' },
                { type: 'Object', value: '签证' },
                { type: 'Object', value: '变更单' },
                { type: 'Object', value: '工程量计算底稿' },
                { type: 'Object', value: '工程概况' },
                { type: 'Object', value: '工作方案' },
                { type: 'Object', value: '实施方案' },
                { type: 'Object', value: '质量控制流程表' },
                { type: 'Object', value: '审核日记' },
                { type: 'Object', value: '会议纪要' },
                { type: 'Object', value: '工程资料移交清单' },
                { type: 'Object', value: '成果文件签收单' },
                { type: 'Object', value: '工程造价咨询业务质量回访单' },
                { type: 'Object', value: '盖章版的成果文件' }
            ],
            fileList: [],
            salaryAttachName: ''
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        console.log(this.projectVo, 'projectVo')

        this.$bus.on('openImportFormjbxx', this.openImportForm)
    },
    beforeDestroy() {
        this.$bus.off('openImportFormjbxx', this.openImportForm)
    },
    mounted() {

    },
    methods: {
        openImportForm() {
            this.dialogShow = true
        },
        handleUploadChange(filed, fileList) {
            // if (filed.name.indexOf('.xls') === -1 && filed.name.indexOf('.xlsx')) {
            //     this.$alert('上传文件格式错误', '提示', {
            //         confirmButtonText: '确定',
            //         type: 'warning'
            //     })
            //     this.fileList = []
            //     this.salaryAttachName = ''
            //     return
            // }
            this.fileList = [filed]
            this.salaryAttachName = filed.name
        },
        closeDialog() {
            this.dialogShow = false
            this.salaryAttachName = ''
            this.fileList = []
        },
        // 上传文件
        submitSalary() {
            this.formValidate(() => {
                if (!this.salaryAttachName) {
                    return this.$message.warning('请选择需上传的文件！')
                }
                this.isDisabled = true
                this.doing = true
                const file = new FormData()
                file.append('file', this.fileList[0].raw)
                file.append('module', 'zlgd')
                file.append('attachsSize', this.fileList[0].size)
                file.append('busiDataId', 'zlgd')
                file.append('attachsType', 'ATTACH')
                file.append('attachsName', this.fileList[0].name)
                this.$http({
                    url: '/tbgl/zlgd/upload',
                    method: 'POST',
                    data: file,
                    params: { projectId: this.projectVo.id, type: this.vo.type }
                }).then(res => {
                    this.isDisabled = false
                    this.salaryAttachName = ''
                    if (res.code === 'success') {
                        this.message = res.body
                        this.doing = false
                        this.dialogShow = false
                        this.fileList = []
                        this.$nextTick(() => {
                            this.$bus.emit('refreshListData')
                        })
                    } else {
                        this.$message.error(res.message)
                    }
                    this.$nextTick(() => {
                        this.doing = false
                    })
                })
            })
        }
    }
}
</script>

<style scoped lang="scss">
.el-tooltip{
  /deep/ .el-upload.el-upload--text{
      width: 100%;
  }
}
</style>
