<template>
  <div id="schedulesAddForm">
    <el-dialog
      v-el-drag-dialog
      title="排班登记"
      :visible.sync="dialogVisible"
      width="70%"
      :close-on-click-modal="false"
      :before-close="closeHandler"
    >
      <div>
        <el-form ref="changeForm" :model="vo" style="margin: 10px 0px;text-align: center">
          <div class="el-table el-table--fit el-table--border">
            <div class="el-table__body-wrapper">
              <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
                <tbody>
                  <tr>
                    <td class="el-table__cell tdlbl tdw17 tdf-gray" colspan="1"><span class="required">*</span>排班人员</td>
                    <td class="el-table__cell tdcont tdw83" colspan="5">
                      <template v-if="canEdit && !singleEdit">
                        <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="排班人员" rules="required">
                          <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                            <el-input
                              v-model="vo.name"
                              v-select-dialog="'staff'"
                              :readonly="true"
                              type="textarea"
                              multiple="true"
                              :root-org-code="loginUser.orgCode"
                              name="name"
                              placeholder="请选择排班人员"
                            >
                            </el-input>
                          </el-tooltip>
                        </ValidationProvider>
                      </template>
                      <span v-else>{{ vo.name }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="el-table__cell tdlbl tdw17 tdf-gray" colspan="1"><span class="required">*</span>排班日期</td>
                    <td class="el-table__cell tdcont tdw83" colspan="5">
                      <template v-if="canEdit && !singleEdit">
                        <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="排班日期" rules="required">
                          <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                            <el-date-picker
                              v-model="vo.clockInDate"
                              style="width:100%;"
                              type="dates"
                              format="yyyy-MM-dd"
                              value-format="yyyy-MM-dd"
                              placeholder="请选择排班日期"
                              :picker-options="{
                                disabledDate(timer) {
                                  return timer.getTime() < new Date().getTime()
                                }
                              }"
                            >
                            </el-date-picker>
                          </el-tooltip>
                        </ValidationProvider>
                      </template>
                      <span v-else>{{ vo.clockInDate }}</span>
                    </td>
                  </tr>
                  <tr>
                    <!-- <td class="el-table__cell tdlbl tdw33" colspan="2"><span class="required">*</span>班次</td> -->
                    <td class="el-table__cell tdlbl tdw17 tdf-gray" colspan="1">班次</td>
                    <td class="el-table__cell tdcont tdw83" colspan="5">
                      <div style="display:flex;align-items:center;">
                        <!-- <ValidationProvider v-if="schedules.length" ref="ValidationProvider" v-slot="{ errors }" name="班次" rules="required">
                          <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                            <el-checkbox-group v-if="schedules.length" v-model="checkList" :disabled="!canEdit">
                              <el-checkbox v-for="item in schedules" :key="item.id" :label="item.id">{{ item.text }}</el-checkbox>
                            </el-checkbox-group>
                          </el-tooltip>
                        </ValidationProvider> -->
                        <el-checkbox-group v-if="schedules.length" v-model="checkList" :disabled="!canEdit">
                          <el-checkbox v-for="item in schedules" :key="item.id" :label="item.id">{{ item.text }}</el-checkbox>
                        </el-checkbox-group>
                        <span v-else>暂无班次</span>
                        <el-button v-if="canEdit" style="margin-left:15px;" @click="handleAddOther"><i class="el-icon-plus"></i> 其他班次</el-button>
                      </div>
                    </td>
                  </tr>
                  <!-- 动态排班 - 设置模块 - 开始 -->
                  <template v-for="(item, index) in vo.recordResultList">
                    <tr :key="index">
                      <td class="el-table__cell tdlbl tdw17" colspan="1" style="font-weight:600;">{{ item.timeName }}</td>
                      <td class="el-table__cell tdcont tdw83" colspan="5">
                        <div class="selected-item-field">
                          <div class="field-left">
                            <template v-if="canEdit">
                              <el-time-select
                                v-model="item.logonTime"
                                :clearable="false"
                                style="width:110px;"
                                placeholder="起始时间"
                                :picker-options="{
                                  start: '00:00',
                                  end: '23:59'
                                }"
                                @change="calculatingShiftDuration(item)"
                              >
                              </el-time-select>
                              <span style="margin:0 10px;">至</span>
                              <el-time-select
                                v-model="item.logoffTime"
                                :clearable="false"
                                style="width:110px;"
                                placeholder="结束时间"
                                :picker-options="{
                                  start: '00:00',
                                  end: '23:59',
                                  minTime: item.logonTime
                                }"
                                @change="calculatingShiftDuration(item)"
                              >
                              </el-time-select>
                            </template>
                            <span v-else>{{ item.logonTime }} 至 {{ item.logoffTime }}</span>
                          </div>
                          <div>
                            共
                            <el-input v-if="canEdit" v-model="item.workHours" style="width: 35px;"></el-input>
                            <span v-else>{{ item.workHours }}</span>
                            小时
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <!-- 动态排班 - 设置模块 - 结束 -->
                  <!-- 动态其他排班 - 开始 -->
                  <template v-if="otherSchedules.length">
                    <tr class="td-table">
                      <td class="el-table__cell tdcont tdw17" colspan="1">班次编号</td>
                      <td class="el-table__cell tdcont tdw33" colspan="1">班次名称</td>
                      <td class="el-table__cell tdcont" colspan="2">班次时段</td>
                      <td class="el-table__cell tdcont" colspan="1">班次时长</td>
                      <td class="el-table__cell tdcont" colspan="1">操作</td>
                    </tr>
                  </template>
                  <template v-for="(item, index) in otherSchedules">
                    <OtherSchedulesField :key="index" :field-data.sync="otherSchedules[index]" :can-edit="canEdit" @handleDelete="deleteOther(index)" />
                  </template>
                  <!-- 动态其他排班 - 结束 -->
                </tbody>
              </table>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeHandler">关 闭</el-button>
        <template v-if="canEdit">
          <el-button type="primary" @click="saveHandler('1')">保 存</el-button>
          <el-button type="primary" @click="saveHandler('2')">保存并关闭</el-button>
        </template>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@/global/utils'
import OtherSchedulesField from './components/otherSchedulesField'

// 默认班次数据
const DEFAULT_schedules_data = {
    'classesNumber': '其他',
    'classesOrderNo': '',
    'classesType': 0,
    'timeId': '',
    'timeName': '其他',
    'logoffTime': '',
    'logonTime': '',
    'timeSpan': '',
    'workHours': ''
}
export default {
    components: {
        OtherSchedulesField
    },
    data() {
        return {
            cellData: {},
            vo: {
                recordResultList: []
            },
            dialogVisible: false,
            singleEdit: false,
            loginUser: this.$store.getters.loginUser,
            checkList: [], // 选中的班次
            otherSchedules: [], // 其他班次
            canEdit: true // 是否编辑
        }
    },
    computed: {
        schedules() {
            if (this.vo && this.vo.passenger && this.vo.passenger.classesNameOptions) {
                return this.vo.passenger.classesNameOptions
            }
            return []
        }
    },
    watch: {
        'vo.recordResultList': {
            deep: true,
            handler(cur, old) {
                this.vo.recordResultList.forEach(checked => {
                    if (checked.logonTime && checked.logoffTime) {
                        checked.timeSpan = checked.logonTime + '-' + checked.logoffTime
                    }
                })
            }
        },
        checkList(val, old) {
            if (val.length > this.vo.recordResultList.length) {
                // 获取新增的id
                const checked = deepClone(val)
                this.vo.recordResultList.forEach(item => {
                    const index = checked.indexOf(item.timeId)
                    if (index !== -1) {
                        checked.splice(index, 1)
                    }
                })
                // 通过id - 新增排班设置
                const newOption = this.schedules.find(item => item.id === checked[0])
                this.vo.recordResultList.push({
                    'classesNumber': newOption.classesNumber,
                    'classesOrderNo': newOption.orderNo,
                    'classesType': 1,
                    'timeId': newOption.id,
                    'timeName': newOption.value,
                    'logoffTime': newOption.logoffTime,
                    'logonTime': newOption.logonTime,
                    'timeSpan': '',
                    'workHours': newOption.workHours
                })
            } else if (val.length < this.vo.recordResultList.length) {
                // 获取需要删除的id
                const checked = deepClone(old)
                val.forEach(item => {
                    const index = checked.indexOf(item)
                    if (index !== -1) {
                        checked.splice(index, 1)
                    }
                })
                // 通过id查下标删除排班设置
                const deleteIndex = this.vo.recordResultList.findIndex(re => re.timeId === checked[0])
                this.vo.recordResultList.splice(deleteIndex, 1)
            }
        }
    },
    created() {
        this.$bus.on('openPbDialog', this.openPbDialog)
    },
    beforeDestroy() {
        this.$bus.off('openPbDialog', this.openPbDialog)
    },
    methods: {
        // 计算排班时长
        calculatingShiftDuration(item) {
            if (item.logonTime === null || item.logoffTime === null) {
                return
            }
            const params = {
                startTime: item.logonTime,
                endTime: item.logoffTime
            }
            this.$http({
                url: '/yykq/timeInterval/calculatingShiftDuration',
                methods: 'GET',
                params
            }).then(function(res) {
                if (res.code === 'success' && res.body.workTime && res.body.workHours) {
                    item.workHours = res.body.workHours
                    item.workTime = res.body.workTime
                }
            })
        },
        // 人员选择
        onSelectStaffCompleted(selected) {
            const result = selected.reduce((prev, curr, index) => {
                if (index === 0) {
                    prev.account = curr.account
                    prev.personId = curr.personId
                    prev.name = curr.name
                } else {
                    prev.account += ',' + curr.account
                    prev.personId += ',' + curr.personId
                    prev.name += ',' + curr.name
                }

                return prev
            }, {
                account: '',
                personId: '',
                name: ''
            })
            Object.assign(this.vo, result)
        },
        // 删除 - 其他班次
        deleteOther(index) {
            if (this.otherSchedules[index].timeId) {
                this.$http({
                    url: '/yykq/recordResult/deleteRecordResultByTimeId',
                    method: 'POST',
                    params: { timeId: this.otherSchedules[index].timeId, account: this.vo.account }
                }).then(res => {
                    if (res.code === 'success') {
                        this.otherSchedules.splice(index, 1)
                        this.$bus.emit('refreshSchedules')
                    } else {
                        this.$message.error('删除失败，请稍后再试！')
                    }
                })
            } else {
                this.otherSchedules.splice(index, 1)
            }
        },
        // 新增 - 其他班次
        handleAddOther() {
            const schedule = Object.assign({}, deepClone(DEFAULT_schedules_data), {
                classesOrderNo: 1000 + this.otherSchedules.length
            })
            this.otherSchedules.push(schedule)
        },
        // 打开排班弹窗
        openPbDialog(row, isSingleEdit = false) {
            if (isSingleEdit) this.singleEdit = true
            this.checkList = []
            this.cellData = row
            // 时间小于当前时间时不可操作排班
            if (isSingleEdit && this.cellData.clockInDate) {
                this.canEdit = !(new Date(this.cellData.clockInDate).getTime() < new Date().getTime())
                if (this.cellData.classesNumber === '借') {
                    this.canEdit = false
                }
            }
            // 兼容排班记录行点击
            if (this.cellData.canAdjusted === 0) {
                this.canEdit = false
            }
            // 点击单格内排班
            if (this.singleEdit) {
                const params = {
                    account: row.account,
                    clockInDate: row.clockInDate,
                    deptId: row.departmentId,
                    deptName: row.department
                }
                this.getSchedulesInfo(params)
            } else {
                // 批量排班
                const params = {
                    deptId: row.departmentId,
                    deptName: row.department,
                    personSchId: row.personSchId
                }
                this.getSchedulesInfo(params).then(() => {
                    if (row.account) {
                        this.vo.account = row.account
                        this.vo.name = row.name
                        this.vo.personId = row.personId
                    }
                })
            }
            this.dialogVisible = true
        },
        // 获取排班信息
        getSchedulesInfo(params = {}) {
            return new Promise((resolve, reject) => {
                this.$http({
                    url: '/yykq/recordResult/getPBRecordResultInfoList',
                    method: 'GET',
                    params
                }).then((res) => {
                    if (res.code === 'success') {
                        this.vo = res.body.vo
                        if (this.vo.recordResultList && this.vo.recordResultList.length) {
                            const checkedRecode = [] // 选中数据
                            const recordResultList = [] // 排班设置数据
                            const otherSchedules = [] // 其他排班设置数据
                            this.vo.recordResultList.forEach((recode, index) => {
                                // 拆分时间段为两个字段
                                const timeSpanArr = recode.timeSpan.split('-')
                                recode.logonTime = timeSpanArr[0]
                                recode.logoffTime = timeSpanArr[1]

                                if (recode.classesType === 1) {
                                    // 标准排班数据
                                    checkedRecode.push(recode.timeId)
                                    recordResultList.push(recode)
                                } else if (recode.classesType === 0) {
                                    // 其他排班数据单独抽离处理
                                    otherSchedules.push(recode)
                                }
                            })
                            // logonTime/logoffTime属性原本不存在，重新深拷贝一个新obj以保证时间选择器正常
                            this.$set(this.vo, 'recordResultList', deepClone(recordResultList))
                            this.$set(this, 'otherSchedules', deepClone(otherSchedules))
                            this.$set(this, 'checkList', checkedRecode)
                        }
                        resolve()
                    } else {
                        this.$message.error(res.message)
                    }
                }).catch((err) => {
                    console.warn('err => ', err)
                })
            })
        },
        isUselessValue(val) {
            return val === undefined || val === '' || val === null
        },
        // type 1保存 2保存并关闭
        saveHandler(type) {
            // 检验排班设置是否为空
            const unsuccessful = this.vo.recordResultList.some(re => this.isUselessValue(re.logonTime) || this.isUselessValue(re.logoffTime) || this.isUselessValue(re.workHours))
            const otherUnsuccessful = this.otherSchedules.some(re => this.isUselessValue(re.logonTime) || this.isUselessValue(re.logoffTime) || this.isUselessValue(re.workHours) || this.isUselessValue(re.timeName) || this.isUselessValue(re.classesNumber))
            if (unsuccessful || otherUnsuccessful) {
                return this.$message.warning('请检查表单是否填写完成后提交！')
            }
            // 处理、合并vo数据
            const data = deepClone(this.vo)
            data.recordResultList = [...data.recordResultList, ...this.otherSchedules]

            if (data.recordResultList.length === 0) {
                return this.$message.warning('请确认班次正确无误后提交！')
            }

            if (data.clockInDate instanceof Array) {
                data.clockInDate = data.clockInDate.join(',')
            }
            this.formValidate(() => {
                this.$http({
                    url: '/yykq/recordResult/savePBRecordResultInfoList',
                    method: 'POST',
                    data
                }).then(res => {
                    if (res.code === 'success') {
                        this.$bus.emit('refreshSchedules')
                        if (type === '1') {
                            let params = {}
                            this.checkList = []
                            this.otherSchedules = []
                            if (this.singleEdit) {
                                params = {
                                    account: this.cellData.account,
                                    clockInDate: this.cellData.clockInDate,
                                    deptId: this.cellData.departmentId,
                                    deptName: this.cellData.department
                                }
                                this.getSchedulesInfo(params)
                            } else {
                                params = {
                                    deptId: this.cellData.departmentId,
                                    deptName: this.cellData.department
                                }
                                this.getSchedulesInfo(params).then(() => {
                                    this.vo.account = this.cellData.account
                                    this.vo.name = this.cellData.name
                                    this.vo.personId = this.cellData.personId
                                })
                            }
                        } else {
                            this.closeHandler()
                        }
                    } else {
                        this.$confirm(res.message, '错误', { type: 'warning' })
                    }
                }).catch(err => this.$confirm(err, '错误', { type: 'warning' }))
            }, null, 1)
        },
        // 关闭
        closeHandler() {
            // 页面数据相关
            this.checkList = []
            this.otherSchedules = []
            this.vo = {
                recordResultList: []
            }
            this.cellData = {}
            // 关闭弹窗显示
            this.dialogVisible = false
            // 是否为单cell变更
            this.singleEdit = false
            this.canEdit = false
            this.$nextTick(() => {
                this.canEdit = true
            })
        }
    }
}
</script>

<style scoped lang="scss">
#schedulesAddForm{
  /deep/ .el-dialog .el-form .el-table__body .tdlbl{
    background-color: #F2F5FB !important;
  }
}

.tdf-gray {
  color: #666666 !important;
  font-weight: 700 !important;
}
.td-table{
  td{
    text-align: center !important;
    color: #666666 !important;
    font-weight: 600 !important;
  }
}
.selected-item-field{
  display: flex;
  align-items: center;
  color: #666666;
  font-weight: bold;
  padding-right: 35px;
  .field-left{
    flex: 1;
  }
}

</style>
