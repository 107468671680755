// 导入单个组件
// components
import TbglXmtz from './src/xmxx/index'
import TbglAddZlDialog from './src/xmxx/components/addZlDialog'
import TbglYjcx from './src/yjcx/index'

// 以数组的结构保存组件，便于遍历
const components = {
    TbglXmtz,
    TbglAddZlDialog,
    TbglYjcx
}
// 定义 install 方法
const install = function(Vue) {
    if (install.installed) return
    install.installed = true
    // 遍历并注册全局组件
    Object.keys(components).forEach(key => {
        Vue.component(key, components[key])
    })
}

if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue)
}

export default {
    // 导出的对象必须具备一个 install 方法
    install,
    // 组件列表
    ...components
}
