<template>
  <el-dropdown trigger="click" @command="switchUserOrgan">
    <div v-if="layout===0||!layout">
      <svg-icon class-name="organ-icon" icon-class="person_org_mgr" style="cursor: pointer;" />
    </div>
    <div v-else style="cursor: pointer;">
      <svg-icon class-name="organ-icon" icon-class="companys" style="font-size: 17px;cursor: pointer; color:var(--textColor);" />
      <span style="font-size: 13px;margin-left: 6px;color:var(--textColor);">切换单位</span>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="item of organList" :key="item.code" :disabled="orgCode===item.code" :command="item.code">
        {{ item.name }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
    props: {
    },
    data() {
        return {
            loginUser: this.$store.getters.loginUser,
            organList: this.$store.getters.organList,
            layout: 0
        }
    },
    computed: {
        orgCode() {
            return this.loginUser.orgCode
        }
    },
    created() {
        this.layout = window.layoutStyle
    },
    methods: {
        switchUserOrgan(orgCode) {
            this.$http({
                method: 'GET',
                url: 'base/sysSetup/switchOrgLogin',
                params: { 'orgCode': orgCode },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                if (response.code === 'success') {
                    this.$store.dispatch('user/tokenSsoLogin', response.body)
                        .then(async(res) => {
                            await this.$store.dispatch('route/updateRoutes', [])
                            await this.$store.dispatch('tagsView/delAllViews')
                            var url = location.href.split('#')
                            location.replace(url[0])
                            // this.$router.push({ path: '/', query: this.otherQuery })
                            this.$bus.emit('getWaterMarkConfig')
                        })
                        .catch(error => {
                            this.$message({ message: error.msg, type: 'error' })
                        })
                } else {
                    this.$message({ message: response.message, type: 'error' })
                }
            })
        }
    }

}
</script>

<style lang="scss" scoped>
.organ-icon{
    font-size: 22px;
}
</style>
