<template>
  <div class="app-container kq-home background-app-container fill">
    <div class="header">
      <kq-info />
    </div>
    <ul class="content el-table">
      <li class="content-item">
        <div class="l" style="height: 304px;">
          <ItemTask :types="type" :names="name" :modules="modules" :urlsearch="urlsearch" />
        </div>
        <div class="r" style="height: 304px;"><kqdk /></div>
      </li>
      <li class="content-item" style="margin-bottom: 2px;">
        <div class="l" style="height: 304px;">
          <kq-mine />
        </div>
        <div class="r">
          <Apply :apply-array="applyArray" :show-column-num="3" :title="'发起申请'" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import KqInfo from './components/KQInfo.vue'
import kqdk from './components/KQDK.vue'
import KqMine from './components/KqMine.vue'
import ItemTask from '@/wapp/common/components/ItemTask/index'
import Apply from '@/wapp/common/components/Apply/index'
export default {
    name: 'KqglIndex',
    components: {
        KqInfo, ItemTask, Apply, kqdk, KqMine
    },
    props: {

    },
    data() {
        return {
            type: ['myTodo', 'myDone'],
            name: ['待审批', '已审批'],
            modules: ['kqglTodo', 'kqglDone'],
            urlsearch: 'module:vacate,outsideWork,travel,overtimeWork,resign',
            applyArray: [{
                lableName: '出差申请',
                wfCode: 'travel',
                wfName: '出差申请',
                startNodeName: '',
                wfEngineUrl: 'yykq/travel',
                icon: './images/w-imgs/app-icons/ccsq.png'
            }, {
                lableName: '补卡申请',
                wfCode: 'resign',
                wfName: '补卡申请',
                startNodeName: '',
                wfEngineUrl: 'yykq/resign',
                icon: './images/w-imgs/app-icons/bksq.png'
            }, {
                lableName: '加班申请',
                wfCode: 'overtimeWork',
                wfName: '加班申请',
                startNodeName: '',
                wfEngineUrl: 'yykq/overtimeWork',
                icon: './images/w-imgs/app-icons/jbsq.png'
            }, {
                lableName: '请假申请',
                wfCode: 'vacate',
                wfName: '请假申请',
                startNodeName: '',
                wfEngineUrl: 'yykq/vacate',
                icon: './images/w-imgs/app-icons/qjsq.png'
            }, {
                lableName: '外勤申请',
                wfCode: 'outsideWork',
                wfName: '外勤申请',
                startNodeName: '',
                wfEngineUrl: 'yykq/outsideWork',
                icon: './images/w-imgs/app-icons/wqsq.png'
            }]
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        this.$bus.emit('addPortalPageClass', true)
        this.$bus.emit('showNavSearch', false)
    },
    mounted() {

    },
    beforeDestroy() {
        this.$bus.emit('addPortalPageClass', false)
        this.$bus.emit('showNavSearch', true)
    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
$gap: 10px;
.header {
  margin-bottom: $gap;
  padding: 10px;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  background: #fff;
}
.content {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  .content-item {
    $lw: 320px;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-bottom: $gap;
    .l {
      width: 100%;
      height: 100%;
      min-height: 1px;
      float: left;
      padding-right: $lw + $gap;
    }
    .r {
      width: $lw;
      margin-left: -$lw;
      float: left;
    }
    &:after {
      content: '';
      display: block;
      visibility: hidden;
      clear: both;
    }

  }
}
/deep/ .el-table::before {
    background-color:#fff;
  border: none !important;
}
/deep/.el-table--border::after {
    background-color:#fff;
  border: none !important;
}
/deep/.el-table--border {
  background-color: #fff !important;
    border-left: none !important;
    border-color: #fff !important;
}
</style>
