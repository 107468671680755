<template>
  <div>
    <!-- <el-radio-group v-model="viewType" style="padding: 10px;">
      <el-radio-button label="view">视图</el-radio-button>
      <el-radio-button label="list">排班记录</el-radio-button>
    </el-radio-group> -->
    <div style="padding: 10px;font-size: 14px;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;justify-content: space-between;">
      <div style="min-width: 650px;display: flex;align-items: center;">
        <div class="dj">
          考勤月份：
          <el-date-picker v-model="value1" class="riqi" :clearable="false" type="month" placeholder="选择月" format="yyyy年M月" value-format="yyyy-MM" />
        </div>
        <statistics-search v-select-dialog="suborg" :search-type="searchType" :show-filter="viewType==='view'" @statisticsClick="statistics" />
        <el-button v-if="false" class="csh" type="primary" @click="chushihua(scope)">初始化</el-button>
      </div>
      <el-button-group v-if="viewType === 'view'">
        <el-button @click="refreshData"><i class="el-icon-refresh" /></el-button>
        <!-- <el-button v-select-dialog="'staff'" multiple="true"><i class="el-icon-plus" style="color: #0EA04D"></i> 添加借调</el-button> -->
        <el-button @click="handleMultipleAdd"><i class="el-icon-plus" style="color: #0EA04D" /> 批量排班</el-button>
        <el-button @click="handleSeconded"><i class="el-icon-plus" style="color: #0EA04D" /> 批量借调</el-button>
        <!-- <el-button @click="importHandler">
          <svg aria-hidden="true" class="svg-icon" style="height: 13px; width: 13px" v-on="$listeners">
            <use :xlink:href="'#icon-import'" style="fill:#EC8423 " />
          </svg> 导入</el-button> -->
        <el-button v-table-toxls="views" file-name="排班登记表">
          <svg aria-hidden="true" class="svg-icon" style="height: 13px; width: 13px" v-on="$listeners">
            <use :xlink:href="'#icon-export'" style="fill:#EC8423 " />
          </svg> 导出</el-button>
      </el-button-group>
      <!-- <el-button-group v-else>
        <el-button @click="refreshData"><i class="el-icon-refresh"></i></el-button>
        <el-button @click="handleMultipleAdd"><i class="el-icon-plus" style="color: #0EA04D"></i> 批量排班</el-button>
        <el-button @click="handleListExport">
          <svg aria-hidden="true" class="svg-icon" style="height: 13px; width: 13px" v-on="$listeners">
            <use :xlink:href="'#icon-export'" style="fill:#EC8423 " />
          </svg> 导出</el-button>
      </el-button-group> -->
    </div>
    <!-- <div style="    display: flex;
    justify-content: space-around;
    padding: 20px;
    font-size: larger;"
    >
      排班登记表
    </div> -->
    <div style="display: flex;justify-content: space-around;padding: 20px 20px 5px 20px;font-size: larger;">
      <div style="width:33%;display: flex;font-size: 16px;">
        <div style="padding: 20px 0px 5px;font-size: 14px;margin-right:50px">科室负责人：{{ vo.ksPrincipal || '' }}</div>
        <div v-if="vo.kqManager"><div style="padding: 20px 0px 5px;font-size: 14px;">科室考勤员：{{ vo.ksManager || '' }}</div></div>
      </div>
      <div style="width:33%;text-align: center;"><span v-if="viewType === 'view'">{{ month }}月份排班登记表</span></div>
      <div style="width:33%;display: flex;font-size: 16px;justify-content: right;">
        <div style="padding: 20px 0px 5px;font-size: 14px;">考勤人数：{{ vo.kqPersonNumber ||0 }}人</div> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        <div style="padding: 20px 0px 5px;font-size: 14px;">实有人数：{{ vo.actualPersonNumber ||0 }}人</div>
      </div>
    </div>

    <template v-if="viewType === 'view'">
      <div class="table-box">

        <el-table id="views" ref="dommm" v-loading="loading" :data="dateList" style="width: 100%" :header-row-class-name="handleTableHeader" border @header-click="clickFun" @selection-change="handleSelectionChange">
          <el-table-column :label="month + '月份排班登记表'" align="center">
            <el-table-column
              type="selection"
              width="55"
              align="center"
            />
            <el-table-column align="center" prop="name" label="姓名" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <!-- <span style="cursor: pointer;" @click="hang(scope)">{{ scope.row.name }}</span> -->
                <span style="cursor: pointer;">{{ scope.row.name }}</span>
              </template>
            </el-table-column>

            <el-table-column v-for="(item,index) in time1" :key="index" :label="item" min-width="45" align="center" style="cursor: pointer;height:50vw;width:100">
              <template slot-scope="scope">
                <div>
                  <div
                    :style="{'background-color': setBgcolor(scope)}"
                    style="height:40px"
                    @click="logScope(scope,scope.$index,scope.column.label)"
                  >
                    <!-- {{ scope.row.data[0].passenger.listdata[index].pbNoStr?scope.row.data[0].passenger.listdata[index].pbNoStr:" " }} -->
                    {{ scope.row.recordResults[item] ? scope.row.recordResults[item].classesNumber : '-' }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <SchedulesList v-if="isReady && viewType === 'list'" ref="SchedulesList" :filter-data="filterData" />
    <SchedulesAddForm />
    <MultipleSchedulesAddForm ref="MultipleAdd" />
    <ImportForm />
  </div>

</template>

<script>
import StatisticsSearch from '../common/StatisticsSearch'
import ImportForm from '../common/ImportForm'
import SchedulesAddForm from './schedulesAddForm'
import MultipleSchedulesAddForm from './components/multipleSchedulesAddForm'
import SchedulesList from './schedulesList'
// import qs from 'qs'
const moment = require('moment')
const deepClone = (obj) => JSON.parse(JSON.stringify(obj))
export default {
    components: {
        StatisticsSearch, ImportForm, SchedulesList, SchedulesAddForm, MultipleSchedulesAddForm
    },
    props: {

    },
    data() {
        return {
            ksPersonName: '',
            kqPersonName: '',
            ksKqPersonName: '',
            kqPersonNum: '',
            peoNum: 0,
            loading: true,
            dateList: [],
            vo: {},
            savelist: [],
            value1: '',
            time1: '',
            time: '',
            selectDept: '',
            selectDeptName: '',
            isReady: true,
            content: '',
            month: '',
            searchType: [
                {
                    type: 'subOrg',
                    title: '登记科室'
                }
            ],
            thisweek: [],
            currentMonthHolidays: [],
            day: [],
            loginUser: this.$store.getters.loginUser,
            addPersonList: [],
            scopeRow: {},
            bcglsList: [], // 科室的排班信息
            ksManager: false, // 科室管理员
            kqManager: false, //  考勤管理员
            viewType: 'view', // 展示模式： view视图 list列表
            selectionData: [] // view视图列表选中
        }
    },
    computed: {
        filterData() {
            return {
                clockInDate: this.value1,
                deptId: this.selectDept,
                deptName: this.selectDeptName
            }
        }
    },
    watch: {
        // value1(val) {
        //     console.log(val)
        //     const time1 = val.split('-')
        //     const y = time1[0]
        //     const m = time1[1]
        //     this.getDays(y, m)
        //     // this.getListData()
        // }
        // 'selectDept': {
        // 	immediate: true,
        //     handler(newVal, oldVal) {
        //         if (newVal) this.findBcmxByDeptId(newVal)
        //     }
        // }
    },
    created() {
        if (this.$store.state.user.roles.indexOf('kqManager') !== -1) {
            // 考勤管理员
            this.kqManager = true
            this.searchType[0].kqManager = this.kqManager
        }
        if (this.$store.state.user.roles.indexOf('ksManager') !== -1) {
            // 科室管理员
            this.ksManager = true
        }
        this.value1 = moment(new Date()).format('YYYY-MM')
        this.getDays(new Date().getFullYear(), new Date().getMonth() + 1)
        this.getHolidays(this.value1)
        this.$bus.emit('showNavSearch', false)
        this.$bus.on('refreshSchedules', this.statistics)
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true)
        this.$bus.off('refreshSchedules', this.statistics)
    },
    mounted() {
        this.value1 = moment(new Date()).format('YYYY-MM')
        this.$bus.on('statisticsClick', (data) => {
            this.statistics(data)
        })
    },
    methods: {
        handleTableHeader(head) {
            if (head.rowIndex === 0) return 'tableHeaderTitle'
        },
        handleListExport() {
            this.$refs.SchedulesList.exportTableData()
        },
        handleSelectionChange(val) {
            this.selectionData = val
        },
        setBgcolor(scope) {
            const columnIndex = scope.column.label
            const targetColumns = [...this.currentMonthHolidays]
            return targetColumns.includes(columnIndex) ? '#f5f5f5' : ''
        },
        // 批量排班
        handleMultipleAdd() {
            this.$refs.MultipleAdd.openSchedulesAddForm(() => {
                this.refreshData()
            })
        },
        // 批量借调
        handleSeconded() {
            const selectedCellData = {}
            this.selectionData.length && this.selectionData.forEach(item => {
                selectedCellData.department = item.department
                selectedCellData.departmentId = item.departmentId
                if (selectedCellData.account) {
                    selectedCellData.account += ',' + item.account
                    selectedCellData.name += ',' + item.name
                    selectedCellData.personId += ',' + item.personId
                } else {
                    selectedCellData.account = item.account
                    selectedCellData.name = item.name
                    selectedCellData.personId = item.personId
                }
            })
            if (!selectedCellData.department) {
                selectedCellData.departmentId = this.selectDept
                selectedCellData.department = this.selectDeptName
            }
            this.$bus.emit('openPbDialog', selectedCellData)
            return
        },
        // 单个点击
        logScope(scope, index, columnLabel) {
            const currentRowData = deepClone(scope.row)
            const cellData = scope.row.recordResults[columnLabel]
            if (!cellData) {
                currentRowData.clockInDate = this.value1 + '-' + (columnLabel < 10 ? '0' + columnLabel : columnLabel)
            }
            this.$bus.emit('openPbDialog', Object.assign({}, currentRowData, cellData), true)
            return
        },
        // 行全选
        hang(scope, y, x) {
            this.scopeRow = scope.row
            this.$bus.emit('openPbDialog', scope.row, x, this.bcglsList)
            return
        },
        // 列全选
        clickFun(column) {
            if (!isNaN(column.label)) {
                return
            }
        },
        getWeekends(year, month) {
            const weekends = []
            const date = new Date(year, month - 1, 1)
            while (date.getMonth() === month - 1) {
                if (date.getDay() === 0 || date.getDay() === 6) {
                    weekends.push(date.getDate())
                }
                date.setDate(date.getDate() + 1)
            }

            return weekends.join(',')
        },
        // 初始化
        chushihua(column) {
            const targetColumns = this.thisweek
            // this.dateList.forEach(item => {
            //     targetColumns
            //  item.data[0].passenger.listdata

            // })
            this.dateList.forEach((item, index1) => {
                item.data[0].passenger.listdata.forEach((aaa, index) => {
                    // console.log(ite, index, 999)
                    if (targetColumns.includes(index + 1)) {
                        return
                    } else {
                        this.$set(this.dateList[index1].data[0].passenger.listdata[index], 'pbStatus', 1)
                    }
                })
            })
            this.dateList.forEach(item => {
                this.savelist.push(item.data[0])
            })
            const filteredArray = this.savelist.reduce(function(acc, obj) {
                const existingObjIndex = acc.findIndex(function(aaa) {
                    return aaa.id === obj.id
                })

                if (existingObjIndex !== -1) {
                    // 如果已存在相同id的对象，则替换为新的对象
                    acc[existingObjIndex] = obj
                } else {
                    // 如果不存在相同id的对象，则将对象添加到结果数组中
                    acc.push(obj)
                }
                return acc
            }, [])
            this.savelist = filteredArray
        },
        refreshData() {
            this.statistics()
        },
        // 保存
        tosave() {
            this.$http({
                method: 'PUT',
                contentType: 'application/x-www-form-urlencoded',
                data: this.savelist,
                params: { pbNy: this.value1 },
                url: '/yykq/rypbkqdj/save'
            }).then(res => {
                if (res.code === 'success') {
                    console.log(res, 'res11111111111')
                    this.datelist = res.body.listdata
                    this.$message.success('保存成功')
                    this.$bus.emit('refreshListData')
                    this.getListData(this.time1)
                }
            })
            this.savelist = []
        },
        // 导出
        todao() {

        },
        // 导入
        importHandler() {
            this.$bus.emit('openImportForm')
        },
        /**
        * 选择人员完成回调
        */
        // onSelectStaffCompleted(selectedNodes, attr) {
        //     if (selectedNodes.length) {
        //         console.log('selectedNodes', selectedNodes)
        //         var staffIds = ''
        //         selectedNodes.forEach(item => {
        //             staffIds += item.id + ','
        //         })
        //         staffIds = staffIds.substring(0, staffIds.length - 1)

        //         this.$http({
        //             contentType: 'application/x-www-form-urlencoded',
        //             url: `/yykq/rypbkqdj/dispathPerson`,
        //             method: 'POST',
        //             params: {
        //                 staffIds: staffIds,
        //                 dateTime: this.value1,
        //                 staffDeptId: this.selectDept
        //             }
        //         }).then(res => {
        //             if (res.code === 'success') {
        //                 this.$message.success('保存成功。')
        //                 this.getListData()
        //             }
        //         })
        //     }
        // },
        // 获取对应部门的班次
        findBcmxByDeptId(id) {
            this.$http({
                contentType: 'application/x-www-form-urlencoded',
                url: `/yykq/pbgl/findBcmxByDeptId`,
                method: 'GET',
                params: { deptId: id }
            }).then(res => {
                if (res.code === 'success') {
                    this.bcglsList = res.body.bcgls
                }
            })
        },
        statistics(vals) {
            if (vals) {
                this.selectDept = vals.subOrg.id
                this.selectDeptName = vals.subOrg.name
            }
            if (!this.selectDept) {
                return this.$message.warning('请选择登记科室后查询！')
            }
            this.getDays(new Date(this.value1).getFullYear(), new Date(this.value1).getMonth() + 1)
            this.getHolidays(this.value1)
            this.loading = true
            this.dateList = []

            this.$bus.emit('refreshListData')
            this.isReady = false
            this.$nextTick(() => {
                this.isReady = true
            })
            this.month = this.value1.split('-')[1]
            this.getListData(this.time1)
        },
        getDays(year, month) {
            const days = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
            if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
                days[2] = 29
            }
            console.log(days[month])
            this.time1 = days[month]
            const weekendss = this.getWeekends(year, month)
            const weeks = weekendss.split(',')
            const thisweek = []

            for (let index = 1; index <= this.time1; index++) {
                for (let i = 0; i < weeks.length; i++) {
                    if (weeks[i] == index) {
                        thisweek.push(index)
                    }
                }
            }
            this.thisweek = thisweek
            console.log(this.thisweek, 777)
        },
        getHolidays(timer) {
            const formatTimer = moment(new Date(timer)).format('YYYY-MM')
            const formatTimerInfo = formatTimer.split('-')
            const params = [
                { 'compare': '=', 'field': 'orgCode', 'merge': false, 'value': this.loginUser.orgCode },
                { 'compare': '=', 'field': 'year', 'merge': false, 'value': formatTimerInfo[0] }
            ]
            this.$http({
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: params,
                url: 'yykq/holiday/days/querydata'
            }).then(res => {
                const month = formatTimerInfo[1]
                if (res.code === 'success') {
                    if (!res.body.listdata.length) return
                    const dayList = res.body.listdata[0].day.split(',')
                    const result = []
                    dayList.forEach(item => {
                        const timerArr = item.split('-')
                        if (timerArr[1] === month) {
                            result.push(Number(timerArr[2]))
                        }
                    })
                    this.currentMonthHolidays = result
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    })
                }
            }).catch(err => this.$message.error(err))
        },
        getListData(day) {
            day = moment(this.value1, 'YYYY-MM').daysInMonth()
            this.time1 = day
            const data = {
                clockInDate: this.value1,
                deptId: this.selectDept
            }
            const params = data
            this.$http({
                method: 'GET',
                url: '/yykq/recordResult/getPBView',
                contentType: 'application/x-www-form-urlencoded',
                params: params
            }).then(res => {
                if (res.code === 'success') {
                    this.loading = false
                    this.dateList = res.body.listdata
                    this.vo = res.body.vo
                    this.dateList = this.dateList.map(item => {
                        item.recordResults = item.recordResultList.reduce((prev, curr) => {
                            prev[curr.number] = curr
                            return prev
                        }, {})
                        return item
                    })
                } else {
                    this.$message.error(res.message)
                }
            })
        }
        // async getListData(day) {
        //     day = moment(this.value1, 'YYYY-MM').daysInMonth()
        //     this.time1 = day
        //     const data = {
        //         clockInDate: this.value1,
        //         deptId: this.selectDept
        //     }
        //     const params = data
        //     this.$http({
        //         method: 'GET',
        //         url: '/yykq/recordResult/getPBView',
        //         contentType: 'application/x-www-form-urlencoded',
        //         params: params
        //     }).then(res => {
        //         if (res.code === 'success') {
        //             this.loading = false
        //             console.log('getPBView => ', res)
        //             this.ksPersonName = res.body.ksPersonName
        //             this.kqPersonName = res.body.kqPersonName
        //             this.ksKqPersonName = res.body.ksKqPersonName
        //             this.kqPersonNum = res.body.kqPersonNum
        //             const data = res.body.list
        //             const data1 = Object.keys(data)
        //             this.peoNum = data1.length
        //             const data2 = data1.map((item) => {
        //                 return {
        //                     name: item.split('_')[1],
        //                     data: data[item]
        //                 }
        //             })
        //             this.dateList = data2
        //             this.dateList.forEach(nameList => {
        //                 nameList.data[0].passenger.listdata.forEach(data => {
        //                     if (data.recordInfos) {
        //                         var pbNoStr = ''
        //                         data.recordInfos.forEach(info => {
        //                             pbNoStr += info.bcNo + '/'
        //                         })
        //                         data.pbNoStr = pbNoStr.substring(0, pbNoStr.length - 1)
        //                     }
        //                 })
        //             })
        //             console.log(this.dateList, 123)
        //         } else {
        //             this.$message.error(res.message)
        //         }
        //     })
        // }
    }
}
</script>

<style scoped lang="scss">
/deep/.el-table .el-table__header-wrapper .el-table__header thead th{
    cursor: pointer !important;
}
/deep/.el-table--border .el-table__cell:first-child .cell {
    cursor: auto !important;
    padding-left: 10px;
}
/deep/.el-table--medium .el-table__cell {
    padding: 0px 0 !important;
}
/deep/.el-table .cell {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: normal;
    word-break: break-all;
    line-height: 40px !important;
    padding-left: 10px;
    padding-right: 10px;
    padding: 0px 0px !important;
    width: 100% !important;
}
.dj{
        padding: 5px;
}
.right {
  margin-right: 10px;
  margin-bottom: 10px;
  float: right;
  display: flex;
  justify-content: flex-end;
}
.csh{
    position: relative;
    height: 36px;
    font-size: 13px;
    padding-left: 12px;
    padding-right: 10px;
    padding-top: 10px;
    border-radius: 3px;
    margin: 0 10px;
    top: 0px;
}

.table-box {
    width: 100%;
    padding: 0 10px 10px 10px;
}

/deep/.el-table--scrollable-x .el-table__body-wrapper{
  overflow-x: auto !important;
}
/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: rgba(248, 247, 247, 0.87) !important;
}

/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
  margin: 0 2px !important;
  border-radius: 6px !important;
  background-color: rgba(199, 199, 199, 0.65) !important;
}

/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: hsla(240, 1%, 35%, 0.418)  !important;
}
.table-main-title{
    display: none;
}
.disabled-cell{
    position: relative;
}
.disabled-cell::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background:linear-gradient(
        45deg,
    rgba(102, 102, 102, .2) 0, rgba(102, 102, 102, .2) 25%, transparent 25%, transparent 50%,
    rgba(102, 102, 102, .2) 50%, rgba(102, 102, 102, .2) 75%, transparent 75%, transparent
    );
    background-size: 6px 6px;
}
</style>
<style>
.tableHeaderTitle{
    display: none;
}
</style>
