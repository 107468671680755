<template>
  <el-dialog title="打卡明细" :visible.sync="dialogVisible" width="900px">
    <!-- <div class="dk-detail-dialog"> -->
    <!-- <h4 style="text-align: center; line-height: 36px; margin: 0;">打卡明细</h4> -->
    <div v-if="normalArr.length===0 && outsideArr.length===0" style="text-align: center;">暂无数据</div>
    <table v-if="normalArr.length > 0" class="dk-list">
      <thead>
        <tr>
          <th style="width: 80px; text-align: center;">序号</th>
          <th style="width: 160px; text-align: center;">考勤时段</th>
          <!--<th style="width: 160px; text-align: center;">考勤时间</th>-->
          <th style="width: 279px; text-align: center;">打卡时间</th>
          <th style="width: 160px; text-align: center;">打卡类型</th>
          <th style="width: 160px; text-align: center;">打卡结果</th>

        </tr>
      </thead>
      <tbody>
        <tr v-for="(tr, $index) in normalArr" :key="$index">
          <td>{{ $index + 1 }}</td>
          <td style="">{{ tr.logonTime + "-" + tr.logoffTime }}</td>
          <!--<td>{{tr.recordType == 1 ? tr.logonTime : tr.logoffTime}}</td>-->
          <td>{{ tr.occureDate }}</td>
          <td>
            <template v-if="tr.recordType == 1">上班</template>
            <template v-else-if="tr.recordType == 2">下班</template>
            <template v-else-if="tr.recordType == 4">无效</template>
            <template v-else />
          </td>
          <td>{{ result[tr.recordResult] }}</td>

        </tr>
      </tbody>
    </table>
    <!--外勤-->
    <table v-if="outsideArr.length > 0" class="dk-list outside-table">
      <thead>
        <tr>
          <th style="width: 80px; text-align: center;">序号</th>
          <th style="width: 160px; text-align: center;">外勤打卡时间</th>
          <th style="width: 230px; text-align: left;">打卡地址</th>
          <th style="width: 209px;text-align: left;"> 打卡图片</th>
          <th style="width: 160px; text-align: left;">打卡备注</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(tr, index) in outsideArr" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ tr.occureDate }}</td>
          <td style="text-align: left;">
            {{ tr.unitAddress }}
          </td>
          <td :id="tr.id" class="imgs-list">
            <template v-for="(pic, pIndex) in tr.picturesPath">
              <div v-if="pIndex < 2" :key="pIndex" style="float: left; width: 70px; height: 48px; margin-right: 6px;">
                <!-- <img v-if="pic" :src="apigateway + pic" style="width: 100%; height: 100%;"> -->
                <el-image
                  style="width: 100%; height: 100%;"
                  :src="pic"
                  :preview-src-list="tr.picturesPath"
                />
              </div>
            </template>
          </td>
          <td style="text-align: left;" class="over-hidden-outer">
            <div class="over-hidden">{{ tr.remark }}</div>
            <div class="hover-show">{{ tr.remark }}<i class="triangle" /></div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- </div> -->
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: '',
    data() {
        return {
            dialogVisible: false,
            odk: {},
            normalArr: [],
            outsideArr: []
        }
    },
    computed: {
        ...mapGetters(['loginUser', 'routes'])
    },
    created() {
        this.$bus.on('dkDetailDialogOpen', this.dialogOpen)
    },
    beforeDestroy() {
        this.$bus.off('dkDetailDialogOpen')
    },
    methods: {
        dialogOpen(data) {
            this.odk = data
            this.loadingData()
            this.dialogVisible = true
        },
        // 详情查询
        loadingData() {
            this.$http({
                url: 'yykq/recordDetail/querydata',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    orderBy: 'occureDate asc'
                },
                data: [
                    {
                        'compare': '=',
                        'field': 'account',
                        'merge': true,
                        'value': this.loginUser.personAccount
                    }, {
                        'compare': '=',
                        'field': 'orgCode',
                        'merge': true,
                        'value': this.loginUser.orgCode
                    }, {
                        'compare': '=',
                        'field': 'timeId',
                        'merge': true,
                        'value': this.odk.TIME_ID
                    }, {
                        'compare': '>=',
                        'field': 'occureDate',
                        'merge': true,
                        'value': this.odk.CLOCK_IN_DATE + ' 00:00:00'
                    }, {
                        'compare': '<=',
                        'field': 'occureDate',
                        'merge': true,
                        'value': this.odk.CLOCK_IN_DATE + ' 23:59:59'
                    }
                ]
            }).then(res => {
                const $scope = this
                const list = res.body.listdata
                this.normalArr = []
                this.outsideArr = []
                $scope.result = ['', '正常', '迟到', '早退', '外勤', '加班', '补卡']
                list.forEach(function(v, i) {
                    if (v.recordType === 3 || v.recordResult === 4) {
                        v.picturesPath = v.picturesPath ? v.picturesPath.split(',') : []
                        for (let i = 0; i < v.picturesPath.length; i++) {
                            v.picturesPath[i] = process.env.VUE_APP_BASE_API + '/' + v.picturesPath[i]
                        }
                        $scope.outsideArr.push(v)
                    } else {
                        $scope.normalArr.push(v)
                    }
                })
            }).catch(err => {
                // 错误处理
                console.log(err)
            })
        }
    }
}
</script>
<style scoped lang="scss">
	.dk-detail-dialog { width: 100%; height: auto; padding: 40px 30px; border-radius: 4px;}
	.dk-list {width: 100%; border: 1px solid #EEEEEE; table-layout: fixed; margin: 30px 0; border-collapse: collapse;}
	.dk-list thead tr { background-color: #EEEEEE; }
	.dk-list th, .dk-list td{height: 46px; padding: 6px 12px; text-align: center; border: 1px solid #EEEEEE;}
	.outside-table {table-layout: fixed;}
	.over-hidden-outer {position: relative;}
	.over-hidden {width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; cursor: default; position: relative;}
	.hover-show { display: none; width: auto; white-space: nowrap; padding: 5px 12px; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.5); position: absolute; top: -32px; left: 0; z-index: 9;}
	.hover-show .triangle{ display: block; position: absolute; left: 6px; bottom: -7px;  width: 0; height: 0;border-width: 7px;border-style: solid;border-color: #FFFFFF transparent transparent transparent; border-bottom: none;}
	.over-hidden-outer:hover .hover-show { display: block;}
</style>
