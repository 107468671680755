<template>
    <div>
        <el-scrollbar id="elScrollbar" wrap-class="scrollbar-wrapper" :class="{ 'std-scrollbar-wrapper': showTopMenus, 'dark-scrollbar-wrapper': !showTopMenus }">
            <el-menu :default-active="activeMenu" :collapse="isCollapse" :background-color="menuBg" :text-color="menuText" :active-text-color="'var(--bgColor)'" :unique-opened="true" :collapse-transition="false">
                <!-- 展示全部应用名称 -->
                <el-menu-item v-show="menuName && loginUser.orgCode !== 'plat'" :style="layoutStyle === 1 ? 'border-bottom:1px solid #EBEBEB;' : ''" class="submenu-title-noDropdown submenu-title-default" @click="clickTag">
                    <svg-icon v-if="!isCollapse" style="font-size: 16px" :icon-class="menuTitle.includes(menuName) ? iconData[menuName] : 'yingyong'" @click="clickTag"></svg-icon>
                    <svg v-if="isCollapse" class="hamburger-siderA" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="64" height="64" @click="clickTag">
                        <path fill="var(--lmTextColor)" d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z" />
                    </svg>
                    <span class="appnameA">
                        {{ menuName }}
                        <svg class="hamburger-sider hamburgerActive" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="64" height="64" @click="clickTag">
                            <path fill="var(--lmTextColor)" d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z" />
                        </svg>
                    </span>
                </el-menu-item>
                <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" class="menu-wrapper_border" />
            </el-menu>
            <div style="height: 70px; background: linear-gradient(var(--lmBgColor), rgba(0, 0, 0, 0))"></div>
            <div v-if="loginUser.orgCode !== 'plat' && menuName === '工单管理' && canShowChat" class="keBox" @click="gotoChat"></div>
        </el-scrollbar>

        <!-- <div id="imgLmbg" class="lmBgimg">
      <img v-if="bgcImg&&imgShow" class="lmBgimgImg" style="width:100%;" :src="bgcImg" alt="">
    </div> -->

        <div v-if="designTime" class="designTimeSidebar" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
import variables from '@/wapp/common/styles/variables.module.scss'

export default {
    components: { SidebarItem },
    props: {
        designTime: { type: Boolean, default: false }
    },
    data() {
        return {
            menuName: '',
            menuIcon: '',
            menuIconClass: '',
            bgcImg: '',
            imgShow: false,
            hamburgerActive: true,
            loginUser: this.$store.getters.loginUser,
            canShowChat: false,
            layoutStyle: 0,
            menuTitle: ['首页', '工作台', '报表中心', '流程中心', '系统设置'],
            iconData: {
                首页: 'menhu',
                工作台: 'gzt',
                报表中心: 'apps_baobiao',
                流程中心: 'liucheng',
                应用中心: 'yingyong',
                系统设置: 'shezhi'
            }
        }
    },
    computed: {
        ...mapGetters(['routes', 'sidebar']),
        showTopMenus() {
            return this.$store.state.settings.showTopMenus
        },
        activeMenu() {
            const route = this.$route
            const { meta, path } = route

            // if set path, the sidebar will highlight the path you set
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            return path
        },
        variables() {
            return variables
        },
        isCollapse() {
            return !this.sidebar.opened
        },
        menuBg() {
            return this.showTopMenus ? variables.stdMenuBgcolor : variables.darkMenuBgcolor
        },
        menuText() {
            return this.showTopMenus ? variables.stdMenuText : variables.darkMenuText
        },
        menuActiveText() {
            return this.showTopMenus ? variables.stdMenuAcitveText : variables.darkMenuAcitveText
        }
    },
    watch: {
        $route(val) {
            this.menuName = localStorage.getItem('setSysMenuName')
        }
    },
    created() {
        this.getIsEs()
        this.menuName = localStorage.getItem('sys-menuName')
        if (this.$route.query.sys) {
            this.menuName = decodeURI(this.$route.query.sys)
        }
        this.openChat()
        this.$bus.on('getMenuName', this.getMenuName)
        this.menuName = localStorage.getItem('setSysMenuName')
    },
    mounted() {
        const observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (Number(entry.contentRect.width) > 180) {
                    this.imgShow = true
                } else {
                    this.imgShow = false
                }
                // 可以通过entry回调中的contentRect来获取目标元素当前宽度和高度数据
            }
        })

        const target = document.querySelector('.el-scrollbar')
        observer.observe(target) // 开始监听目标元素

        this.$http({
            // 访问用户基本设置信息
            method: 'GET',
            contentType: 'application/x-www-form-urlencoded',
            params: { id: this.$store.getters.loginUser.userId },
            url: 'base/user/getById'
        }).then(
            (response) => {
                if (response.code === 'success') {
                    this.uiStyle = response.body.vo.uiStyle
                    this.getStyleListData()
                } else {
                    this.$message.error(response.message)
                }
            },
            (err) => {
                console.log(err)
            }
        )
    },
    beforeDestroy() {
        this.$bus.off('getMenuName', this.getMenuName)
        localStorage.removeItem('sys-menuName')
    },
    methods: {
        openChat() {
            this.$http({
                method: 'get',
                contentType: 'application/x-www-form-urlencoded',
                params: {
                    orgCode: 'standard',
                    paramName: 'base.isOpenChat'
                },
                url: 'base/paramConfig/findParamValueByName'
            }).then((res) => {
                if (res.code === 'success') {
                    this.canShowChat = res.body.value === 'true'
                }
            })
        },
        gotoChat() {
            window.open('/#/kflt?isAdmin=2')
        },
        clickTag() {
            if (this.layoutStyle === 1) {
                this.hamburgerActive = !this.hamburgerActive
                this.$bus.emit('toggleClick')
                this.$bus.emit('theSiderShow')
                this.$bus.emit('openSideBar', false)
                this.$store.dispatch('app/toggleSideBar')
            } else {
                this.hamburgerActive = !this.hamburgerActive
                this.$store.dispatch('app/toggleSideBar')
                this.$bus.emit('toggleClick')
                this.$bus.emit('theSiderShow')
            }
        },
        // 判断是否开启es
        getIsEs() {
            this.$http({
                method: 'get',
                url: '/common/elasticsearch/openElasticsearch'
            }).then((res) => {
                if (res.code === 'success') {
                    if (!res.body.openElasticsearch) {
                        this.routes.forEach((item, index) => {
                            if (item.meta.code === 'dcdb_appdbsx.svg') {
                                if (item.children.length > 0) {
                                    item.children.forEach((val, idx) => {
                                        if (val.meta.code === 'qwjs') {
                                            item.children.splice(idx, 1)
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            })
        },
        getStyleListData() {
            this.$http({
                url: '/base/styleConfig/querydata',
                method: 'post',
                contentType: 'application/x-www-form-urlencoded',
                data: [
                    { compare: '=', field: 'styleCode', merge: true, value: this.uiStyle || '' },
                    { compare: '=', field: 'orgCode', merge: true, value: this.loginUser.orgCode }
                ]
            }).then(
                (res) => {
                    if (res.code === 'success') {
                        if (res.body.listdata.length > 0) {
                            this.layoutStyle = res.body.listdata[0].columnMenu
                            if (res.body.listdata[0].lmBgImg) {
                                this.bgcImg = process.env.VUE_APP_BASE_API + '/base/styleConfig/download?nameField=lmBgImg&dataField=lmBgImgFile&id=' + res.body.listdata[0].id
                                // setTimeout(() => {
                                //     this.$nextTick(() => {
                                //         debugger
                                //         const imgLmbgDom = document.getElementById('imgLmbg')
                                //         let imgHeight = 0
                                //         if (imgLmbgDom) {
                                //             imgHeight = imgLmbgDom.offsetHeight
                                //         }
                                //         document.getElementById('elScrollbar').style.height = `calc(100% - ${imgHeight}px)`
                                //     })
                                // }, 2000)
                                let imgDom = ''
                                if (res.body.listdata[0].columnMenu === 0) {
                                    imgDom = document.querySelector('#elScrollbar .scrollbar-wrapper')
                                } else {
                                    imgDom = document.querySelector('.topMu')
                                    imgDom.style.backgroundSize = '100%'
                                }
                                imgDom.style.backgroundImage = `url(${this.bgcImg})`
                                imgDom.style.backgroundRepeat = 'no-repeat'
                                imgDom.style.backgroundPosition = 'bottom'
                            }
                        }
                    } else {
                        this.$message.error(res.message)
                    }
                },
                (err) => {
                    console.log(err)
                }
            )
        },
        getMenuName(menuName, menuIcon) {
            console.log(menuName, menuIcon, 'menuName, menuIcon')
            // this.menuName = menuName
            this.menuIcon = menuIcon
            if (this.menuIcon.indexOf('.svg') > -1) {
                this.menuIconClass = this.menuIcon.substring(0, this.menuIcon.lastIndexOf('.svg'))
            } else {
                this.menuIcon = 'el-icon-' + this.menuIcon
            }
        }
    }
}
</script>

<style lang="scss">
@import '~@/wapp/common/styles/variables.module.scss';
.appnameA {
    // margin-left: -5px;
    font-weight: 600;
}
.keBox {
    position: absolute;
    cursor: pointer;
    bottom: 130px;
    right: 30px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    background-image: url('~public/images/w-imgs/app-icons/kfgl.png');
    background-size: 70% 70%;
    background-position: center;
    background-repeat: no-repeat;
}
.hamburger-sider {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.hamburger-siderA {
    width: 22px;
    height: 22px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.hamburger-sider-hide {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
}
.chat-icon {
    font-size: 26px;
    cursor: pointer;
    color: var(--textColor);
    position: relative;
    left: 50%;
    top: 16px;
    transform: translate(-50%, -50%);
}
.hamburgerActive {
    transform: rotate(180deg) translateY(50%);
}
.menu-wrapper_border {
    position: relative;
    &::after {
        position: absolute;
        content: '';
        width: 100%;
        bottom: 0;
        left: 0;
        // border-bottom: 1px solid #ececec;
        z-index: 1;
    }
}
.is-disabled {
    opacity: 1 !important;
}
.el-menu--popup {
    overflow: hidden;
    border-radius: 3px;
}
/* 没有顶部导航时 */
.nest-menu.notop {
    border-top: none !important;
    .el-menu-item {
        background-color: $dark-sub-menu-bgcolor !important;
        border-bottom: 1px dotted #777;
        height: 50px;
        line-height: 50px;
        &.is-active {
            color: #fff !important;
            &:after {
                right: 0;
            }
        }
        &:hover {
            background-color: #445c80 !important;
            color: #fff !important;
        }
    }

    &:last-child .el-menu-item {
        border-bottom: none;
    }
}
.el-menu-item {
    height: $menuItemHeight;
}

.std-scrollbar-wrapper {
    // background-color: $std-menu-bgcolor;
    background-color: var(--lmBgColor);
    border-right: 1px solid #e6e6e6;

    .submenu-title-noDropdown,
    .el-submenu__title {
        &:hover {
            background-color: $std-menu-hover !important;
        }
    }

    .is-active > .el-submenu__title {
        color: $std-sub-menu-active-text; // !important;
    }

    & .nest-menu .el-submenu > .el-submenu__title,
    & .el-submenu .el-menu-item {
        // background-color: $std-sub-menu-bgcolor !important;
        background-color: var(--lmBgColor) !important;
        &:hover {
            background-color: $std-sub-menu-hover !important;
        }
    }
}

.dark-scrollbar-wrapper {
    background-color: $dark-menu-bgcolor;
    border-right: $dark-menu-bgcolor solid 1px;

    .submenu-title-noDropdown,
    .el-submenu__title {
        &:hover {
            background-color: $dark-menu-hover !important;
        }
    }

    .is-active > .el-submenu__title {
        /* color: $dark-sub-menu-active-text;// !important; */
        color: #fff; // !important;
    }

    & .nest-menu .el-submenu > .el-submenu__title,
    & .el-submenu .el-menu-item {
        background-color: $dark-sub-menu-bgcolor !important;
        border-bottom: none;
        /* background-color:#51688b !important; */

        &.is-active {
            color: #fff !important;
        }

        &:hover {
            /* background-color: $dark-sub-menu-hover !important; */
            background-color: #445c80 !important;
            color: #fff !important;
        }
    }
}
.hideSidebar {
    .sidebar-container {
        .submenu-title-default {
            svg {
                margin-left: 21px;
                font-size: 19px !important;
            }
        }
    }
}
.designTimeSidebar {
    margin: 0px;
    top: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.7;
}
.lmBgimg {
    // width:calc(100% - 1px);
    width: 100%;
    height: 0px;
    position: relative;
    background-color: var(--lmBgColor);
    text-align: right;
    padding-bottom: 10px;
    .lmBgimgImg {
        position: absolute;
        top: -100%;
        right: 0;
        width: 100%;
    }
}
</style>
