<template>
  <div id="DoorLayout" :style="{ 'grid-template-columns': `repeat(${layoutItem.w}, 1fr)`}">
    <template v-for="(block, index) in blocks">
      <div
        :key="index"
        class="DoorLayout_item"
        :class="'DoorLayout_item_' + getBlockDoorConfig(block).currDirection"
      >

      </div>
    </template>
  </div>
</template>
<script>
export default {
    props: {
        layoutItem: { type: Object, default: () => {}, require: true }
    },
    computed: {
        blocks() {
            const result = []
            for (let y = 1; y <= this.layoutItem.h; y++) {
                for (let x = 1; x <= this.layoutItem.w; x++) {
                    result.push({ x, y })
                }
            }
            return result
        }
    },
    methods: {
        getBlockDoorConfig(block) {
            const Door = this.layoutItem.layout.find(item => item.x === block.x && item.y === block.y)
            return Door && Door.config || { direction: [], currDirection: '' }
        }
    }
}
</script>

<style lang="scss" scoped>
#DoorLayout{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    display: grid;
    user-select: none;
    pointer-events: none;
    .DoorLayout_item{
        position: relative;
        height: 30px;
    }

    .DoorLayout_item_R::after{
        content: '';
        position: absolute;
        right: 1px;
        top: 1px;
        bottom: 0;
        width: 6px;
        background: #999999;
        outline: 1px #999999 solid;
    }
    .DoorLayout_item_T::after{
        content: '';
        position: absolute;
        right: 0;
        top: 1px;
        left: 0;
        height: 6px;
        background: #999999;
        outline: 1px #999999 solid;
    }
    .DoorLayout_item_L::after{
        content: '';
        position: absolute;
        left: 1px;
        top: 0;
        bottom: 0;
        width: 6px;
        background: #999999;
        outline: 1px #999999 solid;
    }
    .DoorLayout_item_B::after{
        content: '';
        position: absolute;
        right: 0;
        bottom: 0px;
        left: 0;
        height: 6px;
        background: #999999;
        outline: 1px #999999 solid;
    }
}
</style>
