<template>
  <div class="personal-duty-table">
    <el-input v-show="false"></el-input>
    <div class="month-cell">
      <div style="display: flex;width: 50%;">
        <div class="month-cell_font">日期：</div>
        <div class="month-cell_date-box">
          <el-date-picker
            v-model="value1"
            disabled
            style="width:100%;margin-top:4px;"
            class="riqi"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </div>
      </div>
      <div style="display: flex;width: 50%;border-left:1px solid #e2e7ed">
        <div class="month-cell_font">宿舍：</div>
        <div class="month-cell_date-box" style="line-height: 44px;">
          {{ vo.roomNo || vo[0].roomNo }}
        </div>
      </div>
    </div>
    <el-table
      :data="tableData"
      :highlight-current-row="false"
      border
      style="width: 100%"
      :header-cell-style="{background:'#fff'}"
    >
      <el-table-column
        type="index"
        label="序号"
        width="50"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="xs"
        label="学生"
        width="140"
        align="center"
      >
        <!-- <template slot-scope="scope">
          <div @click="selRow(scope)">
            <StudentClassInput :ref="'scoreUserName'+scope.$index" :disabled="false" :dormitory="true" :cur-val="tableData[scope.$index].ownerName" @sel-row="onSelectListpageRowCompleted" />
          </div>
        </template> -->
        <template slot-scope="scope">{{ tableData[scope.$index].ownerName }}</template>
      </el-table-column>
      <!-- <el-table-column
        prop="ss"
        label="宿舍"
        width="110"
        align="center"
      >

        <template slot-scope="scope">{{ tableData[scope.$index].roomNo }}</template>
      </el-table-column> -->
      <el-table-column prop="bj" label="班级" width="119" align="center">
        <template slot-scope="scope">{{ tableData[scope.$index].gradeClass }}</template>
      </el-table-column>
      <el-table-column
        prop="val"
        label="早上迟出"
        width="110"
        align="center"
      >
        <template slot-scope="scope">
          <el-input-number v-model="tableData[scope.$index].morningDeduct" size="mini" :min="0"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        prop="val"
        label="午睡"
        width="110"
        align="center"
      >
        <template slot-scope="scope">
          <el-input-number v-model="tableData[scope.$index].noonDeduct" size="mini" :min="0"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        prop="val"
        label="晚睡"
        width="110"
        align="center"
      >
        <template slot-scope="scope">
          <el-input-number v-model="tableData[scope.$index].nightDeduct" size="mini" :min="0"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        prop="val"
        label="迟到"
        width="110"
        align="center"
      >

        <template slot-scope="scope">
          <el-input-number v-model="tableData[scope.$index].lateDeduct" size="mini" :min="0"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        prop="val"
        label="违纪"
        width="110"
        align="center"
      >
        <template slot-scope="scope">
          <el-input-number v-model="tableData[scope.$index].breachDeduct" size="mini" :min="0"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        prop="bz"
        label="备注"
        align="left"
      >
        <template slot-scope="scope">
          <el-input v-model="tableData[scope.$index].memo" placeholder="请输入"></el-input>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" align="center" width="110">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="deleteRow(scope.$index)">删除</el-button>
        </template>
      </el-table-column> -->

    </el-table>
    <!-- <div class="btn-box">
      <el-button type="primary" @click="addNewTheme">新增</el-button>
    </div> -->
  </div>
</template>

<script>
//
// import StudentClassInput from './student-class-input.vue'
export default {
    components: {
        // StudentClassInput
    },
    props: {
        vo: null
    },
    data() {
        return {
        //
            showname: false,

            showbtn: true,
            studentList: [],
            studentInfo: {},
            classList: [],
            dialogFormVisible: false,
            formLabelWidth: '120px',
            formInline: {
                grade: '',
                class: ''
            },
            curGrade: '',
            curClass: '',
            checkName: '',
            //
            //
            refList: [],
            currentSelIndex: 0,
            value1: '',
            tableData: [{
                classId: null,
                classNo: null,
                gradeClass: null,
                scoreUserId: null,
                bedNo: null,
                buildId: null,
                scoreUserName: '',
                ownerName: '',
                morningDeduct: 0,
                noonDeduct: 0,
                nightDeduct: 0,
                lateDeduct: 0,
                breachDeduct: 0,
                memo: '',
                roomId: null,
                roomNo: null,
                dutyDate: '',
                scoreTime: ''
            }]

        }
    },
    watch: {
        tableData: {
            deep: true,
            immediate: true,
            handler(newVal) {
                console.log('newvla', newVal)
                this.tableData.forEach((item) => {
                    console.log('item', item)
                    item.scoreTime = this.value1
                    item.dutyDate = this.value1
                })
                console.log('this.tableList', this.tableData)
                this.$bus.emit('getDataByOther', newVal)
            }
        },
        value1(newVal) {
            this.tableData.forEach((item) => {
                item.scoreTime = newVal
                item.dutyDate = newVal
            })
        },
        formInline: {
            deep: true, // 开启深度监听
            async handler(v) {
                console.log('监听到变化', v)
                this.curGrade = v.grade
                const { body: { vo }} = await this.$http({
                    method: 'get',
                    url: '/bus/gradeClass/getAllClass',
                    params: { grade: v.grade }
                })
                console.log('vo', vo)
                this.classList = vo.map((item, index) => {
                    return { label: item.classNo + '班', value: index, classid: item.id }
                })
                this.curClass = v.class
            }
        }

    },
    created() {
        if (Array.isArray(this.vo)) {
            this.value1 = this.vo[0].dutyDate
            this.tableData = this.vo
        } else {
            this.value1 = this.vo.dutyDate
            this.tableData = this.vo.passenger.dataList
        }
    },
    mounted() {
        this.$parent.$el.children[0].setAttribute('class', 'el-table el-table--fit')
    },
    methods: {
        async onSubmit() {
            console.log('当前年级', this.curGrade)
            console.log('当前班级', this.curClass)
            this.studentList = []
            const res = await this.$http({
                method: 'get',
                url: '/bus/student/getClassStudent',
                params: {
                    classId: this.classList[this.curClass].classid
                }
            })
            console.log('res', res)
            this.studentList = res.body.list.map(item => {
                return {
                    bedId: item.bedId,
                    bedNo: item.bedNo,
                    buildId: item.buildId,
                    classId: item.classId,
                    classNo: item.classNo,
                    gradeClass: item.gradeClass,
                    roomId: item.roomId,
                    memo: item.memo,
                    name: item.name,
                    gender: item.gender == 2 ? '女' : '男',
                    id: item.id,
                    roomNo: item.roomNo,
                    startYear: item.startYear
                }
            })
        },
        handleClick(row) {
            console.log(row)
            this.checkName = row.name
            this.showbtn = false
            this.showname = true
            this.dialogFormVisible = false
            this.studentInfo = row
            console.log('this.studentInfo', this.studentInfo)
            this.tableData[0].classId = this.studentInfo.classId
            this.tableData[0].classNo = this.studentInfo.classNo
            this.tableData[0].gradeClass = this.studentInfo.gradeClass
            this.tableData[0].bedNo = this.studentInfo.bedNo
            this.tableData[0].buildId = this.studentInfo.buildId
            this.tableData[0].scoreUserId = this.studentInfo.id
            this.tableData[0].ownerName = this.studentInfo.name
            // this.$refs['scoreUserName' + this.currentSelIndex].formItem.value = this.studentInfo.name
            console.log('121312', this.tableData)
            // this.vo.scoreUserName = studentInfo[0].name
            this.$http({
                method: 'post',
                url: '/bus/buildingRoom/getRoomByStudent',
                params: { studentID: this.studentInfo.id }
            }).then((res) => {
                if (res.body.vo.length === 0) {
                    this.tableData[0].roomId = ''
                    this.tableData[0].roomNo = ''
                } else {
                    this.tableData[0].roomId = res.body.vo[0].roomID
                    this.tableData[0].roomNo = res.body.vo[0].roomOn
                }
            })
        },
        addNewTheme() {
            if ((this.tableData.length === 0) || this.tableData.at(-1).ownerName !== '') {
                this.tableData.push({
                    classId: null,
                    classNo: null,
                    bedNo: null,
                    buildId: null,
                    gradeClass: null,
                    scoreUserId: null,
                    scoreUserName: '',
                    ownerName: '',
                    morningDeduct: 0,
                    noonDeduct: 0,
                    nightDeduct: 0,
                    lateDeduct: 0,
                    breachDeduct: 0,
                    roomId: null,
                    roomNo: null,
                    memo: null,
                    dutyDate: '',
                    scoreTime: '' })
            } else {
                this.$message.warning('请编辑数据后再新增数据')
            }
        },

        onSelectListpageRowCompleted(studentInfo) {
            console.log('this.currentSelIndexthis.currentSelIndex', this.currentSelIndex)
            this.tableData[this.currentSelIndex].classId = studentInfo.classId
            this.tableData[this.currentSelIndex].classNo = studentInfo.classNo
            this.tableData[this.currentSelIndex].gradeClass = studentInfo.gradeClass
            this.tableData[this.currentSelIndex].bedNo = studentInfo.bedNo
            this.tableData[this.currentSelIndex].buildId = studentInfo.buildId
            this.tableData[this.currentSelIndex].scoreUserId = studentInfo.id
            this.tableData[this.currentSelIndex].ownerName = studentInfo.name
            this.$http({
                method: 'post',
                url: '/bus/buildingRoom/getRoomByStudent',
                params: { studentID: studentInfo.id }
            }).then((res) => {
                if (res.body.vo.length === 0) {
                    this.tableData[this.currentSelIndex].roomId = ''
                    this.tableData[this.currentSelIndex].roomNo = ''
                } else {
                    this.tableData[this.currentSelIndex].roomId = res.body.vo[0].roomID
                    this.tableData[this.currentSelIndex].roomNo = res.body.vo[0].roomOn
                }
            })
        },
        selRow(row) {
            this.currentSelIndex = row.$index
        },
        deleteRow(index) {
            this.tableData.splice(index, 1)
            this.tableData.forEach((item, tableIndex) => {
                console.log(this.$refs['scoreUserName' + tableIndex].selStudent, item.ownerName)
                this.$refs['scoreUserName' + tableIndex].selStudent = item.ownerName
            })
        }
    }
}
</script>

<style scoped lang="scss">
td {
  border: 1px;
}
.personal-duty-table{
  padding-right: 2px;
}
div {
}

.month-cell{
    display: flex;
    height: 44px;
    border: 1px solid #e2e7ed !important;
    border-bottom: 0px !important;
    align-items: center;
    .month-cell_font{
      text-align: right;
      line-height: 44px;
      font-size: 13px;
      font-weight: 600;
      width: 190px;
      border-right: #e2e7ed 1px solid;
      text-rendering: optimizeLegibility;
      font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
      color: #000;
    }
    .month-cell_date-box{
        margin-left: 20px;
    }
}

.sel-group{
    display: flex;
    justify-content: space-around;
}
.el-select {
    width: 116px;
}

.el-input-number{
    width: 90px !important;
}

.btn-box {
    padding: 15px;
    position:relative;
    left: -2px;
     z-index:999;
     background:#fff;
     display: flex;
     justify-content: center;
}
/deep/ .el-dialog .el-dialog__header{
  height: 54px;
}
</style>
