import Vue from 'vue'
import httpVueLoader from 'http-vue-loader'
const _import = require('@/wapp/_import/_' + process.env.NODE_ENV)

Vue.prototype.$loadComponent = function(vueUrl) {
    vueUrl = vueUrl.replace(/\/\//g, '/').replace('component：', '')
    try {
        if (vueUrl.indexOf('#/dcontent') === 0) {
            let productCode = sessionStorage.getItem('productCode')
            if (!productCode) {
                productCode = window.productCode
            }
            if (productCode !== '') {
                if (vueUrl.indexOf('?') === -1) {
                    vueUrl += '?productCode=' + productCode
                } else {
                    vueUrl += '&productCode=' + productCode
                }
            }

            if (vueUrl.indexOf('?') === -1) {
                vueUrl += '?httpPort=' + location.port
            } else {
                vueUrl += '&httpPort=' + location.port
            }

            return httpVueLoader(
                process.env.VUE_APP_BASE_API + vueUrl.substring(1)
            )
        } else if (vueUrl.indexOf('@/') === 0) {
            const componentName = vueUrl
                .substring(vueUrl.lastIndexOf('/') + 1)
                .split('.')[0]
                .split('?')[0]
            if (
                Vue.options.components[componentName] &&
                vueUrl.indexOf('private=1') === -1
            ) {
                return Vue.options.components[componentName]
            } else {
                return _import(vueUrl.substring(7).split('?')[0])
            }
        }
        console.error('vueurl error:' + vueUrl)
    } catch (e) {
        console.error(e)
    }
}

if (typeof window !== 'undefined') {
    window.$loadComponent = Vue.prototype.$loadComponent
}
