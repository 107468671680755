<template>
  <div>
    <el-table class="xzhqTable" :data="wfInstance.passenger.gwhScheduleDaliys" border style="width: 100%" @row-click="rowClick">
      <el-table-column prop="workDay" label="日期" width="100" align="center">
        <template slot-scope="scope">
          <span>{{ changeDay(scope.row.workDay) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="daliyLeader" label="带班领导" align="center" />
      <el-table-column prop="dayName" label="白班值班人员" width="" align="center">
        <template slot-scope="scope">
          <el-input v-if="curTn.tnNodeName === '部门填报' && scope.row.operatorId === loginUser.personId" v-model="scope.row.dayName" v-select-dialog="'staff'" :org-id="scope.row.operatorDeptId" name="dayName" resize="none" />
          <span v-else>{{ scope.row.dayName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="nightName" label="晚班值班人员" width="" align="center">
        <template slot-scope="scope">
          <el-input v-if="curTn.tnNodeName === '部门填报' && scope.row.operatorId === loginUser.personId" v-model="scope.row.nightName" v-select-dialog="'staff'" :org-id="scope.row.operatorDeptId" name="nightName" resize="none" />
          <span v-else>{{ scope.row.nightName }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
const moment = require('moment')
import { mapGetters } from 'vuex'
export default {
    components: {

    },
    props: {
        vo: { type: Object, default: function() { return {} } },
        wfInstance: { type: Object, default: function() { return {} } },
        curTn: { type: Object, default: function() { return {} } },
        canEdit: { type: Number, default: function() { return 1 } }
    },
    data() {
        return {
            tableData: [],
            rowIndex: 0
        }
    },
    computed: {
        ...mapGetters(['routes', 'loginUser']),
        changeDay() {
            return (day) => {
                return moment(day).format('M月D号')
            }
        }
    },
    watch: {

    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
        onSelectStaffCompleted(nodes, attr) {
            this.wfInstance.passenger.gwhScheduleDaliys[this.rowIndex].reponseLevel = this.vo.repsonseLevel
            if (attr.name === 'dayName') {
                this.wfInstance.passenger.gwhScheduleDaliys[this.rowIndex].dayName = nodes[0].name
                this.wfInstance.passenger.gwhScheduleDaliys[this.rowIndex].dayContact = nodes[0].mobile
                this.wfInstance.passenger.gwhScheduleDaliys[this.rowIndex].dayAccount = nodes[0].personId
                if (!this.wfInstance.passenger.gwhScheduleDaliys[this.rowIndex].nightName) {
                    this.wfInstance.passenger.gwhScheduleDaliys[this.rowIndex].nightName = nodes[0].name
                    this.wfInstance.passenger.gwhScheduleDaliys[this.rowIndex].nightContact = nodes[0].mobile
                    this.wfInstance.passenger.gwhScheduleDaliys[this.rowIndex].nightAccount = nodes[0].personId
                }
            } else if (attr.name === 'nightName') {
                this.wfInstance.passenger.gwhScheduleDaliys[this.rowIndex].nightName = nodes[0].name
                this.wfInstance.passenger.gwhScheduleDaliys[this.rowIndex].nightContact = nodes[0].mobile
                this.wfInstance.passenger.gwhScheduleDaliys[this.rowIndex].nightAccount = nodes[0].personId
                if (!this.wfInstance.passenger.gwhScheduleDaliys[this.rowIndex].dayName) {
                    this.wfInstance.passenger.gwhScheduleDaliys[this.rowIndex].dayName = nodes[0].name
                    this.wfInstance.passenger.gwhScheduleDaliys[this.rowIndex].dayContact = nodes[0].mobile
                    this.wfInstance.passenger.gwhScheduleDaliys[this.rowIndex].dayAccount = nodes[0].personId
                }
            }
        },
        rowClick(row, column, event) {
            console.log('row', row)
            this.wfInstance.passenger.gwhScheduleDaliys.map((item, index) => {
                if (item.workDay === row.workDay) {
                    this.rowIndex = index
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.xzhqTable {
  /deep/ .el-input input {
    pointer-events: none;
  }
}
</style>
