<template>
  <span v-if="!f" class="btn" @click="startWfInstance">补卡申请</span>
  <span v-else>已申请</span>
</template>

<script>
export default {
    name: 'ResignApply',
    props: {
        attr: { type: Object, default() { return {} } }
    },
    data() {
        return {
            f: true
        }
    },
    created() {
        this.runRequestBk()
    },
    methods: {
        // 执行补卡查询
        async runRequestBk() {
            this.f = await this.requestBkInfo()
        },
        // 查询补卡信息
        requestBkInfo() {
            return new Promise((resolve, reject) => {
                this.$http({
                    url: 'yykq/resignInfo/getById',
                    method: 'GET',
                    params: {
                        id: this.attr.id
                    }
                }).then(data => {
                    resolve(data.code === 'success')
                }).catch(err => {
                // 异常处理
                    reject(err)
                })
            })
        },
        startWfInstance(code, name, start, wfEngineUrl) {
            let href = '/wflow?busiCode=resign&wfName=' + (encodeURI('补卡申请')) + '&startNodeName=' + ''
            href += '&wfEngineUrl=' + encodeURIComponent('yykq/resign') + '&detialId=' + this.attr.id

            window.open('/#' + href)
        }
    }
}
</script>

<style scoped lang="scss">
.btn {
    color: #409eff;
    padding: 0 12px;
    cursor: pointer;
}
</style>
