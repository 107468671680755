// layout元素，会根据数组内name去动态加载所有组件
export const Elements = [
    {
        show: true,
        areaId: null,
        areaNumber: '',
        name: 'room', // 过道/走廊
        title: '物料区域',
        w: 3,
        h: 2,
        layout: [], // 房间支持嵌套定义
        config: {
            backgroundColor: '#f6f9ff',
            hidden: [] // 需要隐藏的墙壁 L,R,T,B 左右上下
        }
    },
    {
        show: true,
        areaId: null,
        areaNumber: '',
        name: 'area', // 功能区域
        title: '功能区域',
        w: 3,
        h: 2,
        config: {
            backgroundColor: '#f4fbfd',
            hidden: [] // 需要隐藏的墙壁 L,R,T,B 左右上下
        }
    },
    {
        show: true,
        areaId: null,
        areaNumber: '',
        name: 'corridor', // 过道/走廊
        title: '过道',
        w: 1,
        h: 4,
        config: {
            backgroundColor: '#eef2f5',
            hidden: [] // 需要隐藏的墙壁 L,R,T,B 左右上下
        }
    },
    {
        show: false,
        areaId: null,
        areaNumber: '',
        name: 'door',
        title: '门',
        w: 1,
        h: 1,
        config: {
            direction: [], // 可选择朝向 L,R,T,B
            currDirection: '' // 当前朝向
        }
    }
]
