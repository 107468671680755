<template>
  <div style="overflow: hidden;position: relative;">
    <!-- 周视图 -->
    <template>
      <div class="week-time">
        <div class="week-content"></div>
        <div class="content-info">
          <el-tooltip class="item" effect="dark" :content="shouOrOPen?'展开':'收起'" placement="top">
            <svg-icon v-if="!shouOrOPen" icon-class="shouqi" style="font-size: 14px;cursor: pointer;position: absolute;bottom: 5px;right: 5px;" @click="changType(1)" />
            <svg-icon v-if="shouOrOPen" icon-class="zhankai" style="font-size: 14px;cursor: pointer;position: absolute;bottom: 5px;right: 5px;" @click="changType(2)" />
          </el-tooltip>
          <div v-for="(itemA,indexC) in 7" :key="indexC" class="info-item" />
        </div>
      </div>
      <div v-for="(item,index) in listDetail" :key="index">
        <div class="week-time" :class="[{canOPen:index<7},{canOPenA:(index>17 && index<=23)}]">
          <div class="week-content">
            <span class="content-title">{{ item.title }}</span>
          </div>
          <div class="content-info">
            <el-tooltip class="item" effect="dark" :content="shouOrOPenA?'展开':'收起'" placement="top">
              <svg-icon v-if="!shouOrOPenA&&index==17" icon-class="shouqi" style="font-size: 14px;cursor: pointer;position: absolute;bottom: 5px;right: 5px;" @click.stop="changTypeA(1)" />
              <svg-icon v-if="shouOrOPenA&&index==17" icon-class="zhankai" style="font-size: 14px;cursor: pointer;position: absolute;bottom: 5px;right: 5px;" @click.stop="changTypeA(2)" />
            </el-tooltip>
            <div v-for="(itemA,indexB) in item.valData" :key="indexB" class="info-item" @click="addData(item,indexB,'week')">
              <template v-if="itemA.listdata.length>0">
                <template v-if="itemA.listdata.length>1">
                  <div v-for="(val,indexC) in itemA.listdata" :key="indexC" style="position: relative;" :style="{'width':val.width}">
                    <div v-if="!val.isEnd" style="position: absolute;" class="info-box" :class="'info-box_'+val.listDetail.id" :style="{'width':'100%','height': (val.timeHeight-2)+'px','margin-top':val.startHeight+'px'}" @mouseenter="enter(val)" @mouseleave="leave(val)" @click.stop="getDetail(val.listDetail,false)">
                      <span :style="{'color':val.listDetail.importantWork==1?'red':''}" class="info-title">{{ val.listDetail.title }}</span>
                    </div>
                    <div v-if="val.isEnd" :class="'info-box_'+val.listDetail.id" style="position: absolute;color: #929292;border-left: 3px solid #929292;" class="info-box" :style="{'width':'100%','height': (val.timeHeight-2)+'px','margin-top':val.startHeight+'px'}" @mouseenter="enter(val)" @mouseleave="leave(val)" @click.stop="getDetail(val.listDetail,false)">
                      <span class="info-title">{{ val.listDetail.title }}</span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div v-for="(val,indexC) in itemA.listdata" :key="indexC" style="position: relative;" :style="{'width':val.width,'left':val.left}">
                    <div v-if="!val.isEnd" style="position: absolute;" class="info-box" :class="'info-box_'+val.listDetail.id" :style="{'width':'100%','height': (val.timeHeight-2)+'px','margin-top':val.startHeight+'px'}" @mouseenter="enter(val)" @mouseleave="leave(val)" @click.stop="getDetail(val.listDetail,false)">
                      <span :style="{'color':val.listDetail.importantWork==1?'red':''}" class="info-title">{{ val.listDetail.title }}</span>
                    </div>
                    <div v-if="val.isEnd" :class="'info-box_'+val.listDetail.id" style="position: absolute;color: #929292;border-left: 3px solid #929292;" class="info-box" :style="{'width':'100%','height': (val.timeHeight-2)+'px','margin-top':val.startHeight+'px'}" @mouseenter="enter(val)" @mouseleave="leave(val)" @click.stop="getDetail(val.listDetail,false)">
                      <span class="info-title">{{ val.listDetail.title }}</span>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>

        </div>
      </div>
    </template>
    <div v-if="rowdata.boxShow" class="proBox" :style="{'top':topWidth,'left':leftWidth}">
      <div class="detail-list">
        <div class="tip-title">{{ rowdata.title }}</div>
        <div class="tip-start">开始时间：{{ rowdata.startTime }}</div>
        <div class="tip-end">结束时间：{{ rowdata.endTime }}</div>
        <div class="tip-createPerson">创建人：{{ rowdata.creator }}</div>
        <div class="tip-place">是否为重点工作：{{ rowdata.importantWork==1?'是':'否' }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        whichType: {
            type: String,
            default: '1'
        },
        listDetail: {
            type: Array,
            default: function() {
                return []
            }
        },
        weekArray: {
            type: Array,
            default: function() {
                return []
            }
        }
    },
    data() {
        return {
            monthClick: false,
            bigScreen: false,
            shouOrOPen: false,
            shouOrOPenA: false,
            liHeight: 0,
            rowdata: {},
            topWidth: '',
            leftWidth: ''
        }
    },
    watch: {
    },
    mounted() {
        this.getWindowInfo()
        window.addEventListener('resize', this.getWindowInfo)
        this.initLine()
        if (this.whichType === '4') {
            setTimeout(() => {
                this.initLine()
            }, 300)
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getWindowInfo)
    },
    methods: {
        enter(item) {
            item.listDetail.boxShow = true
            this.rowdata = item.listDetail
            const position = document.querySelector('.info-box_' + item.listDetail.id).getBoundingClientRect()
            this.leftWidth = position.left + position.width + 20 + 'px'
            this.topWidth = (position.top + (position.height / 2 - 76)) + 'px'
            this.$forceUpdate()
        },
        leave(item) {
            item.listDetail.boxShow = false
            this.$forceUpdate()
        },
        initLine() {
            this.$nextTick(() => {
                if (document.querySelectorAll('.canOPen') && document.querySelectorAll('.canOPenA')) {
                    document.querySelectorAll('.canOPen').forEach(item => {
                        item.style = 'display:none;'
                        this.shouOrOPen = true
                    })
                    document.querySelectorAll('.canOPenA').forEach(item => {
                        item.style = 'display:none;'
                        this.shouOrOPenA = true
                    })
                }
                if (document.querySelectorAll('.el-scrollbar__view').length === 3) {
                    this.liHeight = document.querySelectorAll('.el-scrollbar__view')[2].clientHeight
                    this.$bus.emit('liH', this.liHeight)
                }
            })
        },
        changType(type) {
            if (type === 1) {
                if (!this.shouOrOPen) {
                    document.querySelectorAll('.canOPen').forEach(item => {
                        item.style = 'display:none;'
                    })
                    this.shouOrOPen = true
                }
            } else {
                if (this.shouOrOPen) {
                    document.querySelectorAll('.canOPen').forEach(item => {
                        item.style = 'display:flex;'
                    })
                    this.shouOrOPen = false
                }
            }
            if (document.querySelectorAll('.el-scrollbar__view').length === 3) {
                this.liHeight = document.querySelectorAll('.el-scrollbar__view')[2].clientHeight
                this.$bus.emit('liH', this.liHeight)
            }
        },
        changTypeA(type) {
            if (type === 1) {
                if (!this.shouOrOPenA) {
                    document.querySelectorAll('.canOPenA').forEach(item => {
                        item.style = 'display:none;'
                    })
                    this.shouOrOPenA = true
                }
            } else {
                if (this.shouOrOPenA) {
                    document.querySelectorAll('.canOPenA').forEach(item => {
                        item.style = 'display:flex;'
                    })
                    this.shouOrOPenA = false
                }
            }
            if (document.querySelectorAll('.el-scrollbar__view').length === 3) {
                this.liHeight = document.querySelectorAll('.el-scrollbar__view')[2].clientHeight
                this.$bus.emit('liH', this.liHeight)
            }
        },
        hasWitch(item) {
            if (item.length === 1) {
                return '98%'
            } else if (item.length > 1) {
                return Math.floor(98 / item.length) + '%'
            }
        },
        leftIndex(item, index) {
            if (item.length === 1) {
                return '1%'
            } else if (item.length > 1) {
                return (100 / item.length * index) + 0.5 + '%'
            }
        },
        weekWidth(item) {
            if (item.length === 1) {
                return '98%'
            } else if (item.length > 1) {
                return (98 / item.length) + '%'
            }
        },
        forMarTime(val) {
            const value = val.split(':')
            return value[0] + ':' + value[1]
        },
        addData(item, index, type) {
            if (type === 'week') {
                const currentStart = this.weekArray[index].dataValue + ' ' + item.title
                const currentEnd = this.weekArray[index].dataValue + ' ' + (Number(item.timeDate) < 9 ? ('0' + ((item.title.split(':')[0] - 0) + 1) + ':00') : (item.title.split(':')[0] - 0) + 1 + ':00')
                item.currentStart = currentStart
                item.currentEnd = currentEnd
            }
            this.$bus.emit('editForm', item, 'add', false)
        },
        getMonthDetail(vo) {
            let isEnd = ''
            if (new Date(vo.endDate + ' ' + vo.endTime).getTime() < new Date().getTime()) {
                isEnd = true
            } else {
                isEnd = false
            }
            this.$bus.emit('editForm', vo, 'edit', isEnd)
        },
        getDetail(vo, isEnd) {
            this.$bus.emit('editForm', vo, 'edit', isEnd)
        },
        getWindowInfo() {
            if (document.body.clientWidth > 1600) {
                this.bigScreen = true
            } else {
                this.bigScreen = false
            }
        },
        forMat(time) {
            const week = ['日', '一', '二', '三', '四', '五', '六']
            const date = new Date(time)
            const weekDate = '周' + week[date.getDay()]
            const dateTime = (date.getMonth() + 1) + '月' + date.getDate() + '号'
            return {
                weekDate,
                dateTime
            }
        },
        // 获取当前时间结构
        nowLine() {
            var d = new Date()
            var hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
            var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
            var startHeight = (minutes - 0) + (30 - 0)
            var time = hours + ':' + minutes
            return {
                hours,
                minutes,
                time,
                startHeight
            }
        }
    }
}
</script>
<style lang="scss">
 .pro-detail{
   border-radius: 8px !important;
   .proTime{
    .pro-title{
      height: 40px;
      overflow: hidden;
      font-size: 12px;
      line-height: 40px;
      position: relative;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-bottom: 1px solid #EBEBEB;
    }
   }
 }
 .proWeek-detail{
   border-radius: 8px !important;
   .proTime{
    .pro-title{
      height: 40px;
      overflow: hidden;
      font-size: 12px;
      line-height: 40px;
      position: relative;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-bottom: 1px solid #EBEBEB;
    }
   }
 }
 .proTime-detail{
   border-radius: 8px !important;
   .proTime{
    .pro-title{
      height: 40px;
      overflow: hidden;
      font-size: 12px;
      line-height: 40px;
      position: relative;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-bottom: 1px solid #EBEBEB;
    }
   }
 }
 .proMonth-detail-pop{
  border-radius: 8px !important;
  height: unset !important;
   .proTime{
    .pro-title{
      height: 40px;
      overflow: hidden;
      font-size: 12px;
      line-height: 40px;
      position: relative;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-bottom: 1px solid #EBEBEB;
    }
   }
 }
</style>
<style scope lang="scss">
.proBox{
    position: fixed;
    width: 220px;
    height: 152px;
    background: #fff;
    padding: 10px 16px;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    color: #333;
    font-size: 12px;
    z-index: 9999;
    line-height: 2;
    box-shadow: 5px 5px 5px #909399;
    &::before{
      content:'';
      display: inline-block;
      position: absolute;
    }
    .detail-list{
      .tip-title{
          width: 200px;
          font-size: 16px;
          font-weight: 700;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
      }
    }
}
.box-time{
    height: 60px;
    display: flex;
   .content-time{
     height: 100%;
     width: 60px;
     font-size:14px;
     color: #929292;
     position: relative;
     .content-title{
      position: absolute;
      top: -8px;
      right: 10px;
     }
     .active{
      color: red;
      position: absolute;
      &::after{
        content: '';
        width: 5px;
        height: 5px;
        position: absolute;
        background: red;
        border-radius: 50%;
        left: 50%;
        bottom: -8px;
        transform: translateX(-50%);
      }
     }
   }
   .now-time{
      position: absolute;
      z-index: 66;
      width: 44px;
      height: 20px;
      font-size: 12px;
      text-align: center;
      color: white;
      line-height: 20px;
      background: var(--bgColor);
      border-radius: 4px;
      left: 3px;
   }
   .content-info{
     height: 100%;
     width: calc(100% - 60px);
     cursor: pointer;
     position: relative;
     border-bottom: 1px solid #EBEBEB;
     border-left: 1px solid #EBEBEB;
     .info-box{
       color: var(--bgColor);
       font-size:12px;
       border-radius:4px;
       background: #eff2f6;
       position: absolute;
       border-left: 3px solid var(--bgColor);
       cursor:pointer;
       z-index: 10 ;
       display: flex;
       overflow: hidden;
       justify-content: space-between;
       align-items: center;
       padding: 0 7px;
       .info-title{
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        white-space: nowrap;
        display: inline-block;
     }
     }
      .info-box-end{
       color: #bbbdc2;
       font-size:12px;
       border-radius: 5px;
       position: absolute;
       background: #F6F6F5;
       text-decoration:line-through;
       border: 0.5px solid rgba(228, 230, 236, 0.8);
       cursor:pointer;
       z-index: 10 ;
       display: flex;
       overflow: hidden;
       justify-content: space-between;
       align-items: center;
       padding: 0 7px;
       .info-title{
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        white-space: nowrap;
        display: inline-block;
      }
      .info-end{
         position: absolute;
          width: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 14px;
          background: #F9F9F9;
          right: 0px;
          top: 0px;
          border-bottom: 0.5px solid rgba(228, 230, 236, 0.8);
          border-left: 0.5px solid rgba(228, 230, 236, 0.8);
          border-radius: 0 0 0 5px;
      }
     }
   }
  }
  .week-time{
    height: 60px;
    display: flex;
   .week-content{
     height: 100%;
     width: 60px;
     font-size:14px;
     color: #929292;
     position: relative;
     .content-title{
      position: absolute;
      top: -8px;
      right: 10px;
     }
     .active{
      color: red;
      position: absolute;
      &::after{
        content: '';
        width: 5px;
        height: 5px;
        position: absolute;
        background: red;
        border-radius: 50%;
        left: 50%;
        bottom: -8px;
        transform: translateX(-50%);
      }
     }
   }
   .now-time{
      position: absolute;
      z-index: 66;
      width: 44px;
      height: 20px;
      font-size: 12px;
      text-align: center;
      color: white;
      line-height: 20px;
      background: var(--bgColor);
      border-radius: 4px;
      left: 3px;
   }
   .content-info{
     height: 100%;
     width: calc(100% - 60px);
     cursor: pointer;
     position: relative;
     border-bottom: 1px solid #EBEBEB;
     border-left: 1px solid #EBEBEB;
     display: flex;
     .info-item{
         width: 14.28%;
         border-right: 1px solid #EBEBEB;
         height: 100%;
        // justify-content: space-around;
         display: flex;

     }
     .info-item>span{
      width:100%
     }
     .info-box{
       color: var(--bgColor);
       font-size:12px;
       border-radius:4px;
       background: #eff2f6;
       border-left: 3px solid var(--bgColor);
       cursor:pointer;
       z-index: 10 ;
       display: flex;
     //  overflow: hidden;
       flex-direction: column;
       justify-content: center;
       padding: 0 7px;
       .info-title{
        font-weight: bold;
        margin-top: 5px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
     }
     }
      .info-box-end{
       color: #bbbdc2;
       font-size:12px;
       border-radius: 5px;
       background: #F6F6F5;
       text-decoration:line-through;
       border: 0.5px solid rgba(228, 230, 236, 0.8);
       cursor:pointer;
       z-index: 10 ;
       display: flex;
       overflow: hidden;
       flex-direction: column;
       justify-content: center;
       padding: 0 7px;
       .info-title{
        font-weight: bold;
        margin-top: 5px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
      }
      .info-end{
         position: absolute;
          width: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 14px;
          background: #F9F9F9;
          right: 0px;
          top: 0px;
          border-bottom: 0.5px solid rgba(228, 230, 236, 0.8);
          border-left: 0.5px solid rgba(228, 230, 236, 0.8);
          border-radius: 0 0 0 5px;
      }
     }
   }
  }
 .item-third{
       width: 98px;
       display: flex;
       font-size: 12px;
       text-align: center;
       position: relative;
       height: 29px;
       line-height: 30px;
       color: #F76400;
       background: rgba(255, 191, 146, 0.22);
       border-radius: 2px;
       margin: 0 auto;
       margin-top: 5px;
       &:last-child{
           margin-top: 6px;
       }
       &::before{
         display: block;
         content: '';
         position: absolute;
         top: -1px;
         height: 1px;
         width: 99px;
         background: rgba(247, 100, 0, 0.6);
     }
     }
     .four-pro{
       .pro-title{
         height: 40px;
         line-height: 40px;
         font-size: 18px;
         font-weight: bold;
       }
       .info-title{
         height: 25px;
         font-size: 14px;
         display: flex;
         align-items: center;
         .span-title{
           margin-left: 10px;
           overflow: hidden;
           white-space: nowrap;
           text-overflow: ellipsis;
           width: 80px;
         }
          .span-time{
            margin-left: 10px;
          }
          .span-color{
              width: 6px;
              height: 6px;
              display: inline-block;
              background:  var(--bgColor);
              transform: rotate(45deg);
          }
       }
     }
     .four-item{
       width: 100%;
       background:  var(--bgColor);
       display: flex;
       align-items: center;
       margin-top: 1px;
       .four-span{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        font-size: 14px;
        color: white;
        margin-left: 8px;
      }
     }
     .four-itemA{
       width: 28px;
       height: 28px;
       border-radius: 50%;
       background:  var(--bgColor);
       display: flex;
       align-items: center;
       margin-top: 1px;
       .four-span{
        font-size: 14px;
        color: white;
        margin-left: 10px;
      }
     }
</style>
