<template>
  <div>
    <div
      style="padding: 10px;     font-size: 14px;   display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;"
    >
      <div class="dj">
        登记日期：
        <el-date-picker
          v-model="value"
          class="riqi"
          type="month"
          placeholder="选择月"
          format="yyyy年M月"
          value-format="yyyy-M"
        />

      </div>
      <statistics-search :default-display="false" :search-type="searchType" @statisticsClick="statistics" />

    </div>
    <!-- <div style="    padding: 5px 15px;
    text-align: right;"
    >
      <el-button @click="todao">
        <svg
          aria-hidden="true"
          class="svg-icon"
          style="height: 13px; width: 13px"
          v-on="$listeners"
        >
          <use :xlink:href="'#icon-export'" style="fill:#EC8423 " />
        </svg>导出</el-button>
    </div> -->

    <div class="table-box">

      <el-table
        class="custom-table"
        style="width:100%"
        border
        :data="dataList2"
        @row-click="rowClick"
      >
        <el-table-column
          label="序号"
          width="60"
          type="index"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.index }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" align="center" width="80">
          <template slot-scope="scope">
            <div>{{ scope.row.staffName }}</div>

          </template>
        </el-table-column>
        <el-table-column align="center" label="科室">
          <template slot-scope="scope">
            <div>{{ scope.row.staffDept }}</div>

          </template>
        </el-table-column>
        <!-- <el-table-column label="考勤员">
          <template slot-scope="scope">
            <div>{{ scope.row.kqPerson }}</div>

          </template>
        </el-table-column><el-table-column label="科室负责人">
          <template slot-scope="scope">
            <div>{{ scope.row.ksPerson }}</div>

          </template>
        </el-table-column> -->

        <el-table-column
          label="迟到（次）"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.cdNum }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="早退（次）"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.ztNum }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="旷工（天）"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.kgNum }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(option, optionkey) in dataList.top"
          :key="optionkey"
          align="center"
          header-align="center"
          :label="option+'（天）'"
          width="100"
        >
          <template slot-scope="scope">
            <div>

              {{ dataList2[scope.$index].passenger.list.find(item => item.typeName === option)?.day || 0 }}
            </div>

          </template>
        </el-table-column>
        <el-table-column
          label="出勤（天）"
          width="100"
          align="center"
        >

          <template slot-scope="scope">
            <div>{{ scope.row.cqNum&lt;0?0:scope.row.cqNum }}</div>
          </template>
        </el-table-column>

        <!-- <el-table-column
          label="备注"
          width="100"
        >

          <template slot-scope="scope">
            <div>{{ scope.row.remark }}</div>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <el-dialog
      title="科室考勤统计"
      :visible.sync="dialogVisible"
      width="1450px"
    >
      <div>
        <grkqtj />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import StatisticsSearch from '@/wapp/IndustryVersionCode/kqgl/kqhz/common/StatisticsSearch'
import grkqtj from './components/grkqtj'
export default {
    components: {
        StatisticsSearch, grkqtj
    },
    props: {

    },
    data() {
        return {
            dialogVisible: false,
            dataList: [],
            value: '',
            time: '',
            selectDept: '',
            selectName: '',
            content: '',
            searchType: [
                {
                    type: 'subOrg',
                    title: '登记科室'
                }
            ],
            dataList2: [],
            dataList1: {
                list: [
                    {
                        'affairTimes': 0.0,
                        'annualTimes': 0.0,
                        'bereavementTimes': 0.0,
                        'cdNum': 0,
                        'cqNum': null,
                        'gcNum': 0.0,
                        'hzDate': '2023-7',
                        'id': '16942041554e4dcbbc90f0844ff088ac',
                        'illTimes': 0.0,
                        'jxNum': 0.0,
                        'kgNum': 0.0,
                        'kqNum': 34,
                        'kqPerson': '黄珍',
                        'kqPersonId': '41b9c66980a440f8957f4626baee6463',
                        'ksPerson': null,
                        'ksPersonId': null,
                        'marryTimes': 0.0,
                        'maternityTimes': 0.0,
                        'orderNo': null,
                        'orgCode': 'nfyk',
                        'orgName': null,
                        'parentId': null,
                        'passenger': {
                            'list': [
                                {
                                    'day': 1.0,
                                    'id': '4d6a182ed71c4ac0af5164e4411a4a56',
                                    'parentId': '16942041554e4dcbbc90f0844ff088ac',
                                    'passenger': {

                                    },
                                    'typeId': '16942041554e4dcbbc90f0844ff088aj',
                                    'typeName': '婚假'
                                }
                            ]
                        },
                        'realityNum': 34,
                        'registrationDate': null,
                        'remark': null,
                        'staffDept': '运营部',
                        'staffDeptId': '95238ae52b08459c85b5ce2c4bce435b',
                        'staffId': '41b9c66980a440f8957f4626baee6463',
                        'staffName': '黄珍',
                        'visitTimes': 0.0,
                        'ztNum': 0
                    },
                    {
                        'affairTimes': 0.0,
                        'annualTimes': 0.0,
                        'bereavementTimes': 0.0,
                        'cdNum': 0,
                        'cqNum': null,
                        'gcNum': 0.0,
                        'hzDate': '2023-7',
                        'id': '25d06ecb48244e04b03b3032841bb7da',
                        'illTimes': 0.0,
                        'jxNum': 0.0,
                        'kgNum': 0.0,
                        'kqNum': 34,
                        'kqPerson': '黄丽莲',
                        'kqPersonId': 'de2d4228073f4765bb6e5656df656ff1',
                        'ksPerson': null,
                        'ksPersonId': null,
                        'marryTimes': 0.0,
                        'maternityTimes': 0.0,
                        'orderNo': null,
                        'orgCode': 'nfyk',
                        'orgName': null,
                        'parentId': null,
                        'passenger': {

                            'list': [
                                {
                                    'day': 3.0,
                                    'id': '4d6a182ed71c4ac0af5164e4411a4a52',
                                    'parentId': '60f08cea579044daad3cd1d597717a85',
                                    'passenger': {

                                    },
                                    'typeId': '4d6a182ed71c4ac0af5164e4411a4a53',
                                    'typeName': '产假'
                                },
                                {
                                    'day': 2.0,
                                    'id': '4d6a182ed71c4ac0af5164e4411a4a53',
                                    'parentId': '60f08cea579044daad3cd1d597717a85',
                                    'passenger': {

                                    },
                                    'typeId': '60f08cea579044daad3cd1d597717a81',
                                    'typeName': '病假'
                                },
                                {
                                    'day': 4.0,
                                    'id': '4d6a182ed7222164e4411a4a53',
                                    'parentId': '60f0scea579044daad3cd1d597717a85',
                                    'passenger': {

                                    },
                                    'typeId': '60f08cea579044daad3cd1d597717a81',
                                    'typeName': '婚假'
                                }
                            ]
                        },
                        'realityNum': 34,
                        'registrationDate': null,
                        'remark': null,
                        'staffDept': '运营部',
                        'staffDeptId': '95238ae52b08459c85b5ce2c4bce435b',
                        'staffId': 'de2d4228073f4765bb6e5656df656ff1',
                        'staffName': '黄丽莲',
                        'visitTimes': 0.0,
                        'ztNum': 0
                    }
                ],
                top: ['婚假', '婚假', '产假', '婚假', '病假']
            }

        }
    },
    computed: {

    },
    watch: {
        value(val) {
            console.log(val)
            const time = val.split('-')
            const y = time[0]
            const m = time[1]
            this.getDays(y, m)
        },
        cqNum(val) {
            console.log(val)
        }
    },
    created() {
        this.$bus.emit('showNavSearch', false)
        const date = new Date()
        this.value = date.getFullYear() + '-' + (date.getMonth() + 1)
        const time = this.value.split('-')
        const y = time[0]
        const m = time[1]
        this.getDays(y, m)
        this.selectDept = this.$store.getters.loginUser.staffList[0].orgFullId.split('-')[1]
        // this.getdata()
        // this.getshuju()
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true)
    },
    mounted() {

    },
    methods: {
        rowClick(row) {
            console.log('row', row)
            this.dialogVisible = true
            this.$nextTick(() => {
                this.$bus.emit('setGetData', row)
            })
        },
        todao() {
            // 导出的表头
            const title = [
    	          { title: '名字', align: 'center', colspan: 1, rowspan: 1 }

            ]
            for (let i = 1; i <= this.time1; i++) {
                var obj = { align: 'center', colspan: 1, rowspan: 1 }
                obj.title = i
                title.push(obj)
            }
            console.log(title, 'title')
            const tbtitle = []
            tbtitle.push(title)
            console.log(tbtitle, 'tbtitle')
            const tbdata = []
            // 数据处理
            this.dateList.forEach((list, listIndex) => {
                console.log(list, listIndex, ' this.dateList')
                list.data.forEach((day) => {
                    console.log(day, 'day')
                })
      	// 状态处理
                // 	if (list.assetStatus === '1') {
                // 		list.assetStatus = '闲置'
                // 	} else if (list.assetStatus === '2') {
                //  		list.assetStatus = '在用'
                //  	} else if (list.assetStatus === '3') {
                // 		list.assetStatus = '借用'
                //   	}
     	// var timefarmat = []
     	// 时间处理
     	// if (list.checkTime != null) {
     	// 	timefarmat = list.checkTime.split(' ')
                //   	list.checkTime = timefarmat[0]
     	// }
     	// 导出的值
     	const row = [
     		{ value: day.staffName, align: 'center', colspan: 1, rowspan: 1 },
        	{ value: list.assetName, align: 'center', colspan: 1, rowspan: 1 },
        	{ value: list.assetStatus, align: 'center', colspan: 1, rowspan: 1 },
        	{ value: list.checkTime, align: 'center', colspan: 1, rowspan: 1 }
                ]
                tbdata.push(row)
            })

            // 发起请求---固定写法
            this.$http({
     	method: 'get',
     	headers: {
     	'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
     	data: qs.stringify({
     		rows: JSON.stringify(tbdata),
     		titles: JSON.stringify(tbtitle),
     		fileName: '资产盘点一览表'
     	}),
     	url: '/yykq/rypbkqdj/exportdata?httpPort=' + location.port
            }).then((res) => {
     	if (res.code === 'success') {
     		// 写法一
        	// window.open(encodeURI('/apigw/' + res.body.xlsurl), '_blank')

        	// 写法二（推荐）
        	 var href = '/apigw/' + res.body.xlsurl
                    var tempLink = document.createElement('a')// 创建一个a标签
                    tempLink.style.display = 'none'
                    tempLink.href = encodeURI(href)
                    tempLink.setAttribute('download', '我的请休假.xls')// 给a标签添加下载属性
                    if (typeof tempLink.download === 'undefined') {
                        tempLink.setAttribute('target', '_blank')
                    }
                    document.body.appendChild(tempLink)// 将a标签添加到body当中
                    console.log('tempLink==', tempLink)
                    tempLink.click()// 启动下载
                    document.body.removeChild(tempLink)// 下载完毕删除a标签
                    href = ''
                } else {
        	this.$alert(res.message, '操作提示', { type: 'error' })
                }
            })
        },
        getDayValue(index, option) {
            const item = this.dataList2[index].passenger.list.find(item => item.typeName === option)
            return item ? item.day.toString() : '0' // 将day字段的值转为字符串
        },
        setDayValue(index, option, value, scope) {
            console.log(scope, 231)
            const item = this.dataList2[index].passenger.list.find(item => item.typeName === option)
            if (item) {
                item.day = value ? parseInt(value) : 0// 将输入的值转为整数
            }
        },
        btnFn(scope) {
            console.log(scope, 'scope')
        },
        statistics(vals) {
            this.dateList = []
            console.log('valssss', vals)
            this.selectDept = vals.subOrg.id
            // this.selectName = vals.orgStaff.name
            console.log('this.selectDept', this.selectDept)
            this.$bus.emit('refreshListData')
            this.getshuju(this.time)
        },
        getshuju() {
            const data = {
                pbNy: this.value,
                hzDate: this.value,
                staffDeptId: this.selectDept,
                staffName: this.selectName
            }
            this.$http({
                method: 'POST',
                url: '/yykq/kqhz/AttendanceSummary',
                contentType: 'application/x-www-form-urlencoded',
                params: data
            }).then(res => {
                if (res.code === 'success') {
                    console.log('res', res)
                    this.dataList = res.body.list
                    this.dataList2 = this.dataList.list
                }
                this.dataList2.forEach((item, index) => {
                    item.index = index + 1
                })
            })
        },
        tosave() {
            const date = {
                date: this.dataList
            }
            this.$http({
                method: 'PUT',
                contentType: 'application/x-www-form-urlencoded',
                data: date,
                url: '/yykq/kqhz/save'
            }).then(res => {
                if (res.code === 'success') {
                    this.datelist = res.body.listdata
                    this.$message.success('保存成功')
                    this.$bus.emit('refreshListData')
                }
            })
        },
        daoru() {
            this.$bus.emit('openImportFamilyForm')
        },
        daochu() {

        },
        getWeekends(year, month) {
            const weekends = []
            const date = new Date(year, month - 1, 1)
            while (date.getMonth() === month - 1) {
                if (date.getDay() === 0 || date.getDay() === 6) {
                    weekends.push(date.getDate())
                }
                date.setDate(date.getDate() + 1)
            }

            return weekends.join(',')
        },
        getDays(year, month) {
            const days = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
            if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
                days[2] = 29
            }

            console.log(days[month])
            this.time = days[month]
        }

    }
}
</script>

<style scoped lang="scss">
.dj{
  padding: 10px !important;
}
.right {
  margin-right: 10px;
  margin-bottom: 10px;
  float: right;
  display: flex;
  justify-content: flex-end;
}
/deep/.el-table th.el-table__cell.is-leaf {
    border: 1px solid #EBEEF5 !important;
}
.table-box {
  width: 100%;
  padding: 0 10px;
}
/deep/.el-table__empty-block{
   border: 1px solid #EBEEF5 !important;
}
.el-table {
  overflow: hidden;
}
/deep/.el-table--scrollable-x .el-table__body-wrapper{
  overflow-x: auto !important;
}
/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: rgba(248, 247, 247, 0.87) !important;
}

/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
  margin: 0 2px !important;
  border-radius: 6px !important;
  background-color: rgba(199, 199, 199, 0.65) !important;
}

/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: hsla(240, 1%, 35%, 0.418)  !important;
}
</style>
