<template>
  <div id="roomLayout">
    <template v-if="insideLayout">
      <inside-layout :data="insideLayoutData" :layout-item="layoutItem" />
    </template>
    <LayoutTitle v-else :title="layoutItem.title" />
    <DoorLayout :layout-item="layoutItem" />
    <LayoutHiddenHandler :layout-item="layoutItem" />
  </div>
</template>

<script>
import LayoutTitle from '../components/LayoutTitle'
import LayoutHiddenHandler from '../components/LayoutHiddenHandler'
import DoorLayout from './doorLayout'
export default {
    components: { LayoutTitle, LayoutHiddenHandler, DoorLayout },
    props: {
        layoutItem: { type: Object, require: true, default: () => {} },
        selected: { type: Object, require: true, default: () => {} },
        insideLayout: { type: Object, default: () => {} } // { key: 'roomLayout', component: '@/wapp/scgl/warehousesPlan/areaComponent' }
    },
    computed: {
        insideLayoutData() {
            return this.insideLayout && this.insideLayout.data ? this.insideLayout.data.filter(i => i.materialId === this.layoutItem.areaId) : []
        }
    },
    created() {
        if (this.insideLayout && this.insideLayout.component) {
            this.$options.components['InsideLayout'] = this.$loadComponent(this.insideLayout.component)
        }
    }
}
</script>

<style lang="scss" scoped>
#roomLayout{
    position: relative;
    height: 100%;
    width: 100%;
}
</style>
