<template>
  <div>
    <el-link v-if="column.title=='系统操作'" type="primary" @click="gotoUserSystem">进入系统<i class="el-icon-right el-icon--right" /> </el-link>
    <el-link v-else-if="column.title=='模块设置'" type="primary" @click="gotoModule"><i class="el-icon-setting" />模块设置</el-link>
    <el-link v-else-if="column.title=='模板设置'" type="primary" @click="gotoProductSetting"><i class="el-icon-setting" />模板设置</el-link>
    <el-link v-else type="primary" @click="gotoSystemSetting"><i class="el-icon-setting" />系统设置</el-link>
  </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        column: { type: Object, default: null },
        row: { type: Object, default: null },
        index: { type: Number, default: null }
    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
        gotoModule() {
            window.open('/#/designer/udmGenerator?id=' + this.row.id)
            console.log(this.column, 'column')
        },
        gotoProductSetting() {
            window.open('/#/designer/productSetting?id=' + this.row.id)
        },
        gotoSystemSetting() {
            window.open('/#/designer/systemSetting?id=' + this.row.id)
            console.log(this.column, 'column')
        },
        gotoUserSystem() {
            this.$http({
                method: 'GET',
                url: 'base/sysSetup/switchSysLogin',
                params: { 'sysId': this.row.id, 'productCode': this.row.productName },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                if (response.code === 'success') {
                    this.$store.dispatch('user/tokenSsoLogin', response.body)
                        .then(async(res) => {
                            if (this.row.sysUrl === '#') {
                                location = '/'
                            } else {
                                location = this.row.sysUrl
                            }
                        })
                        .catch(error => {
                            this.$message({ message: error.msg, type: 'error' })
                        })
                } else {
                    this.$message({ message: response.message, type: 'error' })
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.el-link{
    margin-left: 5px;
}
.el-icon-setting{
    margin-right: 5px;
}
</style>
