<template>
  <div>
    <el-menu
      :default-active="defaultData"
      class="el-menu-vertical-demo"
      :unique-opened="true"
      text-color="#333333"
      background-color="#f3f3f3"
      active-text-color="#1a82e3"
      @select="selectItem"
    >
      <helpItem :list-data="typeData"></helpItem>
    </el-menu>
  </div>
</template>
<script>
import helpItem from './helpItem.vue'
export default {
    components: {
        helpItem
    },
    props: {
        typeData: {
            type: Array,
            default: function() { return [] }
        },
        defaultData: { type: String, default: '' }
    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    created() {
    },
    beforeDestroy() {

    },
    mounted() {
        this.defaultSelect(this.defaultData)
    },
    methods: {
        selectItem(key, keyPath) {
            this.$emit('clickItem', key)
        },
        defaultSelect(val) {
            this.$emit('clickItem', val)
        }
    }
}
</script>

<style scoped lang="scss">
.el-menu-vertical-demo{
    border-right: none !important;
}
</style>
