<template>
  <div class="AreaLayout">
    <grid-layout id="AreaLayoutContent" ref="gridlayout" :vertical-compact="true" :is-draggable="isDraggable" :is-resizable="isResizable" :layout.sync="layout" :col-num="col" :row-height="height" :margin="[1, 1]">
      <grid-item
v-for="(item, idx) in layout" :key="item.i" :class="{
          'vue-grid-item__selected': selected && selected.areaId === item.areaId,
          'vue-grid-item__hasHiddenWall': item.config.hidden && item.config.hidden.length
        }" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i"
>
        <component :is="item.name + 'Layout'" v-if="$options.components[item.name + 'Layout']" :layout-item.sync="layout[idx]" :inside-layout="getInsideLayout(item.name + 'Layout')" :selected="selected" />
      </grid-item>
    </grid-layout>
    <Basicform v-if="moduleInfo" ref="basicForm" :module-code="moduleInfo.moduleCode" :title="moduleInfo.title" :win-width="1300" :win-height="600" :toolbar-btns="toolbarBtns" :on-basic-form-before-open="onBasicFormBeforeOpen" :on-basic-form-opened="onBasicFormOpened" :on-basic-form-before-submit="onBasicFormBeforeSubmit" :on-basic-form-submited="onBasicFormSubmited">
      <template #default="slotProps">
        <BusiForm ref="busiForm" :vo="slotProps.vo" :can-edit="slotProps.canEdit" :module-info="moduleInfo" />
      </template>
    </Basicform>
  </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout'
import { Elements } from '../AreaConfig'

const GridLayout = VueGridLayout.GridLayout
const GridItem = VueGridLayout.GridItem
export default {
    name: 'AreaLayout',
    components: { GridLayout, GridItem },
    props: {
        parentId: { type: String, default: '' },
        col: { type: [String, Number], default: 24 },
        height: { type: [String, Number], default: 30 },
        layout: { type: Array, default: () => [], require: true },
        insideLayout: { type: Array, default: () => [] }, // [{ key: 'roomLayout', component: '@/wapp/scgl/warehousesPlan/areaComponent' }]
        selected: { type: Object, default: () => { } },
        isInlineLayout: { type: Boolean, default: false },
        isDraggable: { type: Boolean, default: true },
        isResizable: { type: Boolean, default: true }
    },
    data() {
        return {
            moduleInfo: null,
            toolbarBtns: []
        }
    },
    created() {
    // 初始化挂在可渲染layout组件
        this.initLayoutComponents()
        this.$bus.on('openLayoutBusiform', this.openLayoutBusiform)
    },
    beforeDestroy() {
        this.$bus.off('openLayoutBusiform', this.openLayoutBusiform)
    },
    methods: {
        initLayoutComponents() {
            Elements.forEach(El => {
                if (!El.show) return
                const componentName = El.name
                this.$options.components[componentName + 'Layout'] = this.$loadComponent(`@/wapp/areaEditor/layouts/${componentName + 'Layout'}`)
            })
        },
        getInsideLayout(name) {
            return this.insideLayout ? this.insideLayout.find(item => item.key === name) : null
        },
        openLayoutBusiform(moduleInfo, canEdit, data = null, buttons = []) {
            this.moduleInfo = moduleInfo
            this.$options.components['BusiForm'] = this.$loadComponent(this.moduleInfo.template)
            this.toolbarBtns = buttons.map(btn => {
                btn.btnClick = this[btn.btnClick]
                return btn
            })
            this.$nextTick(() => {
                this.$bus.emit('openBusiform', this.moduleInfo, data, canEdit)
            })
        },
        onBasicFormSubmited() {
            this.$bus.emit('refreshCurrentArea')
        },
        closeDialog() {
            this.moduleInfo = null
            this.toolbarBtns = []
        }
    }
}
</script>

<style lang="scss" scoped>
.AreaLayout {
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-sizing: border-box;
    user-select: none;
    .vue-grid-layout {
        width: 100%;
        min-height: 500px;
    }
    .vue-grid-item:not(.vue-grid-placeholder) {
        outline: 1px solid #333333;
    }
    .vue-grid-item__selected {
        outline: 1px solid #3d92e0 !important;
        z-index: 100;
    }
    .vue-grid-item__hasHiddenWall {
        z-index: 1;
    }
    .vue-grid-item .resizing {
        opacity: 0.9;
    }

    .vue-grid-item .static {
        background: #cce;
    }

    .vue-grid-item .text {
        font-size: 24px;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 100%;
        width: 100%;
    }

    .vue-grid-item .no-drag {
        height: 100%;
        width: 100%;
    }

    .vue-grid-item .minMax {
        font-size: 12px;
    }
}
</style>
