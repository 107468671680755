<template>
  <WflowDesigner ref="wfDesigner" />
</template>

<script>
export default {

    data() {
        return {

        }
    }
}
</script>
