export default {
    async getDefaultData($scope, id) {
        let interfacePseudoData = []
        await $scope.$http({
            url: 'base/portlet/getById?product=1',
            method: 'get',
            params: {
                id
            }
        }).then(res => {
            if (res.code === 'success') {
                let jsonString = res.body.vo.interfacePseudoData
                const cleanedJsonString = jsonString.trim().replace(/\s+/g, '')
                interfacePseudoData = JSON.parse(cleanedJsonString)
            }
        })
        return interfacePseudoData
    }
}
