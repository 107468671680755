<template>
  <div class="app-container">
    <div class="top-line">
      <statistics-search :search-type="searchType" @statisticsClick="statistics" />
    </div>
    <el-row :gutter="20">
      <template v-for="tb in tbArr">
        <el-col :key="tb.id" class="table-item" :span="8">
          <rank-table :args="tb" />
        </el-col>
      </template>
    </el-row>
    <rank-detail />
    <rank-personage />
  </div>
</template>
<script>
const moment = require('moment')

import RankTable from './RankTable'
import RankDetail from './RankDetail'
import RankPersonage from './RankPersonage'
import StatisticsSearch from '@/wapp/common/components/StatisticsSearch'

export default {
    name: '',
    components: { RankTable, RankDetail, RankPersonage, StatisticsSearch },
    data() {
        return {
            chooseDate: [],
            tbArr: [
                { id: 1, name: '勤奋榜', titMsg: '工作时长', api: 'yykq/homePageIndex/dailyWorkTimeRank', fields: ['name', 'DEPARTMENT', 'workTimes'] },
                { id: 2, name: '迟到榜', titMsg: '共$num人迟到', api: 'yykq/homePageIndex/comeLateRank', fields: ['NAME', 'DEPARTMENT', 'comeLate'] },
                { id: 3, name: '早退榜', titMsg: '共$num人早退', api: 'yykq/homePageIndex/leaveEarlyRank', fields: ['name', 'deptName', 'leaveEarly'] },
                { id: 7, name: '加班榜', titMsg: '共$num人加班', api: 'yykq/homePageIndex/workOverTimeRank', fields: ['name', 'deptName', 'workOverTime'] },
                { id: 9, name: '请假榜', titMsg: '共$num人请假', api: 'yykq/homePageIndex/vacateRank', fields: ['name', 'dept', 'totalNums'] },
                { id: 10, name: '旷工榜', titMsg: '共$num人旷工', api: 'yykq/homePageIndex/absenceRank', fields: ['NAME', 'DEPARTMENT', 'absenceTime'] }
            ],
            searchType: [
                {
                    type: 'range',
                    title: '统计日期',
                    rangeType: 'daterange',
                    rangeSeparator: '至',
                    dateFormat: 'yyyy-MM-dd'
                }
            ]
        }
    },
    computed: {
    },
    watch: {
    },
    created() {
        const time = new Date()
        const year = time.getFullYear()
        const month = time.getMonth() + 1

        if (time.getDate() < 15) {
            const m = month === 1 ? 12 : month - 1
            const y = month === 1 ? year - 1 : year
            const ed = new Date(y, m, 0).getDate()

            this.chooseDate = [moment(new Date(y, m - 1, 1)).format('YYYY-MM-DD'), moment(new Date(y, m - 1, ed)).format('YYYY-MM-DD')]
        } else {
            this.chooseDate = [moment(new Date(year, month - 1, 1)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')]
        }

        this.$bus.emit('showNavSearch', false) // 取消右上搜索按钮
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true) // 还原右上搜索按钮
    },
    mounted() {

    },
    methods: {
        statistics(vals) {
            this.chooseDate = [moment(vals.range[0]).format('YYYY-MM-DD'), moment(vals.range[1]).format('YYYY-MM-DD')]
            this.$nextTick(() => {
                this.$bus.emit('refreshrank')
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.table-item {
    margin-bottom: 20px;
}
.top-line{
    margin-bottom: 10px;
    .label{line-height: 36px;font-size: 14px;width:80px;}
}
/deep/ .el-date-editor .el-range-separator{width: 8%}
	.table thead>tr{
		background: #f8f8f8!important;
	}
	.table tbody>tr:hover{
		background: none!important;
		cursor:pointer;
	}
	.table tbody>tr{
		height: 45px;
	}
  /deep/ .el-dialog .el-dialog__body{
    max-height: 500px;
    overflow: auto;
  }
</style>
