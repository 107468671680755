<template>
  <div>
    <div style="padding: 10px">
      <table
        style="
          border-collapse: collapse;
          border: 1px solid #e2e7ed;
          width: 100%;
        "
      >
        <tr style="height: 45px">
          <td
            style="
              border: 1px solid #e2e7ed;
              text-align: right;
              width: 200px;
              background: #eef0f3;
              padding: 0 10px;
              font-size: 13px;
              font-weight: 700;
            "
          >
            月份
          </td>
          <td style="border: 1px solid #e2e7ed; text-align: left">
            <el-date-picker
              v-model="value1"
              class="riqi"
              type="month"
              placeholder="选择月"
              format="yyyy年M月"
              value-format="yyyy-M"
            >
            </el-date-picker>
          </td>
          <td
            style="
              border: 1px solid #e2e7ed;
              text-align: right;
              width: 200px;
              background: #eef0f3;
              padding: 0 10px;
              font-size: 13px;
              font-weight: 700;
            "
          >
            学校
          </td>
          <td style="border: 1px solid #e2e7ed; text-align: left">
            <el-select v-model="valueSc" placeholder="请选择">
              <el-option
                v-for="item in optionsSc"
                :key="item.id"
                :label="item.schoolName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </td>
        </tr>

        <tr style="height: 45px; text-align: center">
          <td colspan="4" style="border-top: 1px solid #e2e7ede2e7ed">
            <div>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="add()"
              >开始搜索</el-button>
              <el-button icon="el-icon-close" @click="cleanUp">清除搜索</el-button>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="block">
      <!-- <span style="font-size: 12px; margin-left: 10px"
            class="demonstration"
      >月</span>
      <el-date-picker
        v-model="value1"
        class="riqi"
        type="month"
        placeholder="选择月"
        format="yyyy年M月"
        value-format="yyyy-M"
      >
      </el-date-picker> -->

      <div class="right">
        <div
          data-v-fd7a5c9c=""
          class="el-button-group toolbar-button-group el-popover__reference"
          aria-describedby="el-popover-4191"
          tabindex="0"
        >
          <!-- <button
            data-v-fd7a5c9c=""
            type="button"
            class="el-button el-button--default el-button--small is-plain import_btn"
          >
            <span>
              <svg
                data-v-5d08e6e2=""
                data-v-fd7a5c9c=""
                aria-hidden="true"
                class="svg_icon svg-icon"
              >
                <use data-v-5d08e6e2="" xlink:href="#icon-import" />
              </svg>
              <span data-v-fd7a5c9c="">导入</span>
            </span>
          </button> -->
          <button
            data-v-fd7a5c9c=""
            type="button"
            class="el-button el-button--default el-button--small is-plain export_btn"
            @click="exportData"
          >
            <span>
              <svg
                data-v-5d08e6e2=""
                data-v-fd7a5c9c=""
                aria-hidden="true"
                class="svg_icon svg-icon"
              >
                <use data-v-5d08e6e2="" xlink:href="#icon-export" />
              </svg>
              <span data-v-fd7a5c9c="">导出</span></span>
          </button>
        </div>
      </div>
    </div>
    <div class="table-box">

      <el-table :data="dateList" style="width: 100%" border>
        <el-table-column align="center" prop="gradeClass" label="班级" min-width="120px"></el-table-column>
        <el-table-column prop="roomNo" label="房号" width="90" align="center">
        </el-table-column>
        <el-table-column prop="doc" :label="m+'月'" align="center">

          <el-table-column
            v-for="item in time"
            :key="item"
            :label="item"
            width="45"
            :prop="getcurDay(item)"
            align="center"
          >
            <!-- :prop="y+'-'+(m.length = 2 ？m : (0+ m))+'-'+item+' 00:00:00 '" -->
          </el-table-column>
        </el-table-column>

        <el-table-column
          prop="allTotal"
          label="宿舍总扣分"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="countScore"
          width="100"
          align="center"
        >
          <template #header>
            宿舍得分<br>（百分制）
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
const moment = require('moment')
const now = new Date() // 当前日期
const nowDayOfWeek = now.getDay() // 今天本周的第几天
const nowDay = now.getDate() // 当前日
const nowMonth = now.getMonth() // 当前月
const nowYear = now.getFullYear() // 当前年
export default {
    components: {},
    props: {},
    data() {
        return {
            loginUser: this.$store.getters.loginUser,
            valueSc: '全部',
            y: '',
            m: '',
            time: '',
            valueA: '',
            valueB: '',
            value1: '',
            banji: '',
            arrList: [],
            optionsSc: [
                { schoolName: '全部', id: '全部' }
            ],
            dateList: []
            // options1: [
            //     { value: '高一', label: '高一' },
            //     { value: '高二', label: '高二' },
            //     { value: '高三', label: '高三' },
            //     { value: '全部', label: '全部' }
            // ],
            // options2: [{ value: '全部', label: '全部' }]
        }
    },
    computed: {
        getcurDay() {
            return (item) => {
                const month = this.m.length > 1 ? this.m : '0' + this.m
                const day = Number(item) > 9 ? item : '0' + item
                const result = this.y + '-' + month + '-' + day + ' 00:00:00 '
                console.log(item, item.length, result)
                return result
            }
        }
    },
    watch: {
        // value1: {
        //     immediate: true,
        //     handler() {
        //         this.add()
        //     }

        // }
    },
    created() {
        this.getSchoolData()
        this.$bus.emit('showNavSearch', false)
    },
    beforeDestroy() {},
    mounted() {
        const date = new Date()
        this.value1 = date.getFullYear() + '-' + (date.getMonth() + 1)
        this.add()
    },
    methods: {
        cleanUp() {
            const date = new Date()
            this.value1 = date.getFullYear() + '-' + (date.getMonth() + 1)
            this.valueSc = '全部'
        },
        getSchoolData() {
            this.$http({
                method: 'post',
                url: '/bus/school/querydata',
                data: [
                    { 'compare': '=', 'field': 'orgCode', 'merge': false, 'value': this.loginUser.orgCode },
                    { 'compare': '=', 'field': 'status', 'merge': false, 'value': 1 }
                ]
            }).then(res => {
                console.log(res, 'getSchoolData')
                if (res.code === 'success') {
                    res.body.listdata.forEach(item => {
                        this.optionsSc.push(item)
                    })
                }
            })
        },
        exportData() {
            if (this.valueSc !== '全部') {
                window.open(`apigw/bus/buildingRoomScore/exportTopRoomScore?year=${this.y}&schoolId=${this.valueSc}&month=${this.m}&pageNum=1&pageSize=100000&fileName=文明宿舍评比`)
            } else {
                window.open(`apigw/bus/buildingRoomScore/exportTopRoomScore?year=${this.y}&month=${this.m}&pageNum=1&pageSize=100000&fileName=文明宿舍评比`)
            }
        },
        add() {
            console.log(this.value1)
            const time1 = this.value1.split('-')
            const y = time1[0]
            const m = time1[1]
            this.y = y
            this.m = m
            console.log(y, m)
            this.getDays(y, m)
            this.getalldate(y, m)
        },
        searchClick() {
            console.log(123)
        },

        getalldate(y, m) {
            this.$http({
                method: 'post',
                url: '/bus/buildingRoomScore/getTopRoom',
                params: {
                    year: y,
                    month: m,
                    schoolId: this.valueSc
                }
            }).then((res) => {
                console.log(111, res)
                if (res.code === 'success') {
                    this.dateList = res.body.vo
                    this.dateList.forEach((item) => {
                        const countScore = 100 - Number(item.allTotal) < 0 ? 0 : (100 - Number(item.allTotal))
                        this.$set(item, 'countScore', countScore)
                    })
                    this.dateList.sort((a, b) => {
                        return b.countScore - a.countScore
                    })
                    console.log(this.dateList)
                }
            })
        },
        getDays(year, month) {
            const days = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
            if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
                days[2] = 29
            }
            console.log(days[month])
            this.time = days[month]
            console.log(1112, this.time, month)
        }
    }
}
</script>

<style scoped lang="scss">
.right {
  margin-right: 10px;
  margin-bottom: 10px;
  float: right;
  display: flex;
  justify-content: flex-end;
}

div.block {
  margin: 10px 0;
  width: 100%;
  // background-color: red;
}
.el-select {
  margin: 0 5px;
  width: 98%;
}
.riqi {
  margin: 0 5px;
  width: 98%;
}
.table-box {
  width: 100%;
  padding: 0 10px;
}

.el-table {
  overflow: hidden;
}
</style>
