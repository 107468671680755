<template>
  <div class="app-container">
    <div style="margin-bottom: 20px" class="top">
      <div class="top-left">
        <div class="left-week">
          <el-radio-group v-model="radio" size="small" @change="changeTime">
            <el-radio-button label="week">周</el-radio-button>
            <el-radio-button label="day">日</el-radio-button>
          </el-radio-group>
        </div>

      </div>
      <div class="top-right">
        <!-- <span style="margin-Right:10px"> <span style="padding: 0 9px;background: #6ad9a5;border-radius: 50%;" />已预订 </span> -->
        <!-- <span style="margin-Right:10px"> <span style="padding: 0 9px;background: #FEC171;border-radius: 50%;" />申请中 </span> -->
        <!-- <span style="margin-Right:10px"> <span style="padding: 0 9px;background: #E65D6E;border-radius: 50%;" />不通过 </span> -->
        <div class="left-date" style="position:relative">
          <el-button-group>
            <el-button type="text" icon="el-icon-arrow-left" @click="changeTimeDown(radio)" />
            <el-button v-if="radio === 'day'" type="text" class="date-data">{{ listData[0].start }}</el-button>
            <el-button v-if="radio === 'week'" type="text" class="date-data">{{ listData[0].start }} 至 {{ listData[6].end }}</el-button>
            <el-button type="text"><i class="el-icon-arrow-right el-icon--right" @click="changeTimeUp(radio)" /></el-button>
            <el-button v-if="radio === 'day'" style="color:var(--bgColor)" type="text" @click="changeTime(radio)">今天</el-button>
            <el-button v-if="radio === 'week'" style="color:var(--bgColor)" type="text" @click="changeTime(radio)">本周</el-button>
          </el-button-group>
          <div v-if="radio === 'day'" class="suspension">
            <el-date-picker v-model="dayTime" :clearable="false" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" @change="timeChange" />
          </div>
        </div>
        <span style="display: inline-block; font-size: 16px;">场地：</span>
        <el-select v-model="changdi" placeholder="请选择" @change="selectPlace">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.siteName"
            :value="item"
          />
        </el-select>
      </div>
    </div>
    <div v-loading="loading" class="content">
      <div v-if="radio === 'day'" class="table_float_time">
        <span v-if=" hours1 > 0" @click="changeHoures('down')"><i class="el-icon-arrow-left el-icon--left" /> </span>
        <span v-if="hours < 23" @click="changeHoures('up')"><i class="el-icon-arrow-right el-icon--right" style="position:absolute;right:0" /> </span>
      </div>
      <DateCalendar :listdata="listData" :tabledata="tableData" :table-cell-click="tableCellClick" :table-cell-inner-click="tableCellInnerClick">
        <template #inner="scope">
          <div v-if="scope.vo.status===1||scope.vo.status===3||scope.vo.status===2" :style="{'background':backgroundColor[scope.vo.status - 1]}" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color:#fff">
            <span :style="{'color':color[scope.vo.status - 1]}">{{ daystartform(scope.vo.beginTime,scope.vo.endTime,scope.vo.meetRoomId) }}&nbsp;&nbsp;{{ scope.vo.content }}</span>
            <!-- {{ scope.vo.beginTime.substr(11,5) }} - {{ scope.vo.endTime.substr(11,5) }} -->
          </div>
        </template>
        <template #tip="scope">
          <p style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin:5px 0;line-height:20px;font-size: 16px;font-weight: bold;">{{ scope.vo.content }}</p>
          <p style="margin:5px 0;line-height:20px;">预约人：{{ scope.vo.applayName }}</p>
          <p style="margin:5px 0;line-height:20px;">联系方式：{{ scope.vo.phone }}</p>
          <!-- <p style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin:5px 0">服务配置：{{ scope.vo.serviceProperties }}</p> -->
          <p style="margin:5px 0;line-height:20px;">开始时间：{{ scope.vo.beginTime.substr(0,16) }}</p>
          <p style="margin:5px 0;line-height:20px;">结束时间：{{ scope.vo.endTime.substr(0,16) }}</p>
          <el-button v-if="(scope.vo.applyUserid===loginUser.personId&&new Date(scope.vo.beginTime).getTime() > new Date().getTime())" style="color: var(--textColor);backgroundColor:#00a0ff" type="primary" plain size="mini" @click="editApply(scope.vo)">修改预约</el-button>
          <el-button v-if="(scope.vo.applyUserid===loginUser.personId && new Date(scope.vo.beginTime).getTime() > new Date().getTime()) " style="color: var(--textColor);backgroundColor:#00a0ff" type="primary" plain size="mini" @click="cancelApply(scope.vo)">取消预约</el-button>
          <!-- <el-button style="color: var(--textColor);backgroundColor:#00a0ff" type="primary" plain size="mini" @click="editApply(scope.vo)">修改预约</el-button>
          <el-button style="color: var(--textColor);backgroundColor:#00a0ff" type="primary" plain size="mini" @click="cancelApply(scope.vo)">取消预约</el-button> -->
        </template>
        <template #moretip="scope">
          <a @click="tableCellInnerClick(scope.vo)">
            <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-bottom:5px">
              {{ scope.vo.beginTime.substr(11,5) }} - {{ scope.vo.endTime.substr(11,5) }}&nbsp;&nbsp;{{ scope.vo.content }}
            </div>
          </a>
          <!-- <span>{{ scope.vo }}</span> -->
        </template>

      </DateCalendar>
      <el-dialog
        v-if="diaData!==null"
        v-model="diaData"
        title="取消场馆类型"
        :visible.sync="dialogVisible"
        width="30%"
      >
        <template>
          <el-radio-group v-if="diaData.preordainType==='1'" v-model="radioType" style="margin:30px 0">
            <el-radio :label="0">取消当前</el-radio>
            <el-radio :label="1">取消后续</el-radio>
          </el-radio-group>
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose(diaData,'cancel')">取 消</el-button>
            <el-button type="primary" @click="handleClose(diaData,'confirm')">确 定</el-button>
          </span>
        </template>

      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
const moment = require('moment')
import DateCalendar from '@/wapp/common/components/Calendar/cgglIndex'

export default {
    components: {
        DateCalendar
    },
    props: {
    },
    data() {
        return {
            dialogVisible: false,
            diaData: null,
            radioType: 0,
            radio: 'week',
            options: [], // 下拉框配置
            tableData: [], // 表格数据
            listData: [], // 表头配置
            today: '',
            todayFirst: '',
            monday: '',
            mondayFirst: '',
            rooms: [], // 场馆信息
            arr: [],
            newlist: [],
            siteid: '', // 场地信息
            arrangeData: [], // 本周场馆预约数据
            dayarrangeData: [], // 当天场馆预约数据
            isAdmin: false,
            dayTime: '',
            data: [],
            hours: 22,
            hours1: 8,
            meetingStatus: ['取消预订', '已预订', '申请中', '申请通过', '申请不通过'],
            backgroundColor: ['#409EFF', '#f2f2f2', '#409EFF', '#f2f2f2'],
            color: ['#fff', '#333', '#fff', '#fff'],
            daystart: '', // 保存日的当前时间
            huiyishi: [], // 保存场馆信息
            changdi: '', // 保存场地
            loading: false
        }
    },
    computed: {
        ...mapGetters(['loginUser', 'routes'])
    },
    watch: {

    },
    created() {
        // this.siteData()
        this.startFunc()
        // this.searchAllAdmin()
        this.eachDate('week', this.mondayFirst)
        this.siteData1()
        this.$bus.on('refreshtable', this.refreshtable)
    },
    beforeDestroy() {
        this.$bus.off('refreshtable', this.refreshtable)
    },
    mounted() {
    },
    methods: {
        daystartform(stat, end, ids) {
            const star = stat.substr(11, 5)
            const ends = end.substr(11, 5)
            const times = star + '-' + ends
            if (this.radio === 'week') {
                return times
            } else {
                const qqq = this.listData[0].start// 选中的年月日
                const www = stat.substr(0, 10)
                const eee = end.substr(0, 10)
                let rrrk = ''
                let rrrj = ''
                //  const ttt=rrrk+'-'+rrrj
                for (let i = 0; i < this.huiyishi.length; i++) {
                    if (ids === this.huiyishi[i].id) {
                        rrrk = this.huiyishi[i].rbegintime.substr(11, 5)
                        rrrj = this.huiyishi[i].rendtime.substr(11, 5)
                    }
                }
                // debugger
                if (www === eee) {
                    return times
                } if (www < qqq && eee > qqq) {
                    return rrrk + '-' + rrrj
                } if (www === qqq && eee > qqq) {
                    return star + '-' + rrrj
                } if (www < qqq && eee === qqq) {
                    return rrrk + '-' + ends
                }
            }
        },
        refreshtable() {
            if (this.radio === 'week') {
                this.hyydData(this.listData[0].start, this.listData[6].end, this.siteid)
            } else {
                this.hyydData(this.listData[0].start, this.listData[0].start, this.siteid)
            }
        },
        tableCellClick(row, column) {
            let day = null
            let now = null
            if (this.radio === 'week') {
                day = new Date(column.weekday).getTime()
                now = new Date(moment(new Date()).format('YYYY-MM-DD')).getTime()
            } else {
                if (new Date(column.start).getTime() <= new Date(moment(new Date()).format('YYYY-MM-DD')).getTime()) {
                    day = Number(column.showdate.substr(0, 2))
                    now = new Date().getHours()
                }
            }
            if ((this.radio === 'week' && day < now) || (this.radio === 'day' && day <= now && day !== null)) {
                const alert = this.radio === 'week' ? '预约时间不能小于当天！' : '请在当前时间之后预约！'
                this.$alert(alert, '提示', { type: 'warning' })
                return
            }
            const weekDay = new Date(column.weekday).getDay()
            let week = ''
            switch (weekDay) {
                case 0:
                    week = '周日'
                    break
                case 1:
                    week = '周一'
                    break
                case 2:
                    week = '周二'
                    break
                case 3:
                    week = '周三'
                    break
                case 4:
                    week = '周四'
                    break
                case 5:
                    week = '周五'
                    break
                case 6:
                    week = '周六'
                    break
            }
            row.site = this.changdi
            if (row.openday.indexOf(week) === -1) {
                this.$alert('场馆开放周期为‘' + row.openday + '’', '提示', { type: 'warning' })
                return
            }
            // this.$set(row)
            this.$emit('addNewForm', row, column)
        },
        tableCellInnerClick(row) {
            this.$emit('viewObject', row, 'see')
        },
        changeHoures(type) {
            if (type === 'up') {
                this.hours += 1
                this.hours1 += 1
                this.eachDate('day', this.today)
            } else {
                this.hours -= 1
                this.hours1 -= 1
                this.eachDate('day', this.today)
            }
            this.hyydData(this.listData[0].start, this.listData[this.listData.length - 1].end, this.siteid)
        },
        // 查寻所有的场地
        siteData1() {
            this.$http({
                method: 'POST',
                url: '/site/management/listdata',
                contentType: 'application/x-www-form-urlencoded',
                params: {
                    pageNum: 1,
                    pageSize: 999,
                    'orderBy': 'orderNo asc'
                }
            }).then(res => {
                if (res.code === 'success') {
                    this.options = res.body.pageInfo.list
                    if (this.options.length > 0) {
                        this.changdi = this.options[0].siteName
                        this.siteid = this.options[0].id
                        this.hyydData(this.listData[0].start, this.listData[this.listData.length - 1].end, this.siteid)
                    }
                }
            })
        },
        /**
        * 该场地下的场馆信息
        */
        hyydData(startdate, enddate, siteid) {
            return new Promise((resolve, reject) => {
                this.loading = true
                this.$http({
                    method: 'GET',
                    url: '/site/booking/queryBooking',
                    contentType: 'application/x-www-form-urlencoded',
                    params: {
                        'endDate': enddate,
                        'buildingId': siteid,
                        'startDate': startdate
                    }
                }).then(res => {
                    if (res.code === 'success') {
                        const tableData = []
                        this.dayarrangeData = []
                        const dayarrangeData = []
                        const message = res.body.listdata
                        message.forEach(item => {
                            item.rowData = item.weekdays
                            delete item.weekdays
                            tableData.push(item)
                            dayarrangeData.push(item.weekdays)
                        })
                        // this.tableData = tableData
                        this.arrangeData = message
                        this.dayarrangeData = dayarrangeData
                        this.huiyishi = tableData
                        console.log('tableData', tableData)
                        this.HandleMeetingList(tableData)
                        this.loading = false
                        resolve(true)
                    }
                })
            })
        },
        /**
        * 初始时间默认
        */
        startFunc() {
            var date = new Date()
            var weekday = date.getDay()
            weekday = weekday === 0 ? 7 : weekday
            var monday = new Date(1000 * 60 * 60 * 24 * (1 - weekday) + date.getTime())
            this.today = date.getTime()
            this.todayFirst = this.today
            this.monday = monday.getTime()
            this.mondayFirst = this.monday
        },
        // 切换时间
        eachDate(type, start, hours) {
            const listData = []
            this.dayTime = moment(start).format('YYYY-MM-DD')
            if (type === 'day') {
                const z = this.hours1
                const x = this.hours

                for (var i = z; i <= x; i++) {
                    const obj = {
                        date: start,
                        showdate: (i > 9 ? i : '0' + i) + ':00',
                        start: moment(start).format('YYYY-MM-DD'),
                        end: moment(start).format('YYYY-MM-DD'),
                        type: type
                    }
                    listData.push(obj)
                }
                this.listData = listData
            } else if (type === 'week') {
                var t = new Date(start).getTime()
                const obj1 = {
                    date: new Date(moment(new Date(start)).format('YYYY-MM-DD')).getTime(),
                    showdate: moment(start).format('MM月DD日') + ' ' + '周一',
                    start: moment(start).format('YYYY-MM-DD'),
                    end: moment(start).format('YYYY-MM-DD'),
                    type: type
                }
                listData.push(obj1)
                for (var j = 0; j < 6; j++) {
                    t += 24 * 60 * 60 * 1000
                    var week = ''
                    if (j === 0) { week = '周二' } else if (j === 1) { week = '周三' } else if (j === 2) { week = '周四' } else if (j === 3) { week = '周五' } else if (j === 4) { week = '周六' } else if (j === 5) { week = '周日' }
                    const obj2 = {
                        date: new Date(moment(new Date(t)).format('YYYY-MM-DD')).getTime(),
                        showdate: moment(t).format('MM月DD日') + ' ' + week,
                        start: moment(t).format('YYYY-MM-DD'),
                        end: moment(t).format('YYYY-MM-DD'),
                        type: type
                    }
                    listData.push(obj2)
                }
                this.listData = listData
                console.log('this.listData ', this.listData)
            }
        },
        changeTime(type) {
            this.startFunc()
            if (type === 'day') {
                this.eachDate(type, this.todayFirst)
            } else if (type === 'week') {
                this.eachDate(type, this.mondayFirst)
            }
            this.hyydData(this.listData[0].start, this.listData[this.listData.length - 1].end, this.siteid)
        },
        // 减时间
        changeTimeDown(type) {
            if (type === 'day') {
                this.today -= 24 * 60 * 60 * 1000
                this.eachDate(type, this.today)
            } else if (type === 'week') {
                this.monday -= 7 * 24 * 60 * 60 * 1000
                this.eachDate(type, this.monday)
            }
            this.hyydData(this.listData[0].start, this.listData[this.listData.length - 1].end, this.siteid)
        },
        // 加时间
        changeTimeUp(type) {
            if (type === 'day') {
                this.today += 24 * 60 * 60 * 1000
                this.eachDate(type, this.today)
            } else if (type === 'week') {
                this.monday += 7 * 24 * 60 * 60 * 1000
                this.eachDate(type, this.monday)
            }
            this.hyydData(this.listData[0].start, this.listData[this.listData.length - 1].end, this.siteid)
        },
        // 日视图切换时间
        timeChange(val) {
            this.today = new Date(val).getTime()
            this.eachDate('day', new Date(val).getTime())
            this.hyydData(this.listData[0].start, this.listData[this.listData.length - 1].end, this.siteid)
        },
        // 切换场地
        selectPlace(value) {
            // for (let i = 0; i < this.options.length; i++) {
            //     if (value === this.options[i].id) {
            //         this.changdi = this.options[i].name
            //     }
            // }
            this.changdi = value.siteName
            console.log('this.changdi', this.changdi)
            console.log('value', value)
            this.siteid = value.id
            this.hyydData(this.listData[0].start, this.listData[this.listData.length - 1].end, this.siteid)
        },
        handleClose(obj, type) {
            if (type === 'cancel') {
                this.dialogVisible = false
            } else {
                obj.passenger.cycle = this.radioType
                this.cancelApplyFuc(obj)
            }
        },
        // 修改预约
        editApply(openForm) {
            console.log(openForm, 447)
            // window.open('/#/wflow?wfEngineUrl=hygl/roomApply&busiDataId=' + openForm.passenger.busiDataId + '&orgCode=tsys')
            this.$emit('viewObject', openForm, 'edit')
        },
        // 取消预约
        cancelApply(hysOption) {
            this.$confirm('确定取消预约吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.cancelApplyFuc(hysOption)
            }).catch(() => {

            })
        },
        cancelApplyFuc(hysOption) {
            console.log('hysOption', hysOption)
            this.$http({
                method: 'DELETE',
                url: '/site/booking/delete',
                contentType: 'application/x-www-form-urlencoded',
                params: {
                    ids: hysOption.id
                }
            }).then(res => {
                if (res.code === 'success') {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    })
                    this.refreshtable()
                    this.dialogVisible = false
                }
            })
        },
        // 查询所有管理员
        searchAllAdmin() {
            this.$http({
                method: 'GET',
                url: `base/organMgr/findLinkManByJobPositionCode?jobPositionCode=ConferenceManager&orgCodes=${this.loginUser.orgCode}`,
                contentType: 'application/x-www-form-urlencoded'
            }).then(res => {
                if (res.code === 'success') {
                    res.body.listdata.forEach(item => {
                        if (this.loginUser.personId === item.personId) {
                            this.isAdmin = true
                        }
                    })
                }
            })
        },
        // 处理数据方法
        HandleMeetingList(map) {
            const itemMap = []
            const itemList = {}
            map.forEach(tr => {
                // debugger
                var rr = parseInt(tr.rbegintime.substr(11, 2))
                var tt = parseInt(tr.rendtime.substr(11, 2))
                tr.tdData = tr.rowData
                delete tr.rowData
                var meetingTrMap = []
                const daylist = JSON.parse(JSON.stringify(this.listData))
                tr.tdData.forEach(td => {
                    td.innerContent = td.siteList
                    delete td.siteList
                    for (let i = 0; i < td.innerContent.length; i++) {
                        const l = itemList[td.innerContent[i].id]

                        if (!l) {
                            const a = new Date(this.listData[0].start).getTime()// 周开始时间
                            const b = new Date(this.listData[6].end).getTime()// 周结束时间
                            const c = new Date(td.innerContent[i].beginTime).getTime()// 会开始时间
                            const d = new Date(td.innerContent[i].endTime).getTime()// 会议结束时间
                            let e = ''// 跨天计算开始时间
                            let f = ''// 跨天计算结束时间
                            e = new Date(moment(c).format('YYYY-MM-DD')).getTime() > a ? new Date(moment(c).format('YYYY-MM-DD')).getTime() : a
                            f = new Date(moment(d).format('YYYY-MM-DD')).getTime() < b ? new Date(moment(d).format('YYYY-MM-DD')).getTime() : b
                            if (itemMap.length > 0) {
                                // debugger
                                const x = itemMap[itemMap.length - 1]// 上一条会议
                                let xs = ''; let xe = ''; let ys = ''
                                if (this.radio === 'week') {
                                    xs = new Date(moment(new Date(x.beginTime)).format('YYYY-MM-DD')).getTime()// 上条会议开始时间
                                    xe = new Date(moment(new Date(x.endTime)).format('YYYY-MM-DD')).getTime()// 上条会议结束时间
                                    ys = new Date(moment(new Date(td.innerContent[i].beginTime)).format('YYYY-MM-DD')).getTime()// 本条会议开始时间
                                    if (ys <= xe) {
                                        td.innerContent[i].topData = x.meetRoomId === td.innerContent[i].meetRoomId ? (x.topData + 1) : 0
                                        td.innerContent[i].noPeer = ys > xs
                                    } else {
                                        td.innerContent[i].topData = 0
                                    }
                                } else {
                                    // debugger
                                    const qq = x.beginTime.substr(0, 10)
                                    const ww = x.endTime.substr(0, 10)
                                    var xem = parseInt(x.endTime.substr(14, 2))
                                    if (qq === ww) {
                                        xs = parseInt(x.beginTime.substr(11, 2))// 上条会议开始时间
                                        // const xsm = parseInt(x.beginTime.substr(14, 2))
                                        xe = parseInt(x.endTime.substr(11, 2)) // 上条会议结束时间
                                        xem = parseInt(x.endTime.substr(14, 2))
                                    } if (qq < daylist[0].start && ww > daylist[0].start) {
                                        xs = rr// 上条会议开始时间
                                        // const xsm = parseInt(x.beginTime.substr(14, 2))
                                        xe = tt // 上条会议结束时间
                                        xem = tt
                                    } if (qq === daylist[0].start && ww > daylist[0].start) {
                                        xs = parseInt(x.beginTime.substr(11, 2))// 上条会议开始时间
                                        xe = tt // 上条会议结束时间
                                        xem = tt
                                    } if (qq < daylist[0].start && ww === daylist[0].start) {
                                        xs = rr// 上条会议开始时间
                                        xe = parseInt(x.endTime.substr(11, 2)) // 上条会议结束时间
                                        xem = parseInt(x.endTime.substr(14, 2))
                                    }
                                    // const xem = parseInt(x.endTime.substr(14, 2))
                                    ys = parseInt(td.innerContent[i].beginTime.substr(11, 2)) // 本条会议开始时间
                                    const ysm = parseInt(td.innerContent[i].beginTime.substr(14, 2))
                                    if (ys < xe || (ys === xe && xem > 0 && ysm > xem)) {
                                        td.innerContent[i].topData = x.meetRoomId === td.innerContent[i].meetRoomId ? (x.topData + 1) : 0
                                        td.innerContent[i].noPeer = ys > xs
                                    } else {
                                        td.innerContent[i].topData = 0
                                    }
                                }
                            } else {
                                td.innerContent[i].topData = 0
                            }
                            if (this.radio === 'week') {
                                td.innerContent[i].currentDate = ((f - e + (24 * 60 * 60 * 1000)) / 1000 / 60 / 60 / 24)// 所占格数
                                if (!meetingTrMap[td.innerContent[i].topData]) {
                                    meetingTrMap[td.innerContent[i].topData] = {}
                                    meetingTrMap[td.innerContent[i].topData].weekday = JSON.parse(JSON.stringify(tr.tdData))
                                }
                                meetingTrMap[td.innerContent[i].topData].weekday.forEach((week, index) => {
                                    delete week.innerContent
                                    delete week.meetingList
                                    if (td.innerContent[i].topData >= 5) {
                                        delete week.innerContent
                                        delete week.meetingList
                                        meetingTrMap[5].weekday.forEach((week2, index2) => {
                                            if (week.weekday === week2.weekday && td.weekday === week2.weekday) {
                                                if (!week2.viewData)week2.viewData = []
                                                week2.viewData.push(td.innerContent[i])
                                            }
                                        })
                                    } else {
                                        if (td.weekday === week.weekday) {
                                            week.viewData = td.innerContent[i]
                                            if (td.innerContent[i].currentDate > 1) {
                                                meetingTrMap[td.innerContent[i].topData].weekday.splice(index + 1, td.innerContent[i].currentDate - 1)
                                            }
                                        }
                                    }
                                })
                                if (td.innerContent[i].topData > 5) meetingTrMap.splice(6, (td.innerContent[i].topData - 6 === 0 ? 1 : td.innerContent[i].topData - 6))
                            } else {
                                // debugger
                                const itemListDay = {}
                                // daylist = JSON.parse(JSON.stringify(this.listData))
                                for (let j = 0; j < daylist.length; j++) {
                                    const qq = td.innerContent[i].beginTime.substr(0, 10)
                                    const ww = td.innerContent[i].endTime.substr(0, 10)
                                    let mb = Number(td.innerContent[i].beginTime.substr(11, 2))// 会议开始时间
                                    let me = Number(td.innerContent[i].endTime.substr(11, 2))// 会议结束时间
                                    let mem = Number(td.innerContent[i].endTime.substr(14, 2))
                                    if (qq < daylist[0].start && ww > daylist[0].start) {
                                        mb = rr// 上条会议开始时间
                                        // const xsm = parseInt(x.beginTime.substr(14, 2))
                                        me = tt // 上条会议结束时间
                                        mem = tt
                                    } if (qq === daylist[0].start && ww > daylist[0].start) {
                                        // mb = parseInt(td.innerContent[i].beginTime.substr(11, 2))// 上条会议开始时间
                                        me = tt // 上条会议结束时间
                                        mem = tt
                                    } if (qq < daylist[0].start && ww === daylist[0].start) {
                                        mb = rr// 上条会议开始时间
                                        // me = parseInt(td.innerContent[i].endTime.substr(11, 2)) // 上条会议结束时间
                                        // mem = Number(td.innerContent[i].endTime.substr(14, 2))
                                    }
                                    // const mb = Number(td.innerContent[i].beginTime.substr(11, 2))// 会议开始时间
                                    // const me = Number(td.innerContent[i].endTime.substr(11, 2))// 会议结束时间
                                    // const mem = Number(td.innerContent[i].endTime.substr(14, 2))
                                    const db = Number(daylist[j].showdate.substr(0, 2))// 当前展示开始时间
                                    const de = Number(daylist[daylist.length - 1].showdate.substr(0, 2))// 当前展示结束时间

                                    if (!itemListDay[td.innerContent[i].id]) { // 没有计算过天数
                                        const a1 = mb > db ? mb : db // 开始时间最大的数
                                        const a2 = (me === 0 ? 24 : me) > de ? (de + 1) : (me === 0 ? 24 : me)// 结束时间最小的数
                                        // if (td.innerContent[i].id === '3b0c3357be1949c39c3d01a80558b18d') {
                                        //     debugger
                                        // }
                                        td.innerContent[i].currentDate = (a2 - a1) === 0 ? 1 : (a2 === 24 ? a2 - a1 : (mem > 0 ? (a2 - a1 + 1) : a2 - a1))
                                        // td.innerContent[i].topData = 0
                                        if (mb < db) {
                                            itemListDay[td.innerContent[i].id] = td.innerContent[i]
                                            if (!daylist[j].innerContent)daylist[j].innerContent = []
                                            daylist[j].innerContent.push(td.innerContent[i])
                                        } else {
                                            if (mb === db) {
                                                itemListDay[td.innerContent[i].id] = td.innerContent[i]
                                                if (!daylist[j].innerContent)daylist[j].innerContent = []
                                                daylist[j].innerContent.push(td.innerContent[i])
                                            }
                                        }
                                    }
                                }
                                if (!meetingTrMap[td.innerContent[i].topData]) {
                                    meetingTrMap[td.innerContent[i].topData] = {}
                                }

                                meetingTrMap[td.innerContent[i].topData].weekday = JSON.parse(JSON.stringify(daylist))

                                meetingTrMap[td.innerContent[i].topData].weekday.forEach((week, index) => {
                                    if (td.innerContent[i].topData >= 5) {
                                        meetingTrMap[5].weekday.forEach((week2, index) => {
                                            if (week.showdate === week2.showdate && week.innerContent) {
                                                if (!week2.viewData)week2.viewData = week.innerContent
                                                week2.viewData = week.innerContent.filter(item => {
                                                    if (item.topData >= 5) {
                                                        week2.viewData.push(item)
                                                        return week2.viewData
                                                    }
                                                })
                                                delete week.innerContent
                                            }
                                        })
                                    } else {
                                        if (week.innerContent) {
                                            const a = week.innerContent.filter(item => item.topData === td.innerContent[i].topData)
                                            if (a.length > 0) {
                                                week.viewData = a[0]
                                                // console.log(week.viewData, index)
                                                // delete week.innerContent
                                                if (week.viewData.currentDate > 1) {
                                                    meetingTrMap[week.viewData.topData].weekday.splice(index + 1, week.viewData.currentDate - 1)
                                                }
                                            }
                                        }
                                    }
                                })
                                if (td.innerContent[i].topData > 5) meetingTrMap.splice(6, (td.innerContent[i].topData - 6 === 0 ? 1 : td.innerContent[i].topData - 6))
                            }
                            itemList[td.innerContent[i].id] = td.innerContent[i]
                            itemMap.push(td.innerContent[i])
                        } else {
                            td.innerContent.splice(i--, 1)
                        }
                    }
                })
                meetingTrMap = meetingTrMap.filter(item => item !== '空白')
                tr.viewTr = meetingTrMap
                // debugger
                if (this.radio === 'day') {
                    tr.tdData = daylist
                }
            })
            this.tableData = map

            console.log('')
            console.log('mapmapmap', map)
        }
    }
}
</script>
<style lang="scss" scoped>
@import "~@/wapp/common/styles/element-variables.scss";
.top{
  display: flex;
  justify-content: space-between;
  .top-left{
    display: inline-block;
    .left-week{
      display: inline-block;
    }
    .left-date{
      display: inline-block;
      .suspension{
        position: absolute;
        top: -7px;
        left: 34px;
        opacity: 0;
        .el-date-editor.el-input{width: 105px;}
      }
      .el-button--text.date-data{
            color:  #303133;
            cursor: default;
            &:hover{
                color: #303133;
            }
      }
      .el-button--text{
        color:  #303133;
        &:hover{
            color: #409EFF;
        }
      }
    }
  }
  .top-right{
    display: inline-block;
    .left-date{
      display: inline-block;
      top: -3px;
      margin-right: 30px;
        .suspension{
            position: absolute;
            top: -7px;
            left: 34px;
            opacity: 0;
            .el-date-editor.el-input{width: 105px;}
        }
        .el-button--text.date-data{
                color:  #303133;
                cursor: default;
                &:hover{
                    color: #303133;
                }
        }
        .el-button--text{
            color:  #303133;
                &:hover{
                    color: #409EFF;
                }
        }
  }
  .el-button {
        font-size: 16px;
        padding: 0px 6px;
    }
}
  .left-nowDate{
    display: inline-block;
    .el-button--text {
    vertical-align: middle;
    }
  }
}
/deep/ .has-gutter{
/deep/ tbody{
  .el-table__row:hover{
    background-color: #FFF;
  }
}
}
 /deep/ .current{
      background: #F3F4F4;
      color:#333333;
    }
.hysTd{
    cursor: pointer;
    background:  $--color-success;
    color:#FFFFFF;
    border-top:1px solid #ffffff;
    height:25px;
    text-align: left;
    line-height: 25px;
    overflow: hidden;
}
.cancelapply{
    width: 80px;
    height: auto;
    padding: 4px 8px;
    border: 1px solid $--color-primary;
    color: $--color-primary;
    text-align: center;
    cursor: pointer;
}
.content{
    position:relative;
    .table_float_time{
        cursor: pointer;
        width: calc(100% - 181px);
        position: absolute;
        top: 14px;
        z-index: 50;
        left: 181px;
    }
}
/deep/ .out_table_box .table .cell{
    color: #666666;
    font-size: 15px;
    font-weight: bold;
}
/deep/.out_table_box .table .out_tbody .ground_div .virtual_table tbody tr td:last-child{
     border-right: 1px solid #DEE2E6;
}

.shadowClass{
    box-shadow: 5px 5px 5px #909399 !important;
}
/deep/.out_table_box .table thead tr th{
    line-height: 32px !important;
    background-color: #F2F5FB;
    box-sizing: border-box !important
}
/deep/.out_table_box .table thead tr th:last-child{
    border:1px solid #d8d8d8;
    box-sizing: border-box !important
}
/deep/.out_table_box .table thead tr th:first-child{
    // border-left: 1px solid #EBEEF5 !important;
    box-sizing: border-box !important;
    position: relative;
    //  &::before{
    //         content: '';
    //         position: absolute;
    //         border-left: 1px solid #EBEEF5;
    //         top: 0;
    //         left: 0;
    //         bottom: 0;
    //       }
}
/deep/.out_table_box .table thead tr .current{
    background: var(--bgColor) !important;
}
/deep/.out_table_box .table thead tr .current .cell{
    color: var(--textColor) !important;
}
</style>
<style lang="scss">
@import "~@/wapp/common/styles/element-variables.scss";
a:hover{color:$--color-primary ;}
</style>
