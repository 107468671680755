<template>
  <div>
    <div style="margin:20px 0 10px;text-align:right">
      <el-button
        v-table-toxls="exportTable"
        plain
        class="exportbtn"
        :file-name="'部门日均时长统计'"
        size="mini"
      ><svg-icon :icon-class="'export'" /> 导出</el-button>
    </div>
    <list-table id="exportTable" :list-tb="listTb" :list-data="listData" :empty-text="emptyText" />
  </div>
</template>

<script>
import ListTable from '../components/ListTable'

export default {
    components: {
        ListTable
    },
    props: {
    },
    data: function() {
        return {
            loginUser: this.$store.getters.loginUser,
            listTb: [
                { 'prop': 'DEPARTMENT', 'width': '', 'align': 'left', 'title': '部门', 'sortable': true },
                { 'prop': 'NAME', 'width': '80px', 'align': 'center', 'title': '姓名 ', 'sortable': true },
                { 'prop': 'workday', 'width': '95px', 'align': 'center', 'title': '出勤天数', 'sortable': true },
                { 'prop': 'workTime', 'width': '110px', 'align': 'center', 'title': '工作总时长', 'sortable': true },
                { 'prop': 'avgTime', 'width': '125px', 'align': 'center', 'title': '日均工作时长 ', 'sortable': true }
            ],
            listData: [],
            emptyText: '正在加载..',
            loadingMast: null
        }
    },
    computed: {
    },
    watch: {
    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {
        setTimeout(() => {
            this.getData()
        }, 50)
    },
    methods: {
        // 获取数据，部门人员工作时长
        getData() {
            // if (this.loadingMast !== null) this.loadingMast.close()
            this.emptyText = '正在加载..'
            // this.loadingMast = this.$loading({
            //     target: '.app-container .el-table__body-wrapper'
            // })
            this.$http({
                url: 'yykq/homePageIndex/deptWorkTime',
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                params: {
                    startTime: this.$vnode.context.chooseDate[0],
                    endTime: this.$vnode.context.chooseDate[1],
                    orgCode: this.$vnode.context.orgCode,
                    deptId: this.$vnode.context.selectDept
                }
            }).then(res => {
                if (res.code === 'success') {
                    this.listData = res.body.listdata
                    // this.loadingMast.close()
                    if (this.listData.length === 0) {
                        this.emptyText = '暂无数据'
                    }
                } else {
                    this.$message.error(res.message)
                }
            })
        }

    }
}
</script>

<style scoped>
    /* /deep/ .el-table-toolbar{display: none;} */
</style>
