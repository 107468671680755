<template>
  <div>
    <div style="padding: 10px">
      <table
        style="
          border-collapse: collapse;
          border: 1px solid #e2e7ed;
          width: 100%;
        "
      >
        <tr style="height: 45px">
          <td
            style="
              border: 1px solid #e2e7ed;
              text-align: right;
              width: 200px;
              background: #eef0f3;
              padding: 0 10px;
              font-size: 13px;
              font-weight: 700;
            "
          >
            日期
          </td>
          <td style="border: 1px solid #e2e7ed; text-align: left">
            <el-date-picker
              v-model="value1"
              class="riqi"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </td>
          <td
            style="
              border: 1px solid #e2e7ed;
              text-align: right;
              width: 200px;
              background: #eef0f3;
              padding: 0 10px;
              font-size: 13px;
              font-weight: 700;
            "
          >
            学校
          </td>
          <td style="border: 1px solid #e2e7ed; text-align: left">
            <el-select v-model="valueSc" placeholder="请选择">
              <el-option
                v-for="item in optionsSc"
                :key="item.id"
                :label="item.schoolName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr style="height: 45px">
          <td
            style="
              border: 1px solid #e2e7ed;
              text-align: right;
              width: 200px;
              background: #eef0f3;
              padding: 0 10px;
              font-size: 13px;
              font-weight: 700;
            "
          >
            年级
          </td>
          <td style="border: 1px solid #e2e7ed; text-align: left">
            <el-select v-model="valueA" placeholder="请选择">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td
            style="
              border: 1px solid #e2e7ed;
              text-align: right;
              width: 200px;
              background: #eef0f3;
              padding: 0 10px;
              font-size: 13px;
              font-weight: 700;
            "
          >
            班级
          </td>
          <td style="border: 1px solid #e2e7ed; text-align: left">
            <el-select v-model="valueB" placeholder="请选择">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr style="height: 45px; text-align: center">
          <td colspan="4" style="border-top: 1px solid #e2e7ede2e7ed">
            <div>
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchClick()"
              >开始搜索</el-button>
              <el-button icon="el-icon-close" @click="cleanUp">清除搜索</el-button>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="block">
      <!-- <span style="font-size: 12px; margin-left: 10px">日期:</span>
      <el-date-picker
        v-model="value1"
        class="riqi"
        type="date"
        placeholder="选择日期"
        format="yyyy 年 MM 月 dd 日"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>

      <span style="font-size: 12px; font-color: gary">年级:</span>
      <el-select v-model="valueA" placeholder="请选择">
        <el-option
          v-for="item in options1"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <span style="font-size: 12px">班级:</span>
      <el-select v-model="valueB" placeholder="请选择">
        <el-option
          v-for="item in options2"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="searchClick()">查询</el-button> -->

      <div class="right">
        <div
          data-v-fd7a5c9c=""
          class="el-button-group toolbar-button-group el-popover__reference"
          aria-describedby="el-popover-4191"
          tabindex="0"
        >
          <button
            data-v-fd7a5c9c=""
            type="button"
            class="el-button el-button--default el-button--small is-plain export_btn"
            @click="exportData"
          >
            <span>
              <svg
                data-v-5d08e6e2=""
                data-v-fd7a5c9c=""
                aria-hidden="true"
                class="svg_icon svg-icon"
              >
                <use data-v-5d08e6e2="" xlink:href="#icon-export" />
              </svg>
              <span data-v-fd7a5c9c="">导出</span></span>
          </button>
        </div>
      </div>
    </div>
    <div class="table-box">
      <el-table :data="arrList" style="width: 100%">
        <el-table-column prop="gardeClass" align="center" label="项目/班" min-width="120px">
        </el-table-column>
        <el-table-column label="电器" align="center">
          <el-table-column
            prop="AIRCOND_DEDUCT"
            label="空调"
            width="80"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.AIRCOND_DEDUCT==undefined?0:scope.row.AIRCOND_DEDUCT }}
            </template>
          </el-table-column>
          <el-table-column
            prop="FAN_DEDUCT"
            label="风扇"
            width="80"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.FAN_DEDUCT==undefined?0:scope.row.FAN_DEDUCT }}
            </template>
          </el-table-column>
          <el-table-column prop="LAMP_DEDUCT" label="灯" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.row.LAMP_DEDUCT==undefined?0:scope.row.LAMP_DEDUCT }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="FLOOR_DEDUCT"
          label="地面"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.FLOOR_DEDUCT==undefined?0:scope.row.FLOOR_DEDUCT }}
          </template>
        </el-table-column>
        <el-table-column
          prop="TOTAL_BED_SCORE_DEDUCT"
          label="床铺"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.TOTAL_BED_SCORE_DEDUCT==undefined?0:scope.row.TOTAL_BED_SCORE_DEDUCT }}
          </template>
        </el-table-column>
        <el-table-column prop="DESK_DEDUCT" label="桌椅" width="80" align="center">
          <template slot-scope="scope">
            {{ scope.row.DESK_DEDUCT==undefined?0:scope.row.DESK_DEDUCT }}
          </template>
        </el-table-column>
        <el-table-column prop="SHOE_DEDUCT" label="鞋子" width="80" align="center">
          <template slot-scope="scope">
            {{ scope.row.SHOE_DEDUCT==undefined?0:scope.row.SHOE_DEDUCT }}
          </template>
        </el-table-column>
        <el-table-column
          prop="TRUNK_DEDUCT"
          label="行李箱"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.TRUNK_DEDUCT==undefined?0:scope.row.TRUNK_DEDUCT }}
          </template></el-table-column>
        <el-table-column
          prop="WASH_DEDUCT"
          label="洗手台阳台"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.WASH_DEDUCT==undefined?0:scope.row.WASH_DEDUCT }}
          </template></el-table-column>
        <el-table-column
          prop="TOILET_DEDUCT"
          label="卫生间"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.TOILET_DEDUCT==undefined?0:scope.row.TOILET_DEDUCT }}
          </template></el-table-column>
        <el-table-column
          prop="TRASH_DEDUCT"
          label="垃圾篓"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.TRASH_DEDUCT==undefined?0:scope.row.TRASH_DEDUCT }}
          </template></el-table-column>
        <el-table-column label="阳台物品摆放" align="center">
          <el-table-column
            prop="TOOL_DEDUCT"
            label="卫生工具"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.TOOL_DEDUCT==undefined?0:scope.row.TOOL_DEDUCT }}
            </template>
          </el-table-column>
          <el-table-column
            prop="BUCKET_DEDUCT"
            label="桶盆"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.BUCKET_DEDUCT==undefined?0:scope.row.BUCKET_DEDUCT }}
            </template>
          </el-table-column></el-table-column>
        <el-table-column prop="MORNING_LATE_DEDUCT" label="早上迟出" width="80" align="center">
          <template slot-scope="scope">
            {{ scope.row.MORNING_LATE_DEDUCT==undefined?0:scope.row.MORNING_LATE_DEDUCT }}
          </template> </el-table-column>

        <el-table-column label="宿舍区" align="center">
          <el-table-column prop="NOON_DEDUCT" label="午睡" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.row.NOON_DEDUCT==undefined?0:scope.row.NOON_DEDUCT }}
            </template>
          </el-table-column>
          <el-table-column prop="NIGHT_DEDUCT" label="晚睡" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.row.NIGHT_DEDUCT==undefined?0:scope.row.NIGHT_DEDUCT }}
            </template>
          </el-table-column>
          <el-table-column prop="LATE_DEDUCT" label="迟到" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.row.LATE_DEDUCT==undefined?0:scope.row.LATE_DEDUCT }}
            </template>
          </el-table-column>
          <el-table-column prop="BREACH_DEDUCT" label="违纪" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.row.BREACH_DEDUCT==undefined?0:scope.row.BREACH_DEDUCT }}
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="扣分" width="80" align="center">
          <template slot-scope="scope">
            {{
              Number(scope.row.BREACH_DEDUCT) +
                Number(scope.row.LATE_DEDUCT) +
                Number(scope.row.NIGHT_DEDUCT) +
                Number(scope.row.NOON_DEDUCT) +
                Number(scope.row.MORNING_LATE_DEDUCT) +
                Number(scope.row.BUCKET_DEDUCT) +
                Number(scope.row.TOOL_DEDUCT) +
                Number(scope.row.TRASH_DEDUCT) +
                Number(scope.row.TOILET_DEDUCT) +
                Number(scope.row.WASH_DEDUCT) +
                Number(scope.row.TRUNK_DEDUCT) +
                Number(scope.row.SHOE_DEDUCT) +
                Number(scope.row.DESK_DEDUCT)+
                Number(scope.row.TOTAL_BED_SCORE_DEDUCT) +
                Number(scope.row.FLOOR_DEDUCT) +
                Number(scope.row.LAMP_DEDUCT) +
                Number(scope.row.FAN_DEDUCT) +
                Number(scope.row.AIRCOND_DEDUCT)
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {

            valueA: '全部',
            valueB: '全部',
            valueSc: '全部',
            loginUser: this.$store.getters.loginUser,
            value1: new Date().toJSON().slice(0, 10),
            banji: '',
            options1: [
                { value: '高一', label: '高一' },
                { value: '高二', label: '高二' },
                { value: '高三', label: '高三' },
                { value: '全部', label: '全部' }
            ],
            optionsSc: [
                { schoolName: '全部', id: '全部' }
            ],
            options2: [{ value: '全部', label: '全部' }],
            arrList: [],
            arr1: []
        }
    },
    computed: {},
    watch: {
        valueA(val) {
            this.valueB = '全部'
            if (val === '全部') {
                this.options2 = [{ value: '全部', label: '全部' }]
                return
            }
            if (val) {
                this.$http({
                    method: 'post',
                    url: '/bus/buildingRoomScore/getGradeClass',
                    params: {
                        grade: this.valueA
                    }
                }).then((res) => {
                    console.log(21312, res)
                    if (res.code === 'success') {
                        console.log(val)

                        this.options2 = [{ value: '全部', label: '全部' }]
                        const arr = res.body.vo
                        if (arr.length > 0) {
                            arr.forEach((item) => {
                                this.options2.push({
                                    label: item + '班',
                                    value: item
                                })
                            })
                        }
                    }
                })
            }
        }
    },
    created() {
        this.getSchoolData()
        this.$bus.emit('showNavSearch', false)
    },
    beforeDestroy() {},
    mounted() {
        this.searchClick()
    },
    methods: {
        cleanUp() {
            this.value1 = new Date().toJSON().slice(0, 10)
            this.valueA = '全部'
            this.valueB = '全部'
            this.valueSc = '全部'
        },
        getSchoolData() {
            console.log(this.loginUser)
            this.$http({
                method: 'post',
                url: '/bus/school/querydata',
                data: [
                    { 'compare': '=', 'field': 'orgCode', 'merge': false, 'value': this.loginUser.orgCode },
                    { 'compare': '=', 'field': 'status', 'merge': false, 'value': 1 }
                ]
            }).then(res => {
                console.log(res)
                if (res.code === 'success') {
                    res.body.listdata.forEach(item => {
                        this.optionsSc.push(item)
                    })
                }
            })
        },
        exportData() {
            debugger
            window.open(`apigw/bus/buildingRoomScore/exportTotalRoomScore?date=${this.value1}&schoolId=${this.valueSc}&grade=${this.valueA}&classNo=${this.valueB}&pageNum=1&pageSize=100000&fileName=内务评分汇总`)

            // if (this.valueSc !== '全部') {
            // } else {
            //     window.open(`apigw/bus/buildingRoomScore/exportTotalRoomScore?date=${this.value1}&grade=${this.valueA}&classNo=${this.valueB}&pageNum=1&pageSize=100000&fileName=内务评分汇总`)
            // }
        },
        searchClick() {
            this.arrList = ''
            this.$http({
                method: 'post',
                url: '/bus/buildingRoomScore/toSumScore',
                params: {
                    grade: this.valueA,
                    classNo: this.valueB,
                    date: this.value1,
                    schoolId: this.valueSc
                }
            }).then((res) => {
                if (res.code === 'success') {
                    console.log('776', res, this.valueA, this.valueB, this.value1)
                    this.arrList = res.body.vo
                    // res.forEach(() => {
                    //     res.body.vo === undefined ? 0 : res.body.vo
                    // })

                    // const arr = []
                    // Object.keys(res.body.vo).forEach((key) => {
                    //     arr.push({
                    //         list: res.body.vo[key]
                    //     })
                    // })

                    // this.arr = arr
                    // console.log(arr, 12121)

                    // this.arr1 = []
                    // for (let i = 0; i < arr.length; i++) {
                    //     console.log(arr[i].list, 1234)
                    //     this.arr1 = arr[i].list
                    //     // for (let j = 0; j < this.arr1.length; j++) {
                    //     //     this.arr2.push(this.arr1[j])
                    //     // }
                    //     // this.arrList = this.arr2
                    //     console.log(this.arr1, 89989)
                    // }
                // this.dataFormat()
                // this.dataFormat1()
                }
            })
        }

        // addForm() {
        //     this.moduleInfo.formTemplate = '#/dcontent/vuebusiform/W/bus.buildingRoomScore.form.vue?sysCode=' + this.$route.meta.sysCode
        //     this.$options.components['BusiForm'] = this.$loadComponent(this.moduleInfo.formTemplate)

    //     this.$nextTick(() => {
    //         this.$bus.emit('openBusiform', this.moduleInfo, null, 1, (res) => {
    //             if (res.code === 'success') {
    //                 console.log(132323, res)
    //             }
    //         })
    //     })
    // }
    }
}
</script>

<style scoped lang="scss">
.right {
  margin-right: 10px;
  margin-bottom: 10px;
  float: right;
  display: flex;
  justify-content: flex-end;
}

div.block {
  margin: 10px 0;
  width: 100%;
  // background-color: red;
}
.el-select {
  margin: 0 5px;
  width: 98%;
}
.riqi {
  margin: 0 5px;
  width: 98%;
}
.table-box {
  width: 100%;
  padding: 0 10px;
}

.el-table {
  overflow: hidden;
}
</style>
