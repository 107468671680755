import echartcolumnar from './src/EchartColumnar.vue'
import echartannular from './src/EchartAnnular.vue'
import echartstackcolumnar from './src/EchartStackColumnar'
import echartdashboard from './src/EchartDashboard'
import echartTreemap from './src/EchartTreemap'
export const EchartColumnar = echartcolumnar
export const EchartAnnular = echartannular
export const EchartStackColumnar = echartstackcolumnar
export const EchartDashboard = echartdashboard
export const EchartTreemap = echartTreemap
