<template>
  <div class="main">
    <div v-show="false" class="tableDetail">
      <data-table
        v-if="newVo&&newVo.innerList"
        :module-info="moduleInfo"
        :tb-columns="tbColumns"
        :toolbar-btns="listpageBtns"
        :on-list-data-before-load="onListDataBeforeLoad"
        :on-list-data-loaded="onListDataLoaded"
        :on-delete-successed="onDeleteSuccessed"
        :on-cell-click="cellClick"
        :row-class-name="tableRowClassName"
      >
      </data-table>
      <data-table
        v-else
        :module-info="moduleInfo"
        :tb-columns="tbColumns"
        :toolbar-btns="listpageBtns"
        :on-list-data-before-load="onListDataBeforeLoad"
        :on-list-data-loaded="onListDataLoaded"
        :on-delete-successed="onDeleteSuccessed"
        :row-class-name="tableRowClassName"
      >
      </data-table>
    </div>
    <div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @row-click="cellClick"
      >
        <el-table-column
          prop=""
          label="序号"
          width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span :style="{color: setcolor(scope.row.importantWork)}">{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="subject"
          label="事项标题"
        >

          <template slot-scope="scope">
            <span :style="{color: setcolor(scope.row.importantWork)}">{{ scope.row.subject }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="startTime"
          label="开始时间"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            <span :style="{color: setcolor(scope.row.importantWork)}">{{ setdata(scope.row.startTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="importantWork"
          label="是否为重点工作"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span :style="{color: setcolor(scope.row.importantWork)}">{{ scope.row.importantWork==1?'是':'否' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="location"
          label="地点"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span :style="{color: setcolor(scope.row.importantWork)}">{{ scope.row.location }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- <Basicform
      ref="busiform"
      :title="'事项登记'"
      :win-width="moduleInfo.winWidth"
      :win-height="moduleInfo.winHeight"
      :append-to-body="true"
      :wf-attach="true"
      :toolbar-btns="busiformBtns"
      :on-basic-form-before-open="onBasicFormBeforeOpened"
      :on-basic-form-before-submit="onBasicFormBeforeSubmit"
      :on-basic-form-submited="onBasicFormSubmited"
    >
      <template v-slot:default="slotProps">
        <busi-form :vo="slotProps.vo" :can-edit="slotProps.canEdit" :module-info="moduleInfo" />
      </template>
    </Basicform> -->

  </div>
</template>

<script>
// const tdList = document.querySelectorAll('td')
// console.log(tdList, 'tdList')
// tdList.forEach(td => {
//     const tdText = td.innerText.trim()
//     if (tdText === '是') {
//         const tr = td.closest('tr')
//         tr.style.color = 'red'
//         console.log(tr, 1212)
//     }
// })
export default {
    components: {},
    props: {
        newVo: { type: Object, default: function() { return {} } },
        endTimep: { type: String, default: '' },
        startTimep: { type: String, default: '' }
    },
    data() {
        return {
            isShow: true,
            tableData: [],
            moduleInfo: {
                'winWidth': 1000,
                'delUrl': 'zhsp/sxdj/delete',
                'addNewUrl': 'zhsp/sxdj/addNew',
                'insertUrl': 'zhsp/sxdj/insert',
                'winHeight': 550,
                'updateUrl': 'zhsp/sxdj/update',
                'listDataUrl': 'zhsp/sxdj/listdata',
                'queryDataUrl': 'zhsp/sxdj/dayView',
                'getByIdUrl': 'zhsp/sxdj/getById',
                'queryHolidayUrl': 'base/holiday/querydata'
            },
            listpageBtns: [{}],
            tbColumns: [
                {
                    field: 'index',
                    title: '序号',
                    width: '45px',
                    align: 'center',
                    valign: 'middle',
                    cellStyle: { css: { 'color': 'red' }}
                },
                {
                    align: 'left',
                    field: 'subject',
                    title: '事项标题',
                    width: '',
                    sortable: true,
                    listAdvSearchElement: 'input',
                    searchable: true,
                    listAdvSearchIndex: 0,
                    propType: 'String',
                    cellStyle: { css: { 'color': 'red' }}
                },
                {
                    align: 'center',
                    field: 'startTime',
                    title: '开始时间 ',
                    width: '150px',
                    sortable: true,
                    listAdvSearchElement: 'date',
                    searchable: true,
                    listAdvSearchIndex: 1,
                    propType: 'java.util.Date',
                    elementFormatter: 'format：yyyy年MM月dd日 HH:mm\r\n'
                },
                {
                    align: 'center',
                    field: 'importantWork',
                    title: '是否为重点工作',
                    width: '150px',
                    sortable: true,
                    listAdvSearchElement: 'input',
                    searchable: true,
                    listAdvSearchIndex: 2,
                    propType: 'Short',
                    elementFormatter: '1：是\n0：否'
                },
                {
                    align: 'center',
                    field: 'location',
                    title: '地点',
                    width: '150px',
                    sortable: true,
                    listAdvSearchElement: 'input',
                    searchable: true,
                    listAdvSearchIndex: 3,
                    propType: 'String'
                }
            ]
        }
    },
    computed: {},
    watch: {

    },
    created() {
        this.$bus.on('setlistdata', this.setlistdata)
        // this.geDataList()
    },
    beforeDestroy() {
        this.$bus.off('setlistdata', this.setlistdata)
    },
    mounted() {

    },
    methods: {
        setdata(val) {
            const originalTime = val
            const date = new Date(originalTime)

            const year = date.getFullYear()
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const day = date.getDate().toString().padStart(2, '0')
            const hour = date.getHours().toString().padStart(2, '0')
            const minute = date.getMinutes().toString().padStart(2, '0')

            const formattedTime = year + '年' + month + '月' + day + '日 ' + hour + ':' + minute

            return formattedTime
        },
        setcolor(val) {
            let color = ''
            if (val == 1) {
                color = 'red'
            }
            return color
        },
        geDataList() {
            if (this.newVo && this.newVo.innerList) {
                this.tableData = this.newVo.innerList
            } else {
                this.$http({
                    method: 'post',
                    url: '/zhsp/sxdj/listdata',
                    params: {
                        pageSize: 65000,
                        pageNum: 1,
                        orderBy: 'startTime desc',
                        listpageId: 'c0ddf63924a749db9aab7a37541eae5f',
                        urlsearch: `startTime>:${this.startTimep};endTime<:${this.endTimep};status:1;`
                    }
                }).then(res => {
                    if (res.code === 'success') {
                        console.log(res)
                        this.tableData = res.body.pageInfo.list
                    }
                })
            }
        },
        setlistdata(obj) {
            this.startTimep = obj.startTimep
            this.endTimep = obj.endTimep
            this.geDataList()
        },
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex === 1) {
                return 'warning-row'
            } else if (rowIndex === 3) {
                return 'success-row'
            }
            return ''
        },
        cellClick(row) {
            if (this.newVo.innerList) return
            this.moduleInfo.formTemplate = '#/dcontent/vuebusiform/W/zhsp.sxdj.form.vue'
            this.$options.components['BusiForm'] = this.$loadComponent(this.moduleInfo.formTemplate)
            this.$nextTick(() => {
                this.$bus.emit('openBusiform', this.moduleInfo, row, 0, (res) => {
                    if (res.code === 'success') {
                        this.geDataList()
                    }
                })
            })
        },
        /**
* 加载数据完成，pageInfo为包含查询结果的分页信息对象，不需要返回值
*/
        onListDataLoaded($scope, pageInfo) {
            console.log('sysLog listdata loaded')
            if (this.newVo && this.newVo.innerList) {
                pageInfo.list = this.newVo.innerList
                pageInfo.total = this.newVo.innerList.length
            }

            // setTimeout(() => {
            //     debugger
            //     const dom = document.querySelectorAll('.el-table__row')
            //     dom.forEach(item => {
            //         if (item.innerText.indexOf('是') > -1) {
            //             item.style.color = 'red'
            //         }
            //     })
            //     console.log('cellStyle', dom)
            // }, 100)
        },
        /**
* 加载数据准备，qparams为查询参数对象，不需要返回值
*/
        onListDataBeforeLoad: function($scope, qparams) {
            qparams.orderBy = 'startTime desc'
            qparams.urlsearch = `startTime>:${this.startTimep};endTime<:${this.endTimep}`
        }
    }
}
</script>

<style scoped lang="scss">

.red-row {
  color: red; // 设置字体颜色为红色
}
.top-tools, .breadcrumb {
    overflow: hidden;
    margin-top: 15px;
    padding: 0 15px;
    .l {
      float: left;
      display: flex;
      align-items: center;

      // width: 480px;
      .top-text{
         height: 100%;
         margin: 0 auto;
         margin-left: 40px;
      }
    }
    .r {
      float: right;
      width: 200px;
      text-align: right
    }
}

.main {
    padding: 0px 20px;

}

  .tableDetail{
    margin-top:0px;
  }

  .input-group.date input{height:36px;}
  /deep/ .el-range-separator{width: 30px;}
  .el-table, .wflow-container, .el-button {
    font-size: 14px !important;
   }
   /deep/.el-table__header th{
     font-weight: 500;
   }

</style>
