<template>
  <ModuleIndex :row="row" />
</template>

<script>
export default {
    props: {
        row: { type: Object, default: function() { return {} } }
    },
    data() {
        return {
        }
    }
}
</script>
