<template>
  <div>
    {{ row[column.field] }}<el-link icon="el-icon-download" @click="downloadH5app" />
  </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        column: { type: Object, default: null },
        row: { type: Object, default: null },
        index: { type: Number, default: null }
    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
        downloadH5app() {
            const downloadUrl = '/apigw/h5/version/download?id=' + this.row.id + '&dataField=program&nameField=fileName'
            window.open(downloadUrl)
        }
    }
}
</script>

<style scoped lang="scss">
.el-link{
    margin-left: 5px;
}
</style>
