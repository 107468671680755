<template>
    <div v-if="showNavbar" class="navbar">
        <div class="navbar-icon">
            <span class="icon-style">
                <svg-icon v-if="navbarIcon.indexOf('.svg') !== -1" :icon-class="navbarIcon.substring(0, navbarIcon.indexOf('.svg'))" />
                <i v-if="navbarIcon.indexOf('.svg') === -1" :class="'el-icon-' + navbarIcon" />
            </span>
        </div>

        <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

        <div v-if="showNavSearch" class="right-menu">
            <template v-if="device !== 'mobile'">
                <search id="header-search" class="right-menu-item" :advanced-hidder="hidden" />
            </template>
        </div>

        <div v-if="showWflowTitle" class="right-menu wflow-title">
            <span class="title">当前状态：{{ titleObj.wfNodeNames }}</span>
            <span class="flFormSerial">业务编号:{{ titleObj.flFormSerial }}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/wapp/common/components/Breadcrumb'
import Search from '@/wapp/common/components/SearchInput'

export default {
    components: {
        Breadcrumb,
        Search
    },
    data() {
        return {
            showNavbar: true,
            searchText: '',
            showNavSearch: true,
            waiting1: false,
            waiting: false,
            showWflowTitle: false,
            titleObj: {},
            hidden: false
        }
    },
    computed: {
        ...mapGetters(['sidebar', 'device']),
        navbarIcon() {
            return this.$route.meta.icon ? this.$route.meta.icon : this.$route.meta.parentIconCode
        }
    },
    created() {
        this.$bus.on('showNavbar', this.setNavbar)
        this.$bus.on('showNavSearch', this.setNavSearch)
        this.$bus.on('showNavWflowTitle', this.showNavWflowTitle)
        this.$bus.on('hiddenAdvSearch', this.hiddenAdvSearch)
    },
    beforeDestroy() {
        this.$bus.off('showNavbar', this.setNavbar)
        this.$bus.off('showNavSearch', this.setNavSearch)
        this.$bus.off('showNavWflowTitle', this.showNavWflowTitle)
        this.$bus.off('hiddenAdvSearch', this.hiddenAdvSearch)
    },
    updated() {
        this.$bus.emit('clearSearchText')
    },
    methods: {
        toggleSideBar() {
            this.$store.dispatch('app/toggleSideBar')
        },
        setNavbar(show) {
            // console.log(show, 76)
            if (show) {
                this.waiting1 = true
                setTimeout(() => {
                    this.showNavbar = this.waiting1
                }, 1000)
            } else {
                this.waiting1 = show
                this.showNavbar = show
            }
        },
        setNavSearch(show) {
            if (show) {
                this.waiting = true
                setTimeout(() => {
                    this.showNavSearch = this.waiting
                }, 1000)
            } else {
                this.waiting = show
                this.showNavSearch = show
            }
        },
        showNavWflowTitle(show, obj) {
            this.titleObj = obj
            this.showWflowTitle = show
        },
        hiddenAdvSearch(val) {
            this.hidden = val
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/wapp/common/styles/variables.module.scss';

.navbar {
    height: 50px;
    margin: 0;
    padding: 0 10px;
    overflow: hidden;
    position: relative;
    background: #fff;
    border-bottom: $borderDashed;
    /* box-shadow: 0 1px 2px rgba(0,21,41,.08); */

    .navbar-icon {
        /* padding-left: 15px; */
        float: left;
        height: $menuItemHeight;
        line-height: $menuItemHeight;
        color: var(--subjectColor);
        margin-right: 8px;
        .icon-style {
            // background: $light-blue;
            border-radius: 3px;
            font-size: 12px;
            display: block;
            width: 18px;
            line-height: 18px;
            position: relative;
            text-align: center;
            top: 16px;
        }
    }

    .right-menu {
        float: right;
        height: 100%;
        line-height: 48px;
        right: 7px;
        /* margin-right: 20px; */
        &:focus {
            outline: none;
        }

        .right-menu-item {
            display: inline-block;
            padding: 0 1px;
            font-size: 14px !important;
            color: #5a5e66;
            vertical-align: text-bottom;
            line-height: normal;
            height: auto;
            top: 50%;
            transform: translateY(-50%);

            &.hover-effect {
                cursor: pointer;
                transition: background 0.3s;

                &:hover {
                    background: rgba(0, 0, 0, 0.025);
                }
            }
        }
        &.wflow-title {
            color: #999;
            font-size: 12px;
            .title {
                position: relative;
                padding-right: 10px;
                margin-right: 10px;
                &::after {
                    position: absolute;
                    content: '';
                    height: 100%;
                    border-right: 1px solid #999;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
}
</style>
