<template>
  <div class="app-container">
    <menubar />
    <el-row class="min-clr-wr">
      <mini-calendar :father-month="1" />
      <mini-calendar :father-month="2" />
      <mini-calendar :father-month="3" />
      <mini-calendar :father-month="4" />
      <mini-calendar :father-month="5" />
      <mini-calendar :father-month="6" />
      <mini-calendar :father-month="7" />
      <mini-calendar :father-month="8" />
      <mini-calendar :father-month="9" />
      <mini-calendar :father-month="10" />
      <mini-calendar :father-month="11" />
      <mini-calendar :father-month="12" />
    </el-row>
  </div>

</template>

<script>
import Menubar from './components/Menubar.vue'
import MiniCalendar from './components/MiniCalendar.vue'
export default {
    components: {
        Menubar,
        MiniCalendar
    },
    props: {

    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        this.$bus.emit('showNavSearch', false)
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true)
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
    .min-clr-wr{
        padding-top: 6px;
        border-top: 1px solid #eaeaea;
    }
    .min-clr-wr:after{
        clear: both;
        display: block;
        content: "";
    }
    /deep/.minicalendar{
        float: left;
        padding: 4px;
        table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            font-size: 12px;
            font-family: "微软雅黑";
            tr {
                th{
                    padding: 3px 5px;
                    position: relative;
                    line-height: 28px;
                    text-align: center;
                    border: 1px solid #eaeaea;
                    font-size: 14px;
                    background-color: #fff;
                    color: #65A0CE;
                }
                td{
                    padding: 3px 5px;
                    line-height: 22px;
                    border: 1px solid #eaeaea;
                }
            }
        }
        .week-title{
            font-weight: 700;
            color: #666;
            background-color: #f8f8f8;
            td {
                line-height: 28px;
            }
        }
        .date{
            cursor: pointer;
            color: #000000;
            &:hover {
                outline :1px solid #65A0CE;
            }
        }
        .active{
            color: #ff0000;
        }
    }
</style>
