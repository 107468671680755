<template>
    <div class="xmgl_detail">
        <div class="sidebar-logo-container">
            <div class="sidebar-title" style="white-space: nowrap">考勤汇总表明细2</div>
        </div>
        <div class="main-container">
            <div class="table-box">
                <el-table class="custom-table" style="width: 100%" border :data="dataList2">
                    <el-table-column label="序号" width="45" type="index" align="center">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">
                                {{ scope.row.index }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="姓名" width="80" align="center">
                        <template slot-scope="scope">
                            <div>{{ scope.row.staffName }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="科室" width="80" align="center">
                        <template slot-scope="scope">
                            <div>{{ scope.row.staffDept }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="迟到(次)" width="80" align="center" prop="cdNum">
                        <template slot-scope="scope">
                            <div>{{ scope.row.cdNum }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="早退(次)" width="80" align="center" prop="ztNum">
                        <template slot-scope="scope">
                            <div>{{ scope.row.ztNum }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="旷工(天)" width="80" align="center" prop="kgNum">
                        <template slot-scope="scope">
                            <div>{{ scope.row.kgNum }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column v-for="(option, optionkey) in dataList.top" :key="optionkey" align="center" header-align="center" :label="option + '天'" width="80" :prop="propValue" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>
                                {{ dataList2[scope.$index].passenger.list.find((item) => item.typeName === option)?.day || 0 }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="出勤（天）" width="100" align="center" prop="cqNum">
                        <template slot-scope="scope">
                            <div>{{ scope.row.cqNum }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="备注">
                        <template slot-scope="scope">
                            <div>{{ scope.row.remark }}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    components: {},
    props: {},
    data() {
        return {
            showedit: true,
            dataList: [],
            value: '',
            time: '',
            selectDept: '',
            content: '',
            searchType: [
                {
                    type: 'subOrg',
                    title: '登记科室'
                }
            ],
            dataList2: [],
            dataList3: [],
            inputValue: '',
            saveList: [],
            count: [],
            menuActive: '',
            projectVo: {
                id: this.$route.query.busiDataId,
                name: ''
            },
            // 登录人角色
            rolesList: this.$store.state.user.roles,
            isAdmin: false
        }
    },
    computed: {
        ...mapGetters(['loginUser'])
    },
    watch: {},
    created() {},
    beforeDestroy() {},
    mounted() {
        const cookieStyle = this.Cookies.get('uiStyle')
        let style = ''
        if (cookieStyle !== this.loginUser.uiStyle) {
            style = this.loginUser.uiStyle
            this.Cookies.set('uiStyle', style)
        } else {
            style = cookieStyle
        }
        document.querySelector('body').setAttribute('id', this.loginUser.orgCode !== 'plat' ? style : 'skinScienceBlue')
        this.nickname = this.loginUser.nickname
        if (this.rolesList && this.rolesList.includes('ecpmAdmin')) {
            this.isAdmin = true
        }
        const pbNy = this.$route.query.pbNy
        const hzDate = this.$route.query.hzDate
        const staffDeptId = this.$route.query.staffDeptId
        this.getshuju(pbNy, hzDate, staffDeptId)
        console.log(pbNy, hzDate, staffDeptId)
    },
    methods: {
        getshuju(pbNy, hzDate, staffDeptId) {
            const data = {
                pbNy: pbNy,
                hzDate: hzDate,
                staffDeptId: staffDeptId
            }
            this.$http({
                method: 'POST',
                url: '/yykq/kqhz/AttendanceSummary',
                contentType: 'application/x-www-form-urlencoded',
                params: data
            }).then((res) => {
                if (res.code === 'success') {
                    console.log('res', res)
                    this.dataList = res.body.list
                    this.dataList2 = this.dataList.list
                    this.dataList.top = []
                    this.dataList2[0].passenger.list.forEach((item) => {
                        this.dataList.top.push(item.typeName)
                    })
                }
                this.dataList3 = JSON.parse(JSON.stringify(this.dataList2))
                this.dataList2.forEach((item, index) => {
                    item.index = index + 1
                })

                const obj = {
                    cdNum: 0,
                    cqNum: '',
                    gcNum: 0,
                    kgNum: 0,
                    passenger: {
                        list: []
                    },
                    ztNum: 0,
                    index: '合计'
                }

                this.dataList3[0].passenger.list.forEach((item) => {
                    obj.passenger.list.push(item)
                })

                obj.passenger.list.forEach((item) => {
                    let dataNum = 0
                    this.dataList3.forEach((val) => {
                        val.passenger.list.forEach((val1) => {
                            if (val1.typeName === item.typeName) {
                                dataNum += val1.day
                            }
                        })
                    })
                    item.day = dataNum
                })

                this.dataList3.forEach((item) => {
                    obj.cdNum += item.cdNum
                    obj.ztNum += item.ztNum
                    obj.kgNum += item.kgNum
                })

                this.dataList2.push(obj)

                console.log(this.dataList2, this.dataList3, 9999)
            })
        },
        getSum() {}
    }
}
</script>

<style scoped lang="scss">
@import '~@/wapp/common/styles/variables.module.scss';
.xmgl_detail {
    .sidebar-logo-container {
        // background: #354052;
        // background:var(--textColor);
        // background:$dark-menu-bgcolor;
        // color: #cad2db;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0 20px;

        .sidebar-title {
            color: #f9fafc;
            font-weight: 400;
            line-height: 29px;
            font-size: 22px;
        }
        .sidebar-divide {
            border-right: 1px solid #ccc;
            margin: 0 10px;
            height: 22px;
        }
        .sidebar_title_project {
            padding-top: 8px;
            font-size: 16px;
            line-height: 21px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .sidebar-container {
        overflow-y: auto !important;
        .el-menu-item {
            border-bottom: 1px solid #ececec;
        }
    }
    .menu-container {
        /deep/ .el-tabs__header {
            margin-bottom: unset;
        }
        /deep/.el-tabs__item {
            padding: 0 20px !important;
        }
    }
    .main-container {
        overflow: auto;
        padding: 20px 10px;
        margin-top: unset !important;
        margin-left: unset !important;
        height: calc(100vh - 100px) !important;
        min-height: calc(100vh - 100px) !important;
        .main-content {
            height: 100%;
        }
    }
}
.sidebar-logo-container {
    background: $headerBackground;
}
.sidebar-logo-container {
    background: var(--bgColor);
    color: var(--textColor);
}
.el-table {
    overflow: hidden;
}
/deep/.el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: auto !important;
}

/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    background-color: rgba(248, 247, 247, 0.87) !important;
}

/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
    margin: 0 2px !important;
    border-radius: 6px !important;
    background-color: rgba(199, 199, 199, 0.65) !important;
}

/deep/ .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: hsla(240, 1%, 35%, 0.418) !important;
}
/deep/.el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: auto !important;
}
.el-table::before {
    height: 0px;
}

.custom-table .el-table tr:last-child td {
    border: none !important;
}
</style>
