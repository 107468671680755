<template>
  <div>
    <div v-if="canEdit" class="left-cont">
      <validation-provider v-slot="{ errors }" :name="message" :rules="{ required: required,max:10 }">
        <el-tooltip :disabled="errors===undefined||errors[0]===undefined" :content="errors===undefined?'':errors[0]" :class="{'invalid-field':errors!==undefined&&errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
          <el-input v-model="vo[field]" :name="field" :placeholder="'请输入'+message" />
        </el-tooltip>
      </validation-provider>
    </div>
    <div v-else class="left-cont">{{ vo[field] }}</div>
    <div class="compute-unit">
      <span v-if="vo.computeUnit===1">分钟</span>
      <span v-if="vo.computeUnit===2">小时</span>
      <span v-if="vo.computeUnit===3">天</span>
    </div>
  </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        vo: { type: Object, default: function() { return {} } },
        canEdit: { type: Boolean, default: function() { return true } },
        required: { type: Boolean, default: function() { return true } },
        field: { type: String, default: function() { return '' } },
        message: { type: String, default: function() { return '' } }
        // filterHoliday: { type: Number, default: function() { return 0 } } // 当请假申请选择的请假类型不排除节假日的时候，需要把工作时长的单位改成“天”
    },
    data() {
        return {
            filterHoliday: 1 // 当请假申请选择的请假类型不排除节假日的时候，需要把工作时长的单位改成“天”
        }
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
    .left-cont {
        float: left;
        margin-right: 10px;
    }
    .compute-unit {
        float: left;
    }
</style>
