<template>
  <div style="position:relative;overflow-x:auto">
    <!-- 最外层table -->
    <div class="out_table_box">
      <table class="table" border="0" cellspacing="0" cellpadding="0">
        <!-- 标题 -->
        <thead>
          <tr>
            <th style="width:181px"><div class="cell">场馆</div></th>
            <template v-for="(item,itemKey) in listdata">
              <th :key="itemKey" :class="{'current':item.date===todayFirst&&item.type==='week'}" :style="{'text-align':item.type==='day'?'left':''}">
                <div class="cell" :style="{'padding-left':(item.type==='day'&&itemKey===0)?'15px':''}">{{ item.showdate }}</div>
              </th>
            </template>
          </tr>
        </thead>
        <!-- 表格 -->
        <tbody class="out_tbody">
          <!-- 根据会议室循环 一个会议室一行-->
          <template v-for="(dataTr,trIndex) in tabledata">
            <tr :key="trIndex">
              <td :colspan="listdata.length + 1">
                <!-- 根据会议室遍历生成底层表格 -->
                <div class="ground_div">
                  <!-- 底层虚拟表格 -->
                  <table class="virtual_table" cellspacing="0" cellpadding="0" :style="rowStyle" style="height:140px">
                    <tbody>
                      <tr>
                        <td style="width:181px">
                          <span v-if="dataTr.approve==0"><img style="height:16px;vertical-align:text-bottom" src="images/w-imgs/hygl/hysyd.png" alt=""> {{ dataTr.name }}</span>
                          <span v-else>{{ dataTr.name }}</span>
                        </td>
                        <template v-for="(dateTd,tdIndex) in dataTr.tdData">
                          <td :key="tdIndex" @click="tableCellClick(dataTr,dateTd)" />
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- 视图展示表格 -->
                <div class="view_div">
                  <table class="view_table" cellspacing="0" cellpadding="0">
                    <tbody>
                      <template v-for="(viewTr,viewTrIndex) in dataTr.viewTr">
                        <tr v-if="viewTrIndex < 5" :key="viewTrIndex">
                          <!-- <td>{{ dataTr.name }}</td> -->
                          <template v-for="(viewTd,viewTdIndex) in viewTr.weekday">
                            <td :key="viewTdIndex" :colspan="viewTd.viewData ? viewTd.viewData.currentDate : 1" @click="tableCellClick(dataTr,viewTd)">
                              <div v-if="viewTd.viewData" class="inter-bank-inner" @click="tableCellInnerClick(viewTd.viewData)" @click.stop>
                                <el-tooltip :popper-class="'tooltip_a'" class="item" effect="light" placement="left">
                                  <div>
                                    <slot :vo="viewTd.viewData" name="inner" />
                                  </div>
                                  <div slot="content">
                                    <slot :vo="viewTd.viewData" name="tip" />
                                  </div>
                                </el-tooltip>
                              </div>
                            </td>
                          </template>
                        </tr>
                        <tr v-else-if="viewTrIndex >= 5" :key="viewTrIndex">
                          <template v-for="(viewTd,viewTdIndex) in viewTr.weekday">
                            <td :key="viewTdIndex" @click="tableCellClick(dataTr,viewTd)">
                              <div style="margin:3px 5px 0;cursor: pointer;width:30px" @click.stop>
                                <el-tooltip v-if="viewTd.viewData && viewTd.viewData.length > 0" :popper-class="'tooltip_a'" class="item" effect="light" placement="bottom-start">
                                  <div>
                                    <span>
                                      <i class="el-icon-more" />
                                    </span>
                                  </div>
                                  <div slot="content">
                                    <template v-for="(more,moreIndex) in viewTd.viewData">
                                      <div :key="moreIndex">
                                        <slot :vo="more" name="moretip" />
                                      </div>
                                    </template>
                                  </div>
                                </el-tooltip>
                              </div>
                            </td>
                          </template>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </template>
          <tr v-if="tabledata.length===0">
            <td style="border-left: 1px solid #EBEEF5;border-bottom:1px solid #EBEEF5;border-right:1px solid #EBEEF5;line-height:60px;text-align:center;color:#909399" :colspan="listdata.length + 1">暂无数据</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
const mometn = require('moment')
export default {
    components: {

    },
    props: {
        listdata: { type: Array, default: function() { return [] } },
        tabledata: { type: Array, default: function() { return [] } },
        rowStyle: { type: Function, default: function() { return null } },
        tableCellClick: { type: Function, default: function() { return null } },
        tableCellInnerClick: { type: Function, default: function() { return null } }
    },
    data() {
        return {
            today: '',
            todayFirst: '',
            monday: '',
            mondayFirst: '',
            isScroll: false
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        console.log('tabledata111', this.tabledata)
        this.todayFirst = new Date(mometn(new Date()).format('YYYY-MM-DD')).getTime()
    },
    beforeDestroy() {
    },
    mounted() {

    },
    methods: {
        /* rowStyle({ row, rowIndex }) {
            const stylejson = {}
            stylejson.height = '140px !important'
            stylejson.overflow = 'hidden'
            return stylejson
        } */
    }
}
</script>

<style scoped lang="scss">
@import "~@/wapp/common/styles/element-variables.scss";

.el-button {
    font-size: 16px;
    padding: 0px 6px;
    }
/deep/ .has-gutter{
/deep/ tbody{
  .el-table__row:hover{
    background-color: #FFF;
  }
}
}
 /deep/ .current{
      background: #409EFF;
      color:#FFFFFF
    }
.hysTd{
  cursor: pointer;
  background:  $--color-success;
  color:#FFFFFF;
  border-top:1px solid #ffffff;
  height:25px;
  text-align: left;
  line-height: 25px;
  overflow: hidden;
}

.out_table_box{
  .table{
    width: 100%;
    //border-collapse:collapse;
    //border-spacing:0;
    font-size:13px ;
    table-layout: fixed;
    .cell{padding:0 5px;}
    thead{
      tr{
        th{
          border-left: 1px solid #E0E0E0;
          border-top:1px solid #E0E0E0;
          border-bottom:1px solid #E0E0E0;
          color: #909399;
          white-space: pre-line !important;
          .call{
            position: relative;
            word-wrap: normal;
            text-overflow: ellipsis;
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0px 5px !important;
            width: 100% !important;
          }
        }
        th:last-child{border-right:1px solid #E0E0E0;}
        .current{
          background: #409EFF;
          color:#fff
        }
      }
    }
    .out_tbody>tr>td{
        position: relative;
        padding: 0;
      }
    .out_tbody{
      .ground_div{
        .virtual_table{
          //border-collapse:collapse;
          //border-spacing:0;
          width: 100%;
          table-layout: fixed;
          tbody {
            tr{
              td{
                border-left: 1px solid #E0E0E0;
                border-bottom:1px solid #E0E0E0;
                min-width: 0;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                text-overflow: ellipsis;
                vertical-align: middle;
                position: relative;
                text-align: left;
                padding: 10px 5px;
                color: #606266;
              }
              td:last-child{border-right:1px solid #E0E0E0;}
            }
          }
        }
      }
      .view_div{
        position: absolute;
        width: calc(100% - 181px);
        top: 0;
        left: 181px;
        // height: 100%;
        .view_table{
          width: 100%;
          table-layout: fixed;
          tbody{
            tr{
              td{
                // position: relative;
                .inter-bank-inner{
                  margin: 5px 5px 0;
                  margin-top:3px ;
                  cursor: pointer;
                  height: 32px;
                  /deep/ span{
                    color: #FFF;
                    font-size:14px ;
                    line-height: 32px;
                    padding: 0 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.tooltip_a{
  width: 250px;
  box-shadow: 5px 5px 5px #909399 !important;
}
</style>
