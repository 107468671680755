
<template>
  <div class="ec-sp-form ec-d-form bc-content container" style="width: 931px; margin: 0 auto;">
    <table border="0" cellpadding="0" cellspacing="0" style="width: 100%;">
      <tr>
        <td colspan="6" style="width: 100%; height: auto;">
          <table class="head-table" border="0" cellpadding="0" cellspacing="0" style="width: 100%;">
            <tr>
              <td class="el-table__cell tdlbl td-col-l">班组名称：</td>
              <td class="el-table__cell tdcont td-col-m form-group">
                <div class="td-inner">
                  <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="请输入班组名称" rules="required">
                    <el-tooltip :disabled="errors[0]===undefined||khide" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                      <el-input ref="groupName" v-model="vo.groupName" v-can-edit :readonly="!canEdit" placeholder="请输入班组名称" type="text" name="groupName" autocomplete="off" />
                    </el-tooltip>
                  </ValidationProvider>
                </div>
                <span v-if="canEdit" class="field-tip">最多10个字符，中英文或者数字</span>
              </td>
            </tr>
            <tr>
              <td class="el-table__cell tdlbl td-col-l">起止时间：</td>
              <td class="el-table__cell tdcont td-col-m form-group">
                <div class="td-inner">
                  <el-row>
                    <el-col :span="11">
                      <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="请选择启用日期" rules="required">
                        <el-tooltip :disabled="errors[0]===undefined||khide" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                          <el-date-picker v-model="vo.startDate" :readonly="!canEdit" name="startDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="启用日期" :clearable="false" />
                        </el-tooltip>
                      </ValidationProvider>
                    </el-col>
                    <el-col :span="2" style="height: 35px;line-height: 35px;text-align: center;">至</el-col>
                    <el-col :span="11" style="text-align: right;">
                      <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="请选择停用日期" rules="required">
                        <el-tooltip :disabled="errors[0]===undefined||khide" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                          <el-date-picker v-model="vo.endDate" :readonly="!canEdit" name="endDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="停用日期" :clearable="false" />
                        </el-tooltip>
                      </ValidationProvider>
                    </el-col>
                  </el-row>
                </div>
              </td>
            </tr>
            <tr class="trmh">
              <td class="el-table__cell tdlbl td-col-l">参与考勤人员：</td>
              <td class="el-table__cell tdcont td-col-m form-group">
                <div class="td-inner">
                  <el-radio-group v-model="vo.userType" :disabled="!canEdit" name="userType">
                    <el-radio :label="1">全部</el-radio>
                    <el-radio :label="2">指定群组</el-radio>
                    <el-radio :label="3">指定部门</el-radio>
                  </el-radio-group>
                </div>
              </td>
            </tr>
            <tr v-if="vo.userType == 2" class="trmh">
              <td class="el-table__cell tdlbl td-col-l">适用群组：</td>
              <td class="td-col-m form-group">
                <div class="td-inner">
                  <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="适用群组" rules="required">
                    <el-tooltip :disabled="errors[0]===undefined||khide" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                      <el-input :key="'qunzu'" v-model="vo.userName" v-select-dialog="'workgroup'" v-can-edit placeholder="请选择工作群组" name="workgroup" />
                    </el-tooltip>
                  </ValidationProvider>
                </div>
              </td>
            </tr>
            <tr v-if="vo.userType == 3" class="trmh">
              <td class="el-table__cell tdlbl td-col-l">适用部门：</td>
              <td class="td-col-m form-group">
                <div class="td-inner">
                  <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="适用部门" rules="required">
                    <el-tooltip :disabled="errors[0]===undefined||khide" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                      <el-input :key="'bumen'" v-model="vo.userName" v-select-dialog="'suborg'" v-can-edit org-level="2,3" dialog-title="选择部门" multiple="true" placeholder="请选择部门" name="userName" />
                    </el-tooltip>
                  </ValidationProvider>
                </div>
              </td>
            </tr>
            <tr class="trmh">
              <td class="el-table__cell tdlbl td-col-l" style="vertical-align: top; line-height: 32px;">无需考勤人员：</td>
              <td class="td-col-m form-group">
                <div class="td-inner">
                  <el-input v-model="vo.uncheck" v-select-dialog="'staff'" v-can-edit type="text" :row="2" multiple="true" placeholder="请选择" name="uncheck" resize="none" />
                </div>
              </td>
            </tr>
            <tr class="trmh">
              <td class="el-table__cell tdlbl td-col-l">排班方式：</td>
              <td class="el-table__cell tdcont td-col-m form-group">
                <div class="td-inner">
                  <el-radio-group v-model="vo.schWay" :disabled="!canEdit" name="schWay">
                    <el-radio :label="1">固定班次</el-radio>
                    <el-radio :label="2">自定义班次</el-radio>
                  </el-radio-group>
                </div>
              </td>
            </tr>
            <tr v-if="vo.schWay == 1" class="trmh">
              <td class="el-table__cell tdlbl td-col-l">排班周期：</td>
              <td class="el-table__cell tdcont td-col-m form-group">
                <div class="td-inner">
                  <el-radio-group v-model="vo.cycleType" :disabled="!canEdit" name="cycleType">
                    <el-radio :label="1">周（以周为单位循环）</el-radio>
                    <el-radio :label="2">月（以月为单位循环）</el-radio>
                  </el-radio-group>
                </div>
              </td>
            </tr>
            <tr v-if="canEdit" class="trmh">
              <td class="el-table__cell tdlbl td-col-l">考勤时间段：</td>
              <td class="el-table__cell tdcont td-col-m form-group">
                <div class="td-inner">
                  <button type="button" class="add-time-btn" @click="addBc()">+ 添加考勤时段</button>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <el-button v-if="canEdit" plain style="float: right; margin-bottom: 10px;" @click="clearSd();">批量清除时段</el-button>
          <div style="clear: both;visibility: hidden;" />
          <table class="time-line" cellspacing="0" cellpadding="0" style="">
            <thead>
              <tr>
                <th class="t-head gray-box">
                  <span class="cycle-type">{{ (vo.schWay == 1 && vo.cycleType == 2) || vo.schWay == 2 ? '日期' : '周' }}</span>
                  <div class="bias-line" />
                  <span class="bc-time">时间</span>
                </th>
                <template v-for="i in hourArr">
                  <th :key="i" class="gray-box" style="text-align: center; width: 32px;">{{ i }}</th>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, $index) in vo.passenger.resAry" :key="$index">
                <td class="gray-box">
                  <div style="width: 100%; max-width: 58px; height: 100%; line-height: 31px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;font-size: 11px;">{{ item.cycleTimeShow || item.cycleTime }}</div>
                </td>
                <td v-if="$index == 0" colspan="25" :rowspan="vo.passenger.resAry.length+1">
                  <ul class="time-span-main">
                    <li v-for="(item2, $pi) in vo.passenger.resAry" :key="$pi">
                      <div class="grid-row">
                        <b v-for="i in hourArr" :key="'h'+i" class="grid" />
                      </div>
                      <template>
                        <div v-for="tsi in item2.timeSpanIds" :key="tsi" :style="{'left': tsi.posX * 32 + 'px', 'width': tsi.len * 32 + 'px', 'background-color': tsi.color ? tsi.color : 'lightblue'}" class="time-span-box" @click="editBc($pi)">
                          <el-tooltip
                            :content="(tsi.workOrRest == 1 ? '工作日排班： ' : tsi.workOrRest == 2 ? '休息日排班： ' : '') + ' 时间段 ' + tsi.logonTime + ' - ' + tsi.logoffTime"
                            placement="top"
                            :enterable="false"
                            effect="light"
                            popper-class="time-span-tip"
                          >
                            <div class="show-timespan" style="">
                              <span>{{ tsi.logonTime }} - {{ tsi.logoffTime }}&nbsp;&nbsp;{{ tsi.workOrRest === 1 ? '工' : (tsi.workOrRest === 2 ? '休' : '') }}</span>
                            </div>
                          </el-tooltip>
                        </div>
                      </template>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr class="trmh">
        <td colspan="3">
          <el-row>
            <el-col :span="4" class="" style="line-height: 20px;">法定节日自动排休:</el-col>
            <el-col :span="16" class="lk m1">
              <el-switch v-model="vo.holidayRest" :disabled="!canEdit" active-color="#13ce66" inactive-color="#ff4949" name="o" :active-value="1" :inactive-value="2" />
              <span style="color:#c1c1c1;margin-left:10px">开启后法定节日不再排班（周六、周日不属于法定节日）</span>
            </el-col>
          </el-row>
        </td>
      </tr>
      <tr>
        <td class="el-table__cell tdlbl td-col-l" style="vertical-align: top;">
          <div style="line-height: 40px;">考勤方式:</div>
        </td>
        <td class="el-table__cell tdcont td-col-l1" colspan="3">
          <div v-if="false" style="height: 40px; line-height: 40px;">
            <el-checkbox v-model="vo.mobileSign" :disabled="!canEdit" name="mobileSign"><span style="padding-left: 4px;">移动定位打卡</span><small>（可以添加多个考勤地点）</small></el-checkbox>
          </div>
          <el-row v-if="vo.mobileSign&&false">
            <el-col :span="8">
              <button
                class="add-time-btn"
                style="width: 200px;"
                :disabled="!canEdit"
                @click.prevent="selectLocation()"
              >添加考勤地点</button>
            </el-col>
          </el-row>
          <el-row v-if="vo.mobileSign">
            <el-col :span="20">
              <div class="kqscope-outer">
                <table class="kqscope">
                  <tbody>
                    <tr>
                      <td style="font-weight: 600;">考勤地址</td>
                      <td style="width: 122px; text-align: center; font-weight: 600;">有效范围</td>
                      <td style="width: 122px; text-align: center; font-weight: 600;">操作</td>
                    </tr>
                    <tr v-for="(item, i) in personSchAddressList" :key="i">
                      <td>{{ item.unitAddress }}</td>
                      <td style="text-align: center;">{{ item.gpsScope }}米</td>
                      <td style="text-align: center;">
                        <el-button type="text" @click="delAddressLi(i)">删除</el-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" class="">
              <div style="height: 40px; line-height: 40px;">
                <el-checkbox v-model="vo.officeSign" :disabled="!canEdit" name="officeSign">
                  <span style="padding-left: 4px;">办公室打卡</span>
                  <small>开启后，在办公室电脑端也可以打卡</small>
                </el-checkbox>
              </div>
              <div style="height: 40px; line-height: 40px;">
                <el-checkbox v-model="vo.outWorkSign" :disabled="!canEdit" name="outWorkSign">
                  <span style="padding-left: 4px;">开启外勤打卡</span>
                  <small>开启后，外勤打卡功能可以使用</small>
                </el-checkbox>
              </div>
              <div v-if="vo.outWorkSign" style="line-height: 40px; padding-left: 24px;">
                <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="外勤打卡方式" rules="required">
                  <el-tooltip :disabled="errors[0]===undefined||khide" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                    <el-radio-group v-model="vo.outWorkSignType">
                      <el-radio :disabled="!canEdit" name="outWorkSignType" :label="1">
                        <span>外勤打卡需身份确认</span>
                        <small>（身份确认的方式是人脸识别）</small>
                      </el-radio>
                      <br>
                      <el-radio style="margin-top:10px" :disabled="!canEdit" name="outWorkSignType" :label="2">
                        <span>外勤打卡需拍照和填写备注</span>
                        <small />
                      </el-radio>
                    </el-radio-group>
                  </el-tooltip>
                </ValidationProvider>
              </div>
            </el-col>
          </el-row>
        </td>
      </tr>
    </table>
    <template>
      <bc-set-dialog ref="bcSet" @sureBcSet="sureSelectedBc" />
    </template>
    <Mapee v-model="mapDialog" :vo="vo" :gps-scope-list="gpsScopeList" @mapSure="selectedAddrEvent" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { hourArr, gpsScopeList, monthArr, week, weeks } from '../utils.js'
import { deepClone } from '@/global/utils'
import Mapee from './Map/index'
import BcSetDialog from './BcSet/index'

export default {
    name: 'FormNewAdd',
    components: { Mapee, BcSetDialog },
    props: {
        vo: {
            type: Object,
            default() {
                return {}
            }
        },
        canEdit: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            dialogShow: false,
            mapDialog: false,
            hourArr,
            gpsScopeList,
            monthArr,
            week,
            weeks,
            backvo: deepClone(this.vo),
            resAry: [],
            personSchAddressList: []
        }
    },
    computed: {
        ...mapGetters(['loginUser'])
    },
    watch: {
        'vo.schWay': {
            handler(newValue, oldValue) {
                const $scope = this
                if (newValue === oldValue) return
                if (newValue === 2) {
                    if (!$scope.vo.startDate || !$scope.vo.endDate) {
                        $scope.$alert('请先选择起止日期', '提示')
                        $scope.vo.schWay = 1
                        return
                    }
                }
                this.computeTypes()
            },
            deep: true,
            immediate: true
        },
        'vo.cycleType': {
            handler(newValue, oldValue) {
                if (newValue === oldValue) return
                this.computeTypes()
            },
            deep: true,
            immediate: true
        },
        'vo.startDate': {
            handler(newValue, oldValue) {
                const $scope = this
                if (newValue === oldValue) return
                if ($scope.vo.endDate && this.compareDate(newValue, $scope.vo.endDate) > 0) {
                    $scope.vo.startDate = oldValue || ''
                    return $scope.$alert('启用日期必须早于停用日期', '提示')
                }
                if ($scope.vo.schWay === 2) {
                    this.computeTypes()
                }
            },
            deep: true,
            immediate: true
        },
        'vo.endDate': {
            handler(newValue, oldValue) {
                const $scope = this
                if (newValue === oldValue) return
                if ($scope.vo.startDate && this.compareDate($scope.vo.startDate, newValue) > 0) {
                    $scope.vo.endDate = oldValue || ''
                    return $scope.$alert('停用日期必须晚于启用日期', '提示')
                }
                if ($scope.vo.schWay === 2) {
                    this.computeTypes()
                }
            },
            deep: true,
            immediate: true
        }
    },
    beforeCreate() {

    },
    created() {
        const $scope = this
        const listAry = $scope.vo.schWay === 1 ? $scope.vo.cycleSchInfoList : $scope.vo.personSchInfoList
        this.initializeData(listAry)
        if (!$scope.vo.createDate) {
            $scope.vo.createDate = Vue.filter('formatTime')(new Date(), 'yyyy-MM-dd HH:mm')
        }
        $scope.vo.modifyDate = Vue.filter('formatTime')(new Date(), 'yyyy-MM-dd HH:mm')
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                document.querySelector('.basicform-panel').classList.add('kqgl_rypb')
            }, 0)
        })
    },
    methods: {
        // 初始化请求数据
        initializeData(listAry, callback) {
            const $scope = this
            if (listAry && listAry.length > 0) {
                const arr = listAry
                const ary = []
                const obj = {}
                let index = 0

                arr.forEach((v, i) => {
                    let lms = 0
                    let arySplit = 0
                    if (!obj[v.cycleTime] && obj[v.cycleTime] !== 0) {
                        const item = {}
                        obj[v.cycleTime] = index++
                        var regexp = /^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/
                        if ($scope.vo.schWay === 2 && regexp.test(v.cycleTime)) {
                            var dateArr = v.cycleTime.split('-')
                            item.cycleTimeShow = dateArr[1] + '月' + dateArr[2] + '日'
                        } else if ($scope.vo.schWay === 1 && $scope.vo.cycleType === 1) {
                            item.cycleTimeShow = $scope.week[parseInt(v.cycleTime) - 1]
                        } else if ($scope.vo.schWay === 1 && $scope.vo.cycleType === 2) {
                            item.cycleTimeShow = v.cycleTime + '日'
                        }
                        item.cycleTime = v.cycleTime

                        item.timeSpanIds = []
                        if (v.logonTime) {
                            lms = $scope.compareTimes(v.logonTime, v.logoffTime)
                            arySplit = v.logonTime.split(':')
                        }
                        item.timeSpanIds.push({
                            id: v.timeId,
                            name: v.timeName,
                            logonTime: v.logonTime,
                            logoffTime: v.logoffTime,
                            color: v.color,
                            len: lms / (60 * 60 * 1000),
                            posX: Number(parseInt(arySplit[0]) + '.' + parseInt(arySplit[1] * 1 / 6 * 100)),
                            workOrRest: v.workOrRest
                        })
                        item.usable = !!v.timeId
                        ary.push(item)
                    } else {
                        if (v.logonTime) {
                            lms = $scope.compareTimes(v.logonTime, v.logoffTime)
                            arySplit = v.logonTime.split(':')
                        }
                        ary[index - 1].timeSpanIds.push({
                            id: v.timeId,
                            name: v.timeName,
                            logonTime: v.logonTime,
                            logoffTime: v.logoffTime,
                            color: v.color,
                            len: lms / (60 * 60 * 1000),
                            posX: Number(parseInt(arySplit[0]) + '.' + parseInt(arySplit[1] * 1 / 6 * 100)),
                            workOrRest: v.workOrRest
                        })
                    }
                })
                // $scope.resAry = ary
                $scope.vo.passenger.resAry = ary
                // this.computePbListStyle(ary)
            }
            $scope.personSchAddressList = $scope.vo.personSchAddressList && $scope.vo.personSchAddressList.length > 0 ? $scope.vo.personSchAddressList : []
            typeof callback === 'function' && callback()
        },

        // 排班的方式类型
        computeTypes() {
            const $scope = this
            let arr = $scope.weeks
            if ($scope.vo.schWay === 1) {
                if ($scope.vo.cycleType === 1) {
                    arr = $scope.weeks
                }
                if ($scope.vo.cycleType === 2) {
                    arr = $scope.monthArr
                }
            } else if ($scope.vo.schWay === 2) {
                arr = this.renderDateList()
            }
            // $scope.resAry = arr
            $scope.vo.passenger.resAry = arr
            // this.computePbListStyle(arr)
        },
        // 计算渲染排班周期列表的数据
        // computePbListStyle(arr) {
        // const $scope = this
        // this.$bus.emit('bcSetEvent', $scope.vo.passenger.resAry)
        // },
        // 获取自定义日期列表
        renderDateList() {
            const $scope = this
            const dateList = []
            const startDate = $scope.vo.startDate
            const endDate = $scope.vo.endDate
            const startStr = startDate.replace(/-/g, '/')
            const endStr = endDate.replace(/-/g, '/')
            var startTimestamp = new Date(startStr).getTime()
            var endTimestamp = new Date(endStr).getTime()
            for (let t = startTimestamp; t <= endTimestamp; t += 24 * 60 * 60 * 1000) {
                const d = new Date(t)
                const dStr = d.getMonth() + 1 + '月' + d.getDate() + '日'
                const cycleTime = d.getFullYear() + '-' + ((d.getMonth() + 1).toString().padStart(2, '0')) + '-' + (d.getDate().toString().padStart(2, '0'))
                dateList.push({
                    cycleTimeShow: dStr,
                    cycleTime: cycleTime,
                    timeSpanIds: [],
                    usable: false
                })
            }
            return dateList
        },
        addBc() {
            // this.resAry.forEach(v => {
            //     const usable = false
            //     v.timeSpanIds = []
            //     v.usable = usable
            // })

            this.$bus.emit('bcSetDialogShow', { vo: this.vo, resAry: this.vo.passenger.resAry, formType: 'bcset' })
        },
        // 打开编辑考勤班次时段弹出框
        editBc($i) {
            if (!this.canEdit) { return }
            this.edit_i = $i
            this.$bus.emit('bcSetDialogShow', { vo: this.vo, resAry: this.vo.passenger.resAry, index: $i, formType: 'editBc' })
        },
        // 打开地图弹出框
        selectLocation() {
            if (!this.canEdit) { return }
            this.mapDialog = true
            this.$bus.emit('showMap')
        },
        // 清空时段
        clearSd() {
            const $scope = this
            if (!$scope.vo.passenger.resAry || $scope.vo.passenger.resAry.length <= 0) return
            $scope.vo.passenger.resAry.forEach((v, i) => {
                v.timeSpanIds = []
                v.usable = false
            })
            // this.$bus.emit('bcSetEvent', $scope.vo.passenger.resAry)
            this.$refs['bcSet'].clearSd()
        },
        // 时段选择确认
        sureSelectedBc(data) {
            data.resAry.map(item => {
                if (!item.usable) {
                    item.timeSpanIds = []
                }
            })
            this.vo.passenger.resAry = data.resAry
            this.$forceUpdate()
            // this.$bus.emit('bcSetEvent', data.resAry)
        },
        // 地图选择确认
        selectedAddrEvent(data) {
            this.personSchAddressList.push(data)
            this.vo.personSchAddressList = this.personSchAddressList
        },
        // 删除考勤地址
        delAddressLi($i) {
            if (!this.canEdit) return
            this.personSchAddressList.splice($i, 1)
            this.vo.personSchAddressList = this.personSchAddressList
        },
        //	两个日期间的大小
        compareDate(s, e) {
            const oDate1 = new Date(s)
            const oDate2 = new Date(e)
            return oDate1.getTime() - oDate2.getTime()
        },

        // 获取两个时间之间差值
        compareTimes(bt, et) {
            const strb = bt.split(':')
            if (strb.length !== 2) return 0
            const stre = et.split(':')
            if (stre.length !== 2) return 0

            const b = new Date()
            const e = new Date()

            b.setHours(strb[0])
            b.setMinutes(strb[1])
            e.setHours(stre[0])
            e.setMinutes(stre[1])
            return e.getTime() - b.getTime()
        },
        onSelectSuborgCompleted(val) {
            let name = ''
            let ids = ''
            val.forEach(v => {
                if (name !== '')name += '、'
                if (ids !== '') ids += ','
                name += v.name
                ids += v.id
            })
            this.vo.userName = name
            this.vo.userId = ids
        },
        onSelectWorkgroupCompleted(val) {
            let name = ''
            let ids = ''
            val.forEach(v => {
                if (name !== '')name += '、'
                if (ids !== '') ids += ','
                name += v.name
                ids += v.id
            })
            this.vo.userName = name
            this.vo.userId = ids
        }
    }
}
</script>
<style lang="scss">
.el-tooltip__popper.is-light.time-span-tip {
    border: none;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.5);
    & .popper__arrow::after {
      border-width: 6px;
      left: -1px;
      top: -6px;
    }
}
.basicform-panel.kqgl_rypb{
  &::before{display: none;}
  &::after{display: none;}
  .el-scrollbar__border{
     &::before{display: none;}
    &::after{display: none;}
  }
}
</style>
<style lang="scss" scoped>
$tl-border-color: #d8dce5;
table td {
  padding: 6px;
  height: 32px;
  border: 0;
}
table th {
  font-weight: normal;
  padding: 0 !important;
}
.head-table{
  border: 1px solid #EBEEF5;
  border-collapse:collapse;
  td{
    border: 1px solid #EBEEF5;
    height: 46px;
  }
  .tdlbl {
    text-align: right;
  }
  .tdcont {
    overflow: hidden;
    .field-tip {
      line-height: 36px;
      padding-left: 8px;
    }
  }
  .td-inner {
    float: left;
    width: 486px;
  }
}
label {
  padding-right: 12px;
}
.add-time-btn {
  border: none;
  background-color: #f2f2f2;
  outline: none;
  width: 100%;
  height: 35px;
  color: #666;
  &:hover {
    color: #333;
    background-color: #f8f8f8;
  }
}
.gray-box {
  background-color: #f8f8f8;
  text-align: center;
  border-bottom: 1px solid $tl-border-color;
  border-right: 1px solid $tl-border-color;
  color: #888;
  font-weight: bold;
}

.t-head {
  width: 59px;
  height: 30px;
  position: relative;
  font-size: 12px;
  .cycle-type {
    position: absolute;
    left: 2px;
    bottom: 0;
  }
  .bc-time {
    position: absolute;
    top: 2px;
    right: 2px;
  }
  .bias-line {
    width: 66px;
    height: 0;
    border-top: 1px solid $tl-border-color;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(26deg);
    transform-origin: 0 0;
  }
}
.trmh td {
  height: 46px;
  vertical-align: middle;
}
.tdlbl.td-col-l {
  width: 12.8%;
  font-weight: bold;
}
.tdcont.td-col-m {
  width: 58%;
  position: relative;
}
.time-line {
  border: 1px solid $tl-border-color; table-layout: fixed; word-break: break-word;border-right:none; border-bottom: none;
  td {
    padding: 0;
  }
  th {
    padding: 0;
    line-height: unset !important;
  }
}
.kqscope-outer {
  padding: 20px 0;
  .kqscope {
    width: 100%;
    table-layout: fixed;
    border: 1px solid #EBEEF5;
    border-collapse:collapse;
    td{
      border: 1px solid #EBEEF5;
    }
    tr {
      td {
        height: 40px;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
small {
  display: inline;
  color: #777777;
}
.time-span-main,
.time-span-main li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.time-span-main li {
  width: 100%;
  height: 32px;
  position: relative;
  .grid-row {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .grid {
      display: block;
      width: 32px;
      height: 32px;
      border-right: 1px solid $tl-border-color;
      border-bottom: 1px solid $tl-border-color;
      float: left;
      box-sizing: border-box;
    }
  }
}

.time-span-box {
  width: auto;
  height: 100%;
  box-shadow: 0 0 4px 0 #f2f2f2;
  position: absolute;
  top: 0;
  border-bottom: 1px solid #f2f2f2;
  .show-timespan {
    width: 100%; height: 100%; line-height: 32px; text-align: center; font-size: 12px; cursor: pointer; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;
  }
}
</style>
