<template>
  <el-form v-if="vo.id" :model="vo">
    <div style="width:100%;" class="el-table el-table--fit el-table--border">
      <div class="el-table__body-wrapper">
        <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
          <tbody>
            <tr>
              <td class="el-table__cell tdlbl tdw17" colspan="6">
                <span style="float:left;">
                  接报基本情况
                </span>
              </td>
            </tr>
            <tr>
              <td ref="tdlbl_接报日期" class="el-table__cell tdlbl tdw17" colspan="1">
                <span class="required">*</span>
                <span v-field-lbl="'reciveTime'" class="field">接报日期</span>
              </td>
              <td class="el-table__cell tdcont tdw83" colspan="5">
                <form-item ref="reciveTime" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':'','elementReadonly':1,'elementType':'date','emptyMessage':'请选择接报日期','errorMessage':null,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'接报日期','propName':'reciveTime','propType':'java.util.Date','textFormatter':'yyyy-MM-dd HH:mm','valueMaxLength':null,'valueMinLength':null,'tbFieldName':'DATE_73241','model':'vo.reciveTime','option':[],'formTdAlign':'text-left','weekday':0,'stepNum':5,'formTdColspan':24}" :vo="vo" :can-edit="canEdit" />
              </td>
            </tr>
            <tr>
              <td ref="tdlbl_告知人" class="el-table__cell tdlbl tdw17" colspan="1">
                <span class="required">*</span>
                <span v-field-lbl="'informer'" class="field">告知人</span>
              </td>
              <td class="el-table__cell tdcont tdw33" colspan="2">
                <form-item ref="informer" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'','errorMessage':null,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'告知人','propName':'informer','propType':'String','textFormatter':'textfield','valueMaxLength':100,'valueMinLength':null,'tbFieldName':'TEXTFIELD_56839','model':'vo.informer','option':[],'formTdAlign':'text-left','autocomplete':'off','showWordLimit':false,'formTdColspan':24}" :vo="vo" :can-edit="canEdit" />
              </td>
              <td ref="tdlbl_电话" class="el-table__cell tdlbl tdw17" colspan="1">
                <span class="required">*</span>
                <span v-field-lbl="'mobilePhone'" class="field">电话</span>
              </td>
              <td class="el-table__cell tdcont tdw33" colspan="2">
                <form-item ref="mobilePhone" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'','errorMessage':null,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'电话','propName':'mobilePhone','propType':'String','textFormatter':'textfield','valueMaxLength':100,'valueMinLength':null,'tbFieldName':'TEXTFIELD_56839','model':'vo.mobilePhone','option':[],'formTdAlign':'text-left','autocomplete':'off','showWordLimit':false,'formTdColspan':24}" :vo="vo" :can-edit="canEdit" />
              </td>
            </tr>
            <tr>
              <td ref="tdlbl_值班情况" class="el-table__cell tdlbl tdw17" colspan="1">
                <span class="required">*</span>
                <span v-field-lbl="'content'" class="field">值班情况</span>
              </td>
              <td class="el-table__cell tdcont tdw83" colspan="5">
                <form-item ref="content" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'','errorMessage':null,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'值班情况','propName':'content','propType':'String','textFormatter':'textfield','valueMaxLength':100,'valueMinLength':null,'tbFieldName':'TEXTFIELD_56839','model':'vo.content','option':[],'formTdAlign':'text-left','autocomplete':'off','showWordLimit':false,'formTdColspan':24}" :vo="vo" :can-edit="canEdit" />
              </td>
            </tr>
            <tr>
              <td class="el-table__cell tdlbl tdw17" colspan="6">
                <span style="float:left;">
                  突发事件现场情况
                </span>
              </td>
            </tr>
            <tr>
              <td ref="tdlbl_发生时间" class="el-table__cell tdlbl tdw17" colspan="1">
                <span class="required">*</span>
                <span v-field-lbl="'occurrenceTime'" class="field">发生时间</span>
              </td>
              <td class="el-table__cell tdcont tdw83" colspan="5">
                <!-- <form-item ref="occurrenceTime" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'','errorMessage':null,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'发生时间','propName':'occurrenceTime','propType':'String','textFormatter':'textfield','valueMaxLength':100,'valueMinLength':null,'tbFieldName':'TEXTFIELD_56839','model':'vo.occurrenceTime','option':[],'formTdAlign':'text-left','autocomplete':'off','showWordLimit':false,'formTdColspan':24}" :vo="vo" :can-edit="canEdit" /> -->
                <form-item ref="occurrenceTime" :field="{'defaultValue':null,'elementCanEdit':'{${canEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':'','elementReadonly':1,'elementType':'date','emptyMessage':'请选择发生时间','errorMessage':null,'formTdAlign':'text-left','formTdColspan':12,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'发生时间','propName':'occurrenceTime','propType':'java.util.Date','textFormatter':'yyyy-MM-dd HH:mm','valueMaxLength':null,'valueMinLength':null,'model':'vo.occurrenceTime','option':[],'weekday':0,'stepNum':5}" :vo="vo" :can-edit="canEdit" />
              </td>
            </tr>
            <tr>
              <td ref="tdlbl_发生地点" class="el-table__cell tdlbl tdw17" colspan="1">
                <span class="required">*</span>
                <span v-field-lbl="'occurrencePlace'" class="field">发生地点</span>
              </td>
              <td class="el-table__cell tdcont tdw83" colspan="5">
                <div style="display:flex;align-items: center;">
                  <el-select v-model="vo.occurrencePlaceEx" placeholder="请选择" :disabled="canEdit!==1||canEdit===0">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <form-item ref="occurrencePlace" style="margin-left:5px;" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'','errorMessage':null,'isRequired':1,'onClickEvent':null,'passenger':{},'propComment':'发生地点','propName':'occurrencePlace','propType':'String','textFormatter':'textfield','valueMaxLength':100,'valueMinLength':null,'tbFieldName':'TEXTFIELD_56839','model':'vo.occurrencePlace','option':[],'formTdAlign':'text-left','autocomplete':'off','showWordLimit':false,'formTdColspan':24}" :vo="vo" :can-edit="canEdit" />

                </div>
              </td>
            </tr>
            <tr>
              <td ref="tdlbl_周围环境 " class="el-table__cell tdlbl tdw17" colspan="1">
                <span class="field">周围环境 </span>
              </td>
              <td class="el-table__cell tdcont tdw83 sp-td" colspan="5" style="padding:0;border: none;">
                <div style="width:100%;border: none;" class="el-table el-table--fit el-table--border">
                  <div class="el-table__body-wrapper">
                    <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
                      <tbody>
                        <tr>
                          <td ref="tdlbl_水环境" class="el-table__cell tdlbl tdw17" colspan="1">
                            <span v-field-lbl="'waterEnvironment'" class="field">水环境</span>
                          </td>
                          <td class="el-table__cell tdcont tdw83" colspan="5">
                            <form-item ref="waterEnvironment" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'','errorMessage':null,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'水环境','propName':'waterEnvironment','propType':'String','textFormatter':'textfield','valueMaxLength':100,'valueMinLength':null,'tbFieldName':'TEXTFIELD_56839','model':'vo.waterEnvironment','option':[],'formTdAlign':'text-left','autocomplete':'off','showWordLimit':false,'formTdColspan':24}" :vo="vo" :can-edit="canEdit" />
                          </td>
                        </tr>
                        <tr>
                          <td ref="tdlbl_居民点" class="el-table__cell tdlbl tdw17" colspan="1">
                            <span v-field-lbl="'settlement'" class="field">居民点</span>
                          </td>
                          <td class="el-table__cell tdcont tdw83" colspan="5">
                            <form-item ref="settlement" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'','errorMessage':null,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'居民点','propName':'settlement','propType':'String','textFormatter':'textfield','valueMaxLength':100,'valueMinLength':null,'tbFieldName':'TEXTFIELD_56839','model':'vo.settlement','option':[],'formTdAlign':'text-left','autocomplete':'off','showWordLimit':false,'formTdColspan':24}" :vo="vo" :can-edit="canEdit" />
                          </td>
                        </tr>
                        <tr>
                          <td ref="tdlbl_其他敏感点" class="el-table__cell tdlbl tdw17" colspan="1">
                            <span v-field-lbl="'otherPoints'" class="field">其他敏感点</span>
                          </td>
                          <td class="el-table__cell tdcont tdw83" colspan="5">
                            <form-item ref="otherPoints" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'','errorMessage':null,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'其他敏感点','propName':'otherPoints','propType':'String','textFormatter':'textfield','valueMaxLength':100,'valueMinLength':null,'tbFieldName':'TEXTFIELD_56839','model':'vo.otherPoints','option':[],'formTdAlign':'text-left','autocomplete':'off','showWordLimit':false,'formTdColspan':24}" :vo="vo" :can-edit="canEdit" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td ref="tdlbl_事件类型" class="el-table__cell tdlbl tdw17" colspan="1">
                <span v-field-lbl="'eventType'" class="field">事件类型</span>
              </td>
              <td class="el-table__cell tdcont tdw83 sp-td" colspan="5" style="padding:0;border:none;">
                <div style="width:100%;border:none;" class="el-table el-table--fit el-table--border">
                  <div class="el-table__body-wrapper">
                    <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
                      <tbody>
                        <tr>
                          <td class="el-table__cell tdcont tdw100" colspan="6">

                            <form-item ref="eventType" :field="{'defaultValue':null,'elementCanEdit':'{{curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':'泄露：泄露\n交通事故：交通事故\n爆炸：爆炸\n违法倾倒污染物：违法倾倒污染物\n其他：其他','elementReadonly':0,'elementType':'checkbox','emptyMessage':'','errorMessage':null,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'事件类型','propName':'eventType','propType':'String','textFormatter':null,'valueMaxLength':100,'valueMinLength':null,'tbFieldName':'eventType','model':'vo.eventType','option':[{'value':'泄露','name':'泄露'},{'name':'交通事故','value':'交通事故'},{'name':'爆炸','value':'爆炸'},{'name':'违法倾倒污染物','value':'违法倾倒污染物'},{'name':'其他','value':'其他'}],'formTdAlign':'text-left','optionGenerateType':0,'clearable':true,'filterable':false,'allowCreate':false,'readonlyShowChecked':0,'formTdColspan':24}" :vo="vo" :can-edit="canEdit" />

                          </td>
                        </tr>
                        <tr>
                          <td ref="tdlbl_说明" class="el-table__cell tdlbl tdw17" colspan="1">
                            <span v-field-lbl="'eventComment'" class="field">说明</span>
                          </td>
                          <td class="el-table__cell tdcont tdw83" colspan="5">
                            <form-item ref="eventComment" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'','errorMessage':null,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'说明','propName':'eventComment','propType':'String','textFormatter':'textfield','valueMaxLength':100,'valueMinLength':null,'tbFieldName':'TEXTFIELD_56839','model':'vo.eventComment','option':[],'formTdAlign':'text-left','autocomplete':'off','showWordLimit':false,'formTdColspan':24}" :vo="vo" :can-edit="canEdit" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td ref="tdlbl_污染物情况" class="el-table__cell tdlbl tdw17" colspan="1">
                <span v-field-lbl="'pollutantType'" class="field">污染物情况</span>
              </td>
              <td class="el-table__cell tdcont tdw83  sp-td" colspan="5" style="padding:0;border:none;">
                <div style="width:100%;border:none;" class="el-table el-table--fit el-table--border">
                  <div class="el-table__body-wrapper">
                    <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
                      <tbody>
                        <tr>
                          <td class="el-table__cell tdcont tdw100" colspan="6">
                            <form-item ref="pollutantType" :field="{'defaultValue':null,'elementCanEdit':'{{curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':'液体：液体\n气体：气体\n固体：固体\n放射性：放射性\n气味：气味','elementReadonly':0,'elementType':'checkbox','emptyMessage':'','errorMessage':null,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'污染物情况','propName':'pollutantType','propType':'String','textFormatter':null,'valueMaxLength':100,'valueMinLength':null,'tbFieldName':'pollutantType','model':'vo.pollutantType','option':[{'value':'液体','name':'液体'},{'name':'气体','value':'气体'},{'name':'固体','value':'固体'},{'name':'放射性','value':'放射性'},{'name':'气味','value':'气味'}],'formTdAlign':'text-left','optionGenerateType':0,'clearable':true,'filterable':false,'allowCreate':false,'readonlyShowChecked':0,'formTdColspan':24}" :vo="vo" :can-edit="canEdit" />
                          </td>
                        </tr>
                        <tr>
                          <td ref="tdlbl_具体描述" class="el-table__cell tdlbl tdw17" colspan="1">
                            <span v-field-lbl="'pollutantComment'" class="field">具体描述</span>
                          </td>
                          <td class="el-table__cell tdcont tdw83" colspan="5">
                            <form-item ref="pollutantComment" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'textarea','emptyMessage':'请输入具体描述','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'具体描述','propName':'pollutantComment','propType':'String','textFormatter':'textarea','valueMaxLength':null,'valueMinLength':null,'model':'vo.pollutantComment','option':[],'autocomplete':'off','showWordLimit':false,'autosize':true}" :vo="vo" :can-edit="canEdit" :autosize="true" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td ref="tdlbl_现场人员伤亡情况" class="el-table__cell tdlbl tdw17" colspan="1">
                <span v-field-lbl="'casualties'" class="field">现场人员伤亡情况</span>
              </td>
              <td class="el-table__cell tdcont tdw33" colspan="2">
                <form-item ref="casualties" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'','errorMessage':null,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'现场人员伤亡情况','propName':'casualties','propType':'String','textFormatter':'textfield','valueMaxLength':100,'valueMinLength':null,'tbFieldName':'TEXTFIELD_56839','model':'vo.casualties','option':[],'formTdAlign':'text-left','autocomplete':'off','showWordLimit':false,'formTdColspan':24}" :vo="vo" :can-edit="canEdit" />
              </td>
              <td ref="tdlbl_造成损失情况" class="el-table__cell tdlbl tdw17" colspan="1">
                <span v-field-lbl="'lossSituation'" class="field">造成损失情况</span>
              </td>
              <td class="el-table__cell tdcont tdw33" colspan="2">
                <form-item ref="lossSituation" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'input','emptyMessage':'','errorMessage':null,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'造成损失情况','propName':'lossSituation','propType':'String','textFormatter':'textfield','valueMaxLength':100,'valueMinLength':null,'tbFieldName':'TEXTFIELD_56839','model':'vo.lossSituation','option':[],'formTdAlign':'text-left','autocomplete':'off','showWordLimit':false,'formTdColspan':24}" :vo="vo" :can-edit="canEdit" />
              </td>
            </tr>
            <tr>
              <td ref="tdlbl_事件现场情况说明" class="el-table__cell tdlbl tdw17" colspan="1">
                <span v-field-lbl="'situation'" class="field">事件现场情况说明</span>
              </td>
              <td class="el-table__cell tdcont tdw83" colspan="5">
                <form-item ref="situation" :field="{'defaultValue':null,'elementCanEdit':'{${curTN.tnCanEdit}}','elementCssHeight':null,'elementCssWidth':null,'elementFormatter':null,'elementReadonly':0,'elementType':'textarea','emptyMessage':'请输入事件现场情况说明','errorMessage':null,'formTdAlign':'text-left','formTdColspan':0,'isRequired':0,'onClickEvent':null,'passenger':{},'propComment':'事件现场情况说明','propName':'situation','propType':'String','textFormatter':'textarea','valueMaxLength':null,'valueMinLength':null,'model':'vo.flContent','option':[],'autocomplete':'off','showWordLimit':false,'autosize':true}" :vo="vo" :can-edit="canEdit" :autosize="true" />
              </td>
            </tr>
            <tr v-if="vo.passenger.attachments&&vo.passenger.attachments.length && !canEdit">
              <td class="el-table__cell tdlbl tdw17" colspan="1">
                <span class="field">现场照片</span>
              </td>
              <td class="el-table__cell tdcont tdw83" colspan="5">
                <div style="display: flex;flex-wrap: wrap;">
                  <el-image
                    v-for="(item,index) in vo.passenger.attachments"
                    :key="index"
                    style="height: 100px;"
                    :src="`/apigw/zbgl/reportRecord/downloadById?attachIds=${item.id}`"
                    :preview-src-list="[`/apigw/zbgl/reportRecord/downloadById?attachIds=${item.id}`]"
                  >
                  </el-image>

                </div>
              </td>
            </tr>
            <tr v-else>
              <td ref="tdlbl_现场照片" class="el-table__cell tdlbl tdw17">
                现场照片
              </td>
              <td class="el-table__cell tdcont tdcont" colspan="5">
                <Attachment ref="attachments" :vo="vo" :is-required="0" accept=".jpg,.png,.gif" empty-message="" wf-attach-url="zbgl/reportRecord" :limit-count="10" online-editor="" online-file="" :attachments="vo.passenger.attachments" :attachs-type="'ATTACH'" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="form-bottom">
      <div class="bc li">
        <span style="width:100px;font-size: 15px;">接报人签名：</span>
        <el-input v-if="canEdit" v-model="vo.creator" style="width:150px;" placeholder="请输入"></el-input>
        <span v-else style="display: inline-block; width: 120px;height: 36px;  border-bottom: 1px solid #DCDFE6;font-size: 14px; line-height: 36px; padding-left: 8px;">{{ vo.creatorSignature }}</span>
      </div>
      <div class="bc ri">
        <span style="width:100px;font-size: 15px;">填报时间：</span>
        <el-date-picker
          v-if="canEdit"
          v-model="vo.reciveTime"
          type="datetime"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          placeholder="请选择日期"
        >
        </el-date-picker>
        <span v-else style="display: inline-block;width: 220px;height: 36px;border-bottom: 1px solid #DCDFE6;font-size: 14px;line-height: 36px;padding-left: 8px;">{{ vo.reciveTime }}</span>
        <i class="el-icon-date dateicon"></i>
      </div>
    </div>
  </el-form>
</template>

<script>
export default {
    components: {

    },
    props: {
        vo: { type: Object, default: function() { return {} } },
        canEdit: { type: Number, default: 1 }
    },
    data() {
        return {
            refresh: false,
            imageUrl: '',
            loginUser: this.$store.getters.loginUser,
            fileList: [],
            attachList: [],
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            srcList: [
                'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
                'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
            ],
            options: [{
                value: '越秀区',
                label: '越秀区'
            }, {
                value: '荔湾区',
                label: '荔湾区'
            }, {
                value: '海珠区',
                label: '海珠区'
            }, {
                value: '天河区',
                label: '天河区'
            }, {
                value: '白云区',
                label: '白云区'
            }, {
                value: '黄埔区',
                label: '黄埔区'
            }, {
                value: '番禺区',
                label: '番禺区'
            }, {
                value: '花都区',
                label: '花都区'
            }, {
                value: '南沙区',
                label: '南沙区'
            }, {
                value: '增城区',
                label: '增城区'
            }, {
                value: '从化区',
                label: '从化区'
            }]
        }
    },
    computed: {
    },
    watch: {
        vo: {
            handler(newValue, oldValue) {
                this.refresh = true
                this.$nextTick(() => {
                    this.refresh = false
                })
            },
            deep: true
        }
    },
    created() {
        this.httpPort = location.port
        console.log('this.vo==', this.vo)
        if (this.vo.id) {
            if (!this.vo.passenger.attachments) this.vo.passenger.attachments = []
            // this.attachList = this.vo.passenger.attachments
        }
    },
    beforeDestroy() {

    },
    mounted() {
        if (!this.vo.creator) {
            this.vo.creator = this.loginUser.personName
        }
    },
    methods: {
        onBasicFormSubmited($scope, reloadList) {
        },
        onBasicFormBeforeSubmit($scope, vo, defer) {
            defer.resolve(true)
        }

    }
}
</script>

<style scoped lang="scss">

.form-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  .bc {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .dateicon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      color: #ccc;
    }
  }
  /deep/.li .el-input__inner{
    border: none;
    border-bottom: 1px solid #DCDFE6;
  }
  /deep/.ri .el-input__inner {
    border: none;
    border-bottom: 1px solid #DCDFE6;
    padding-left: 15px;
  }
  /deep/ .el-input__prefix {
    display: none;
  }
}
  /deep/.sp-td .el-table::before {
    background-color: #fff !important;
  }
  .el-input.is-disabled .el-input__inner{
		background-color: #FFF !important;
    border:none !important;;
		padding: 0 !important;;
		cursor: pointer !important;
		font-size:15px !important;
	}
  /deep/.el-table .el-input--prefix .el-input__inner{
    padding-left: 30px !important ;
    text-align: left !important;
    background-color: #fff;
}
</style>
