<template>
  <BatchProcessing />
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>
