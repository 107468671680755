import { render, staticRenderFns } from "./Jbb.vue?vue&type=template&id=4dc0c512&scoped=true"
import script from "./Jbb.vue?vue&type=script&lang=js"
export * from "./Jbb.vue?vue&type=script&lang=js"
import style0 from "./Jbb.vue?vue&type=style&index=0&id=4dc0c512&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dc0c512",
  null
  
)

export default component.exports