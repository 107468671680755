<template>
  <el-table
    :data="listData"
    border
    :empty-text="emptyText"
    style="width: 100%"
  >
    <el-table-column
      type="index"
      label="序号"
      width="45"
      align="center"
      :fixed="listTb[0].fixed"
    />
    <el-table-column
      v-for="(option,optionKey) in listTb"
      :key="optionKey"
      :label="option.title"
      :prop="option.prop"
      :width="option.width"
      :align="option.align"
      :fixed="option.fixed"
      :sortable="option.sortable"
    />
  </el-table>

</template>

<script>

export default {
    components: {

    },
    props: {
        listTb: { type: Array, default: function() { return [] } },
        listData: { type: Array, default: function() { return [] } },
        emptyText: { type: String, default: function() { return '' } }
    },
    data() {
        return {

        }
    },
    computed: {
    },
    watch: {
    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
    }
}
</script>

<style scoped lang="scss">

</style>
