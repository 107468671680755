import { render, staticRenderFns } from "./LayoutDisplayer.vue?vue&type=template&id=b01e683c&scoped=true"
import script from "./LayoutDisplayer.vue?vue&type=script&lang=js"
export * from "./LayoutDisplayer.vue?vue&type=script&lang=js"
import style0 from "./LayoutDisplayer.vue?vue&type=style&index=0&id=b01e683c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b01e683c",
  null
  
)

export default component.exports