<template>
  <div id="areaLayout">
    <template v-if="$slots.areaLayout">
      <slot name="areaLayout"></slot>
    </template>
    <LayoutTitle v-else :title="layoutItem.title" />
    <LayoutHiddenHandler :layout-item="layoutItem" />
  </div>
</template>

<script>
import LayoutTitle from '../components/LayoutTitle'
import LayoutHiddenHandler from '../components/LayoutHiddenHandler'
export default {
    components: { LayoutTitle, LayoutHiddenHandler },
    props: {
        layoutItem: { type: Object, require: true, default: () => {} }
    }
}
</script>

<style lang="scss" scoped>
#areaLayout{
    position: relative;
    height: 100%;
    width: 100%;
}
</style>
