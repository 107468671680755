<!-- eslint-disable vue/no-dupe-keys -->
<template>
  <div class="date_table_tree" style="display: flex;">
    <div class="date_table_tree_border">
      <slot name="tree-tabs"></slot>
      <giant-tree
        v-if="treeData.length"
        :nodes="treeData"
        @on-created="onTreeCreated"
        @on-click="onTreeNodeClick"
      >
        <!-- <template v-if="!noContextMenu" v-slot:context-menu="slotProps">
          <slot
            :treeId="slotProps.ztreeId"
            :contextNode="slotProps.contextNode"
            name="context-menu"
          >
            <ul>
              <li key="addBtn" @click="addMenu(slotProps.treeId,slotProps.contextNode)">新增子菜单</li>
              <li v-if="slotProps.contextNode.id!=='0'" key="editBtn" @click="editMenu(slotProps.treeId,slotProps.contextNode)">编辑菜单</li>
              <li v-if="slotProps.contextNode.id!=='0'" key="delBtn" @click="delMenu(slotProps.treeId,slotProps.contextNode)">删除菜单</li>
            </ul>
          </slot>
        </template> -->
      </giant-tree>
      <!-- <div v-else-if="!noContextMenu" class="date_table_tree_btn">
        <el-button plain
                   icon="el-icon-plus"
                   @click="addMenu"
        >新增</el-button>
      </div> -->
    </div>
    <data-table
      v-if="dataTableShow"
      ref="dataTable"
      style="width: 100%;"
      :module-info="moduleInfo"
      :tb-columns="tbColumns"
      :toolbar-btns="toolbarBtns"
      :on-selected-rows-change="selectedRowsChange"
      :on-list-data-before-load="onListDataBeforeLoad"
      :on-list-data-loaded="onListDataLoaded"
      :on-cell-click="cellClick"
      :on-delete-successed="onDeleteSuccessed"
    >
      <template slot="toolbarLeft-slotLeft">
        <slot name="toolbarLeft-slotLeft" />
      </template>
    </data-table>
  </div>
</template>

<script>
export default {
    components: {},
    props: {
        moduleInfo: {
            type: Object,
            default: () => ({})
        },
        tbColumns: {
            type: Array,
            default: () => []
        },
        toolbarBtns: {
            type: Array,
            default: () => []
        },
        onListDataBeforeLoad: {
            type: Function,
            default: () => null
        },
        onListDataLoaded: {
            type: Function,
            default: () => null
        },
        onDeleteSuccessed: {
            type: Function,
            default: () => null
        },
        onSelectedRowsChange: {
            type: Function,
            default: () => null
        },
        onListCellClick: {
            type: Function,
            default: () => null
        },
        // 默认树节点
        treeRoot: {
            type: [Boolean, String],
            default: () => false
        },
        // 树结构url
        querydataUrl: {
            type: String,
            default: () => ''
        },
        activeTreeNode: {
            type: Object,
            default: () => ({})
        },
        noContextMenu: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            array: [],
            // 当前登陆人
            loginUser: this.$store.getters.loginUser,
            // 默认树节点
            treeRootList: [],
            // 选中节点
            activeNode: null,
            // 列表是否展示
            dataTableShow: true,
            /**
             *
             */
            tree: null,
            treeNode: null,
            treeData: [],
            // 支部数
            zbNum: 0,
            // 总支数
            zzNum: 0,
            productCode: ''
        }
    },
    computed: {},
    watch: {
        treeRoot: {
            immediate: true,
            handler(val) {
                if (!val) {
                    this.treeRootList = []
                } else if (val === true) {
                    const orgName = this.loginUser.orgName
                    this.treeRootList = [
                        {
                            id: '0',
                            name: orgName,
                            open: true,
                            title: orgName
                        }
                    ]
                } else {
                    this.treeRootList = val
                }
            }
        },
        activeTreeNode: {
            handler(val) {
                if (!val) {
                    this.activeNode = null
                    return
                }
                if (!this.activeNode) {
                    this.activeNode = val
                    return
                }
                if (val.id !== this.activeNode.id) {
                    this.activeNode = val
                }
            }
        },
        activeNode: {
            handler(val) {
                this.setTreeSelect()
            }
        }
    },
    created() {
        if (this.$route.meta.params) this.productCode = this.$route.meta.params.split('=')[1]
        this.getTreeData()
        this.$bus.on('refreshTreeData', this.getTreeData)
    },
    beforeDestroy() {
        this.$bus.off('refreshTreeData', this.getTreeData)
    },
    mounted() {},
    methods: {
        cellClick(row) {
            this.onListCellClick(row)
        },
        // 获取树结构数据
        getTreeData() {
            let url = this.querydataUrl || this.moduleInfo.querydataUrl
            if (!url) {
                const defaultUrl = this.moduleInfo.listDataUrl
                const reg = /^(\/?\w+\/\w+\/)(.*)/
                url = defaultUrl.replace(reg, '$1querydata')
            }
            this.$http({
                url,
                method: 'GET',
                // data: [
                //     { compare: '=', field: 'orgCode', merge: true, value: this.loginUser.orgCode }
                // ]
                params: { orgCode: this.loginUser.orgCode, subOrgLevel: 2, openSysCode: this.loginUser.sysCode }
            })
                .then(res => {
                    if (res.code !== 'success') {
                        return this.$alert(res.message, '操作提示', {
                            type: 'error'
                        })
                    }
                    const listdata = [].concat(
                        this.treeRootList,
                        res.body.listdata
                    )
                    const id = res.body?.listdata?.length ? res.body.listdata[0].parentId : ''
                    const obj = { id, parentId: '0', name: '全部', categoryName: '全部', open: true, title: '全部' }
                    listdata.unshift(obj)
                    this.treeData = this.treeDataAdapter(listdata)
                    console.log(this.treeData, 'this.treeData')
                    if (!this.treeData.length) {
                        this.zTree = null
                        this.$emit('activeTreeNodeChange', null)
                        this.$emit('active-tree-node-change', null)
                    }
                })
        },
        // 树结构转化
        treeDataAdapter(treeData) {
            if (this.$vnode.context.treeDataAdapter) {
                return this.$vnode.context.treeDataAdapter(treeData)
            }
            console.log(treeData, 'treeData')
            treeData.forEach(v => {
                v.pId = v.parentId || '0'
                v.title = v.name
                v.open = true
            })
            return treeData
        },
        // 树结构加载完成
        onTreeCreated(zTree) {
            console.log(zTree, 'zTreezTreezTree')
            this.zTree = zTree
            if (this.$vnode.context.onTreeCreated) {
                this.$vnode.context.onTreeCreated(zTree)
                return
            }
            // this.setTreeSelect()
            const treenodes = zTree.getNodes()
            console.log(treenodes, this.loginUser, 'treenodes')
            let createdTree = {}
            treenodes[0].children.forEach(item => {
                if (item.orgFullId == this.loginUser.staffList[0].orgFullId) {
                    createdTree = item
                }
            })
            this.dataTableShow = false
            this.zTree.selectNode(createdTree)// 选中当前节点
            this.$emit('activeTreeNodeChange', createdTree)
            this.$emit('active-tree-node-change', createdTree)
            this.$nextTick(() => {
                setTimeout(() => {
                    this.dataTableShow = true
                }, 500)
            })
        },
        // 选中树节点
        setTreeSelect() {
            const treenodes = this.zTree ? this.zTree.getNodes() : []
            console.log(treenodes, '123')
            if (treenodes.length <= 0) return
            if (this.activeNode === null) {
                this.onTreeNodeClick('', treenodes[0].id, treenodes[0]) // 选中第一节点
            } else if (this.treeData.some(v => v.id === this.activeNode.id)) {
                this.zTree.selectNode(this.activeNode) // 选中当前节点
            } else {
                this.onTreeNodeClick('', treenodes[0].id, treenodes[0]) // 选中第一节点
            }
        },
        // 树结构点击事件
        onTreeNodeClick(evt, treeId, treeNode) {
            this.dataTableShow = false
            this.activeNode = treeNode || null
            console.log(this.activeNode, 'this.activeNode')
            console.log('this.activeNode', this.activeNode)
            this.$emit('activeTreeNodeChange', this.activeNode)
            this.$emit('active-tree-node-change', this.activeNode)
            this.$nextTick(() => {
                this.dataTableShow = true
            })
        },
        // 新增树
        addMenu(treeId, treeNode) {
            this.onTreeNodeClick('', treeId, treeNode)
            if (this.$vnode.context.addMenu) {
                this.$vnode.context.addMenu(treeId, treeNode)
                return
            }
            this.$bus.emit('openBusiform', this.moduleInfo, null, 1)
        },
        // 编辑树
        editMenu(treeId, treeNode) {
            if (this.$vnode.context.editMenu) {
                this.$vnode.context.editMenu(treeId, treeNode)
                return
            }
            this.$bus.emit('openBusiform', this.moduleInfo, treeNode, 1)
        },
        // 删除树
        delMenu(treeId, treeNode) {
            this.$refs.dataTable.deleteIds(treeNode, null, null, 1)
        },
        selectedRowsChange(row) {
            this.array = []
            row.forEach((item) => {
                this.array.push(item.id)
            })
            this.onSelectedRowsChange(row)
        },
        getArray() {
            return this.array
        }
    }
}
</script>

<style scoped lang="scss">
.date_table_tree {
    display: flex;
    .date_table_tree_border {
        width: 240px;
        min-width: 240px;
        border: 1px solid #d8dce5;
        margin-right: 10px;
        height: calc(100vh - 60px - 50px - 20px - 50px - 20px);
        display: flex;
        flex-direction: column;
    }
    .date_table_tree_btn {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
}
</style>
