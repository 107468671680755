<template>
  <div>
    <div class="title">
      <div style="font-size: 14px;color: #000;font-weight: 600;">代课信息:</div>
      <!-- -1起草  0草稿 -->
      <div v-if="canEdit" style="display:flex;">
        <el-link :underline="false" icon="el-icon-plus" @click="handAdd">添加</el-link>
      </div>
    </div>
    <!-- 数据列表 -->
    <el-table :data="dataArr" border style="width: 100%;border-left: none;" class="inner-data" @row-click="rowClick">
      <el-table-column type="index" label="序号" width="50" align="center" :index="'index'" />
      <el-table-column align="center" prop="courseName" width="140" label="代课班级" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.courseName }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="lessonName" idth="140" label="代课课程" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.lessonName }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="teacherName" label="代课教师" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.teacherName }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="startTime" label="代课开始时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.startTime | timeFormat }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="endTime" label="代课结束时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.endTime | timeFormat }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="canEdit" align="center" label="操作" width="90">
        <template slot-scope="scope">
          <el-link type="primary" :underline="false" @click="editClick(scope.row)">修改</el-link>
          <el-link type="primary" :underline="false" @click="delClick(scope.row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <!-- <div v-else style="width:100%;height:100px;line-height:100px;text-align:center;border-top:1px solid #E2E7ED;">
      <span style="color:#999;font-size:12px;">暂无数据</span>
    </div> -->
    <Basicform
      ref="taskForm"
      :title="formTitle"
      :module-code="moduleInfo.moduleCode"
      :on-basic-form-before-open="onBasicFormBeforeOpen"
      :on-basic-form-opened="onBasicFormOpened"
      :on-basic-form-before-submit="onBasicFormBeforeSubmit"
      :on-basic-form-submited="onBasicFormSubmited"
      :toolbar-btns="busiformBtns"
      :append-to-body="true"
    >
      <template #default="slotProps">
        <busi-form ref="busiform" :vo="slotProps.vo" :can-edit="slotProps.canEdit" :module-info="moduleInfo" />
      </template>
    </Basicform>
  </div>
</template>

<script>
const moment = require('moment')
export default {
    filters: {
        timeFormat(val) {
            if (!val) return ''
            return moment(new Date(val)).format('YYYY-MM-DD dddd')
        }
    },
    components: {

    },
    props: {
        vo: {
            type: Object,
            default: function() {
                return {}
            }
        },
        canEdit: {
            type: Number,
            default: function() {
                return 1
            }
        },
        wfInstance: {
            type: Object,
            default: function() {
                return {}
            }
        }
    },
    data() {
        return {
            formTitle: '代课信息',
            moduleInfo: {
                'winWidth': 1000,
                'winHeight': 600,
                moduleCode: 'classInfo',
                listDataUrl: '/change/dksqInfo/listdata',
                addNewUrl: '/change/dksqInfo/addNew',
                insertUrl: '/change/dksqInfo/insert',
                updateUrl: '/change/dksqInfo/update',
                getByIdUrl: '/change/dksqInfo/getById',
                delUrl: '/change/dksqInfo/delete',
                uploadUrl: '/change/dksqInfo/upload',
                formTemplate: '#/dcontent/vuebusiform/W/change.classInfo.form.vue?sysCode='
            },
            dataArr: [],
            busiformBtns: []
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        this.getData()
    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
        // 获取listdata数据
        async getData() {
            const data = [
                { 'compare': '=', 'field': 'applyId', 'merge': true, 'value': this.vo.id }
            ]
            const res = await this.$app.querydata('/change/dksqInfo/querydata', data)
            if (res.code === 'success') {
                this.dataArr = res.body.listdata || []
            } else {
                this.$message({
                    message: res.message,
                    type: 'warning'
                })
            }
        },
        handAdd() {
            if (!this.wfInstance.id) {
                this.$vnode.context.$vnode.context.btnSubmitClick('AutoSave', async(saveRst, a, b, c) => {
                    if (saveRst) {
                        this.openBusiForm(null, this.moduleInfo, 1)
                    } else {
                        return false
                    }
                })
            } else {
                this.openBusiForm(null, this.moduleInfo, 1)
            }
        },
        rowClick(row, column, event) {
            console.log('column', column)
            if (!column.property) return
            this.busiformBtns = [
                {
                    id: 'btn_close',
                    btnName: '关 闭',
                    btnIcon: 'close',
                    btnClick: 'closeDialog',
                    btnPermission: '',
                    btnType: ''
                }
            ]
            this.openBusiForm(row, this.moduleInfo, 0)
        },
        editClick(row) {
            this.openBusiForm(row, this.moduleInfo, 1)
        },
        delClick(row) {
            this.$confirm('是否删除该代课信息?', '操作提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http({
                    contentType: 'application/x-www-form-urlencoded',
                    url: this.moduleInfo.delUrl,
                    method: 'delete',
                    params: { ids: row.id }
                }).then(res => {
                    if (res.code === 'success') {
                        this.$message({ type: 'success', message: '删除成功!' })
                        this.getData()
                    }
                })
            }).catch(() => {

            })
        },
        openBusiForm(row, moduleInfo, canEdit) {
            this.$options.components['BusiForm'] = this.$loadComponent('#/dcontent/vuebusiform/W/change.dksqInfo.form.vue?sysCode=' + this.$route.meta.sysCode)
            this.$nextTick(() => {
                this.$bus.emit('openBusiform', moduleInfo, row, canEdit, (res) => {
                    this.busiformBtns = []
                })
            })
        },
        /**
        * 表单打开检查，vo为已加载task对象，允许打开：defer.resolve(true)，阻止打开：defer.reject(reason)
        */
        onBasicFormBeforeOpen: function($scope, vo, defer) {
            console.log('task form before open')
            defer.resolve(true)
        },
        /**
        * 表单提交检查，可执行业务数据高级校验，vo为待提交的task对象，校验成功：defer.resolve(true)，校验失败：defer.reject(reason)
        */
        onBasicFormBeforeSubmit: function($scope, vo, defer) {
            if (!vo.applyId) {
                vo.applyId = this.vo.id
            }
            defer.resolve(true)
        },
        /**
        * 表单提交完成，vo为已保存成功的task对象，窗口即将关闭，不需要返回值
        */
        async onBasicFormSubmited($scope, vo) {
            this.getData()
        }

    }
}
</script>

<style scoped lang="scss">
    .font13 {
        font-size: 13px;;
    }
    .title {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        padding: 0 16px;
        background-color: #fff;
    }
    .cursor-p {
        cursor: pointer;
    }
    .svg-icon {
        margin: 0 5px;
        position:relative;
        top: 1px;
    }

    .sp {
        display: inline-block;
        margin: 0 10px;
        &::after {
           content: '';
            display: inline-block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 13px;
            background-color: #606266;
            margin: 0 8px;
        }
        &:last-child {
           &::after {
                content: '';
                width: 1px;
                height: 0;
            }
        }
    }

    .wflow-container .el-form .inner-data.el-table::before{
        background-color: #fff !important;
    }
    .wflow-container .el-form .inner-data.el-table.el-table--border td {
        border-right-color: #999 ;
    }
</style>

