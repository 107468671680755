<template>
    <!-- 通讯录 -->
    <div v-loading="!loading" class="searchTel">
        <template v-if="list.length > 0">
            <div v-for="item in list" :key="item" class="tel-item flex-row flex-bw">
                <el-popover placement="bottom-start" style="padding: 0" :visible-arrow="false" width="240" popper-class="cards" trigger="click">
                    <div class="card">
                        <div class="flex-row head">
                            <img
                                style="width: 48px; height: 48px; margin-right: 12px; border-radius: 50%"
                                :src="item.avatar.indexOf('http') > -1 ? item.avatar : locationName + '/apigw/' + item.avatar"
                                alt=""
                                srcset=""
                                @error="
                                    (e) => {
                                        e.target.src = '/images/w-imgs/userAvatar.png'
                                    }
                                "
                            />
                            <div class="user flex1">
                                <div class="name"><span v-html="item.name" /></div>
                                <div class="detial">
                                    <span style="padding-right: 30px" v-html="item.positionName" />
                                </div>
                            </div>
                        </div>
                        <div class="cd-item po-r">
                            <svg-icon class="po-a" style="left: 15px; top: 3px" icon-class="cardgs" />
                            <span v-html="item.orgName" />
                        </div>
                        <div class="cd-item po-r">
                            <svg-icon class="po-a" style="left: 15px; top: 3px" icon-class="cardorg" />
                            <span v-html="item.department" />
                        </div>
                        <div class="cd-item po-r">
                            <svg-icon class="po-a" style="left: 15px; top: 3px" icon-class="cardpsion" />
                            {{ item.positionName }}
                        </div>
                        <div class="cd-item po-r">
                            <svg-icon class="po-a" style="left: 15px; top: 3px" icon-class="cardphone" />
                            <span v-html="'进入“企微-即刻呼”电话联系他吧~'" />
                        </div>
                        <div class="cd-item po-r">
                            <svg-icon class="po-a" style="left: 15px; top: 2px" icon-class="cardemial" />
                            <span style="padding-right: 10px">{{ item.email || '无' }}</span>
                            <span class="pter copy" @click="copy(item.email)"><svg-icon icon-class="cardcopy" /></span>
                        </div>
                        <div style="padding: 10px">
                            <!-- <el-button style="width:100%;" type="primary" @click="openQwChat(item.employeeNo)"><i class="icon1 el-icon-message" /> 企微聊天</el-button> -->
                        </div>
                    </div>
                    <div slot="reference" class="flex-row pter">
                        <img
                            id="telDetail"
                            class="pter img"
                            style="width: 48px; height: 48px; margin-right: 10px; border-radius: 50%"
                            :src="item.avatar.indexOf('http') > -1 ? item.avatar : locationName + '/apigw/' + item.avatar"
                            alt=""
                            srcset=""
                            @error="
                                (e) => {
                                    e.target.src = '/images/w-imgs/userAvatar.png'
                                }
                            "
                        />

                        <div class="user">
                            <div class="name"><span id="telDetail" v-html="item.name" /></div>
                            <div class="detial">
                                <span id="telDetail" style="padding-right: 30px">
                                    部门：
                                    <span id="telDetail" v-html="item.orgName" />
                                    /
                                    <span id="telDetail" v-html="item.department" />
                                </span>
                            </div>
                        </div>
                    </div>
                </el-popover>
                <div class="btns flex-row flex-item">
                    <!-- <el-button id="goStationMap" @click="goMap(item)"><i id="goStationMap" class="el-icon-map-location" />查看工位地图</el-button>
          <el-button id="opQwChat" @click="openQwChat(item.employeeNo)"><i id="opQwChat" class="el-icon-chat-dot-round" />企微联系</el-button> -->
                </div>
            </div>
            <div class="pagination">
                <el-pagination style="margin: 0 auto" :current-page="info.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="info.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="info.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
            </div>
        </template>
        <template v-if="list.length === 0">
            <el-empty style="padding-top: 130px" :image="'./images/w-imgs/nosearch.png'" :image-size="200">
                <!-- 没有搜到相关的通讯录数据 -->
            </el-empty>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        searchKey: {
            type: String,
            default: function () {
                return ''
            }
        }
    },
    data() {
        return {
            list: [],
            input: '',
            loading: false,
            info: {
                pageNum: 1,
                pageSize: 10,
                total: 100
            },
            locationName: ''
        }
    },
    computed: {},
    watch: {},
    created() {
        this.locationName = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
        goMap(item) {
            this.$http({
                method: 'get',
                contentType: 'application/x-www-form-urlencoded',
                url: '/portal/workStation/getWorkStationMapUrl?empNo=' + item.employeeNo + '&redirectUrl=' + location.href
            }).then(async (res) => {
                if (res.code === 'success') {
                    window.open(res.body.result)
                } else {
                    this.loginOutMain(res.body.result)
                    // location.href = res.body.result
                    this.$message.error(res.message)
                }
            })
        },
        init(val, num) {
            if (!val) {
                this.list = []
                this.loading = true
                return
            }
            if (num) this.info.pageNum = 1
            this.loading = false
            if (val) this.input = val
            const params = {
                pageNum: this.info.pageNum,
                pageSize: this.info.pageSize,
                keyword: this.input
            }
            this.$http({
                method: 'GET',
                params,
                url: '/common/elasticsearch/searchContacts'
            }).then((res) => {
                if (res.code === 'success') {
                    this.list = res.body.listdata || []
                    this.info.total = res.body.total
                    this.$emit('setTotal', this.info.total)
                }
                this.loading = true
            })
        },
        handleSizeChange(val) {
            this.info.pageSize = val
            this.info.pageNum = 1
            this.init(this.input)
        },
        handleCurrentChange(val) {
            this.info.pageNum = val
            this.init(this.input)
        },
        copy(text) {
            const input = document.createElement('input')
            input.value = text
            input.style.opacity = 0
            document.body.appendChild(input)
            input.select()
            document.execCommand('copy')
            document.body.removeChild(input)
            this.$message.success('复制成功')
        },
        openQwChat(employeeNo) {
            this.$http({
                method: 'get',
                contentType: 'application/x-www-form-urlencoded',
                url: '/portal/wxqyh/getWxqyhLaunchCode?userId=' + employeeNo
            }).then(async (res) => {
                if (res.code === 'success') {
                    location.href = 'wxwork://launch?launch_code=' + res.body.result
                } else {
                    this.$message.error(res.message)
                }
            })
        }
    }
}
</script>
<style>
.cards {
    padding: 0;
    border-radius: 8px;
    margin-top: -67px !important;
    margin-left: 58px;
}
</style>
<style scoped lang="scss">
.copy {
    padding: 4px 6px;
    border-radius: 8px;
}
.copy:hover {
    background: #f7f8fa;
    color: var(--bgColor);
}
.card {
    border-radius: 8px;
    .head {
        background: url('~public/images/w-imgs/personBg.png') no-repeat;
        background-size: cover;
        padding: 15px;
        border-radius: 8px 8px 0 0;
        .name {
            font-size: 16px;
            font-weight: 600;
            color: #171717;
            line-height: 22px;
        }
        .detial {
            font-size: 14px;
            padding-top: 5px;
            line-height: 20px;
            color: rgba(36, 41, 43, 0.45);
        }
    }
    .cd-item {
        padding: 0px 15px;
        padding-left: 40px;
        margin-top: 10px;
        font-size: 14px;
        line-height: 20px;
        .icon1 {
            margin-right: 10px;
            left: 15px;
            top: 3.1px;
        }
        .icon2 {
            cursor: pointer;
        }
        .icon2:hover {
            color: var(--bgColor);
        }
    }
}
.pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}
.searchTel {
    //background: url('~public/images/w-imgs/searchbox.png') #fff no-repeat;
    background-size: 100% auto;
    border-radius: 12px;
    min-height: 500px;
    .tel-item {
        padding: 15px 15px;
        position: relative;

        img {
            cursor: pointer;
        }
        .name {
            font-size: 16px;
            font-weight: 500;
            color: rgba(61, 187, 227, 0.85);
            margin-top: 5px;
            span {
                font-size: 14px;
                font-weight: 400;
                color: #262626;
            }
        }

        .detial {
            font-size: 12px;
            line-height: 20px;
            color: #595959;
            margin-top: 8px;
            i {
                position: relative;
                left: 5px;
            }
        }
        .btns {
            /deep/.el-button {
                border-radius: 8px;
            }
            i {
                margin-right: 5px;
            }
        }
    }
}
.tel-item + .tel-item {
    border: 0;
    border-top: 1.5px solid;
    border-image: linear-gradient(90deg, #fff, #ebebeb, #ebebeb, #ebebeb, #fff) 1;
}
.img {
    transition: all 1s;
}
.img:hover {
    transform: scale(1.1);
    transition: all 1s;
}
</style>
