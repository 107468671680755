<template>
  <TextRecognition>
  </TextRecognition>
</template>
<script>
export default {
}
</script>

<style>

</style>
