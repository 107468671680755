<template>
    <div class="cqyj">
        <PageContent :title="'应急值班设置'">
            <el-form>
                <div class="el-table el-table--fit el-table--border">
                    <div class="el-table__body-wrapper">
                        <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
                            <tbody>
                                <tr>
                                    <td class="el-table__cell tdlbl tdw17" style="background-color: #fff">
                                        <span class="required">*</span>
                                        排班生成时间
                                    </td>
                                    <td class="el-table__cell tdcontle__cell tdcont" colspan="5">
                                        <span class="td-lable">每月前&nbsp;&nbsp;</span>
                                        <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name=" " rules="required|integer|min_value:0">
                                            <el-tooltip :disabled="errors[0] === undefined" :content="errors[0]" :class="{ 'invalid-field': errors[0] !== undefined }" popper-class="error-popper-class" placement="right-start" effect="dark">
                                                <el-input-number v-model="vo.generateDay" style="width: 120px" name="generateDay" :min="0" label="描述文字" @change="handleChange"></el-input-number>
                                            </el-tooltip>
                                        </ValidationProvider>
                                        &nbsp;天的&nbsp;&nbsp;
                                        <el-time-select v-model="vo.generateTime" :picker-options="{start: '08:30',step: '00:15',end: '18:30'}" placeholder="选择时间"></el-time-select>
                                        &nbsp;&nbsp; 后生成月度应急值班。
                                    </td>
                                </tr>
                                <tr>
                                    <td class="el-table__cell tdlbl tdw17" style="background-color: #fff">
                                        <span class="required">*</span>
                                        排班截止时间
                                    </td>
                                    <td class="el-table__cell tdcontle__cell tdcont" colspan="5">
                                        <span class="td-lable">每月前&nbsp;&nbsp;</span>
                                        <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name=" " rules="required|integer|min_value:0">
                                            <el-tooltip :disabled="errors[0] === undefined" :content="errors[0]" :class="{ 'invalid-field': errors[0] !== undefined }" popper-class="error-popper-class" placement="right-start" effect="dark">
                                                <el-input-number v-model="vo.deadlineDay" style="width: 120px" name="deadlineDay" :min="0" label="描述文字" @change="handleChange"></el-input-number>
                                            </el-tooltip>
                                        </ValidationProvider>
                                        &nbsp;天的&nbsp;&nbsp;
                                        <el-time-select v-model="vo.deadlineTime" :picker-options="{ start: '08:30', step: '00:15',end: '18:30'}" placeholder="选择时间"></el-time-select>
                                        &nbsp;&nbsp; 后不可调班。
                                    </td>
                                </tr>
                                <tr>
                                    <td class="el-table__cell tdlbl tdw17" style="background-color: #fff">
                                        <span class="required">*</span>
                                        应急值班提醒时间
                                    </td>
                                    <td class="el-table__cell tdcontle__cell tdcont" colspan="5">
                                        <span class="td-lable">值班前&nbsp;&nbsp;</span>
                                        <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name=" " rules="required|min_value:1">
                                            <el-tooltip :disabled="errors[0] === undefined" :content="errors[0]" :class="{ 'invalid-field': errors[0] !== undefined }" popper-class="error-popper-class" placement="right-start" effect="dark">
                                                <el-input-number v-model="vo.remindDay" style="width: 120px" name="remindDay" :precision="1" :step="1" :min="1" label="描述文字" @change="handleChange"></el-input-number>
                                            </el-tooltip>
                                        </ValidationProvider>
                                        &nbsp;天的&nbsp;&nbsp;
                                        <el-time-select v-model="vo.remindTime" :picker-options="{start: '08:30',step: '00:15',end: '18:30'}" placeholder="选择时间"></el-time-select>
                                        &nbsp;&nbsp; 发送提醒通知值班人员（包括值班司机）。
                                    </td>
                                </tr>
                                <tr>
                                    <td class="el-table__cell tdlbl tdw17" style="background-color: #fff">
                                        <span class="required">*</span>
                                        应急值班提醒方式
                                    </td>
                                    <td colspan="5" class="el-table__cell tdcont tdw83">
                                        <el-checkbox-group v-model="vo.dutyRemindType">
                                            <el-checkbox :label="'0'">站内信</el-checkbox>
                                            <el-checkbox :label="'1'">短信</el-checkbox>
                                            <el-checkbox :label="'3'">粤政易消息</el-checkbox>
                                        </el-checkbox-group>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="el-table__cell tdlbl tdw17" style="background-color: #fff">
                                        <span class="required">*</span>
                                        应急值班离岗/出勤提醒
                                    </td>
                                    <td colspan="2" class="el-table__cell tdcont tdw83">
                                        <el-checkbox-group v-model="vo.attendanceRemindType">
                                            <el-checkbox :label="'0'">站内信</el-checkbox>
                                            <el-checkbox :label="'1'">短信</el-checkbox>
                                            <el-checkbox :label="'3'">粤政易消息</el-checkbox>
                                        </el-checkbox-group>
                                    </td>
                                    <td class="el-table__cell tdlbl tdw17" style="background-color: #fff">
                                        <span class="required">*</span>
                                        离岗/出勤提醒范围
                                    </td>
                                    <td class="el-table__cell tdcont tdw33" colspan="2">
                                        <ValidationProvider v-slot="{ errors }" name="离岗/出勤提醒范围" rules="required">
                                            <el-tooltip :disabled="errors[0] === undefined" :content="errors[0]" :class="{ 'invalid-field': errors[0] !== undefined }" popper-class="error-popper-class" placement="right-start" effect="dark">
                                                <el-input v-model="vo.remainPerson" v-select-dialog="'staff'" type="input" multiple="true" :root-org-code="vo.orgCode" placeholder="请选择提醒人员" name="remainPerson" resize="none" />
                                            </el-tooltip>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="el-table__cell tdlbl tdw17" style="background-color: #fff">
                                        <span class="required">*</span>
                                        离岗/出勤提醒频率
                                    </td>
                                    <td class="el-table__cell tdcontle__cell tdcont" colspan="5">
                                        <span class="td-lable">间隔&nbsp;&nbsp;</span>
                                        <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name=" " rules="required|min_value:0.5">
                                            <el-tooltip :disabled="errors[0] === undefined" :content="errors[0]" :class="{ 'invalid-field': errors[0] !== undefined }" popper-class="error-popper-class" placement="right-start" effect="dark">
                                                <el-input-number v-model="vo.attendanceRemindHour" style="width: 120px" name="clockInFrequency" :precision="1" :step="1" :min="1" label="描述文字" @change="handleChange"></el-input-number>
                                            </el-tooltip>
                                        </ValidationProvider>
                                        &nbsp;小时打卡次数少于&nbsp;
                                        <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name=" " rules="required|min_value:0.5">
                                            <el-tooltip :disabled="errors[0] === undefined" :content="errors[0]" :class="{ 'invalid-field': errors[0] !== undefined }" popper-class="error-popper-class" placement="right-start" effect="dark">
                                                <el-input-number v-model="vo.attendanceRemindNum" style="width: 120px" name="clockInFrequency" :precision="1" :step="1" :min="1" label="描述文字" @change="handleChange"></el-input-number>
                                            </el-tooltip>
                                        </ValidationProvider>
                                        &nbsp;次发送消息提醒。
                                    </td>
                                </tr>
                                <tr>
                                    <td class="el-table__cell tdlbl tdw17" style="background-color: #fff">
                                        <span class="required">*</span>
                                        应急值班打卡位置
                                    </td>
                                    <td class="el-table__cell tdcontle__cell tdcont" colspan="5">
                                        {{ vo.clinkInPlace }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="el-table__cell tdlbl tdw17" style="background-color: #fff">
                                        <span class="required">*</span>
                                        应急值班打卡距离范围
                                    </td>
                                    <td class="el-table__cell tdcontle__cell tdcont" colspan="5">
                                        <span class="td-lable">间隔不大于&nbsp;&nbsp;</span>
                                        <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name=" " rules="required|integer|min_value:0">
                                            <el-tooltip :disabled="errors[0] === undefined" :content="errors[0]" :class="{ 'invalid-field': errors[0] !== undefined }" popper-class="error-popper-class" placement="right-start" effect="dark">
                                                <el-input-number v-model="vo.clockInDistance" style="width: 120px" name="clockInDistance" :min="100" label="描述文字" @change="handleChange"></el-input-number>
                                            </el-tooltip>
                                        </ValidationProvider>
                                        &nbsp;米。
                                    </td>
                                </tr>
                                <tr>
                                    <td class="el-table__cell tdlbl tdw17" style="background-color: #fff">
                                        <span class="required">*</span>
                                        应急值班打卡频率
                                    </td>
                                    <td class="el-table__cell tdcontle__cell tdcont" colspan="5">
                                        <span class="td-lable">间隔&nbsp;&nbsp;</span>
                                        <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name=" " rules="required|min_value:0.5">
                                            <el-tooltip :disabled="errors[0] === undefined" :content="errors[0]" :class="{ 'invalid-field': errors[0] !== undefined }" popper-class="error-popper-class" placement="right-start" effect="dark">
                                                <el-input-number v-model="vo.clockInFrequency" style="width: 120px" name="clockInFrequency" :precision="1" :step="0.1" :min="0.1" label="描述文字" @change="handleChange"></el-input-number>
                                            </el-tooltip>
                                        </ValidationProvider>
                                        &nbsp;小时自动打卡。
                                    </td>
                                </tr>
                                <tr>
                                    <td class="el-table__cell tdlbl tdw17" style="background-color: #fff">
                                        <span class="required">*</span>
                                        值班管理员
                                    </td>
                                    <td class="el-table__cell tdcontle__cell tdcont" colspan="5">
                                        <!-- <span class="td-lable">间隔&nbsp;&nbsp;</span> -->
                                        <ValidationProvider v-slot="{ errors }" name="值班管理员" rules="required">
                                            <el-tooltip :disabled="errors[0] === undefined" :content="errors[0]" :class="{ 'invalid-field': errors[0] !== undefined }" popper-class="error-popper-class" placement="right-start" effect="dark">
                                                <el-input v-model="vo.dutyAdministrator" v-select-dialog="'staff'" type="input" multiple="false" :root-org-code="vo.orgCode" placeholder="请选择值班管理员" name="dutyAdministrator" resize="none" />
                                            </el-tooltip>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="el-table__cell tdlbl tdw17" style="background-color: #fff">
                                        <span class="required">*</span>
                                        车队长
                                    </td>
                                    <td class="el-table__cell tdcontle__cell tdcont" colspan="2">
                                        <!-- <span class="td-lable">间隔&nbsp;&nbsp;</span> -->
                                        <ValidationProvider v-slot="{ errors }" name="车队长" rules="required">
                                            <el-tooltip :disabled="errors[0] === undefined" :content="errors[0]" :class="{ 'invalid-field': errors[0] !== undefined }" popper-class="error-popper-class" placement="right-start" effect="dark">
                                                <el-input v-model="vo.carCaptain" v-select-dialog="'staff'" type="input" multiple="false" :root-org-code="vo.orgCode" placeholder="请选择车队长" name="carCaptain" resize="none" />
                                            </el-tooltip>
                                        </ValidationProvider>
                                    </td>
                                    <td class="el-table__cell tdlbl tdw17" style="background-color: #fff">
                                        <span class="required">*</span>
                                        车队长联系电话
                                    </td>
                                    <td class="el-table__cell tdcontle__cell tdcont" colspan="2">
                                        <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name=" " rules="required|min_value:0.5">
                                            <el-tooltip :disabled="errors[0] === undefined" :content="errors[0]" :class="{ 'invalid-field': errors[0] !== undefined }" popper-class="error-popper-class" placement="right-start" effect="dark">
                                                <el-input v-model="vo.carCaptainPhone" name="carCaptainPhone" :precision="1" label="车队长联系电话"></el-input>
                                            </el-tooltip>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </el-form>
            <div slot="footer" style="height: 100px">
                <div style="margin-bottom: 10px; font-size: 13px">
                    <div>
                        <el-button plain icon="el-icon-check" @click="submit">保存</el-button>
                    </div>
                </div>
            </div>
        </PageContent>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            vo: {},
            loginUser: this.$store.getters.loginUser
        }
    },
    computed: {},
    watch: {},
    created() {
        self.$bus.emit('showNavSearch', false) // 隐藏右上搜索按钮
        this.getData()
    },
    mounted() {},
    beforeDestroy() {
        self.$bus.emit('showNavSearch', true)
    },
    methods: {
        // 选择人员
        onSelectStaffCompleted(selectedNodes, attr) {
            let selected = ''
            let selectedId = ''
            let selectedPhone = ''
            selectedNodes.forEach(function (node) {
                if (selected !== '') selected += '、'
                if (selectedId !== '') selectedId += ','
                if (selectedPhone !== '') selectedPhone += ','
                selected += node.name
                selectedId += node.personId
                selectedPhone += node.mobile
            })
            if (attr.name == 'remainPerson') {
                this.$set(this.vo, 'remainPerson', selected)
                this.$set(this.vo, 'remainPersonId', selectedId)
            } else if (attr.name == 'dutyAdministrator') {
                this.$set(this.vo, 'dutyAdministrator', selected)
                this.$set(this.vo, 'dutyAdministratorId', selectedId)
            } else if (attr.name == 'carCaptain') {
                this.$set(this.vo, 'carCaptain', selected)
                this.$set(this.vo, 'carCaptainId', selectedId)
                this.$set(this.vo, 'carCaptainPhone', selectedPhone)
            }
        },
        getData() {
            this.$http({
                method: 'POST',
                url: '/zbgl/dutySet/getDutySet'
            }).then((res) => {
                if (res.code == 'success') {
                    this.vo = res.body.vo
                    var arr = []
                    var arr1 = []
                    if (!this.vo.dutyRemindType) {
                        this.vo.dutyRemindType = []
                    } else {
                        arr = this.vo.dutyRemindType.split(',')
                        this.vo.dutyRemindType = arr
                    }
                    if (!this.vo.attendanceRemindType) {
                        this.vo.attendanceRemindType = []
                    } else {
                        arr1 = this.vo.attendanceRemindType.split(',')
                        this.vo.attendanceRemindType = arr1
                    }
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    })
                }
            })
        },
        submit() {
            let attendanceRemindType = ''
            let dutyRemindType = ''
            if (this.vo.attendanceRemindType && this.vo.attendanceRemindType.length > 0) {
                this.vo.attendanceRemindType.forEach((item) => {
                    if (attendanceRemindType !== '') attendanceRemindType += ','
                    attendanceRemindType += item
                })
                this.vo.attendanceRemindType = attendanceRemindType
            }
            if (this.vo.dutyRemindType && this.vo.dutyRemindType.length > 0) {
                this.vo.dutyRemindType.forEach((item) => {
                    if (dutyRemindType !== '') dutyRemindType += ','
                    dutyRemindType += item
                })
                this.vo.dutyRemindType = dutyRemindType
            }
            this.$http({
                method: 'POST',
                url: '/zbgl/dutySet/saveDutySet',
                data: this.vo
            }).then((res) => {
                if (res.code == 'success') {
                    this.$message({
                        message: '保存成功！',
                        type: 'success'
                    })
                    this.getData()
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    })
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
/deep/.input-style .el-input__inner {
    pointer-events: none !important;
    border: none !important;
    resize: none !important;
    text-align: center;
}
</style>
