<template>
  <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
    <tbody>
      <tr>
        <td class="el-table__cell tdlbl tdw17" colspan="1">考勤日期</td>
        <td class="el-table__cell tdcont tdw33" colspan="2">
          {{ zo.occurredDate }}&nbsp;{{ zo.timeSpan }}&nbsp;({{ zo.normalOnTime }}-{{ zo.normalOffTime }})
        </td>
        <td class="el-table__cell tdlbl tdw17" colspan="1">打卡信息</td>
        <td class="el-table__cell tdcont tdw33" colspan="2">
          <span>签到</span>
          <span style="color: red;">{{ zo.onTime?zo.onTime:'' }}{{ $vnode.context.onstatus }}</span>
          <span>签退</span>
          <span style="color: red;">{{ zo.offTime?zo.offTime:'' }}{{ $vnode.context.offstatus }}</span>
        </td>
      </tr>
      <tr>
        <td class="el-table__cell tdlbl tdw17" colspan="1">补签签到时间</td>
        <td class="el-table__cell tdcont tdw33" colspan="2">
          <!-- <form-item :field="{&quot;cssClass&quot;:null,&quot;cssStyle&quot;:null,&quot;defaultValue&quot;:null,&quot;elementCanEdit&quot;:&quot;0&quot;,&quot;elementCssHeight&quot;:null,&quot;elementCssWidth&quot;:null,&quot;elementFormatter&quot;:&quot;format：HH:mm\r\n&quot;,&quot;elementReadonly&quot;:1,&quot;elementType&quot;:&quot;date&quot;,&quot;emptyMessage&quot;:&quot;请输入补签签到时间 &quot;,&quot;errorMessage&quot;:null,&quot;formElementIndex&quot;:0,&quot;formShow&quot;:0,&quot;formTdAlign&quot;:&quot;text-left&quot;,&quot;formTdColspan&quot;:0,&quot;id&quot;:null,&quot;isRequired&quot;:0,&quot;onBlurEvent&quot;:null,&quot;onChangeEvent&quot;:null,&quot;onClickEvent&quot;:null,&quot;onDblClickEvent&quot;:null,&quot;onFocusEvent&quot;:null,&quot;onKeyDownEvent&quot;:null,&quot;onKeyPressEvent&quot;:null,&quot;onKeyUpEvent&quot;:null,&quot;onMouseDownEvent&quot;:null,&quot;onMouseOverEvent&quot;:null,&quot;onMouseUpEvent&quot;:null,&quot;passenger&quot;:{},&quot;propComment&quot;:&quot;补签签到时间 &quot;,&quot;propName&quot;:&quot;recordOnTime&quot;,&quot;propType&quot;:&quot;java.util.Date&quot;,&quot;tElementFormatter&quot;:&quot;format：HH:mm\r\n&quot;,&quot;tElementType&quot;:&quot;date&quot;,&quot;tIsRequired&quot;:0,&quot;tPropComment&quot;:&quot;补签签到时间 &quot;,&quot;tPropName&quot;:&quot;recordOnTime&quot;,&quot;tPropType&quot;:&quot;java.util.Date&quot;,&quot;tTbEntityFieldId&quot;:&quot;bac63001bd904c62bca02368cec6ee10&quot;,&quot;tTbEntityId&quot;:&quot;f9bbdeca665f4fa78ed86ca00a318079&quot;,&quot;tTbFieldLength&quot;:null,&quot;tbBusiformId&quot;:null,&quot;tbEntityFieldId&quot;:&quot;bac63001bd904c62bca02368cec6ee10&quot;,&quot;tbEntityId&quot;:&quot;f9bbdeca665f4fa78ed86ca00a318079&quot;,&quot;textFormatter&quot;:&quot;HH:mm\r\n&quot;,&quot;valueMaxLength&quot;:null,&quot;valueMinLength&quot;:null,&quot;model&quot;:&quot;zo.recordOnTime&quot;,&quot;option&quot;:[]}" :vo="zo" :can-edit="canEdit" /> -->
          <el-time-picker
            v-if="canEdit"
            v-model="zo.recordOnTime"
            :picker-options="{
              format: 'HH:mm'
            }"
            value-format="HH:mm"
            format="HH:mm"
            placeholder="补签签到时间"
            class="resign-time"
          />
          <span v-else>{{ zo.recordOnTime }}</span>
        </td>
        <td class="el-table__cell tdlbl tdw17" colspan="1">补签签退时间</td>
        <td class="el-table__cell tdcont tdw33" colspan="2">
          <!-- <form-item :field="{&quot;cssClass&quot;:null,&quot;cssStyle&quot;:null,&quot;defaultValue&quot;:null,&quot;elementCanEdit&quot;:&quot;0&quot;,&quot;elementCssHeight&quot;:null,&quot;elementCssWidth&quot;:null,&quot;elementFormatter&quot;:&quot;format：HH:mm\r\n&quot;,&quot;elementReadonly&quot;:1,&quot;elementType&quot;:&quot;date&quot;,&quot;emptyMessage&quot;:&quot;请输入补签签退时间 &quot;,&quot;errorMessage&quot;:null,&quot;formElementIndex&quot;:0,&quot;formShow&quot;:0,&quot;formTdAlign&quot;:&quot;text-left&quot;,&quot;formTdColspan&quot;:0,&quot;id&quot;:null,&quot;isRequired&quot;:0,&quot;onBlurEvent&quot;:null,&quot;onChangeEvent&quot;:null,&quot;onClickEvent&quot;:null,&quot;onDblClickEvent&quot;:null,&quot;onFocusEvent&quot;:null,&quot;onKeyDownEvent&quot;:null,&quot;onKeyPressEvent&quot;:null,&quot;onKeyUpEvent&quot;:null,&quot;onMouseDownEvent&quot;:null,&quot;onMouseOverEvent&quot;:null,&quot;onMouseUpEvent&quot;:null,&quot;passenger&quot;:{},&quot;propComment&quot;:&quot;补签签退时间 &quot;,&quot;propName&quot;:&quot;recordOffTime&quot;,&quot;propType&quot;:&quot;java.util.Date&quot;,&quot;tElementFormatter&quot;:&quot;format：HH:mm\r\n&quot;,&quot;tElementType&quot;:&quot;date&quot;,&quot;tIsRequired&quot;:0,&quot;tPropComment&quot;:&quot;补签签退时间 &quot;,&quot;tPropName&quot;:&quot;recordOffTime&quot;,&quot;tPropType&quot;:&quot;java.util.Date&quot;,&quot;tTbEntityFieldId&quot;:&quot;bac63001bd904c62bca02368cec6ee10&quot;,&quot;tTbEntityId&quot;:&quot;f9bbdeca665f4fa78ed86ca00a318079&quot;,&quot;tTbFieldLength&quot;:null,&quot;tbBusiformId&quot;:null,&quot;tbEntityFieldId&quot;:&quot;bac63001bd904c62bca02368cec6ee10&quot;,&quot;tbEntityId&quot;:&quot;f9bbdeca665f4fa78ed86ca00a318079&quot;,&quot;textFormatter&quot;:&quot;HH:mm\r\n&quot;,&quot;valueMaxLength&quot;:null,&quot;valueMinLength&quot;:null,&quot;model&quot;:&quot;zo.recordOffTime&quot;,&quot;option&quot;:[]}" :vo="zo" :can-edit="canEdit" /> -->
          <el-time-picker
            v-if="canEdit"
            v-model="zo.recordOffTime"
            :picker-options="{
              format: 'HH:mm'
            }"
            value-format="HH:mm"
            format="HH:mm"
            placeholder="补签签退时间"
            class="resign-time"
          />
          <span v-else>{{ zo.recordOffTime }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
    components: {

    },
    props: {
        vo: { type: Object, default: function() { return {} } },
        zo: { type: Object, default: function() { return {} } },
        canEdit: { type: Boolean, default: function() { return true } }
    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
    .resign-time {
        width: 100%;
    }
</style>
