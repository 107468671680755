<template>
  <div>
    <el-dialog v-if="dialogFormVisible" v-el-drag-dialog :visible.sync="dialogFormVisible" :close-on-click-modal="false" :width="'1000px'" :title="'批量导入员工家庭信息'">
      <el-form :model="vo">
        <div class="el-table el-table--fit el-table--border inner-table">
          <div class="el-table__body-wrapper">
            <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
              <tbody>
                <tr>
                  <td class="tdlbl tdw17"><span class="required">*</span>考勤审批</td>
                  <td class="tdcont tdw83">
                    <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="员工信息文件" rules="required">
                      <el-tooltip :disabled="errors[0]===undefined" :content="errors[0]" :class="{'invalid-field':errors[0]!==undefined}" popper-class="error-popper-class" placement="right-start" effect="dark">
                        <el-upload
                          ref="upload"
                          :on-change="handleUploadChange"
                          :action="'/yykq/monthlyStatisticsInfo/importKQ'"
                          :file-list="fileList"
                          :show-file-list="false"
                          :auto-upload="false"
                          :with-credentials="true"
                          :accept="'.xls,.xlsx'"
                        >
                          <el-input v-model="salaryAttachName" name="salaryAttachName" placeholder="请选择员工家庭信息文件">
                            <el-button slot="append" icon="el-icon-upload2" />
                          </el-input>
                        </el-upload>
                      </el-tooltip>
                    </ValidationProvider>
                  </td>
                </tr>
                <tr>
                  <td class="tdlbl tdw17">注意事项</td>
                  <td class="tdcont tdw83">
                    <div style="line-height:25px">
                      <span><strong>上传文件内容格式说明：</strong></span><br>
                      <span>1.导入文件应为Office Excel文件;</span><br>
                      <span>2.导入文件暂不支持Office 2007及以后版本的Excel文件，如您使用的是Office 2007及以后版本，请把Excel文件转换为2003或之前的版本格式，具体转换方式为：打开的Excel文件中，选择“另存为”->“Excel 97-2003工作簿”；</span><br>
                      <span>3.进行导入操作时，请确保您导入的文件并未处于使用状态。</span><br>
                      <span>
                        <strong>上传文件内容示例：</strong>
                      </span><br>
                      <span>
                        <a href="/templates/personMessaheImport.xls" target="_blank" title="点击下载"><span style="color:red;width:100%;">点击下载Excel导入示例</span></a>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button v-for="(btn,key) in busiformBtns" :key="key" :disabled="doing" :plain="btn.btnType===null || btn.btnType===''" :icon="'el-icon-'+btn.btnIcon" :type="btn.btnType!==null?btn.btnType:''" @click="btn.btnClick">{{ btn.btnName }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import qs from 'qs'
export default {
    components: {},
    props: {},
    data() {
        return {
            busiId: null,
            listData: [],
            dialogFormVisible: false,
            salaryAttachName: '',
            fileList: [],
            doing: false,
            busiformBtns: [
                {
                    id: 'btn_close',
                    btnName: '关 闭',
                    btnIcon: 'close',
                    btnClick: this.closeDialog,
                    btnPermission: '',
                    btnType: ''
                }, {
                    id: 'btn_save',
                    btnName: '导 入',
                    btnIcon: 'check',
                    btnClick: this.submitSalary,
                    btnPermission: 'saveObject',
                    btnType: 'primary'
                }
            ]
        }
    },
    computed: {},
    watch: {},
    created() {
        this.getData()
        this.$bus.on('openImportFamilyForm', this.openImportFamilyForm)
        console.log('location', location)
    },
    beforeDestroy() {
        this.$bus.off('openImportFamilyForm', this.openImportFamilyForm)
    },
    mounted() {
        console.log('this', this)
        this.$bus.on('show', (id) => {
            this.busiId = id
            this.dialogFormVisible = true
        })
        this.$bus.on('export', (data) => this.toExport(data))
    },
    methods: {
        toExport(data) {
            const title = [
                { title: '序号', width: 60, align: 'center', colspan: 1, rowspan: 1 },
                { title: '部门', width: 100, align: 'left', colspan: 1, rowspan: 1 },
                { title: '迟到时长', width: 120, align: 'center', colspan: 1, rowspan: 1 },
                { title: '早退时长', width: 120, align: 'center', colspan: 1, rowspan: 1 },
                { title: '旷工时长', width: 120, align: 'center', colspan: 1, rowspan: 1 },
                { title: '放射时长', width: 120, align: 'center', colspan: 1, rowspan: 1 },
                { title: '放射时长', width: 120, align: 'center', colspan: 1, rowspan: 1 },
                { title: '病假时长', width: 120, align: 'center', colspan: 1, rowspan: 1 },
                { title: '婚嫁时长', width: 120, align: 'center', colspan: 1, rowspan: 1 },
                { title: '产假时长', width: 120, align: 'center', colspan: 1, rowspan: 1 },
                { title: '探亲时长', width: 120, align: 'center', colspan: 1, rowspan: 1 },
                { title: '丧假时长', width: 120, align: 'center', colspan: 1, rowspan: 1 },
                { title: '进修时长', width: 120, align: 'center', colspan: 1, rowspan: 1 },
                { title: '事假时长', width: 120, align: 'center', colspan: 1, rowspan: 1 },
                { title: '年休时长', width: 120, align: 'center', colspan: 1, rowspan: 1 },
                { title: '公差时长', width: 120, align: 'center', colspan: 1, rowspan: 1 },
                { title: '出勤时长', width: 120, align: 'center', colspan: 1, rowspan: 1 }
            ]
            const tbtitle = []
            tbtitle.push(title)

            const tbdata = []
            data.forEach((list, listIndex) => {
                const row = [
                    { value: listIndex + 1, align: 'center', colspan: 1, rowspan: 1 },
                    { value: list.creator, align: 'left', colspan: 1, rowspan: 1 },
                    { value: list.createDept, align: 'center', colspan: 1, rowspan: 1 },
                    { value: list.lateTimes, align: 'center', colspan: 1, rowspan: 1 },
                    { value: list.leaveEarlyTimes, align: 'center', colspan: 1, rowspan: 1 },
                    { value: list.absenceTimes, align: 'center', colspan: 1, rowspan: 1 },
                    { value: list.radiateTimes, align: 'center', colspan: 1, rowspan: 1 },
                    { value: list.illTimes, align: 'center', colspan: 1, rowspan: 1 },
                    { value: list.marryTimes, align: 'center', colspan: 1, rowspan: 1 },
                    { value: list.maternityTimes, align: 'center', colspan: 1, rowspan: 1 },
                    { value: list.visitTimes, align: 'center', colspan: 1, rowspan: 1 },
                    { value: list.bereavementTimes, align: 'center', colspan: 1, rowspan: 1 },
                    { value: list.educationTimes, align: 'center', colspan: 1, rowspan: 1 },
                    { value: list.affairTimes, align: 'center', colspan: 1, rowspan: 1 },
                    { value: list.annualTimes, align: 'center', colspan: 1, rowspan: 1 },
                    { value: list.publicTimes, align: 'center', colspan: 1, rowspan: 1 },
                    { value: list.workTimes, align: 'center', colspan: 1, rowspan: 1 }
                ]
                tbdata.push(row)
            })

            this.$http({
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                data: qs.stringify({
                    rows: JSON.stringify(tbdata),
                    titles: JSON.stringify(tbtitle),
                    fileName: '科室月份考勤审批'
                }),
                url: 'common/exportXlsFile?httpPort=' + location.port
            }).then((res) => {
                if (res.code === 'success') {
                    window.open(encodeURI('/apigw/' + res.body.xlsurl), '_blank')
                } else {
                    this.$alert(res.message, '操作提示', { type: 'error' })
                }
            })
        },
        getData() {
            this.$http({
                method: 'POST',
                url: '/yykq/monthlyStatistics/zibiao'
            }).then(res => {
                this.listData = res
                console.log('res', res)
            })
        },
        openImportFamilyForm() {
            this.dialogFormVisible = true
        },
        handleUploadChange(filed, fileList) {
            if (filed.name.indexOf('.xls') === -1 && filed.name.indexOf('.xlsx')) {
                this.$alert('上传文件格式错误', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                })
                this.fileList = []
                this.salaryAttachName = ''
                return
            }
            this.fileList = [filed]
            this.salaryAttachName = filed.name
        },
        submitSalary() {
            this.formValidate(() => {
                this.doing = true
                const file = new FormData()
                file.append('file', this.fileList[0].raw)
                this.$http({
                    url: '/yykq/monthlyStatisticsInfo/importKQ',
                    method: 'POST',
                    data: file,
                    params: {
                        status: 1
                    }
                }).then(res => {
                    if (res.code === 'success') {
                        // this.$http({
                        //     method: 'POST',
                        //     url: '/yykq/monthlyStatisticsInfo/queryzibiao',
                        //     params: {
                        //         id: this.busiId
                        //     }
                        // }).then(res => console.log(res, '3333'))
                        this.$bus.emit('save')
                        this.$message({
                            message: res.message,
                            type: 'success'
                        })
                        this.closeDialog()
                        this.$nextTick(() => {
                            this.$bus.emit('refreshListData')
                        })
                    } else {
                        this.$message.error(res.message)
                    }
                    this.$nextTick(() => {
                        this.doing = false
                    })
                })
            })
        },
        closeDialog() {
            this.dialogFormVisible = false
            this.salaryAttachName = ''
            this.fileList = []
        }
    }
}
</script>

<style scoped lang="scss">
.el-tooltip{
  /deep/ .el-upload.el-upload--text{
      width: 100%;
  }
}
</style>
