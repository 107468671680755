<template>
  <PageContent :title="formTitle">
    <el-form>
      <div class="el-table el-table--fit el-table--border">
        <div class="el-table__body-wrapper">
          <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
            <tbody>
              <tr>
                <td class="el-table__cell tdcont">
                  <el-button size="small" :disabled="vo.status==0" type="primary" @click="openSignatureQr">手机扫码签名</el-button>
                  <div class="el-upload__tip">手机扫码打开画板，在手机上手写生成意见签名<br>支持任意有扫一扫功能的APP，如果不能正常打开签名画板，请选择“使用浏览器打开”</div>
                </td>
              </tr>
              <tr>
                <td class="el-table__cell tdcont">
                  <el-upload
                    :action="base_api + moduleInfo.uploadPhotoUrl"
                    :on-change="fileChange"
                    :on-error="handleUploadError"
                    :on-success="handleUploadSuccess"
                    accept=".jpg,.png"
                    :auto-upload="false"
                    :show-file-list="false"
                  >
                    <el-button size="small" :disabled="vo.status==0" type="primary">选择本地图片</el-button>
                    <div class="el-upload__tip">选择本地图片上传自己的意见签名，支持jpg、png格式的图片<br>请上传200K以下，像素150*75以上图片，以保证意见签名清晰度</div>
                  </el-upload>
                </td>
              </tr>
              <tr>
                <td class="el-table__cell tdcont">
                  <el-radio-group v-model="vo.wfType">
                    <el-radio :label="1">适用所有文件类型</el-radio>
                    <el-radio :label="0">仅适用指定的文件类型</el-radio>
                  </el-radio-group>
                  <WfNames v-if="vo.wfType === 0" :vo="vo" :can-edit="1" :value-field="'name'" />
                </td>
              </tr>
              <tr>
                <td class="el-table__cell tdcont">
                  <el-radio-group v-model="vo.status">
                    <el-radio :label="1" style="margin-right:10px">启用</el-radio>
                    <el-radio :label="0" style="margin-right:10px">禁用</el-radio>
                  </el-radio-group>
                  启用的意见签名图片将显示在流程表单的意见栏中
                </td>
              </tr>
              <tr>
                <td class="el-table__cell tdcont">意见签名预览</td>
              </tr>
              <tr>
                <td class="el-table__cell tdcont">
                  <div class="ideaDiv">同意 </div>
                  <div style="float:left;padding-left:300px">
                    <img v-if="photoUrl!=''&&vo.status==1" :src="photoUrl" height="75" width="150">
                    <span v-if="photoUrl==''||vo.status==0">&nbsp;&nbsp;{{ curUser.personName }}</span>
                    <span style="margin-right:100px;" />{{ curTime }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-form>
    <div slot="footer">
      <el-button plain icon="el-icon-check" @click="uploadFile">保 存</el-button>
    </div>

    <el-dialog v-el-drag-dialog :visible.sync="showQrCode" :close-on-click-modal="false" width="640px" title="意见签名" :before-close="closeSignatureQr">
      <div class="content">
        <img :src="codeimg" alt="">
        <p>手机扫一扫添加意见签名</p>如果不能正常打开签名画板，请选择“使用浏览器打开”
      </div>
    </el-dialog>
  </PageContent>
</template>

<script>
const moment = require('moment')

export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            formTitle: '意见签名',
            moduleInfo: {
                insertUrl: 'usersetting/signature/insert',
                updateUrl: 'usersetting/signature/update',
                uploadPhotoUrl: 'usersetting/signature/upload',
                querySignUrl: 'usersetting/signature/queryIdeaSignature'
            },
            curUser: this.$store.getters.loginUser,
            showQrCode: false,
            timer: null,
            photoFile: null,
            photoUrl: '',
            base_api: '',
            vo: { status: 1 },
            curTime: moment(new Date()).format('YYYY-MM-DD HH:mm')
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        this.$bus.emit('showNavSearch', false) // 取消右上搜索按钮
        this.base_api = process.env.VUE_APP_BASE_API
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true) // 还原右上搜索按钮
    },
    mounted() {
        // 获取用户是否已经上传意见签名图片,如果是则让img标签的src地址=获取后台意见签名图片的地址
        this.$http({
            method: 'GET',
            contentType: 'application/x-www-form-urlencoded',
            params: { },
            url: this.moduleInfo.querySignUrl
        }).then(response => {
            if (response.code === 'success') {
                if (response.body.vo) {
                    this.vo = response.body.vo
                    if (response.body.vo.imgFileName !== null) {
                        this.photoUrl = '/apigw/' + this.moduleInfo.uploadPhotoUrl.replace('upload', 'download') + '?dataField=IMG_FILE_DATA&nameField=IMG_FILE_NAME&id=' + response.body.vo.id
                    }
                }
            } else {
                this.$message.error(response.message)
                console.log(response.message)
            }
        }, err => {
            console.log(err)
        })
    },
    methods: {
        openSignatureQr() {
            // 手机扫码签名
            const goUrl = window.location.protocol + '//' + window.location.host + '/mindex.html#/mPdfSign'

            if (goUrl.indexOf('127.0.0.1') !== -1 || goUrl.indexOf('localhost') !== -1) {
                this.$alert('手机端不能访问' + window.location.protocol + '//' + window.location.host, '操作提示', { type: 'warning' })
                return
            }

            this.$http({
                method: 'GET',
                url: 'usersetting/signature/getSignatureQrCode',
                params: { signatureH5Url: goUrl }
            }).then(response => {
                if (response.code === 'success') {
                    this.codeimg = 'data:image/jpg;base64,' + response.body.image
                    this.tSessionId = response.body.tSessionId
                    this.timer = setInterval(() => { this.searchNewSign() }, 2000)

                    this.showQrCode = true
                } else {
                    this.$message.error(response.message)
                }
            })
        },
        closeSignatureQr() {
            this.showQrCode = false
            window.clearInterval(this.timer)
        },
        searchNewSign() {
            // 查询新增签名
            this.$http({
                method: 'GET',
                url: 'usersetting/signature/getById',
                params: { id: this.tSessionId }
            }).then(response => {
                if (response.code === 'success') {
                    this.vo = response.body.vo
                    if (response.body.vo.imgFileName !== null) {
                        this.photoUrl = '/apigw/' + this.moduleInfo.uploadPhotoUrl.replace('upload', 'download') + '?dataField=IMG_FILE_DATA&nameField=IMG_FILE_NAME&id=' + response.body.vo.id
                    }

                    this.closeSignatureQr()
                }
            })
        },
        uploadFile() {
            // 图片上传的方法
            if (this.vo.status === 1 && !this.photoFile && this.vo.id === undefined) {
                this.$message.warning('请选择意见签名图片')
                return
            }
            const _this = this
            const reqData = { contentType: 'application/x-www-form-urlencoded', data: this.vo }
            new Promise((resolve, reject) => {
                if (_this.vo.id === undefined) {
                    reqData.method = 'POST'
                    reqData.url = this.moduleInfo.insertUrl

                    this.vo.imgPercentage = 1
                    this.vo.imgXPos = 0
                    this.vo.imgYPos = 0
                    this.vo.imgFileName = this.vo.imgFileName || ''
                    this.vo.imgFileData = this.vo.imgFileData || ''
                } else {
                    reqData.method = 'PUT'
                    reqData.url = this.moduleInfo.updateUrl

                    this.vo.imgFileName = this.vo.imgFileName || ''
                    this.vo.imgFileData = this.vo.imgFileData || ''
                }

                this.$http(reqData).then(response => {
                    if (response.code === 'success') {
                        if (this.photoFile) {
                            if (response.body.vo) {
                                this.vo.id = response.body.vo.id
                            }
                            resolve(true)
                        } else {
                            this.$message.success(response.message)
                            reject('no upload file')
                        }
                    } else {
                        this.$message.error(response.message)
                        reject(response.message)
                    }
                })
            }).then(() => {
                const fd = new FormData()
                fd.append('file', this.photoFile[0].raw)
                fd.append('id', this.vo.id)
                fd.append('dataField', 'IMG_FILE_DATA')
                fd.append('nameField', 'IMG_FILE_NAME')
                this.$http({
                    method: 'PUT',
                    url: this.moduleInfo.uploadPhotoUrl,
                    data: fd
                }).then(data => {
                    if (data.code === 'success') {
                        this.$message.success('意见签名上传成功')
                        this.photoFile = null
                    } else {
                        this.$message.error(data.message)
                    }
                })
            })
        },
        // 选择文件
        fileChange(file, files) {
            if (this.beforeAvatarUpload(file)) {
                const f = []
                f.push(file)
                this.photoFile = f
            }
        },
        beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png'
            const isLt2M = file.raw.size / 1024 < 200

            if (!isJPG) {
                this.$message.error('意见签名图片只支持 JPG/PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('意见签名图片大小不能超过 200K!')
            }
            if (isJPG && isLt2M) {
                this.photoUrl = URL.createObjectURL(file.raw)
            }

            return isJPG && isLt2M
        },
        handleUploadError(err, file, fileList) {
            this.$message.error(err.message)
        },
        handleUploadSuccess(response, file, fileList) {
            this.$message.success(response.message)
        }
    }
}
</script>

<style scoped lang="scss">
    .el-table td{
        padding:4px;
    }
    .el-upload__tip{
        line-height: 16px;
        position: absolute;
        left:120px;
        top:0;
        text-align:left;
    }

</style>

