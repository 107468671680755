<template>
  <div class="blank-container page-holiday">
    <div class="app-container">
      <div class="el-table-toolbar">
        <div class="toolbar-left">
          <div style="display:flex;align-items:center">
            <span class="label">年份：</span>
            <el-date-picker v-model="searchDate.year" :clearable="false" type="year" placeholder="请选择年份" />
            <span class="label" style="padding:0 10px">节假日：</span>
            <el-select v-model="searchDate.holiday" placeholder="请选择">
              <el-option label="全部" value="" />
              <el-option v-for="i in holidayList" :key="i.id" :label="i.name" :value="i.id" />
            </el-select>
            <span style="padding:0 10px">
              <el-button type="primary" @click="getListInfo">统计</el-button>
            </span>
          </div>
        </div>
        <div class="toolbar-right">
          <el-button-group>
            <el-button v-table-toxls="views" style="height:32px" plain file-name="节假日值班安排表">
              <svg-icon icon-class="export" /> 导出
            </el-button>
          </el-button-group>
        </div>
      </div>

      <div>
        <el-table id="views" :data="tableData" border style="width: 100%">
          <el-table-column prop="arrangeDatetime" label="日期" width="100" align="center">
            <template slot-scope="scope">
              {{ changeData(scope.row.arrangeDatetime) }}
            </template>
          </el-table-column>
          <el-table-column prop="responseLevel" label="响应级别" width="100" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.responseLevel+"级" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="leaderName" label="带班领导" width="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.leaderContact">{{ scope.row.leaderName+"（"+scope.row.leaderContact+'）' }} </span>
              <span v-else>{{ scope.row.leaderName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="holidayDep" label="值班部门" width="150" align="center" />
          <el-table-column prop="address" label="白班值班人员" width="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.dayContact">{{ scope.row.dayName +"（"+scope.row.dayContact +'）' }} </span>
              <span v-else>{{ scope.row.dayName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="晚班值班人员" width="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.nightContact">{{ scope.row.nightName +"（"+scope.row.nightContact +'）' }} </span>
              <span v-else>{{ scope.row.nightName }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

  </div>
</template>

<script>
import qs from 'qs'
import { mapGetters } from 'vuex'
const moment = require('moment')
export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            searchDate: {
                year: moment().format('YYYY'),
                holiday: ''
            },
            tableData: [],
            ids: []
        }
    },
    computed: {
        ...mapGetters(['routes', 'loginUser']),
        changeData() {
            return (data) => {
                return moment(data).format('M月D号')
            }
        }
    },
    watch: {
        'searchDate.year': {
            handler: function(value) {
                if (value) {
                    this.searchDate.year = moment(value).format('YYYY')
                    this.searchDate.holiday = ''
                    this.getHolidayList()
                } else {
                    this.holidayList = []
                }
            },
            deep: true,
            immediate: true

        }
    },
    created() {
        this.$bus.$emit('showNavSearch', false)
        this.getHolidayList()
    },
    beforeDestroy() {
        this.$bus.$emit('showNavSearch', true)
    },
    mounted() {
        this.getListInfo()
    },
    methods: {
    // 获取节假日选择列表
        getHolidayList() {
            this.$http({
                method: 'POST',
                contentType: 'application/json charset=utf-8',
                url: '/xzhq/holiday/listdata',
                data: qs.stringify({
                    urlsearch: `status:1;orgCode:${this.loginUser.orgCode};beginDay>:${this.searchDate.year}-01-01 00:00:00;beginDay<:${this.searchDate.year}-12-31 23:59:59`
                })
            }).then((res) => {
                if (res.code === 'success') {
                    this.ids = []
                    this.holidayList = res.body.pageInfo.list
                    res.body.pageInfo.list.map((item) => {
                        this.ids.push(item.id)
                    })
                    if (this.ids.length === 0) this.ids.push(false)
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 查询
        getListInfo() {
            console.log('this.search', this.searchDate)
            this.$http({
                method: 'POST',
                contentType: 'application/json charset=utf-8',
                url: '/xzhq/holidayArrangeDetail/listdata',
                data: qs.stringify({
                    orderBy: 'arrangeDatetime asc',
                    urlsearch: this.searchDate.holiday ? `holidayId:${this.searchDate.holiday}` : `holidayId in ${this.ids.join(',')}`,
                    orgCode: this.loginUser.orgCode
                })
            }).then((res) => {
                if (res.code === 'success') {
                    this.tableData = res.body.pageInfo.list
                } else {
                    this.$message.error(res.message)
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.page-holiday {
  .el-table-toolbar {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .toolbar-right {
      /deep/ .el-button-group {
        .el-button:hover {
          background: #fff;
          border-color: #409eff;
          color: #409eff;
        }
      }
    }
  }
  .label {
    line-height: 36px;
    font-size: 14px;
    color: #606266;
  }
}
</style>
