<template>

  <div v-loading="loading" :element-loading-text="ssoLoginMsg" style="width:100%;height:100%" />

</template>

<script>

export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            loading: true,
            ssoLoginMsg: '正在登陆...',
            corpId: '',
            agentId: ''
        }
    },
    computed: {

    },
    watch: {
    },
    created() {
        // const jwtCookieName = 'ECWEB-JWTSSO-TOKEN-' + location.port
        // const jwtToken = this.Cookies.get(jwtCookieName)
        const appId = this.$route.query.appId
        const packageCode = this.$route.query.packagecode
        const tokenCode = this.$route.query.code

        this.$http({
            method: 'POST',
            url: 'login/cmcc/ssoLogin',
            params: {
                appId: appId,
                packagecode: packageCode,
                code: tokenCode
            }
        }).then(rest => {
            if (rest.code === 'success') {
                this.ssoLoginMsg = '登录成功，即将进入系统...'

                this.$store.dispatch('user/tokenSsoLogin', rest.body)
                    .then(res => {
                        this.$router.replace('/#home')
                    })
            } else {
                this.ssoLoginMsg = rest.message
            }
        }).catch((err) => {
            this.ssoLoginMsg = err
        })
    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
    }
}
</script>

<style scoped lang="scss">

</style>
