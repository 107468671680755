<template>
  <el-popover
    ref="slideVerifyPopover"
    v-model="slideVerifyVisible"
    placement="top"
    trigger="manual"
    popper-class="slide_verify_popover"
  >
    <div class="slide_verify_popover_box">
      <img
        :src="resultMap.bigImage"
        class="slide_verify_popover_img slide_verify_img_big"
      >
      <!-- <img
        :src="resultMap.smallImage"
        class="slide_verify_popover_img slide_verify_img_small"
        :style="{ top: resultMap.yHeight, left: smallImgLeft }"
      > -->
      <div
        class="slide_verify_popover_img slide_verify_img_small"
        :style="{ top: resultMap.yHeight, left: smallImgLeft }"
      >
        <img :src="resultMap.smallImage" style="vertical-align: middle">
      </div>
      <i v-if="!slideVerifyType" class="el-icon-refresh-right slide_verify_img_refresh" @click="refreshSlideImg" />
    </div>
    <div slot="reference" ref="slideVerifyRail" class="slide_verify_rail " :class="{slide_verify_rail_error:!~slideVerifyType}">
      <div
        class="slide_verify_rail_left"
        :style="{ width: slideLeftWidth }"
      />
      <div class="slide_verify_rail_right">
        <span
v-if="slideRailRightTextShow"
              class="slide_rail_right_text"
        >请拖动滑块至缺口</span>
      </div>
      <div
        ref="slideVerifyRailBtn"
        class="slide_verify_rail_btn"
        :style="{ left: slideBtnLeft }"
        unselectable="on"
        onselectstart="return false;"
        @mousedown.prevent="slideBtnMousedown"
        @touchstart.prevent.stop="slideBtnMousedown"
        @touchmove.prevent.stop="mousemoveFn"
        @touchend.prevent.stop="mouseupFn"
      >
        <i v-if="!slideVerifyType" class="el-icon-right" />
        <i v-else-if="!~slideVerifyType" class="el-icon-close" />
        <i v-else class="el-icon-check" />
      </div>
    </div>
  </el-popover>
</template>

<script>
const encrypt = new window.JSEncrypt()
export default {
    components: {},
    props: {
        disabled: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            // 弹出层显示
            slideVerifyVisible: false,
            // 弹出层显示延迟
            slideVerifyVisibleTimer: null,
            // 滑轨左侧宽度
            slideLeftWidth: 0,
            // 滑块居左距离
            slideBtnLeft: 0,
            // 滑块移动范围
            slideBtnMoveExtent: 0,
            // 小图居左距离
            smallImgLeft: 0,
            // 小图移动范围
            smallImgMoveExtent: 0,
            // 鼠标初始位置
            mouseStartX: 0,
            // 滑轨右侧文字展示
            slideRailRightTextShow: true,
            // 能否刷新
            canRefresh: true,
            // 滑动状态：-1：失败；0：初始；1：成功
            slideVerifyType: 0,
            resultMap: {
                smallImage: '',
                bigImage: '',
                yHeight: ''
            },
            // 上次鼠标移入Y坐标
            mouseoverLastY: 0
        }
    },
    computed: {},
    watch: {},
    created() {},
    beforeDestroy() {
        document.removeEventListener('mouseover', this.mouseoverFn)
        document.removeEventListener('touchstart', this.touchOther)
    },
    mounted() {
        document.addEventListener('mouseover', this.mouseoverFn)
        document.addEventListener('touchstart', this.touchOther)
        this.getSlideImg()
    },
    methods: {
        init() {
            this.slideLeftWidth = 0
            this.slideBtnLeft = 0
            this.slideBtnMoveExtent = 0
            this.smallImgLeft = 0
            this.smallImgMoveExtent = 0
            this.slideVerifyType = 0
            this.slideRailRightTextShow = true
        },
        getRsaPublicKey() {
            this.$http({
                method: 'GET',
                url: 'login/getRsaPublicKey'
            }).then(res => {
                if (res.code !== 'success') {
                    this.error = '服务异常，请稍后重试'
                    return
                }
                encrypt.setPublicKey(res.body.rsaPublicKey)
            })
        },
        getSlideImg() {
            this.$http({
                method: 'GET',
                url: '/login/slide/getImageVerifyCode'
            }).then((res) => {
                if (res.code !== 'success') {
                    return
                }
                const { bigImage, smallImage, yHeight, sessionId } = res.body.resultMap
                this.resultMap.bigImage = 'data:image/png;base64,' + bigImage
                this.resultMap.smallImage = 'data:image/png;base64,' + smallImage
                this.resultMap.yHeight = yHeight + 'px'
                this.resultMap.sessionId = sessionId

                this.init()
            })
        },
        // 滑块鼠标按下事件
        slideBtnMousedown(e) {
            if (this.disabled) return
            if (this.slideVerifyType) return
            this.slideVerifyVisible = true
            this.slideRailRightTextShow = false
            this.mouseStartX = e.clientX || e.touches[0].clientX
            const slideBtn = this.$refs.slideVerifyRailBtn
            const slideBtnWidth = slideBtn.offsetWidth
            const slideRail = slideBtn.parentElement
            const slideRailWidth = slideRail.clientWidth
            this.slideBtnMoveExtent = slideRailWidth - slideBtnWidth
            this.$nextTick(() => {
                const bigImg = document.querySelector(
                    '.slide_verify_popover_img.slide_verify_img_big'
                )
                const bigImgWidth = bigImg.offsetWidth
                const smallImg = document.querySelector(
                    '.slide_verify_popover_img.slide_verify_img_small'
                )
                const smallImgWidth = smallImg.offsetWidth
                this.smallImgMoveExtent = bigImgWidth - smallImgWidth
            })
            document.addEventListener('mousemove', this.mousemoveFn)
            document.addEventListener('mouseup', this.mouseupFn)
        },
        // 鼠标移入事件
        mouseoverFn(e) {
            if (this.disabled) return
            // 是否向上移动
            let moveUp = false
            const mouseoverLastY = this.mouseoverLastY
            this.mouseoverLastY = e.screenY
            if (mouseoverLastY > e.screenY) moveUp = true
            // 是否移入滑轨
            const inRail = this.$refs.slideVerifyRail.contains(e.target)
            // 从下向上移入滑轨不触发浮窗
            if (inRail && moveUp) return
            // 是否移入浮窗
            const inPopover = this.$refs.slideVerifyPopover.$refs.popper.contains(
                e.target
            )
            if (inPopover || inRail || this.mouseStartX) {
                if (this.slideVerifyVisibleTimer) {
                    clearTimeout(this.slideVerifyVisibleTimer)
                    this.slideVerifyVisibleTimer = null
                }
                this.slideVerifyVisible = true
            } else {
                if (this.slideVerifyVisibleTimer) return
                this.slideVerifyVisibleTimer = setTimeout(() => {
                    this.slideVerifyVisible = false
                }, 500)
            }
        },
        // 鼠标移动事件
        mousemoveFn(e) {
            const mouseNowX = e.clientX || e.touches[0].clientX
            let mouseMoveX = mouseNowX - this.mouseStartX
            if (mouseMoveX < 0) mouseMoveX = 0
            if (mouseMoveX > this.slideBtnMoveExtent) {
                mouseMoveX = this.slideBtnMoveExtent
            }
            this.slideBtnLeft = mouseMoveX + 'px'
            const ratio = mouseMoveX / this.slideBtnMoveExtent
            this.slideLeftWidth = ratio * 100 + '%'
            this.smallImgLeft = this.smallImgMoveExtent * ratio + 'px'
        },
        // 鼠标松开事件
        mouseupFn() {
            this.mouseStartX = 0
            document.removeEventListener('mousemove', this.mousemoveFn)
            document.removeEventListener('mouseup', this.mouseupFn)
            const xWidth = parseFloat(this.smallImgLeft)
            // verifyImageCode
            this.$http({
                method: 'get',
                url: '/login/slide/verifyImageCode',
                params: {
                    sessionId: this.resultMap.sessionId,
                    // xWidth: encrypt.encrypt(parseFloat(this.smallImgLeft))
                    xWidth
                }
            }).then((res) => {
                if (res.code !== 'success') {
                    this.slideVerifyType = -1
                    setTimeout(() => {
                        this.refreshSlideImg()
                        this.init()
                    }, 2000)
                    return
                }
                this.slideVerifyType = 1
                this.$emit('confim', xWidth)
            })
        },
        // 刷新图片
        refreshSlideImg() {
            if (!this.canRefresh) return
            this.canRefresh = false
            this.getSlideImg()
            const refreshTimer = setTimeout(() => {
                this.canRefresh = true
                clearTimeout(refreshTimer)
            }, 500)
        },
        touchOther(e) {
            // 是否移入滑轨
            const inRail = this.$refs.slideVerifyRail.contains(e.target)
            // 是否移入浮窗
            const inPopover = this.$refs.slideVerifyPopover.$refs.popper.contains(
                e.target
            )
            if (!inPopover && !inRail) {
                this.slideVerifyVisible = false
            }
        }
    }
}
</script>
<style lang="scss">
.slide_verify_popover {
  .slide_verify_popover_box {
    position: relative;
    .slide_verify_popover_img {
      vertical-align: middle;
    }
    .slide_verify_img_small {
      position: absolute;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 5px solid #21e683;

      }
    }
    .slide_verify_img_refresh {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 24px;
      color: #fff;
    }
  }
}
</style>
<style scoped lang="scss">
.slide_verify_rail {
  border-radius: 2px;
  height: 38px;
  background-color: #f7f9fa;
  border: 1px solid #e4e7eb;
  position: relative;
  display: flex;
  .slide_verify_rail_left {
    border: 1px solid #1991fa;
    background: #d1e9fe;
    border-radius: 2px;
  }
  .slide_verify_rail_right {
    flex: 1;
    border: 1px solid #e4e7eb;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    .slide_rail_right_text {
      user-select: none;
      padding-left: 38px;
      font-size: 12px;
    }
  }
  .slide_verify_rail_btn {
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
    position: absolute;
    top: 0;
    height: 100%;
    width: 38px;
    // background-color: #d1e9fe;
    background-color: #fff;
    border: 1px solid #1991fa;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }
}
.slide_verify_rail_error{
  .slide_verify_rail_left{
    border-color: #fe3931;
    background-color: #fa96a0;
  }
  .slide_verify_rail_btn{
    border-color: #fe3931;
  }
}
</style>
