<template>
  <div class="icons-container">

    <el-tabs type="border-card">
      <el-tab-pane label="Icons">
        <div v-for="item of svgIcons" :key="item" @click="handleClipboard(generateIconCode(item),$event)">
          <el-tooltip placement="top">
            <div slot="content">
              {{ generateIconCode(item) }}
            </div>
            <div class="icon-item">
              <svg-icon :icon-class="item" class-name="disabled" />
              <span>{{ item }}</span>
            </div>
          </el-tooltip>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Element-UI Icons">
        <div v-for="item of elementIcons" :key="item" @click="handleClipboard(generateElementIconCode(item),$event)">
          <el-tooltip placement="top">
            <div slot="content">
              {{ generateElementIconCode(item) }}
            </div>
            <div class="icon-item">
              <i :class="'el-icon-' + item" />
              <span>{{ item }}</span>
            </div>
          </el-tooltip>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import clipboard from '@/wapp/common/utils/clipboard'
import svgIcons from './svg-icons'
import elementIcons from './element-icons'

export default {
    name: 'Icons',
    data() {
        return {
            svgIcons,
            elementIcons
        }
    },
    created() {
        this.$bus.$emit('showNavSearch', false)
    },
    beforeDestroy() {
        if (this.$vnode.context.iconDialogFormVisible) {
            this.$bus.$emit('showNavSearch', true)
        }
    },
    methods: {
        generateIconCode(symbol) {
            return symbol + '.svg'
        },
        generateElementIconCode(symbol) {
            return symbol
        },
        handleClipboard(text, event) {
            clipboard(text, event)
            if (this.$vnode.context.iconDialogFormVisible) {
                if (this.$vnode.context.vo) {
                    if (this.$vnode.context.vo.icon !== undefined) {
                        this.$vnode.context.$set(this.$vnode.context.vo, 'icon', text)
                    } else if (this.$vnode.context.vo.code !== undefined) {
                        this.$vnode.context.$set(this.$vnode.context.vo, 'code', text)
                    }
                }
                if (this.$vnode.context.newMenu) {
                    this.$vnode.context.$set(this.$vnode.context.newMenu, 'code', text)
                }
                this.$vnode.context.$forceUpdate()
                this.$vnode.context.iconDialogFormVisible = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.icons-container {
  margin: 10px 20px 0;
  overflow: hidden;

  .icon-item {
    margin: 20px;
    height: 85px;
    text-align: center;
    width: 100px;
    float: left;
    font-size: 30px;
    color: #24292e;
    cursor: pointer;
  }

  span {
    display: block;
    font-size: 16px;
    margin-top: 10px;
  }

  .disabled {
    pointer-events: none;
  }
}
</style>
