<template>
    <PageContent :title="formTitle">
        <el-form :model="vo">
            <div class="el-table el-table--fit el-table--border">
                <div class="el-table__body-wrapper">
                    <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
                        <tbody>
                            <tr>
                                <td class="el-table__cell tdlbl tdw17">消息服务器</td>
                                <td class="el-table__cell tdcont tdw83" colspan="3">
                                    <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="消息服务器" rules="required">
                                        <el-tooltip :disabled="errors[0] === undefined" :content="errors[0]" :class="{ 'invalid-field': errors[0] !== undefined }" popper-class="error-popper-class" placement="right-start" effect="dark">
                                            <el-input v-model="wsurl" type="input" placeholder="请输入消息服务器地址" name="wsurl" resize="none" />
                                        </el-tooltip>
                                    </ValidationProvider>
                                </td>
                            </tr>
                            <tr>
                                <td class="el-table__cell tdlbl tdw17">消息用户ID</td>
                                <td class="el-table__cell tdcont tdw83" colspan="3">
                                    <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="消息用户ID" rules="required">
                                        <el-tooltip :disabled="errors[0] === undefined" :content="errors[0]" :class="{ 'invalid-field': errors[0] !== undefined }" popper-class="error-popper-class" placement="right-start" effect="dark">
                                            <el-input v-model="wsuserId" type="input" placeholder="请输入消息用户ID" name="wsurl" resize="none" @change="setFromUserId(val)" />
                                        </el-tooltip>
                                    </ValidationProvider>
                                </td>
                            </tr>
                            <tr>
                                <td class="el-table__cell tdlbl tdw17">发送消息</td>
                                <td class="el-table__cell tdcont tdw83" colspan="3">
                                    <ValidationProvider ref="ValidationProvider" v-slot="{ errors }" name="发送消息" rules="required">
                                        <el-tooltip :disabled="errors[0] === undefined" :content="errors[0]" :class="{ 'invalid-field': errors[0] !== undefined }" popper-class="error-popper-class" placement="right-start" effect="dark">
                                            <el-input v-model="sendmsg" type="textarea" :row="10" placeholder="请输入待发送消息" name="sendmsg" resize="none" />
                                        </el-tooltip>
                                    </ValidationProvider>
                                </td>
                            </tr>
                            <tr>
                                <td class="el-table__cell tdlbl tdw17">接收消息</td>
                                <td class="el-table__cell tdcont tdw83" colspan="3">
                                    <div v-html="showmsg" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </el-form>
        <div slot="footer">
            <el-button ref="btnSubmit" icon="el-icon-check" @click="resetWebSocket">重新连接</el-button>
            <el-button ref="btnSubmit" type="primary" icon="el-icon-check" @click="sendMessage">发送消息</el-button>
        </div>
    </PageContent>
</template>

<script>
const moment = require('moment')
export default {
    components: {},
    props: {},
    data() {
        return {
            formTitle: '发送消息',
            websocket: null,
            wsurl: '',
            wsuserId: this.$store.getters.loginUser.userId,
            loginUser: this.$store.getters.loginUser,
            showmsg: '',
            sendmsg: '{"toUsers":[],"message":{"fromUser":{"userId":"234","userName":"李四"},"body":{"messageId":"2123","type":"100","content":"你好"}},"ticket":"xxx"}'
        }
    },
    computed: {},
    watch: {},
    created() {
        this.$bus.$emit('showNavSearch', false)
        this.initWebSocket()
    },
    beforeDestroy() {
        this.$bus.$emit('showNavSearch', true)
        this.closeWebSocket()
    },
    mounted() {
        const message = JSON.parse(this.sendmsg)
        if (message.toUsers) {
            message.toUsers.push({ userId: this.loginUser.userId, userName: this.loginUser.personName })
        }
        //message.jwtToken = window.$auth.getToken()
        message.ticket = window.$auth.getTicket()
        message.message.fromUser.userId = this.wfuserId

        this.sendmsg = JSON.stringify(message)
    },
    methods: {
        setFromUserId(val) {
            const message = JSON.parse(this.sendmsg)
            message.message.fromUser.userId = this.wfuserId

            this.sendmsg = JSON.stringify(message)
        },
        sendMessage() {
            const _this = this
            this.formValidate(() => {
                _this.websocket.send(_this.sendmsg)
                _this.showMessage('发送成功')
            })
        },
        showMessage(innerHTML) {
            this.showmsg = moment(new Date()).format('HH:mm:ss') + ' ' + innerHTML
        },
        closeWebSocket() {
            this.websocket.close()
        },
        resetWebSocket() {
            this.initWebSocket(this.wsurl)
        },
        initWebSocket(wsurl) {
            // 判断当前浏览器是否支持WebSocket
            if ('WebSocket' in window) {
                // 连接WebSocket节点
                if (!wsurl) {
                    wsurl = location.href.split('#')[0]
                    wsurl = wsurl.replace('https:', 'wss:').replace('http:', 'ws:') + 'webSocket/'
                    this.wsurl = wsurl
                } else if (this.websocket != null) {
                    try {
                        this.websocket.close()
                    } catch (e) {
                        console.log(e)
                    }
                }

                this.websocket = new WebSocket(wsurl + this.wsuserId) //
            } else {
                this.showMessage('Not support websocket')
            }

            // 连接发生错误的回调方法
            this.websocket.onerror = () => {
                this.showMessage('error')
            }

            // 连接成功建立的回调方法
            this.websocket.onopen = (event) => {
                this.showMessage('open')
            }

            // 接收到消息的回调方法
            this.websocket.onmessage = (event) => {
                this.showMessage(event.data)
            }

            // 连接关闭的回调方法
            this.websocket.onclose = (e) => {
                this.showMessage(JSON.stringify(e))
            }

            // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
            window.onbeforeunload = () => {
                this.websocket.close()
            }
        }
    }
}
</script>

<style scoped lang="scss">
.tblbl-left {
    /deep/ .el-button {
        float: right;
    }
}
</style>
