<template>

  <el-dialog v-el-drag-dialog :visible.sync="dialogShow" :close-on-click-modal="false" :destroy-on-close="true" title="考勤范围设置" class="map-box" append-to-body @opened="dialogOpened()">
    <div class="m-main">
      <div id="container" class="m-main-inner" />
      <div class="m-a-i-box">
        <span>请输入地址：</span>
        <div class="m-a-i">
          <input id="searchInput" type="text" class="">
        </div>
      </div>
      <div id="amap-sug-result" />
    </div>
    <div class="m-bottom">
      <div class="m-a">
        <div class="" style="margin-top: 10px;">
          打卡范围：
          <select id="gpsScope" name="gpsScope" value="" style="width: 160px; min-width: 80px;" @change="scopeChangeEvent()">
            <template v-for="item in gpsScopeList">
              <option :key="item.value" :value="item.value">{{ item.txt }}</option>
            </template>
          </select>
        </div>
        <div style="margin-top: 10px;">
          地址名称：
          <input id="name" type="text" name="">
        </div>

        <div style="margin-top: 10px;">
          详细地址：
          <input id="unitAddress" type="text" name="" style="width: 320px">
        </div>
        <input id="lng" type="hidden" value="">
        <input id="lat" type="hidden" value="">
      </div>
      <!-- <div class="m-btn">
        <button type="button" class="ec-btn" ng-click="closeThisDialog();">取消</button>
        <button type="button" class="ec-btn ec-primary-btn" ng-click="selectedAddrEvent()">确定</button>
      </div> -->
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogShow = false">取 消</el-button>
      <el-button type="primary" @click="selectedAddrEvent()">确 定</el-button>
    </span>
  </el-dialog>

</template>
<script type="text/javascript">
import AMap from 'AMap'
export default {
    name: 'MAP',
    props: {
        gpsScopeList: {
            type: Array,
            default() {
                return []
            }
        },
        vo: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            dialogShow: false,
            map: null,
            circle: null,
            moveMarker: null,
            geocoder: null
        }
    },
    created() {
        this.$bus.on('showMap', this.openMap)
    },
    beforeDestroy() {
        this.$bus.off('showMap')
    },
    methods: {
        /**
        * 打开
        */
        dialogOpened() {
            this.init()
        },
        openMap() {
            this.dialogShow = true
        },
        /**
        * 关闭
        */
        handleClose() {
            // this.$emit('input', false)
        },
        init() {
            const map = new AMap.Map('container', {
                resizeEnable: true,
                zoom: 13
            })
            this.map = map
            const circle = new AMap.Circle({
                strokeColor: '#1890FF', // 边框线颜色
                strokeOpacity: 0.8, // 边框线透明度
                strokeWeight: 1, // 边框线宽
                fillColor: '#1791fc', // 填充色
                fillOpacity: 0.35, // 填充透明度
                draggable: true
            })
            this.circle = circle

            this.geocoder = new AMap.Geocoder({
                city: '', // 城市设为北京，默认：“全国”
                radius: 1000 // 范围，默认：500
            })
            // 拖拽
            circle.on('dragstart', (e) => {
                console.log('start')
                var center = circle.getCenter()
                if (this.moveMarker) map.remove(this.moveMarker)
                this.moveMarker = new AMap.Marker({
                    position: [center.lng, center.lat]
                })
                map.add(this.moveMarker)
            })
            circle.on('dragging', (e) => {
                var center = circle.getCenter()
                this.moveMarker.setPosition(center)
            })
            circle.on('dragend', (e) => {
                var center = circle.getCenter()
                console.log('end')
                this.getAddress(center.lng, center.lat)
            })

            var auto = new AMap.Autocomplete({
                input: 'searchInput',
                output: 'amap-sug-result'
            })

            // 构造地点查询类
            this.placeSearch = new AMap.PlaceSearch({
                map: map // 展现结果的地图实例
            })

            // 添加点击事件
            AMap.event.addListener(this.placeSearch, 'markerClick', (e) => {
                if (this.moveMarker) map.remove(this.moveMarker)
                if (e.data.address) {
                    this.postMapMessage({
                        address: e.data.address,
                        lng: e.data.location.lng,
                        lat: e.data.location.lat,
                        name: e.data.name || e.data.address
                    })
                } else {
                    this.getAddress(e.data.location.lng, e.data.location.lat)
                }
            })

            AMap.event.addListener(auto, 'select', (e) => {
                if (this.moveMarker) map.remove(this.moveMarker)
                if (e.poi.address) {
                    this.postMapMessage({
                        address: e.poi.address,
                        lng: e.poi.location.lng,
                        lat: e.poi.location.lat,
                        name: e.poi.name || e.poi.address
                    })
                } else {
                    this.getAddress(e.poi.location.lng, e.poi.location.lat)
                }
                this.placeSearch.setCity(e.poi.adcode)
                this.placeSearch.search(e.poi.name)
            })
        },
        // 范围选择事件
        scopeChangeEvent() {
            if (!($('#lng').val() && $('#lat').val())) {
                return
            }
            this.addCircle(new AMap.LngLat($('#lng').val(), $('#lat').val()))
        },
        postMapMessage(data) {
            $('#name').val(data.name)
            $('#lng').val(data.lng)
            $('#lat').val(data.lat)
            $('#unitAddress').val(data.address)
            if (!this.moveMarker) {
                this.addCircle(new AMap.LngLat(data.lng, data.lat))
            }
        },
        addCircle(lnglat) {
            let radius = $('#gpsScope').val()
            if (!radius || radius === '? undefined:undefined ?') {
                radius = 100
                $('#gpsScope').val(100)
            }
            this.circle.setCenter(lnglat)
            this.circle.setRadius(Number(radius))
            this.map.add(this.circle)
        },
        getAddress(lng, lat) {
            this.geocoder.getAddress([lng, lat], (status, result) => {
                if (status === 'complete' && result.info === 'OK') {
                    this.postMapMessage({
                        address: result.regeocode.formattedAddress,
                        lng: lng,
                        lat: lat,
                        name: result.regeocode.pois.length > 0 ? result.regeocode.pois[0].name : (result.regeocode.addressComponent.district + result.regeocode.addressComponent.street)
                    })
                }
            })
        },
        // 确定选择考勤地址
        selectedAddrEvent() {
            const $scope = this
            if (!$('#name').val()) return
            if (!($('#lng').val() && $('#lat').val())) {
                return this.$alert('请选择考勤地点', '提示')
            }
            this.$emit('mapSure', { id: '', gpsScope: $('#gpsScope').val(), gpsXy: $('#lng').val() + ', ' + $('#lat').val(), unitAddress: $('#unitAddress').val(), memo: '', passenger: {}, personSchId: $scope.vo.id, status: 0 })
            this.dialogShow = false
        }
    }

}

</script>
<style lang="scss" scoped>
	.map-box {
		padding: 30px;
	}

	.m-main {
		width: 100%;
		height: 400px;
		position: relative;
	}

	.m-main-inner {
		width: 100%;
		height: 100%;
	}

	.m-a-i-box {
		width: 220px;
		height: 92px;
		padding: 12px;
		position: absolute;
		top: 0;
		right: 0;
		border: 1px solid #DDDDDD;
		background-color: rgba(255, 255, 255, .9);
	}

	.m-a-i {
		margin-top: 8px;
		width: 100%;
		height: 28px;
	}

	.m-a-i input {
		width: 100%;
		height: 100%;
		border: 1px solid #DDDDDD;
		border-radius: 3px;
	}

	.m-bottom {
		display: table;
		width: 100%;
		height: 80px;
		margin-top: 15px;
	}

	.m-a,
	.m-btn {
		display: table-cell;
		vertical-align: middle;
	}

	.m-btn {
		width: 25%;
		text-align: right;
		vertical-align: bottom;
	}

	.m-a input, .m-a select {
		width: 160px;
		height: 28px;
	}

	#amap-sug-result {
		width: 220px;
		height: auto;
		max-height: 300px;
		position: absolute;
		top: 76px;
		right: 0;
		background-color: #fefefe;
		overflow-y: auto;
	}
	#amap-sug-result .auto-item {
		width: 100%;
		white-space: pre-wrap;
	}
</style>
