<template>
  <div id="aa" class="app-container">
    <div class="data-table-container">
      <div class="el-table-toolbar">
        <el-row>
          <el-col class="el-table-toolbar-left" :span="8">
            <span class="el-icon-arrow-left" style="cursor: pointer;" @click="getDate('last')" />
            {{ startDate }} 至 {{ endDate }}
            <span class="el-icon-arrow-right" style="cursor: pointer;" @click="getDate('next')" />
            <span style="color: var(--bgColor);cursor: pointer;" @click="getDate('now')">本周</span>
          </el-col>
          <el-col class="el-table-toolbar-right" :span="16" style="display: flex;flex-direction: row;justify-content: flex-end;align-items: center;">
            <span>图例：</span>
            &nbsp;
            <span style="width: 15px;height: 3px;display: inline-block;background:#48ba79;"></span>
            &nbsp;
            <span>已排</span>
            &nbsp;&nbsp;
            <span style="width: 15px;height: 3px;display: inline-block;background:#f27707;"></span>
            &nbsp;
            <span>未排</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </el-col>
        </el-row>
      </div>
      <div v-if="listData.length > 0">
        <el-table
          :data="listData"
          border
          :max-height="tableHeight"
          style="width: 100%"
        >
          <el-table-column
            prop="dutyDepart"
            label="部门"
            align="center"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="data"
            label="日期"
            width="120"
          >

            <el-table-column
              prop="dutyPerson"
              label="排班"
              align="center"
            >
              <template slot="header" slot-scope="scope">
                <el-checkbox v-model="allChanged" @change="changeAll"><div style="width:60px">排班</div></el-checkbox>
              </template>
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.changed" @change="changeName(scope)"><div style="width:60px">{{ scope.row.dutyPerson }}</div></el-checkbox>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            v-for="(option,optionkey) in times"
            :key="optionkey"
            align="left"
            header-align="center"
            :label="option.month+'月'+option.day+'日 '+option.sign"
            :label-class-name="option.year===nowyear&&option.month===nowmoth&&option.day===nowday? 'current':''"
            class-name="extrude"
          >

            <!-- <el-table-column
              label="早餐"
              align="center"
            >
              <template slot="header" slot-scope="scope">
                <el-checkbox v-model="option.breakfastChange" :disabled="nowDate > listData[0].dutyInfo[optionkey].breakfastJiezhiTime" @change="changeNameColumn(optionkey,option.breakfastChange,'breakfast')">早餐</el-checkbox>
              </template>
              <template slot-scope="scope">
                <div v-if="nowDate<=option.year + '-' + option.month + '-' + option.day" style="height:24px;" @click="selectFn(scope.$index,optionkey,'breakfast')">
                  <img
                    v-if="scope.row.dutyInfo[optionkey].breakfast"
                    style="height: 14px; width: 16px"
                    src="/images/checkTable.png"
                    alt=""
                  >
                </div>
                <div v-else style="height:24px;">
                  <img
                    v-if="scope.row.dutyInfo[optionkey].breakfast"
                    style="height: 14px; width: 16px"
                    src="/images/checkTable.png"
                    alt=""
                  >
                </div>
              </template>
            </el-table-column> -->

            <el-table-column
              label="全选"
              align="center"
            >
              <template slot="header" slot-scope="scope">
                <el-checkbox v-model="option.schedulingChange" :disabled="nowDate > listData[0].dutyInfo[optionkey].pbJiezhiTime" @change="changeNameColumn(optionkey,option.schedulingChange,'scheduling')">全选</el-checkbox>
              </template>
              <template slot-scope="scope">
                <div v-if="nowDate<=option.year + '-' + option.month + '-' + option.day" style="height:24px;" @click="selectFn(scope.$index,optionkey,'scheduling')">
                  <!-- <img
                    v-if="scope.row.dutyInfo[optionkey].scheduling"
                    style="height: 14px; width: 16px"
                    src="/images/checkTable.png"
                    alt=""
                  > -->
                  <span v-if="scope.row.dutyInfo[optionkey].scheduling" style="color:#48ba79;font-size:18px">✔</span>
                  <span v-else-if="scope.row.dutyInfo[optionkey].schedulingMonthStatus === '0'" style="color:#f27707;font-size:18px">✔</span>

                </div>
                <div v-else style="height:24px;">
                  <!-- <img
                    v-if="scope.row.dutyInfo[optionkey].scheduling"
                    style="height: 14px; width: 16px"
                    src="/images/checkTable.png"
                    alt=""
                  > -->

                  <span v-if="scope.row.dutyInfo[optionkey].scheduling" style="color:#48ba79;font-size:18px">✔</span>
                  <span v-else-if="scope.row.dutyInfo[optionkey].schedulingMonthStatus === '0'" style="color:#f27707;font-size:18px">✔</span>
                </div>
              </template>
            </el-table-column>

          </el-table-column>

        </el-table>
      </div>
      <div style="height: 100px;line-height: 100px;text-align: center;">
        <el-button type="primary" @click="submit">提 交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require('moment')
const now = new Date() // 当前日期
const nowDayOfWeek = now.getDay() // 今天本周的第几天
const nowDay = now.getDate() // 当前日
const nowMonth = now.getMonth() // 当前月
const nowYear = now.getFullYear() // 当前年
export default {
    components: {
    },
    props: {

    },
    data() {
        return {
            loginUser: this.$store.getters.loginUser,
            listData: [],
            startDate: '',
            endDate: '',
            weekStartDate: '',
            weekEndDate: '',
            thisWeekStartDate: '',
            times: [],
            nowday: '',
            nowmoth: '',
            nowyear: '',
            spanArr: [],
            nowDate: '',
            allChanged: false,
            // breakfastTimes: ['0', '0'],
            pbTimes: ['0', '0'],
            tableHeight: 0
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        // this.getTimeJiezhi()

        setInterval(() => {
            //   // 创建一个新的 Date 对象，表示当前日期
            // const today = new Date()
            // // 获取年、月、日
            // const year = today.getFullYear()
            // const month = String(today.getMonth() + 1).padStart(2, '0') // 月份需要加1，且补齐两位
            // const day = String(today.getDate()).padStart(2, '0') // 补齐两位
            // // 将年、月、日拼接成"2023-12-23"格式
            // const formattedDate = `${year}-${month}-${day}`
            // this.nowDate = formattedDate

            const now = new Date()
            const year = now.getFullYear()
            const month = (now.getMonth() + 1).toString().padStart(2, '0')
            const day = now.getDate().toString().padStart(2, '0')
            const hours = now.getHours().toString().padStart(2, '0')
            const minutes = now.getMinutes().toString().padStart(2, '0')

            const formattedDateTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes // 当前时间
            this.nowDate = formattedDateTime
        }, 1000)

        setTimeout(() => {
            this.defaultWeekDate()
        }, 200)
        this.setHeight()
    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
        setHeight() {
            var windowHeight = window.innerHeight
            console.log('窗口高度：' + windowHeight)
            this.tableHeight = windowHeight - 400
        },
        // getTimeJiezhi() {
        //     // 查早餐
        //     this.$http({
        //         url: '/base/paramConfig/querydata',
        //         method: 'POST',
        //         data: [
        //             {
        //                 compare: '=',
        //                 field: 'paramName',
        //                 merge: true,
        //                 value: 'dcgl.zcdc'
        //             },
        //             {
        //                 compare: '=',
        //                 field: 'orgCode',
        //                 merge: true,
        //                 value: this.loginUser.orgCode
        //             }
        //         ]
        //     })
        //         .then((res) => {
        //             if (res.code === 'success') {
        //                 const arr = res.body.listdata[0].paramValue.split(',')
        //                 console.log('arrzaocan', arr)

        //                 // this.breakfastDayOne = Number(arr[0])
        //                 // this.breakfastDateOne = arr[1]
        //                 this.breakfastTimes = arr
        //                 console.log(arr, 'arr')
        //             }
        //         })
        //         .catch((res) => {})

        //     // 查午餐
        //     this.$http({
        //         url: '/base/paramConfig/querydata',
        //         method: 'POST',
        //         data: [
        //             {
        //                 compare: '=',
        //                 field: 'paramName',
        //                 merge: true,
        //                 value: 'dcgl.wcdc'
        //             },
        //             {
        //                 compare: '=',
        //                 field: 'orgCode',
        //                 merge: true,
        //                 value: this.loginUser.orgCode
        //             }
        //         ]
        //     })
        //         .then((res) => {
        //             if (res.code === 'success') {
        //                 const arr = res.body.listdata[0].paramValue.split(',')
        //                 console.log('arrwucan', arr)
        //                 // this.lunchDayOne = Number(arr[0])
        //                 // this.lunchDateOne = arr[1]
        //                 this.pbTimes = arr
        //             }
        //         })
        //         .catch((res) => {})
        // },
        // 全选、取消
        changeAll() {
            console.log('this.allChanged', this.allChanged)

            if (this.allChanged) {
                this.listData.forEach(item => {
                    item.dutyInfo.forEach((val, index) => {
                        // if (index < 5) { // index小于5 不选周六日
                        // if (val.breakfastJiezhiTime >= this.nowDate) { // 前端限制选择行 只能选中今天开始往后的排班
                        //     val.breakfast = true
                        // }
                        if (val.pbJiezhiTime >= this.nowDate) { // 前端限制选择行 只能选中今天开始往后的排班
                            val.scheduling = true
                        }
                        // }
                    })
                })
            } else {
                this.listData.forEach(item => {
                    item.dutyInfo.forEach((val, index) => {
                        // if (index < 5) { // index小于5 不选周六日
                        // if (val.breakfastJiezhiTime >= this.nowDate) { // 前端限制选择行 只能取消今天开始往后的排班
                        //     val.breakfast = false
                        // }
                        if (val.pbJiezhiTime >= this.nowDate) { // 前端限制选择行 只能取消今天开始往后的排班
                            val.scheduling = false
                        }
                        // }
                    })
                })
            }
        },
        // 点击姓名行选中/取消
        changeName(scope) {
            console.log(scope, this.thisWeekStartDate)

            if (scope.row.changed) {
                this.listData[scope.$index].dutyInfo.forEach((item, index) => {
                    // if (index < 5) { // index小于5 不选周六日
                    // if (item.breakfastJiezhiTime >= this.nowDate) { // 前端限制选择行 只能选中今天开始往后的排班
                    //     item.breakfast = true
                    // }
                    if (item.pbJiezhiTime >= this.nowDate) { // 前端限制选择行 只能选中今天开始往后的排班
                        item.scheduling = true
                    }
                    // }
                })
            } else {
                this.listData[scope.$index].dutyInfo.forEach((item, index) => {
                    // if (index < 5) { // index小于5 不选周六日
                    // if (item.breakfastJiezhiTime >= this.nowDate) { // 前端限制选择行 只能取消今天开始往后的排班
                    //     item.breakfast = false
                    // }
                    if (item.pbJiezhiTime >= this.nowDate) { // 前端限制选择行 只能取消今天开始往后的排班
                        item.scheduling = false
                    }
                    // }
                })
            }
        },
        // 点击列（早餐、午餐）
        changeNameColumn(index, isSelect, key) {
            console.log(index, isSelect, this.thisWeekStartDate)
            if (isSelect) {
                this.listData.forEach(item => {
                    item.dutyInfo[index][key] = true
                })
            } else {
                this.listData.forEach(item => {
                    item.dutyInfo[index][key] = false
                })
            }
        },
        // 保存
        submit() {
            // const deptId = this.$store.getters.loginUser.staffList[0].orgFullId.split('-')[1]
            this.$http({
                method: 'post',
                url: '/zbgl/dutyMobileRecord/saveDutyMobileRecordInfo',
                params: {
                    startDate: this.startDate,
                    endDate: this.endDate
                    // deptId: deptId
                },
                data: this.listData
            }).then(res => {
                if (res.code === 'success') {
                    this.$message.success(res.message)
                    this.getLeadOfficial(this.startDate, this.endDate)
                    // 保存后重置列点击的状态
                    this.times.forEach(item => {
                        // item.breakfastChange = false
                        item.schedulingChange = false
                    })
                }
            })
        },
        // 单元格点击
        selectFn(listDataIndex, timeIndex, type) {
            const now = new Date()
            const year = now.getFullYear()
            const month = (now.getMonth() + 1).toString().padStart(2, '0')
            const day = now.getDate().toString().padStart(2, '0')
            const hours = now.getHours().toString().padStart(2, '0')
            const minutes = now.getMinutes().toString().padStart(2, '0')

            const formattedDateTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes // 当前时间

            // if (type === 'breakfast') {
            //     let previousDays = this.getPreviousDays(this.listData[listDataIndex].dutyInfo[timeIndex].dutyDate, this.breakfastTimes[0])
            //     previousDays = previousDays + ' ' + this.breakfastTimes[1]
            //     console.log(formattedDateTime, previousDays)
            //     if (formattedDateTime < previousDays) {
            //         this.listData[listDataIndex].dutyInfo[timeIndex].breakfast = !this.listData[listDataIndex].dutyInfo[timeIndex].breakfast
            //     } else {
            //         this.$message.warning('当前排班已截至，不允许排班或取消！')
            //     }
            // }
            if (type === 'scheduling') {
                let previousDays = this.getPreviousDays(this.listData[listDataIndex].dutyInfo[timeIndex].dutyDate, this.pbTimes[0])
                previousDays = previousDays + ' ' + this.pbTimes[1]
                if (formattedDateTime < previousDays) {
                    this.listData[listDataIndex].dutyInfo[timeIndex].scheduling = !this.listData[listDataIndex].dutyInfo[timeIndex].scheduling
                } else {
                    this.$message.warning('当前排班已截至，不允许排班或取消！')
                }
            }
        },
        // 计算截至时间 （传入的日期，提前的天数）
        getPreviousDays(dateString, days) {
            var currentDate = new Date(dateString)
            var previousDays = new Date(currentDate)
            previousDays.setDate(currentDate.getDate() - days)

            var year = previousDays.getFullYear()
            var month = (previousDays.getMonth() + 1).toString().padStart(2, '0')
            var day = previousDays.getDate().toString().padStart(2, '0')

            var previousDaysString = year + '-' + month + '-' + day
            return previousDaysString
        },
        // 新增
        addNew() {
            this.$emit('addNewForm')
        },
        // 导入
        // importBtn() {
        //     this.$bus.emit('openImportForm')
        // },
        // 获取数据
        getLeadOfficial(start, end) {
            console.log('loginUser', this.$store.getters.loginUser)
            // const deptId = this.$store.getters.loginUser.staffList[0].orgFullId.split('-')[1]
            this.$http({
                method: 'GET',
                contentType: 'application/x-www-form-urlencoded',
                url: '/zbgl/dutyMobileRecord/getDeptOrderingMealInfo',
                params: {
                    'startDate': start + ' 00:00:00',
                    'endDate': end + ' 23:59:59'
                    // 'deptId': deptId
                }
            }).then(res => {
                if (res.code === 'success') {
                    res.body.listdata.forEach(item => {
                        item.changed = false
                        item.dutyInfo.forEach(val => {
                            // val.breakfastJiezhiTime = this.getPreviousDays(val.dutyDate, this.breakfastTimes[0]) + ' ' + this.breakfastTimes[1]
                            val.pbJiezhiTime = this.getPreviousDays(val.dutyDate, this.pbTimes[0]) + ' ' + this.pbTimes[1]
                        })
                    })
                    this.listData = res.body.listdata
                    this.allChanged = false
                    console.log(111, this.listData)
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 加载一周日期
        showDate(time) {
            const arr = []
            let d = new Date(time).getDate()
            let m = new Date(time).getMonth() + 1
            let y = new Date(time).getFullYear()
            const list = { 'day': d, 'month': m, 'sign': '星期一', 'flage': m, 'year': y }
            arr.push(list)
            for (let i = 0; i < 6; i++) {
                const c = time + (24 * 60 * 60 * 1000)
                d = new Date(c).getDate()
                m = new Date(c).getMonth() + 1
                y = new Date(c).getFullYear()
                time = c
                let week = ''
                if (i === 0) { week = '星期二' }
                if (i === 1) { week = '星期三' }
                if (i === 2) { week = '星期四' }
                if (i === 3) { week = '星期五' }
                if (i === 4) { week = '星期六' }
                if (i === 5) { week = '星期日' }
                const lists = { 'day': d, 'month': m, 'sign': week, 'flage': m, 'year': y }
                arr.push(lists)
            }
            arr.forEach(item => {
                // item.breakfastChange = false
                item.schedulingChange = false
            })
            this.times = arr
            this.nowday = nowDay
            this.nowmoth = nowMonth + 1
            this.nowyear = nowYear
        },
        // 默认展示本周日期
        defaultWeekDate() {
            // 默认本周
            if (nowDayOfWeek === 0) {
                this.weekStartDate = new Date(nowYear, nowMonth, nowDay - (nowDayOfWeek + 6))
                this.weekEndDate = new Date(nowYear, nowMonth, (nowDay - nowDayOfWeek))
            } else {
                this.weekStartDate = new Date(nowYear, nowMonth, (nowDay - nowDayOfWeek) + 1)
                this.weekEndDate = new Date(nowYear, nowMonth, ((nowDay - nowDayOfWeek) + 1) + 6)
            }
            this.startDate = moment(new Date(Date.parse(this.weekStartDate.getFullYear() + '/' + (this.weekStartDate.getMonth() + 1) + '/' + this.weekStartDate.getDate()))).format('YYYY-MM-DD')
            this.endDate = moment(new Date(Date.parse(this.weekEndDate.getFullYear() + '/' + (this.weekEndDate.getMonth() + 1) + '/' + this.weekEndDate.getDate()))).format('YYYY-MM-DD')
            this.thisWeekStartDate = Date.parse(this.startDate)
            this.showDate(this.thisWeekStartDate)
            this.getLeadOfficial(this.startDate, this.endDate)
        },
        // 获取上周、下周、本周日期
        getDate(way) {
            if (way === 'now') { // 本周
                this.startDate = moment(new Date(Date.parse(this.weekStartDate.getFullYear() + '/' + (this.weekStartDate.getMonth() + 1) + '/' + this.weekStartDate.getDate()))).format('YYYY-MM-DD')
                this.endDate = moment(new Date(Date.parse(this.weekEndDate.getFullYear() + '/' + (this.weekEndDate.getMonth() + 1) + '/' + this.weekEndDate.getDate()))).format('YYYY-MM-DD')
                this.showDate(this.thisWeekStartDate)
            } else if (way === 'last') { // 上周
                let a = (Date.parse(this.startDate) - 7 * 24 * 60 * 60 * 1000)
                this.showDate(a)
                a = new Date(a)
                let b = (Date.parse(this.endDate) - 7 * 24 * 60 * 60 * 1000)
                b = new Date(b)
                this.startDate = moment(new Date(Date.parse(a.getFullYear() + '/' + (a.getMonth() + 1) + '/' + a.getDate()))).format('YYYY-MM-DD')
                this.endDate = moment(new Date(Date.parse(b.getFullYear() + '/' + (b.getMonth() + 1) + '/' + b.getDate()))).format('YYYY-MM-DD')
            } else if (way === 'next') { // 下周
                let a = (Date.parse(this.startDate) + 7 * 24 * 60 * 60 * 1000)
                this.showDate(a)
                a = new Date(a)
                let b = (Date.parse(this.endDate) + 7 * 24 * 60 * 60 * 1000)
                b = new Date(b)
                this.startDate = moment(new Date(Date.parse(a.getFullYear() + '/' + (a.getMonth() + 1) + '/' + a.getDate()))).format('YYYY-MM-DD')
                this.endDate = moment(new Date(Date.parse(b.getFullYear() + '/' + (b.getMonth() + 1) + '/' + b.getDate()))).format('YYYY-MM-DD')
            }
            this.getLeadOfficial(this.startDate, this.endDate)
        }
    }
}
</script>

  <style scoped lang="scss">
  @import "~@/wapp/common/styles/element-variables.scss";
      .el-table-toolbar{
          margin: 0 0 10px 0;
          .el-row{
              height: 32px;
              line-height: 32px;
          }
      }
      .el-table-toolbar-right{
          text-align: right
      }
      /deep/ .current{
        background: var(--subjectColor) !important;
        color:#FFFFFF
      }
      .gwapTd{
        height:100%;
        cursor: pointer;
        background:  $--color-primary;
        color:#FFFFFF;
        border-top:1px solid #ffffff;
        padding: 5px
      }
      .gwapNo01{
        width: calc(100% + 1px) !important;
        position: relative;
        left: -1px;
        z-index: 11;

      }
      .gwapTd:first-child{
        border-top:none
      }
      /deep/ .el-table__body td.extrude{
        padding: 0;
        vertical-align: top;
      }
      /deep/ .el-table__body td.extrude .cell{
        padding: 0!important;
        overflow: initial;
      }
      /deep/ .el-table th.el-table__cell>.cell{
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

  /deep/.el-table .el-table__header-wrapper .el-table__header thead th{
      color: #666666 !important;
      font-size: 15px !important;
    border-right: 1px solid #d8d8d8 !important;
    border-bottom: 1px solid #d8d8d8 !important;
  }
  /deep/.el-table .el-table__header-wrapper .el-table__header thead th:last-child{
    border-right: 1px solid #d8d8d8 !important;
  }

  /deep/.el-table th.is-leaf{
    border-bottom: 1px solid #d8d8d8 !important;
  }
  .el-table.el-table--border{
    border-color: #d8d8d8;
  }
  .el-table.el-table--border::after{
    background-color: #d8d8d8;
  }
  </style>
  <style lang="scss">
  .el-tooltip__popper.is-light{
    border-color:#ebeef5;
  }
  .el-tooltip__popper.is-light[x-placement^=right] .popper__arrow{
    border-right-color:#ccc
  }

  .nowap {
    width: 100%;
    display: inline-block;
    height: 18px;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  </style>
