<template>
  <div>
    <!-- 查看 -->
    <el-table v-if="wfInstance.passenger.deptCollectStatuses && wfInstance.passenger.deptCollectStatuses.length>0" :data="wfInstance.passenger.deptCollectStatuses" border style="width: 100%">
      <el-table-column type="index" label="序号" width="45" align="center" />
      <el-table-column prop="deptName" label="填报部门" align="center" />
      <el-table-column prop="reportPeopleName" label="填报人" width="" align="center" />
      <el-table-column prop="reportTime" label="填报时间" width="" align="center" />
      <el-table-column prop="nightStaff" label="操作" width="" align="center">
        <template slot-scope="scope">
          <el-link @click="workTable(scope.row)">值班表</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div style="height:4px" />
    <!-- 填报 -->
    <el-table v-if="wfInstance.passenger.deptScheduleDaliys && wfInstance.passenger.deptScheduleDaliys.length>0" class="xzhqTable" :data="wfInstance.passenger.deptScheduleDaliys" border style="width: 100%" @row-click="rowClick">
      <el-table-column prop="workDay" label="日期" width="100" align="center">
        <template slot-scope="scope">
          <span>{{ changeDay(scope.row.workDay) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="daliyLeader" label="带班领导" align="center">
        <template slot-scope="scope">
          <el-input v-if="curTn.tnNodeName === '部门填报'" v-model="scope.row.daliyLeader" v-select-dialog="'staff'" :org-id="scope.row.deptId" name="daliyLeader" resize="none" />
          <span v-else>{{ scope.row.daliyLeader }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dayStaff" label="白班值班人员" width="" align="center">
        <template slot-scope="scope">
          <el-input v-if="curTn.tnNodeName === '部门填报'" v-model="scope.row.dayStaff" v-select-dialog="'staff'" :org-id="scope.row.deptId" name="dayStaff" resize="none" />
          <span v-else>{{ scope.row.dayStaff }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="nightStaff" label="晚班值班人员" width="" align="center">
        <template slot-scope="scope">
          <el-input v-if="curTn.tnNodeName === '部门填报'" v-model="scope.row.nightStaff" v-select-dialog="'staff'" :org-id="scope.row.deptId" name="nightStaff" resize="none" />
          <span v-else>{{ scope.row.nightStaff }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 值班表 -->
    <el-dialog class="tjbg_dialog" width="1000px" title="值班表" :visible.sync="dialogVisible" :close-on-click-modal="false" :append-to-body="true" :destroy-on-close="true">
      <el-table :data="innerTableList" border style="width: 100%">
        <el-table-column prop="workDay" label="日期" width="100" align="center">
          <template slot-scope="scope">
            <span>{{ changeDay(scope.row.workDay) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="reponseLevel" width="80" label="响应级别" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.reponseLevel">{{ scope.row.reponseLevel+'级' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="daliyLeader" label="带班领导" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.leaderConttact">{{ scope.row.daliyLeader+"("+scope.row.leaderConttact+")" }}</span>
            <span v-else>{{ scope.row.daliyLeader }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="dayStaff" label="白班值班人员" width="" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.dayContact">{{ scope.row.dayStaff+"("+scope.row.dayContact+")" }}</span>
            <span v-else>{{ scope.row.dayStaff }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="nightStaff" label="晚班值班人员" width="" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.nightContact">{{ scope.row.nightStaff+"("+scope.row.nightContact+")" }}</span>
            <span v-else>{{ scope.row.nightStaff }}</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
const moment = require('moment')
import { mapGetters } from 'vuex'
export default {
    components: {

    },
    props: {
        vo: { type: Object, default: function() { return {} } },
        wfInstance: { type: Object, default: function() { return {} } },
        curTn: { type: Object, default: function() { return {} } },
        canEdit: { type: Number, default: function() { return 1 } }
    },
    data() {
        return {
            tableData: [],
            rowIndex: 0,
            dialogVisible: false,
            innerTableList: []
        }
    },
    computed: {
        ...mapGetters(['routes', 'loginUser']),
        changeDay() {
            return (day) => {
                return moment(day).format('M月D号')
            }
        }
    },
    watch: {

    },
    created() {
    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
        onSelectStaffCompleted(nodes, attr) {
            console.log('nodes[0]', nodes[0])

            this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].reponseLevel = this.vo.repsonseLevel
            if (attr.name === 'dayStaff') {
                // this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].dayStaff = nodes[0].name
                this.$set(this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex], 'dayStaff', nodes[0].name)
                this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].dayContact = nodes[0].mobile
                this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].dayAccount = nodes[0].personId
                if (!this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].nightStaff) {
                    this.$set(this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex], 'nightStaff', nodes[0].name)
                    // this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].nightStaff = nodes[0].name
                    this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].nightContact = nodes[0].mobile
                    this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].nightAccount = nodes[0].personId
                }
            } else if (attr.name === 'nightStaff') {
                this.$set(this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex], 'nightStaff', nodes[0].name)
                // this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].nightStaff = nodes[0].name
                this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].nightContact = nodes[0].mobile
                this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].nightAccount = nodes[0].personId
                if (!this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].dayStaff) {
                    this.$set(this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex], 'dayStaff', nodes[0].name)
                    // this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].dayStaff = nodes[0].name
                    this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].dayContact = nodes[0].mobile
                    this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].dayAccount = nodes[0].personId
                }
            } else if (attr.name === 'daliyLeader') {
                this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].daliyLeader = nodes[0].name
                this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].leaderConttact = nodes[0].mobile
                this.wfInstance.passenger.deptScheduleDaliys[this.rowIndex].leaderAccount = nodes[0].personId
            }
        },
        rowClick(row, column, event) {
            console.log('row', row)
            this.wfInstance.passenger.deptScheduleDaliys.map((item, index) => {
                if (item.workDay === row.workDay) {
                    this.rowIndex = index
                }
            })
            console.log('this.rowIndex', this.rowIndex)
        },
        workTable(row) {
            console.log('row', row)
            this.innerTableList = row.passenger.deptScheduleDaliys
            this.dialogVisible = true
        }
    }
}
</script>

<style scoped lang="scss">
.tjbg_dialog {
  /deep/ .el-dialog__body {
    height: 550px;
    overflow-y: auto;
  }
}
.xzhqTable {
  /deep/ .el-input input {
    pointer-events: none;
  }
}
</style>
