import Vue from 'vue'
import Wapp from '@/wapp/wapp'

import Cookies from 'js-cookie'
import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import ELEMENT from 'element-ui'

import '@/wapp/common/styles/element-variables.scss'
import '@/wapp/common/styles/index.scss' // global css

import { store, httpRequest } from 'ecinc-cloud-wappaio'

import { VueCropper } from '../../public/plugins/vue-cropper/src/index'
import { uuid } from '@/global/utils/index.js'
Vue.prototype.Cookies = Cookies
Vue.prototype.$http = httpRequest
Vue.use(VueCropper)

import ecwappaio from 'ecinc-cloud-wappaio'
Vue.use(ecwappaio)

import i18n from '@/global/lang/index'

import router from '@/wapp/common/router'

import wappUtils from '@/wapp/common/utils'
Vue.prototype.$wappUtils = wappUtils
Vue.prototype.$uuid = uuid

Vue.use(require('ecinc-cloud-yoabase'))
Vue.use(require('ecinc-cloud-yoaemail'))
Vue.use(require('ecinc-cloud-yoagwgl'))
Vue.use(require('ecinc-cloud-yoadcdb'))
Vue.use(require('ecinc-cloud-yoadbgl'))
Vue.use(require('ecinc-cloud-yoahygl'))
Vue.use(require('ecinc-cloud-yoaclgl'))
Vue.use(require('ecinc-cloud-yoacgjgl'))
Vue.use(require('ecinc-cloud-yoackgl'))
Vue.use(require('ecinc-cloud-yoazzgl'))
Vue.use(require('ecinc-cloud-yoayxgl'))
Vue.use(require('ecinc-cloud-yoazcgl'))
Vue.use(require('ecinc-cloud-yoawpgl'))
Vue.use(require('ecinc-cloud-yoatsgl'))
Vue.use(require('ecinc-cloud-yoarsgl'))
Vue.use(require('ecinc-cloud-yoakqgl'))
Vue.use(require('ecinc-cloud-yoapxgl'))
Vue.use(require('ecinc-cloud-yoahtgl'))
Vue.use(require('ecinc-cloud-yoaxmgl'))
Vue.use(require('ecinc-cloud-yoacggl'))
Vue.use(require('ecinc-cloud-yoafygl'))
Vue.use(require('ecinc-cloud-yoaszyd'))
Vue.use(require('ecinc-cloud-yoafzgl'))
Vue.use(require('ecinc-cloud-yoadjgl'))
Vue.use(require('ecinc-cloud-yoaecoa'))
Vue.use(require('ecinc-cloud-yoazsgl'))
Vue.use(require('ecinc-cloud-yoawjzx'))
Vue.use(require('ecinc-cloud-yoagzbg'))
Vue.use(require('ecinc-cloud-yoarwgl'))
Vue.use(require('ecinc-cloud-yoagdgl'))
Vue.use(require('ecinc-cloud-yoazpgl'))
Vue.use(require('ecinc-cloud-yoakhgl'))
Vue.use(require('ecinc-cloud-yoaxxfb'))
Vue.use(require('ecinc-cloud-yoacrm'))
Vue.use(require('ecinc-cloud-yoaggxx'))
Vue.use(require('ecinc-cloud-yoajxgl'))
Vue.use(require('ecinc-cloud-yoaksgl'))
Vue.use(require('ecinc-cloud-yoakygl'))
Vue.use(require('ecinc-cloud-yoawdgl'))
Vue.use(require('ecinc-cloud-yoaznbg'))
// Vue.use(require('ecinc-cloud-yoaxcgl'))

// import '@/wapp/common/mock/mockServer.js'
// import axios from 'axios'
// Vue.prototype.axios = axios

const moment = require('moment')
Vue.prototype.$moment = moment // 时间格式化 挂载在vue实例上 方便使用
const qs = require('qs')
Vue.prototype.$qs = qs
// 调用$getDefaultData获取小组件伪数据显示
import defaultApi from '@/wapp/common/utils/defaultApi.js'
Vue.prototype.$getDefaultData = ($scope, id) => {
    return defaultApi.getDefaultData($scope, id)
}

Vue.prototype.$isEmpty = (value) => {
    return value === '' || value == null
}

import '@/global/icons' // icon
import '@/wapp/common/router/beforeEach' // permission control
import '@/wapp/_import/vueLoader.js'
import '@/global/utils/validator.js'

import EVueContextmenu from 'e-vue-contextmenu'
Vue.use(EVueContextmenu)

import vuePlugsPrint from '@/global/utils/vuePlugsPrint'
Vue.use(vuePlugsPrint)
// register global utility filters
import * as filters from '@/global/filters' // global filters
Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

Vue.use(ELEMENT, {
    size: Cookies.get('size') || 'medium' // set element-ui default size
})

import '../../packages/developer'

new Vue({
    el: '#wapp',
    router,
    store,
    i18n,
    render: (h) => h(Wapp)
})
